// NOTE: if you update this file, you need to run `make generatestyles`

import colors from './colors'
import {css} from '@emotion/react'

const constants = {
  // Note that we override these font stacks with much shorter ones for email (to keep email sizes
  // smaller) in scripts/generate-styles.js
  fontFamily:
    'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontFamilyHeader:
    'tablet-gothic-semi-condensed, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontMonospace:
    'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontWeightNormal: '400',
  fontWeightSemibold: '600',
  fontWeightBold: '700',

  // NB: These are only meant to be used in conjunction with font sizes below.
  // Hence, the naming is meant to match up with the corresponding fonts and
  // why the XS is actually bigger than the XL :P
  lineHeightXS: '1.5',
  lineHeightS: '1.5',
  lineHeightBase: '1.5',
  lineHeightM: '1.2',
  lineHeightL: '1.2',
  lineHeightXL: '1.1',

  // Font sizes (in rems)
  // Roughly equivalent to a 1.15x scaling factor, although I rounded certain numbers.
  // See https://type-scale.com/?size=14&scale=1.15&text=A%20Visual%20Type%20Scale&font=Open%20Sans&fontweight=700&bodyfont=Poppins&bodyfontweight=400&lineheight=1.45&backgroundcolor=white&fontcolor=%23333&preview=false
  // Keep in sync with Typography.js
  fontSizeXS: '0.75rem',
  fontSizeS: '0.875rem',
  fontSizeBase: '1rem',
  fontSizeM: '1.125rem',
  fontSizeL: '1.5rem',
  fontSizeXL: '2rem',

  xsIcon: '1rem',
  smallIcon: '2rem',
  mediumIcon: '3rem',
  largeIcon: '4rem',
  hugeIcon: '5rem',
}

const mixins = {
  h1: css`
    font: ${constants.fontWeightBold} ${constants.fontSizeXL} /
      ${constants.lineHeightXL} ${constants.fontFamilyHeader};
  `,
  h2: css`
    font: ${constants.fontWeightBold} ${constants.fontSizeL} /
      ${constants.lineHeightL} ${constants.fontFamilyHeader};
  `,
  h3: css`
    font: ${constants.fontWeightBold} ${constants.fontSizeM} /
      ${constants.lineHeightM} ${constants.fontFamilyHeader};
  `,
  h4: css`
    font: ${constants.fontWeightSemibold} ${constants.fontSizeBase} /
      ${constants.lineHeightBase} ${constants.fontFamily};
  `,
  body1: css`
    font: ${constants.fontWeightNormal} ${constants.fontSizeBase} /
      ${constants.lineHeightBase} ${constants.fontFamily};
  `,
  body2: css`
    font: ${constants.fontWeightNormal} ${constants.fontSizeS} /
      ${constants.lineHeightS} ${constants.fontFamily};
    color: ${colors.neutral400};
  `,
  subtitle1: css`
    font: ${constants.fontWeightNormal} ${constants.fontSizeXS} /
      ${constants.lineHeightXS} ${constants.fontFamily};
    color: ${colors.neutral400};
  `,
}

const typography = {
  ...constants,
  ...mixins,
}
export default typography

export const storybook = {
  constants,
  mixins,
}
