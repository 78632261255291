import {Button, FormItem, Typography} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'

import {ActionType} from './FundraisingFormReducer'
import ContactInformationFields from './field_groups/ContactInformationFields'
import ContributionInformationFields from './field_groups/ContributionInformationFields'
import {FormDefinitionElementNames} from 'events/details/FundraisingForm/constants'
import GeneralFields from './field_groups/GeneralFields'
import {OnlineActionFillSource} from 'app/enums'
import PaymentInformationFields from './field_groups/PaymentInformationFields'
import Timeslots from 'events/details/SignupForm/Timeslots'
import {getAvailableTimeslots} from 'events/details/util'
import {setSignupValues} from 'events/details/FundraisingForm/actions'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const SectionHeaderWrapper = styled.div`
  margin-bottom: ${styles.space.m};
`

const messages = defineMessages({
  contactInformation: {
    defaultMessage: 'Your details',
  },
  paymentInformation: {
    defaultMessage: 'Payment information',
  },
})

export default function FundraisingFormStep({
  dispatch,
  formMetadata,
  isActiveStep,
  isDonateToRsvpEvent,
  onChange,
  organization,
  prefillSource,
  shouldRenderVgsFields,
  section,
  submitJsForm,
  values,
  maybePrioritizedTimeslot,
  signupValues,
  event,
}) {
  const intl = useIntl()

  if (!section.name || !section.children) {
    return null
  }

  const availableTimeslots = getAvailableTimeslots(event)
  const shouldShowTimeslots = availableTimeslots.length > 1

  const getInputs = () => {
    const commonFieldProps = {
      fields: section.children,
      formMetadata,
      onChange,
      prefillSource,
      values,
    }

    // Each of the following cases could potentially become a component.
    switch (section.name) {
      case FormDefinitionElementNames.PAYMENT_INFO:
        return (
          <>
            <SectionHeaderWrapper>
              <Typography variant="h3">
                {intl.formatMessage(messages.paymentInformation)}
              </Typography>
            </SectionHeaderWrapper>
            <PaymentInformationFields
              creditCardLastFour={values.cc_4_digit}
              creditCardType={values.cc_type}
              dispatch={dispatch}
              fields={commonFieldProps.fields}
              formMetadata={formMetadata}
              shouldRenderVgsFields={shouldRenderVgsFields}
              submitJsForm={submitJsForm}
            />
          </>
        )
      case FormDefinitionElementNames.CONTRIBUTION_INFO:
        const handleSelectShift = (selected) => {
          dispatch(setSignupValues({shifts: selected}))
        }

        return (
          <>
            <ContributionInformationFields
              {...commonFieldProps}
              dispatch={dispatch}
              displayFrequencyOptions={!isDonateToRsvpEvent}
              organization={organization}
            />
            {shouldShowTimeslots && (
              <Timeslots
                event={event}
                maybePrioritizedTimeslot={maybePrioritizedTimeslot}
                precheckedTwoTimeslots={false}
                onChange={handleSelectShift}
                shifts={signupValues.shifts || []}
                // TODO: Implement shiftErrors
                shiftErrors={{}}
                // Do not enable select all for fundraising form
                showSelectAll={false}
                selectAllOn={false}
                setSelectAllOn={() => {}}
              />
            )}
          </>
        )
      case FormDefinitionElementNames.CONTACT_INFO:
        return (
          <>
            <SectionHeaderWrapper>
              <Typography variant="h3">
                {intl.formatMessage(messages.contactInformation)}
              </Typography>
            </SectionHeaderWrapper>
            {!!prefillSource &&
              [
                OnlineActionFillSource.FAST_ACTION,
                OnlineActionFillSource.PROFILE,
              ].includes(prefillSource) && (
                <SectionHeaderWrapper>
                  <F defaultMessage="Using details from your ActionID profile." />
                  <Button
                    link
                    padding="none"
                    onClick={() =>
                      dispatch({type: ActionType.PREFILLED_FORM_VALUES_CLEARED})
                    }
                    data-testid="clearPrefilledFields"
                  >
                    <F defaultMessage="Clear details" />
                  </Button>
                </SectionHeaderWrapper>
              )}
            <ContactInformationFields {...commonFieldProps} />
          </>
        )
      default:
        return <GeneralFields {...commonFieldProps} />
    }
  }

  return (
    // TODO: Convert this to transitions and animated.div like in SignupForm/index.js
    <div style={!isActiveStep ? {display: 'none'} : undefined}>
      <FormItem>{getInputs()}</FormItem>
    </div>
  )
}
