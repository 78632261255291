import {setBuildCommit, setLastSeenLoggedInAtToNow} from 'redux/actions'

import DataProvider from 'data/DataProvider'
import {connect} from 'react-redux'
import hoistNonReactStatics from 'hoist-non-react-statics'
import withDashboardAnalytics from 'analytics/withDashboardAnalytics'
import {withRouter} from 'react-router'

/**
 * HOC that hooks up screen components (i.e. Pages) to data passed down to
 * the server, redux, page analytics and other page-level hooks and side-effects.
 */
// eslint-disable-next-line no-use-before-define
function withScreenDataHOC(Wrapped, opts) {
  const mapDispatchToProps = (dispatch) => ({
    setLastSeenLoggedInAtToNow: () => dispatch(setLastSeenLoggedInAtToNow()),
    setBuildCommit: (buildCommit) => dispatch(setBuildCommit(buildCommit)),
  })

  const DataProviderWrapper = (props) => (
    <DataProvider Wrapped={Wrapped} screenOptions={opts || {}} {...props} />
  )
  const Connected = connect(null, mapDispatchToProps)(DataProviderWrapper)

  return hoistNonReactStatics(withRouter(Connected), Wrapped)
}

// eslint-disable-next-line no-use-before-define
const withScreenData = (Wrapped, opts) =>
  withScreenDataHOC(withDashboardAnalytics(Wrapped), opts)
export default withScreenData
