import {Route, Switch} from 'react-router-dom'

import DetailsScreen from 'events/details/DetailsScreen'
import EmailContentReportScreen from 'events/report/EmailContentReportScreen'
import EventContentReportScreen from 'events/report/EventContentReportScreen'
import EventFeedScreen from 'events/feed/FeedScreen'
import EventLayout from './EventLayout'
import FeedMapScreen from 'events/feed/FeedMapScreen'
import HostEventVerificationSuccessScreen from 'events/create/HostEventVerificationSuccessScreen'
import HostInterestScreen from 'events/host/HostInterestScreen'
import MuteThreadScreen from 'events/settings/MuteThreadScreen'
import PublicHostCreateEventScreen from 'events/create/PublicHostCreateEventScreen'
import UnsubscribeScreen from 'events/settings/UnsubscribeScreen'
import UserSettingsScreen from 'events/settings/UserSettingsScreen'
import VerifyAfterEventCreationScreen from 'events/create/VerifyAfterEventCreationScreen'
import VolunteerScheduleScreen from 'events/schedule/VolunteerScheduleScreen'

/**
 * These are all the event routes. The top-level ones don't give the page
 * a maxWidth or padding, and leave it up to the page to handle.
 * The other routes do add a max width and you probably want
 * to add your route there.
 */
export default function EventRoutes({isCustomDomain, trackingParams}) {
  return (
    <Switch>
      <Route
        exact
        path={isCustomDomain ? '/map/' : ['/map/', '/:feedId/map/']}
        render={(props) => (
          <EventLayout>
            <FeedMapScreen trackingParams={trackingParams} {...props} />
          </EventLayout>
        )}
      />

      <Route
        exact
        path={isCustomDomain ? '/' : ['/', '/embed/:feedId/feed/', '/:feedId/']}
        render={(props) => (
          <EventLayout>
            <EventFeedScreen trackingParams={trackingParams} {...props} />
          </EventLayout>
        )}
      />
      <Route
        exact
        path={
          isCustomDomain
            ? '/events/:landingPageSlug/'
            : ['/events/:landingPageSlug/', '/:feedId/events/:landingPageSlug']
        }
        render={(props) => (
          <EventLayout>
            <EventFeedScreen trackingParams={trackingParams} {...props} />
          </EventLayout>
        )}
      />
      <Route
        exact
        path={
          isCustomDomain
            ? '/c/:eventCampaignSlug/'
            : '/:feedId/c/:eventCampaignSlug/'
        }
        render={(props) => (
          <EventLayout>
            <EventFeedScreen trackingParams={trackingParams} {...props} />
          </EventLayout>
        )}
      />
      <Route
        exact
        path={
          isCustomDomain
            ? '/event/:eventId(\\d+)/'
            : '/:feedId/event/:eventId(\\d+)/'
        }
        render={(props) => (
          <EventLayout>
            <DetailsScreen trackingParams={trackingParams} {...props} />
          </EventLayout>
        )}
      />
      <Route
        exact
        path={
          isCustomDomain
            ? '/schedule/:personId(\\d+)/:scheduleHash/'
            : '/schedule/:personUuid/'
        }
        render={(props) => (
          <EventLayout>
            <VolunteerScheduleScreen
              trackingParams={trackingParams}
              {...props}
            />
          </EventLayout>
        )}
      />
      <EventLayout maxWidth>
        <Switch>
          <Route
            exact
            path={
              isCustomDomain
                ? ['/event/create/', '/c/:eventCampaignSlug/event/create/']
                : [
                    '/:feedId/event/create/',
                    '/:feedId/c/:eventCampaignSlug/event/create/',
                  ]
            }
            render={(props) => (
              <PublicHostCreateEventScreen
                trackingParams={trackingParams}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={isCustomDomain ? '/host/' : '/:feedId/host/'}
            render={(props) => (
              <HostInterestScreen trackingParams={trackingParams} {...props} />
            )}
          />
          <Route
            exact
            path={isCustomDomain ? '/verify/' : '/:feedId/verify/'}
            component={VerifyAfterEventCreationScreen}
          />
          <Route
            exact
            path={
              isCustomDomain
                ? '/host_event_verification_success/'
                : '/:feedId/host_event_verification_success/'
            }
            component={HostEventVerificationSuccessScreen}
          />
          <Route
            exact
            path="/report_event/:eventId(\d+)/"
            component={EventContentReportScreen}
          />
          <Route
            exact
            path="/notifications/:personId(\d+)/:hash/unsubscribe/:emailCategory(\d+)/"
            component={UnsubscribeScreen}
          />
          <Route
            exact
            path="/notifications/:userId(\d+)/:hash/mute_thread/:threadId(\d+)/"
            component={MuteThreadScreen}
          />
          {!isCustomDomain && (
            <>
              <Route
                exact
                path="/me/settings/"
                component={UserSettingsScreen}
              />
              <Route
                exact
                path="/report_email/:emailUuid/"
                component={EmailContentReportScreen}
              />
            </>
          )}
        </Switch>
      </EventLayout>
    </Switch>
  )
}
