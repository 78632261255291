import {focusable, getBorderRadiusFromProps} from 'components/styles/mixins'

import {F} from 'util/i18n'
import {css} from '@emotion/react'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const MAIN_CONTENT_ID = 'main-content'

// Generally you won't need to use this directly; it's already included in the global header.
// Individual layouts opt into this by both instructing the header to render this, and wrapping main
// content with MainContent in that layout
export const SkipToContentLink = styled((props) => (
  <a href={`#${MAIN_CONTENT_ID}`} {...props}>
    <F defaultMessage="Skip to content" />
  </a>
))`
  margin: 0;
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;

  ${(props) =>
    focusable(css`
      background: ${styles.colors.white};
      height: auto;
      width: auto;
      clip: auto;
      padding: ${styles.space.m};
      ${getBorderRadiusFromProps(props)}
    `)}
`

// Wrap main content with this
export const MainContent = ({children}) => (
  <main id={MAIN_CONTENT_ID}>{children}</main>
)
