import {Button, MailboxImage, Message, Typography} from 'components'

import {Component} from 'react'
import LoginModalButton from 'app/login/LoginModalButton'
import api from 'data/api'
import {getQueryParams} from 'util/url'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import withScreenData from 'app/withScreenData'

const VerifyComponentWrapper = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  margin-top: ${styles.space.l};
  margin-bottom: ${styles.space.xl};
`

const EmailSentText = styled.div`
  margin-top: ${styles.space.m};
`

const EmailResentText = styled.div`
  margin-top: ${styles.space.m};
  margin-bottom: ${styles.space.m};
`

const ResendButtonWrapper = styled.div`
  margin-top: ${styles.space.s};
  margin-bottom: ${styles.space.m};
  display: flex;
  justify-content: center;
  font-size: 1rem;
`

class VerifyAfterEventCreationScreen extends Component {
  constructor(props) {
    super(props)

    const urlParams = getQueryParams(this.props.location.search)
    const eventId = urlParams.eventId
    const eventUuid = urlParams.eventUuid // NB(avi): For backwards compatibility. Remove after a couple deploys
    const hostEmail = urlParams.host_email
    this.state = {
      hostEmail,
      eventId,
      eventUuid, // NB(avi): For backwards compatibility. Remove after a couple deploys
      isDonationCampaign: !!urlParams.is_donation_campaign,
      emailResent: false,
      errorMessage: null,
    }
  }

  onClickResend = () => {
    this.setState({emailResent: true})
    this.requestEventVerificationEmailIfNotBlocked()
  }

  requestEventVerificationEmailIfNotBlocked = async () => {
    // if the host is blocked by the org, we'll send them to this page but with
    // no event id (uuid in backwards compatible case). don't send an email in that case
    if (this.state.eventId || this.state.eventUuid) {
      try {
        if (this.state.eventId)
          await api.requestEventVerificationEmail(this.state.eventId)
        else if (this.state.eventUuid)
          await api.backwardsCompatibleRequestEventVerificationEmail(
            this.state.eventUuid
          )
      } catch (e) {
        this.setState({
          errorMessage:
            (e && e.message) ||
            `Could not send event verification email to ${this.state.hostEmail}`,
        })
        return
      }
    }
  }

  render() {
    return (
      <VerifyComponentWrapper>
        <MailboxImage />
        <Typography variant="h2">Almost done! Check your email</Typography>
        <EmailSentText>
          Please click on the verification link we've emailed
          <br />
          to <strong>{this.state.hostEmail}</strong>
          {/* <br /> */} to confirm that
          <br />
          you submitted{' '}
          {this.state.isDonationCampaign ? 'a fundraiser' : 'an event'}.
        </EmailSentText>
        {this.state.errorMessage ? (
          <Message type="error" header="Login request failed">
            {this.state.errorMessage}
          </Message>
        ) : (
          <div>
            {this.state.emailResent ? (
              <EmailResentText>
                We've emailed you another verification link
              </EmailResentText>
            ) : (
              <>
                <ResendButtonWrapper>
                  <Button
                    link
                    onClick={this.onClickResend}
                    disabled={this.state.emailResent}
                    margin="small"
                    iconPosition="left"
                    icon="repeat"
                  >
                    Re-send verification link
                  </Button>
                </ResendButtonWrapper>
              </>
            )}
            <LoginModalButton secondary>
              Log in to your dashboard
            </LoginModalButton>
          </div>
        )}
      </VerifyComponentWrapper>
    )
  }
}

export default withScreenData(VerifyAfterEventCreationScreen)
