import {Message, MessageType, Typography} from 'components'

import withScreenData from 'app/withScreenData'

function MuteThreadScreen({data}) {
  return (
    <>
      <Typography variant="h1">
        Unsubscribed from {data.thread_description}
      </Typography>
      <Message type={MessageType.INFO}>
        You will no longer receive these messages at {data.email_address}.
      </Message>
    </>
  )
}

export default withScreenData(MuteThreadScreen)
