import {createIntl, defineMessages} from 'util/i18n'
import {createMailUrl, formatQueryString, getQueryParams} from './url'
import {
  getOrganizationEventByEventIdUrl,
  getOrganizationEventUrl,
} from 'util/routing'

import {EventType} from 'app/enums'
import analytics from 'analytics'
import {isDonationCampaignEvent} from 'util/event'
import {maybeStringToMaybeInt} from './string'

export function getShareLinkFromEventId({
  eventId,
  organization,
  user,
  initialQueryParams,
  shareParamsPartial,
}) {
  const currentVolunteer = null
  const shareParams = getShareParams(
    currentVolunteer,
    user,
    initialQueryParams,
    shareParamsPartial
  )
  return getOrganizationEventByEventIdUrl(
    organization,
    eventId,
    shareParams
  ).toString()
}

function getShareParams(
  currentVolunteer,
  user,
  initialQueryParams,
  shareParamsPartial
) {
  const maybeUserFirstName = maybeGetUserFirstName(currentVolunteer, user)
  const maybeReferringUserId =
    maybeStringToMaybeInt(initialQueryParams.chained_invite_referring_user) ||
    (user && user.id)

  const shareParams = {
    // try to include a user id
    // $FlowFixMe: flow doesn't like conditionals when spreading.
    ...(maybeReferringUserId ? {referring_vol: maybeReferringUserId} : {}),
    // and a first name
    ...(maybeUserFirstName
      ? {rname: maybeUserFirstName}
      : // if we have no first name, then `force_banner=true` will prompt the page to still show
        // the referrer banner. see `DetailsScreen.getReferrerBannerInfo`
        {force_banner: 'true'}),
    // if we've saved share params in state, that means someone has signed up for the event, so we
    // should use those (more detailed) params
    ...(shareParamsPartial || {}),
  }
  return shareParams
}

export function getShareLink({
  currentVolunteer,
  event,
  initialQueryParams,
  organization,
  shareParamsPartial,
  user,
}) {
  const shareParams = getShareParams(
    currentVolunteer,
    user,
    initialQueryParams,
    shareParamsPartial
  )
  return getOrganizationEventUrl(organization, event, shareParams).toString()
}

export function maybeGetUserFirstName(currentVolunteer, user) {
  return (
    (user && user.first_name) ||
    (currentVolunteer && currentVolunteer.firstName) ||
    null
  )
}

export function getShareLinks(props) {
  return {
    facebookUrl: maybeGetFbShareUrl(props),
    twitterUrl: maybeGetTweetUrl(props),
    mailtoUrl: maybeGetMailtoUrl(props),
    copyUrl: getShareLinkWithParams(props, 'copy_link'),
    nativeShareUrl: getShareLinkWithParams(props, 'native_share'),
  }
}

export function trackShareInitiated(share_medium, otherParams) {
  analytics.track('registration.shareInitiated', {
    share_medium,
    ...otherParams,
  })
}

const messages = defineMessages({
  petitionText: {
    defaultMessage:
      'I signed this petition organized by {orgName}. They still need more signatures. Can you sign and share as well?',
  },
  donationCampaignText: {
    defaultMessage: 'I donated to {orgName}. Can you donate and share as well?',
  },
  onlineActionText: {
    defaultMessage:
      'I took action with {orgName}. Will you join me? Use this link to sign up: ',
  },
  defaultText: {
    defaultMessage:
      "I'm attending {orgName}'s event, “{eventTitle}” - sign up now to join me!",
  },
})

export function trackHostFormShareInitiated() {
  // TODO(jo): expand on this if host form sharing shows momentum, otherwise delete
  analytics.track('hostForm.shareInitiated')
}

function getShareText(props, includeHashtag = false) {
  const intl = createIntl()

  let orgName = ''
  if (props.event.organization) {
    orgName = props.event.organization.name
  } else if (props.organization) {
    orgName = props.organization.name
  }
  const eventTitle = props.event.name
  const maybeHashtag = includeHashtag
    ? ` ${maybeGetFormattedHashtag(props)}`
    : ''
  if (props.event.is_virtual_flexible) {
    if (props.event.event_type === EventType.PETITION) {
      return intl.formatMessage(messages.petitionText, {
        orgName: orgName,
      })
    } else if (isDonationCampaignEvent(props.event)) {
      return intl.formatMessage(messages.donationCampaignText, {
        orgName: orgName,
      })
    }
    return intl.formatMessage(messages.onlineActionText, {orgName: orgName})
  }

  return `${intl.formatMessage(messages.defaultText, {
    orgName: orgName,
    eventTitle: eventTitle,
  })}${maybeHashtag}`
}

function maybeGetFormattedHashtag({hashtag}) {
  return hashtag ? `#${hashtag}` : ''
}

export function getShareLinkWithParams(props, medium) {
  const shareLinkUrl = new URL(props.shareLink)
  let shareContext = props.modalType
    ? `${props.modalType}-modal`
    : `${props.shareContext || ''}`
  if (props.entryPointWasChainedInvite) {
    // Indicate that this share modal was displayed because a chained invite link was opened,
    // so that we can track the signups resulting from these shares.
    shareContext += '-chained-invite'
  }
  shareLinkUrl.search = formatQueryString({
    ...getQueryParams(shareLinkUrl.search),
    share_context: shareContext,
    share_medium: medium,
  })
  return shareLinkUrl.toString()
}

function maybeGetFbShareUrl(props) {
  const text = getShareText(props)
  const link = getShareLinkWithParams(props, 'facebook')
  const hashtag = maybeGetFormattedHashtag(props)
  return createFbShareUrl({
    link,
    text,
    hashtag,
  })
}

function maybeGetTweetUrl(props) {
  const shareLink = getShareLinkWithParams(props, 'twitter')
  return createTweetUrl({link: shareLink, text: getShareText(props, true)})
}

function maybeGetMailtoUrl(props) {
  const shareLink = getShareLinkWithParams(
    props,
    props.shareToEmailsAllocated ? 'mailto_recipients' : 'mailto'
  )
  const recipientEmails = props.recipientEmails
  const subject = 'Hey'
  const firstName = props.userFirstName || ''
  const body = firstName
    ? `Hi –\n\n${getShareText(props)}\n\n${shareLink}\n\n` +
      `Hope to see you there, \n${firstName}`
    : `Hi –\n\n${getShareText(props)}\n\n${shareLink}\n\nHope to see you there!`

  return createMailUrl(
    recipientEmails ? recipientEmails.join(',') : '' /* empty email */,
    subject,
    body
  )
}

export function createTweetUrl({link, text}) {
  const twitterUrl = new URL('https://twitter.com/intent/tweet')
  twitterUrl.search = formatQueryString({
    url: link,
    text: text,
  })
  return twitterUrl.toString()
}

export function createFbShareUrl({link, text, hashtag}) {
  const fbUrl = new URL('https://www.facebook.com/dialog/share')
  const shareUrl = link ? new URL(link) : null
  // necessary only on dev because FB will not load share dialog for localhost URL
  if (shareUrl && shareUrl.host.includes('localhost')) {
    shareUrl.host = 'staging.mobilize.us'
    shareUrl.protocol = 'https:'
    shareUrl.port = ''
  }
  fbUrl.search = formatQueryString({
    app_id: window.__MLZ_FACEBOOK_APP_ID__,
    display: 'page',
    quote: text,
    hashtag: hashtag,
    href: shareUrl?.toString(),
  })
  return fbUrl.toString()
}

export function parseSocialShareUrl(url) {
  if (isTweetUrl(url)) {
    return {
      link: url.searchParams.get('url'),
      text: url.searchParams.get('text'),
    }
  } else if (isFbShareUrl(url)) {
    return {
      link: url.searchParams.get('href'),
      text: url.searchParams.get('quote'),
    }
  } else {
    return {link: null, text: null}
  }
}

export function isTweetUrl(url) {
  return (
    url.hostname === 'twitter.com' &&
    url.pathname === '/intent/tweet' &&
    url.searchParams.has('text') // we only require text content for twitter urls
  )
}

export function isFbShareUrl(url) {
  return (
    url.hostname === 'www.facebook.com' &&
    url.pathname === '/dialog/share' &&
    url.searchParams.has('href') // we only require link for fb urls
  )
}

// NB: A bit arbitrary but it was so that the link didn't get cut in half when it ended up in an sms.
// We were seeing that happen on an Android phone, not on iOS.
const MAX_ORG_NAME_LENGTH = 30

export const NativeShareState = Object.freeze({
  UNSUPPORTED: 1,
  COMPLETED: 2,
  CANCELLED: 3,
})

export async function nativeShareEvent(url, event, context) {
  if (typeof navigator.share !== 'function') {
    return NativeShareState.UNSUPPORTED
  }

  analytics.track('nativeShare.initiated', {context})
  try {
    const text =
      event.organization.name.length <= MAX_ORG_NAME_LENGTH
        ? `Want to join me for this ${event.organization.name} event?`
        : 'Want to join me for this event?'

    /* $FlowFixMe (jared) Flow thinks we may have made `navigator.share` undefined again since
     the check above. We didn't, and pulling the `share` function out into a variable (the
     usual solution) causes the browser to complain about an illegal invocation, so just
     ignore this warning. */
    await navigator.share({
      title: event.name,
      text,
      url,
    })
    analytics.track('nativeShare.completed', {context})
    return NativeShareState.COMPLETED
  } catch (e) {
    analytics.track('nativeShare.cancelled', {context})
    return NativeShareState.CANCELLED
  }
}
