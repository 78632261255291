import FormItem from './FormItem'
import {KeyboardDatePicker as MuiDatePicker} from '@material-ui/pickers'
import {maybeHTML5DateFromISO} from 'util/time'
import moment from 'vendor/moment'

export default function DatePicker({
  disableToolbar,
  hint,
  label,
  onChange,
  required,
  sectionLabel,
  shouldReturnHTML5Date,
  value,
  minDate,
  maxDate,
  ...props
}) {
  const handleChange = (value) => {
    if (value) {
      value.set({hour: 0, minute: 0, second: 0, millisecond: 0})
      const isoString = value.toISOString()
      onChange(
        shouldReturnHTML5Date ? maybeHTML5DateFromISO(isoString) : isoString
      )
      return
    }

    onChange(null)
  }
  const outOfRangeErrorMessage =
    minDate && maxDate
      ? `Choose a date between ${minDate.format('MM/DD')} and ${maxDate.format(
          'MM/DD'
        )}`
      : 'Choose a date within range'

  return (
    <FormItem label={sectionLabel} required={required}>
      <MuiDatePicker
        autoOk
        disableToolbar={disableToolbar}
        format="MM/DD/YYYY"
        helperText={hint}
        inputVariant="outlined"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleChange}
        value={value ? moment(value, moment.HTML5_FMT.DATE) : null}
        variant="inline"
        maxDateMessage={outOfRangeErrorMessage}
        minDateMessage={outOfRangeErrorMessage}
        {...props}
      />
    </FormItem>
  )
}
