import {
  Button,
  Grid,
  Link,
  Loader,
  MaxWidthContainer,
  Typography,
} from 'components'

import {F} from 'util/i18n'
import api from 'data/api'
import {getOrganizationLandingPageUrl} from 'util/routing'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useAsync} from 'hooks/async'

const LandingPageGridWrapper = styled.div`
  margin-top: ${styles.space.m};
  margin-bottom: ${styles.space.xl};
`

const LandingPageList = ({organization}) => {
  const showLandingPageList = orgFlagIsActive(
    organization,
    'enable_suggested_landing_pages'
  )

  const landingPageSuggestionsState = useAsync(async () => {
    if (!showLandingPageList) return {landing_pages: []}
    return api.getLandingPageSuggestions(organization.slug)
  }, [organization.slug])
  const isLoading = landingPageSuggestionsState.loading
  const hasError = landingPageSuggestionsState.error
  const landingPages =
    (landingPageSuggestionsState.result &&
      landingPageSuggestionsState.result.landing_pages) ||
    []

  if (!isLoading && (hasError || !landingPages.length)) {
    return null
  }

  return (
    <MaxWidthContainer>
      <Typography variant="h2">
        <F defaultMessage="Find events and volunteer opportunities by type or cause" />
      </Typography>
      <LandingPageGridWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid container spacing={1}>
            {landingPages.map((landingPage) => (
              <Grid item xs={12} sm={6} md={4} key={landingPage.id}>
                <Link
                  to={getOrganizationLandingPageUrl(organization, landingPage, {
                    utm_source: 'landing_page_grid',
                  })}
                  plain
                >
                  <Button secondary fluid>
                    {landingPage.short_display_name || landingPage.title}
                  </Button>
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
      </LandingPageGridWrapper>
    </MaxWidthContainer>
  )
}

export default LandingPageList
