import {formatQueryString, getQueryParams} from 'util/url'

import {Pagination as MaterialPagination} from '@material-ui/lab'
import {withRouter} from 'react-router'

function Pagination({
  pagingInfo,
  history,
  location,
  style,
  onPageChangeOverride,
}) {
  const onPageChange = (e, page) => {
    if (onPageChangeOverride) {
      onPageChangeOverride(page)
      return
    }
    history.push({
      search: formatQueryString({
        // Preserve non-pagination parameters
        ...getQueryParams(location.search),
        page,
        per_page: pagingInfo.per_page,
      }),
    })
  }

  const showPagination = pagingInfo.num_pages && pagingInfo.num_pages > 1

  return (
    <div style={showPagination ? style : undefined}>
      {showPagination ? (
        <MaterialPagination
          showFirstButton
          showLastButton
          variant="outlined"
          shape="rounded"
          page={parseInt(pagingInfo.page, 10)}
          count={pagingInfo.num_pages}
          onChange={onPageChange}
        />
      ) : null}
    </div>
  )
}

export default withRouter(Pagination)
