import {Button, Link, MaxWidthContainer} from 'components'
import {formatQueryString, getQueryParams} from 'util/url'
import styles, {getBorderRadiusFromProps} from 'components/styles'

import styled from '@emotion/styled/macro'
import {withRouter} from 'react-router'

const PageLinkWrapper = styled.div`
  display: inline-block;
  padding-top: ${styles.space.s};
  padding-bottom: ${styles.space.s};
  padding-left: ${styles.space.m};
  padding-right: ${styles.space.m};
  background-color: ${styles.colors.white};
  border: 1px solid ${styles.colors.neutral300};
  &:first-child {
    border-right: 0;
    ${(props) =>
      getBorderRadiusFromProps(props, new Set(['top-left', 'bottom-left']))};
  }
  &:last-child {
    ${(props) =>
      getBorderRadiusFromProps(props, new Set(['top-right', 'bottom-right']))};
  }
`

// Public-facing pagination; a simple cursor-based setup.
function PublicSitePagination(props) {
  const {pagingInfo, location, numOnPage} = props
  const parsedPage = parseInt(pagingInfo.page, 10)

  // Pull out current params and re-format them into Links to preserve
  // non-pagination query parameters
  const currentParams = getQueryParams(location.search)

  // If we have the number of pages in the response (e.g. from Algolia), then
  // use it to disable the "next" button; otherwise make a best guess based on
  // whether the page looks like the last page
  const disableNext =
    typeof pagingInfo.num_pages === 'number'
      ? pagingInfo.page >= pagingInfo.num_pages
      : typeof pagingInfo.per_page === 'number'
      ? numOnPage < pagingInfo.per_page
      : false

  const disablePrevious = parsedPage === 1

  if (disableNext && disablePrevious) {
    return null
  }

  return (
    <MaxWidthContainer marginBottom>
      <PageLinkWrapper>
        <Link
          plain
          // TODO(jared) eventually this should probably go through a proper link builder, but
          // for now it's fine because it's just a query string
          to={formatQueryString({
            ...currentParams,
            page: parsedPage - 1,
            per_page: pagingInfo.per_page,
          })}
        >
          <Button link padding="none" disabled={disablePrevious}>
            Previous page
          </Button>
        </Link>
      </PageLinkWrapper>
      <PageLinkWrapper>
        <Link
          plain
          // TODO(jared) eventually this should probably go through a proper link builder, but
          // for now it's fine because it's just a query string
          to={formatQueryString({
            ...currentParams,
            page: parsedPage + 1,
            per_page: pagingInfo.per_page,
          })}
        >
          <Button link padding="none" disabled={disableNext}>
            Next page
          </Button>
        </Link>
      </PageLinkWrapper>
    </MaxWidthContainer>
  )
}

export default withRouter(PublicSitePagination)
