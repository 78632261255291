import {orgFlagIsActive} from 'util/organization'

export const isZoomEnabled = (privilegedOrganization) =>
  orgFlagIsActive(privilegedOrganization, 'enable_zoom_integration') &&
  privilegedOrganization?.is_zoom_integration_active

export const EMPTY_VIRTUAL_JOIN_DETAILS = {
  privateDetails: null,
  virtualJoinUrl: null,
  zoomMeetingId: null,
  zoomMeetingType: null,
}
