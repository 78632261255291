import {Suspense, lazy} from 'react'

const AdminRoutes = lazy(() => import('admin/AdminRoutes'))
const DashboardRoutes = lazy(() => import('dashboard/app/DashboardRoutes'))
const SelfServiceRoutes = lazy(() => import('organization/SelfServiceRoutes'))

function LazyLoadedComponent(props) {
  const Component = props.lazyComponent

  return (
    <Suspense fallback={<span />}>
      <Component {...props} />
    </Suspense>
  )
}

export function adminRoutes(props) {
  return <LazyLoadedComponent lazyComponent={AdminRoutes} {...props} />
}

export function dashboardRoutes(props) {
  return <LazyLoadedComponent lazyComponent={DashboardRoutes} {...props} />
}

export function selfServiceRoutes(props) {
  return <LazyLoadedComponent lazyComponent={SelfServiceRoutes} {...props} />
}
