import {Autocomplete, CheckboxField, Input} from 'components'
import {
  getCustomSignupFieldBooleanValue,
  getCustomSignupFieldTextValue,
} from 'util/custom-signup-fields'

import {CustomSignupFieldType} from 'app/enums'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const MarginTop0 = styled.div`
  margin-top: 0;
`

const CustomFieldWrapper = styled.div`
  margin-bottom: ${styles.space.m};
`

function CustomField({
  field,
  customSignupFieldValues,
  setCustomSignupFieldValue,
  requiredCustomFieldsEnabled,
  error,
}) {
  const {id, field_type, name, signup_form_label, is_required, choices} = field
  const errorMessage =
    error && (error.message || error.boolean_value || error.text_value)
  const isRequired = requiredCustomFieldsEnabled && !!is_required

  const isLongAnswerField = field_type === CustomSignupFieldType.LONG_TEXT

  return (
    <CustomFieldWrapper>
      {field_type === CustomSignupFieldType.BOOLEAN ? (
        // CheckboxFields do not receive an errorMessage because the only error
        // validation should be the required browser check. See this PR for
        // custom error implementation if we decide we need this feature
        // https://github.com/mobilizeamerica/mobilize/pull/6944
        <CheckboxField
          name={`custom-field-${name}`}
          label={`${signup_form_label}${isRequired ? '*' : ''}`}
          checked={getCustomSignupFieldBooleanValue(
            customSignupFieldValues,
            id
          )}
          onChange={({value}) => setCustomSignupFieldValue(id, value, null)}
          required={isRequired}
        />
      ) : field_type === CustomSignupFieldType.DROPDOWN ? (
        <Autocomplete
          label={signup_form_label}
          name={`custom-field-${name}`}
          value={getCustomSignupFieldTextValue(customSignupFieldValues, id)}
          onChange={(e, {value}) => setCustomSignupFieldValue(id, null, value)}
          options={
            choices?.map((choice) => ({text: choice, value: choice})) || []
          }
          required={isRequired}
          error={!!errorMessage}
          hint={errorMessage}
        />
      ) : (
        <Input
          label={signup_form_label}
          name={`custom-field-${name}`}
          value={getCustomSignupFieldTextValue(customSignupFieldValues, id)}
          onChange={(e) => setCustomSignupFieldValue(id, null, e.target.value)}
          required={isRequired}
          error={!!errorMessage}
          hint={errorMessage}
          multiline={isLongAnswerField}
          rows={isLongAnswerField ? 8 : undefined}
        />
      )}
    </CustomFieldWrapper>
  )
}

export default function CustomFields({
  customSignupFieldValues,
  event,
  onChange,
  errors,
}) {
  const showCustomSignupFields = !!event.custom_signup_fields

  function setCustomSignupFieldValue(customFieldId, booleanValue, textValue) {
    let existingValues = [...customSignupFieldValues]
    const customSignupFieldIdx = existingValues.findIndex(
      (field) => field.custom_signup_field_id === customFieldId
    )
    if (customSignupFieldIdx >= 0) {
      existingValues[customSignupFieldIdx].boolean_value = booleanValue
      existingValues[customSignupFieldIdx].text_value = textValue
      onChange(existingValues)
    }
  }

  const requiredCustomFieldsEnabled = orgFlagIsActive(
    event.organization,
    'enable_required_custom_fields'
  )

  return (
    showCustomSignupFields && (
      <>
        {event.custom_signup_fields &&
          event.custom_signup_fields.map((field) => (
            <CustomField
              key={field.id}
              field={field}
              customSignupFieldValues={customSignupFieldValues}
              setCustomSignupFieldValue={setCustomSignupFieldValue}
              requiredCustomFieldsEnabled={requiredCustomFieldsEnabled}
              error={errors && errors[field.id.toString()]}
            />
          ))}
        <MarginTop0>
          <hr style={{marginTop: '0px'}} />
        </MarginTop0>
      </>
    )
  )
}

export function customFieldsValueMapper(event) {
  if (!event.custom_signup_fields) {
    return []
  }

  return event.custom_signup_fields.map((f) =>
    f.field_type === CustomSignupFieldType.BOOLEAN
      ? {
          custom_signup_field_id: f.id,
          text_value: null,
          boolean_value: false,
        }
      : {
          custom_signup_field_id: f.id,
          text_value: '',
          boolean_value: null,
        }
  )
}
