import {
  Table as MaterialTable,
  TableBody as MaterialTableBody,
  TableCell as MaterialTableCell,
  TableContainer as MaterialTableContainer,
  TableFooter as MaterialTableFooter,
  TableHead as MaterialTableHead,
  TableRow as MaterialTableRow,
  makeStyles,
} from '@material-ui/core'

import styled from '@emotion/styled/macro'
import styles from './styles'

// Matches padding with StyledTableContainer.
const WithoutContainer = styled.div`
  margin-top: ${styles.space.m};
  margin-bottom: ${styles.space.m};
`

export const Table = ({children, overflowVisible, maxHeight, ...props}) => {
  const content = <MaterialTable {...props}>{children}</MaterialTable>

  if (overflowVisible) {
    return <WithoutContainer>{content}</WithoutContainer>
  }

  return (
    <MaterialTableContainer style={maxHeight ? {maxHeight} : {}}>
      {content}
    </MaterialTableContainer>
  )
}

const useTableRowStyles = makeStyles((theme) => ({
  verticalAlignTop: {
    verticalAlign: 'top',
  },
}))

const TableRow = ({verticalAlignTop, ...props}) => {
  const classes = useTableRowStyles()
  return (
    <MaterialTableRow
      classes={verticalAlignTop && {root: classes.verticalAlignTop}}
      role="row"
      {...props}
    />
  )
}

const TableComponents = {
  Table,
  Cell: MaterialTableCell,
  Row: TableRow,
  Body: MaterialTableBody,
  Head: MaterialTableHead,
  Footer: MaterialTableFooter,
}

export default TableComponents
