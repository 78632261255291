import Button from './Button'
/**
 * A button with a message that appears alongside on submit
 *
 * NOTE: This component almost certainly can and should be more configurable.
 * Make props more configurable as necessary.
 */
import {Component} from 'react'
import Icon from './Icon'
import styled from '@emotion/styled/macro'
import styles from './styles'

const MessageWrapper = styled.span`
  margin-left: ${styles.space.m};
`

class ButtonWithSuccessMessage extends Component {
  state = {succeeded: false}

  onClick = async () => {
    await this.props.onClick()
    this.setState({succeeded: true})
  }

  // Used by refs to reset the button state.
  clearSuccess = () => {
    this.setState({succeeded: false})
  }

  render() {
    const {icon, buttonMessage, successMessage, color, disabled} = this.props
    const {succeeded} = this.state
    return (
      <>
        <Button
          color={color}
          onClick={this.onClick}
          disabled={disabled || succeeded}
        >
          {icon && <Icon name={icon} />}
          {buttonMessage}
        </Button>
        {succeeded && (
          <MessageWrapper>
            <Icon name="check" style={{color: styles.colors.success300}} />{' '}
            {successMessage}
          </MessageWrapper>
        )}
      </>
    )
  }
}

export default ButtonWithSuccessMessage
