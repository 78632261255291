import {Button, Loader} from 'components'
import {
  getOrganizationEventByEventIdUrl,
  getOrganizationFeedUrl,
} from 'util/routing'

import {F} from 'util/i18n'
import {LocationSpecificity} from 'app/enums'
import api from 'data/api'
import {getCityStateZip} from 'util/geo'
import styled from '@emotion/styled/macro'
import {useAsync} from 'hooks/async'

const ButtonWrapper = styled.div`
  white-space: pre-line;
  min-width: 150px;
`

const getEventMarkerPropertyValuesForCluster = (cluster, property) => {
  return [
    ...new Set(
      cluster.leaves.map((marker) => marker[property]).filter((prop) => !!prop)
    ),
  ]
}

export default function FeedMapClusterInfo({
  clusterMarker,
  organization,
  filterParams,
}) {
  const zipcodes = getEventMarkerPropertyValuesForCluster(
    clusterMarker,
    'zipcode'
  )
  const locationSpecificities = getEventMarkerPropertyValuesForCluster(
    clusterMarker,
    'location_specificity'
  )
  const isSingleZipCluster =
    zipcodes.length === 1 &&
    locationSpecificities.length === 1 &&
    locationSpecificities[0] === LocationSpecificity.ZIPCODE

  const {
    result: representativeEventResponse,
    error,
    aborted,
  } = useAsync(async () => {
    if (clusterMarker.leaves.length === 0 || !isSingleZipCluster) {
      return
    }
    const clusterLeafId = clusterMarker.leaves[0].event_id
    const url = getOrganizationEventByEventIdUrl(organization, clusterLeafId)
    return api.fetchScreenResponse(url.pathname, {}, true)
  }, [organization, clusterMarker])

  const representativeEvent = representativeEventResponse?.data.event

  const onClusterClick = (marker) => {
    if (!marker.isExpandable) {
      const clusterCenter = {
        lat: marker.lat,
        lon: marker.lng,
      }
      const urlForFeedCenteredAtCluster = getOrganizationFeedUrl(
        organization,
        {
          ...filterParams,
          ...clusterCenter,
          maxDist: 1,
        },
        {from_map: true}
      )
      window.open(urlForFeedCenteredAtCluster, '_blank')
    }
  }

  const cityStateZip =
    representativeEvent && getCityStateZip(representativeEvent)

  return (
    <ButtonWrapper>
      {isSingleZipCluster && !representativeEvent && !error && !aborted ? (
        <Loader />
      ) : (
        <Button
          padding="none"
          link
          onClick={() => onClusterClick(clusterMarker)}
        >
          {representativeEvent && cityStateZip ? (
            <F
              defaultMessage="See {eventCount} more actions in{newLine}{address}"
              values={{
                eventCount: clusterMarker.count,
                address: cityStateZip,
                newLine: '\n',
              }}
            />
          ) : (
            <F
              defaultMessage="See {eventCount} actions at this location"
              values={{eventCount: clusterMarker.count}}
            />
          )}
        </Button>
      )}
    </ButtonWrapper>
  )
}
