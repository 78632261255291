import {Timezone, TimezoneAlias} from 'app/enums'

import api from 'data/api'
import {logError} from 'util/common'
import moment from 'vendor/moment'

export const FULL_DATE_FORMAT = 'MMM D, YYYY'
export const TWELVE_HOUR_TIME_FORMAT = 'h:mm A'
export const TIMESLOT_ROW_DATE_FORMAT = 'YYYY-MM-DD'

// Guesses the browser timezone or returns Eastern time by default
export function browserTimezone() {
  const guess = moment.tz.guess()
  if (guess && Object.values(Timezone).includes(guess)) {
    return guess
  }
  return Timezone.EASTERN
}

export function getNumDaysBetween(startDate, endDate) {
  const currDate = (typeof startDate === 'string'
    ? moment(startDate)
    : startDate.clone()
  ).startOf('day')
  const lastDate = (typeof endDate === 'string'
    ? moment(endDate)
    : endDate.clone()
  ).startOf('day')
  return lastDate.diff(currDate, 'day')
}

export function getDaysBetween(startDate, endDate, step = 'day') {
  const dates = []

  const shouldFormat = typeof startDate === 'string'

  const currDate = (typeof startDate === 'string'
    ? moment(startDate)
    : startDate.clone()
  ).startOf('day')
  const lastDate = (typeof endDate === 'string'
    ? moment(endDate)
    : endDate.clone()
  ).startOf('day')

  while (currDate.valueOf() <= lastDate.valueOf()) {
    const clone = currDate.clone()
    dates.push(shouldFormat ? clone.format('YYYY-MM-DD') : clone)
    currDate.add(1, step)
  }

  return dates
}

export async function maybeGetTzFromLatLon(latLon) {
  let maybeTimezone
  try {
    // This '$FlowFixMe:' is actually to fix the 'return maybeTimezone' below.
    // $FlowFixMe: We know that the type is a $Timezone since it appeared in the list of values.
    maybeTimezone = await api.getTimezoneFromLatLon(latLon)
  } catch (error) {
    // Don't log the 404 errors to Sentry since that error is returned when no results are found.
    if (error.status === 404) {
      console.log(error.status)
    } else {
      logError(error)
    }
    return null
  }

  if (!maybeTimezone) {
    return null
  }
  if (Object.values(Timezone).includes(maybeTimezone)) {
    return maybeTimezone
  }
  // If not, check if there's an aliased timezone (e.g. America/Detroit -> America/New_York)
  const canonicalTimezone = TimezoneAlias[maybeTimezone]
  if (canonicalTimezone) {
    return canonicalTimezone
  }
  return null
}

export function timeslotDateToHumanReadable(timeslot) {
  return moment(timeslot.date).format('dddd, MMMM D, YYYY')
}

export function maybeGetDisplayDateFromEventCampaign({
  hosted_event_range_start,
  hosted_event_range_end,
  hosted_event_default_date,
  hosted_event_default_start,
  hosted_event_default_end,
  hosted_event_default_timezone,
}) {
  if (hosted_event_range_start && hosted_event_range_end) {
    const startMoment = moment(hosted_event_range_start, moment.ISO_8601)
    const endMoment = moment(hosted_event_range_end, moment.ISO_8601)

    // If the year is the same, then include the year at the end, but not with both dates
    const isSameYear = areAllMomentsSameYear([startMoment, endMoment])
    if (isSameYear) {
      const formattedStartWithoutYear = startMoment.format('MMM D')
      const isSameMonth = startMoment.month() === endMoment.month()
      // If the months are the same, render the month and a date range
      if (isSameMonth) {
        return `${formattedStartWithoutYear}-${endMoment.format('D, YYYY')}`
      }
      // If the months are different, render the month with each
      return `${formattedStartWithoutYear} - ${endMoment.format(
        FULL_DATE_FORMAT
      )}`
    }

    // Otherwise, fully spell out both dates
    return `${startMoment.format(FULL_DATE_FORMAT)} - ${endMoment.format(
      FULL_DATE_FORMAT
    )}`
  }

  if (
    hosted_event_default_date &&
    hosted_event_default_start &&
    hosted_event_default_end &&
    hosted_event_default_timezone
  ) {
    const date = moment.tz(
      hosted_event_default_date,
      hosted_event_default_timezone
    )
    const monthDayYear = {
      day: date.day(),
      month: date.month(),
      year: date.year(),
    }
    // These are full timestamps but are not necessarily on the right day, so we have to parse them
    // as ISO-8601 timestamps, set them to the desired timezone, and then set them to the right day
    const startTime = moment(hosted_event_default_start, moment.ISO_8601)
      .tz(hosted_event_default_timezone)
      .set(monthDayYear)
    const endTime = moment(hosted_event_default_end, moment.ISO_8601)
      .tz(hosted_event_default_timezone)
      .set(monthDayYear)

    const startTimeFormat =
      startTime.format('a') !== endTime.format('a') ? 'h:mma' : 'h:mm'
    return `${date.format(FULL_DATE_FORMAT)} ${startTime.format(
      startTimeFormat
    )}-${endTime.format('h:mma z')}`
  }

  if (hosted_event_default_date) {
    return moment(hosted_event_default_date, moment.HTML5_FMT.DATE).format(
      FULL_DATE_FORMAT
    )
  }

  return null
}

function doMomentsHaveSameAmPmValues(m1, m2) {
  return m1.format('a') === m2.format('a')
}

export function getMinimalDateFormat(m) {
  const startIsInDifferentYear = !areAllMomentsSameYear([m, moment()])
  return `ddd M/D${startIsInDifferentYear ? '/YY' : ''}`
}

export function getMinimalTimeFormat(
  m,
  {includeAmPm = false, includeTimezone = false}
) {
  const maybeAmPm = includeAmPm ? 'a' : ''
  const maybeTimezone = includeTimezone ? ' z' : ''
  // Don't include minutes if the time is on the hour
  return `h${m.minute() !== 0 ? ':mm' : ''}${maybeAmPm}${maybeTimezone}`
}

const HALF_DAY = 1000 * 60 * 60 * 12

/**
 * Get a minimal representation of the time range between two moments.
 *
 * If `timezone` is passed as an option, then localize the moments to that timezone. Only include
 * the timezone in the output if it's not the same as the browser timezone.
 */
export function formatMinimalMomentRange(
  startMoment,
  endMoment,
  {onlyTimes, timezone} = {}
) {
  // moment#tz mutates itself, so clone it so as to not modify the function argument
  const startMomentLocalized = timezone
    ? startMoment.clone().tz(timezone)
    : startMoment
  const endMomentLocalized = timezone
    ? endMoment.clone().tz(timezone)
    : endMoment

  const startDateFormat = getMinimalDateFormat(startMoment)
  const endDateFormat = getMinimalDateFormat(endMoment)

  // We grab the longer of the two. We want dates to be consistent in look.
  // So 12/20/19 - 3/20/20 shouldn't look like 12/20/19 - 3/20
  const dateFormatForBoth =
    startDateFormat > endDateFormat ? startDateFormat : endDateFormat

  const startTimeFormat = getMinimalTimeFormat(startMomentLocalized, {
    // Only include "am/pm" in the start time if the start and end have different am/pm values (it's
    // redundant otherwise, since we state it at the end of the time)
    includeAmPm: !doMomentsHaveSameAmPmValues(startMomentLocalized, endMoment),
  })
  const endTimeFormat = getMinimalTimeFormat(endMomentLocalized, {
    includeAmPm: true,
    includeTimezone: !!timezone && moment.tz.guess() !== timezone,
  })
  const startDate = startMomentLocalized.format(dateFormatForBoth)
  const endDate = endMomentLocalized.format(dateFormatForBoth)

  // If dates aren't the same and the range is longer than 12 hours,
  // then we just show the dates.
  if (
    !onlyTimes &&
    startDate !== endDate &&
    endMoment - startMoment >= HALF_DAY
  ) {
    return `${startDate} – ${endDate}`
  }

  const startTime = startMomentLocalized.format(startTimeFormat)
  const endTime = endMomentLocalized.format(endTimeFormat)

  if (onlyTimes) {
    return `${startTime} – ${endTime}`
  }

  return `${startDate}, ${startTime} – ${endTime}`
}

export function timeToTimezoneFormattedTime(time, timezone) {
  return moment(time).tz(timezone).format('HH:mm')
}

export function maybeTimeToTimezoneFormattedTime(time, timezone) {
  if (!time || !timezone) {
    return null
  }
  return timeToTimezoneFormattedTime(time, timezone)
}

// Given a time in HH:mm format, convert it to a full datetime with the current date (used in edge
// cases when we want to save something in the backend as a datetime but only care about the hour/
// minute fields)
export function maybeHourMinTimeToDateTime(time) {
  if (!time) {
    return null
  }
  const date = moment().format('YYYY-MM-DD')
  return `${date}T${time}`
}

export function iso8601ToMomentInTz(timeString, timezone) {
  return moment(timeString, moment.ISO_8601).tz(timezone)
}

export function formatSingleStartTime(startTime, timezone) {
  const thisYear = moment().tz(timezone).year()
  const startMoment = iso8601ToMomentInTz(startTime, timezone)
  const format =
    thisYear === startMoment.year()
      ? 'ddd, MMM D @ h:mma z'
      : 'ddd, MMM D, YYYY @ h:mma z'
  return startMoment.format(format)
}

// TODO(jared) figure out how to move this (and other fns here) to time-util. moving this particular
// function over creates a circular dependency that breaks logError mocking in api.test.js
export function areAllMomentsSameYear(moments) {
  return moments.every((m) => m.year() === moments[0].year())
}

export const maybeMomentFromMaybeISOString = (s, {daysToAdd = 0} = {}) => {
  const maybeValidMoment = moment(s, moment.ISO_8601)
  if (!maybeValidMoment.isValid()) {
    return null
  }
  return maybeValidMoment.add(daysToAdd, 'days')
}

export function isoDateStr(date) {
  return moment(date).format().substring(0, 10)
}

export function humanReadableDate(date) {
  return moment(date).format('MMMM D, YYYY')
}

export function maybeHTML5DateFromISO(iso) {
  return iso ? moment(iso, moment.ISO_8601).format(moment.HTML5_FMT.DATE) : null
}

export function maybeEndOfDay(iso) {
  const momentDate = maybeMomentFromMaybeISOString(iso)
  if (momentDate) {
    return momentDate.endOf('day').toISOString()
  }
  return null
}

export const BEGINNING_OF_DAY_24H_TIME = '00:00'
export const END_OF_DAY_24H_TIME = '23:59'

export function ISOStringInNDays(n) {
  return moment().add(n, 'day').startOf('day').toISOString()
}
