import {memoize, range} from './common'

import {ANONYMOUS_ID_COOKIE_NAME} from 'app/constants'
import analytics from 'analytics'
import cookies from 'browser-cookies'
import ksuid from 'ksuid'

function pseudoRandomByte() {
  return Math.floor(Math.random() * 256)
}

// Memoize so that even if a client doesn't accept cookies, anonymous id is stable per pageload
const generateAndSetAnonymousId = memoize(() => {
  const payload = range(16).map(pseudoRandomByte)
  // `Buffer` is a Node internal, but Webpack polyfills it for us. It might eventually be good to
  // probably have to polyfill with core-js for the client bundle.)
  const anonymousId = ksuid.fromParts(Date.now(), Buffer.from(payload)).string

  // Attempt to save it as a cookie from the client
  cookies.set(ANONYMOUS_ID_COOKIE_NAME, anonymousId, {
    expires: 365,
    samesite: 'None',
    // Should evaluate to true everywhere except dev
    secure: window.location.protocol === 'https:',
  })

  // Record an analytics event to keep track of how often we have to do this
  analytics.trackAnonymousIdGenerated(anonymousId)

  return anonymousId
})

export function getMobilizeAnonymousId() {
  // This is set at the edge (see `cloudflare/anonymous-id`), but when cookies are declined, get a
  // better default, and attempt to set it as a client-side cookie
  return cookies.get(ANONYMOUS_ID_COOKIE_NAME) || generateAndSetAnonymousId()
}
