import {
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core'

import FormItem from './FormItem'

export default function RadioGroup({
  hint,
  label,
  name,
  onChange,
  options,
  required,
  row,
  value,
}) {
  // This map is to get the original value type (e.g. boolean, number) which unfortunately always comes back
  // as a string from the onChange event.
  let optionsMap = {}
  options.forEach(({value}) => (optionsMap[String(value)] = value))

  function handleChange(evt) {
    const {name, value} = evt.currentTarget
    onChange(name, optionsMap[value || 'null'])
  }

  return (
    <FormItem label={label} hint={hint} required={required} type="radio">
      <MuiRadioGroup
        name={name}
        value={value}
        onChange={handleChange}
        row={row}
      >
        {options.map(({disabled, text, value}) => (
          <MuiFormControlLabel
            key={String(value)}
            label={text}
            value={value}
            disabled={disabled}
            control={<MuiRadio color="primary" required={required} />}
          />
        ))}
      </MuiRadioGroup>
    </FormItem>
  )
}
