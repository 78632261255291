import {Button, Message, TextArea, Typography} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'

import {useState} from 'react'

const messages = defineMessages({
  textareaPlaceholder: {
    defaultMessage: "Tell us what's wrong with this {reportedEntity}",
  },
})

function ContentReportComponent(props) {
  const [notes, setNotes] = useState(null)
  const [showReportForm, setShowReportForm] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const intl = useIntl()

  const submitContentReport = async () => {
    try {
      await props.onSubmit({notes})
      setShowReportForm(false)
    } catch (error) {
      setErrorMessage(
        error.message || (
          <F defaultMessage="There was an error submitting your report" />
        )
      )
    }
  }

  const {current_organization: org} = props.data
  const {reportedEntityDisplayName: reportedEntity} = props
  const orgName = org.name
  return (
    <>
      <Typography variant="h1">
        <F
          defaultMessage="Report {reportedEntity} as inappropriate"
          values={{reportedEntity}}
        />
      </Typography>
      {showReportForm ? (
        <>
          <Typography variant="body1">
            <F
              defaultMessage="Submit a report for {orgName} to review."
              values={{orgName}}
            />
          </Typography>
          <TextArea
            name="notes"
            label="Additional details (optional)"
            maxWidth="500px"
            value={notes || ''}
            onChange={(e) => setNotes(e.target.value)}
            placeholder={intl.formatMessage(messages.textareaPlaceholder, {
              reportedEntity,
            })}
          />
          <Button name="submit" onClick={submitContentReport}>
            Submit
          </Button>
          {errorMessage && <Message type="error">{errorMessage}</Message>}
        </>
      ) : (
        <Typography variant="body1">
          <F
            defaultMessage="Thanks for flagging the {reportedEntity} as inappropriate. We've sent this along to {orgName} to review."
            values={{reportedEntity, orgName}}
          />
        </Typography>
      )}
    </>
  )
}

export default ContentReportComponent
