import styles, {
  getPrimaryTextColorFromProps,
  getSecondaryBodyFontFromProps,
} from 'components/styles'

import styled from '@emotion/styled/macro'

const EventType = styled.span`
  ${getPrimaryTextColorFromProps};
  ${getSecondaryBodyFontFromProps};
  font-weight: ${styles.typography.fontWeightBold};
  text-transform: uppercase;
  ${(props) => props.leftPad && `padding-left: ${styles.space.s};`}
  font-size: ${styles.typography.fontSizeS};
  letter-spacing: 0.05rem;
`

export default EventType
