import {Button, Link, Typography} from 'components'
import {
  checkIsPromptForDonationHostedEvent,
  getEventCategoryName,
  getMailtoLink,
} from './util'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import CurrentUserContext from 'app/CurrentUserContext'
import DonationHostedEvent from './DonationHostedEvent'
import {HostFormInstructions} from './HostCreateEventComponents'
import {isDonationCampaignHostedEvent} from 'util/event'
import {isGroupEventCampaign} from 'util/eventCampaign'
import {useContext} from 'react'

function HostCreateEventInstructions({
  contactEmail,
  eventCampaign,
  eventId,
  hasLoggedInOnThisBrowser,
  hostedEvent,
  showLoginModal,
}) {
  const orgContext = useContext(CurrentOrganizationContext)
  const org = orgContext?.currentOrganization

  const userContext = useContext(CurrentUserContext)
  const user = userContext?.user

  const mailtoLink = org && getMailtoLink(contactEmail, eventCampaign, org)
  const isGroupCampaign = isGroupEventCampaign(eventCampaign)
  const isDonationCampaign = isDonationCampaignHostedEvent(hostedEvent)
  const eventCategoryName = getEventCategoryName(
    isDonationCampaign,
    isGroupCampaign
  )
  const isPromptForDonationHostedEvent = checkIsPromptForDonationHostedEvent(
    hostedEvent,
    isDonationCampaign
  )

  return (
    <>
      {isPromptForDonationHostedEvent && (
        <DonationHostedEvent
          contactEmail={contactEmail}
          hostedEvent={hostedEvent}
          isDonationCampaign={isDonationCampaign}
          mailtoLink={mailtoLink}
        />
      )}
      {eventCampaign.rendered_hosted_event_instructions_html ? (
        <HostFormInstructions data-testid="host_create_new_event_from_campaign_instructions">
          <Typography variant="body1">
            <span
              dangerouslySetInnerHTML={{
                __html: eventCampaign.rendered_hosted_event_instructions_html,
              }}
            />
          </Typography>
        </HostFormInstructions>
      ) : (
        !eventId && (
          <HostFormInstructions data-testid="host_create_new_event_instructions">
            <Typography variant="body1">
              This form will let you submit an event to {org?.name}. After it's
              approved, you'll be able to edit it, invite people, and track
              who's coming.
            </Typography>
          </HostFormInstructions>
        )
      )}
      {!isPromptForDonationHostedEvent && contactEmail && (
        <HostFormInstructions data-testid="host_create_event_contact_instructions">
          <Typography variant="body1">
            If you have any questions, contact{' '}
            <Link to={mailtoLink} target="_blank">
              {org?.name}
            </Link>
            .
          </Typography>
        </HostFormInstructions>
      )}

      {!user && hasLoggedInOnThisBrowser && (
        <HostFormInstructions>
          <Typography variant="body2">
            Save time in creating your {eventCategoryName} by{' '}
            <Button
              data-testid="host_create_event_login_button"
              onClick={() => showLoginModal()}
              link
              padding="none"
            >
              logging in first.
            </Button>
          </Typography>
        </HostFormInstructions>
      )}
    </>
  )
}

export default HostCreateEventInstructions
