import {Button, Icon, InputGroup, MaxWidthContainer} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {
  NativeShareState,
  getShareLink,
  maybeGetUserFirstName,
  nativeShareEvent,
} from 'util/share'
import {
  getActNowButtonProps,
  getPrimaryButtonProps,
  getStickyPostSignupText,
  getVirtualActionText,
  shouldShowActNowButton,
  useSignupButtonLabel,
} from './util'
import styles, {
  getPrimaryTextColorFromProps,
  getPrimaryTitleFontFromProps,
  mediaMinWidthSmall,
} from 'components/styles'
import {useContext, useState} from 'react'

import CurrentUserContext from 'app/CurrentUserContext'
import {ParticipationShortlinkSubtype} from 'app/enums'
import ShareModal from 'events/modals/ShareModal'
import analytics from 'analytics'
import {filterFalsy} from 'util/common'
import {getEventTypeAffinityChecks} from 'util/event'
import {getSubtypedShortlink} from 'util/routing'
import styled from '@emotion/styled/macro'

const ShiftSummary = styled.div`
  text-align: center;
  ${getPrimaryTextColorFromProps};
  ${getPrimaryTitleFontFromProps};
  font-weight: ${styles.typography.fontWeightBold};
  margin-bottom: ${styles.space.s};
`

const StickySignupButtonContainer = styled.div`
  ${mediaMinWidthSmall('display: none;')};
  position: fixed;
  transition: bottom 0.2s ease-in;
  ${(props) =>
    // 200px is pretty arbitrary; it's enough to move it off the screen in all cases I've seen
    `bottom: ${props.shouldDisplay ? 0 : '-200px'}`};
  left: 0;
  padding: ${styles.space.m};
  padding-bottom: ${(props) => (props.showBottomPadding ? styles.space.m : 0)};
  width: 100%;
  background-color: ${styles.colors.white};
  z-index: ${styles.zindex.navigation};
  box-shadow: 0px -3px 8px ${styles.colors.transparentGrey};
`

const i18nMessages = defineMessages({
  donateSignup: {defaultMessage: 'Donate and sign up'},
  pickTime: {defaultMessage: 'Pick a time'},
})

export default function Sticky({
  currentVolunteer,
  event,
  expandActLater,
  identityFields,
  initialQueryParams,
  onActLaterClick,
  onShiftsChange,
  organization,
  participationShortlink,
  shareParamsFromSignup: shareParamsPartial,
  shifts,
  shouldDisplay,
  signupCreatedWithinLastFiveSeconds,
  signupInFlightOrCreated,
  isGroupAndAlreadyJoined,
}) {
  /* don't open this share modal by default unless this is from an invite */
  const [shareModalOpen, setShareModalOpen] = useState(
    getEntryPointWasChainedInvite(initialQueryParams)
  )

  const {user} = useContext(CurrentUserContext)

  const primaryButtonProps = getPrimaryButtonProps(
    signupInFlightOrCreated,
    isGroupAndAlreadyJoined
  )
  const actNowButtonProps = getActNowButtonProps(
    event,
    signupCreatedWithinLastFiveSeconds,
    signupInFlightOrCreated,
    isGroupAndAlreadyJoined,
    onShiftsChange
  )
  const virtualActionText = getVirtualActionText(
    event,
    signupCreatedWithinLastFiveSeconds,
    signupInFlightOrCreated,
    isGroupAndAlreadyJoined
  )
  const intl = useIntl()

  const signedUpText = getStickyPostSignupText(event, shifts)
  const showActNowButton = shouldShowActNowButton(event, expandActLater)

  const {isDonationCampaign, isPromptForDonation} = getEventTypeAffinityChecks(
    event
  )

  const stickySignupButtonProps = {
    ...(showActNowButton ? actNowButtonProps : primaryButtonProps),
    onClick: (e) => {
      analytics.trackButtonClicked('sticky-signup')

      if (showActNowButton) {
        actNowButtonProps.onClick(e)
      }

      const isPickATime = event.is_virtual_flexible && !showActNowButton
      if (isPickATime && !!onActLaterClick) {
        onActLaterClick()
      }

      // If we appear to be missing some data, if this button is virtual flexible,
      // or if there is not exactly one shift selected
      // (which means one of several things: this is a single-timeslot event, this is a donation
      // campaign, this is an act now shift, or the user scrolled down, selected a timeslot, and
      // then scrolled back up), then scroll the signup card into view and don't try to submit
      // the form.
      const {firstName, lastName, email, phone, zip} = identityFields
      if (
        ![firstName, lastName, email, phone, zip].every(Boolean) ||
        (!!shifts && filterFalsy(shifts).length !== 1) ||
        isDonationCampaign ||
        isPromptForDonation ||
        isPickATime
      ) {
        e.target.closest('form').scrollIntoView({
          // ScrollIntoViewOptions are unsupported on IE and Safari, but will still scroll the
          // element into view (just with less finesse). See
          // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
          behavior: 'smooth',
        })
        e.preventDefault()
      }
    },
  }

  function getNativeShareUrl() {
    if (participationShortlink) {
      // If the user has signed up (and thus created a participation-scoped shortlink), use that
      // (with the EVENT_DETAILS_NATIVE_SHARE subtype)
      return getSubtypedShortlink(
        participationShortlink,
        ParticipationShortlinkSubtype.EVENT_DETAILS_NATIVE_SHARE
      ).toString()
    }

    // Otherwise, use the event-scoped native share shortlink, falling back to a long generated url
    // if for whatever reason that's null.
    const shareParamsPartial = {
      share_medium: 'native_share',
      share_context: 'event_details',
    }
    return (
      event.event_details_native_share_shortlink ||
      getShareLink({
        currentVolunteer,
        event,
        initialQueryParams,
        organization,
        shareParamsPartial,
        user,
      })
    )
  }

  function closeShareModal() {
    setShareModalOpen(false)
  }

  async function onRequestShare(context) {
    const nativeShareResult = await nativeShareEvent(
      getNativeShareUrl(),
      event,
      context
    )
    if (nativeShareResult === NativeShareState.UNSUPPORTED) {
      // Default behavior / fallback if the browser doesn't support native sharing
      setShareModalOpen(true)
    }
  }

  const shareLink = getShareLink({
    currentVolunteer,
    event,
    initialQueryParams,
    organization,
    shareParamsPartial,
    user,
  })
  const maybeUserFirstName = maybeGetUserFirstName(currentVolunteer, user)

  const scheduledEventSignupCopy = useSignupButtonLabel({
    event,
    isFeed: false,
    signedUp: false, // this button never actually submits a signup for scheduled events
    signupIdentityFields: identityFields,
  })

  const buttonText = isPromptForDonation
    ? intl.formatMessage(i18nMessages.donateSignup)
    : event.is_virtual_flexible
    ? showActNowButton
      ? virtualActionText
      : intl.formatMessage(i18nMessages.pickTime)
    : scheduledEventSignupCopy

  return (
    <>
      <StickySignupButtonContainer
        shouldDisplay={shouldDisplay}
        // A bit janky, but only add bottom padding in this case, since in the other case, the
        // InputGroup wrapping the two buttons adds bottom padding
        showBottomPadding={signupInFlightOrCreated}
      >
        <MaxWidthContainer noGutters>
          {signupInFlightOrCreated ? (
            <>
              <ShiftSummary>
                <Icon name="check" /> {signedUpText}
              </ShiftSummary>
              <Button
                {...primaryButtonProps}
                disabled={false}
                type="button"
                onClick={() => {
                  const context = 'sticky-share-primary'
                  analytics.trackButtonClicked(context)
                  onRequestShare(context)
                }}
                data-testid="sticky-share-primary"
              >
                <F defaultMessage="Share" />
              </Button>
            </>
          ) : (
            <InputGroup>
              <Button
                secondary
                type="button"
                onClick={() => {
                  const context = 'sticky-share-secondary'
                  analytics.trackButtonClicked(context)
                  onRequestShare(context)
                }}
                data-testid="sticky-share-secondary"
              >
                <F defaultMessage="Share" />
              </Button>
              <Button
                {...stickySignupButtonProps}
                data-testid="sticky-signup-button"
              >
                {buttonText}
              </Button>
            </InputGroup>
          )}
        </MaxWidthContainer>
      </StickySignupButtonContainer>

      <ShareModal
        onRequestClose={closeShareModal}
        isOpen={shareModalOpen}
        shareLink={shareLink}
        linkForCopy={participationShortlink}
        event={event}
        userFirstName={maybeUserFirstName}
        modalType="event-detail-page"
        entryPointWasChainedInvite={getEntryPointWasChainedInvite(
          initialQueryParams
        )}
      />
    </>
  )
}

function getEntryPointWasChainedInvite(initialQueryParams) {
  // NB: we'll set chained_invite_referring_user=<referrer user id> to trigger this
  return !!initialQueryParams.chained_invite_referring_user
}
