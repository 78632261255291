import {memoize, sample} from 'util/common'

import {FeedItem} from 'events/components'
import {getOrganizationDefaultEventCampaignCreateEventUrl} from 'util/routing'

// Memoize this so as to not flash different images on re-renders
const memoizedSample = memoize(sample)

const HostEventFeedItem = ({organization, eventCampaign}) => (
  <FeedItem
    linkTo={getOrganizationDefaultEventCampaignCreateEventUrl(organization)}
    headline="Host an event!"
    aria-label="Host an event"
    subhead="Pick a location near you"
    buttonText="Get started"
    imageUrl={memoizedSample(eventCampaign.hosted_event_images)}
  />
)

export default HostEventFeedItem
