import {Button, Icon, Typography} from 'components'

import {Component} from 'react'
import {getOrganizationDashboardEventsUrl} from 'util/routing'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import withScreenData from 'app/withScreenData'

const FlexCenterWrapper = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  margin-top: ${styles.space.xl};
  margin-bottom: ${styles.space.xl};
`

const BodyTextWithMarginBottom = styled.div`
  margin-bottom: 25px;
`

class HostEventVerificationSuccessScreen extends Component {
  onClickVisitDashboard = () => {
    const organization = this.props.data.current_organization

    this.props.history.push(
      getOrganizationDashboardEventsUrl(organization).pathname
    )
  }

  render() {
    const {current_organization} = this.props.data

    return (
      <FlexCenterWrapper>
        <Icon name="check" success huge />
        <Typography variant="h2">Your event is verified!</Typography>

        <BodyTextWithMarginBottom>
          {current_organization.name} may have to review and modify your event
          before it is approved. For now, you can log in to edit and check the
          status of your event. You'll receive an email notification as soon as
          it goes live.{' '}
        </BodyTextWithMarginBottom>

        <Button onClick={this.onClickVisitDashboard}>
          Log in to your dashboard
        </Button>
      </FlexCenterWrapper>
    )
  }
}

export default withScreenData(HostEventVerificationSuccessScreen)
