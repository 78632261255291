import {LinearProgress} from '@material-ui/core'

export default function ProgressBar({value, max}) {
  // If max is not passed in, assume value is a percentage between 0 and 100.
  // If max is <= 0, something is wrong. Set value to 0 to avoid dividing by 0.
  // If max is preset, calculate what percentage of max the provided value is.
  const percentValue = max ? (max > 0 ? (value / max) * 100 : 0) : value

  return <LinearProgress variant="determinate" value={percentValue} />
}
