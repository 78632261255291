import {Button, Grid, Link, MaxWidthContainer, Typography} from 'components'
import {F, useIntl} from 'util/i18n'

import cv from 'util/clientVars'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useOrgCreationLinks} from 'hooks/navigation'

const GridWrapper = styled.div`
  margin-top: ${styles.space.m};
  margin-bottom: ${styles.space.xl};
`

export default function OrgCreationPrompt({organization}) {
  const intl = useIntl()
  const orgCreationLinks = useOrgCreationLinks()
  if (!organization.is_mobilize) {
    return null
  }
  return (
    <MaxWidthContainer>
      <Typography variant="h2">
        <F
          defaultMessage="Part of a mission-driven organization that wants to host events on {productName}?"
          values={{productName: cv.product_name}}
        />
      </Typography>
      {/* TODO(jared) this grid and wrapper is lifted directly from LandingPageList.js; it could
          eventually be worth standardizing, especially if this component is here to stay */}
      <GridWrapper>
        <Grid container spacing={1}>
          {orgCreationLinks.map((link) => (
            <Grid item xs={12} sm={6} md={4} key={link.url.toString()}>
              <Link to={link.url} plain>
                <Button secondary fluid>
                  {intl.formatMessage(link.label)}
                </Button>
              </Link>
            </Grid>
          ))}
        </Grid>
      </GridWrapper>
    </MaxWidthContainer>
  )
}
