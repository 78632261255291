import DefaultThemeProvider from 'app/DefaultThemeProvider'
import {FacebookButton} from 'components'
import {FacebookLoginMessageType} from 'app/enums'
import {Global} from '@emotion/react'
import api from 'data/api'
import {css} from '@emotion/react'
import {getQueryParams} from 'util/url'
import {partial} from 'util/common'
import {useState} from 'react'
import withScreenData from 'app/withScreenData'

const globalStyles = css`
  body {
    min-width: 0;
  }
`

function postMessage(origin, id, type, data = null) {
  window.parent.postMessage({type, id, data}, origin)
}

function FacebookLoginIframeScreen(props) {
  const [loading, setLoading] = useState(false)
  const {location, data} = props
  const {parent_origin} = data
  // See FacebookLoginButton: we include an id for postMessage destinations so as to disambiguate
  // which button handles the message
  const id = getQueryParams(location.search).id

  const postMessageToParentWithId = partial(postMessage, parent_origin, id)

  return (
    <DefaultThemeProvider>
      <Global styles={globalStyles} />
      <FacebookButton
        fluid
        loading={loading}
        onClick={() => {
          setLoading(true)
          postMessageToParentWithId(FacebookLoginMessageType.INITIATED)

          // TODO(jared) it would be great to dedupe some of this code w/ FacebookLoginButton
          window.FB.login(
            async (response) => {
              const {authResponse, status} = response
              if (status === 'connected') {
                let data
                try {
                  data = await api.getTokenFromFacebookDetails(
                    authResponse.userID,
                    authResponse.accessToken,
                    new URL(parent_origin).host // Just the hostname
                  )
                } catch (e) {
                  setLoading(false)
                  postMessageToParentWithId(FacebookLoginMessageType.ERROR, {
                    message: e.message,
                  })
                  return
                }
                setLoading(false)
                postMessageToParentWithId(
                  FacebookLoginMessageType.SUCCESS,
                  data
                )
              } else if (status === undefined) {
                // User cancelled the OAuth flow
                setLoading(false)
              }
            },
            {scope: 'public_profile,email'}
          )
        }}
      >
        Continue with Facebook
      </FacebookButton>
    </DefaultThemeProvider>
  )
}

export default withScreenData(FacebookLoginIframeScreen)
