import {Timezone, UsTimezone, UsTimezoneToString} from 'app/enums'

import Select from './Select'
import moment from 'vendor/moment'
import {useMemo} from 'react'

export default function TimezoneSelector({
  disabled,
  fluid,
  onChange,
  selectedTimezone,
}) {
  const memoizedDropdownOptions = useMemo(() => {
    const primaryTimezones = Object.values(Timezone)
    const primaryTimezonesSet = new Set(primaryTimezones)
    const allUsTimezonesWithoutPrimary = Object.values(UsTimezone).filter(
      (tz) => !primaryTimezonesSet.has(tz)
    )

    return primaryTimezones
      .concat(allUsTimezonesWithoutPrimary)
      .map((tz, index) => ({
        text: `(GMT${moment()
          .tz(tz)
          // $FlowFixMe - Flow thinks tz is mixed even though we know it is a string
          .format('Z')}) ${UsTimezoneToString[tz]}`,
        // $FlowFixMe - Flow thinks tz is mixed even though we know it is a string
        value: tz,
      }))
  }, [])

  return (
    <Select
      name="timezone"
      label="Timezone"
      value={selectedTimezone || ''}
      onChange={onChange}
      options={memoizedDropdownOptions}
      disabled={disabled}
    />
  )
}
