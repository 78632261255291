import * as React from 'react'

import {AttendanceStatus, ParticipationStatus} from 'app/enums'
import {
  Button,
  Icon,
  Link,
  Loader,
  Table,
  Tooltip,
  Typography,
} from 'components'

import {CopyToClipboard} from 'react-copy-to-clipboard'
import {F} from 'util/i18n'
import {createMailUrl} from 'util/url'
import {getGroupSignupCounts} from '../../util/groupSignup'
import {makeStyles} from '@material-ui/core/styles'
import styled from '@emotion/styled/macro'
import styles from '../../components/styles'
import {useSnackbar} from 'notistack'

const ComponentWrapper = styled.div`
  display: inline-block;
  height: 18rem;
  max-height: 18rem;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const IconDimOnHover = styled.p`
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`
const TableWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding-bottom: 2rem;
`

const CancelledMemberWrapper = styled.span`
  font-style: italic;
  color: #888888;
`

const useStyles = makeStyles({
  table: {
    border: '0.1rem solid #dddddd',
    borderRadius: '0.25rem',
  },
})

function UndoCancelButton({
  updateFunction,
  participationId,
  isWithinTwoDaysOfStart,
  groupIsFull = false,
}) {
  return (
    <Tooltip
      title={
        groupIsFull
          ? 'This group is at capacity. If you wish to add the user to your group increase the reservation size.'
          : ''
      }
    >
      <Button
        secondary
        type="button"
        onClick={() =>
          updateFunction(
            participationId,
            isWithinTwoDaysOfStart
              ? ParticipationStatus.CONFIRMED
              : ParticipationStatus.REGISTERED
          )
        }
        disabled={groupIsFull}
      >
        {' '}
        {isWithinTwoDaysOfStart ? (
          <F defaultMessage="Confirm" />
        ) : (
          <F defaultMessage="Sign Up" />
        )}
      </Button>
    </Tooltip>
  )
}

export default function GroupSignupMembersList({
  participation,
  groupLeaderEmail,
  shareLink,
  updateGroupLeaderStatus,
  updateGroupMemberStatus,
  loading,
}) {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const {
    numUnclaimedGroupSpots,
    numAdditionalGroupSpotsPlusLeaderIfNotCancelled,
    numClaimedGroupSpotsPlusLeaderIfNotCancelled,
  } = getGroupSignupCounts(participation)

  const sortAlphabetically = (a, b) => {
    if (a.first_name < b.first_name) {
      return -1
    }
    if (a.first_name > b.first_name) {
      return 1
    }
    if (a.last_name < b.last_name) {
      return -1
    }
    if (a.last_name > b.last_name) {
      return 1
    }
    return 0
  }

  const maybeUpdateGroupMemberStatus = async (
    memberParticipationId,
    status
  ) => {
    await updateGroupMemberStatus(memberParticipationId, status)
    // Snackbar happens too fast to catch the updated state after the API call,
    // so we've got to do the subtraction here
    const groupSizeWithUncancelledMember =
      numAdditionalGroupSpotsPlusLeaderIfNotCancelled + 1
    const groupSizeWithoutCancelledMember =
      numAdditionalGroupSpotsPlusLeaderIfNotCancelled - 1

    const snackbarMessage =
      status === ParticipationStatus.REGISTERED
        ? `Contact RSVP uncancelled. Your reservation size is now ${groupSizeWithUncancelledMember}.`
        : `Contact RSVP cancelled. Your reservation size is now ${groupSizeWithoutCancelledMember}. Cancelled members can be found at the bottom of your list.`
    snackbar.enqueueSnackbar(`${snackbarMessage}`, {
      variant: 'success',
    })
  }

  return loading ? (
    <Loader />
  ) : (
    <ComponentWrapper>
      <HeaderWrapper>
        <Typography variant="h2">
          <F defaultMessage="Members" />
        </Typography>

        {numUnclaimedGroupSpots > 0 && (
          <Tooltip title="Click to copy link!">
            <CopyToClipboard text={shareLink.toString()}>
              <IconDimOnHover>
                <Icon
                  name="link"
                  secondary
                  style={{color: styles.colors.primary200}}
                />
                <Link to="">copy invite link</Link>
              </IconDimOnHover>
            </CopyToClipboard>
          </Tooltip>
        )}
      </HeaderWrapper>
      <TableWrapper className={classes.table}>
        <Table.Table stickyHeader overflowVisible>
          <Table.Head>
            <Table.Row>
              <Table.Cell>
                Contacts ({numClaimedGroupSpotsPlusLeaderIfNotCancelled})
              </Table.Cell>
              <Table.Cell>RSVP</Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {participation.status_attendance_combined !==
            AttendanceStatus.CANCELLED ? (
              <Table.Row>
                <Table.Cell>
                  You (Group Leader) <br />
                  <Link
                    data-testid="group leader email"
                    to={createMailUrl(groupLeaderEmail)}
                    target="_blank"
                  >
                    {groupLeaderEmail}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    onClick={() => {
                      updateGroupLeaderStatus(
                        participation.id,
                        ParticipationStatus.CANCELLED
                      )
                    }}
                    secondary
                    type="button"
                  >
                    Cancel
                  </Button>
                </Table.Cell>
              </Table.Row>
            ) : null}
            {participation.group_members
              .filter(
                (group_member) =>
                  group_member.participation_status_attendance_combined !==
                  AttendanceStatus.CANCELLED
              )
              .sort(sortAlphabetically)
              .map((group_member) => {
                const isEmaillessMember = !group_member.email
                return (
                  <Table.Row key={group_member.id}>
                    <Table.Cell>
                      {group_member.first_name} {group_member.last_name} <br />
                      {!isEmaillessMember ? (
                        <Link
                          data-testid="group member email"
                          to={createMailUrl(group_member.email)}
                          target="_blank"
                        >
                          {group_member.email}
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        secondary
                        type="button"
                        onClick={() =>
                          maybeUpdateGroupMemberStatus(
                            group_member.participation_id,
                            ParticipationStatus.CANCELLED
                          )
                        }
                      >
                        {' '}
                        Cancel
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            {participation.status_attendance_combined ===
            AttendanceStatus.CANCELLED ? (
              <Table.Row>
                <Table.Cell>
                  <CancelledMemberWrapper>
                    You (Group Leader)
                  </CancelledMemberWrapper>
                </Table.Cell>
                <Table.Cell>
                  <UndoCancelButton
                    updateFunction={updateGroupLeaderStatus}
                    participationId={participation.id}
                    isWithinTwoDaysOfStart={
                      participation.is_within_two_days_of_start
                    }
                    groupIsFull={numUnclaimedGroupSpots === 0}
                  />
                </Table.Cell>
              </Table.Row>
            ) : null}
            {participation.group_members
              .filter(
                (group_member) =>
                  group_member.participation_status_attendance_combined ===
                  AttendanceStatus.CANCELLED
              )
              .sort(sortAlphabetically)
              .map((group_member) => {
                return (
                  <Table.Row key={group_member.id}>
                    <Table.Cell>
                      <CancelledMemberWrapper>
                        {group_member.first_name} {group_member.last_name}
                      </CancelledMemberWrapper>
                    </Table.Cell>
                    <Table.Cell>
                      <UndoCancelButton
                        updateFunction={updateGroupMemberStatus}
                        participationId={group_member.participation_id}
                        isWithinTwoDaysOfStart={
                          participation.is_within_two_days_of_start
                        }
                        groupIsFull={numUnclaimedGroupSpots === 0}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          </Table.Body>
        </Table.Table>
      </TableWrapper>
    </ComponentWrapper>
  )
}
