import Button, {ComboButton} from './Button'

import {ActionButtons} from './CardModal'
import {Popover as MaterialPopover} from '@material-ui/core'
import styled from '@emotion/styled/macro'
import styles from './styles'
import {useState} from 'react'
import {withStyles} from '@material-ui/core/styles'

const Popover = withStyles((theme) => ({
  paper: {
    overflow: 'visible',
    marginTop: styles.space.m,
  },
}))(MaterialPopover)

// TODO(mime): these styling is just temporary until we kill Semantic further.
const ContentWrapper = styled.div`
  min-width: 20rem;
  border: 1px solid ${styles.colors.neutral300};
  padding: ${styles.space.m};

  & [name='location_filter_type'],
  & .inline.field:not(:last-child),
  & [role='listbox']:not(:last-child),
  & input:not(:last-child),
  & label:not(:last-child) {
    margin-right: ${styles.space.s};
  }

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    top: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${styles.colors.neutral300};
  }

  &:after {
    top: -9px;
    border-bottom: 10px solid ${styles.colors.white};
  }
`

const PopupWrapper = styled.div`
  margin-right: ${styles.space.s};
  margin-bottom: ${styles.space.s};
`

export default function FilterPopup({
  children,
  currentValue,
  filterKeys,
  flowing,
  label,
  onApply,
  onClear,
  onUndo,
  position,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  function handleClear(evt) {
    evt.preventDefault()
    onClear(filterKeys)
  }

  function handlePopupOpen(evt) {
    setAnchorEl(evt.currentTarget)
    setOpen(true)
  }

  function handleClose(evt) {
    onUndo(filterKeys)
    setOpen(false)
  }

  function handleApply(evt) {
    onApply()
    setOpen(false)
  }

  const content = (
    <ContentWrapper>
      {children}
      <ActionButtons>
        <Button secondary onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleApply}>Apply</Button>
      </ActionButtons>
    </ContentWrapper>
  )

  const trigger = currentValue ? (
    <ComboButton>
      <Button active={open} onClick={handlePopupOpen}>
        {currentValue}
      </Button>
      <Button icon="times" onClick={handleClear} />
    </ComboButton>
  ) : (
    <Button icon="sort-down" secondary onClick={handlePopupOpen}>
      {label}
    </Button>
  )

  return (
    <PopupWrapper>
      {trigger}
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        position={position || 'bottom'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {content}
      </Popover>
    </PopupWrapper>
  )
}
