import {Button, Link, Typography} from 'components'
import {isDonationCampaignHostedEvent, isGroupHostedEvent} from 'util/event'

import CoHostRow from './CoHostRow'
import {F} from 'util/i18n'
import {partial} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const HostFormHeadingWrapper = styled.div`
  margin-bottom: ${styles.space.m};
  margin-top: ${styles.space.xl};
`

export default function HostEditCoHosts({
  hostedEvent,
  creatingEvent,
  eventPermissions,
  onCoHostsChange,
}) {
  const {co_hosts: coHosts} = hostedEvent
  const addCoHostRow = () => {
    onCoHostsChange([...coHosts, {first_name: '', last_name: '', email: ''}])
  }
  const onCoHostChange = (idx, coHost) => {
    const newCoHosts = [...coHosts]
    newCoHosts[idx] = coHost
    onCoHostsChange(newCoHosts)
  }
  const onRemoveCoHost = (idx) => {
    const newCoHosts = [...coHosts]
    newCoHosts.splice(idx, 1)
    onCoHostsChange(newCoHosts)
  }

  const canEdit = creatingEvent
    ? true // event creator can always add co-hosts
    : !!eventPermissions?.can_edit_event_co_hosts

  const isDonationCampaign = isDonationCampaignHostedEvent(hostedEvent)
  const isGroup = isGroupHostedEvent(hostedEvent)

  return (
    <>
      <HostFormHeadingWrapper>
        <Typography variant="h2">
          {canEdit ? (
            isDonationCampaign ? (
              <F defaultMessage="Who else is hosting this fundraiser with you?" />
            ) : isGroup ? (
              <F defaultMessage="Who else is leading this group with you?" />
            ) : (
              <F defaultMessage="Who else is hosting this event with you?" />
            )
          ) : (
            <F defaultMessage="Co-hosts" />
          )}
        </Typography>
      </HostFormHeadingWrapper>
      <Typography variant="body1">
        {isDonationCampaign ? (
          <F defaultMessage="Co-hosts can manage donors, view and download signups, and edit this fundraiser." />
        ) : isGroup ? (
          <F defaultMessage="Co-hosts can manage group members, view and download signups, and edit this group." />
        ) : (
          <F defaultMessage="Co-hosts can manage attendees, view and download signups, and edit this event." />
        )}{' '}
        <Link target="_blank" to="https://mblz.io/co-host">
          <F defaultMessage="Learn more" />
        </Link>
      </Typography>

      {coHosts.map((coHost, idx) => (
        <CoHostRow
          key={idx}
          coHost={coHost}
          onCoHostChange={partial(onCoHostChange, idx)}
          onRemove={partial(onRemoveCoHost, idx)}
          canEdit={canEdit}
        />
      ))}

      {canEdit && (
        <Button
          secondary
          type="button"
          icon="plus"
          iconPosition="left"
          onClick={addCoHostRow}
        >
          <F defaultMessage="Add a co-host" />
        </Button>
      )}
    </>
  )
}
