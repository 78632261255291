import {Button, Typography} from 'components'

import ConnectedAccountSettingsCard from './ConnectedAccountSettingsCard'
import NotificationsUserSettingsCard from './NotificationsUserSettingsCard'
import SettingsCard from './SettingsCard'
import UserDetailsSettingsCard from './UserDetailsSettingsCard'
import api from 'data/api'
import {getLoginPathname} from 'util/routing'
import {withRouter} from 'react-router'
import withScreenData from 'app/withScreenData'

function UserSettingsScreen({data, history, initialQueryParams}) {
  const {user} = data

  return (
    <>
      <Typography variant="h1">Your Account</Typography>
      <UserDetailsSettingsCard initialUser={user} />
      <ConnectedAccountSettingsCard
        facebook_associated_at={user.facebook_associated_at}
        google_associated_at={user.google_associated_at}
      />
      {user.email_notification_settings && (
        <NotificationsUserSettingsCard
          email_notification_settings={user.email_notification_settings}
          // Because we remove them on mount (see the queryParamsToRemove option passed to
          // withScreenData), pass the initial query param values along so that this component can
          // render a message if a setting was toggled.
          initialQueryParams={initialQueryParams}
        />
      )}
      <SettingsCard
        header="Log Out"
        footer="Make sure you have access to the email associated with your account if you would like to log back in."
      >
        <Button
          link
          padding="none"
          onClick={async () => {
            await api.logoutCurrentUser()
            history.push(getLoginPathname())
          }}
        >
          Log out now
        </Button>
      </SettingsCard>
    </>
  )
}

export default withRouter(
  withScreenData(UserSettingsScreen, {
    // So that reloading the page doesn't reset a notification setting (processed by the server)
    queryParamsToRemove: ['meta', 'category', 'setting'],
  })
)
