import {Button, Link, Loader, Typography} from 'components'
import {getCityState, getLocationCopyForVirtualEvents} from 'util/geo'
import styles, {mediaMinWidthSmall} from 'components/styles'

import {Carousel} from 'events/components'
import {CarouselCategory} from 'app/enums'
import {F} from 'util/i18n'
import React from 'react'
import {css} from '@emotion/react'
import flag from 'experiments/flag'
import {formatEventTimesOneLine} from 'util/timeslot'
import {getOrganizationEventUrl} from 'util/routing'
import {getOrganizationFeedUrl} from 'util/routing'
import {isRegistrationOnlyEvent} from 'util/event'
import {logError} from 'util/common'
import styled from '@emotion/styled/macro'
import useFetchEvents from 'hooks/useFetchEvents'
import {useInView} from 'react-intersection-observer'

const Heading = styled.div`
  display: flex;
  a {
    margin-left: 1rem;
    padding-top: 0.3rem;
  }

  h2 {
    margin-bottom: 0.5rem;
  }
`

const LoaderContainer = styled.div`
  margin: 2.5rem auto;
`

const EventCardLight = styled(Link)`
  box-shadow: 5px 5px 8px ${styles.colors.transparentGrey};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 15rem;
  min-height: 15rem;
  margin-right: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  &:hover {
    text-decoration: none !important;
  }

  button {
    height: 48px;
  }

  ${mediaMinWidthSmall(css`
    flex-direction: row;
    min-height: unset;
  `)};
`

const Time = styled.p`
  font-weight: bold;
  color: ${styles.colors.neutral400};
  margin-bottom: 0;
  margin-top: 1rem;
`

const Location = styled.p`
  color: ${styles.colors.neutral400};
  font-weight: normal;
`

const GroupLinkedEvents = ({
  currentEvent,
  organization,
  shouldDelayLoad = true,
}) => {
  const [ref, inView] = useInView({
    rootMargin: '100px 0px',
    triggerOnce: true,
  })
  const config = {
    title: 'Upcoming events and actions',
    getFilterParamsPartial: ({currentEvent}) => {
      if (!currentEvent) {
        logError(
          'Tried to initialize a "group linked events" carousel without a currentEvent'
        )
        return null
      }
      return {
        suggestingGroups: [currentEvent.id],
        owningGroups: [currentEvent.id],
      }
    },
  }

  const maybeFilterParamsPartial = config.getFilterParamsPartial({
    currentEvent,
    organization,
  })

  const shouldFetchAndRender =
    (!shouldDelayLoad || inView) && !flag.isActive('kill_event_carousels')

  const {events, hasError, isLoading} = useFetchEvents(
    maybeFilterParamsPartial,
    organization,
    shouldFetchAndRender
  )

  let filteredEvents = events
  if (currentEvent && filteredEvents && filteredEvents.length) {
    filteredEvents = filteredEvents.filter((e) => e.id !== currentEvent.id)
  }

  const moreEventsUrl = getOrganizationFeedUrl(
    organization,
    maybeFilterParamsPartial
  )

  const getLocation = (event) => {
    const cityState = getCityState(event).trim()
    const location = getLocationCopyForVirtualEvents(event)

    return !event ||
      isRegistrationOnlyEvent(event) ? null : event.is_virtual ? (
      <F defaultMessage="Virtual · {location}" values={{location}} />
    ) : (
      cityState
    )
  }

  if (!isLoading && (hasError || !filteredEvents.length)) {
    return <div ref={shouldDelayLoad ? ref : null} />
  }

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  return (
    <div ref={shouldDelayLoad ? ref : null}>
      <Heading>
        <Typography variant="h2">
          <F defaultMessage={config.title} />
        </Typography>
        {filteredEvents.length > 3 && <Link to={moreEventsUrl}>See all</Link>}
      </Heading>
      {window.innerWidth > styles.limits.breakpointSmall ? (
        <>
          {filteredEvents.map((event, i) => {
            if (i > 2) return null

            return (
              <EventCardLight
                key={event.id}
                to={getOrganizationEventUrl(organization, event)}
              >
                <div>
                  <Typography variant="h3">{event.name}</Typography>
                  <Time>{formatEventTimesOneLine(event, false)}</Time>
                  <Location>{getLocation(event)}</Location>
                </div>
                <Button secondary>See Details</Button>
              </EventCardLight>
            )
          })}
        </>
      ) : (
        <Carousel
          carouselId={CarouselCategory.GROUP_LINKED_EVENTS}
          items={events.slice(0, 3)}
          itemKeyFn={(item) => item.id}
          isLoading={isLoading}
          itemRenderFn={(item) => (
            <EventCardLight
              key={item.id}
              to={getOrganizationEventUrl(organization, item)}
            >
              <div>
                <Typography variant="h3">{item.name}</Typography>
                <Time>{formatEventTimesOneLine(item, false)}</Time>
                <Location>{getLocation(item)}</Location>
              </div>
              <Button secondary>See Details</Button>
            </EventCardLight>
          )}
        />
      )}
    </div>
  )
}

export default GroupLinkedEvents
