import styled from '@emotion/styled/macro'
import styles from './styles'

export const AVATAR_SIZE = '43px'

/**
 * An avatar for a user. For now just centers text (initials) vertically and horizontally, but
 * eventually we could use this to display an optional profile pic.
 */
const Avatar = styled.div`
  height: ${AVATAR_SIZE};
  width: ${AVATAR_SIZE};
  line-height: ${AVATAR_SIZE};
  border-radius: 50%;
  font-size: ${styles.typography.fontSizeBase};
  text-align: center;
  background-color: ${styles.colors.neutral200};
  font-weight: ${styles.typography.fontWeightSemibold};
`

/** @component */
export default Avatar
