import {AuthProvider, AuthProviderToDisplayName} from 'app/enums'

import {Button} from 'components'
import api from 'data/api'

/**
 * Rudimentary implementation of an auth provider disconnect button. Needs to be shown/hidden by a parent that
 * has knowledge of whether the user's account is connected with the relevant provider or not.
 */
export default function DisconnectAuthProviderButton({
  onComplete,
  onError,
  authProvider,
}) {
  return (
    <Button
      secondary
      icon="times"
      onClick={async () => {
        try {
          const apiMethod =
            authProvider === AuthProvider.FACEBOOK
              ? api.disconnectUserFacebook
              : api.disconnectUserGoogle
          await apiMethod()
          onComplete()
        } catch (e) {
          if (typeof onError === 'function') {
            onError(e)
          }
        }
      }}
    >
      Disconnect {AuthProviderToDisplayName[authProvider]}
    </Button>
  )
}
