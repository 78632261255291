import {CheckboxField, DatePicker, Input, RadioGroup, Select} from 'components'

import {UsStatesAndTerritories} from 'app/enums'
import {pick} from 'util/common'

const FORM_TYPE_TO_REACT_ELEMENT = {
  checkbox: CheckboxField,
  date: DatePicker,
  international_phone: Input, // XXX: not finished wiring up
  radios: RadioGroup,
  select: Select,
  textfield: Input,
}

export default function MobilizeOnlineActionInput({
  formMetadata,
  input,
  onChange,
  value: formValue,
}) {
  function getOptionsListForStateProvinces(rawOptions) {
    const fromDefinitionUsOptions = rawOptions.map(
      (el) => el.group === 'US' && el.value
    )
    const includedStates = pick(UsStatesAndTerritories, fromDefinitionUsOptions)

    return Object.entries(includedStates).map(([abbreviation, name]) => ({
      /* $FlowFixMe Object.entries thinks values are mixed, but it's a string*/
      display: name,
      value: abbreviation,
    }))
  }

  function getOptionsList() {
    const optionsSource = input.options

    // If the source is a string, the definition is in the metadata.
    if (typeof optionsSource === 'string') {
      const rawOptions = formMetadata.options[optionsSource]

      if (optionsSource === 'table_stateprovinces') {
        // Special handling in order to filter out options that are not
        // supported by Mobilize.
        return getOptionsListForStateProvinces(rawOptions)
      }
      return rawOptions
    }

    return optionsSource
  }

  if (input.type === 'markup') {
    // We don't support this option for now because OnlineAction Forms allow
    // users to enter scripts and this is a potential XSS vulnerability.
    return null
  }

  if (input.type === 'hidden') {
    return <input type="hidden" name={input.name} value={input.value} />
  }

  if (input.type === 'date') {
    return (
      <DatePicker
        label={input.title}
        onChange={(value) => onChange(input.name, value)}
        required={input.required}
        value={formValue || ''}
        disableToolbar={false}
        shouldReturnHTML5Date // EA's API errors on ISO8601 dates.
      />
    )
  }

  const Element = FORM_TYPE_TO_REACT_ELEMENT[input.type]
  if (!Element) {
    return null
  }

  // NB: There are fields like 'PersonalUrl' that are empty basically.
  // Not sure why.
  if (input.type === 'textfield' && !input.title) {
    return null
  }

  const optionsList = getOptionsList()
  const options = optionsList
    ? optionsList.map(({display, value, val}) => ({
        text: display ?? val,
        value: value ?? val,
      }))
    : undefined

  // TODO(mime): christ, gotta consolidate these different signatures on select/input/radios :-/
  function handleSelectChange(evt, {name, value}) {
    onChange(name, value)
  }

  function handleCheckboxChange(evt) {
    const {name, value} = evt
    onChange(name, value)
  }

  function handleInputChange(evt) {
    const {name, value} = evt.target
    onChange(name, value)
  }

  function handleRadioChange(name, value) {
    onChange(name, value)
  }

  // This mapping allows us to map the change onChange event handlers for each
  // ActionTag field type to our form fields. This mapping handles most types
  // but is not comprehensive. We default to handleInputChange() for types
  // that are not included below.
  const handleTypeMap = {
    checkbox: handleCheckboxChange,
    select: handleSelectChange,
    radios: handleRadioChange,
    textfield: handleInputChange,
  }

  return (
    // $FlowFixMe: options and row are not valid props for all input types.
    <Element
      name={input.name}
      label={input.title}
      maxLength={input.maxlength}
      onChange={handleTypeMap[input.type] || handleInputChange}
      options={options}
      required={input.required}
      row={input.type === 'radios' ? true : undefined}
      value={formValue || ''}
    />
  )
}
