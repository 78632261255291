import {F, createIntl, defineMessages, useIntl} from 'util/i18n'
import {Icon, Input, Link, Select, TextArea} from 'components'
import {
  createFbShareUrl,
  createTweetUrl,
  isFbShareUrl,
  isTweetUrl,
  parseSocialShareUrl,
} from 'util/share'

import {VirtualActionSocialShareType} from 'app/enums'
import {isValidURL} from 'util/url'
import {useState} from 'react'

export const inferSelectedSocialOption = (url) => {
  if (url && isValidURL(url)) {
    const actionUrl = new URL(url.includes(':') ? url : `https://${url}`)
    if (isTweetUrl(actionUrl)) {
      return VirtualActionSocialShareType.TWITTER_ONLY
    } else if (isFbShareUrl(actionUrl)) {
      return VirtualActionSocialShareType.FACEBOOK_ONLY
    } else {
      return null
    }
  }
}

const inferSocialShareUrlParams = (url) => {
  if (url && isValidURL(url)) {
    const actionUrl = new URL(url.includes(':') ? url : `https://${url}`)
    return parseSocialShareUrl(actionUrl)
  } else {
    return {link: null, text: null}
  }
}

const messages = defineMessages({
  socialShareActionButtonText: {
    defaultMessage: 'Share post on {platform}',
  },
  socialPostContentHelpTextFacebook: {
    defaultMessage:
      'This will show up as block quote text above the share link. Emoji are not supported.',
  },
  socialPostContentHelpTextTwitter: {
    defaultMessage: 'Include relevant @usernames and hashtags.',
  },
})

export const getButtonTextForSocialShareType = (shareType) => {
  const intl = createIntl()
  if (shareType === VirtualActionSocialShareType.FACEBOOK_ONLY) {
    return intl.formatMessage(messages.socialShareActionButtonText, {
      platform: 'Facebook',
    })
  } else if (shareType === VirtualActionSocialShareType.TWITTER_ONLY) {
    return intl.formatMessage(messages.socialShareActionButtonText, {
      platform: 'Twitter',
    })
  } else {
    return null
  }
}

export default function VirtualActionSocialShareSettings({
  virtualActionUrl,
  updateFields,
}) {
  const [selectedShareType, setSelectedShareType] = useState(
    inferSelectedSocialOption(virtualActionUrl)
  )

  const intl = useIntl()
  const {link, text} = inferSocialShareUrlParams(virtualActionUrl)
  const [socialPostContent, setSocialPostContent] = useState(text)
  const [socialShareUrl, setSocialShareUrl] = useState(link)

  const isFbSelected =
    selectedShareType === VirtualActionSocialShareType.FACEBOOK_ONLY

  const onSetSocialRedirectSettings = (shareType, shareLink, shareText) => {
    setSelectedShareType(shareType)
    setSocialShareUrl(shareLink)
    setSocialPostContent(shareText)
    const formattedLink =
      !shareLink || shareLink.includes(':') ? shareLink : `https://${shareLink}`
    let actionUrl = null
    if (shareType === VirtualActionSocialShareType.TWITTER_ONLY) {
      actionUrl = createTweetUrl({link: formattedLink, text: shareText})
    } else if (shareType === VirtualActionSocialShareType.FACEBOOK_ONLY) {
      actionUrl = createFbShareUrl({link: formattedLink, text: shareText})
    }
    updateFields({
      virtual_action_url: actionUrl,
      virtual_action_button_text: getButtonTextForSocialShareType(shareType),
    })
  }

  const socialPostContentHelpText = isFbSelected
    ? intl.formatMessage(messages.socialPostContentHelpTextFacebook)
    : intl.formatMessage(messages.socialPostContentHelpTextTwitter)

  return (
    <>
      <Select
        name="social_share_type"
        value={selectedShareType ? selectedShareType : ''}
        label={<F defaultMessage="Social platform" />}
        onChange={(e, {value}) => {
          onSetSocialRedirectSettings(value, socialShareUrl, socialPostContent)
        }}
        required
        options={[
          {
            value: VirtualActionSocialShareType.TWITTER_ONLY,
            key: VirtualActionSocialShareType.TWITTER_ONLY,
            text: 'Twitter',
          },
          {
            value: VirtualActionSocialShareType.FACEBOOK_ONLY,
            key: VirtualActionSocialShareType.FACEBOOK_ONLY,
            text: 'Facebook',
          },
        ]}
      />
      <TextArea
        value={socialPostContent || ''}
        label={<F defaultMessage="Content of post" />}
        name="virtual_action_social_post_content"
        onChange={(e) =>
          onSetSocialRedirectSettings(
            selectedShareType,
            socialShareUrl,
            e.currentTarget.value
          )
        }
        hint={socialPostContentHelpText}
        rows={6}
        required
      />
      <Input
        style={{marginBottom: 0}}
        value={socialShareUrl || ''}
        hint={
          <F defaultMessage="This is the link that will be shared on social media. " />
        }
        label={<F defaultMessage="Link to share" />}
        name="virtual_action_share_url"
        onChange={(e) =>
          onSetSocialRedirectSettings(
            selectedShareType,
            e.currentTarget.value,
            socialPostContent
          )
        }
        required={isFbSelected}
      />
      {virtualActionUrl && selectedShareType && (
        <Link to={virtualActionUrl} target="_blank" popup>
          <F defaultMessage="Try this on " />
          {isFbSelected ? 'Facebook' : 'Twitter'} <Icon name="external-link" />
        </Link>
      )}
    </>
  )
}
