import {Layer} from 'react-map-gl'
import {LocationSpecificity} from 'app/enums'
import styles from 'components/styles'

export default function MapLayers() {
  // step function: initial radius followed by next threshold and next radius, repeating
  const clusterSizeSteps = [20, 100, 30, 750, 40]

  return (
    <>
      <Layer
        id="approximate-marker-radius"
        type="circle"
        source="events"
        // only show the radius if the feature has an eventCount of 1 and is approx location
        // or if the feature is a cluster (or single event) and all the events in it are zipcode-only
        filter={[
          'any',
          ['==', ['get', 'eventCount'], ['get', 'zipcodeOnlyCount']],
          [
            'all',
            ['==', ['get', 'eventCount'], 1],
            [
              '==',
              ['get', 'locationSpecificity'],
              LocationSpecificity.APPROXIMATE,
            ],
          ],
        ]}
        paint={{
          'circle-radius': {
            stops: [
              [12, 20],
              [18, 300],
            ],
            base: 2,
          },
          'circle-color': styles.colors.primary100,
          'circle-opacity': 0.5,
          'circle-stroke-color': styles.colors.primary200,
          'circle-stroke-width': 2,
          'circle-stroke-opacity': 0.5,
        }}
        minzoom={12}
      />
      <Layer
        id="clusters"
        type="circle"
        source="events"
        // the eventCount attribute is added to all cluster features summing the counts of each marker
        // included in it, but we want to hide the cluster layer if there is only 1 event
        // more on the syntax here: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/
        filter={['>', ['get', 'eventCount'], 1]}
        paint={{
          'circle-color': styles.colors.primary200,
          'circle-radius': ['step', ['get', 'eventCount'], ...clusterSizeSteps],
          'circle-stroke-width': 3,
          'circle-stroke-color': styles.colors.white,
        }}
        layout={{
          'circle-sort-key': ['to-number', ['get', 'eventCount']],
        }}
      />
      <Layer
        id="cluster-count"
        type="symbol"
        source="events"
        // hide the count if there is only 1 event
        filter={['>', ['get', 'eventCount'], 1]}
        layout={{
          'text-size': 15,
          'text-field': '{eventCount}',
          'text-font': ['Arial Unicode MS Bold'], // todo(kayvon): use consistent Mobilize fonts
        }}
        paint={{'text-color': styles.colors.white}}
      />
      <Layer
        id="unclustered-marker"
        type="circle"
        source="events"
        // only show the single-event marker if the feature has an eventCount of 1
        filter={['==', ['get', 'eventCount'], 1]}
        paint={{
          'circle-radius': 12,
          'circle-stroke-width': 3,
          'circle-color': styles.colors.primary200,
          'circle-stroke-color': styles.colors.white,
        }}
      />
    </>
  )
}
