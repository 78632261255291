import {EventTag as EventTagEnum, eventTagToDisplayName} from 'app/enums'
import styles, {getSecondaryBodyFontFromProps} from './styles'

import styled from '@emotion/styled/macro'

// Local colors, we could eventually move these to style-utils if we decide we want to reuse them
const COLOR_TAG_CORAL = '#fb595b'
const COLOR_TAG_PURPLE = '#9a57dd'
const COLOR_TAG_LEAF = '#27ae60'
const COLOR_TAG_GOLD = '#f09952'
const COLOR_TAG_TEAL = '#06b5ba'

const TAG_TO_COLOR = {
  [EventTagEnum.HIGH_PRIORITY]: COLOR_TAG_CORAL,
  [EventTagEnum.CLOSEST]: COLOR_TAG_GOLD,
  [EventTagEnum.SOONEST]: COLOR_TAG_GOLD,
  [EventTagEnum.GOTV]: COLOR_TAG_TEAL,
  [EventTagEnum.ACT_NOW]: COLOR_TAG_PURPLE,
  [EventTagEnum.VIRTUAL]: COLOR_TAG_LEAF,
}

/**
 * A tag indicating something about an event (possible values in `$EventTag` enum)
 */
const EventTag = styled(({tag, ...props}) => (
  <span {...props}>{eventTagToDisplayName(tag)}</span>
))`
  ${getSecondaryBodyFontFromProps};
  color: ${styles.colors.white};
  text-transform: uppercase;
  font-weight: ${styles.typography.fontWeightBold};

  padding: 2px 8px 2px;
  border-radius: 2px;
  font-size: ${styles.typography.fontSizeS};
  letter-spacing: 0.05rem;
  background-color: ${(props) => TAG_TO_COLOR[props.tag]};
`

/** @component */
export default EventTag
