import {F} from 'util/i18n'
import {Typography} from 'components'
import cv from 'util/clientVars'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const PoweredByWrapper = styled.div`
  text-align: center;
  margin-top: ${styles.space.l};
`

export default function PoweredByMobilize() {
  return (
    <PoweredByWrapper>
      <Typography variant="body2">
        <F
          defaultMessage="Powered by {logo}"
          values={{
            logo: (
              <img
                src={cv.powered_by_logo_horizontal_url}
                width="89"
                height="18"
                alt={`Powered by ${cv.product_name}`}
              />
            ),
          }}
        />
      </Typography>
    </PoweredByWrapper>
  )
}
