import {
  CheckboxField,
  DatePicker,
  FormItem,
  Image,
  Input,
  Select,
  TextArea,
  Typography,
} from 'components'
import {
  checkIsPromptForDonationHostedEvent,
  getEventCategoryName,
  getMailtoLink,
} from './util'
import {
  isGroupEventCampaign,
  isPeerToPeerFundraisingCampaign,
} from 'util/eventCampaign'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {F} from 'util/i18n'
import {HostFormHeadingWrapper} from './HostCreateEventComponents'
import MarkdownHelp from 'dashboard/components/MarkdownHelp'
import {TIMESLOT_ROW_DATE_FORMAT} from 'util/time'
import UneditableDescription from './UneditableDescription'
import {capitalizeFirstLetter} from 'util/string'
import {centsToDollars} from 'util/currency'
import {eventTypeToDisplayName} from 'app/enums'
import {isDonationCampaignHostedEvent} from 'util/event'
import moment from 'vendor/moment'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useContext} from 'react'

const FundraisingNarrowInputWrapper = styled.div`
  max-width: 265px;
  margin-bottom: ${styles.space.m};
`

export default function HostCreateEventBasicInfo({
  canEditTrustedFields,
  contactEmail,
  eventCampaign,
  eventTypeErrorMessage,
  hostedEvent,
  onEventFieldChange,
  onEventTypeChange,
  onTimeslotsChange,
  timeslotsToEdit,
}) {
  const orgContext = useContext(CurrentOrganizationContext)
  const org = orgContext?.currentOrganization

  const isDonationCampaign = isDonationCampaignHostedEvent(hostedEvent)
  const isGroupCampaign = isGroupEventCampaign(eventCampaign)
  const isPromptForDonationHostedEvent = checkIsPromptForDonationHostedEvent(
    hostedEvent,
    isDonationCampaign
  )
  const useMultiDayTimeslot =
    isPeerToPeerFundraisingCampaign(eventCampaign) || isGroupCampaign

  const eventCategoryName = getEventCategoryName(
    isDonationCampaign,
    isGroupCampaign
  )

  const mailtoLink = org && getMailtoLink(contactEmail, eventCampaign, org)
  return (
    <div data-testid="host_event_basic_info">
      <HostFormHeadingWrapper>
        <Typography variant="h2">Basic information</Typography>
      </HostFormHeadingWrapper>
      <Input
        name="name"
        value={hostedEvent.name || ''}
        label={`${capitalizeFirstLetter(eventCategoryName)} title`}
        onChange={onEventFieldChange}
        autoComplete="nope"
        required
        disabled={!canEditTrustedFields}
        hint={
          canEditTrustedFields ? null : (
            <UneditableDescription
              contactEmail={contactEmail}
              mailtoLink={mailtoLink}
            />
          )
        }
      />
      {eventCampaign.hosted_event_types.length > 1 && (
        <Select
          label="Event Type"
          name="event_type"
          value={hostedEvent.event_type || undefined}
          disabled={!canEditTrustedFields}
          options={eventCampaign.hosted_event_types.map((eventType) => ({
            value: eventType,
            text: eventTypeToDisplayName(eventType),
          }))}
          required
          error={!!eventTypeErrorMessage}
          onChange={(e, {value}) => onEventTypeChange(e, value)}
        />
      )}
      <FormItem label="Photo" hint={`This image is set by ${org?.name || ''}.`}>
        <Image
          src={hostedEvent.image_url}
          maxWidth={542}
          alt={`Event image for ${eventCampaign.name}`}
        />
      </FormItem>

      <TextArea
        name="description"
        value={hostedEvent.description || ''}
        label={isGroupCampaign ? 'About this group' : 'Description'}
        onChange={onEventFieldChange}
        autoComplete="nope"
        hint={
          <>
            {canEditTrustedFields ? (
              isGroupCampaign ? (
                'Public, shown on the group signup page.'
              ) : (
                'You can change or add to this recommended description.'
              )
            ) : (
              <UneditableDescription
                contactEmail={contactEmail}
                mailtoLink={mailtoLink}
              />
            )}
            <MarkdownHelp />
          </>
        }
        required
        disabled={!canEditTrustedFields}
      />

      {useMultiDayTimeslot && (
        <FundraisingNarrowInputWrapper>
          <DatePicker
            label="End date *"
            required
            onChange={(value) => {
              const ts = timeslotsToEdit[0]
              onTimeslotsChange([
                {
                  ...ts,
                  endDate: moment(value, moment.ISO_8601).format(
                    TIMESLOT_ROW_DATE_FORMAT
                  ),
                },
              ])
            }}
            shouldReturnHTML5Date
            disablePast
            disabled={!!eventCampaign.hosted_event_range_end}
            value={timeslotsToEdit[0].endDate}
            hint={`When will your ${eventCategoryName} end?`}
          />
        </FundraisingNarrowInputWrapper>
      )}

      {isPromptForDonationHostedEvent && (
        <FundraisingNarrowInputWrapper>
          <Input
            name="fundraising_goal_amount_in_cents"
            label="Fundraising goal (optional)"
            onChange={onEventFieldChange}
            autoComplete="nope"
            prefix="$"
            type="number"
            hint="How much do you plan to raise?"
            value={
              hostedEvent.fundraising_goal_amount_in_cents
                ? centsToDollars(hostedEvent.fundraising_goal_amount_in_cents)
                : undefined
            }
          />
        </FundraisingNarrowInputWrapper>
      )}

      {/* Display tags set on event campaign if applicable. */}
      {!orgFlagIsActive(org, 'disable_tags_in_distributed_organizing') &&
        hostedEvent.tags &&
        hostedEvent.tags.length > 0 && (
          <>
            <Typography variant="h2">
              <F defaultMessage="Event tags" />
            </Typography>
            <Typography variant="body1">
              <F defaultMessage="These tags are set by the event campaign organizers." />
            </Typography>

            {hostedEvent.tags.map((tag) => (
              <CheckboxField
                disabled
                key={tag.id}
                name={tag.name}
                label={tag.event_create_form_checkbox_label || tag.name}
                checked={true}
                onChange={() => {}}
              />
            ))}
          </>
        )}
    </div>
  )
}
