import {Button, Icon, Link, Loader} from 'components'

import {FilterList} from './FilterList'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const Wrapper = styled.div`
  background-color: ${styles.colors.neutral200};
  border-radius: 5px;
  color: ${(props) =>
    props.muted ? styles.colors.neutral400 : styles.colors.black};
  font-size: ${styles.typography.fontSizeS};
  margin-bottom: ${styles.space.m};
  padding: ${styles.space.m};
  position: relative;
  ${(props) =>
    props.isLoading
      ? `
      align-items: center;
      display: flex;
    `
      : ''}
`

const StyledLink = styled(Link)`
  color: ${styles.colors.black};
`

const WarningWrapper = styled.div`
  color: ${styles.colors.black};
  font-size: ${styles.typography.fontSizeXS};
  padding-top: ${styles.space.s};
`

const CloseWrapper = styled.span`
  position: absolute;
  top: 4px;
  right: 4px;
`

const LoadMsg = styled.div`
  margin-left: ${styles.space.m};
  width: 100%;
`

const Unbold = styled.div`
  font-weight: ${styles.typography.fontWeightNormal};
`

export default function EventCard({
  eventName = '',
  filterParams,
  isLoading,
  muted,
  onRequestDismiss,
  organization,
  timeslotDetail = '',
  to = '',
  warnings,
}) {
  if (isLoading) {
    return (
      <Wrapper isLoading={isLoading}>
        <Loader />
        <LoadMsg>Loading suggestion...</LoadMsg>
      </Wrapper>
    )
  }

  return (
    <Wrapper muted={muted}>
      <StyledLink noUnderline target="_blank" to={to}>
        {eventName}
        {filterParams && organization && (
          <FilterList filterParams={filterParams} organization={organization} />
        )}
        <Unbold>
          {timeslotDetail && <div>{timeslotDetail}</div>}
          {warnings?.map(({text, icon}) => (
            <WarningWrapper key={text}>
              <Icon noMargin name={icon} /> {text}
            </WarningWrapper>
          ))}
        </Unbold>
      </StyledLink>
      {typeof onRequestDismiss === 'function' && (
        <CloseWrapper>
          <Button
            link
            plain
            noUnderline
            onClick={onRequestDismiss}
            icon="times"
            aria-label="Close"
            linkTextColor={styles.colors.neutral400}
          />
        </CloseWrapper>
      )}
    </Wrapper>
  )
}
