import {Button, Link, MaxWidthContainer, Typography} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {
  getMobilizeFeedUrl,
  getOrganizationFeedUrl,
  getOrganizationHostInterestUrl,
} from 'util/routing'
import styles, {mediaMinWidthSmall} from 'components/styles'
import {useContext, useState} from 'react'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import CurrentUserContext from 'app/CurrentUserContext'
import LoggedInMenu from './LoggedInMenu'
import LoginModal from 'app/login/LoginModal'
import OrganizerMenu from './OrganizerMenu'
import Search from './Search'
import {SkipToContentLink} from 'app/SkipToContent'
import {css} from '@emotion/react'
import cv from 'util/clientVars'
import {orgFlagIsActive} from 'util/organization'
import {remapImageUrlToProxy} from 'util/image'
import styled from '@emotion/styled/macro'

const messages = defineMessages({
  navImageAlt: {
    defaultMessage: '{org_name} - Home',
  },
  mainNavAriaLabel: {
    defaultMessage: 'Main navigation',
  },
})

const IMAGE_HEIGHT = '4rem'
const IMAGE_REDUCTION_FACTOR_MOBILE = 0.8
const NAV_LINK_PADDING = styles.space.xs
const TOP_NAV_DESKTOP_PADDING = styles.space.s

// NB: the 'height' property is calculated here to prevent the reflow of the layout
// after the image logo finally loads in.
// It's the padding of the TopNavContainer + image height - the NavLinkWrapper padding.
// Could hard code it probably but this is more correct.
const TopNavContainer = styled.nav`
  /* positioned relative for search to be able to overlay on mobile. */
  position: relative;

  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0 ${styles.space.m};
  height: calc(
    ${IMAGE_HEIGHT} * ${IMAGE_REDUCTION_FACTOR_MOBILE} + ${NAV_LINK_PADDING} * 2
  );

  ${mediaMinWidthSmall(`
    padding: ${TOP_NAV_DESKTOP_PADDING} ${styles.space.m};
    height: calc(
      ${TOP_NAV_DESKTOP_PADDING} * 2 + ${IMAGE_HEIGHT} + ${NAV_LINK_PADDING} * 2
    );
  `)};
`

const NavImage = styled.img`
  max-height: ${IMAGE_HEIGHT};
  max-width: ${IMAGE_REDUCTION_FACTOR_MOBILE * 100}%;

  ${mediaMinWidthSmall('max-width: 100%;')}
`

const NavLinkWrapper = styled.div`
  display: inline-block;
  font-size: ${styles.typography.fontSizeL};
  margin-right: ${styles.space.m};
  line-height: inherit;
  padding-top: ${NAV_LINK_PADDING};
  padding-bottom: ${NAV_LINK_PADDING};

  a {
    &,
    &:hover {
      text-decoration: none;
    }
  }
`

const MenuToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const OrganizerMenuWrapper = styled.div`
  border-right: 1px solid ${styles.colors.neutral300};
  margin-left: ${styles.space
    .xs}; // this stops the search icon from obscuring the OrganizerMenu focus state
`

const HostEventWrapper = styled.div`
  padding: ${styles.space.s} ${styles.space.m};
  border-right: 1px solid ${styles.colors.neutral300};
  ${(props) =>
    !props.showOnMobile &&
    css`
      display: none;
      ${mediaMinWidthSmall('display: block;')}
    `}
`
const HostEventMobileCopy = styled.span`
  ${mediaMinWidthSmall('display: none;')}
`
const HostEventDesktopCopy = styled.span`
  display: none;
  white-space: nowrap;
  ${mediaMinWidthSmall('display: inline;')}
`

export default function Header({
  contactEmail,
  maxWidth,
  showHostEventLink,
  showHostEventLinkOnMobile,
  showSkipToContentLink,
}) {
  const intl = useIntl()
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const {user} = useContext(CurrentUserContext)

  const distOrgHostLink =
    currentOrganization && getOrganizationHostInterestUrl(currentOrganization)

  const shouldRenderOrganizerMenu =
    currentOrganization &&
    orgFlagIsActive(currentOrganization, 'enable_header_organizer_menu')

  const shouldRenderHostEventLink =
    !shouldRenderOrganizerMenu &&
    showHostEventLink &&
    currentOrganization &&
    orgFlagIsActive(currentOrganization, 'enable_distributed_organizing')

  return (
    <MaxWidthContainer as="header" maxWidth={maxWidth} noGutters>
      <TopNavContainer
        aria-label={intl.formatMessage(messages.mainNavAriaLabel)}
      >
        <Logo currentOrganization={currentOrganization} />
        {showSkipToContentLink && <SkipToContentLink />}

        <HeaderRightWrapper>
          <Search />

          <MenuToggleWrapper>
            {shouldRenderOrganizerMenu && (
              <OrganizerMenuWrapper>
                <OrganizerMenu />
              </OrganizerMenuWrapper>
            )}
            {shouldRenderHostEventLink && (
              <HostEventWrapper showOnMobile={showHostEventLinkOnMobile}>
                <Link to={distOrgHostLink || ''} plain>
                  <HostEventDesktopCopy>
                    <F defaultMessage="Host an event" />
                  </HostEventDesktopCopy>
                  <HostEventMobileCopy>
                    <F defaultMessage="Host" />
                  </HostEventMobileCopy>
                </Link>
              </HostEventWrapper>
            )}
            {user ? (
              <LoggedInMenu
                contactEmail={contactEmail}
                showHostEventLink={showHostEventLink}
              />
            ) : (
              <Login />
            )}
          </MenuToggleWrapper>
        </HeaderRightWrapper>
      </TopNavContainer>
    </MaxWidthContainer>
  )
}

// Exported just for testing
export function Logo({currentOrganization}) {
  const intl = useIntl()
  return (
    <NavLinkWrapper>
      {currentOrganization && currentOrganization.branding ? (
        <Link
          plain
          to={
            currentOrganization.branding.url ||
            getOrganizationFeedUrl(currentOrganization)
          }
          // Without this, the link can sometimes be smaller than the logo, and the focused state
          // (for screenreaders) can cut through the logo
          style={{display: 'block'}}
        >
          {currentOrganization.branding.logo_url ? (
            <NavImage
              src={remapImageUrlToProxy(currentOrganization.branding.logo_url)}
              alt={intl.formatMessage(messages.navImageAlt, {
                org_name: currentOrganization.name,
              })}
            />
          ) : (
            <Typography variant="h2">{currentOrganization.name}</Typography>
          )}
        </Link>
      ) : (
        <Link plain to={getMobilizeFeedUrl()}>
          <NavImage
            src={remapImageUrlToProxy(cv.logo_url)}
            alt={intl.formatMessage(messages.navImageAlt, {
              org_name: cv.product_name,
            })}
          />
        </Link>
      )}
    </NavLinkWrapper>
  )
}

// Exported just for testing
export function Login() {
  const [loginOpen, setLoginOpen] = useState(false)

  // Interim implementation that reloads the page so that the data for the newly logged in User
  // will be displayed.
  const handleThirdPartyAccountConnected = () => {
    window.location.reload()
  }

  const handleClick = () => {
    setLoginOpen(true)
  }

  const handleRequestClose = () => {
    setLoginOpen(false)
  }

  return (
    <>
      <Button link onClick={handleClick} nowrap>
        <F defaultMessage="Log in" />
      </Button>
      <LoginModal
        isOpen={loginOpen}
        onRequestClose={handleRequestClose}
        onFacebookConnected={handleThirdPartyAccountConnected}
        onGoogleConnected={handleThirdPartyAccountConnected}
      />
    </>
  )
}
