import {ActionType} from '../FundraisingFormReducer'
import CheckboxField from 'components/CheckboxField'
import {F} from 'util/i18n'
import {formatNumberAsCurrency} from 'util/currency'
import {useMemo} from 'react'
import {useMountEffect} from 'hooks/useMountEffect'

export default function CoverCostsAmountInput({
  dispatch,
  input,
  onChange,
  organizationName,
  selectedAmount,
  value,
}) {
  const calculatedAmount = useMemo(() => {
    const {amount: fixedAmount, percentage} = input.formula
    return (
      parseFloat(selectedAmount) * (percentage / 100) +
      fixedAmount
    ).toFixed(2)
  }, [input, selectedAmount])

  // Initialize CoverCostsAmountCalculated with the calculatedAmount.
  // N.B. useMountEffect to only run once because a dependency on onChange
  // causes repeating state updates.
  useMountEffect(() => {
    dispatch({
      type: ActionType.COVER_COSTS_AMOUNT_CALCULATED_UPDATED,
      data: {
        coverCostsAmountCalculated: calculatedAmount,
        coverCostsAmountChecked: value,
      },
    })
  })

  const handleChange = (evt) => {
    dispatch({
      type: ActionType.COVER_COSTS_AMOUNT_CALCULATED_UPDATED,
      data: {
        coverCostsAmountCalculated: calculatedAmount,
        coverCostsAmountChecked: evt.value,
      },
    })
  }

  const calculatedProcessingFee = parseFloat(calculatedAmount)
  const label = isNaN(calculatedProcessingFee) ? (
    <F
      defaultMessage="I'd like to cover the processing fee so all of my donation goes to {orgName}"
      values={{
        orgName: organizationName,
      }}
    />
  ) : (
    <F
      defaultMessage="I'd like to cover the {amount} processing fee so all of my donation goes to {orgName}"
      values={{
        amount: formatNumberAsCurrency(calculatedProcessingFee),
        orgName: organizationName,
      }}
    />
  )

  return (
    <CheckboxField
      // N.B. CheckboxField only has a `checked` prop, not `value`
      checked={value}
      label={label}
      onChange={handleChange}
      name={input.name}
      required={input.required}
    />
  )
}
