import styles, {
  getPrimaryTextColorFromProps,
  getReadableColorOnPrimaryColor,
  getSecondaryBodyFontFromProps,
} from './styles'

import MaxWidthContainer from './MaxWidthContainer'
import {css} from '@emotion/react'
import styled from '@emotion/styled/macro'

const getBrandingFromTheme = (props) => {
  return css`
    background-color: ${props.theme.brand_banner_background_color};
    ${getSecondaryBodyFontFromProps(props)};
    font-weight: ${props.theme.link_font_weight};
  `
}

const BannerOuter = styled.div`
  ${(props) =>
    props.small
      ? `padding-top: ${styles.space.s}; padding-bottom: ${styles.space.s};`
      : `padding-top: ${styles.space.l}; padding-bottom: ${styles.space.l};`}
  ${(props) => props.center && 'text-align: center;'}
  ${(props) => !props.compact && `margin-bottom: ${styles.space.m};`}
  ${(props) =>
    props.theme.style === 'minimal' &&
    !props.topOfPage &&
    `border-top: 1px solid ${styles.colors.neutral300};`}
  ${(props) =>
    props.theme.style === 'minimal'
      ? getPrimaryTextColorFromProps(props)
      : getReadableColorOnPrimaryColor(props)};
  font-size: ${styles.typography.fontSizeBase};
  ${(props) => props.useBrandingFromTheme && getBrandingFromTheme(props)};
  ${(props) =>
    props.caution &&
    `background-color: ${styles.colors.caution100}; color: ${styles.colors.black};`}
}
`

const Banner = ({children, ...props}) => {
  return (
    <BannerOuter {...props}>
      <MaxWidthContainer>{children}</MaxWidthContainer>
    </BannerOuter>
  )
}
export default Banner
