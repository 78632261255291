import {isEmpty, last} from './common'

import queryString from 'query-string'
import {stripTrailingSlash} from './string'

/**
 * @description takes 2 strings/urls, normalizes them, then compares them
 */
export function isSamePath(url1, url2) {
  const normalizedUrl = stripTrailingSlash(url1.toLowerCase())
  const normalizedUrl2 = stripTrailingSlash(url2.toLowerCase())
  return normalizedUrl === normalizedUrl2
}

export function searchHasProperty(location, prop) {
  // '?foo' gets parsed into {foo: null}; also accept ?foo=<whatever> for easier BE URL generation
  return new URLSearchParams(location.search).has(prop)
}

export function getQueryParams(qs) {
  return queryString.parse(qs ?? window.location.search)
}

export function formatQueryString(params) {
  return isEmpty(params) ? '' : `?${queryString.stringify(params)}`
}

export function createMailUrl(email, subject, body) {
  return `mailto:${email}${formatQueryString({subject, body})}`
}

// Based on https://stackoverflow.com/a/69315214
const URL_PATTERN = /^((http|https)+:\/\/(([\w\u00C0-\u1FFF\u2C00-\uD7FF-]+\.)*([\w\u00C0-\u1FFF\u2C00-\uD7FF-]*\.?))(:\d+)?(((\/[^\s#$%^&*?]+)+|\/?)(\?[\w\u00C0-\u1FFF\u2C00-\uD7FF:;&%_,.~+=-]+)?)?(#[\w\u00C0-\u1FFF\u2C00-\uD7FF-_]+)?)$/i

export function isValidURL(str) {
  let url
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }
  return (
    (url.protocol === 'http:' || url.protocol === 'https:') &&
    URL_PATTERN.test(str)
  )
}

const KEBAB_CASE_REGEX = /\s+/g
const DISALLOWED_SLUG_CHARACTERS_REGEX = /[^A-Za-z0-9-_]+/g

export function slugify(s, opts = {}) {
  const slug = s
    .toLowerCase()
    .replace(KEBAB_CASE_REGEX, '-')
    .replace(DISALLOWED_SLUG_CHARACTERS_REGEX, '')

  if (opts.maxLength) {
    return slug.substring(0, opts.maxLength)
  }
  return slug
}

export function getPath() {
  return window.location.pathname.substr(1)
}

export function getTrackingParams(qs) {
  const lastElementIfIsArray = (val) => (Array.isArray(val) ? last(val) : val)
  // query-string will return an array if a query param is specified multiple times;
  // here we make sure that tracking params end up as strings
  const queryParams = getQueryParams(qs)
  const reducer = (currentParams, currentKey) => ({
    ...currentParams,
    [currentKey]: lastElementIfIsArray(queryParams[currentKey]),
  })
  const flattenedQueryParams = Object.keys(queryParams).reduce(reducer, {})
  return {
    utm_source: flattenedQueryParams.utm_source || null,
    utm_medium: flattenedQueryParams.utm_medium || null,
    utm_campaign: flattenedQueryParams.utm_campaign || null,
    utm_term: flattenedQueryParams.utm_term || null,
    utm_content: flattenedQueryParams.utm_content || null,
    utm_adgroup: flattenedQueryParams.utm_adgroup || null,
    referrer: document.referrer || null,
    // "Namespace" AK tracking params under `actionkit_` to avoid conflicts with other request keys
    actionkit_source: flattenedQueryParams.source || null,
    actionkit_akid: flattenedQueryParams.akid || null,
    followup_modal_context: flattenedQueryParams.followup_modal_context || null,
    host_commitment_source: flattenedQueryParams.host_commitment_source || null,
    event_creation_source: flattenedQueryParams.event_creation_source || null,
  }
}
