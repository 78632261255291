import {F, defineMessages, useIntl} from 'util/i18n'
import {Image, Link, MaxWidthContainer} from 'components'
import styles, {mediaMinWidthSmall} from 'components/styles'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import DefaultThemeProvider from 'app/DefaultThemeProvider'
import {css} from '@emotion/react'
import cv from 'util/clientVars'
import {getOrgCreationUrl} from 'util/routing'
import moment from 'vendor/moment'
import {remapImageUrlToProxy} from 'util/image'
import styled from '@emotion/styled/macro'
import {useContext} from 'react'

const messages = defineMessages({
  primaryNavAriaLabel: {
    defaultMessage: 'Primary footer navigation',
  },
  secondaryNavAriaLabel: {
    defaultMessage: 'Secondary footer navigation',
  },
  poweredByLogoAltText: {
    defaultMessage: 'Powered by {productName}',
  },
})

const FooterOuter = styled.footer`
  background-color: ${styles.colors.white};
  border-top: 1px solid ${styles.colors.neutral300};
  padding: ${styles.space.xl} 0 ${styles.space.m};
  flex-shrink: 0;
`

const PrimaryWrapper = styled.nav`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${mediaMinWidthSmall(css`
    flex-direction: row;
  `)};
`

const SecondaryWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  margin-top: ${styles.space.xl};
  border-top: 1px solid ${styles.colors.neutral300};
  padding-top: ${styles.space.m};
  font-size: ${styles.typography.fontSizeS};
  color: ${styles.colors.neutral400};
`

const PrimaryLinkWrapper = styled.div`
  margin: ${styles.space.m} 0;
  text-align: center;
  white-space: nowrap;
  ${mediaMinWidthSmall(css`
    margin: 0 ${styles.space.m};
  `)};
`

const PrimaryLink = (props) => (
  <PrimaryLinkWrapper>
    <Link secondary target="_blank" {...props} />
  </PrimaryLinkWrapper>
)
const SecondaryLink = (props) => <Link secondary target="_blank" {...props} />

const POWERED_BY_LOGO_DISPLAY_WIDTH_PX = 200
const POWERED_BY_LOGO_URL_RATIO = 173 / 600 // Actual dimensions of the asset

const ImageWrapper = styled.div`
  width: ${POWERED_BY_LOGO_DISPLAY_WIDTH_PX}px;
`
const ImageOuter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${styles.space.l};
`

export default function Footer() {
  const intl = useIntl()
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const showBranding =
    !currentOrganization ||
    !currentOrganization.branding.remove_mobilize_branding
  return (
    <DefaultThemeProvider>
      <FooterOuter>
        <MaxWidthContainer>
          {showBranding && (
            <ImageOuter>
              <Link plain to={cv.marketing_site_url} target="_blank">
                <ImageWrapper>
                  <Image
                    src={remapImageUrlToProxy(cv.powered_by_logo_url)}
                    ratio={POWERED_BY_LOGO_URL_RATIO}
                    maxWidth={POWERED_BY_LOGO_DISPLAY_WIDTH_PX}
                    alt={intl.formatMessage(messages.poweredByLogoAltText, {
                      productName: cv.product_name,
                    })}
                  />
                </ImageWrapper>
              </Link>
            </ImageOuter>
          )}
          <PrimaryWrapper
            aria-label={intl.formatMessage(messages.primaryNavAriaLabel)}
          >
            <PrimaryLink to={getOrgCreationUrl()}>
              <F
                defaultMessage="Get {productName} for your organization"
                values={{productName: cv.product_name}}
              />
            </PrimaryLink>
            <PrimaryLink to={cv.feedback_url}>
              <F
                defaultMessage="Send {productName} feedback"
                values={{productName: cv.product_name}}
              />
            </PrimaryLink>
            <PrimaryLink to={cv.jobs_url}>
              <F
                defaultMessage="Jobs at {productName}"
                values={{productName: cv.product_name}}
              />
            </PrimaryLink>
            <PrimaryLink to="https://mobilize.statuspage.io/">
              <F defaultMessage="Status page" />
            </PrimaryLink>
          </PrimaryWrapper>
          <SecondaryWrapper
            aria-label={intl.formatMessage(messages.secondaryNavAriaLabel)}
          >
            <span>
              <F
                defaultMessage="© {year} {legalName}"
                values={{year: moment().year(), legalName: cv.legal_name}}
              />
            </span>
            <span>
              <SecondaryLink to={cv.tos_url}>
                <F defaultMessage="Terms" />
              </SecondaryLink>{' '}
              ·{' '}
              <SecondaryLink to={cv.privacy_policy_url}>
                <F defaultMessage="Privacy" />
              </SecondaryLink>
            </span>
          </SecondaryWrapper>
        </MaxWidthContainer>
      </FooterOuter>
    </DefaultThemeProvider>
  )
}
