import {
  Button,
  CheckboxField,
  Message,
  MessageType,
  ShadowContainer,
} from 'components'
import {
  filterOutAtCapacityTimeslots,
  formatTimeslot,
  isAtCapacity,
} from 'util/timeslot'
import styles, {fontSizeSmall} from 'components/styles'
import {useContext, useState} from 'react'

import {F} from 'util/i18n'
import IntlMessageContext from 'app/IntlMessageContext'
import {classnames} from 'util/common'
import styled from '@emotion/styled/macro'

const RemainingSpotsMessage = styled.div`
  color: ${styles.colors.error200};
  ${fontSizeSmall};
`
const ErrorMessageWrapper = styled.div`
  margin-top: ${styles.space.s};
`
const PrioritizedTimeslotWrapper = styled.div`
  margin-top: ${styles.space.m};
  margin-bottom: ${styles.space.m};
`
const SeeMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export default function TimeslotCheckboxes({
  event,
  onChange,
  selectedTimeslotIds,
  maybePrioritizedTimeslot,
  timeslotErrors,
  renderAsRadioButtons,
}) {
  const {
    intlMessageConfig: {locale},
  } = useContext(IntlMessageContext)
  const [isCollapsed, setIsCollapsed] = useState(true)

  const onSelectionChange = (evt) => {
    const {name, value} = evt
    const timeslotId = parseInt(name, 10)
    if (value) {
      if (renderAsRadioButtons) {
        onChange([timeslotId])
      } else {
        onChange([...selectedTimeslotIds, timeslotId])
      }
    } else {
      onChange(
        selectedTimeslotIds.filter((selectedId) => selectedId !== timeslotId)
      )
    }
  }

  const toggleExpand = (evt) => {
    evt.preventDefault()
    setIsCollapsed(!isCollapsed)
  }

  const renderTimeslot = (timeslot) => {
    const {timezone} = event
    const checked = selectedTimeslotIds.includes(timeslot.id)
    const timeslotError =
      timeslotErrors[String(timeslot.id)] ||
      (isAtCapacity(timeslot) && (
        <F defaultMessage="This time is at max capacity. Choose another below." />
      ))
    let label = formatTimeslot(timeslot, timezone, locale)
    if (timeslot.remaining_spots) {
      label = (
        <>
          {label}
          <RemainingSpotsMessage>
            <F
              defaultMessage="{remainingSpots, plural, one {# spot} other {# spots}} left"
              values={{remainingSpots: timeslot.remaining_spots}}
            />
          </RemainingSpotsMessage>
        </>
      )
    }
    if (timeslotError) {
      label = (
        <>
          {label}
          {timeslotError && (
            <ErrorMessageWrapper>
              <Message type={MessageType.ERROR} addTopMargin>
                {timeslotError}
              </Message>
            </ErrorMessageWrapper>
          )}
        </>
      )
    }
    return (
      <div key={timeslot.id}>
        <CheckboxField
          name={`${timeslot.id}`}
          onChange={onSelectionChange}
          checked={checked}
          label={label}
          disabled={!!timeslotError}
          trackingName="Timeslot checkbox"
          type={renderAsRadioButtons ? 'radio' : 'checkbox'}
        />
      </div>
    )
  }

  const availableTimeslots = filterOutAtCapacityTimeslots(event.times)
  const normalTimeslots = availableTimeslots.filter(
    (timeslot) =>
      !maybePrioritizedTimeslot || timeslot.id !== maybePrioritizedTimeslot.id
  )
  const firstTimeslots = normalTimeslots.slice(0, 4)
  const otherTimeslots = normalTimeslots.slice(4)
  return (
    <div>
      {maybePrioritizedTimeslot && (
        <PrioritizedTimeslotWrapper>
          {renderTimeslot(maybePrioritizedTimeslot)}
        </PrioritizedTimeslotWrapper>
      )}
      {firstTimeslots.map(renderTimeslot, this)}
      <div style={isCollapsed ? {display: 'none'} : {}} id="overflowTimeslots">
        {otherTimeslots.map(renderTimeslot, this)}
      </div>
      {isCollapsed && otherTimeslots.length > 0 ? (
        <ShadowContainer collapsed={isCollapsed}>
          {renderTimeslot(otherTimeslots[0])}
        </ShadowContainer>
      ) : null}
      {otherTimeslots.length > 0 && (
        <SeeMoreWrapper>
          <Button
            link
            type="button"
            padding="none"
            aria-controls="overflowTimeslots"
            aria-expanded={!isCollapsed}
            aria-label="See more timeslots"
            iconPosition="left"
            onClick={toggleExpand}
          >
            <span
              style={{marginRight: styles.space.xs}}
              className={classnames(
                'fa',
                isCollapsed ? 'fa-angle-down' : 'fa-angle-up'
              )}
            />
            {isCollapsed ? (
              <F defaultMessage="Show more" />
            ) : (
              <F defaultMessage="Show fewer" />
            )}
          </Button>
        </SeeMoreWrapper>
      )}
    </div>
  )
}
