import {F, defineMessages, useIntl} from 'util/i18n'
import {Grid, Icon, Section, Typography} from 'components'

import {AccessibilityStatus} from 'app/enums'
import {getAccessibilityFeatureDisplayName} from 'app/enums'

const messages = defineMessages({
  sectionTitle: {defaultMessage: 'Accessibility'},
})

export default function AccessibilityDetails({notes, status, features}) {
  const intl = useIntl()
  return (
    <>
      <Section title={intl.formatMessage(messages.sectionTitle)}>
        <Grid container spacing={2}>
          {status === AccessibilityStatus.ACCESSIBLE && (
            <>
              <Grid item xs={1}>
                <Icon
                  secondary
                  name="universal-access"
                  iconStyle="regular"
                  noMargin
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1">
                  <F defaultMessage="This event meets ADA standards" />
                </Typography>
              </Grid>
            </>
          )}
          {!!features?.length && (
            <>
              <Grid item xs={1}>
                <Icon secondary name="check" iconStyle="regular" noMargin />
              </Grid>
              <Grid item xs={11}>
                <Grid container>
                  {features.map((af) => (
                    <Grid
                      item
                      xs={12}
                      // If there are 4+ features, then show as a grid on wide screens; otherwise,
                      // just display as a column.
                      md={features.length >= 4 ? 6 : undefined}
                      key={af}
                    >
                      {getAccessibilityFeatureDisplayName(af)}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
          {notes && (
            <>
              <Grid item xs={1}>
                <Icon
                  secondary
                  name="comments-alt"
                  iconStyle="regular"
                  noMargin
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1">
                  <F
                    defaultMessage="Notes from the organizer: {notes}"
                    values={{notes}}
                  />
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={1}>
            <Icon
              secondary
              name="question-circle"
              iconStyle="regular"
              noMargin
            />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1">
              <F defaultMessage="Have accessibility questions? Reply to your registration email to confirm your requirements or request more information." />
            </Typography>
          </Grid>
        </Grid>
      </Section>
    </>
  )
}
