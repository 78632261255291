import {
  AccessibilityFeature,
  AccessibilityStatus,
  getAccessibilityFeatureDisplayName,
} from 'app/enums'
import {CheckboxField, Grid, Link, TextArea, Typography} from 'components'

import {F} from 'util/i18n'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const AccessibilityFeatureWrapper = styled.div`
  margin-bottom: ${styles.space.xl};
  max-width: 550px;
`

const AccessibilityDetails = ({
  accessibilityStatus,
  accessibilityNotes,
  accessibilityFeatures,
  onStatusChange,
  onNotesChange,
  onFeaturesChange,
}) => {
  const accessibilityFeaturesSet = new Set(accessibilityFeatures)

  return (
    <>
      <CheckboxField
        id="mbz-accessibility-status"
        name="accessibility_status"
        checked={accessibilityStatus === AccessibilityStatus.ACCESSIBLE}
        onChange={({value}) =>
          onStatusChange(
            'accessibility_status',
            value
              ? AccessibilityStatus.ACCESSIBLE
              : AccessibilityStatus.NOT_SURE
          )
        }
        label={<F defaultMessage="This event meets ADA standards" />}
        hint={
          <F defaultMessage="Shown on the event page if checked. Helps supporters discover accessible events." />
        }
      />
      <Typography variant="h3">
        <F defaultMessage="Accessibility features" />
      </Typography>
      <AccessibilityFeatureWrapper>
        <Typography variant="body1">
          <F
            defaultMessage="Let supporters know which accomodations are available to help them take part in this event. <a>Learn more</a>"
            values={{
              a: (msg) => (
                <Link to="https://mobilize.us/s/a11y-features" target="_blank">
                  {msg}
                </Link>
              ),
            }}
          />
        </Typography>
        <Grid container>
          {Object.values(AccessibilityFeature).map(
            // $FlowFixMe - Flow thinks Object.values is always `mixed`
            (af) => (
              <Grid item xs={12} md={6} key={af}>
                <CheckboxField
                  checked={accessibilityFeaturesSet.has(af)}
                  label={getAccessibilityFeatureDisplayName(af)}
                  onChange={({value}) => {
                    const newAccessibilityFeatures = new Set(
                      accessibilityFeaturesSet
                    )
                    if (value) {
                      newAccessibilityFeatures.add(af)
                    } else {
                      newAccessibilityFeatures.delete(af)
                    }
                    onFeaturesChange(Array.from(newAccessibilityFeatures))
                  }}
                />
              </Grid>
            )
          )}
        </Grid>
      </AccessibilityFeatureWrapper>
      <TextArea
        name="accessibility_notes"
        value={accessibilityNotes || ''}
        label={
          <F defaultMessage="Additional accessibility details (optional)" />
        }
        hint={
          <F defaultMessage="Other information explaining accessibility accommodations for this event" />
        }
        onChange={onNotesChange}
        autoComplete="nope"
      />
    </>
  )
}

export default AccessibilityDetails
