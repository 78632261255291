import {
  EventsFetcherCarousel,
  FecDisclaimer,
  LandingPageList,
} from 'events/components'
import {isFiltered, maybeGetSingleSelectedOrgName} from 'util/feed'
import styles, {mediaMaxWidthSmall} from 'components/styles'

import {CarouselCategory} from 'app/enums'
import EventCampaignDetails from 'events/host/EventCampaignDetails'
import EventList from './EventList'
import {F} from 'util/i18n'
import FeedBanners from './FeedBanners'
import FeedHeader from './FeedHeader'
import FilterPanel from './FilterPanel'
import OrgCreationPrompt from './OrgCreationPrompt'
import OrgSuggestionsCarousel from 'events/components/OrgSuggestionsCarousel'
import flag from 'experiments/flag'
import {hasFecDisclaimer} from 'util/organization'
import styled from '@emotion/styled/macro'

const EventFeedWrapper = styled.div`
  background-color: ${styles.colors.neutral100};
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  /*
    XXX(mime): This is to prevent the carousel from making the
      whole page scroll offscreen on Safari.
  */
  overflow-x: clip;
`

const EventListWrapper = styled.div`
  background-color: ${styles.colors.neutral100};
  padding-bottom: ${styles.space.m};
  flex-grow: 1;
`

const FecWrapper = styled.div`
  margin-bottom: ${styles.space.m};
`

const StickyFilterWrapper = styled.div`
  border-top: 1px solid ${styles.colors.neutral300};
  ${mediaMaxWidthSmall(`
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: #f9f9f9;
    border-bottom: 1px solid ${styles.colors.neutral300};
    box-shadow: 0px 3px 3px rgb(204 204 204 / 50%);
    max-height: 500px;
    overflow-y: scroll;
    overscroll-behavior: none;
  `)};
`

const CAROUSELS = [
  CarouselCategory.HIGH_PRIORITY,
  CarouselCategory.NEAR_YOU,
  CarouselCategory.STATEWIDE,
  CarouselCategory.POPULAR_VIRTUAL,
  CarouselCategory.POPULAR_VIRTUAL_FLEXIBLE,
  CarouselCategory.GROUPS_FOR_ORG,
]

function EventFeed({
  events,
  organization,
  onFilterChange,
  isLoading,
  filterParams,
  pagingInfo,
  shownOrgs,
  filterTags,
  orgTagIds,
  isGeolocated,
  geolocationName,
  landingPage,
  eventCampaign,
  currentVolunteer,
  group,
}) {
  const hasFilters = isFiltered(filterParams)

  // Show the filter panel if there are events (that can be filtered) or if there are filters
  // applied (that can be changed). Also make sure the kill switch is off.
  const showFilterPanel =
    !flag.isActive('kill_feed_filters') && (events.length > 0 || hasFilters)

  // Show the carousels if (1) we're on the first results page,
  // (2) there are no filters applied and (3) we're not on a landing page
  const shouldShowCarousels =
    pagingInfo.page === 1 && !hasFilters && !landingPage && !eventCampaign

  const maybeSelectedOrgName = maybeGetSingleSelectedOrgName(
    filterParams,
    events
  )

  // Header is set for landing pages and mobilize org.
  const feedHeader = landingPage ? (
    landingPage.title
  ) : organization.is_mobilize && maybeSelectedOrgName ? (
    <F
      defaultMessage="Take action with {selectedOrgName}"
      values={{selectedOrgName: maybeSelectedOrgName}}
    />
  ) : group ? (
    <F
      defaultMessage="Events and actions for {groupName}"
      values={{groupName: group.name}}
    />
  ) : organization.is_mobilize ? (
    <F defaultMessage="Events, Petitions, and Volunteer Opportunities" />
  ) : undefined

  // Description is set for landing pages and the mobilize org when filtered to a single org
  const feedDescription = landingPage ? (
    landingPage.description
  ) : organization.is_mobilize && maybeSelectedOrgName ? (
    <F
      defaultMessage="Events, petitions, volunteer opportunities, and actions with {selectedOrgName}"
      values={{selectedOrgName: maybeSelectedOrgName}}
    />
  ) : undefined

  // If header is present or page is an event campaign landing page, do not render a feed banner.
  const showFeedBanner = !feedHeader && !eventCampaign

  if (isLoading) {
    // TODO(mime): need a better loading state here.
    return null
  }

  return (
    <EventFeedWrapper>
      {showFeedBanner && (
        <FeedBanners
          organization={organization}
          pagingInfo={pagingInfo}
          currentVolunteer={currentVolunteer}
        />
      )}
      {eventCampaign && (
        <EventCampaignDetails
          eventCampaign={eventCampaign}
          organization={organization}
        />
      )}
      {showFilterPanel && (
        <StickyFilterWrapper>
          <FilterPanel
            onFilterChange={onFilterChange}
            filterParams={filterParams}
            shownOrgs={shownOrgs}
            filterTags={filterTags}
            orgTagIds={orgTagIds || []}
            organization={organization}
          />
        </StickyFilterWrapper>
      )}
      <FeedHeader header={feedHeader} description={feedDescription} />
      {shouldShowCarousels && (
        <>
          {CAROUSELS.map((carouselCategory) => (
            <EventsFetcherCarousel
              key={carouselCategory}
              organization={organization}
              carouselCategory={carouselCategory}
              categoryLikelyHasEvents={
                carouselCategory === CarouselCategory.HIGH_PRIORITY
                  ? events.some((evt) => evt.is_featured)
                  : undefined
              }
              signupSource={`one_tap_carousel_feed_${carouselCategory}`}
            />
          ))}
          <OrgSuggestionsCarousel
            carouselCategory={CarouselCategory.ORGS_WITH_UPCOMING_EVENTS}
            organization={organization}
          />
        </>
      )}

      <EventListWrapper>
        <EventList
          events={events}
          filterParams={filterParams}
          onFilterChange={onFilterChange}
          organization={organization}
          pagingInfo={pagingInfo}
          isGeolocated={isGeolocated}
          geolocationName={geolocationName}
        />
      </EventListWrapper>
      <LandingPageList organization={organization} />

      <OrgCreationPrompt organization={organization} />

      {hasFecDisclaimer(organization) && (
        <FecWrapper>
          <FecDisclaimer organization={organization} />
        </FecWrapper>
      )}
    </EventFeedWrapper>
  )
}
export default EventFeed
