import {
  AccessibilityStatus,
  AuthProviderConnectionState,
  CountryCode,
  EventVisibility,
  LoginMethod,
  PostSignupAsk,
} from 'app/enums'
import {
  BEGINNING_OF_DAY_24H_TIME,
  END_OF_DAY_24H_TIME,
  TIMESLOT_ROW_DATE_FORMAT,
  maybeTimeToTimezoneFormattedTime,
  timeToTimezoneFormattedTime,
} from 'util/time'
import {
  Button,
  Input,
  Message,
  MessageType,
  RadioGroup,
  Typography,
} from 'components'
import {Component, createRef} from 'react'
import {browserTimezone, maybeGetTzFromLatLon} from 'util/time'
import {
  hasFecDisclaimer,
  hasValidDonationPostSignupAskConfig,
  orgFlagIsActive,
} from 'util/organization'
import {
  isDonationCampaignHostedEvent,
  isGroupHostedEvent,
  parseEventType,
} from 'util/event'
import {
  isGroupEventCampaign,
  isPeerToPeerFundraisingCampaign,
} from 'util/eventCampaign'
import {partial, sample} from 'util/common'

import AccessibilityDetails from '../AccessibilityDetails'
import AuthenticateHostUser from './AuthenticateHostUser'
import {EMPTY_LOCATION_VALIDATION_RESULTS} from 'app/constants'
import {EMPTY_VIRTUAL_JOIN_DETAILS} from '../util'
import {FecDisclaimer} from 'events/components'
import GroupChatInput from '../GroupChatInput'
import GroupSuggestedEventsLinkingSettings from 'dashboard/events/create/GroupSuggestedEventsLinkingSettings'
import HostCreateEventBasicInfo from './HostCreateEventBasicInfo'
import HostCreateEventCommunications from './HostCreateEventCommunications'
import HostCreateEventFormHeader from './HostCreateEventFormHeader'
import HostCreateEventInstructions from './HostCreateEventInstructions'
import HostCreateLocation from './HostCreateLocation'
import HostCreateTimeslots from './HostCreateTimeslots'
import HostEditCoHosts from './HostEditCoHosts'
import {HostFormHeadingWrapper} from './HostCreateEventComponents'
import LoginModal from 'app/login/LoginModal'
import OwningGroupsLinkingSettings from 'dashboard/events/create/OwningGroupsLinkingSettings'
import analytics from 'analytics'
import api from 'data/api'
import {connect} from 'react-redux'
import cv from 'util/clientVars'
import {dollarStringToCents} from 'util/currency'
import {getEventCategoryName} from './util'
import {getOneLineAddressFromLocation} from 'util/geo'
import {getQueryParams} from 'util/url'
import {maybeStringToMaybeInt} from 'util/string'
import moment from 'vendor/moment'
import {setCurrentVolunteer} from 'redux/actions'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {toRelativeUrl} from 'util/routing'
import {validatePotentialTimeslot} from 'util/timeslot'

const HostForm = styled.form`
  margin-top: ${styles.space.xl};
  padding-bottom: ${styles.space.xl};
`

const HostButtonWrapper = styled.div`
  margin-top: ${styles.space.s};
  margin-bottom: ${styles.space.s};
`

const FecWrapper = styled.div`
  margin-bottom: ${styles.space.m};
`

class HostCreateEventForm extends Component {
  formRef = createRef()
  constructor(props) {
    super(props)

    const {
      data,
      currentVolunteer,
      hasLoggedInOnThisBrowser,
      trackingParams,
      eventCreateSource,
    } = props
    const {
      user,
      event: hostedEvent,
      event_campaign: eventCampaign,
      current_organization: currentOrganization,
    } = data
    const first_name =
      user && user.first_name ? user.first_name : currentVolunteer.firstName
    const last_name =
      user && user.last_name ? user.last_name : currentVolunteer.lastName
    const email = user && user.email ? user.email : currentVolunteer.email
    const contact_number =
      user && user.phone ? user.phone : currentVolunteer.phone
    const queryParams = getQueryParams()
    let eventTypeQueryParam
    if (
      queryParams &&
      queryParams.event_type &&
      typeof queryParams.event_type === 'string'
    ) {
      const parsedEventType = parseEventType(queryParams.event_type)
      if (eventCampaign.hosted_event_types.includes(parsedEventType)) {
        eventTypeQueryParam = parsedEventType
      }
    }

    const orgHasValidDonationSetup = hasValidDonationPostSignupAskConfig(
      currentOrganization
    )
    const isP2pFundraisingEventCampaign = isPeerToPeerFundraisingCampaign(
      eventCampaign
    )
    const isGroupCampaign = isGroupEventCampaign(eventCampaign)
    const useMultiDayTimeslot = isP2pFundraisingEventCampaign || isGroupCampaign
    const eventType =
      eventCampaign.hosted_event_types.length === 1
        ? eventCampaign.hosted_event_types[0]
        : eventTypeQueryParam || undefined

    const state = {
      hasLoggedInOnThisBrowser,
      hostedEvent: hostedEvent
        ? hostedEvent
        : {
            name:
              queryParams.event_name ||
              eventCampaign.hosted_event_default_name ||
              '',
            description:
              queryParams.event_description ||
              eventCampaign.hosted_event_default_description ||
              '',
            pro_tips: eventCampaign.hosted_event_default_private_details || '',
            image_url: sample(eventCampaign.hosted_event_images) || '',
            timezone: eventCampaign.hosted_event_default_timezone,
            location: {
              name: '',
              address_line1: '',
              address_line2: '',
              city: '',
              state: '',
              zipcode: '',
              country: CountryCode.UNITED_STATES,
              lat: null,
              lon: null,
            },
            timeslots: [
              {
                starts_at_naive: '',
                ends_at_naive: '',
                max_attendees: null,
                virtual_join_url: null,
              },
            ],
            host: {
              first_name: queryParams.host_first_name || first_name || '',
              last_name: queryParams.host_last_name || last_name || '',
              email: email || queryParams.host_email || '',
            },
            event_type: eventType,
            event_campaign: eventCampaign.id,
            visibility: EventVisibility.PUBLIC,
            contact_number: queryParams.host_phone || contact_number || '',
            distributed_organizing_create_source: eventCreateSource,
            utm_source: trackingParams.utm_source,
            utm_medium: trackingParams.utm_medium,
            utm_campaign: trackingParams.utm_campaign,
            utm_term: trackingParams.utm_term,
            utm_content: trackingParams.utm_content,
            utm_adgroup: trackingParams.utm_adgroup,
            referrer: trackingParams.referrer,
            actionkit_akid: trackingParams.actionkit_akid,
            actionkit_source: trackingParams.actionkit_source,
            accessibility_notes: '',
            accessibility_status: AccessibilityStatus.NOT_SURE,
            accessibility_features: [],
            tags: eventCampaign.tags,
            location_is_private: false,
            is_virtual: isP2pFundraisingEventCampaign || isGroupCampaign,
            chat_enabled:
              orgFlagIsActive(currentOrganization, 'enable_chat') &&
              currentOrganization.chat_enabled,
            contact_host_enabled: true,
            post_signup_asks: [
              // Default to everything on, adding on donation if available based on org settings.
              PostSignupAsk.SOCIAL_SHARING,
              PostSignupAsk.EVENT_SUGGESTIONS,
              PostSignupAsk.SHIFT_SUGGESTIONS,
              ...(orgHasValidDonationSetup ? [PostSignupAsk.DONATION] : []),
            ],
            owning_org_van_location_id: null,
            co_hosts: [],
            primary_locale: cv.default_locale,
            fundraising_goal_amount_in_cents:
              eventCampaign.hosted_event_default_fundraising_goal_amount_in_cents,
            registration_mode: eventCampaign.hosted_event_registration_mode,
            fundraiser_config_id:
              eventCampaign.hosted_event_fundraiser_config_id,
            group_should_include_all_events_by_hosts_by_default: isGroupCampaign,
          },
      defaultLocationValue: '',
      locationValidations: {...EMPTY_LOCATION_VALIDATION_RESULTS},
      timeslotErrorMessage: null,
      submitErrorMessage: null,
      eventTypeErrorMessage: null,
      loggedOutOrganizerError: false,
      loginModalOpen: false,
      authenticationState: {
        continuedWithEmail: false,
        authProviderConnectionState: AuthProviderConnectionState.NOT_ATTEMPTED,
        authProviderErrorMessage: null,
      },
      formSubmitting: false,
      timezoneFetchFailed: false,
      // timeslotsToEdit is where timeslot changes are tracked while the form
      // is being edited
      timeslotsToEdit: [
        {
          date: useMultiDayTimeslot
            ? moment().format(TIMESLOT_ROW_DATE_FORMAT)
            : eventCampaign.hosted_event_default_date || '',
          startTime: useMultiDayTimeslot
            ? BEGINNING_OF_DAY_24H_TIME
            : maybeTimeToTimezoneFormattedTime(
                eventCampaign.hosted_event_default_start,
                eventCampaign.hosted_event_default_timezone
              ) || '',
          endTime: useMultiDayTimeslot
            ? END_OF_DAY_24H_TIME
            : maybeTimeToTimezoneFormattedTime(
                eventCampaign.hosted_event_default_end,
                eventCampaign.hosted_event_default_timezone
              ) || '',
          endDate: useMultiDayTimeslot
            ? !!eventCampaign.hosted_event_range_end
              ? eventCampaign.hosted_event_range_end.substr(0, 10)
              : null
            : undefined,
          deleted: false,
          maxAttendees: null,
          attendingCount: 0,
          ...EMPTY_VIRTUAL_JOIN_DETAILS,
        },
      ],
      perTimeslotErrors: [],
    }

    if (hostedEvent) {
      const {timeslots, location} = hostedEvent
      if (timeslots) {
        state.timeslotsToEdit = timeslots.map((timeslot) => ({
          date: timeslot.starts_at_naive.substr(0, 10),
          startTime: timeslot.starts_at_naive.substr(11, 5),
          endDate: timeslot.ends_at_naive.substr(0, 10),
          endTime: timeslot.ends_at_naive.substr(11, 5),
          id: timeslot.id,
          deleted: timeslot.deleted,
          maxAttendees: timeslot.max_attendees,
          attendingCount: timeslot.attendee_count,
          virtualJoinUrl: timeslot.virtual_join_url,
          // always null for now since we're not sending it to the server anyway
          privateDetails: null,
        }))
      }
      state.defaultLocationValue =
        location && getOneLineAddressFromLocation(location)
    }

    this.state = state
  }

  // Submit button is hidden if logged out and the user has neither
  // pressed "continue with email" nor authenticated with FB
  submitButtonShown = () => {
    // Logged in -> always enabled
    if (this.props.data.user) {
      return true
    }
    const {
      continuedWithEmail,
      authProviderConnectionState,
    } = this.state.authenticationState
    return (
      continuedWithEmail ||
      authProviderConnectionState === AuthProviderConnectionState.SUCCEEDED
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()
    const {
      eventId,
      data,
      currentVolunteer,
      trackingParams,
      trackVolunteerEventCreationForOrg,
    } = this.props
    const {current_organization: org, user} = data
    const {hostedEvent, locationValidations, timeslotsToEdit} = this.state
    const {locationInUnsupportedCountry} = locationValidations
    const {host} = hostedEvent

    if (locationInUnsupportedCountry) {
      return
    }

    // translate timeslot type from TimeslotRow to ApiTimeslot
    const timeslotsToSubmit = timeslotsToEdit.map((timeslot) => {
      // for multi day timeslots, only use EndDate
      const useMultiDayTimeslot =
        isDonationCampaignHostedEvent(hostedEvent) ||
        isGroupHostedEvent(hostedEvent)
      const endDateToUse = useMultiDayTimeslot
        ? timeslot.endDate
        : timeslot.date

      return {
        starts_at_naive:
          timeslot.startTime && timeslot.date
            ? `${timeslot.date}T${timeslot.startTime}`
            : '',
        ends_at_naive:
          timeslot.endTime && endDateToUse
            ? `${endDateToUse}T${timeslot.endTime}`
            : '',
        id: timeslot.id,
        deleted: timeslot.deleted,
        max_attendees: timeslot.maxAttendees,
        virtual_join_url: timeslot.virtualJoinUrl,
        // private_details intentionally omitted as the UI doesn't support it yet.
        // this way if an organizer sets it, host edits won't get rid of it
      }
    })
    const eventToSubmit = {...hostedEvent, timeslots: timeslotsToSubmit}
    const newTimeslotErrors = this.validateTimeslots(timeslotsToEdit)

    this.setState({formSubmitting: true})
    if (!hostedEvent.event_type) {
      this.setState({
        eventTypeErrorMessage: 'Please select an event type.',
        formSubmitting: false,
      })
      return
    } else if (!hostedEvent.location.zipcode) {
      this.setState({
        locationValidations: {
          ...locationValidations,
          locationNotSpecificEnough: true,
        },
        formSubmitting: false,
      })
      return
    } else if (
      timeslotsToSubmit.some((ts) => !ts.starts_at_naive || !ts.ends_at_naive)
    ) {
      this.setState({
        timeslotErrorMessage: 'Start time, end time, and date are required.',
        formSubmitting: false,
      })
      return
    } else if (newTimeslotErrors.filter((t) => t).length > 0) {
      this.setState({
        perTimeslotErrors: newTimeslotErrors,
        formSubmitting: false,
      })
      return
    } else if (!this.submitButtonShown()) {
      this.setState({
        submitErrorMessage:
          'Please choose a method of authentication (either Facebook or email) to continue',
        formSubmitting: false,
      })
      return
    }
    this.setState({
      eventTypeErrorMessage: null,
      submitErrorMessage: null,
      locationValidations: {
        ...locationValidations,
        locationNotSpecificEnough: false,
      },
    })
    try {
      let response
      const isCreateRequest = !eventId

      if (!isCreateRequest && eventId) {
        const hostedEventUpdateRequest = {
          name: eventToSubmit.name,
          description: eventToSubmit.description,
          pro_tips: eventToSubmit.pro_tips,
          timezone: eventToSubmit.timezone,
          location: eventToSubmit.location,
          timeslots: eventToSubmit.timeslots,
          contact_number: eventToSubmit.contact_number,
          host: eventToSubmit.host,
          visibility: eventToSubmit.visibility,
          event_type: eventToSubmit.event_type,
          accessibility_notes: eventToSubmit.accessibility_notes,
          accessibility_status: eventToSubmit.accessibility_status,
          accessibility_features: eventToSubmit.accessibility_features,
          tags: eventToSubmit.tags,
          location_is_private: eventToSubmit.location_is_private,
          is_virtual: eventToSubmit.is_virtual,
          chat_enabled: eventToSubmit.chat_enabled,
          contact_host_enabled: eventToSubmit.contact_host_enabled,
          post_signup_asks: eventToSubmit.post_signup_asks,
          owning_org_van_location_id: eventToSubmit.owning_org_van_location_id,
          co_hosts: eventToSubmit.co_hosts,
          primary_locale: eventToSubmit.primary_locale,
          fundraising_goal_amount_in_cents:
            eventToSubmit.fundraising_goal_amount_in_cents,
          fundraiser_config_id: eventToSubmit.fundraiser_config_id,
          registration_mode: eventToSubmit.registration_mode,
          group_suggested_events: eventToSubmit.group_suggested_events,
          group_should_include_all_events_by_hosts_by_default:
            eventToSubmit.group_should_include_all_events_by_hosts_by_default,
          owning_groups: eventToSubmit.owning_groups,
        }
        response = await api.hostUpdateEvent(
          org.slug,
          eventId,
          hostedEventUpdateRequest
        )
      } else {
        analytics.trackHostedEventSubmitted({
          ...analytics.maybeGetHostedEventProperties(eventToSubmit),
          org_id: org.slug,
          event_creation_source: trackingParams.event_creation_source,
        })
        trackVolunteerEventCreationForOrg(eventToSubmit)
        response = await api.hostCreateEvent(org.slug, eventToSubmit)
      }

      // Event was created successfully.
      if (response.event_id && isCreateRequest) {
        analytics.trackHostedEventCreated({
          event_id: response.event_id,
          ...analytics.maybeGetHostedEventProperties(eventToSubmit),
          org_id: org.slug,
          user_id: response.owner.id,
          event_creation_source: trackingParams.event_creation_source,
        })
      }

      // event was created/updated OR if the user has been blocked by the org.
      // for blocked users, want to store their info in localstorage + identify
      // to analytics to keep autofilling their blocked info. also in both
      // cases continue to the next url the server sends us to (either verify
      // or dashboard events)
      if (response.event_id || response.user_is_blocked) {
        this.props.setCurrentVolunteer({
          ...currentVolunteer,
          firstName: host.first_name,
          lastName: host.last_name,
          email: host.email,
          phone: hostedEvent.contact_number,
        })
        analytics.maybeIdentifyUser(
          {
            id: response.owner.id,
            email: response.owner.email,
          },
          {identifyToChameleon: true}
        )
        this.props.history.push(toRelativeUrl(response.next))
        return
      } else if (!!response.prevention_reason) {
        // Creation was prevented
        if (response.prevention_reason === 'logged_out_organizer') {
          this.setState({loggedOutOrganizerError: true})
        }
      }
    } catch (error) {
      this.setState({
        submitErrorMessage: error.message,
      })

      // Track invalid zipcode submissions
      // TODO(jared) figure out a way to make this way less ad-hoc
      analytics.maybeTrackZipcodeError(
        error,
        org,
        user,
        hostedEvent.location.zipcode
      )
    }
    this.setState({formSubmitting: false})
  }

  onEventFieldChange = (e) => {
    const {name, value} = e.target
    this.setState({
      hostedEvent: {
        ...this.state.hostedEvent,
        [name]:
          name.includes('amount_in_cents') && value
            ? dollarStringToCents(value)
            : value,
      },
    })
  }
  onEventRadioSelectionChange = (fieldName, value) => {
    this.setState({
      hostedEvent: {
        ...this.state.hostedEvent,
        [fieldName]: value,
      },
    })
  }
  onEventCheckboxSelectionChange = ({name, value}) => {
    this.setState({
      hostedEvent: {
        ...this.state.hostedEvent,
        [name]: value,
      },
    })
  }
  onHostFieldChange = (e) => {
    const {name, value} = e.target
    this.setState({
      hostedEvent: {
        ...this.state.hostedEvent,
        host: {
          ...this.state.hostedEvent.host,
          [name]: value,
        },
      },
    })
  }
  onTimeslotsChange = (timeslots) => {
    this.setState({
      timeslotsToEdit: timeslots,
      perTimeslotErrors: this.validateTimeslots(timeslots),
      timeslotErrorMessage: '',
    })
  }
  onTimezoneChange = (timezone) => {
    // If the org set pre-set times, update the times based on the new timezone. Otherwise, these
    // will just be the same times as before.

    const updatedTimeslots = this.state.timeslotsToEdit.map((timeslot) => {
      const currentStartTime = timeslot.startTime || ''
      const currentEndTime = timeslot.endTime || ''
      const {startTime, endTime} = this.reformatStartAndEndWithTimezoneIfPreset(
        timezone,
        currentStartTime,
        currentEndTime
      )
      return {...timeslot, startTime, endTime}
    })

    this.setState({
      timeslotsToEdit: updatedTimeslots,
      hostedEvent: {...this.state.hostedEvent, timezone},
    })
  }
  onOwningOrgVanLocationIdChange = (e) => {
    const owning_org_van_location_id = maybeStringToMaybeInt(
      e.currentTarget.value
    )
    this.setState({
      hostedEvent: {
        ...this.state.hostedEvent,
        owning_org_van_location_id,
      },
    })
  }
  onCoHostsChange = (coHosts) => {
    this.setState({
      hostedEvent: {
        ...this.state.hostedEvent,
        co_hosts: coHosts,
      },
    })
  }
  onHostChange = (hostUpdate) => {
    this.setState({
      hostedEvent: {
        ...this.state.hostedEvent,
        host: {
          ...this.state.hostedEvent.host,
          ...hostUpdate,
        },
      },
    })
  }

  onEventTypeChange = (e, value) => {
    this.setState({
      eventTypeErrorMessage: null,
      hostedEvent: {
        ...this.state.hostedEvent,
        event_type: value,
      },
    })
  }

  onAuthenticationStateChange = (authenticationState) => {
    this.setState({
      authenticationState,
    })
  }

  showLoginModal = () => {
    this.setState({loginModalOpen: true})
  }

  hideLoginModal = () => {
    this.setState({loginModalOpen: false})
  }

  trackLoginSucceeded = (loginMethod, accountInfo) => {
    const {user, organizer_logged_in_until_ts} = accountInfo
    const {has_logged_in, id} = user
    analytics.trackLoginSucceeded({
      user_id: id,
      has_previously_logged_in: has_logged_in,
      login_method: loginMethod,
      organizer_logged_in_until_ts,
    })
  }

  validateTimeslots = (timeslots) => {
    const newTimeslotErrors = timeslots.map((timeslot) => {
      if (timeslot.id) {
        return null
      }
      if (!timeslot.date || !timeslot.startTime || !timeslot.endTime) {
        return null
      }

      const useMultiDayTimeslot =
        isDonationCampaignHostedEvent(this.state.hostedEvent) ||
        isGroupHostedEvent(this.state.hostedEvent)
      return validatePotentialTimeslot(
        timeslot.date || '',
        timeslot.date || '',
        timeslot.startTime || '',
        timeslot.endTime || '',
        this.state.hostedEvent.timezone,
        timeslot.virtualJoinUrl,
        useMultiDayTimeslot
      )
    })
    return newTimeslotErrors
  }
  /** End timeslot control methods */

  reformatStartAndEndWithTimezoneIfPreset(
    timezone,
    currentStartTime,
    currentEndTime
  ) {
    // If the org set a pre-set time, get the new times based on the new timezone. Let callers
    // actually do the state-setting
    let startTime = currentStartTime
    let endTime = currentEndTime
    const {
      hosted_event_default_start,
      hosted_event_default_end,
    } = this.props.data.event_campaign
    if (hosted_event_default_start) {
      startTime = timeToTimezoneFormattedTime(
        hosted_event_default_start,
        timezone
      )
    }
    if (hosted_event_default_end) {
      endTime = timeToTimezoneFormattedTime(hosted_event_default_end, timezone)
    }
    return {startTime, endTime}
  }

  clearLocationValidations = () => {
    this.setState({
      locationValidations: {...EMPTY_LOCATION_VALIDATION_RESULTS},
    })
  }

  updateLocationValidations = (validations, addressOneLiner) => {
    if (addressOneLiner === '') {
      this.clearLocationValidations()
      return
    }
    this.setState({locationValidations: validations})
    this.logAnalyticsEventForLocationError(validations, addressOneLiner)
  }

  logAnalyticsEventForLocationError(validations, addressOneLiner) {
    let errorCode
    if (validations.locationInUnsupportedCountry) {
      errorCode = analytics.EventLocationErrorCode.UNSUPPORTED_COUNTRY
    } else if (validations.locationNotSpecificEnough) {
      errorCode = analytics.EventLocationErrorCode.NOT_SPECIFIC_ENOUGH
    }
    if (errorCode) {
      analytics.trackEventLocationError(this.props.data.current_organization, {
        address_string: addressOneLiner,
        user_id: this.props.data.user ? this.props.data.user.id : null,
        code: errorCode,
      })
    }
  }

  onLocationChange = async (data) => {
    const {addressOneLiner, location, validations} = data
    const {lat, lon} = location

    if (validations) {
      this.updateLocationValidations(validations, addressOneLiner)
    }

    // Attempt to get the timezone from the lat/lng; fall back on the browser timezone
    let newTimezone = browserTimezone()
    let timezoneFetchFailed = true

    if (lat && lon) {
      const maybeTimezoneFromLatLng = await maybeGetTzFromLatLon({
        lat,
        lon,
      })
      newTimezone = maybeTimezoneFromLatLng || browserTimezone()
      timezoneFetchFailed = !maybeTimezoneFromLatLng
    }

    // If the org set pre-set times, update the times based on the new timezone. Otherwise, these
    // will just be the same times as before.
    const updatedTimeslots = this.state.timeslotsToEdit.map((timeslot) => {
      const currentStartTime = timeslot.startTime || ''
      const currentEndTime = timeslot.endTime || ''
      const {startTime, endTime} = this.reformatStartAndEndWithTimezoneIfPreset(
        newTimezone,
        currentStartTime,
        currentEndTime
      )
      return {...timeslot, startTime, endTime}
    })

    this.setState(
      {
        hostedEvent: {
          ...this.state.hostedEvent,
          location,
          timezone: newTimezone,
        },
        timeslotsToEdit: updatedTimeslots,
        timezoneFetchFailed,
        timeslotErrorMessage: null,
      },
      () => {
        this.onTimeslotsChange(updatedTimeslots)
      }
    )
  }

  // copied from Header. i would love if we had only one LoginModal
  onModalFacebookConnected = (accountInfo) => {
    this.trackLoginSucceeded(LoginMethod.FACEBOOK, accountInfo)
    window.location.reload()
  }

  onModalGoogleConnected = (accountInfo) => {
    this.trackLoginSucceeded(LoginMethod.GOOGLE, accountInfo)
    window.location.reload()
  }

  onClickLogin = async (evt) => {
    evt.preventDefault() // Stop the window from scrolling to the top
    this.showLoginModal()
  }

  onLoginSucceeded = (loginMethod, accountInfo) => {
    this.trackLoginSucceeded(loginMethod, accountInfo)

    const currentSubmitForm = this.formRef.current
    if (currentSubmitForm) {
      // NB(avi): There's no good polyfill for this, but we can just rely on backend validation
      if (!!currentSubmitForm.reportValidity) currentSubmitForm.reportValidity()
      currentSubmitForm.dispatchEvent(new Event('submit'))
    }
  }

  render() {
    const {eventId, data} = this.props
    const {
      current_organization: org,
      user,
      event_campaign: eventCampaign,
      event_permissions: eventPermissions,
    } = data
    const {
      hasLoggedInOnThisBrowser,
      hostedEvent,
      defaultLocationValue,
      locationValidations,
      timeslotErrorMessage,
      eventTypeErrorMessage,
      submitErrorMessage,
      loggedOutOrganizerError,
      loginModalOpen,
      formSubmitting,
      timezoneFetchFailed,
      timeslotsToEdit,
      perTimeslotErrors,
      authenticationState,
    } = this.state
    const {
      locationNotSpecificEnough,
      locationInUnsupportedCountry,
    } = locationValidations
    const canEditTrustedFields = !eventId || data.can_edit_trusted_fields
    const contactEmail =
      eventCampaign.dist_org_reply_to_email || org.reply_to_email || null

    // Location errors block form submission and display the error
    const locationError =
      locationNotSpecificEnough || locationInUnsupportedCountry

    // note van location id input will only show for organizers and admins
    // because we only expand has_van_event_campaign_config for logged in
    // organizers and admins
    const showVanLocationIdInput =
      orgFlagIsActive(org, 'enable_gotv_event_specify_van_location_id') &&
      eventCampaign.has_van_event_campaign_config

    const showOwningGroupsSettings =
      orgFlagIsActive(org, 'enable_groups') &&
      !!eventId &&
      !isGroupHostedEvent(hostedEvent)

    const isDonationCampaign = isDonationCampaignHostedEvent(hostedEvent)
    const isGroupCampaign = isGroupEventCampaign(eventCampaign)
    const useMultiDayTimeslot = isDonationCampaign || isGroupCampaign

    const eventCategoryName = getEventCategoryName(
      isDonationCampaign,
      isGroupCampaign
    )

    const hostIdsForAvailableOwningGroups = [
      hostedEvent.host,
      ...(hostedEvent.co_hosts || []),
    ]
      .map((h) => h.id)
      .filter(Boolean)

    return (
      <>
        <LoginModal
          isOpen={loginModalOpen}
          onRequestClose={this.hideLoginModal}
          onFacebookConnected={this.onModalFacebookConnected}
          onGoogleConnected={this.onModalGoogleConnected}
        />
        <HostCreateEventFormHeader eventCampaign={eventCampaign} />
        <HostCreateEventInstructions
          contactEmail={contactEmail}
          eventCampaign={eventCampaign}
          eventId={eventId}
          hasLoggedInOnThisBrowser={hasLoggedInOnThisBrowser}
          hostedEvent={hostedEvent}
          showLoginModal={this.showLoginModal}
        />

        <HostForm onSubmit={this.onSubmit} ref={this.formRef}>
          <HostCreateEventBasicInfo
            canEditTrustedFields={canEditTrustedFields}
            contactEmail={contactEmail}
            eventCampaign={eventCampaign}
            eventTypeErrorMessage={eventTypeErrorMessage}
            hostedEvent={hostedEvent}
            onEventFieldChange={this.onEventFieldChange}
            onEventTypeChange={this.onEventTypeChange}
            onTimeslotsChange={this.onTimeslotsChange}
            timeslotsToEdit={timeslotsToEdit}
          />

          <HostFormHeadingWrapper>
            <Typography variant="h2">Visibility</Typography>
          </HostFormHeadingWrapper>
          <RadioGroup
            name="visibility"
            options={[
              {
                text: `Public — Listed on ${org.name}'s public feed and on select partner feeds. Anyone can view the ${eventCategoryName} page.`,
                value: EventVisibility.PUBLIC,
              },
              {
                text: `Private — Only people with a link can view the ${eventCategoryName} page.`,
                value: EventVisibility.PRIVATE,
              },
            ]}
            value={hostedEvent.visibility}
            onChange={this.onEventRadioSelectionChange}
          />
          <HostFormHeadingWrapper>
            <Typography variant="h2">Location</Typography>
          </HostFormHeadingWrapper>
          <HostCreateLocation
            hostedEvent={hostedEvent}
            defaultLocationValue={defaultLocationValue}
            locationValidations={locationValidations}
            onLocationChange={this.onLocationChange}
            onLocationFieldChange={this.onEventRadioSelectionChange}
            editingEvent={!!eventId}
          />
          {showVanLocationIdInput && (
            <>
              <Typography variant="h2">VAN location</Typography>
              <Input
                name="owning_org_van_location_id"
                label="VAN location ID"
                value={hostedEvent.owning_org_van_location_id}
                onChange={this.onOwningOrgVanLocationIdChange}
              />
            </>
          )}
          {!useMultiDayTimeslot && (
            <>
              <HostFormHeadingWrapper>
                <Typography variant="h2">Date and time</Typography>
              </HostFormHeadingWrapper>
              <HostCreateTimeslots
                timeslots={timeslotsToEdit}
                perTimeslotErrors={perTimeslotErrors}
                onTimeslotsChange={this.onTimeslotsChange}
                eventCampaign={eventCampaign}
                timezoneFetchFailed={timezoneFetchFailed}
                timezone={hostedEvent.timezone}
                onTimezoneChange={this.onTimezoneChange}
                timeslotErrorMessage={timeslotErrorMessage}
                organizationName={org.name}
                eventIsVirtual={!!hostedEvent.is_virtual}
              />
            </>
          )}

          <HostCreateEventCommunications
            canEditTrustedFields={canEditTrustedFields}
            contactEmail={contactEmail}
            eventCampaign={eventCampaign}
            eventId={eventId}
            eventPermissions={eventPermissions}
            hostedEvent={hostedEvent}
            onEventFieldChange={this.onEventFieldChange}
            onEventCheckboxSelectionChange={this.onEventCheckboxSelectionChange}
            onEventRadioSelectionChange={this.onEventRadioSelectionChange}
          />

          {!isDonationCampaign && !isGroupCampaign && (
            <>
              <HostFormHeadingWrapper>
                <Typography variant="h2">Accessibility</Typography>
              </HostFormHeadingWrapper>

              <AccessibilityDetails
                accessibilityStatus={hostedEvent.accessibility_status}
                accessibilityNotes={hostedEvent.accessibility_notes}
                accessibilityFeatures={hostedEvent.accessibility_features || []}
                onStatusChange={this.onEventRadioSelectionChange}
                onNotesChange={this.onEventFieldChange}
                onFeaturesChange={(value) =>
                  this.setState({
                    hostedEvent: {
                      ...this.state.hostedEvent,
                      accessibility_features: value,
                    },
                  })
                }
              />
            </>
          )}

          {orgFlagIsActive(org, 'enable_chat') && !isDonationCampaign && (
            <>
              <HostFormHeadingWrapper>
                <Typography variant="h2">Group chat</Typography>
              </HostFormHeadingWrapper>
              <GroupChatInput
                editingEvent={!!eventId}
                onToggleChat={partial(
                  this.onEventRadioSelectionChange,
                  'chat_enabled',
                  !hostedEvent.chat_enabled
                )}
                chatEnabled={hostedEvent.chat_enabled}
                isGroupEvent={isGroupCampaign}
              />
            </>
          )}

          {isGroupHostedEvent(hostedEvent) && (
            <GroupSuggestedEventsLinkingSettings
              event={hostedEvent}
              onFieldChange={(_, update) => {
                if (update) {
                  // $FlowFixMe: the object literal here is not a full SyntheticInputEvent but is sufficient
                  this.onEventFieldChange({target: update})
                }
              }}
            />
          )}

          {showOwningGroupsSettings && (
            <OwningGroupsLinkingSettings
              event={hostedEvent}
              isCreating={false}
              hostIds={hostIdsForAvailableOwningGroups}
              onFieldChange={(_, update) => {
                if (update) {
                  // $FlowFixMe: the object literal here is not a full SyntheticInputEvent but is sufficient
                  this.onEventFieldChange({target: update})
                }
              }}
            />
          )}

          <HostEditCoHosts
            hostedEvent={hostedEvent}
            creatingEvent={!eventId}
            eventPermissions={eventPermissions}
            onCoHostsChange={this.onCoHostsChange}
          />
          <AuthenticateHostUser
            isLoading={formSubmitting}
            hostedEvent={hostedEvent}
            authenticationState={authenticationState}
            eventId={eventId}
            user={user}
            eventPermissions={eventPermissions}
            onHostChange={this.onHostChange}
            onAuthenticationStateChange={this.onAuthenticationStateChange}
            onLoginSucceeded={this.onLoginSucceeded}
          />
          {!formSubmitting && this.submitButtonShown() && (
            <HostButtonWrapper>
              <Button
                fluid
                disabled={
                  locationError ||
                  !!timeslotErrorMessage ||
                  perTimeslotErrors.filter((t) => t).length > 0
                }
                onClick={() => analytics.trackButtonClicked('host-event')}
              >
                {eventId ? 'Save' : `Host this ${eventCategoryName}`}
              </Button>
            </HostButtonWrapper>
          )}
          {submitErrorMessage && (
            <Message type={MessageType.ERROR}>{submitErrorMessage}</Message>
          )}
          {eventTypeErrorMessage && (
            <Message type={MessageType.ERROR}>{eventTypeErrorMessage}</Message>
          )}
          {(locationError ||
            timeslotErrorMessage ||
            perTimeslotErrors.filter((t) => t).length > 0) && (
            <Message type={MessageType.ERROR}>
              Please fix the above errors to continue.
            </Message>
          )}
          {loggedOutOrganizerError && (
            <Message type={MessageType.ERROR}>
              Looks like there's already an account associated with that email
              address. Please{' '}
              <Button padding="none" link onClick={this.onClickLogin}>
                log in
              </Button>{' '}
              to make your events from the dashboard
            </Message>
          )}
        </HostForm>
        {hasFecDisclaimer(org) && (
          <FecWrapper>
            <FecDisclaimer organization={org} />
          </FecWrapper>
        )}
      </>
    )
  }
}

const mapStateToProps = ({currentVolunteer, lastSeenLoggedInAt}) => ({
  currentVolunteer,
  hasLoggedInOnThisBrowser: lastSeenLoggedInAt !== null,
})
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentVolunteer: (vol) => {
      dispatch(setCurrentVolunteer(vol))
    },
  }
}

export const additionalTrackPageLoadedProps = (props) => {
  const {event_campaign} = props.data

  return {
    host_recurring_timeslots_visible: event_campaign.enable_recurring_timeslots,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HostCreateEventForm)
