import {defineMessages, useIntl} from 'util/i18n'

import mailbox from 'images/mailbox.svg'
import styled from '@emotion/styled/macro'
import styles from './styles'

const messages = defineMessages({
  imageAlt: {
    defaultMessage: 'Mailbox with letter inside',
  },
})

const MailboxStyledImage = styled.img`
  padding: ${styles.space.m};
  width: 76px;
  height: 76px;
`

export default function MailboxImage() {
  const intl = useIntl()
  return (
    <MailboxStyledImage
      alt={intl.formatMessage(messages.imageAlt)}
      src={mailbox}
    />
  )
}
