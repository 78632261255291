import {classnames} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from './styles'

const IconStyled = styled.i`
  ${(props) => props.huge && `font-size: ${styles.typography.hugeIcon}`};
  ${(props) => props.fontSize && `font-size: ${props.fontSize}`};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.checked === true && `margin-left: -${styles.space.l};`};
  ${(props) =>
    props.checked === false &&
    `margin-left: -${styles.space.l}; color: transparent;`};
  line-height: ${styles.typography.lineHeightM};
  text-align: center;
  ${(props) => props.primary && `color: ${styles.colors.primary200};`}
  ${(props) => props.success && `color: ${styles.colors.success300};`}
  ${(props) => props.error && `color: ${styles.colors.error200};`}
  ${(props) => props.caution && `color: ${styles.colors.caution200};`}
  ${(props) => props.secondary && `color: ${styles.colors.neutral400};`}
  ${(props) => !props.noMargin && `margin-right: 0.75rem;`}
`

const IconStyle = Object.freeze({
  SOLID: 'solid',
  REGULAR: 'regular',
  LIGHT: 'light',
  DUOTONE: 'duotone',
})

// Whitelist icons from the `fab` (brand) icon style here
const KNOWN_BRAND_ICONS = new Set([
  'facebook',
  'facebook-f',
  'facebook-square',
  'google',
  'twitter',
])
function getFontAwesomePrefix({name, loading, iconStyle}) {
  if (!loading && KNOWN_BRAND_ICONS.has(name)) {
    return 'fab' // brand
  }
  if (iconStyle === IconStyle.REGULAR) {
    return 'far'
  }
  if (iconStyle === IconStyle.LIGHT) {
    return 'fal'
  }
  if (iconStyle === IconStyle.DUOTONE) {
    return 'fad'
  }
  // iconStyle === IconStyle.SOLID
  return 'fas'
}

function Icon(props) {
  const {
    name,
    caution,
    checked,
    error,
    fontSize,
    huge,
    noMargin,
    primary,
    secondary,
    success,
    width,
    loading,
    style,
  } = props
  const iconProps = {
    caution,
    checked,
    className: classnames(
      getFontAwesomePrefix(props),
      loading ? 'fa-circle-notch fa-spin' : `fa-${name}`
    ),
    error,
    fontSize,
    huge,
    noMargin,
    primary,
    secondary,
    success,
    width,
    style,
    role: 'img',
    'aria-label': props['aria-label'] || `${name} icon`,
  }

  return <IconStyled {...iconProps} />
}

Icon.defaultProps = {
  iconStyle: IconStyle.SOLID,
}

export default Icon
