export function stripTrailingSlash(str) {
  if (str.endsWith('/')) {
    return str.slice(0, -1)
  }
  return str
}

// [a, b, c, d] to "a, b, c and d"
// credit: https://stackoverflow.com/a/16251861
export function toDisplayList(arr) {
  return [arr.slice(0, -1).join(', '), arr.slice(-1)[0]].join(
    arr.length < 2 ? '' : ' and '
  )
}

export function defaultIfNotString(s, d) {
  return typeof s === 'string' ? s : d
}

/** Get the display name for a React.Component */
export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export function maybeStringToMaybeInt(str) {
  const maybeInt = parseInt(str, 10)
  return isNaN(maybeInt) ? null : maybeInt
}

export function maybeStringToTwoDecimalPlaces(value) {
  const parsedFloat = parseFloat(value)
  // Make sure numbers convert to string, also disallow zero and negative values
  if (isNaN(parsedFloat) || parsedFloat <= 0) {
    return null
  }
  // Don't add decimal places to integers
  if (parsedFloat % 1 === 0) {
    return parsedFloat.toFixed(0)
  }
  // Otherwise return the value to 2 decimal places
  return parsedFloat.toFixed(2)
}

export function formatDollarAmount(amount) {
  const maybeToTwoDecimalPlaces =
    maybeStringToTwoDecimalPlaces(amount) || amount
  return `$${maybeToTwoDecimalPlaces}`
}

export function formatPhoneNumber(phone) {
  if (phone.length !== 10) {
    // Don't attempt to format phone numbers that are not 10 digits
    return phone
  }
  return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`
}

// Credit to https://www.30secondsofcode.org/articles/s/js-remove-trailing-zeros
export function toFixedWithoutZeros(num, precision) {
  return `${Number.parseFloat(num.toFixed(precision))}`
}

export function getUserDisplayName(user) {
  return `${user.first_name} ${user.last_name}`.trim() || user.email
}

export function getEmailRecipientString(user) {
  const name = `${user.first_name} ${user.last_name}`.trim()
  if (name) {
    return `${name} (${user.email})`
  } else {
    return user.email
  }
}

export function getCreatorDisplayName(creator) {
  return (creator && getUserDisplayName(creator)) || '(None set)'
}

export function getVolunteerDisplayName(volunteer) {
  return `${volunteer.first_name} ${volunteer.last_name}`.trim() || '(None set)'
}

export function getVolunteerDisplayNameAndEmail(volunteer) {
  const name = `${volunteer.first_name} ${volunteer.last_name}`.trim()
  return `${name} · ${volunteer.email}`
}

export function getDisplayNameForFilteredSearchOptions(user) {
  const {first_name, last_name, email} = user
  const name = `${first_name} ${last_name}`.trim()
  if (name) {
    return `${name} · ${email}`
  }
  return email
}

/**
 * This function is used for generating initials for a circular avatar. It returns an empty string
 * if no first name, last name, or email is present.
 */
export function getInitialsFromNameOrEmail(user) {
  if (user) {
    const {first_name, last_name, email} = user
    const maybe_name = `${first_name[0] || ''}${last_name[0] || ''}`.trim()
    return (maybe_name || email[0] || '').toUpperCase()
  }
  return ''
}

export function maybeBoolToString(param) {
  if (param === null || param === undefined) {
    return null
  }
  return param ? 'Yes' : 'No'
}

export function getCharacterCount(s, maxLength) {
  return `${(s || '').length.toLocaleString()} / ${maxLength.toLocaleString()}`
}

export function capitalizeFirstLetter(s) {
  // Takes a string, returns a new string with the first letter capitalized. TODO(varun): not i18n friendly.
  return s.charAt(0).toUpperCase() + s.slice(1)
}
