// Keep in sync with associated constant in core/analytics.py
export const ANONYMOUS_ID_COOKIE_NAME = 'mobilize_anonymous_id_v2'
export const OLD_ANONYMOUS_ID_COOKIE_NAME = 'mobilize_anonymous_id'

// https://www.npmjs.com/package/cookie#options-1
export const ANONYMOUS_ID_COOKIE_OPTIONS = {
  path: '/',
  secure: true,
  sameSite: 'none',
  maxAge: 60 * 60 * 24 * 365, // One year
}

// Allow list for params used in middleware that should be included in all views
export const PARAMS_TO_ALWAYS_ALLOW = new Set([
  'logout_if_not_user_id', // Used in Middleware
])

// Params used on the event page (see views.public.EventView)
// NB: There are not currently any EventView-specific params, but leaving this
// in case we need to use it.
export const PARAMS_ALLOWED_ON_EVENT_SIGNUP_PAGE = new Set()

// TODO(jared) are these actually needed on the backend?
export const PARAMS_ALLOWED_ON_SELF_SERVICE = new Set([
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_name',
  'utm_adgroup',
  'promotion_only_parent',
])

// Only allow cloudflare location params on landing pages (for debugging purposes).
// Otherwise it doesn't currently depend on any
export const PARAMS_ALLOWED_ON_LANDING_PAGES = new Set([
  'cf_zip',
  'cf_lat',
  'cf_lon',
  // Paging params.
  'page',
  'per_page',
])

// Params used on the event feed (see views.public.EventFeedView)
export const PARAMS_ALLOWED_ON_FEED_PAGE = new Set([
  // Address params.
  'address',
  'cf_zip',
  'cf_lat',
  'cf_lon',
  'country',
  'lat',
  'lon',
  'max_dist',
  'state',
  'zip',
  // Date params.
  'date',
  'start_date',
  'end_date',
  // Paging params.
  'page',
  'per_page',
  // Filter by model.
  'event_campaign',
  'org_ids',
  'tag_ids',
  'owning_group_ids',
  'suggesting_group_ids',
  'promotable_for',
  // Other filters.
  'accessible_only',
  'accessibility_features',
  'event_type',
  'is_virtual',
  'is_virtual_flexible',
  'is_high_priority',
  'is_primary',
  'is_statewide',
  'q',
  'show_all_events',
  'volunteer_hosted_only',
  'primary_locale',
  'org_type',
  'race_type',
])

// These params are used only by the client. We can safely remove them
// to get more cache hits.
export const PARAMS_TO_REMOVE = [
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_name',
  'utm_adgroup',
  'source',
  'akid',
  'first_name',
  'last_name',
  'email',
  'phone',
  'json',
  '_ga',
  'share_medium',
  'share_context',
  'sharer_role',
  'followup_modal_context',
  'referring_data_signature',
  'referring_participation',
]

// Don't remove any query params from pathnames that start with any of these
export const PATHNAME_PREFIXES_TO_NOT_REMOVE_QUERY_PARAMS = [
  '/_/',
  '/dashboard/',
  '/me/',
  '/invite/',
  '/login/',
  '/logout/',
  '/legacy_login/',
  '/admin/',
  '/organization/create/',
]

// NOTE: keep in sync with api/enums.py
export const ORG_SLUG_BLOCKLIST = [
  'admin',
  'api',
  'dashboard',
  'event',
  'events',
  'test',
  'user',
  'login',
  'invite',
  'logout',
  'password_reset',
  'legacy_login',
  'about_passwordless',
  'embed',
  'schedule',
  'organization',
  'me',
  'reset',
  'notifications',
  'report_event',
  'host',
  'verify',
]

// Remove the following headers before passing requests to origin. Note that header names are
// case-insensitive
export const HEADER_BLOCKLIST_BY_PATHNAME_PREFIX = {
  '/static/': [
    'x-amz-security-token',
    'x-amz-server-side-encryption',
    'authorization',
  ],
}
