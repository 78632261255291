import Avatar, {AVATAR_SIZE} from 'components/Avatar'
import {Button, Divider, Menu, MenuItemLink} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {
  appendUserIdDiffersParamIfCrossDomain,
  getCurrentDomainLogoutUrl,
  getOrganizationDashboardEventsUrl,
  getOrganizationDashboardGetStartedUrl,
  getOrganizationFeedUrl,
  getOrganizationHostInterestUrl,
  getUserSettingsUrl,
} from 'util/routing'
import styles, {ellipsizedText} from 'components/styles'
import {useContext, useRef, useState} from 'react'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import CurrentUserContext from 'app/CurrentUserContext'
import {assert} from 'util/common'
import {createMailUrl} from 'util/url'
import {getInitialsFromNameOrEmail} from 'util/string'
import {getOrgCreationUrl} from 'util/routing'
import {isDistributedOrganizingDiscoverableForMaybeOrg} from 'util/organization'
import styled from '@emotion/styled/macro'
// $FlowFixMe (mime): types need updating for latest react-router
import {useLocation} from 'react-router'

const messages = defineMessages({
  accountMenuAriaLabel: {defaultMessage: 'Account menu'},
  menuButtonAriaLabel: {defaultMessage: 'Toggle account menu'},
  contactOrgSubject: {defaultMessage: 'Question about {orgName}'},
})

const MenuText = styled.span`
  color: ${styles.colors.black};
  font-weight: ${styles.typography.fontWeightBold};
`

// A section header with de-emphasized text and no divider.
// TODO(mime): Can't use 'body2' in Typography yet until we sort out the sizing there.
const MenuSectionHeader = styled.div`
  color: ${styles.colors.neutral400};
  text-transform: uppercase;
  border-top: 1px solid ${styles.colors.neutral300};
  margin-top: ${styles.space.s};
  font-size: ${styles.typography.fontSizeS};
  padding-top: ${styles.space.m};
  padding-left: 1.14rem; /* TODO(mime): this is to match Semantic, sigh */
  padding-right: ${styles.space.m};
`

const UserInfo = styled.div`
  display: flex;

  & > ${Avatar} {
    flex: 0 0 ${AVATAR_SIZE};
  }
`

const NameAndEmail = styled.div`
  margin-left: ${styles.space.m};
  color: ${styles.colors.black};
  flex: 0;
  width: calc(
    100% - ${styles.space.m} - ${styles.space.m} - ${AVATAR_SIZE}
  ); /* account for the avatar and padding */

  & > div {
    width: 100%;
    ${ellipsizedText}
  }
`

export default function LoggedInMenu({contactEmail, showHostEventLink}) {
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const {user} = useContext(CurrentUserContext)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const location = useLocation()
  const intl = useIntl()

  assert(user)

  // This is only here to make Flow happy and not have to assert
  // `user`s truthiness a 1000 times.
  if (!user) {
    return null
  }

  const isDistributedOrganizingDiscoverable = isDistributedOrganizingDiscoverableForMaybeOrg(
    currentOrganization
  )

  const maybeFeedUrl = currentOrganization
    ? getOrganizationFeedUrl(currentOrganization)
    : null

  const maybeEventDiscoveryPageUrl = currentOrganization
    ? getOrganizationHostInterestUrl(currentOrganization)
    : null

  const initials = getInitialsFromNameOrEmail(user)
  const fullName = `${user.first_name} ${user.last_name}`

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  const id = `mbz-header-menu`

  const getDashboardLandingPageUrl = (org) =>
    org.account_tier === 7
      ? getOrganizationDashboardGetStartedUrl(org)
      : getOrganizationDashboardEventsUrl(org)

  return (
    <>
      <Button
        link
        large
        iconFontSize={styles.typography.fontSizeL}
        aria-expanded={open}
        aria-label={intl.formatMessage(messages.menuButtonAriaLabel)}
        aria-controls={id}
        aria-haspopup
        data-track="Account Menu"
        icon={open ? 'times' : 'bars'}
        style={{minWidth: '110px'}}
        type="button"
        onClick={handleOpen}
        ref={anchorRef}
      >
        {open ? <F defaultMessage="Close" /> : <F defaultMessage="Menu" />}
      </Button>
      <Menu
        id={id}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        aria-label={intl.formatMessage(messages.accountMenuAriaLabel)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItemLink
          to={appendUserIdDiffersParamIfCrossDomain(getUserSettingsUrl(), user)}
        >
          <UserInfo>
            {initials.length > 0 && <Avatar>{initials}</Avatar>}
            <NameAndEmail>
              <div title={fullName}>
                <strong>{fullName}</strong>
              </div>
              <div title={user.email}>{user.email}</div>
            </NameAndEmail>
          </UserInfo>
        </MenuItemLink>
        <Divider />

        {maybeFeedUrl && maybeFeedUrl.pathname !== location.pathname && (
          <MenuItemLink
            to={
              user
                ? appendUserIdDiffersParamIfCrossDomain(maybeFeedUrl, user)
                : maybeFeedUrl
            }
          >
            <MenuText>
              <F defaultMessage="Take action" />
            </MenuText>
          </MenuItemLink>
        )}

        {maybeEventDiscoveryPageUrl &&
          isDistributedOrganizingDiscoverable &&
          showHostEventLink && (
            <MenuItemLink
              to={
                user
                  ? appendUserIdDiffersParamIfCrossDomain(
                      maybeEventDiscoveryPageUrl,
                      user
                    )
                  : maybeEventDiscoveryPageUrl
              }
            >
              <MenuText>
                <F defaultMessage="Host an event" />
              </MenuText>
            </MenuItemLink>
          )}

        {user.schedule_url && (
          <MenuItemLink
            to={appendUserIdDiffersParamIfCrossDomain(
              new URL(user.schedule_url),
              user
            )}
          >
            <MenuText>
              <F defaultMessage="My schedule" />
            </MenuText>
          </MenuItemLink>
        )}

        {contactEmail && (
          <MenuItemLink
            to={createMailUrl(
              contactEmail,
              intl.formatMessage(messages.contactOrgSubject, {
                orgName: currentOrganization?.name,
              })
            )}
            target="_blank"
          >
            <MenuText>
              <F defaultMessage="Contact organization" />
            </MenuText>
          </MenuItemLink>
        )}

        <MenuItemLink
          to={appendUserIdDiffersParamIfCrossDomain(getUserSettingsUrl(), user)}
        >
          <MenuText>
            <F defaultMessage="Account and notification settings" />
          </MenuText>
        </MenuItemLink>

        <MenuItemLink
          to={getCurrentDomainLogoutUrl({next: location.pathname})}
          // Without a hard nav we'll hit the screen data cache on certain pages and the user will
          // appear to remain logged in (even though their session has been destroyed)
          forceAnchorTag
        >
          <MenuText>
            <F defaultMessage="Log out" />
          </MenuText>
        </MenuItemLink>

        {user.member_orgs && user.member_orgs.length > 0 && (
          <MenuSectionHeader>
            <F defaultMessage="Organize for" />
          </MenuSectionHeader>
        )}
        {user.member_orgs &&
          user.member_orgs.length > 0 &&
          user.member_orgs.map((org) => (
            <MenuItemLink
              key={org.id}
              to={appendUserIdDiffersParamIfCrossDomain(
                getDashboardLandingPageUrl(org),
                user
              )}
            >
              <MenuText>{org.name}</MenuText>
            </MenuItemLink>
          ))}
        <MenuItemLink
          to={getOrgCreationUrl(
            'utm_source=mobilize&utm_medium=referral&utm_campaign=menu'
          )}
          target="_blank"
        >
          <MenuText>
            <F defaultMessage="Create a new dashboard…" />
          </MenuText>
        </MenuItemLink>
      </Menu>
    </>
  )
}
