import {CheckboxField, Message, MessageType, Typography} from 'components'
import {Component, createRef} from 'react'
import {
  NotificationSetting,
  dashboardEmailCategories,
  emailCategoryToSubtext,
  eventAttendanceEmailCategories,
  eventsOwnershipEmailCategories,
} from 'app/enums'

import {F} from 'util/i18n'
import SettingsCard from './SettingsCard'
import api from 'data/api'
import {partial} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {withRouter} from 'react-router'

const SettingWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${styles.colors.neutral300};
  padding-top: ${styles.space.s};
  padding-bottom: ${styles.space.s};

  :last-child {
    border: none;
  }
`
const EmailCategorySection = styled.div`
  margin-top: 15px;
`

const NOTIFICATIONS_ID = 'notifications'

class NotificationsUserSettingsCard extends Component {
  wrapperRef = createRef()

  // Try to pull the setting that changed out of the initial query params
  maybeGetLastChangedSetting = () => {
    const {initialQueryParams} = this.props
    if (initialQueryParams.meta === 'email' && initialQueryParams.category) {
      return (
        this.props.email_notification_settings[initialQueryParams.category] ||
        null
      )
    }

    return null
  }

  state = {
    email_notification_settings: {...this.props.email_notification_settings},
    lastChangedSetting: this.maybeGetLastChangedSetting(),
    error: null,
  }

  onSettingChange = async (category, {value}) => {
    const setting = value ? NotificationSetting.ALL : NotificationSetting.NONE
    const oldSettings = this.state.email_notification_settings

    const newSetting = {
      description: this.state.email_notification_settings[category].description,
      setting,
    }

    try {
      // Optimistically update UI; if there's an error, roll back change and render an error message
      this.setState({
        error: null,
        email_notification_settings: {
          ...oldSettings,
          [category]: newSetting,
        },
        lastChangedSetting: newSetting,
      })
      await api.changeUserEmailNotificationSetting(category, {setting})
    } catch (e) {
      const error =
        (e && e.message) ||
        'There was an error changing your notification setting. Please try again later.'
      this.setState({
        error,
        lastChangedSetting: null,
        email_notification_settings: {...oldSettings},
      })
    }
  }

  componentDidMount() {
    // Scroll into view if the url fragment is present
    if (
      this.props.location.hash === `#${NOTIFICATIONS_ID}` &&
      this.wrapperRef.current &&
      this.wrapperRef.current.scrollIntoView
    ) {
      this.wrapperRef.current.scrollIntoView()
    }
  }

  renderTogglesForCategories(categories) {
    const {email_notification_settings: emailNotificationSettings} = this.state

    return (
      <>
        {categories.map((category) => {
          const {description, setting} = emailNotificationSettings[category]

          return (
            <SettingWrapper key={category}>
              <div>
                <span>{description}</span>
                <br />
                <Typography variant="subtitle1">
                  {/* $FlowFixMe */}
                  {emailCategoryToSubtext(category)}
                </Typography>
              </div>
              <CheckboxField
                checked={setting === NotificationSetting.ALL}
                onChange={partial(this.onSettingChange, category)}
                type="switch"
              />
            </SettingWrapper>
          )
        })}
      </>
    )
  }

  renderEmailNotificationToggles() {
    const {email_notification_settings: emailNotificationSettings} = this.state

    const userEmailNotificationSettingsKeys = Object.keys(
      emailNotificationSettings
    )
    const userEventAttendanceCategories = userEmailNotificationSettingsKeys.filter(
      (en) => eventAttendanceEmailCategories.includes(parseInt(en))
    )
    const userEventOwnershipCategories = userEmailNotificationSettingsKeys.filter(
      (en) => eventsOwnershipEmailCategories.includes(parseInt(en))
    )
    const userDashboardCategories = userEmailNotificationSettingsKeys.filter(
      (en) => dashboardEmailCategories.includes(parseInt(en))
    )

    return (
      <>
        <EmailCategorySection>
          <Typography variant="h3">
            <F defaultMessage="For events you sign up for" />
          </Typography>
          {this.renderTogglesForCategories(userEventAttendanceCategories)}
        </EmailCategorySection>

        {userEventOwnershipCategories.length > 0 && (
          <EmailCategorySection>
            <Typography variant="h3">
              <F defaultMessage="For events you own" />
            </Typography>
            {this.renderTogglesForCategories(userEventOwnershipCategories)}
          </EmailCategorySection>
        )}

        {userDashboardCategories.length > 0 && (
          <EmailCategorySection>
            <Typography variant="h3">
              <F defaultMessage="About this dashboard" />
            </Typography>
            {this.renderTogglesForCategories(userDashboardCategories)}
          </EmailCategorySection>
        )}
      </>
    )
  }

  render() {
    const {lastChangedSetting, error} = this.state

    return (
      <div id={NOTIFICATIONS_ID} ref={this.wrapperRef}>
        <SettingsCard
          header="Notification Settings"
          footer="You can also unsubscribe from confirmation emails in the email footer."
        >
          {lastChangedSetting && (
            <Message type={MessageType.SUCCESS} data-testid="success-message">
              {getLastChangedEmailSettingMessage(lastChangedSetting)}
            </Message>
          )}
          {error && (
            <Message
              header="Oops"
              type={MessageType.ERROR}
              list={[error]}
              data-testid="error-message"
            />
          )}
          {this.renderEmailNotificationToggles()}
        </SettingsCard>
      </div>
    )
  }
}

export function getLastChangedEmailSettingMessage(notificationSetting) {
  const nowOrNoLonger =
    notificationSetting.setting === NotificationSetting.ALL
      ? 'now'
      : 'no longer'
  const description = (notificationSetting.description || '').toLowerCase()
  if (notificationSetting.description.startsWith('Reminder emails')) {
    return `You will ${nowOrNoLonger} receive ${description}.`
  }
  return `You will ${nowOrNoLonger} receive ${description}.`
}

export default withRouter(NotificationsUserSettingsCard)
