import {Component} from 'react'
import DefaultThemeProvider from 'app/DefaultThemeProvider'
import FacebookButton from './FacebookButton'
import {FacebookLoginMessageType} from 'app/enums'
import api from 'data/api'
import callAhaEndpoint from 'dashboard/components/util'
import {getEmbeddedFacebookLoginUrl} from 'util/routing'
import styled from '@emotion/styled/macro'

const LoginIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 52px; // TODO(jared) figure out resizing
`

const Wrapper = styled.div`
  position: relative;
`

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
`

const shouldUseIframe = () => !!window.__MLZ_IS_CUSTOM_DOMAIN__

let counter = 0

class FacebookLoginButton extends Component {
  id

  // For buttons on custom domains, assign a unique id to this component so that if there are
  // multiple FacebookLoginButtons on the same page, they don't all try to handle the same message.
  // We pass the id to the iframe as a query param, and it includes the supplied id in messages.
  constructor(props) {
    super(props)
    // Stringify the id this since we pass it through as a query param - it's easier to do this than
    // to handle int parsing on the other end
    this.id = counter.toString()
    counter += 1
  }

  static defaultProps = {
    type: 'login',
  }

  state = {
    frameLoading: true,
    loading: false,
  }

  handleApiError = (e) => {
    let detailedError
    if (e.json) {
      detailedError =
        typeof e.json.error === 'string' ? e.json.error : e.json.error.message
    }
    if (typeof this.props.onError === 'function') {
      this.props.onError(e, detailedError)
    }
  }

  onMessage = async (event) => {
    const {origin, data} = event
    const {onClick, onError, onComplete} = this.props
    if (
      // Skip this message if it's from the wrong origin
      origin !== new URL(window.__MLZ_SERVER_URL__).origin ||
      !data ||
      // Skip the message if it isn't from the right id (anotherFacebookLoginButton will probably
      // handle it in this case)
      data.id !== this.id ||
      // Skip the message if it doesn't have a type
      !data.type
    ) {
      return
    }
    switch (data.type) {
      case FacebookLoginMessageType.INITIATED:
        if (typeof onClick === 'function') {
          onClick()
        }
        break
      case FacebookLoginMessageType.ERROR:
        if (typeof onError === 'function') {
          // $FlowFixMe
          onError(data.data)
        }
        break
      case FacebookLoginMessageType.SUCCESS:
        // $FlowFixMe
        const {ott, user_id} = data.data
        let userDetails
        try {
          userDetails = await api.loginWithToken(user_id, ott)
        } catch (e) {
          if (typeof onError === 'function') {
            this.handleApiError(e)
          }
          break
        }
        onComplete(userDetails)
        break
      default:
        break // no-op, this is to appease eslint
    }
  }

  onClick = (evt) => {
    const {onClick, onComplete, onError, type} = this.props
    // Prevent from scrolling to the top of the page
    evt.preventDefault()
    this.setState({loading: true})

    if (typeof onClick === 'function') {
      onClick()
    }
    const propTypeCheck = (propType) => {
      if (propType === 'login' || propType === 'aha') {
        return true
      }
      return false
    }
    try {
      window.FB.login(
        async (response) => {
          const {authResponse, status} = response
          if (status === 'connected') {
            const apiMethod = propTypeCheck(type)
              ? api.loginWithFacebook
              : api.connectUserFacebook
            try {
              const data = await apiMethod(
                authResponse.userID,
                authResponse.accessToken
              )
              if (type === 'aha') {
                await callAhaEndpoint(data.user.id)
              }
              this.setState({loading: false})
              onComplete(data)
            } catch (e) {
              this.setState({loading: false})
              if (typeof onError === 'function') {
                this.handleApiError(e)
              }
            }
          } else {
            // NB: when the user has cancelled the FB login, status comes back as 'unknown'.
            // But here, if we get any outcome other than 'connected', return the FB button
            // to its active state.
            this.setState({loading: false})
          }
        },
        {scope: 'public_profile,email'}
      )
    } catch (ex) {
      this.setState({loading: false})
      if (typeof onError === 'function') {
        onError(
          window.FB
            ? ex
            : new Error(
                'The page is still loading. Try clicking again in a bit.'
              )
        )
      }
      return
    }
  }

  componentDidMount() {
    if (shouldUseIframe()) {
      window.addEventListener('message', this.onMessage)
    }
  }

  componentWillUnmount() {
    if (shouldUseIframe()) {
      window.removeEventListener('message', this.onMessage)
    }
  }

  render() {
    const {frameLoading, loading} = this.state
    const {type, fluid} = this.props

    return (
      <DefaultThemeProvider>
        {shouldUseIframe() ? (
          <Wrapper>
            {frameLoading && (
              <LoaderWrapper>
                <FacebookButton fluid disabled />
              </LoaderWrapper>
            )}
            <LoginIframe
              title="Continue with Facebook"
              src={getEmbeddedFacebookLoginUrl(this.id).toString()}
              onLoad={() => this.setState({frameLoading: false})}
            />
          </Wrapper>
        ) : (
          <FacebookButton
            loading={loading}
            fluid={fluid}
            onClick={this.onClick}
          >
            {type === 'login'
              ? 'Continue with Facebook'
              : 'Link Facebook account'}
          </FacebookButton>
        )}
      </DefaultThemeProvider>
    )
  }
}

export default FacebookLoginButton
