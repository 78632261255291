import {Timezone} from 'app/enums'
import moment from 'vendor/moment'

// The westernmost timezone (in relation to the international date line) in which we support
// event creation
export const WESTERNMOST_TIMEZONE_FOR_EVENT_CREATION = Object.values(Timezone)
  // $FlowFixMe: same old spiel about `Object.values`
  .map((tz) => ({
    tz,
    offset: moment.tz(tz)._offset,
  }))
  .sort((a, b) => a.offset - b.offset)[0].tz
