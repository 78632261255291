import {
  FormControl as MuiFormControl,
  FormGroup as MuiFormGroup,
  FormHelperText as MuiFormHelperText,
} from '@material-ui/core'

import Typography from './Typography'
import {makeStyles} from '@material-ui/core/styles'
import styles from './styles'

// This margin-left is to better align our helper text.
const MARGIN_LEFT = 31
const MARGIN_TOP = -5
const useStyles = makeStyles((theme) => ({
  checkbox: {
    // This is a fuzzy number just to better align our helper text.
    marginTop: MARGIN_TOP,
    marginLeft: MARGIN_LEFT,
  },
  radio: {
    marginTop: MARGIN_TOP,
    marginLeft: MARGIN_LEFT,
  },
  switch: {
    marginTop: MARGIN_TOP,
    marginLeft: 58,
  },
  fluid: {
    maxWidth: 'none',
  },
}))

/**
 * Low-level form element to wrap arbitrary inputs.
 *
 * Most in-house form elements (e.g., `Input`, `Select`, etc.) are already wrapped in a `FormItem`
 * and accept a subset of its props.
 *
 * You probably don't need to use this for your form.
 */
const FormItem = ({
  children,
  description,
  forceFormItem,
  fluid,
  hint,
  label,
  required,
  row,
  type,
}) => {
  const classes = useStyles()

  const isCheckboxFieldType = ['checkbox', 'radio', 'switch'].includes(type)
  const rootClass = isCheckboxFieldType
    ? {
        root: classes[type],
      }
    : undefined

  const content = (
    <>
      {description && <Typography variant="body2">{description}</Typography>}
      {row ? <MuiFormGroup row={row}>{children}</MuiFormGroup> : children}
      {hint && (
        <MuiFormHelperText
          variant="outlined"
          classes={rootClass}
          component="div"
        >
          {hint}
        </MuiFormHelperText>
      )}
    </>
  )

  return label || forceFormItem ? (
    <MuiFormControl
      component="fieldset"
      classes={fluid && {root: classes.fluid}}
      required={required}
    >
      <Typography
        component="legend"
        variant="h4"
        style={
          !isCheckboxFieldType ? {marginBottom: styles.space.s} : undefined
        }
      >
        {label}
      </Typography>
      {content}
    </MuiFormControl>
  ) : (
    content
  )
}

export default FormItem
