import GoogleEventMap from 'components/map/GoogleEventMap'
import MapboxEventMap from 'components/map/MapboxEventMap'
import flag from 'experiments/flag'

const EventMap = ({height, width, centerLat, centerLng, zoom}) => {
  return (
    <>
      {flag.isActive('enable_mapbox') ? (
        <MapboxEventMap
          centerLat={centerLat}
          centerLng={centerLng}
          initialZoom={zoom}
          height={height}
          width={width}
        />
      ) : (
        <GoogleEventMap
          centerLat={centerLat}
          centerLng={centerLng}
          zoom={zoom}
          height={height}
          width={width}
        />
      )}
    </>
  )
}

export default EventMap
