import {ActionButtons, Button, CardModal, Typography} from 'components'

import {F} from 'util/i18n'
import styled from '@emotion/styled/macro'

const StyledCardModal = styled(CardModal)`
  top: 30%;
`

export default function AffiliationConfirmModal({
  followOrUnfollow,
  isOpen,
  onClose,
  affiliation,
}) {
  const onConfirm = (event) => {
    followOrUnfollow(event)
    onClose()
  }
  return (
    <StyledCardModal
      isOpen={isOpen}
      onRequestClose={onClose}
      header={`Unfollow ${affiliation.organization.name}?`}
      headerAsTitle
    >
      <Typography variant="body1">
        <F
          defaultMessage={`After unfollowing, you will no longer receive recruitment emails from this organization. ${affiliation.organization.name} will still have access to your contact information, and you will continue to receive emails about events you've signed up for.`}
        />
      </Typography>
      <ActionButtons>
        <Button secondary onClick={onClose}>
          <F defaultMessage={'Cancel'} />
        </Button>
        <Button
          data-testid="affiliation_confirmation_button"
          onClick={(event) => onConfirm(event)}
        >
          <F defaultMessage={'Unfollow'} />
        </Button>
      </ActionButtons>
    </StyledCardModal>
  )
}
