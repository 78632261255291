import {useEffect, useState} from 'react'

export default function useGoogleMaps() {
  const [isLoaded, setIsLoaded] = useState(window.google && window.google.maps)

  useEffect(() => {
    const setFunc = () => setIsLoaded(true)
    window.addEventListener('google-maps-loaded', setFunc)
    return () => window.removeEventListener('google-maps-loaded', setFunc)
  }, [setIsLoaded])

  return [isLoaded]
}
