if (!window.waffle && process.env.NODE_ENV !== 'test') {
  console.warn('Failed to load Waffle JS')
}

/**
 * Test whether a feature flag is active.
 * Backed by Waffle JS. We combine all the checks since on the client they are
 * all stable (e.g., not request-dependent).
 */
function isActive(flagName) {
  const waffle = window.waffle
  if (!waffle) {
    return false
  }
  return (
    waffle.flag_is_active(flagName) ||
    waffle.switch_is_active(flagName) ||
    waffle.sample_is_active(flagName)
  )
}

const flag = {isActive}
export default flag
