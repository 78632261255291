// Money related utilities.

import {createIntl} from 'util/i18n'

export function centsForDisplayInDollars(total_raised_in_cents) {
  const intl = createIntl()
  const dollars = centsToDollars(total_raised_in_cents) || 0

  return intl.formatNumber(dollars, {style: 'currency', currency: 'USD'})
}

export function centsForDisplayInWholeDollars(total_raised_in_cents) {
  const intl = createIntl()
  const dollars = centsToDollars(total_raised_in_cents).toFixed(0) || 0
  return intl.formatNumber(dollars, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}

export function centsToDollars(cents) {
  // Takes 3510, returns 35.10
  return cents / 100
}

export function dollarStringToCents(dollarString) {
  // Takes "35.00", returns 3500.

  return Math.round(parseFloat(dollarString) * 100)
}

export function fractionToDisplayInPercent(numerator, denominator) {
  // Takes 3 and 5, returns "60%". If zero denominator, return an em-dash
  if (!denominator) {
    return '—'
  }

  return `${Math.floor((numerator / denominator) * 100)}%`
}

export function formatNumberAsCurrency(amount, currencyType = 'USD') {
  const intl = createIntl()
  return intl.formatNumber(amount, {
    style: 'currency',
    currency: currencyType,
  })
}

export function isEqualToNearestCent(amountInDollars1, amountInDollars2) {
  return (
    dollarStringToCents(amountInDollars1) ===
    dollarStringToCents(amountInDollars2)
  )
}
