import {
  Button,
  CheckboxField,
  Form,
  Link,
  MaxWidthContainer,
  Message,
  MessageType,
  Typography,
} from 'components'
import {useContext, useState} from 'react'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import TermsOfService from 'events/components/TermsOfService'
import TrackingParamsContext from 'app/TrackingParamsContext'
import UserSignupFormFields from 'events/components/UserSignupFormFields'
import api from 'data/api'
import cv from 'util/clientVars'
import {getIdentityFieldsFromFormValues} from 'util/user'
import {omit} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import useIdentityFields from 'hooks/identity-fields'
import {useSnackbar} from 'notistack'

const HeaderText = styled.div`
  text-align: center;
  margin-bottom: ${styles.space.m};
`

export default function HostInterestForm({onSuccess}) {
  const {trackingParams} = useContext(TrackingParamsContext)
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const initialIdentityFields = useIdentityFields()
  const [values, setValues] = useState({
    ...initialIdentityFields,
    smsOptIn: false,
    // The following street address fields are unused.
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
  })
  const [errorFields, setErrorFields] = useState({})
  const [isSignedUp, setIsSignedUp] = useState(false)
  const snackbar = useSnackbar()

  // To make Flow happy, sigh.
  if (!currentOrganization) {
    return null
  }

  const identityFields = getIdentityFieldsFromFormValues(values)

  const handleChange = ({name, value}) => {
    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()

    let response
    try {
      response = await api.affiliateWithOrg({
        orgSlug: currentOrganization.slug,
        person: identityFields,
        promotionalSmsOptIn: values.smsOptIn,
        commitToHost: true,
        trackingParams,
      })
    } catch (error) {
      const errors = error.json ? omit(error.json.error, 'message') : {}
      setErrorFields(errors)
      return
    }
    setErrorFields({})
    setIsSignedUp(true)
    snackbar.enqueueSnackbar('Thanks!', {variant: 'success'})
    onSuccess({canSkipTraining: response?.has_trained_or_hosted_before})
  }

  const defaultBlurb =
    'Volunteers like you are the engine of our movement. Host events with us to bring your unique voice to our work.'

  return (
    <Form onSubmit={handleSubmit}>
      <MaxWidthContainer maxWidth={`${styles.limits.breakpointSmall}px`}>
        <HeaderText>
          <Typography variant="h1">
            Interested in hosting events for {currentOrganization.name}?
          </Typography>
          <Typography variant="body1" data-testid="blurb">
            {currentOrganization.host_event_general_interest_blurb ||
              defaultBlurb}
          </Typography>
        </HeaderText>
        <UserSignupFormFields
          errorFields={errorFields}
          identityFields={identityFields}
          onChange={handleChange}
        />
        <Button fluid type="submit" disabled={isSignedUp}>
          {isSignedUp ? 'Thanks for signing up!' : 'Sign up to host events'}
        </Button>
        <TermsOfService hasMultipleButtons={false} />
        {Object.keys(errorFields).length > 0 && (
          <Message
            type={MessageType.ERROR}
            header="Form submission failed."
            list={
              // $FlowFixMe: complaining about Array<mixed> vs. Array<string>
              Object.values(errorFields)
            }
          />
        )}
        <CheckboxField
          checked={values.smsOptIn}
          forceFormItem
          label={
            <>
              I want to receive occasional automated text messages from{' '}
              {currentOrganization.name} and {cv.product_name} with updates{' '}
              about how to stay involved, such as recommended events and other{' '}
              actions. Message and data rates may apply. Message frequency
              varies. Text STOP to cancel or HELP for help. By opting in you
              agree to our{' '}
              <Link to={cv.tos_url} popup>
                SMS Shortcode Terms of Service
              </Link>
              .
            </>
          }
          onChange={handleChange}
          name="smsOptIn"
          small
        />
      </MaxWidthContainer>
    </Form>
  )
}
