import {ALWAYS_INCLUDED_ACTION_TAG_GENERAL_FIELDS} from './constants'
import MobilizeOnlineActionInput from './MobilizeOnlineActionInput'

/**
 * Converts ActionTag form definition fields into MobilizeActionTagInputs if
 * they are either required fields or always included fields. Use this for
 * groups of fields that do not belong to a specific group (such as
 * ContactInformationFields).
 */
export default function GeneralFields({
  fields,
  formMetadata,
  onChange,
  values,
}) {
  return (
    <>
      {fields
        .filter(
          (element) =>
            element.required ||
            ALWAYS_INCLUDED_ACTION_TAG_GENERAL_FIELDS.includes(element.name)
        )
        .map((input) => (
          <MobilizeOnlineActionInput
            key={input.name}
            input={input}
            formMetadata={formMetadata}
            onChange={onChange}
            value={values[input.name]}
          />
        ))}
    </>
  )
}
