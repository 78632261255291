import CurrentUserContext from 'app/CurrentUserContext'
import {maybeGetAllocationForMaybeUser} from 'experiments/index'
import {useContext} from 'react'

export function useExperimentAllocation(
  experimentName,
  options = {shouldAllocate: true}
) {
  const {user} = useContext(CurrentUserContext)
  return options.shouldAllocate
    ? maybeGetAllocationForMaybeUser(experimentName, user)
    : null
}

export function useIsAllocatedToExperimentVariant(
  experimentName,
  variantName,
  options = {shouldAllocate: true}
) {
  const allocation = useExperimentAllocation(experimentName, options)
  return !!allocation && allocation.variant.name === variantName
}
