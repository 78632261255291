import {
  EventChainingAction,
  EventType,
  ParticipationShortlinkSubtype,
  PostSignupAsk,
} from 'app/enums'
import {getOrganizationEventUrl, getSubtypedShortlink} from 'util/routing'

import ChainingModalStepper from 'events/modals/ChainingModalStepper'
import {ModalTypes} from 'events/modals/enums'
import VirtualEventRedirectModal from './VirtualEventRedirectModal'
import {getEventTypeAffinityChecks} from 'util/event'
import {hasValidDonationPostSignupAskConfig} from 'util/organization'
import {useState} from 'react'

export default function PostModals({
  currentVolunteer,
  event,
  expandActLater,
  identityFields,
  modalOpen,
  organization,
  setModalOpen,
  signupResponse,
  trackingParams,
  donationAmount,
  owningGroups,
}) {
  const [showActionRedirectModal, setShowActionRedirectModal] = useState(true)
  const onRequestModalClose = () => setModalOpen(false)

  if (event.event_type === EventType.ADVOCACY_CALL) {
    // Advocacy events display a call script on signup, so we should suppress the modal for them
    // because it would obscure the script
    return null
  }

  const {
    recommendedAdditionalTimeslots,
    shareShortlink,
    groupShortlink,
    timeslotIdForShare,
    userId,
    virtualActionRedirectURL,
    groupSignupInfo,
  } = signupResponse

  const nativeShareShortlink = shareShortlink
    ? getSubtypedShortlink(
        shareShortlink,
        ParticipationShortlinkSubtype.POST_SIGNUP_NATIVE_SHARE
      ).toString()
    : null

  const shareLink = getOrganizationEventUrl(organization, event, {
    // `undefined` means to exclude this query param if there's no user; note that this would only
    // really happen if we had to move signups to a queue (and so the endpoint couldn't respond
    // with a user id)
    referring_vol: userId || undefined,
    rname: identityFields.firstName || currentVolunteer.firstName,
    // We know at this point that there will be a non-null timeslot id; default to appease Flow
    timeslot: timeslotIdForShare || undefined,
  }).toString()

  const hasActionRedirect =
    event.is_virtual_flexible && virtualActionRedirectURL && !expandActLater
  if (
    hasActionRedirect &&
    showActionRedirectModal &&
    virtualActionRedirectURL
  ) {
    return (
      <VirtualEventRedirectModal
        event={event}
        isOpen={modalOpen}
        onClose={onRequestModalClose}
        onContinue={() => setShowActionRedirectModal(false)}
        url={virtualActionRedirectURL}
      />
    )
  }

  const showGroupInviteModal = !!groupShortlink
  const {isDonationCampaign} = getEventTypeAffinityChecks(event)
  const orgHasValidDonationConfig = hasValidDonationPostSignupAskConfig(
    event.organization
  )
  const isPromotedSignupPage = !event.current_org_is_owner_or_co_owner

  function modalTypesToAddIfConditionsMet(
    modalTypes,
    ask,
    additionalConditions = true
  ) {
    return event.post_signup_asks.includes(ask) && additionalConditions
      ? modalTypes
      : []
  }

  // Current modal order is:
  //    1. If this was a group signup, show group share modal.
  //    2. If enabled on event and not a group signup, show social share modal.
  //    3. If enabled on event, show signup modal (more events).
  //    4. If org has donation settings filled out and setting enabled on event, show donate modal.
  const modalsToShow = [
    ...(showGroupInviteModal
      ? [ModalTypes.GROUP_INVITE]
      : modalTypesToAddIfConditionsMet(
          [ModalTypes.SHARE],
          PostSignupAsk.SOCIAL_SHARING
        )),
    ...modalTypesToAddIfConditionsMet(
      [ModalTypes.SIGNUP, ModalTypes.LINKED_GROUP],
      PostSignupAsk.EVENT_SUGGESTIONS
    ),
    ...modalTypesToAddIfConditionsMet(
      [ModalTypes.DONATE],
      PostSignupAsk.DONATION,
      !isDonationCampaign && orgHasValidDonationConfig && !isPromotedSignupPage
    ),
  ]

  return (
    <ChainingModalStepper
      chainingAction={EventChainingAction.SIGNED_UP}
      event={event}
      isOpen={modalOpen}
      linkForCopy={shareShortlink}
      linkForNativeShare={nativeShareShortlink}
      linkForGroupShare={groupShortlink}
      onRequestClose={onRequestModalClose}
      organization={organization}
      recommendedTimeslots={recommendedAdditionalTimeslots}
      shareLink={shareLink}
      shareModalType="signup-form"
      skipHeaderPreamble={!!hasActionRedirect}
      trackingParams={trackingParams}
      modals={modalsToShow}
      identityFields={identityFields}
      donationAmount={donationAmount}
      owningGroups={owningGroups}
      groupSignupInfo={groupSignupInfo}
      userId={userId}
    />
  )
}
