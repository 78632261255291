import {Button, Typography} from 'components'

import {ExperienceFeedbackType} from 'app/enums'
import moment from 'vendor/moment'
import {partial} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const EditButtonWrapper = styled.span`
  padding-right: ${styles.space.m};
  padding-left: ${styles.space.s};
  padding-top: ${styles.space.s};
  padding-bottom: ${styles.space.s};
`

const FeedbackActionWrapper = styled.span`
  font-size: ${styles.typography.fontSizeBase};
`

export default function ParticipationItemFeedback(props) {
  const {
    isCancelled,
    canRate,
    experienceFeedbackText,
    experienceFeedbackTextUpdatedAtTz,
    experienceFeedbackType,
    handleFeedbackType,
    openFeedbackModal,
  } = props

  const leftPositiveFeedback =
    experienceFeedbackType === ExperienceFeedbackType.APPROVED_OF_SHIFT
  const leftNegativeFeedback =
    experienceFeedbackType === ExperienceFeedbackType.DISAPPROVED_OF_SHIFT
  const didNotAttend =
    experienceFeedbackType === ExperienceFeedbackType.DID_NOT_ATTEND
  const leftPositiveOrNegativeFeedback =
    leftPositiveFeedback || leftNegativeFeedback

  return (
    <>
      {!isCancelled && leftPositiveOrNegativeFeedback && (
        <>
          <Button
            selected
            icon={
              leftPositiveFeedback
                ? 'thumbs-up'
                : leftNegativeFeedback
                ? 'thumbs-down'
                : ''
            }
          />
          {canRate && (
            <span>
              {experienceFeedbackText ? (
                <>
                  <Typography variant="body1" component="span">
                    You left feedback on{' '}
                    {moment(experienceFeedbackTextUpdatedAtTz).format('ll')}
                  </Typography>

                  <EditButtonWrapper>
                    <Button
                      link
                      padding="none"
                      onClick={openFeedbackModal}
                      name="edit-button"
                    >
                      <FeedbackActionWrapper>Edit</FeedbackActionWrapper>
                    </Button>
                  </EditButtonWrapper>
                </>
              ) : (
                <Button link padding="none" onClick={openFeedbackModal}>
                  <FeedbackActionWrapper>
                    Leave detailed feedback
                  </FeedbackActionWrapper>
                </Button>
              )}
            </span>
          )}
        </>
      )}

      {canRate && !leftPositiveOrNegativeFeedback && (
        <>
          <Button
            selected={leftPositiveFeedback}
            secondary={didNotAttend}
            icon="thumbs-up"
            onClick={partial(
              handleFeedbackType,
              ExperienceFeedbackType.APPROVED_OF_SHIFT
            )}
          />
          <Button
            selected={leftNegativeFeedback}
            secondary={didNotAttend}
            icon="thumbs-down"
            onClick={partial(
              handleFeedbackType,
              ExperienceFeedbackType.DISAPPROVED_OF_SHIFT
            )}
          />
          <Button
            selected={didNotAttend}
            disabled={didNotAttend}
            onClick={partial(
              handleFeedbackType,
              ExperienceFeedbackType.DID_NOT_ATTEND
            )}
            secondary
          >
            Did not attend
          </Button>
        </>
      )}
    </>
  )
}
