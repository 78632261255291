import styled from '@emotion/styled/macro'
import styles from './styles'

/** A container with standard Mobilize max width and gutters */
const MaxWidthContainer = styled.div`
  ${(props) => `max-width: ${props.maxWidth || styles.limits.maxWidth};`}
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    !props.noGutters &&
    `padding-left: ${styles.space.m}; padding-right: ${styles.space.m};`}
  ${(props) => props.marginBottom && `margin-bottom: ${styles.space.m};`}
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
`

export default MaxWidthContainer
