import styles, {
  boxShadowLight,
  getBorderRadiusFromProps,
  mediaMinWidth,
  mediaMinWidthLarge,
} from 'components/styles'

import AffiliationConfirmModal from './AffiliationConfirmModal'
import {F} from 'util/i18n'
import Icon from 'components/Icon'
import Link from 'components/Link'
import LoginModal from 'app/login/LoginModal'
import analytics from 'analytics'
import api from 'data/api'
import styled from '@emotion/styled/macro'
import {useSnackbar} from 'notistack'
import {useState} from 'react'

const CardWrapper = styled(Link)`
  display: flex;
  margin-top: ${styles.space.m};
  margin-right: unset;
  min-width: 290px;
  background-color: ${styles.colors.white};
  ${getBorderRadiusFromProps}
  ${boxShadowLight}
  padding: ${styles.space.m};
  width: calc(100% - 2rem);

  &:hover {
    text-decoration: none;
  }

  ${mediaMinWidth(
    628,
    `
    width: calc((100% - 2rem) / 2);
    &:not(:nth-of-type(2n)) {
      margin-right: 1rem;
    }
  `
  )}

  ${mediaMinWidthLarge(`
    width: calc((100% - 2rem) / 3);
    // unset the previous spacing
    &:not(:nth-of-type(2n)) {
      margin-right: 0;
    }
    &:not(:nth-of-type(3n)) {
      margin-right: 1rem;
    }

  `)}
`

const CardTitle = styled.h4`
  color: ${(props) =>
    props.unfollowed ? styles.colors.neutral400 : styles.colors.black};
  font-weight: 600;
`

const CardDetails = styled.div`
  display: flex;
  width: 100%;
`

const Following = styled.div`
  color: ${styles.colors.success300};
  font-weight: 400;
`

const Unfollowed = styled.div`
  color: ${styles.colors.neutral400};
  font-weight: 400;
`

const FollowButton = styled.span`
  font-weight: 600;
  color: ${styles.colors.primary200};
  &:hover {
    cursor: pointer;
  }
`

const Logo = styled.img`
  filter: ${(props) => (props.unfollowed ? 'unset' : 'grayscale(100%)')};
  height: fit-content;
  max-height: 3rem;
  object-fit: contain;
  opacity: ${(props) => (props.unfollowed ? 1 : 0.5)};
  width: 5rem;
`

const PlaceholderContainer = styled.div`
  height: fit-content;
  max-height: 3rem;
  opacity: 0.6;
  text-align: center;
  width: 5rem;
`

const CardContent = styled.div`
  padding-left: 1rem;
  width: 100%;
`

export default function AffiliationItem({affiliation, user}) {
  const snackbar = useSnackbar()
  const organization = affiliation.organization
  const [showUnfollowModal, setShowUnfollowModal] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [currentlyFollowed, setCurrentlyFollowed] = useState(
    affiliation.user_unfollowed_at
  )

  const followOrUnfollow = async (event) => {
    event.preventDefault()
    if (user?.has_logged_in) {
      try {
        await api.updateUserAffiliation(
          user.id,
          affiliation.organization.id,
          !currentlyFollowed
        )
        setCurrentlyFollowed(!currentlyFollowed)
        snackbar.enqueueSnackbar(
          `${
            currentlyFollowed ? 'You are now following' : 'You no longer follow'
          } ${organization.name}`,
          {variant: 'success'}
        )
        analytics.track('affiliations.vol_schedule_affiliation_item', {
          method: currentlyFollowed
            ? `Followed ${affiliation.organization.name}`
            : `Unfollowed ${affiliation.organization.name}`,
        })
      } catch (error) {
        snackbar.enqueueSnackbar(error.message, {variant: 'error'})
      }
    } else {
      setShowLoginModal(true)
    }
  }

  const onUnfollowClick = (event) => {
    event.preventDefault()
    if (user?.has_logged_in) {
      setShowUnfollowModal(true)
    } else {
      setShowLoginModal(true)
    }
  }

  return (
    <>
      <LoginModal
        isOpen={showLoginModal}
        onRequestClose={() => setShowLoginModal(false)}
        onFacebookConnected={() => window.location.reload()}
        onGoogleConnected={() => window.location.reload()}
      />
      <AffiliationConfirmModal
        affiliation={affiliation}
        followOrUnfollow={(event) => followOrUnfollow(event)}
        isOpen={showUnfollowModal}
        onClose={() => setShowUnfollowModal(false)}
      />
      <CardWrapper tabIndex={0} to={`/${organization.slug}`}>
        {organization.logo_url ? (
          <Logo
            unfollowed={!currentlyFollowed}
            src={organization.logo_url}
            alt={`${organization.name} Logo`}
          />
        ) : (
          <PlaceholderContainer>
            <Icon
              name="users"
              noMargin
              secondary
              fontSize={styles.typography.mediumIcon}
            />
          </PlaceholderContainer>
        )}
        <CardContent data-testid="affiliation_item">
          <CardTitle unfollowed={currentlyFollowed}>
            {organization.name}
          </CardTitle>
          {currentlyFollowed ? (
            <CardDetails>
              <Unfollowed>
                <F defaultMessage={'Unfollowed'} />
                &nbsp;{'•'}&nbsp;
              </Unfollowed>
              <FollowButton
                data-testid="affiliation_follow"
                onClick={(e) => followOrUnfollow(e)}
                onKeyPress={(e) => {
                  if (e.code === 'Enter') {
                    followOrUnfollow(e)
                  }
                }}
                tabIndex={0}
              >
                <F defaultMessage={'Follow'} />
              </FollowButton>
            </CardDetails>
          ) : (
            <CardDetails>
              <Following>
                <Icon name="check" />
                <F defaultMessage={'Following'} />
              </Following>
              &nbsp;{'•'}&nbsp;
              <FollowButton
                data-testid="affiliation_unfollow"
                onClick={(e) => onUnfollowClick(e)}
                onKeyPress={(e) => {
                  if (e.code === 'Enter') {
                    onUnfollowClick(e)
                  }
                }}
                tabIndex={0}
              >
                <F defaultMessage={'Unfollow'} />
              </FollowButton>
            </CardDetails>
          )}
        </CardContent>
      </CardWrapper>
    </>
  )
}
