import styles, {
  getBorderRadiusFromProps,
  getSecondaryBodyFontFromProps,
} from './styles'

import {MessageType} from 'app/enums'
import Typography from './Typography'
import {css} from '@emotion/react'
import styled from '@emotion/styled/macro'

// Re-export MessageType from here, makes things easier.
export {MessageType} from 'app/enums'

const errorColors = css`
  background-color: ${styles.colors.error100};
  border: 1px solid ${styles.colors.error200};
`
const warningColors = css`
  background-color: ${styles.colors.caution100};
  border: 1px solid ${styles.colors.caution200};
`
const successColors = css`
  background-color: ${styles.colors.success100};
  border: 1px solid ${styles.colors.success200};
`
const infoColors = css`
  background-color: ${styles.colors.neutral200};
  border: 1px solid ${styles.colors.neutral300};
`

const hintColors = css`
  background-color: ${styles.colors.hint100};
  border: 1px solid ${styles.colors.hint200};
`
const cssByType = {
  [MessageType.WARNING]: warningColors,
  [MessageType.ERROR]: errorColors,
  [MessageType.SUCCESS]: successColors,
  [MessageType.INFO]: infoColors,
  [MessageType.HINT]: hintColors,
}

const MessageWrapper = styled.div`
  padding: ${styles.space.m};
  ${(props) =>
    props.noBottomMargin
      ? `margin-bottom: 0rem`
      : `margin-bottom: ${styles.space.m}`};
  ${getBorderRadiusFromProps};
  ${(props) => cssByType[props.type]};
  ${getSecondaryBodyFontFromProps};
  ${(props) => props.topMargin && `margin-top: ${styles.space.m};`}
  ${(props) => props.compact && `display: inline-block;`}
`

const MessageList = styled.ul`
  margin: 0;
`

/**
 * Used to display errors, warnings, and info to users.
 *
 * Takes an optional header and either a list of sub-messages or freeform content as `children`.
 */
const Message = ({header, list, type, children, ...props}) => {
  type = type || MessageType.INFO

  return (
    <MessageWrapper {...props} type={type}>
      {header && <Typography variant="h4">{header}</Typography>}
      {children || (
        <>
          {Array.isArray(list) && (
            <MessageList>
              {list.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </MessageList>
          )}
        </>
      )}
    </MessageWrapper>
  )
}

export default Message
