import 'core-js/features/map'
import 'core-js/features/set'
import 'core-js/features/string/starts-with'
import 'core-js/features/string/ends-with'
import 'core-js/features/string/repeat'
import 'core-js/features/array/includes'
import 'core-js/features/object/assign'
import 'core-js/features/object/entries'
import 'core-js/features/object/keys'
import 'core-js/features/object/values'
import 'core-js/features/array/find'
import 'core-js/features/array/from'
import 'core-js/features/array/find-index'
import 'core-js/features/string/includes'
import 'core-js/features/string/pad-start'
import 'core-js/features/number/is-finite'
import 'core-js/features/number/is-integer'
import 'core-js/features/math/log2'
import 'core-js/es/symbol'
import 'core-js/features/array/from'
import 'core-js/features/reflect'
import 'core-js/features/symbol/iterator'
import 'core-js/web/url'
// Polyfill IntersectionObserver for dependency react-intersection-observer
import 'intersection-observer'
// Polyfill :focus-visible CSS pseudoclass (adds a class .focus-visible)
import 'focus-visible'

import {Buffer} from 'buffer'

window.Buffer = Buffer

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}

// Polyfill for `closest`, for IE.
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this

    do {
      if (el.matches(s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}
