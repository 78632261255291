import {defaultTheme, getHoverColor, readableColor} from 'components/styles'

import {ThemeProvider as MuiThemeProvider} from '@material-ui/styles'
import {ThemeProvider} from '@emotion/react'
import muiTheme from 'components/styles/muiTheme'

// This is the most aggressively Flow-typed version of this I could figure out for now
function maybeBrandingToTheme(branding) {
  // So as to not have to repeatedly check branding && branding[key]
  const b = branding || {}
  const getFromBrandingOrDefault = (key) => b[key] || defaultTheme[key]

  // TODO(jared) reduce repetition here a little
  return {
    primary_title_font: getFromBrandingOrDefault('primary_title_font'),
    primary_title_font_weight: getFromBrandingOrDefault(
      'primary_title_font_weight'
    ),
    secondary_body_font: getFromBrandingOrDefault('secondary_body_font'),
    border_radius: getFromBrandingOrDefault('border_radius'),
    primary_text_color: getFromBrandingOrDefault('primary_text_color'),
    secondary_text_color: getFromBrandingOrDefault('secondary_text_color'),
    link_color: getFromBrandingOrDefault('link_color'),
    link_font_weight: getFromBrandingOrDefault('link_font_weight'),
    link_text_decoration: getFromBrandingOrDefault('link_text_decoration'),
    link_hover_text_decoration: getFromBrandingOrDefault(
      'link_hover_text_decoration'
    ),
    link_hover_color: getFromBrandingOrDefault('link_hover_color'),
    link_background: getFromBrandingOrDefault('link_background'),
    link_hover_background: getFromBrandingOrDefault('link_hover_background'),
    input_text_color: getFromBrandingOrDefault('input_text_color'),
    input_border_color: getFromBrandingOrDefault('input_border_color'),
    input_border_width: getFromBrandingOrDefault('input_border_width'),
    title_text_transform: getFromBrandingOrDefault('title_text_transform'),
    button_border_width: getFromBrandingOrDefault('button_border_width'),
    primary_button_border_color:
      b.primary_button_border_color ||
      b.background_color ||
      defaultTheme.primary_button_border_color,
    primary_button_hover_border_color:
      b.primary_button_hover_border_color ||
      (b.background_color && getHoverColor(b.background_color)) ||
      defaultTheme.primary_button_hover_border_color,
    primary_button_background_color:
      b.primary_button_background_color ||
      b.background_color ||
      defaultTheme.primary_button_background_color,
    primary_button_hover_background_color:
      b.primary_button_hover_background_color ||
      (b.background_color && getHoverColor(b.background_color)) ||
      defaultTheme.primary_button_hover_background_color,
    primary_button_text_color:
      b.primary_button_text_color ||
      (b.background_color && readableColor(b.background_color)) ||
      defaultTheme.primary_button_text_color,
    primary_button_hover_text_color: getFromBrandingOrDefault(
      'primary_button_hover_text_color'
    ),
    secondary_button_border_color: getFromBrandingOrDefault(
      'secondary_button_border_color'
    ),
    secondary_button_background_color: getFromBrandingOrDefault(
      'secondary_button_background_color'
    ),
    secondary_button_hover_background_color: getFromBrandingOrDefault(
      'secondary_button_hover_background_color'
    ),
    secondary_button_text_color: getFromBrandingOrDefault(
      'secondary_button_text_color'
    ),
    secondary_button_hover_text_color: getFromBrandingOrDefault(
      'secondary_button_hover_text_color'
    ),
    secondary_button_selected_background_color: getFromBrandingOrDefault(
      'secondary_button_selected_background_color'
    ),
    secondary_button_hover_border_color:
      b.secondary_button_hover_border_color ||
      (b.secondary_button_hover_background_color &&
        getHoverColor(b.secondary_button_hover_background_color)) ||
      defaultTheme.secondary_button_hover_border_color,
    // Can't figure out how to Flow type this because `style` is more specific than the rest (enum
    // instead of string), so just access it directly
    style: b.style || defaultTheme.style,
    // `primary_font_size_scale` is a number, so access it directly to work around Flow
    primary_font_size_scale:
      b.primary_font_size_scale || defaultTheme.primary_font_size_scale,
    // Property name is different here, so just access directly
    primary_color: b.background_color || defaultTheme.primary_color,
    brand_banner_background_color: getFromBrandingOrDefault(
      'brand_banner_background_color'
    ),
    brand_banner_text_color: getFromBrandingOrDefault(
      'brand_banner_text_color'
    ),
  }
}

export default function OrganizationThemeProvider({organization, children}) {
  const branding = maybeBrandingToTheme(organization && organization.branding)

  return (
    <MuiThemeProvider theme={muiTheme(branding)}>
      <ThemeProvider theme={branding}>{children}</ThemeProvider>
    </MuiThemeProvider>
  )
}
