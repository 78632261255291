import {
  Divider as MaterialDivider,
  Menu as MaterialMenu,
  MenuItem as MaterialMenuItem,
} from '@material-ui/core'
import {forwardRef, useRef, useState} from 'react'

import Button from './Button'
import Link from './Link'

let MENU_ID = 0

export function MenuContainer({children, label, onOpen}) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  function handleOpen() {
    if (onOpen) {
      onOpen()
    }
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  const id = `mbz-menu-${MENU_ID++}`
  return (
    <>
      <Button
        secondary
        aria-controls={id}
        aria-haspopup="true"
        icon="sort-down"
        data-track="Menu"
        onClick={handleOpen}
        ref={anchorRef}
        type="button"
      >
        {label}
      </Button>
      <MaterialMenu
        id={id}
        anchorEl={anchorRef.current}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        aria-label="Menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </MaterialMenu>
    </>
  )
}

export const MenuItemLink = forwardRef((props, ref) => (
  <MaterialMenuItem component={Link} disableBackground ref={ref} {...props} />
))
// Get rid of terrible forwardRef default of naming it ForwardRef(ComponentName)
MenuItemLink.displayName = 'MenuItemLink'

export const Divider = MaterialDivider
export const Menu = MaterialMenu
export const MenuItem = MaterialMenuItem
