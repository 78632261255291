import styles, {mediaMinWidthSmall} from './styles'

import Typography from './Typography'
import {css} from '@emotion/react'
import {forwardRef} from 'react'
import styled from '@emotion/styled/macro'

const SectionWrapper = styled.section`
  margin-top: ${styles.space.l};
`

const TypographyWrapper = styled.div`
  ${(props) =>
    props.hideTitleWhenSmall &&
    css`
      display: none;
      ${mediaMinWidthSmall(css`
        display: block;
      `)}
    `}
`

const ChildrenWrapper = styled.div`
  margin-bottom: ${styles.space.l};

  ${(props) =>
    props.fullBleedWhenSmall &&
    css`
      margin: ${styles.space.l} -${styles.space.m};
      ${mediaMinWidthSmall(css`
        margin: 0;
      `)}
    `}
`

const SectionDivider = styled.hr`
  border-top: 1px solid ${styles.colors.neutral200};
  margin-top: ${styles.space.l};
  margin-bottom: ${styles.space.l};
`

const Section = (
  {children, fullBleedWhenSmall, hideTitleWhenSmall, title, noDivider},
  ref
) => (
  <SectionWrapper ref={ref}>
    <TypographyWrapper hideTitleWhenSmall={hideTitleWhenSmall}>
      {!noDivider && <SectionDivider />}
      <Typography variant="h2">{title}</Typography>

      {/* XXX(mime): because of h2:last-child rule, argh */}
      <div />
    </TypographyWrapper>
    <ChildrenWrapper fullBleedWhenSmall={fullBleedWhenSmall}>
      <Typography variant="body1" component="div">
        {children}
      </Typography>
    </ChildrenWrapper>
  </SectionWrapper>
)

// TODO(mime): 'any' should prbly be something else.
const ForwardedSection = forwardRef(Section)
ForwardedSection.displayName = 'Section'
export default ForwardedSection
