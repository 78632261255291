/**
 * Proxy version of moment-timezone with only the data we need (in ./vendor/moment-timezone-data).
 * See also scripts/prepare-moment-timezone-data.js
 */

import moment from 'moment-timezone/moment-timezone'
import momentTimezoneData from './moment-timezone-data.json'

moment.locale('en')

moment.tz.load(momentTimezoneData)

export default moment

// TODO(mime): working on migrating all moment tokens to be here.
export const MOMENT_TOKENS = {
  DAY_NAME: {
    SHORT: 'ddd',
    LONG: 'dddd',
  },
  DAY: {
    SHORT: 'D',
    LONG: 'D', // Even when long, we don't want 0-prefixed.
  },
  MONTH: {
    SHORT: 'MMM',
    LONG: 'MMMM',
  },
  YEAR: {
    SHORT: 'YYYY', // Even when short, we don't want the two-year prefix.
    LONG: 'YYYY',
  },
}
