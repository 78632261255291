import {
  List as MaterialList,
  ListItem as MaterialListItem,
  ListItemIcon as MaterialListItemIcon,
  ListItemText as MaterialListItemText,
} from '@material-ui/core'

import Link from './Link'
import {forwardRef} from 'react'

const ForwardedLink = forwardRef((props, ref) => {
  return <Link {...props} />
})
ForwardedLink.displayName = 'Link'

export function ListItemLink(props) {
  return (
    <li>
      <MaterialListItem button component={ForwardedLink} {...props} />
    </li>
  )
}

/** This component is used for sidebar navigations, see DashboardNavbar as example. */
export const List = MaterialList
export const ListItem = MaterialListItem
export const ListItemIcon = MaterialListItemIcon
export const ListItemText = MaterialListItemText
