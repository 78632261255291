import {CheckboxField, Link, Typography} from 'components/index'

import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const GroupChatInputWrapper = styled.div`
  padding-top: ${styles.space.s};
`

const EventEditGroupChatHelpTextWrapper = styled.div`
  padding-bottom: ${styles.space.m};
`

export default function GroupChatInput({
  editingEvent,
  chatEnabled,
  onToggleChat,
  isGroupEvent,
}) {
  return (
    <>
      <GroupChatInputWrapper>
        <CheckboxField
          name="chat_enabled"
          label={`Let ${
            isGroupEvent ? 'members' : 'attendees'
          } and organizers message each other over email`}
          checked={!!chatEnabled}
          onChange={() => onToggleChat()}
          type="switch"
          hint={
            <>
              {isGroupEvent
                ? 'Creates a group email thread for this group.'
                : 'Creates a group email thread 48 hours before the event.'}{' '}
              Email addresses are anonymized, but each{' '}
              {isGroupEvent ? 'member' : 'attendee'}'s first name and last
              initial are added to their messages.{' '}
              <Link to="https://mblz.io/how-to-configure-chat" target="_blank">
                Learn more
              </Link>
            </>
          }
        />
      </GroupChatInputWrapper>
      {editingEvent && (
        <EventEditGroupChatHelpTextWrapper>
          <Typography variant="subtitle1">
            If a chat thread is already in progress, turning this off will stop
            replies.
          </Typography>
        </EventEditGroupChatHelpTextWrapper>
      )}
    </>
  )
}
