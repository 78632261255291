import {
  Button,
  HideAboveLargeScreen,
  HideBelowLargeScreen,
  Icon,
  Link,
  Loader,
  MaxWidthContainer,
  Typography,
} from 'components'
import {animated, useTransition} from 'react-spring'
import styles, {mediaMinWidthSmall} from 'components/styles'
import {useEffect, useRef, useState} from 'react'

import {F} from 'util/i18n'
import FeedItem from './FeedItem'
import {css} from '@emotion/react'
import styled from '@emotion/styled/macro'

const MARGIN_OVERFLOW = '20vw'

const LinkHeaderWrapper = styled.div`
  color: ${styles.colors.black};
`

const CarouselOuterWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${styles.space.l};
  overflow-x: hidden;

  &::before,
  &::after {
    display: none;
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    z-index: ${styles.zindex.abovePage};
    opacity: 0;
    transition: opacity 200ms ease-in;
  }

  &::before {
    left: 0;
    background: linear-gradient(
      to right,
      ${({backgroundColor}) => backgroundColor} 55%,
      rgba(255, 255, 255, 0)
    );
  }

  &::after {
    right: 0;
    background: linear-gradient(
      to left,
      ${({backgroundColor}) => backgroundColor} 55%,
      rgba(255, 255, 255, 0)
    );
  }

  ${(props) =>
    props.carouselShowLeft
      ? css`
          &::before {
            opacity: 1;
          }

          ${mediaMinWidthSmall(css`
            &::before {
              display: block;
            }
          `)}
        `
      : null}
  ${(props) =>
    props.carouselShowRight
      ? css`
          &::after {
            opacity: 1;
          }

          ${mediaMinWidthSmall(css`
            &::after {
              display: block;
            }
          `)}
        `
      : null}

  ${mediaMinWidthSmall(css`
    overflow-x: visible;
  `)}
`

const CarouselWrapper = styled.div`
  position: relative;
  ${({fluid}) => (fluid ? '' : `max-width: ${styles.limits.maxWidth}`)};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const CarouselInnerWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  /*
    overflows the sides so that we get the
    effect of overflowing yet being centered
  */
  margin-right: -${({overflow}) => overflow};
  margin-left: -${({overflow}) => overflow};
  ${({overflow}) =>
    mediaMinWidthSmall(`margin-left: calc(-${overflow} - ${styles.space.m});`)}
  padding-left: ${({overflow}) => overflow};

  /* create snapping scrollbar */
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;

  /* hide scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const IconWrapperLeft = styled.div`
  display: none;
  position: absolute;
  top: calc(50% - 23px);
  left: 0;
  opacity: 0;
  transition: opacity 200ms ease-in;
  z-index: ${styles.zindex.abovePageShadow};
  pointer-events: none;

  &.carousel-icon-visible {
    pointer-events: all;
    opacity: 1;
  }

  ${mediaMinWidthSmall(css`
    display: block;
  `)}
`

const IconWrapperRight = styled(IconWrapperLeft)`
  left: auto;
  right: 0;
`

// When we were using white-space: nowrap in combination with inline-block,
// scrolling worked as expected. However, we switch to display: flex
// to make the different sized items match heights.
// As a result of that though, display: flex scrolling doesn't let you do margin at
// the end. We add a fake item to give us that extra space.
const CarouselItemScrollEndWorkaround = styled.div`
  min-width: ${({overflow}) => (parseInt(overflow) * 3) / 2}vw;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LoaderContainer = styled.div`
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${styles.space.m};
  width: 100%;
`

const FluidContainer = styled.div``

export default function Carousel({
  carouselId,
  categoryLikelyHasItems,
  items,
  header,
  subheader,
  isLoading,
  itemRenderFn,
  itemKeyFn,
  actionUrl,
  actionItemButtonLabel,
  actionItemHeader,
  dontLinkHeader,
  alwaysShowActionItem,
  fluid,
  backgroundColor = styles.colors.neutral100,
}) {
  const transitionsProps = {
    from: {opacity: 0, transform: 'translate3d(40px,0,0)'},
    enter: {opacity: 1, transform: 'translate3d(0,0,0)'},
    trail: 100,
    keys: itemKeyFn,
  }

  const transition = useTransition(items, transitionsProps)

  const [showLeftIconAndShadow, setShowLeftIconAndShadow] = useState(false)
  const [showRightIconAndShadow, setShowRightIconAndShadow] = useState(false)
  const [scrollLeft, setScrollLeft] = useState(0)
  const scrollEl = useRef(null)

  useEffect(() => {
    if (isLoading) {
      return
    }
    setShowLeftIconAndShadow(scrollLeft !== 0)
    if (scrollEl && scrollEl.current) {
      const el = scrollEl.current
      // Only show if we're not at the end of the scroll width.
      const workaroundDivWidth =
        el.childNodes[el.childNodes.length - 1].clientWidth
      const errorMargin = 5
      setShowRightIconAndShadow(
        el.scrollWidth - workaroundDivWidth >
          el.scrollLeft + el.clientWidth + errorMargin
      )
    } else {
      // Only show if more than one item.
      setShowRightIconAndShadow(!!(items && items.length > 1))
    }
  }, [items, scrollLeft, isLoading])

  // If we're loading and we have a sense that there might not be items
  // of this category, we don't show the header & loader so less
  // things are jumping around.
  if (isLoading && categoryLikelyHasItems === false) {
    return null
  }

  if (!isLoading && (!items || !items.length)) {
    return null
  }

  function handleScroll(evt) {
    setScrollLeft(evt.target.scrollLeft)
  }

  function handleLeft() {
    scrollEl.current && scrollEl.current.scrollBy(-window.innerWidth * 0.66, 0)
  }

  function handleRight() {
    scrollEl.current && scrollEl.current.scrollBy(window.innerWidth * 0.66, 0)
  }

  const hasMoreItemsToShow = items?.length > 3
  const marginOverflow = fluid ? '0' : MARGIN_OVERFLOW

  const Container = fluid ? FluidContainer : MaxWidthContainer

  return (
    <>
      {header && (
        <Container aria-roledescription="carousel" aria-label={header}>
          <HeaderWrapper>
            <div>
              {dontLinkHeader || !actionUrl ? (
                <Typography variant="h2" id={carouselId}>
                  {header}
                </Typography>
              ) : (
                <Link to={actionUrl}>
                  <LinkHeaderWrapper>
                    <Typography variant="h2" id={carouselId}>
                      {header}
                    </Typography>
                  </LinkHeaderWrapper>
                </Link>
              )}
              {subheader && (
                <Typography variant="body1">{subheader}</Typography>
              )}
            </div>
            {!dontLinkHeader && hasMoreItemsToShow && actionUrl && (
              <Link to={actionUrl}>
                <HideBelowLargeScreen>
                  {actionItemButtonLabel || <F defaultMessage="See more" />}
                </HideBelowLargeScreen>
                <HideAboveLargeScreen>
                  <Icon name="chevron-right" />
                </HideAboveLargeScreen>
              </Link>
            )}
          </HeaderWrapper>
        </Container>
      )}
      <CarouselOuterWrapper
        carouselShowLeft={showLeftIconAndShadow}
        carouselShowRight={showRightIconAndShadow}
        backgroundColor={backgroundColor}
      >
        <IconWrapperLeft
          aria-hidden={!showLeftIconAndShadow}
          className={showLeftIconAndShadow ? 'carousel-icon-visible' : ''}
        >
          <Button
            color="transparent"
            aria-label="Previous item"
            tabIndex={showLeftIconAndShadow ? undefined : -1}
            onClick={handleLeft}
          >
            <Icon
              name="chevron-left"
              fontSize={styles.typography.fontSizeXL}
              style={{color: styles.colors.neutral500}}
            />
          </Button>
        </IconWrapperLeft>
        <CarouselWrapper fluid={fluid}>
          <CarouselInnerWrapper
            ref={scrollEl}
            onScroll={handleScroll}
            overflow={marginOverflow}
          >
            {isLoading ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              <>
                {transition((style, item) => (
                  <animated.div style={style}>
                    {itemRenderFn(item)}
                  </animated.div>
                ))}
                {(hasMoreItemsToShow || alwaysShowActionItem) && actionUrl && (
                  <animated.div style={transitionsProps}>
                    <FeedItem
                      isSeeMoreItem
                      headline={actionItemHeader}
                      buttonText={actionItemButtonLabel}
                      isCompactFeedItem
                      linkTo={actionUrl}
                      linkTarget="_blank"
                      aria-label="See more"
                    />
                  </animated.div>
                )}
              </>
            )}
            <CarouselItemScrollEndWorkaround overflow={marginOverflow} />
          </CarouselInnerWrapper>
        </CarouselWrapper>
        <IconWrapperRight
          aria-hidden={!showRightIconAndShadow}
          className={showRightIconAndShadow ? 'carousel-icon-visible' : ''}
        >
          <Button
            color="transparent"
            aria-label="Next item"
            tabIndex={showLeftIconAndShadow ? undefined : -1}
            onClick={handleRight}
          >
            <Icon
              name="chevron-right"
              fontSize={styles.typography.fontSizeXL}
              style={{color: styles.colors.neutral500}}
            />
          </Button>
        </IconWrapperRight>
      </CarouselOuterWrapper>
    </>
  )
}
