// Mockable low-level module for interacting with client chat snippets.
// Intercom is fully supported, while Slaask and Zendesk can be booted up but not shut down
// using the functionality provided here.

export const installIntercomSnippet = (appId) => {
  // This is the intercom snippet from https://www.intercom.com/help/install-intercom-on-your-product-or-site/other-ways-to-get-started/integrate-intercom-in-a-single-page-app
  // except that the IIFE syntax has been removed, Prettier has been applied, and the logic that
  // adds the Intercom script to the dom has been changed not to use window.load/onload events.
  // If you need to update the snippet to a newer version, REPLICATE THESE CHANGES OR THINGS WILL
  // BREAK.
  var w = window
  var ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else {
    var d = document
    var i = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    w.Intercom = i
    var l = function () {
      var s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + appId
      var x = d.getElementsByTagName('script')[0]
      // $FlowFixMe - This issue is Intercom's, not ours.
      x.parentNode.insertBefore(s, x)
    }
    /* NB: we had to customize this code, because we sometimes want to load the snippet after
    any 'load'/'onload' events occur. Instead, we just call `l()` directly. If you update the
    snippet to a newer version, DO THE SAME OR THINGS WILL BREAK.
      if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    */
    l()
  }
}

export const shutdownIntercomIfPresent = () => {
  if (window.Intercom) {
    window.Intercom('shutdown')
  }
}

export const updateIntercom = (maybeUserData) => {
  window.Intercom('update', maybeUserData || {})
}

export const bootIntercom = (appId, maybeUserData) => {
  window.Intercom('boot', {
    app_id: appId,
    ...(maybeUserData || {}),
  })
}

/* eslint-disable */
export const installZendeskSnippet = (zendeskSubdomain) => {
  // This is the zendesk web widget code from
  // https://support.zendesk.com/hc/en-us/articles/115007912068-Using-the-Chat-widget-JavaScript-API
  // with prettier applied

  window.zEmbed ||
    (function (e, t) {
      var n,
        o,
        d,
        i,
        s,
        a = [],
        r = document.createElement('iframe')
      ;(window.zEmbed = function () {
        a.push(arguments)
      }),
        (window.zE = window.zE || window.zEmbed),
        (r.src = 'javascript:false'),
        (r.title = ''),
        // $FlowFixMe
        (r.role = 'presentation'),
        // $FlowFixMe
        ((r.frameElement || r).style.cssText = 'display: none'),
        (d = document.getElementsByTagName('script')),
        (d = d[d.length - 1]),
        // $FlowFixMe
        d.parentNode.insertBefore(r, d),
        (i = r.contentWindow),
        (s = i.document)
      try {
        o = s
      } catch (e) {
        ;(n = document.domain),
          (r.src =
            // $FlowFixMe
            'javascript:var d=document.open();d.domain="' + n + '";void(0);'),
          (o = s)
      }
      ;(o.open()._l = function () {
        var e = this.createElement('script')
        n && (this.domain = n),
          (e.id = 'js-iframe-async'),
          (e.src = 'https://assets.zendesk.com/embeddable_framework/main.js'),
          (this.t = +new Date()),
          (this.zendeskHost = `${zendeskSubdomain}.zendesk.com`),
          (this.zEQueue = a),
          this.body.appendChild(e)
      }),
        o.write('<body onload="document._l();">'),
        o.close()
    })()
}
/* eslint-enable */

const updateZendeskVisibilityIfPresent = (visible) => {
  if (!zendeskIsPresent()) {
    return
  }
  window.zE('webWidget', 'updateSettings', {
    webWidget: {
      chat: {suppress: !visible},
    },
  })
}

export const shutdownZendeskIfPresent = () => {
  // Note: this visually hides Zendesk, but does not fully tear down the widget.
  // where this is used, care should be taken not to expose data to Zendesk
  // inappropriately
  updateZendeskVisibilityIfPresent(false)
}

export const bootZendesk = () => {
  updateZendeskVisibilityIfPresent(true)
}

export const zendeskIsPresent = () => {
  return !!window.zE
}

/* eslint-disable */
export const installSlaaskSnippet = (slaaskAppId) => {
  // This is the slaask chat snippet code except Prettier has been applied.
  !(function () {
    var x = document.createElement('script')
    ;(x.src = 'https://cdn.slaask.com/chat.js'),
      (x.type = 'text/javascript'),
      // $FlowFixMe
      (x.async = 'true'),
      (x.onload = x.onreadystatechange = function () {
        var x = this.readyState
        if (!x || 'complete' == x || 'loaded' == x)
          try {
            // $FlowFixMe
            _slaask.init(slaaskAppId)
          } catch (x) {}
      })
    var t = document.getElementsByTagName('script')[0]
    // $FlowFixMe
    t.parentNode.insertBefore(x, t)
  })()
}
/* eslint-enable */
