import {OnlineActionFillSource, OnlineActionSelectedFrequency} from 'app/enums'
import {difference, omit, pick} from 'util/common'

import {CREDIT_CARD_FIELDS} from './constants'
import {RECURRING_COMMITMENT_FREQUENCY_ONE_TIME} from './field_groups/constants'
import {trackOnlineActionFormLoaded} from './util'

export const ActionType = Object.freeze({
  COVER_COSTS_AMOUNT_CALCULATED_UPDATED: 'coverCostsAmountCalculatedUpdated',
  CREDIT_CARD_FIELDS_CLEARED: 'creditCardFieldsCleared',
  DATA_BAG_IDENTITY_UPDATED: 'dataBagIdentityUpdated',
  // When the Online Action form definition is first parsed from callbacks.
  FORM_DEFINITION_SET: 'formDefinitionSet',
  FORM_VALUES_UPDATED: 'formValuesUpdated',
  MODAL_STATE_UPDATED: 'modalStateUpdated',
  PAYMENT_ERRORED: 'paymentErrored',
  PAYMENT_SUBMITTED: 'paymentSubmitted',
  PAYMENT_SUCCEEDED: 'paymentSucceeded',
  PREFILLED_FORM_VALUES_CLEARED: 'prefilledFormValuesCleared',
  RENDER_VGS_FIELDS: 'shouldRenderVgsFields',
  SELECTED_FREQUENCY_UPDATED: 'selectedFrequencyUpdated',
  SIGNUP_ATTEMPTED_TIMEOUT_ELAPSED: 'signupAttemptedTimeoutElapsed',
  SIGNUP_ERRORED: 'signupErrored',
  SIGNUP_VALUES_UPDATED: 'signupValuesUpdated',
  SIGNUP_SUBMITTED: 'signupSubmitted',
  SIGNUP_SUCCEEDED: 'signupSucceeded',
  SKIPPING_DONATION_STATE_UPDATED: 'skippingDonationStateUpdated',
  SUBMIT_JS_FORM_INITIALIZED: 'submitJsFormInitialized',
  VALIDATION_ERRORED: 'validationErrored',
})

// N.B. We have to use the string literals instead of the ActionType values here
// so Flow can properly recognize them instead of matching to a typeof string.

export default function fundraisingFormReducer(state, action) {
  switch (action.type) {
    case ActionType.COVER_COSTS_AMOUNT_CALCULATED_UPDATED: {
      const {
        coverCostsAmountCalculated: CoverCostsAmountCalculated,
        coverCostsAmountChecked: CoverCostsAmount,
      } = action.data
      return {
        ...state,
        values: {
          ...state.values,
          CoverCostsAmount,
          CoverCostsAmountCalculated,
        },
      }
    }
    case ActionType.CREDIT_CARD_FIELDS_CLEARED: {
      return {
        ...state,
        shouldRenderVgsFields: true,
        values: {...omit(state.values, CREDIT_CARD_FIELDS)},
      }
    }
    case ActionType.DATA_BAG_IDENTITY_UPDATED: {
      const {actionProfileId, fastActionId, fastActionSessionId} = action.data

      return {
        ...state,
        dataBagIdentities: {
          actionProfileId:
            actionProfileId || state.dataBagIdentities.actionProfileId,
          fastActionId: fastActionId || state.dataBagIdentities.fastActionId,
          fastActionSessionId:
            fastActionSessionId || state.dataBagIdentities.fastActionSessionId,
        },
      }
    }
    case ActionType.FORM_DEFINITION_SET: {
      if (state.formDefinition) {
        return state
      }

      const formDefinition = action.data.formDefinition
      const {
        defaultValues,
        requiredFields,
      } = extractFieldValuesFromFormDefinition(formDefinition)
      const prefilledFields = Object.keys(defaultValues)

      // Track data about the options and default values that are displayed.
      // formDefinition can only be set once, so we can call tracking event here.
      trackOnlineActionFormLoaded(formDefinition)

      return {
        ...state,
        formDefinition,
        prefilledFields,
        prefilledFieldsFromFormDefaults: prefilledFields,
        prefillSource: OnlineActionFillSource.DEFAULTS,
        requiredFields,
        values: {
          ...state.values,
          ...defaultValues,
        },
      }
    }
    case ActionType.FORM_VALUES_UPDATED: {
      // This allows the updating of the specified fields without the caller
      // needing access to the existing state of values. It can be used to
      // optionally set the prefillSource and store metadata about the prefilled
      // fields.

      const {hasMobilizeIdentity, prefillSource, valuesToUpdate} = action.data
      const updatedState = {...state}
      let newValues = valuesToUpdate
      let newPrefillSource = prefillSource

      // If a Mobilize identity exists, we only want to store the credit card
      // placeholders.
      if (
        hasMobilizeIdentity &&
        prefillSource === OnlineActionFillSource.FAST_ACTION
      ) {
        newValues = pick(valuesToUpdate, CREDIT_CARD_FIELDS)
        if (newValues.cc_4_digit) {
          newPrefillSource = OnlineActionFillSource.FAST_ACTION_CREDIT_CARD_ONLY
        }
      } else {
        newValues = valuesToUpdate
      }

      const prefilledFields = !!newPrefillSource ? Object.keys(newValues) : null

      updatedState.values = {...updatedState.values, ...newValues}

      if (
        prefilledFields &&
        newPrefillSource === OnlineActionFillSource.DEFAULTS
      ) {
        updatedState.prefilledFieldsFromFormDefaults = prefilledFields || []
      }

      if (prefilledFields) {
        updatedState.prefilledFields = prefilledFields
      }

      if (newPrefillSource) {
        updatedState.prefillSource = newPrefillSource
      }

      return updatedState
    }
    case ActionType.MODAL_STATE_UPDATED: {
      return {...state, modalOpen: action.data.isOpen}
    }
    case ActionType.PAYMENT_ERRORED: {
      // N.B. The signup should not be in flight or created if the payment
      // errored, but set the state to false for consistency.
      return {
        ...state,
        errorMessages: action.data.errors,
        formSubmissionInProgress: false,
        paymentSucceeded: false,
        signupInFlightOrCreated: false,
      }
    }
    case ActionType.PAYMENT_SUBMITTED: {
      // N.B. Setting signupAttemptedWithinLastFiveSeconds here instead of in
      // signupSubmitted because this is the first event in the form submission
      // life cycle.
      return {
        ...state,
        errorMessages: [],
        formSubmissionInProgress: true,
        signupAttemptedWithinLastFiveSeconds: true,
      }
    }
    case ActionType.PAYMENT_SUCCEEDED: {
      return {...state, paymentSucceeded: true}
    }
    case ActionType.PREFILLED_FORM_VALUES_CLEARED: {
      // Clear fields that were prefilled from ActionProfile/FastAction. This
      // does not clear fields that were set from default values.
      const {prefilledFields, prefilledFieldsFromFormDefaults, values} = state
      const clearedFields = {}
      const fieldsToClear = difference(
        prefilledFields,
        prefilledFieldsFromFormDefaults
      )

      fieldsToClear.forEach((field) => {
        let clearedValue
        const currentValue = values[field]

        switch (typeof currentValue) {
          case 'boolean':
            clearedValue = false
            break
          case 'number':
            clearedValue = 0
            break
          case 'string':
            clearedValue = ''
            break
          default:
            clearedValue = null
        }
        clearedFields[field] = clearedValue
      })

      return {
        ...state,
        prefillSource: null,
        values: {
          ...state.values,
          ...clearedFields,
        },
      }
    }
    case ActionType.RENDER_VGS_FIELDS: {
      return {...state, shouldRenderVgsFields: true}
    }
    case ActionType.SELECTED_FREQUENCY_UPDATED: {
      const {
        defaultAmountForFrequency: SelectAmount,
        selectedFrequency: SelectedFrequency,
      } = action.data
      return {
        ...state,
        values: {...state.values, SelectAmount, SelectedFrequency},
      }
    }
    case ActionType.SIGNUP_ATTEMPTED_TIMEOUT_ELAPSED: {
      return {...state, signupAttemptedWithinLastFiveSeconds: false}
    }
    case ActionType.SIGNUP_ERRORED: {
      return {
        ...state,
        errorMessages: action.data.errors,
        signupInFlightOrCreated: false,
        signupSucceeded: false,
      }
    }
    case ActionType.SIGNUP_SUBMITTED: {
      return {
        ...state,
        formSubmissionInProgress: true,
        signupInFlightOrCreated: true,
        paymentSucceeded: true,
      }
    }
    case ActionType.SIGNUP_SUCCEEDED: {
      return {
        ...state,
        formSubmissionInProgress: false,
        modalOpen: action.data.shouldOpenModal,
        signupSucceeded: true,
        signupResponse: action.data.signupResponse,
      }
    }
    case ActionType.SIGNUP_VALUES_UPDATED: {
      const {valuesToUpdate} = action.data
      return {
        ...state,
        signupValues: {
          ...state.signupValues,
          ...valuesToUpdate,
        },
      }
    }
    case ActionType.SKIPPING_DONATION_STATE_UPDATED:
      return {
        ...state,
        isSkippingDonation: action.data.isSkippingDonation,
      }
    case ActionType.SUBMIT_JS_FORM_INITIALIZED:
      return {
        ...state,
        submitJsForm: action.data.submitJsForm,
      }
    case ActionType.VALIDATION_ERRORED: {
      // N.B. The signup should not be in flight or created if the validation
      // errored, but set the state to false for consistency.
      return {
        ...state,
        errorMessages: action.data.errors,
        formSubmissionInProgress: false,
        paymentSucceeded: false,
        signupInFlightOrCreated: false,
      }
    }
    default:
      console.warn('unrecognized action type', action.type)
      return state
  }
}

const extractFieldValuesFromFormDefinition = (formDefinition) => {
  const defaultValues = {}
  const requiredFields = {}
  const sections = formDefinition.form_elements.filter(
    (section) => section.type === 'fieldset' && section.children.length
  )

  sections.forEach((section) =>
    section.children.forEach((field) => {
      updateDefaultValues(field, defaultValues)
      updateRequiredFields(field, requiredFields)
    })
  )

  return {defaultValues, requiredFields}
}

const updateDefaultValues = (field, defaultValues) => {
  if (field.default_value) {
    // We don't currently support Quarterly frequencies because they are not
    // charged immediately. We also hide it as an option, so do not set it as
    // a default value.
    if (
      field.name === 'SelectedFrequency' &&
      field.default_value === OnlineActionSelectedFrequency.QUARTERLY
    ) {
      return
    } else if (
      field.name === 'SelectedFrequency' &&
      !field.options.filter(
        (el) => el.value === RECURRING_COMMITMENT_FREQUENCY_ONE_TIME
      ).length
    ) {
      // This occurs when the options for one-time and recurring frequencies are the same.
      // When constructing the input, we'll add "One-Time" as an option, so make it the
      // default here.
      defaultValues[field.name] = RECURRING_COMMITMENT_FREQUENCY_ONE_TIME
      return
    }
    defaultValues[field.name] = field.default_value
  } else if (field.name === 'ProcessingCurrency') {
    // This is a required field for Mobilize signup, but doesn't have a
    // default_value.
    defaultValues[field.name] = field.value || 'USD'
  }
}

const updateRequiredFields = (field, requiredFields) => {
  if (field.required) {
    requiredFields[field.name] = true
  }
}
