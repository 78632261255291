import {EventRegistrationMode} from 'app/enums'
import {createMailUrl} from 'util/url'
import {getHostFormUrlFromEventCampaign} from 'util/routing'

export const getMailtoLink = (contactEmail, eventCampaign, org) =>
  contactEmail &&
  createMailUrl(
    contactEmail,
    `Question about “${eventCampaign.name}”`,
    `Hello ${org.name},\n` +
      `I have a question about…\n\n\n` +
      `📅 ${eventCampaign.name}\n` +
      getHostFormUrlFromEventCampaign(org, eventCampaign).toString()
  )

export const getEventCategoryName = (isDonationCampaign, isGroupCampaign) =>
  isDonationCampaign ? 'fundraiser' : isGroupCampaign ? 'group' : 'event'

export const checkIsPromptForDonationHostedEvent = (
  hostedEvent,
  isDonationCampaign
) =>
  [
    EventRegistrationMode.DONATE_TO_RSVP_OPTIONAL,
    EventRegistrationMode.DONATE_TO_RSVP,
  ].includes(hostedEvent.registration_mode) || isDonationCampaign
