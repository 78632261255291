import styles, {
  focusable,
  getBorderRadiusFromProps,
  getLinkStyleFromProps,
  readableColor,
} from './styles'

import {Button} from './'
import {Children} from 'react'
import {Link as ReactRouterLink} from 'react-router-dom'
import {css} from '@emotion/react'
import {forwardRef} from 'react'
import {pick} from 'util/common'
import styled from '@emotion/styled/macro'

// Export for testing until Enzyme supports the new context API
// https://github.com/airbnb/enzyme/issues/1647
export const LinkComponent = forwardRef(
  (
    {
      to,
      state,
      className,
      children,
      style,
      target,
      onClick,
      trackingName,
      title,
      popup,
      forceAnchorTag,
      tabIndex,
      ...restProps
    },
    ref
  ) => {
    // If this is just a link wrapping a button, then don't have the link be independently tabbable so
    // that there's only one element to tab across (previously there would be two: one for the link
    // and then another for the button). TODO(jared): can scrap this when we have swapped in a
    // component that more elegantly achieves a "link that looks like a button"
    const hasOnlyButtonChild = Children.toArray(children).every(
      (element, index) => index === 0 && element.type === Button
    )

    let actualTabIndex = tabIndex
    // If there's a tabIndex prop passed, prefer that
    if (typeof actualTabIndex !== 'number' && hasOnlyButtonChild) {
      actualTabIndex = -1
    }

    const commonProps = {
      target,
      style,
      className,
      onClick,
      tabIndex: actualTabIndex,
      'aria-label': restProps['aria-label'],
      ref,
    }
    const url = typeof to === 'string' ? new URL(to, window.location) : to
    const isExternalLink = url.host !== window.location.host

    // If the destination appears to be external, then render an anchor tag instead of a React Router
    // link. This is RR's recommended solution as their Link component always renders a pathname
    // relative to a router: https://github.com/ReactTraining/react-router/issues/1147
    if (isExternalLink || popup || forceAnchorTag) {
      // If target is '_blank', there's a security concern that the next page would have access to 'window.top' of the
      // previous page. To mitigate this we add 'noreferrer noopener'.
      // More details: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-target-blank.md
      const rel = target === '_blank' ? 'noreferrer noopener' : null
      if (popup) {
        const previousOnClick = commonProps.onClick
        commonProps.onClick = (evt) => {
          popupCenter(evt, url.toString(), {title})
          previousOnClick && previousOnClick(evt)
        }
      }

      return (
        <a
          href={url.toString()}
          rel={rel}
          onClick={onClick}
          {...commonProps}
          data-track={trackingName}
        >
          {children}
        </a>
      )
    }

    const locationDescriptor = {
      ...pick(url, 'pathname', 'search', 'hash'),
      state,
    }

    return (
      <ReactRouterLink
        to={locationDescriptor}
        {...commonProps}
        data-track={trackingName}
      >
        {children}
      </ReactRouterLink>
    )
  }
)

function popupCenter(evt, url, options) {
  evt.preventDefault()
  const w = (options && options.width) || 640
  const h = (options && options.height) || 450
  const left = (window.innerWidth - w) / 2
  const top = (window.innerHeight - h) / 2
  window.open(
    url,
    options && options.title,
    `scrollbars=yes, width=${w.toString()},height=${h.toString()},top=${top.toString()},left=${left.toString()}`
  )
}

const getMaybeReadableColorOnPrimaryColorStyles = (props) => {
  if (!props.readableOnPrimaryColor) {
    return null
  }
  const color = readableColor(props.theme.primary_color)
  return css`
    color: ${color};
    &:hover {
      color: ${color};
    }
  `
}

// @TODO the plain prop should unbold/unstyle the link and children - it doesnt atm
const Link = styled(LinkComponent)`
  ${(props) => css`
    ${!props.plain &&
    getLinkStyleFromProps(props, {
      disableBackground: props.disableBackground,
    })}
    ${props.noUnderline &&
    css`
      &:hover {
        text-decoration: none;
      }
    `}
    ${props.useBrandBannerTextColor &&
    css`
      color: ${props.theme.brand_banner_text_color};
      &:hover {
        color: ${props.theme.brand_banner_text_color};
      }
    `}
    ${props.secondary &&
    css`
      &,
      &:hover {
        color: ${styles.colors.neutral400};
      }
    `}
  `}
  ${getMaybeReadableColorOnPrimaryColorStyles};
  cursor: pointer;
  // Use the same border radius that buttons use so that the box shadow is consistent
  ${(props) => focusable(getBorderRadiusFromProps(props))}
`

export default Link
