import {send as fbSend, pageview as pixelPageview} from '../vendor/fb-pixel'

import {Component} from 'react'
import {getDisplayName} from 'util/string'
import {pageview as googleAnalytics4PageView} from '../vendor/google-analytics-4'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {pageview as universalAnalyticsPageView} from '../vendor/google-universal-analytics'

export default function withOrgAnalytics(Wrapped) {
  // Should be nested inside withScreenData HoC to access the props it supplies
  class WithOrgAnalytics extends Component {
    static displayName = `WithOrgAnalytics(${getDisplayName(Wrapped)})`

    trackPageview = () => {
      if (!this.props.data.current_organization) {
        return
      }
      const {
        facebook_pixel_id,
        google_analytics_id,
        google_analytics_4_id,
      } = this.props.data.current_organization
      if (facebook_pixel_id) {
        pixelPageview(facebook_pixel_id)
      }
      if (google_analytics_id) {
        universalAnalyticsPageView(google_analytics_id)
      }
      if (google_analytics_4_id) {
        googleAnalytics4PageView(google_analytics_4_id)
      }
    }

    trackSignup = (signupRequest) => {
      if (!this.props.data.current_organization) {
        return
      }
      const {
        facebook_pixel_id,
        google_analytics_id,
        google_analytics_4_id,
      } = this.props.data.current_organization
      const pathname = this.props.location.pathname
      if (facebook_pixel_id) {
        // send a CompleteRegistration event with the pathname as content_name
        // https://developers.facebook.com/docs/ads-for-websites/pixel-events/v3.0#events
        fbSend(facebook_pixel_id, 'CompleteRegistration', {
          content_name: pathname,
        })
      }
      if (google_analytics_id) {
        // send a faux registration pageview like we do for Mobilize GA
        universalAnalyticsPageView(google_analytics_id, `${pathname}registered`)
      }
      if (google_analytics_4_id) {
        // send a faux registration pageview like we do for Mobilize GA
        googleAnalytics4PageView(google_analytics_4_id, `${pathname}registered`)
      }
    }

    trackVolunteerEventCreation = (hostedEvent) => {
      // noop for now, can add stuff here if we need
    }

    componentDidMount() {
      this.trackPageview()
    }

    render() {
      return (
        <Wrapped
          {...this.props}
          trackSignupForOrg={this.trackSignup}
          trackVolunteerEventCreationForOrg={this.trackVolunteerEventCreation}
        />
      )
    }
  }

  return hoistNonReactStatics(WithOrgAnalytics, Wrapped)
}
