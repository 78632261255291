// components shared across multiple files
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

export const HostFormInstructions = styled.div`
  max-width: 600px;
  margin-bottom: ${styles.space.m};
`

export const HostFormHeadingWrapper = styled.div`
  margin-bottom: ${styles.space.m};
  margin-top: ${styles.space.xl};
`
