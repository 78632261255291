import {ThemeProvider as MuiThemeProvider} from '@material-ui/styles'
import {ThemeProvider} from '@emotion/react'
import {defaultTheme} from 'components/styles'
import muiTheme from 'components/styles/muiTheme'

/** Wrap components in this to brand with the default theme. Note this is also used as a wrapper
 * for each item in the styleguide (see styleguide.config.js) */
export default function DefaultThemeProvider({children}) {
  return (
    <MuiThemeProvider theme={muiTheme(defaultTheme)}>
      <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  )
}
