import {get} from './onlineActionClient'

// TODO(fundraising): Add links to the Confluence documentation for these APIs
// once the Mobilize team has more access to EA documentation.

/**
 * Returns the JSON form definition that can be used to render the Mobilize
 * version of an Online Action form.
 *
 * @param formUrl - the full url of an Online Action form.
 *   Ex: https://secure.ngpvan.com/v1/Forms/QtNO0t7CTkePxpIC-X64TA2
 * @returns {Promise<OnlineActionFormDefinition>}
 */
export function getFormDefinition(formUrl) {
  // TODO(ramil): Validate that formUrl is valid here.
  return get(formUrl)
}

/**
 * Returns an object which is a merge of two objects: the canonical object
 * and the named bucket.
 *
 * @param bagId - ID from 'FastActionDatabagId'. This must be retrieved via the
 *   endpoint https://fastaction.ngpvan.com/api/v2/identity
 * @returns {Promise<DataBagFastActionResponseData>}
 */
export function getFastActionDataBag(bagId) {
  const uri = `https://profile.ngpvan.com/v2/data/${bagId}/fastaction`
  return get(uri)
}

/**
 * Returns either the existing identity from a cookie in the .ngpvan.com domain,
 * or generates a new one. The origin domain must be allow-listed for CORS.
 *
 * @returns {Promise<FastActionIdentityResponse>}
 */
export function getFastActionIdentity() {
  const uri = `https://fastaction.ngpvan.com/api/v2/identity`
  return get(uri, null, {credentials: 'include'})
}

/**
 * Retrieves the profile using cookies in the .ngpvan.com domain.
 * The origin domain must be allow-listed for CORS.
 *
 * @returns {Promise<DataBagProfileResponseData>}
 */
export function getProfileDataBag() {
  return get('https://profile.ngpvan.com/profile', null, {
    credentials: 'include',
  })
}

/**
 * Uses the submit.js library's Form.submit() function to send a payment request
 * to the Mobilize proxy endpoint for Online Action Form contributions.
 *
 * @param event - The Event the payment will be associated with
 * @param paymentData - Data used in the EveryAction Contribution request
 * @param signupData - Data used to create the Mobilize signup.
 * @returns {Promise<SignupWithPaymentApiResponse>}
 */
export async function signUpWithPayment(event, paymentData, signupData) {
  const {formDefinition, submitJsForm, paymentValues} = paymentData
  const payload = {
    ...paymentValues,
    _useCustomSubmissionUrl: true,
    mobilize_metadata: {
      submission_url: formDefinition.submissionUrl,
    },
    // Fields that are only used to record a Mobilize signup and will be removed
    // in the view before POSTing to submit.js
    mobilize_signup_data: signupData,
  }
  const proxyUri = `/_/api/events/${event.id}/payments/${formDefinition.shortCode}/`
  return await submitJsForm.submit(proxyUri, payload)
}
