import {lazyLoadElement} from 'util/lazy-load'

/**
 * EveryAction Library used for embedding credit card payment fields from
 * Very Good Security (the PCI Compliance solution used by EA). This is used
 * in conjunction with Online Action Contribution Forms.
 */

const SUBMIT_JS_ELEMENT_ID = 'submit-js'
const SUBMIT_JS_URL = 'https://assets.targetedaction.net/submit.js'

export const initializeSubmitJs = () => {
  // Ensure this is only loaded once.
  if (document.getElementById(SUBMIT_JS_ELEMENT_ID)) {
    return
  }

  lazyLoadElement('script', {
    id: SUBMIT_JS_ELEMENT_ID,
    type: 'text/javascript',
    src: SUBMIT_JS_URL,
  })
}
