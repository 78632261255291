import {Button, HideAboveSmallScreen, HideBelowSmallScreen} from 'components'
import {useContext, useMemo} from 'react'

import {F} from 'util/i18n'
import {MapContext} from 'react-map-gl'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const CloseButtonWrapper = styled.div`
  margin-right: ${styles.space.s};
`

export default function MapCloseButton({onRequestClose}) {
  const context = useContext(MapContext)
  // memoize the button here because it is a costly render for every map position update
  // instead, attach the changing onClick function to the wrapper div and just re-render that
  const memoizedButton = useMemo(
    () => (
      <>
        <HideAboveSmallScreen>
          <Button secondary icon="times" iconPosition="left" />
        </HideAboveSmallScreen>
        <HideBelowSmallScreen>
          <Button secondary icon="chevron-left" iconPosition="left">
            <F defaultMessage="Back to list" />
          </Button>
        </HideBelowSmallScreen>
      </>
    ),
    []
  )
  return (
    <CloseButtonWrapper
      onClick={() => {
        const {longitude, latitude, zoom} = context.viewport
        onRequestClose({longitude, latitude, zoom})
      }}
    >
      {memoizedButton}
    </CloseButtonWrapper>
  )
}
