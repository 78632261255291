import {
  AuthProvider,
  AuthProviderConnectionState,
  AuthProviderToLoginMethod,
} from 'app/enums'
import {
  Button,
  FacebookLoginButton,
  GoogleLoginButton,
  Input,
  InputGroup,
  Link,
  Loader,
  Message,
  MessageType,
  Typography,
} from 'components'

import {GoogleLoginButtonOptions} from '../../../../app/enums'
import analytics from 'analytics'
import {getUserSettingsUrl} from 'util/routing'
import {isDonationCampaignHostedEvent} from 'util/event'
import {partial} from 'util/common'
import {showGoogleLogin} from 'util/user'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const HostFormInstructions = styled.div`
  max-width: 600px;
  margin-bottom: ${styles.space.m};
`

const HostFormHeadingWrapper = styled.div`
  margin-bottom: ${styles.space.m};
  margin-top: ${styles.space.xl};
`

const GoogleLoginButtonWrapper = styled.div`
  padding-top: ${styles.space.s};
`

const LoginButtonsWrapper = styled.div`
  display: inline-block;
  margin-right: ${styles.space.xl};
  min-width: 18rem;
  margin-bottom: ${styles.space.m};
`

const OrWrapper = styled.div`
  margin-top: ${styles.space.s};
  margin-bottom: ${styles.space.s};
  text-align: center;
`

const LoginInstructions = styled.div`
  margin-bottom: ${styles.space.l};
`

function HostNameInputs({
  host,
  required,
  disableContactInfoFields,
  onHostFieldChange,
}) {
  return (
    <InputGroup stackOnMobile>
      <Input
        name="first_name"
        value={host.first_name}
        label="First name"
        onChange={onHostFieldChange}
        required={required}
        disabled={disableContactInfoFields}
      />
      <Input
        name="last_name"
        value={host.last_name}
        label="Last name"
        onChange={onHostFieldChange}
        required={required}
        disabled={disableContactInfoFields}
      />
    </InputGroup>
  )
}

export default function AuthenticateHostUser({
  isLoading,
  hostedEvent,
  authenticationState,
  eventId,
  user,
  eventPermissions,
  onHostChange,
  onAuthenticationStateChange,
  onLoginSucceeded,
}) {
  const {
    continuedWithEmail,
    authProviderConnectionState,
    authProviderErrorMessage,
  } = authenticationState

  const {host} = hostedEvent

  const actionTypeNoun = isDonationCampaignHostedEvent(hostedEvent)
    ? 'fundraiser'
    : 'event'

  const onHostFieldChange = (e) => {
    const {name, value} = e.target
    onHostChange({
      [name]: value,
    })
  }

  const onContinueWithEmailClicked = () => {
    onAuthenticationStateChange({
      continuedWithEmail: true,
      authProviderConnectionState: AuthProviderConnectionState.NOT_ATTEMPTED,
      authProviderErrorMessage: null,
    })
    analytics.trackButtonClicked('continue-with-email')
  }

  const onInlineAuthProviderLoginClicked = (authProvider) => {
    const buttonName =
      authProvider === AuthProvider.FACEBOOK ? 'facebook-login' : 'google-login'
    analytics.trackButtonClicked(buttonName)
    analytics.trackLoginRequested({
      email: null,
      login_method: AuthProviderToLoginMethod[authProvider],
    })
  }

  const onInlineAuthProviderConnected = (authProvider, accountInfo) => {
    const {
      user: {email, first_name, last_name},
    } = accountInfo

    onAuthenticationStateChange({
      continuedWithEmail: false,
      authProviderConnectionState: AuthProviderConnectionState.SUCCEEDED,
      authProviderErrorMessage: null,
    })
    onHostChange({email, first_name, last_name})

    onLoginSucceeded(AuthProviderToLoginMethod[authProvider], accountInfo)
  }

  const onAuthApiError = (error) => {
    onAuthenticationStateChange({
      continuedWithEmail: false,
      authProviderConnectionState: AuthProviderConnectionState.FAILED,
      authProviderErrorMessage: error.message,
    })
  }

  const disableContactInfoFields = !eventId
    ? false
    : !eventPermissions?.can_edit_event_co_hosts
  // Once they've already successfully logged in with an auth provider, don't let them try with email
  const showEmailBasedAuth =
    authProviderConnectionState !== AuthProviderConnectionState.SUCCEEDED

  return (
    <>
      {user ? (
        <>
          <HostFormHeadingWrapper>
            <Typography variant="h2">Your details</Typography>
          </HostFormHeadingWrapper>
          {!disableContactInfoFields && (
            <HostFormInstructions>
              <Typography variant="body2">
                You are logged in with the email {user.email}. You can edit this
                from <Link to={getUserSettingsUrl()}>your&nbsp;account</Link>:
              </Typography>
            </HostFormInstructions>
          )}
          <HostNameInputs
            host={host}
            required
            disableContactInfoFields={disableContactInfoFields}
            onHostFieldChange={onHostFieldChange}
          />
          {isLoading && <Loader />}
        </>
      ) : (
        <>
          <HostFormHeadingWrapper>
            <Typography variant="h2">
              Finalize {actionTypeNoun} creation
            </Typography>
          </HostFormHeadingWrapper>

          {authProviderConnectionState ===
            AuthProviderConnectionState.NOT_ATTEMPTED && (
            <LoginInstructions>
              Choose a method to create the {actionTypeNoun} and get access to a
              dashboard to manage your {actionTypeNoun}.
            </LoginInstructions>
          )}

          <LoginButtonsWrapper>
            {authProviderConnectionState ===
              AuthProviderConnectionState.SUCCEEDED &&
              !isLoading &&
              `Successfully logged into account with email address ${host.email}, but there are errors in the form. Please fix them before continuing.`}
            {authProviderConnectionState ===
              AuthProviderConnectionState.FAILED && (
              <Message type={MessageType.ERROR}>
                {authProviderErrorMessage ||
                  'Unable to log in to your account. Please continue with email.'}
              </Message>
            )}
            {authProviderConnectionState !==
              AuthProviderConnectionState.SUCCEEDED && (
              <>
                <FacebookLoginButton
                  type="login"
                  fluid
                  iconFontSize="1.5rem"
                  iconPosition="left"
                  onError={onAuthApiError}
                  onClick={partial(
                    onInlineAuthProviderLoginClicked,
                    AuthProvider.FACEBOOK
                  )}
                  onComplete={partial(
                    onInlineAuthProviderConnected,
                    AuthProvider.FACEBOOK
                  )}
                />
                {showGoogleLogin() && (
                  <GoogleLoginButtonWrapper>
                    <GoogleLoginButton
                      type={GoogleLoginButtonOptions.LOGIN}
                      fluid
                      onError={onAuthApiError}
                      onClick={partial(
                        onInlineAuthProviderLoginClicked,
                        AuthProvider.GOOGLE
                      )}
                      onComplete={partial(
                        onInlineAuthProviderConnected,
                        AuthProvider.GOOGLE
                      )}
                    />
                  </GoogleLoginButtonWrapper>
                )}
              </>
            )}
            {showEmailBasedAuth && (
              <>
                {authProviderConnectionState !==
                  AuthProviderConnectionState.SUCCEEDED && (
                  <OrWrapper>or</OrWrapper>
                )}
                {continuedWithEmail ? (
                  <>
                    <HostNameInputs
                      host={host}
                      disableContactInfoFields={disableContactInfoFields}
                      onHostFieldChange={onHostFieldChange}
                    />
                    <Input
                      name="email"
                      value={host.email}
                      label="Your Email"
                      onChange={onHostFieldChange}
                    />
                  </>
                ) : (
                  <Button fluid secondary onClick={onContinueWithEmailClicked}>
                    Continue with email
                  </Button>
                )}
              </>
            )}
            {isLoading && <Loader />}
          </LoginButtonsWrapper>
        </>
      )}
    </>
  )
}
