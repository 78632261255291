import {CheckboxField, Input, TextArea, Typography} from 'components'
import {getEventCategoryName, getMailtoLink} from './util'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {F} from 'util/i18n'
import {HostFormHeadingWrapper} from './HostCreateEventComponents'
import PrimaryLocaleSelector from 'dashboard/events/create/PrimaryLocaleSelector'
import UneditableDescription from './UneditableDescription'
import {capitalizeFirstLetter} from 'util/string'
import cv from 'util/clientVars'
import {isDonationCampaignHostedEvent} from 'util/event'
import {isGroupEventCampaign} from 'util/eventCampaign'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useContext} from 'react'

const BottomMarginWrapper = styled.div`
  margin-bottom: ${styles.space.xl};
`

export default function HostCreateEventCommunications({
  canEditTrustedFields,
  contactEmail,
  eventCampaign,
  eventId,
  eventPermissions,
  hostedEvent,
  onEventFieldChange,
  onEventRadioSelectionChange,
  onEventCheckboxSelectionChange,
}) {
  const orgContext = useContext(CurrentOrganizationContext)
  const org = orgContext.currentOrganization
  const isGroupCampaign = isGroupEventCampaign(eventCampaign)
  const isDonationCampaign = isDonationCampaignHostedEvent(hostedEvent)
  const eventCategoryName = getEventCategoryName(
    isDonationCampaign,
    isGroupCampaign
  )
  const mailtoLink = org && getMailtoLink(contactEmail, eventCampaign, org)
  const disableContactInfoFields = !eventId
    ? false
    : !eventPermissions?.can_edit_event_co_hosts

  return (
    <div data-testid="host_create_event_communications">
      <HostFormHeadingWrapper>
        <Typography variant="h2">Communications</Typography>
      </HostFormHeadingWrapper>
      <Input
        name="contact_number"
        value={hostedEvent.contact_number}
        label={`${capitalizeFirstLetter(eventCategoryName)} contact phone`}
        hint={`Allows ${org?.name || ''} and ${
          cv.product_name
        } to contact you about this ${eventCategoryName}. This number is not shown to supporters.`}
        onChange={onEventFieldChange}
        disabled={disableContactInfoFields}
        required
      />

      <TextArea
        name="pro_tips"
        value={hostedEvent.pro_tips}
        label={`Additional information for ${
          isDonationCampaign
            ? 'supporters'
            : isGroupCampaign
            ? 'members'
            : 'attendees'
        } (optional)`}
        onChange={onEventFieldChange}
        hint={
          <>
            {isGroupCampaign
              ? 'Private, sent to group members when they join.'
              : `Sent to supporters in the ${
                  isDonationCampaign ? 'donation' : 'event'
                } confirmation email.`}
            <br />
            {!canEditTrustedFields && (
              <UneditableDescription
                contactEmail={contactEmail}
                mailtoLink={mailtoLink}
              />
            )}
          </>
        }
        disabled={!canEditTrustedFields}
      />

      {/* Whether or not volunteers can contact this host directly*/}
      {orgFlagIsActive(org, 'enable_contact_host_button') && (
        <BottomMarginWrapper>
          <CheckboxField
            name="contact_host_enabled"
            label={
              isDonationCampaign ? (
                <F defaultMessage="Allow people to contact the reply-to email from the fundraiser page" />
              ) : isGroupCampaign ? (
                <F defaultMessage="Allow people to contact the reply-to email from the group page" />
              ) : (
                <F defaultMessage="Allow people to contact the reply-to email from the signup page" />
              )
            }
            checked={hostedEvent.contact_host_enabled}
            onChange={onEventCheckboxSelectionChange}
            hint={
              isDonationCampaign ? (
                <F defaultMessage="When enabled, anyone who visits the fundraiser page can send a message." />
              ) : isGroupCampaign ? (
                <F defaultMessage="When enabled, anyone who visits the group page can send a message." />
              ) : (
                <F defaultMessage="When enabled, anyone who visits the signup page can send a message." />
              )
            }
          />
        </BottomMarginWrapper>
      )}

      {orgFlagIsActive(org, 'enable_spanish_events') && (
        <BottomMarginWrapper>
          <PrimaryLocaleSelector
            onChange={onEventRadioSelectionChange}
            selectedLocale={hostedEvent.primary_locale}
          />
        </BottomMarginWrapper>
      )}
    </div>
  )
}
