import {limits, space, zindex} from './constants'

import colors from './colors'
import typography from './typography'

const styles = {
  colors,
  limits,
  space,
  typography,
  zindex,
}
export default styles
export * from './mixins'
export {defaultTheme} from './theme'
