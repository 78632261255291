import {Button} from 'components'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {Typography} from 'components/index'
import {getHostFormUrlFromEventCampaign} from 'util/routing'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {trackHostFormShareInitiated} from 'util/share'
import {useContext} from 'react'
import {useIsAllocatedToExperimentVariant} from 'hooks/experiments'
import {useSnackbar} from 'notistack'

const FormHeaderContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-bottom: ${styles.space.m};
`

const HostCreateEventFormHeader = (props) => {
  const orgContext = useContext(CurrentOrganizationContext)
  const organization = orgContext?.currentOrganization

  const {eventCampaign} = props
  const snackbar = useSnackbar()
  const showShareButton = useIsAllocatedToExperimentVariant(
    'host_form_share_button',
    'on'
  )

  const shareUrl =
    organization &&
    getHostFormUrlFromEventCampaign(organization, eventCampaign, {
      utm_source: 'host_form_share',
    })

  return (
    <FormHeaderContainer>
      <Typography data-testid="host_create_event_form_header" variant="h1">
        {eventCampaign.name || 'Host an Event'}
      </Typography>
      {showShareButton && shareUrl && (
        <CopyToClipboard text={shareUrl.href}>
          <Button
            data-testid="host_create_event_share_button"
            secondary
            onClick={() => {
              snackbar.enqueueSnackbar('Share link copied!', {
                variant: 'success',
              })
              trackHostFormShareInitiated()
            }}
          >
            Share
          </Button>
        </CopyToClipboard>
      )}
    </FormHeaderContainer>
  )
}

export default HostCreateEventFormHeader
