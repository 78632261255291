import {Input, ToggleButton, ToggleButtonGroup} from 'components'
import {formatNumberAsCurrency, isEqualToNearestCent} from 'util/currency'

import {RECURRING_COMMITMENT_FREQUENCY_ONE_TIME} from './constants'
import {SELF_RECURRING_SELECT_AMOUNT} from '../constants'

export default function AmountInput({
  input,
  formMetadata,
  onChange,
  selectedFrequency,
  value,
}) {
  const {frequencyOptions, name} = input
  let optionsList

  if (
    selectedFrequency &&
    selectedFrequency !== RECURRING_COMMITMENT_FREQUENCY_ONE_TIME &&
    frequencyOptions
  ) {
    const optionsInfoForFrequency = frequencyOptions.find(
      (options) => options.value === selectedFrequency
    ).options
    if (optionsInfoForFrequency === SELF_RECURRING_SELECT_AMOUNT) {
      // Handle a reference to the metadata instead of the actual options.
      const dataForRecurringFrequency =
        formMetadata.options[SELF_RECURRING_SELECT_AMOUNT]
      optionsList = dataForRecurringFrequency.options
    } else {
      optionsList = optionsInfoForFrequency.options
    }
  } else {
    optionsList = input.options
  }

  // SelectAmount options are formatted like
  // {"20:00": null, "35.00": null, "other": "Other"}
  const options = Object.entries(optionsList).map(([value, label]) => ({
    text: label ?? value,
    value: value,
  }))

  const hasButtonChecked = options.find((option) =>
    isEqualToNearestCent(value, option.value)
  )
  const hasOtherField = options.find((option) => option.value === 'other')

  return (
    <>
      <ToggleButtonGroup exclusive twoColumn>
        {options
          .filter((option) => option.value !== 'other')
          .map((option, index) => (
            <ToggleButton
              key={index}
              name={name}
              onClick={(e) => onChange(name, option.value)}
              // Compare as floats because the default_value can be an integer
              // string while the options are always decimal strings.
              selected={isEqualToNearestCent(value, option.value)}
              value={option.value}
            >
              {/* TODO: Pass in currencyType from the "ProcessingCurrency" form def. */}
              {formatNumberAsCurrency(parseFloat(option.value))}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
      {hasOtherField && (
        <Input
          name={name}
          label="Other"
          onChange={(evt) => onChange(name, evt.target.value)}
          type="number"
          min={input.valueMin || 1}
          // Online Action default max is 999999.99 so our default max should
          // not be higher than that
          max={input.valueMax || 999999}
          // This lets users set their donation to any full cent value
          step={0.01}
          value={hasButtonChecked ? '' : value}
        />
      )}
    </>
  )
}
