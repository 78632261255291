import {combineReducers} from 'redux'
import customFieldFormValuesReducer from './customFieldFormValuesReducer'
import {defaultIfNotString} from 'util/string'
import eventReducer from './eventReducer'
import {isCurrentVolunteerEmailOrLocationChanged} from 'util/user'
import {isEmpty} from 'util/common'
import {reduxActions} from 'app/enums'

// TODO(jared) better union type for actions, see
// https://flow.org/en/docs/react/redux/#toc-typing-redux-actions

const orgFeedMembershipAffirmationReducer = (
  orgFeedMembershipAffirmationState = {},
  action
) => {
  switch (action.type) {
    case reduxActions.ADD_ORG_FEED_MEMBERSHIP_AFFIRMATION:
      return {
        ...orgFeedMembershipAffirmationState,
        [action.orgId]: action.membershipAffirmedAt,
      }
    default:
      return orgFeedMembershipAffirmationState
  }
}

const feedUserDataReducer = (feedUserState = {}, action) => {
  switch (action.type) {
    case reduxActions.SET_FEED_USER_DATA:
      return action.feedUser
    default:
      return feedUserState
  }
}

const currentVolunteerReducer = (currentVolunteerState = {}, action) => {
  switch (action.type) {
    case reduxActions.SET_CURRENT_VOLUNTEER:
      const updatedVolunteer = action.currentVolunteer
      const initialVolunteer = currentVolunteerState

      // If email, zip, and provided street address fields have not changed
      // between the initial state and the updated values, merge street address
      // values from the initial state into the updated values when the updated
      // value is null to preserve them (empty strings should be overwritten).
      // Otherwise, overwrite all fields with the updated values.
      if (
        isCurrentVolunteerEmailOrLocationChanged(
          initialVolunteer,
          updatedVolunteer
        )
      ) {
        return updatedVolunteer
      }

      const {addressLine1, addressLine2, city} = initialVolunteer

      return {
        ...updatedVolunteer,
        addressLine1: defaultIfNotString(
          updatedVolunteer.addressLine1,
          addressLine1 || ''
        ),
        addressLine2: defaultIfNotString(
          updatedVolunteer.addressLine2,
          addressLine2 || ''
        ),
        city: defaultIfNotString(updatedVolunteer.city, city || ''),
        state: defaultIfNotString(
          updatedVolunteer.state,
          initialVolunteer.state || ''
        ),
      }
    case reduxActions.LOAD_DATA_FROM_EMBED:
      return (
        (isEmpty(currentVolunteerState) && action.data.currentVolunteer) ||
        currentVolunteerState
      )
    default:
      return currentVolunteerState
  }
}

const feedReshiftMethodReducer = (feedReshiftMethodState = null, action) => {
  switch (action.type) {
    case reduxActions.SET_FEED_RESHIFT_METHOD:
      return action.method
    default:
      return feedReshiftMethodState
  }
}

const feedFlakeReshiftMethodReducer = (
  feedFlakeReshiftMethodState = null,
  action
) => {
  switch (action.type) {
    case reduxActions.SET_FEED_FLAKE_RESHIFT_METHOD:
      return action.method
    default:
      return feedFlakeReshiftMethodState
  }
}

const lastLoggedInReducer = (lastLoggedInState = null, action) => {
  switch (action.type) {
    case reduxActions.SET_LAST_LOGGED_IN_AT_TO_NOW:
      return Date.now()
    case reduxActions.LOAD_DATA_FROM_EMBED:
      return lastLoggedInState || action.data.lastSeenLoggedInAt || null
    default:
      return lastLoggedInState
  }
}

const numTimesViewedEventCreateModalReducer = (
  numTimesViewedState = 0,
  action
) => {
  switch (action.type) {
    case reduxActions.SET_NUM_TIMES_VIEWED_EVENT_CREATE_CHAINING:
      return numTimesViewedState + 1
    default:
      return numTimesViewedState
  }
}

const buildCommitReducer = (
  buildCommitState = window.__MLZ_BUILD_COMMIT__ || '',
  action
) => {
  switch (action.type) {
    case reduxActions.SET_BUILD_COMMIT:
      return action.data.buildCommit
    default:
      return buildCommitState
  }
}

const eventSignupsReducer = (eventSignupState = {}, action) => {
  switch (action.type) {
    case reduxActions.SET_EVENT_SIGNUPS:
      const {eventId} = action.data

      return {
        ...eventSignupState,
        // merge any previous signups for this event with new signups
        [eventId]: Array.from(
          new Set([
            ...action.data.timeslotIds,
            ...(eventSignupState[eventId] || []),
          ])
        ),
      }
    default:
      return eventSignupState
  }
}

const embedTransferReducer = (embedTransferState = false, action) => {
  switch (action.type) {
    case reduxActions.LOAD_DATA_FROM_EMBED:
      return true
    default:
      return embedTransferState
  }
}

const reducer = combineReducers({
  lastAffirmedFeedMembershipsAt: orgFeedMembershipAffirmationReducer,
  feedUser: feedUserDataReducer,
  currentVolunteer: currentVolunteerReducer,
  feedReshiftMethod: feedReshiftMethodReducer,
  feedFlakeReshiftMethod: feedFlakeReshiftMethodReducer,
  lastSeenLoggedInAt: lastLoggedInReducer,
  numTimesViewedEventCreateModal: numTimesViewedEventCreateModalReducer,
  buildCommit: buildCommitReducer,
  eventSignups: eventSignupsReducer,
  hasAttemptedEmbedTransfer: embedTransferReducer,
  draftEvent: eventReducer,
  customFieldFormValues: customFieldFormValuesReducer,
})

export default reducer
