import EventCampaignFeedItem from './EventCampaignFeedItem'
import HostTrainingEventFeedItem from './HostTrainingEventFeedItem'
import {Typography} from 'components'
import {forwardRef} from 'react'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  list-style-type: none;
`

const BottomTitleWrapper = styled.div`
  margin-top: ${styles.space.l};
  margin-bottom: ${styles.space.m};
`

const EventCampaignList = (
  {eventCampaigns, trainingEvent, eventCreationSource, committedToHost = false},
  ref
) => {
  return (
    <div ref={ref}>
      {committedToHost && (
        <Typography variant="h1">
          Thanks for volunteering to host events!
        </Typography>
      )}

      {!!trainingEvent && (
        <>
          <BottomTitleWrapper>
            <Typography variant="h2">
              {committedToHost
                ? 'Next, attend the training:'
                : 'Attend a training:'}
            </Typography>
          </BottomTitleWrapper>
          <HostTrainingEventFeedItem event={trainingEvent} />
        </>
      )}

      {eventCampaigns.length > 0 && (
        <BottomTitleWrapper>
          <Typography variant="h2">
            {!!trainingEvent && committedToHost
              ? 'Or pick an event to host:'
              : committedToHost
              ? 'Next, pick an event to host:'
              : 'Choose an event to host:'}
          </Typography>
        </BottomTitleWrapper>
      )}

      <List>
        {eventCampaigns.map((ec) => (
          <ListItem key={ec.id}>
            <EventCampaignFeedItem
              eventCreationSource={eventCreationSource}
              eventCampaign={ec}
            />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

// TODO(mime): 'any' should prbly be something else.
const ForwardedEventCampaignList = forwardRef(EventCampaignList)
ForwardedEventCampaignList.displayName = 'EventCampaignList'
export default ForwardedEventCampaignList
