import {Button, Menu, MenuItemLink} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import styles, {mediaMinWidth} from 'components/styles'
import {useRef, useState} from 'react'

import Icon from 'components/Icon'
import styled from '@emotion/styled/macro'
import {useOrgCreationLinks} from 'hooks/navigation'

const messages = defineMessages({
  menuAriaLabel: {defaultMessage: 'Organizer Menu'},
  buttonAriaLabel: {defaultMessage: 'Toggle organizer menu'},
})

const HideOnVerySmall = styled.span`
  display: none;
  ${mediaMinWidth(340, `display: inline;`)};
`

const StyledMenuItemLink = styled(MenuItemLink)`
  min-width: 300px;
`

const MenuText = styled.span`
  color: ${styles.colors.black};
  font-weight: ${styles.typography.fontWeightBold};
`
const ButtonCopy = styled.span`
  margin-right: 0.75rem;
  display: none;
  ${mediaMinWidth(630, `display: inline;`)};
`

export default function OrganizerMenu() {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const intl = useIntl()

  const orgCreationLinks = useOrgCreationLinks()

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  const id = `mbz-header-organizer-menu`

  return (
    <HideOnVerySmall>
      <Button
        link
        large
        iconFontSize={styles.typography.fontSizeL}
        aria-expanded={open}
        aria-label={intl.formatMessage(messages.buttonAriaLabel)}
        aria-controls={id}
        aria-haspopup
        data-track="Account Menu"
        type="button"
        onClick={handleOpen}
        ref={anchorRef}
        border="1px solid black"
      >
        <Icon name="clipboard-list" />
        <ButtonCopy>
          <F defaultMessage="For Organizations" />
        </ButtonCopy>
        <Icon name="caret-down" noMargin />
      </Button>
      <Menu
        id={id}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        aria-label={intl.formatMessage(messages.menuAriaLabel)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {orgCreationLinks.map(({label, url}) => (
          <StyledMenuItemLink to={url.toString()} key={url.toString()}>
            <MenuText>{intl.formatMessage(label)}</MenuText>
          </StyledMenuItemLink>
        ))}
      </Menu>
    </HideOnVerySmall>
  )
}
