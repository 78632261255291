export const MILES_TO_METERS = 1609.34

export const ENTER_KEY_CODE = 13

// NB: When adding new params here, please also add them to LOCATION_BASED_FILTER_PARAMS if appropriate
export const EMPTY_FILTER_PARAMS = {
  accessibilityFeatures: [],
  accessibleOnly: false,
  address: null,
  country: null,
  date: null,
  endDate: null,
  eventCampaign: null,
  eventTypes: [],
  isHighPriority: null,
  isPrimary: null,
  isVirtual: null,
  isVirtualFlexible: null,
  isStatewide: null,
  lat: null,
  lon: null,
  maxDist: Infinity,
  orgTypes: [],
  q: null,
  raceTypes: [],
  selectedOrgs: [],
  showAllEvents: false,
  startDate: null,
  state: null,
  tagIds: [],
  volunteerHostedOnly: false,
  zip: '',
  primaryLocale: null,
  suggestingGroups: [],
  owningGroups: [],
  promotableFor: null,
}

export const LOCATION_BASED_FILTER_PARAMS = new Set([
  'lat',
  'lon',
  'zip',
  'address',
  'state',
  'country',
])

export const EMPTY_DASHBOARD_FILTER_PARAMS = {
  address_line1: '',
  address_line2: '',
  city: '',
  country: [],
  creators: [],
  end_date: null,
  event_campaign_ids: [],
  event_name: '',
  event_owning_org: '',
  event_types: [],
  exclude_event_types: [],
  exact_zip: '',
  location_name: '',
  radius: null,
  start_date: null,
  state: [],
  tag_ids: [],
  van_event_name: '',
  virtual_only: false,
  zip: '',
}

// Use this when the camelCased key format is expected.
export const EMPTY_STREET_ADDRESS_FIELDS_FOR_IDENTITY = Object.freeze({
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
})

// Similar to EMPTY_STREET_ADDRESS_FIELDS_FOR_IDENTITY, but snake_case is used
// for signup requests.
export const EMPTY_STREET_ADDRESS_FIELDS_FOR_SIGNUP_REQUEST = Object.freeze({
  address_line1: '',
  address_line2: '',
  city: '',
  state: '',
})

export const STREET_ADDRESS_FIELDS = Object.keys(
  EMPTY_STREET_ADDRESS_FIELDS_FOR_IDENTITY
)

export const EMPTY_LOCATION_VALIDATION_RESULTS = {
  locationNotSpecificEnough: false,
  locationInUnsupportedCountry: false,
}

export const DEGRADED_SERVICE_MESSAGE =
  "We're currently experiencing issues with our site. Hang tight, we'll be back at 100% shortly."

// Keep in sync with `USER_ID_DIFFERS_PARAM` in api.middleware.logout_user_if_user_id_differs_middleware
export const USER_ID_DIFFERS_PARAM = 'logout_if_not_user_id'

export const LOCATION_INPUT_ERROR_MISSING_ZIP_CODE =
  'This location has no zip code. Please enter a more specific location.'

export const LOCATION_INPUT_ERROR_UNSUPPORTED_COUNTRY =
  'Please enter an address in the U.S. or its territories or commonwealths.'

// Alias so that client bundle imports directly from here instead of the worker directory
export {ANONYMOUS_ID_COOKIE_NAME} from '../cloudflare/constants'

export const DJANGO_CSRF_COOKIE_NAME = 'csrftoken'
export const DJANGO_CSRF_TOKEN_FIELD_NAME = 'csrfmiddlewaretoken'

// Cookie that allows staff to toggle experiments in production. This name
// must stay in sync with the server value defined in settings/base.py
export const STAFF_EXPERIMENTS_OVERRIDE_COOKIE_NAME =
  'mobilize-experiment-overrides'

export const EMPTY_TRACKING_PARAMS = {
  actionkit_akid: null,
  actionkit_source: null,
  event_creation_source: null,
  followup_modal_context: null,
  host_commitment_source: null,
  referrer: null,
  utm_adgroup: null,
  utm_campaign: null,
  utm_content: null,
  utm_medium: null,
  utm_source: null,
  utm_term: null,
}

// See https://www.algolia.com/doc/api-reference/api-parameters/query/
export const ALGOLIA_SEARCH_PARAM_CHARACTER_LIMIT = 512

// Keep in sync with constants in EventSuggestion model of the API
export const MAX_EVENT_SUGGESTIONS = 6

// Fundraising.

// Maximum number of allowed donation prefill amounts
export const MAX_DONATION_AMOUNTS = 7

export const EVERYACTION_CONTRIBUTION_FORM_PREFIX =
  'https://secure.everyaction.com/'
export const NGPVAN_CONTRIBUTION_FORM_PREFIX = 'https://secure.ngpvan.com/'
export const NGPVAN_CONTRIBUTION_FORM_DATA_PREFIX = `${NGPVAN_CONTRIBUTION_FORM_PREFIX}v1/Forms/`

// nb: Keep in sync with constants in CustomSignupField model of the API
export const MAX_LENGTH_FOR_FIXED_OPTIONS = 100
export const MAX_COUNT_FOR_FIXED_OPTIONS = 300
