import {useEffect, useMemo, useRef, useState} from 'react'

import {css} from '@emotion/react'
import styled from '@emotion/styled/macro'
import useGoogleMaps from 'hooks/maps'

const MapContainer = styled.div`
  ${(props) => css`
    height: ${props.height};
    width: ${props.width};
  `}
`

const GoogleEventMap = ({centerLat, centerLng, height, width, zoom}) => {
  const mapContainer = useRef()
  const mapContainerProps = {height, width}
  const [isGoogleMapsLoaded] = useGoogleMaps()
  const [googleMap, setGoogleMap] = useState(null)
  const [marker, setMarker] = useState(null)

  const centerLatLng = useMemo(() => {
    if (isGoogleMapsLoaded) {
      return new window.google.maps.LatLng(centerLat, centerLng)
    }
  }, [centerLat, centerLng, isGoogleMapsLoaded])

  // Initialize the map and marker and zoom level or update them if they changed.
  useEffect(() => {
    if (!isGoogleMapsLoaded) {
      return
    }

    if (googleMap && marker) {
      if (googleMap.getCenter().lat !== centerLatLng.lat) {
        googleMap.setCenter(centerLatLng)
        marker.setPosition(centerLatLng)
      }

      if (googleMap.getZoom() !== zoom) {
        googleMap.setZoom(zoom)
      }
    } else {
      const map = new window.google.maps.Map(mapContainer.current, {
        center: centerLatLng,
        zoom,
        // Disable clicking on icons which can cause the map center to change.
        clickableIcons: false,
        // Disable the button that allows you to expand the map to full screen.
        fullscreenControl: false,
        // Disable panning on desktop, but also pinch controls on mobile.
        gestureHandling: 'none',
        // Disable using the arrow keys to pan the map.
        keyboardShortcuts: false,
        // Disable options to switch to different map views such as terrain and satellite.
        mapTypeControl: false,
        // Disable street view option.
        streetViewControl: false,
      })
      setGoogleMap(map)

      setMarker(
        new window.google.maps.Marker({
          map: map,
          position: centerLatLng,
        })
      )
    }
  }, [centerLatLng, googleMap, isGoogleMapsLoaded, marker, zoom])

  if (!isGoogleMapsLoaded) {
    return null
  }

  return <MapContainer ref={mapContainer} {...mapContainerProps} />
}

export default GoogleEventMap
