import {CarouselCategory, EventType} from 'app/enums'
import {MaxWidthContainer, Typography} from 'components'
import {defineMessages, useIntl} from 'util/i18n'
import {getMobilizeFeedUrl, getOrganizationFeedUrl} from 'util/routing'

import EventFeedItem from './EventFeedItem'
import EventsCarousel from './EventsCarousel'
import flag from 'experiments/flag'
import {logError} from 'util/common'
import moment from 'vendor/moment'
import {sortHighPriorityEvents} from 'util/event'
import useFetchEvents from 'hooks/useFetchEvents'
import {useInView} from 'react-intersection-observer'

const messages = defineMessages({
  highPriority: {
    defaultMessage: 'Highest-priority events',
  },
  nearYou: {
    defaultMessage: 'Closest events this week',
  },
  popularVirtual: {
    defaultMessage: 'Virtual events today',
  },
  popularVirtualFlexible: {
    defaultMessage: 'Quick actions for right now',
  },
  moreFromEventOwningOrg: {
    defaultMessage: 'Organized by {eventOwningOrgName}',
  },
  moreLikeThis: {
    defaultMessage: 'More events like this',
  },
  groupLinkedEvents: {
    defaultMessage: 'Upcoming events and actions for this group',
  },
  groups: {
    defaultMessage: 'Groups',
  },
  moreOfTypes: {
    defaultMessage: 'Recommended for you',
  },
  moreOfTypesSubtitle: {
    defaultMessage: 'Based on your Mobilize signups',
  },
  promotableEventsFromOrgs: {
    defaultMessage: 'Recommended for your organization',
  },
  statewide: {
    defaultMessage: 'Events in your state',
  },
})

const NON_FEED_CAROUSEL_CATEGORIES = [
  CarouselCategory.GROUP_LINKED_EVENTS,
  CarouselCategory.MORE_FROM_EVENT_OWNING_ORG,
  CarouselCategory.MORE_WITH_TAGS,
  CarouselCategory.MORE_OF_TYPES,
]

const ALL_EVENT_TYPES_EXCLUDING_GROUPS = Object.keys(EventType)
  .map((k) => EventType[k])
  .filter((e) => e !== EventType.GROUP)

const CAROUSEL_CONFIG = {
  [CarouselCategory.MORE_FROM_EVENT_OWNING_ORG]: {
    title: messages.moreFromEventOwningOrg,
    getFilterParamsPartial: ({currentEvent}) => {
      if (!currentEvent?.organization) {
        // Returning null means that this carousel isn't applicable and shouldn't render. We should
        // also log an error here because this indicates a programming error.
        // TODO(jared): see if there is a better way to enforce this statically with types
        logError(
          'Tried to initialize a "more from event owning org" carousel without a currentEvent'
        )
        return null
      }
      return {selectedOrgs: [currentEvent.organization.id]}
    },
  },
  [CarouselCategory.HIGH_PRIORITY]: {
    title: messages.highPriority,
    getFilterParamsPartial: () => ({isHighPriority: true}),
  },
  [CarouselCategory.NEAR_YOU]: {
    title: messages.nearYou,
    getFilterParamsPartial: () => {
      const startOfTodayMoment = moment().startOf('day')
      return {
        startDate: startOfTodayMoment.toISOString(),
        endDate: startOfTodayMoment
          .clone()
          .add(7, 'days')
          .endOf('day')
          .toISOString(),
        isVirtualFlexible: false,
      }
    },
  },
  [CarouselCategory.POPULAR_VIRTUAL]: {
    title: messages.popularVirtual,
    getFilterParamsPartial: () => ({
      isVirtual: true,
      isVirtualFlexible: false,
      eventTypes: ALL_EVENT_TYPES_EXCLUDING_GROUPS,
      date: moment().startOf('day').toISOString(),
    }),
  },
  [CarouselCategory.POPULAR_VIRTUAL_FLEXIBLE]: {
    title: messages.popularVirtualFlexible,
    getFilterParamsPartial: () => ({
      isVirtual: true,
      isVirtualFlexible: true,
      eventTypes: ALL_EVENT_TYPES_EXCLUDING_GROUPS,
    }),
  },
  [CarouselCategory.MORE_WITH_TAGS]: {
    // For now the same as MORE_OF_EVENT_TYPE since they are mutually exclusive
    title: messages.moreLikeThis,
    getFilterParamsPartial: ({currentEvent}) => {
      if (!currentEvent) {
        // Ditto comment in MORE_FROM_EVENT_OWNING_ORG
        logError(
          'Tried to initialize a "more with tags" carousel without a currentEvent'
        )
        return null
      }
      if (!currentEvent.tags?.length) {
        // Inapplicable, but not an error, so just return null
        return null
      }
      return {tagIds: currentEvent.tags.map((t) => t.id)}
    },
  },
  [CarouselCategory.GROUPS_FOR_ORG]: {
    title: messages.groups,
    getFilterParamsPartial: () => ({eventTypes: [EventType.GROUP]}),
  },
  [CarouselCategory.MORE_OF_TYPES]: {
    title: messages.moreOfTypes,
    subtitle: messages.moreOfTypesSubtitle,
    getFilterParamsPartial: ({relatedEvents}) => {
      const eventTypes = relatedEvents
        ? relatedEvents.map((event) => event.event_type)
        : []
      const uniqueEventTypes = [...new Set(eventTypes)]

      if (!uniqueEventTypes || uniqueEventTypes.length === 0) {
        return null
      }

      return {eventTypes: uniqueEventTypes}
    },
  },
  [CarouselCategory.STATEWIDE]: {
    title: messages.statewide,
    getFilterParamsPartial: () => ({
      isStatewide: true,
      eventTypes: ALL_EVENT_TYPES_EXCLUDING_GROUPS,
    }),
  },
}

function EventsFetcherCarousel({
  carouselCategory,
  categoryLikelyHasEvents,
  eventSuggestionContext,
  currentEvent,
  relatedEvents,
  relatedOrgs,
  organization,
  shouldDelayLoad,
  signupSource,
  promotionContext,
  fluid,
  backgroundColor,
  isPromotable,
  promotingOrg,
}) {
  const intl = useIntl()
  const [ref, inView] = useInView({
    rootMargin: '100px 0px',
    triggerOnce: true,
  })

  const config = CAROUSEL_CONFIG[carouselCategory]
  const maybeFilterParamsPartial = config.getFilterParamsPartial({
    organization,
    currentEvent,
    relatedEvents,
    relatedOrgs,
  })
  const shouldFetchAndRender =
    (!shouldDelayLoad || inView) && !flag.isActive('kill_event_carousels')
  const {events, hasError, isLoading} = useFetchEvents(
    maybeFilterParamsPartial,
    config.searchMobilizeOrg ? null : organization,
    shouldFetchAndRender
  )

  const moreEventsUrl = config.searchMobilizeOrg
    ? getMobilizeFeedUrl(maybeFilterParamsPartial)
    : getOrganizationFeedUrl(organization, maybeFilterParamsPartial)

  const isHighPriority = carouselCategory === CarouselCategory.HIGH_PRIORITY
  let filteredEvents = events
  if (currentEvent && filteredEvents && filteredEvents.length) {
    filteredEvents = filteredEvents.filter((e) => e.id !== currentEvent.id)
  }

  // TODO(mime): if this ends up being used anywhere else beside Feed/Event page
  // probably need to move this logic out of here and into something more generic
  // like util/event.js or something
  if (filteredEvents && filteredEvents.length) {
    if (isHighPriority) {
      filteredEvents = sortHighPriorityEvents(filteredEvents)
    } else if (!NON_FEED_CAROUSEL_CATEGORIES.includes(carouselCategory)) {
      // For non-high-priority carousels, we don't want to duplicate what's in
      // the high-priority carousel.
      filteredEvents = filteredEvents.filter((e) => !e.is_featured)
    }
  }

  const header = intl.formatMessage(config.title, {
    eventOwningOrgName: currentEvent?.organization?.name,
  })

  const subheader =
    'subtitle' in config ? intl.formatMessage(config.subtitle) : ''

  if (!isLoading && (hasError || !filteredEvents.length)) {
    return <div ref={shouldDelayLoad ? ref : null} />
  }

  // NB if there's only one item, we don't create a carousel but just a regular
  // feed item. This is the compromise we came up with to avoid rendering
  // sometimes long and sometimes 'compact' items.
  // todo(kayvon): style fallback single-event card to match fluid carousel
  return (
    <div
      ref={shouldDelayLoad ? ref : null}
      data-testid="events_fetcher_carousel"
    >
      {filteredEvents.length === 1 && !fluid ? (
        <>
          <MaxWidthContainer marginBottom>
            <Typography variant="h2" id={carouselCategory}>
              {header}
            </Typography>
            {subheader && <Typography variant="body1">{subheader}</Typography>}
          </MaxWidthContainer>
          <MaxWidthContainer noGutters marginBottom>
            <EventFeedItem
              event={filteredEvents[0]}
              eventSuggestionContext={eventSuggestionContext}
              signupSource={signupSource}
              isPromotableEvent={isPromotable}
            />
          </MaxWidthContainer>
        </>
      ) : (
        <EventsCarousel
          header={header}
          subheader={subheader}
          carouselCategory={carouselCategory}
          events={filteredEvents}
          moreEventsUrl={moreEventsUrl.toString()}
          isLoading={isLoading}
          signupSource={signupSource}
          eventSuggestionContext={eventSuggestionContext}
          categoryLikelyHasEvents={categoryLikelyHasEvents}
          promotionContext={promotionContext}
          fluid={fluid}
          backgroundColor={backgroundColor}
          isPromotable={isPromotable}
          promotingOrg={promotingOrg}
        />
      )}
    </div>
  )
}

export default EventsFetcherCarousel
