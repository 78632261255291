import {omit, pick} from 'util/common'

import {STREET_ADDRESS_FIELDS} from 'app/constants'
import {getQueryParams} from './url'

export function showGoogleLogin() {
  return !window.__MLZ_IS_CUSTOM_DOMAIN__
}

// NB(jared) Prefer using `hooks/useIdentityFields` wherever possible. I didn't
// completely fold this logic into the hook in case there are class-based
// components that need this, but probably will at some point
//
// Precedence chain: first look at the query string, then the logged in user,
// then the currentVolunteer stored in Redux, then the feedUser, and finally
// fall back to empty string.
export function getIdentityFields({
  user,
  location,
  currentVolunteer,
  feedUser,
}) {
  const queryParams = getQueryParams(location.search)
  const firstName =
    queryParams.first_name ||
    user?.first_name ||
    currentVolunteer.firstName ||
    feedUser.firstName ||
    ''
  const lastName =
    queryParams.last_name ||
    user?.last_name ||
    currentVolunteer.lastName ||
    feedUser.lastName ||
    ''
  const email =
    queryParams.email ||
    user?.email ||
    currentVolunteer.email ||
    feedUser.email ||
    ''
  const phone =
    queryParams.phone ||
    user?.phone ||
    currentVolunteer.phone ||
    feedUser.phone ||
    ''
  const zip =
    queryParams.zip ||
    user?.zipcode ||
    currentVolunteer.zip ||
    feedUser.zip ||
    ''
  const addressLine1 =
    queryParams.addressLine1 ||
    user?.address_line1 ||
    currentVolunteer.addressLine1 ||
    feedUser.addressLine1 ||
    ''
  const addressLine2 =
    queryParams.addressLine2 ||
    user?.address_line2 ||
    currentVolunteer.addressLine2 ||
    feedUser.addressLine2 ||
    ''
  const city =
    queryParams.city ||
    user?.city ||
    currentVolunteer.city ||
    feedUser.city ||
    ''
  const state =
    queryParams.state ||
    user?.state ||
    currentVolunteer.state ||
    feedUser.state ||
    ''

  return {
    firstName,
    lastName,
    email,
    phone,
    zip,
    addressLine1,
    addressLine2,
    city,
    state,
  }
}

export function isEveryIdentityFieldFilledOut(fields) {
  // We are not currently checking street address fields, but we may want to
  // optionally check if all other fields are not empty (except for
  // addressLine2).
  return Object.values(omit(fields, STREET_ADDRESS_FIELDS)).every(Boolean)
}

// Determine if there was a change in the email, zip, or street address fields
// to be updated. The firstName, lastName, and phone fields are not evaluated.
// To skip evaluation of a field (such as if the field was not in the form),
// exclude it from updatedVolunteer.
export function isCurrentVolunteerEmailOrLocationChanged(
  initialVolunteer,
  updatedVolunteer
) {
  const updatedFieldsToCheck = pick(
    updatedVolunteer,
    'email',
    'zip',
    ...STREET_ADDRESS_FIELDS
  )

  for (const [key, updatedValue] of Object.entries(updatedFieldsToCheck)) {
    // Skip comparison if the updatedValue is not a string. Blank fields will be
    // evaluated.
    if (typeof updatedValue !== 'string') {
      continue
    }

    const initialValue = initialVolunteer[key]

    // If the initialValue is falsy/blank and the updatedValue is truthy, then
    // the value is changing. Otherwise, perform a string comparison of the two
    // values.
    if (
      (!initialValue && !!updatedValue) ||
      (typeof initialValue === 'string' &&
        updatedValue.trim().toLowerCase() !== initialValue.trim().toLowerCase())
    ) {
      return true
    }
  }

  return false
}

export function getIdentityFieldsFromFormValues(formValues) {
  return {
    firstName: formValues?.firstName || '',
    lastName: formValues?.lastName || '',
    email: formValues?.email || '',
    phone: formValues?.phone || '',
    zip: formValues?.zip || '',
    addressLine1: formValues?.addressLine1 || '',
    addressLine2: formValues?.addressLine2 || '',
    city: formValues?.city || '',
    state: formValues?.state || '',
  }
}

export function formatIdentityFieldsForSignupRequest(identityFields) {
  const {
    email,
    firstName,
    lastName,
    phone,
    addressLine1: address_line1,
    addressLine2: address_line2,
    city,
    state,
    zip,
  } = identityFields

  return {
    email,
    firstName,
    lastName,
    phone,
    address_line1,
    address_line2,
    city,
    state,
    zip,
  }
}
