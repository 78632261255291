import ContentReportComponent from './ContentReportComponent'
import api from 'data/api'
import withScreenData from 'app/withScreenData'

function EmailContentReportScreen(props) {
  return (
    <ContentReportComponent
      reportedEntityDisplayName="email"
      onSubmit={(notesObject) =>
        api.submitEmailContentReport(props.data.email_uuid, notesObject)
      }
      data={props.data}
    />
  )
}

export default withScreenData(EmailContentReportScreen)
