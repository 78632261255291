import Icon from 'components/Icon'
import Message from 'components/Message'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

export const TextAreaWithHint = styled.div`
  display: flex;
  flex-direction: column;

  .MuiInputBase-formControl {
    max-width: 100%;
    width: 576px;
  }
  // keeps the position from shifting when adding another element to the form
  .MuiFormControl-root {
    margin-bottom: 0 !important;
  }

  @media (min-width: 1440px) {
    flex-direction: ${(props) => (props.narrow ? 'column' : 'row')};
  }
`

const Hint = styled(Message)`
  color: ${styles.colors.black};
  height: fit-content;
  max-width: 100%;
  margin: 1rem 0;
  width: 420px;

  p {
    margin-bottom: 0;
  }

  a {
    display: inline;
  }

  @media (min-width: 992) {
    height: ${(props) => (props.narrow ? '8.5rem' : '10rem')};
  }

  @media (min-width: 1440px) {
    height: fit-content;
    max-width: 420px;
    width: 100%;
    margin: ${(props) => (props.narrow ? 'unset' : '0 1rem -3rem 1rem')};
  }
`

export default function ClippyHint({children, hintCopy, narrow, showHint}) {
  return (
    <TextAreaWithHint narrow={narrow}>
      {children}
      {showHint && (
        <Hint narrow={narrow} type="hint">
          <Icon name="lightbulb-on" />
          {hintCopy}
        </Hint>
      )}
    </TextAreaWithHint>
  )
}
