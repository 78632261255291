import {formatQueryString, getQueryParams} from './url'

import {logError} from './common'

const IMGIX_HOST_REMAPPING = {
  'mobilize-uploads-staging.s3': 'mobilize-staging.imgix.net',
  'mobilize-uploads-prod.s3': 'mobilizeamerica.imgix.net',
  'mobilize-uploads-load.s3': 'mobilize-load.imgix.net',
}

export function remapImageUrlToProxy(urlStr, maxWidth = 0, maxHeight = 0) {
  let remappedUrlStr = urlStr
  if (urlStr) {
    try {
      const url = new URL(urlStr)
      let remapHost = undefined
      for (const host in IMGIX_HOST_REMAPPING) {
        if (url.host.includes(host)) {
          remapHost = IMGIX_HOST_REMAPPING[host]
          break
        }
      }
      if (remapHost) {
        url.host = remapHost
        const query = url.search ? getQueryParams(url.search) : {}
        query.auto = 'format'
        if (maxWidth && maxHeight) {
          query.w = maxWidth
          query.h = maxHeight
          query.fit = 'crop'
          query.crop = 'faces'
        }
        url.search = formatQueryString(query)
        remappedUrlStr = url.toString()
      }
    } catch (error) {
      logError(error)
    }
  }
  return remappedUrlStr
}

export function maybeToggleFaviconForDarkMode() {
  // Requires a favicon to be set up with the attribute `data-dark-mode` and another favicon without
  // that attribute.
  const darkModeAttribute = 'data-dark-mode'
  const darkFavicon = document.querySelector(
    `link[rel="icon"][${darkModeAttribute}]`
  )
  const lightFavicon = document.querySelector(
    `link[rel="icon"]:not([${darkModeAttribute}])`
  )

  // Don't bother continuing if we can't find favicons to toggle between.
  if (!darkFavicon || !lightFavicon) {
    return
  }

  // Appease Flow. It'd be pretty unlikely if the <head> wasn't defined.
  const {head} = document
  if (!head) {
    return
  }

  function swapFavicons(faviconToAdd, faviconToRemove) {
    if (faviconToRemove.parentNode) {
      faviconToRemove.parentNode.removeChild(faviconToRemove)
    }
    if (!faviconToAdd.parentNode) {
      head.appendChild(faviconToAdd)
    }
  }

  function renderAppropriateFavicon(isDarkMode) {
    if (isDarkMode) {
      swapFavicons(darkFavicon, lightFavicon)
    } else {
      swapFavicons(lightFavicon, darkFavicon)
    }
  }

  const match = window.matchMedia('(prefers-color-scheme: dark)')
  renderAppropriateFavicon(match.matches) // Initial check
  match.addListener((e) => renderAppropriateFavicon(e.matches)) // Toggle if it changes
}
