import {CardModal} from 'components'
import Share from './content/Share'

export default function ShareModal({onRequestClose, closeText, ...props}) {
  return (
    <CardModal
      onRequestClose={onRequestClose}
      closeText={closeText}
      isOpen={props.isOpen}
    >
      <Share {...props} />
    </CardModal>
  )
}
