import HostCreateEventForm, {
  additionalTrackPageLoadedProps,
} from 'dashboard/events/create/host/HostCreateEventForm'

import {ChatSnippetInitializationMode} from 'vendor/intercom/chatSnippetManager'
import {DistributedOrganizingCreateSource} from 'app/enums'
import withOrgAnalytics from 'analytics/withOrgAnalytics'
import withScreenData from 'app/withScreenData'

function PublicHostCreateEventScreen(props) {
  return (
    // $FlowFixMe: HostCreateEventForm is weird and we need to refactor it's amalgamation of props.
    <HostCreateEventForm
      {...props}
      eventCreateSource={DistributedOrganizingCreateSource.PUBLIC}
    />
  )
}

export default withScreenData(withOrgAnalytics(PublicHostCreateEventScreen), {
  additionalTrackPageLoadedProps,
  chatSnippetInitializationMode:
    ChatSnippetInitializationMode.USE_CLIENT_CHAT_SNIPPET_IF_PRESENT_BUT_FALLBACK_TO_MOBILIZE_INTERCOM,
})
