import {formatMinimalMomentRange, humanReadableDate} from 'util/time'

import {eventTypeToDisplayName} from 'app/enums'
import {getValidUSZipcodeFromString} from 'util/geo'
import moment from 'vendor/moment'
import pluralize from 'pluralize'
import {toDisplayList} from 'util/string'

/**
 * @description translates filter params to renderable values
 * @param {*} filterParams EventFilterParams object
 * @param {*} key the filter param key we're comparing against filterParams
 * @param {*} organization org object, used for selectedOrgs key
 */
export const getEventCardTextFromFilterParam = (
  filterParams,
  key,
  organization
) => {
  const value = filterParams[key]
  if (!value) return ''

  // a lot of this complication comes from appeasing Flow
  let valueToRender = value
  if (value instanceof Array) {
    if (key === 'eventTypes') {
      const typeSafeValueForFlow = filterParams.eventTypes || []
      valueToRender = toDisplayList(
        typeSafeValueForFlow.map((val) =>
          eventTypeToDisplayName(val, !!filterParams.isVirtual)
        )
      )
    } else if (key === 'selectedOrgs') {
      const typeSafeValueForFlow = filterParams.selectedOrgs || []
      const includesOwnOrg = typeSafeValueForFlow.includes(organization.id)
      const len = typeSafeValueForFlow.length
      if (len === 0) return ''
      valueToRender = includesOwnOrg
        ? `${organization.name} ${len - 1 > 0 ? `+ ${len - 1} more` : ''}`
        : `${len} ${pluralize('organization', len)}`
    } else if (key === 'tagIds') {
      const typeSafeValueForFlow = filterParams.tagIds || []
      const len = typeSafeValueForFlow.length
      valueToRender = len === 0 ? '' : `${len} ${pluralize('tag', len)}`
    }
  } else if (typeof value === 'string') {
    if (key === 'address') {
      valueToRender = getValidUSZipcodeFromString(value)
    } else if (key === 'startDate') {
      const start = moment(filterParams.startDate)
      const end = moment(filterParams.endDate)
      const year = moment(filterParams.endDate).format('YYYY')
      valueToRender = `${formatMinimalMomentRange(start, end)}, ${year}`
    } else if (key === 'date') {
      const {date} = filterParams
      const formatted = humanReadableDate(date)
      valueToRender = formatted
    }
  } else if (typeof value === 'boolean') {
    if (key === 'accessibleOnly') {
      valueToRender = 'Show ADA accessible events only'
    } else if (key === 'isVirtual') {
      valueToRender = 'Show virtual events only'
    }
  }

  return valueToRender
}
