import {ActionButtons, Button, CardModal} from 'components'

import {TIME_FORMAT} from 'components/TimePicker'
import {TWELVE_HOUR_TIME_FORMAT} from 'util/time'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const MessageWrapper = styled.div`
  display: flex;
  margin-top: ${styles.space.l};
  margin-bottom: ${styles.space.l};
`

export default function HostDeleteTimeslotConfirmationModal({
  onCancelDeleteTimeslot,
  onConfirmDeleteTimeslot,
  timeslotToDelete,
}) {
  const getHeaderText = (timeslotToDelete) => {
    const formattedDate =
      timeslotToDelete.date &&
      moment(timeslotToDelete.date).format('MMM D, YYYY')

    const formattedStart =
      timeslotToDelete.startTime &&
      moment(timeslotToDelete.startTime, TIME_FORMAT).format(
        TWELVE_HOUR_TIME_FORMAT
      )

    return formattedDate && formattedStart
      ? `Cancel the ${formattedDate} ${formattedStart} event time?`
      : ''
  }

  const getConfirmationMessage = (timeslotToDelete) => {
    let deleteTimeslotMsg = ''
    if (!!timeslotToDelete.attendingCount) {
      const count = timeslotToDelete.attendingCount
      if (count > 1) {
        deleteTimeslotMsg = `All ${count} registrations`
      } else if (count === 1) {
        deleteTimeslotMsg = `${count} registration`
      }
      deleteTimeslotMsg += ` will be cancelled when you save changes to this event. Attendees
      will also be sent an automated cancellation email.`
    } else {
      deleteTimeslotMsg = 'No one is currently registered for this time.'
    }

    return deleteTimeslotMsg
  }

  return (
    <CardModal
      header={getHeaderText(timeslotToDelete)}
      headerAsTitle
      isOpen
      onRequestClose={onCancelDeleteTimeslot}
    >
      <MessageWrapper>
        {getConfirmationMessage(timeslotToDelete)}
      </MessageWrapper>
      <ActionButtons>
        <Button onClick={onConfirmDeleteTimeslot}>Cancel event time</Button>
        <Button secondary onClick={onCancelDeleteTimeslot}>
          Go back
        </Button>
      </ActionButtons>
    </CardModal>
  )
}
