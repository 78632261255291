import {
  ALLOWED_ACTION_TAG_CONTRIBUTION_FIELDS,
  RECURRING_COMMITMENT_FREQUENCY_ONE_TIME,
} from './constants'

import {ActionType} from '../FundraisingFormReducer'
import AmountInput from './AmountInput'
import CoverCostsAmountInput from './CoverCostsAmountInput'
import MobilizeOnlineActionInput from './MobilizeOnlineActionInput'
import {OnlineActionSelectedFrequency} from 'app/enums'
import {SELF_RECURRING_SELECT_AMOUNT} from '../constants'
import {logError} from 'util/common'

/**
 * A specific field group for handling contribution information fields because
 * these fields exhibit exhibit unique behavior.
 */
export default function ContributionInformationFields({
  dispatch,
  displayFrequencyOptions,
  fields,
  formMetadata,
  onChange,
  organization,
  values,
}) {
  const findField = (name) => {
    return fields.find((f) => f.name === name)
  }

  const handleRadioChange = (name, value) => {
    onChange(name, value)
  }

  const handleSelectedFrequencyChange = (name, value) => {
    const selectAmountData = findField('SelectAmount')

    if (selectAmountData) {
      let defaultAmountForFrequency

      if (
        value === RECURRING_COMMITMENT_FREQUENCY_ONE_TIME &&
        typeof selectAmountData.default_value === 'string'
      ) {
        defaultAmountForFrequency = selectAmountData.default_value
      } else {
        const optionsInfoForFrequency = selectAmountData.frequencyOptions?.find(
          (options) => options.value === value
        )?.options
        if (optionsInfoForFrequency === SELF_RECURRING_SELECT_AMOUNT) {
          // Handle a reference to the metadata instead of the actual options.
          const dataForRecurringFrequency =
            formMetadata.options[SELF_RECURRING_SELECT_AMOUNT]
          defaultAmountForFrequency = dataForRecurringFrequency?.default_value
        } else if (optionsInfoForFrequency?.default_value) {
          defaultAmountForFrequency = optionsInfoForFrequency?.default_value
        } else if (typeof selectAmountData.default_value === 'string') {
          // optionsInfoForFrequency will be undefined if the options are
          // shared between one-time and recurring frequency selections
          defaultAmountForFrequency = selectAmountData.default_value
        }
      }
      if (defaultAmountForFrequency) {
        dispatch({
          type: ActionType.SELECTED_FREQUENCY_UPDATED,
          data: {defaultAmountForFrequency, selectedFrequency: value},
        })
      }
    } else {
      logError('SelectAmount was not found in field list.', fields)
    }
  }

  const getInputForFieldName = (fieldName) => {
    const input = findField(fieldName)
    const value = values[fieldName]

    switch (fieldName) {
      case 'CoverCostsAmount':
        return (
          <CoverCostsAmountInput
            key={fieldName}
            dispatch={dispatch}
            input={input}
            onChange={onChange}
            organizationName={organization.name}
            selectedAmount={values['SelectAmount']}
            // N.B. alterFill provides this value as a number while
            // form_definition uses a boolean.
            value={!!values['CoverCostsAmount']}
          />
        )
      case 'SelectAmount':
        return (
          <AmountInput
            key={fieldName}
            formMetadata={formMetadata}
            input={input}
            onChange={handleRadioChange}
            selectedFrequency={values['SelectedFrequency']}
            value={value}
          />
        )
      case 'SelectedFrequency':
        if (!displayFrequencyOptions) {
          return
        }

        // We do not currently support the QUARTERLY frequency because its
        // behavior differs from all other frequencies (it is not charged
        // immediately and does not include a confirmation id). The Twice
        // Monthly frequency type behaves the same way, but is not a valid type
        // for contribution forms (only used in CRM forms).

        // N.B. Appease Flow which needs to know that options is an array with filter()
        const frequencyInput =
          input && Array.isArray(input.options)
            ? {
                ...input,
                options: input.options.filter(
                  (el) => el.value !== OnlineActionSelectedFrequency.QUARTERLY
                ),
              }
            : input

        if (
          input &&
          Array.isArray(input.options) &&
          frequencyInput &&
          Array.isArray(
            frequencyInput.options
          ) /* just appeasing flow here */ &&
          !input.options.filter(
            (el) => el.value === RECURRING_COMMITMENT_FREQUENCY_ONE_TIME
          ).length
        ) {
          frequencyInput.options = [
            {
              value: RECURRING_COMMITMENT_FREQUENCY_ONE_TIME,
              display: 'One-Time',
            },
            ...frequencyInput.options,
          ]
        }

        return (
          <MobilizeOnlineActionInput
            key={fieldName}
            formMetadata={formMetadata}
            input={frequencyInput}
            onChange={handleSelectedFrequencyChange}
            // N.B. alterFill may provide this value as a number while
            // form_definition uses a string.
            value={String(value)}
          />
        )
      default:
        return (
          <MobilizeOnlineActionInput
            key={fieldName}
            formMetadata={formMetadata}
            input={input}
            onChange={onChange}
            value={value}
          />
        )
    }
  }

  return (
    <>
      {fields.map((input) => {
        const fieldName = input.name
        return (
          (input.required ||
            ALLOWED_ACTION_TAG_CONTRIBUTION_FIELDS.includes(fieldName)) &&
          getInputForFieldName(fieldName)
        )
      })}
    </>
  )
}
