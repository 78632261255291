import React from 'react'
import type { FormattedMessage, ValuesOf } from 'types'
import { LocaleName } from 'app/enums'
import {
  FormattedMessage as FormattedMessageComponent,
  createIntlCache,
  createIntl as originalCreateIntl,
  defineMessages as originalDefineMessages,
  IntlShape,
} from 'react-intl'
// no @types available; would be good to replace this with a better-supported
// md5 packaged, like js-md5
import MD5 from 'md5.js'

// Re-export everything and override below what we want to override.
export {IntlProvider, useIntl, FormattedNumber} from 'react-intl'

const SETTINGS: {
  defaultLocale: ValuesOf<typeof LocaleName>,
  locales: ValuesOf<typeof LocaleName>[]
} = {
  defaultLocale: 'en',
  locales: ['en', 'es'],
}

// This matches the extraction tool pattern:
//   --id-interpolation-pattern '[md5:contenthash:hex:10]'
const INTERNAL_LOCALES = ['xx-AE', 'xx-LS'] as const

// This matches the extraction tool pattern:
//   --id-interpolation-pattern '[md5:contenthash:hex:10]'
function generateId({id, description, defaultMessage}: FormattedMessage) {
  if (id) {
    return id
  }

  return new MD5()
    .update(description ? `${defaultMessage}#${description}` : defaultMessage)
    .digest('hex')
    .slice(0, 10)
}

export function F(props: FormattedMessage) {
  const id = generateId(props)
  return (
    <span className="i18n-msg">
      <FormattedMessageComponent id={id} {...props} />
    </span>
  )
}

export function strongElement(dataTestId?: string) {
  const props = {}
  if (dataTestId) {
    props['data-testid'] = dataTestId
  }
  return {
    strong: (...children) => <strong {...props}>{children}</strong>,
  }
}

// We programmatically define ID's for messages to make things easier for devs.
export function defineMessages(values: Record<string, FormattedMessage>) {
  for (const key in values) {
    if (!values[key].id) {
      values[key].id = generateId(values[key])
    }
  }
  return originalDefineMessages(values)
}

export function setLocales({defaultLocale, locales}: typeof SETTINGS) {
  SETTINGS.defaultLocale = defaultLocale
  SETTINGS.locales = locales
}

export function getDefaultLocale() {
  return SETTINGS.defaultLocale
}

export function getLocales() {
  return SETTINGS.locales
}

// Based on the request object (and other metrics, if you so chose) deduce the locale
// that the app should be rendered with.
// TODO(mime): add better typing for `req` once unified with client/ssr.
export function getLocaleFromRequest(req: any): string {
  // You can add logic here to extract a locale from your user object.
  // if (user.preferences.locale) {
  //   return findRelevantLocale(user.preferences.locale);
  // }

  // Language override via URL.
  if (req.query.lang) {
    const locale = findRelevantLocale(req.query.lang)
    if (locale) {
      return locale
    }
  }

  // If not in user's preferences, we try to extract from the browser 'Accept-Language' header.
  if (req.headers['accept-language']) {
    const rawHeader = req.headers['accept-language']
    const possibleLanguages = rawHeader
      .split(',')
      .map((lang) => lang.replace(/;q=.*/, ''))
    for (let i = 0; i < possibleLanguages.length; ++i) {
      const locale = findRelevantLocale(possibleLanguages[i])
      if (locale) {
        return locale
      }
    }
  }

  // Final fallback
  return SETTINGS.defaultLocale
}

// Find the exact match locale, if supported, or the next best locale if possible.
// e.g. if `fr-FR` isn't found then `fr` will be used.
function findRelevantLocale(locale: ValuesOf<typeof LocaleName>) {
  if (isValidLocale(locale)) {
    return locale
  }

  const baseLocale = locale.split('-')[0]
  if (isValidLocale(baseLocale)) {
    return baseLocale
  }
}

// Whether the locale is found in our supported locale list. Must be exact.
export function isValidLocale(locale: string) {
  return SETTINGS.locales.includes(locale as ValuesOf<typeof LocaleName>) || isInternalLocale(locale)
}

export function isInternalLocale(locale: string) {
  return (
    process.env.NODE_ENV === 'development' && INTERNAL_LOCALES.includes(locale as typeof INTERNAL_LOCALES[number])
  )
}

const cache = createIntlCache()
let presetIntl: IntlShape | null = null
let didSetupCreateIntl = false

// This is optional but highly recommended since it prevents memory leaks.
// See: https://formatjs.io/docs/intl/#createintl
// This is to avoid every time we do `createIntl` on creating a new
// separate object unnecessarily.
export function setupCreateIntl({defaultLocale, locale, messages}) {
  presetIntl = originalCreateIntl(
    {
      defaultLocale,
      locale,
      messages,
    },
    cache
  )

  didSetupCreateIntl = true
}

export function createIntl(options?: Parameters<typeof originalCreateIntl>[0]) {
  if (options) {
    return originalCreateIntl(options)
  } else {
    if (!didSetupCreateIntl) {
      throw new Error(
        'Need to run setupCreateIntl to use createIntl without options.'
      )
    }
    return presetIntl
  }
}
