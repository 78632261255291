import {AttendanceStatus} from 'app/enums'

export function getGroupSignupCounts(participation) {
  const {
    status_attendance_combined,
    num_additional_reserved_participations,
    num_unclaimed_reserved_participations,
  } = participation

  // We expect this function to only be called in places where these values are defined,
  // but `|| 0` appeases Flow
  const numAdditionalGroupSpots = num_additional_reserved_participations || 0
  const numUnclaimedGroupSpots = num_unclaimed_reserved_participations || 0
  const numClaimedGroupSpots = numAdditionalGroupSpots - numUnclaimedGroupSpots

  const groupLeaderIsCancelled =
    status_attendance_combined === AttendanceStatus.CANCELLED

  return {
    numAdditionalGroupSpots,
    numUnclaimedGroupSpots,
    numUnclaimedGroupSpotsPlusLeaderIfCancelled:
      numUnclaimedGroupSpots + (groupLeaderIsCancelled ? 1 : 0),
    numClaimedGroupSpotsPlusLeaderIfNotCancelled:
      numClaimedGroupSpots + (groupLeaderIsCancelled ? 0 : 1),
    numAdditionalGroupSpotsPlusLeaderIfNotCancelled:
      numAdditionalGroupSpots + (groupLeaderIsCancelled ? 0 : 1),
  }
}
