import styles, {mediaMinWidthSmall} from './styles'

import Button from './Button'
import Card from './Card'
import {Global} from '@emotion/react'
import InputGroup from './InputGroup'
import ReactModal from 'react-modal'
import {css} from '@emotion/react'
import styled from '@emotion/styled/macro'

const getMaxWidth = (props) => {
  if (props.narrow) {
    return 300
  }
  if (props.wide) {
    return 650
  }
  if (props.extraWide) {
    return 1100
  }
  return 500
}

const OVERLAY_CLASSNAME = 'CardModal-overlay'

export const ActionButtons = ({children}) => (
  <InputGroup actions>{children}</InputGroup>
)

const CardModal = styled(
  ({children, header, hideCloseIcon, closeText, padding, ...props}) => (
    <>
      <Global
        styles={css`
          .${OVERLAY_CLASSNAME} {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: ${styles.colors.transparentBlack};
            z-index: ${styles.zindex.modalOverlay};
            overflow-y: auto;
          }
        `}
      />
      <ReactModal {...props} overlayClassName={OVERLAY_CLASSNAME}>
        <Card
          header={header}
          expandVerticallyOnMobile
          headerAsTitle={props.headerAsTitle}
          action={
            hideCloseIcon
              ? undefined
              : () => (
                  <Button
                    link
                    plain={!closeText}
                    noUnderline
                    onClick={props.onRequestClose}
                    icon={closeText ? undefined : 'times'}
                    aria-label="Close"
                    data-testid="modal-close"
                    linkTextColor={
                      closeText ? undefined : styles.colors.neutral400
                    }
                  >
                    {closeText}
                  </Button>
                )
          }
          padding={padding}
        >
          {children}
        </Card>
      </ReactModal>
    </>
  )
)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  // Margin based on the screen width (vw units) to keep it consistent between top/bottom and sides
  top: ${styles.limits.mobileModalMarginPercentage}vw;
  width: ${styles.limits.mobileModalWidthPercentage}vw;
  ${(props) =>
    mediaMinWidthSmall(css`
      top: 100px;
      margin-bottom: 100px;
      max-width: ${getMaxWidth(props)}px;
    `)}
`

CardModal.ActionButtons = ActionButtons

/** @component */
export default CardModal
