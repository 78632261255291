import {Autocomplete, Typography} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {isEqual, logError} from 'util/common'
import {useCallback, useContext, useEffect, useState} from 'react'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import Link from 'components/Link'
import api from 'data/api'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const SectionHeaderWrapper = styled.div`
  margin-top: ${styles.space.xl};
  margin-bottom: ${styles.space.m};
`

const messages = defineMessages({
  placeholderText: {
    defaultMessage: 'Your {orgName} groups (optional)',
  },
})

export default function OwningGroupsLinkingSettings({
  event,
  hostIds,
  onFieldChange,
  isCreating,
}) {
  const intl = useIntl()
  const orgContext = useContext(CurrentOrganizationContext)
  const organization = orgContext?.privilegedOrganization

  const [removedGroups, setRemovedGroupIds] = useState(new Set())
  const [initializedOrCreating, setInitializedOrCreating] = useState(isCreating)
  const [availableGroupsResponse, setAvailableGroupsResponse] = useState(null)

  const hostIdsString = JSON.stringify(hostIds)

  useEffect(() => {
    async function fetchAvailableGroups() {
      try {
        const groupsResponse =
          organization &&
          (await api.getAvailableOwningGroups(
            organization.slug,
            JSON.parse(hostIdsString)
          ))
        setAvailableGroupsResponse(groupsResponse)
      } catch (e) {
        logError(e)
      }
    }
    fetchAvailableGroups()
  }, [organization, hostIdsString])

  const initializeOrUpdateGroups = useCallback(
    (groups) => {
      const owningGroupIds = new Set(
        (event.owning_groups || []).map((g) => g.id)
      )
      let removed = removedGroups
      if (!initializedOrCreating) {
        // when editing an event, we should first set the removed groups to any available group
        // that has not already been saved as an owning group - then any new available default group
        // from adding a co-host can be added in the update method
        removed = new Set(
          groups.filter((g) => !owningGroupIds.has(g.id)).map((g) => g.id)
        )
        setRemovedGroupIds(removed)
        setInitializedOrCreating(true)
      }

      // include all available default groups if they have not already been removed by the user
      // as well as any existing owning groups selected
      const updatedGroups = groups.filter(
        (g) =>
          (g.group_should_include_all_events_by_hosts_by_default &&
            !removed.has(g.id)) ||
          owningGroupIds.has(g.id)
      )

      // prevent rendering again when owning_groups doesn't change
      if (!isEqual(event.owning_groups, updatedGroups)) {
        onFieldChange(null, {
          name: 'owning_groups',
          value: updatedGroups,
        })
      }
    },
    [event.owning_groups, initializedOrCreating, removedGroups, onFieldChange]
  )

  useEffect(() => {
    if (availableGroupsResponse) {
      initializeOrUpdateGroups(availableGroupsResponse.groups)
    }
  }, [availableGroupsResponse, initializeOrUpdateGroups])

  const availableGroups = availableGroupsResponse?.groups || []

  const owningGroups = event.owning_groups || []

  return (
    <>
      <SectionHeaderWrapper>
        <Typography id="LinkedGroups" variant="h2">
          <F defaultMessage="Linked groups" />
        </Typography>
      </SectionHeaderWrapper>
      <Typography variant="body1">
        <F
          defaultMessage={
            'You can link this event to {orgName} groups you are an admin for. <more>Learn more</more>'
          }
          values={{
            orgName: organization?.name,
            more: (msg) => (
              <Link to="https://mblz.io/linking-groups-help" target="_blank">
                {msg}
              </Link>
            ),
          }}
        />
      </Typography>
      <Autocomplete
        multiple
        name="owning_groups"
        value={owningGroups.map((g) => g.id)}
        options={availableGroups.map((group) => ({
          text: group.name,
          value: group.id,
        }))}
        disabled={!initializedOrCreating}
        placeholder={
          owningGroups.length > 0
            ? undefined
            : intl.formatMessage(messages.placeholderText, {
                orgName: organization?.name,
              })
        }
        onChange={(_, {value}) => {
          const newValueSet = new Set(value)
          setRemovedGroupIds(
            new Set([
              ...availableGroups
                .filter((g) => !newValueSet.has(g.id))
                .map((g) => g.id),
            ])
          )
          onFieldChange(null, {
            name: 'owning_groups',
            value: value.map((i) =>
              availableGroups.find((group) => group.id === i)
            ),
          })
        }}
      />
    </>
  )
}
