import {Button, Message, MessageType} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {
  getActNowButtonProps,
  getPrimaryButtonProps,
  getVirtualActionText,
  shouldShowActLaterButton,
  shouldShowActNowButton,
  useSignupButtonLabel,
} from './util'
import {getDayForTimeslot, mapShiftToTimeslot} from 'util/timeslot'
import styles, {fontSizeSmall} from 'components/styles'

import {InView} from 'react-intersection-observer'
import VirtualEventTimeslotPicker from './VirtualEventTimeslotPicker'
import {getAvailableTimeslots} from 'events/details/util'
import styled from '@emotion/styled/macro'

const ActNowOr = styled.div`
  margin-top: ${styles.space.s};
  margin-bottom: ${styles.space.s};
  color: ${styles.colors.neutral400};
  ${fontSizeSmall};
  text-align: center;
`

const AddAnotherButtonWrapper = styled.div`
  margin-top: ${styles.space.m};
  margin-bottom: ${styles.space.m};
  text-align: center;
`
const i18nMessages = defineMessages({
  signupFailed: {defaultMessage: 'Signup failed.'},
  unknownDay: {defaultMessage: 'Unknown day'},
})

export default function ActionButtons({
  event,
  expandActLater,
  identityFields,
  onActLaterClick,
  onShiftsChange,
  onSignupButtonsInViewChange,
  shifts,
  signupCreatedWithinLastFiveSeconds,
  signupInFlightOrCreated,
  isGroupAndAlreadyJoined,
  isSelfCheckIn,
  ...props
}) {
  const primaryButtonProps = getPrimaryButtonProps(
    signupInFlightOrCreated,
    isGroupAndAlreadyJoined
  )
  const actNowButtonProps = getActNowButtonProps(
    event,
    signupCreatedWithinLastFiveSeconds,
    signupInFlightOrCreated,
    isGroupAndAlreadyJoined,
    onShiftsChange
  )
  const virtualActionText = getVirtualActionText(
    event,
    signupCreatedWithinLastFiveSeconds,
    signupInFlightOrCreated,
    isGroupAndAlreadyJoined
  )
  const showActNowButton = shouldShowActNowButton(event, expandActLater)
  const signupButtonText = useSignupButtonLabel({
    event,
    isFeed: false,
    signedUp: signupInFlightOrCreated,
    signupIdentityFields: identityFields,
    isSelfCheckIn,
  })

  function renderNonVirtualFlexibleEvents() {
    // Live for event details page
    return (
      <Button data-testid="actionbutton-submit" {...primaryButtonProps}>
        {signupButtonText}
      </Button>
    )
  }

  function renderVirtualFlexibleEvents() {
    return (
      <>
        {showActNowButton && (
          <Button {...actNowButtonProps} data-testid="act-now-button-primary">
            {virtualActionText}
          </Button>
        )}
        {shouldShowActLaterButton(event, expandActLater) && (
          <>
            {showActNowButton && (
              <ActNowOr>
                <F defaultMessage="OR" />
              </ActNowOr>
            )}
            <Button
              secondary={showActNowButton}
              fluid
              onClick={onActLaterClick}
              data-testid="act-now-button-secondary"
            >
              <F defaultMessage="Pick a time" />
            </Button>
          </>
        )}
        {expandActLater && (
          <ActLater
            event={event}
            onShiftsChange={onShiftsChange}
            primaryButtonProps={primaryButtonProps}
            shiftErrors={props.shiftErrors}
            shifts={shifts}
            signupInFlightOrCreated={signupInFlightOrCreated}
          />
        )}
      </>
    )
  }

  return (
    <InView onChange={onSignupButtonsInViewChange}>
      {!event.is_virtual_flexible && renderNonVirtualFlexibleEvents()}
      {event.is_virtual_flexible && renderVirtualFlexibleEvents()}
    </InView>
  )
}

// exported just for testing
export function ActLater({
  event,
  onShiftsChange,
  primaryButtonProps,
  shiftErrors,
  shifts,
  signupInFlightOrCreated,
}) {
  const intl = useIntl()
  const availableTimeslots = getAvailableTimeslots(event)
  return (
    <>
      {availableTimeslots &&
        shifts.map((shift, index) => (
          <VirtualEventTimeslotPicker
            timeslots={availableTimeslots}
            shift={shift}
            key={`${index}-${shift ? shift.timeslot_id : ''}`}
            onChange={(changedShift) => {
              onShiftsChange(
                // This is a rare case where the index is actually the right thing to look
                // at, since the user might be switching the timeslot the picker refers to
                // by switching the date
                shifts.map((s, i) => {
                  if (i === index) {
                    return changedShift
                  }
                  return s
                })
              )
            }}
          />
        ))}
      <AddAnotherButtonWrapper>
        <Button
          link
          padding="none"
          type="button"
          onClick={(e) => {
            e.preventDefault()
            onShiftsChange([...shifts, null])
          }}
          data-testid="act-later-another-button"
        >
          <F defaultMessage="+ Add another" />
        </Button>
      </AddAnotherButtonWrapper>
      {Object.keys(shiftErrors).length > 0 && (
        <Message
          type={MessageType.ERROR}
          header={intl.formatMessage(i18nMessages.signupFailed)}
          list={Object.keys(shiftErrors).map((timeslotId) => {
            const timeslot = mapShiftToTimeslot(availableTimeslots, timeslotId)
            let day = intl.formatMessage(i18nMessages.unknownDay)
            if (timeslot) {
              day = getDayForTimeslot(timeslot).format('ll')
            }
            return `${day}: ${shiftErrors[timeslotId]}`
          })}
        />
      )}
      <Button {...primaryButtonProps} data-testid="act-later-signup-button">
        {signupInFlightOrCreated ? (
          <F defaultMessage="Thanks for signing up!" />
        ) : (
          <F defaultMessage="Sign up" />
        )}
      </Button>
    </>
  )
}
