import {formatQueryString} from 'util/url'
import {logError} from 'util/common'

// TODO(ramil): Create a new common base class for this and data/api/ApiError
export class EveryActionApiError extends Error {
  response
  json
  status
  code
  extraData

  constructor(msg, res, json = null, code, extraData = null) {
    super(msg)

    this.response = res
    this.json = json
    this.status = res.status
    this.code = code
    this.extraData = extraData
  }
}

export function get(path, queryParams = null, additionalOptions = null) {
  return apiFetch('GET', path, queryParams, null, additionalOptions)
}

export function post(path, body, additionalOptions = null) {
  return apiFetch('POST', path, null, body, additionalOptions)
}

async function apiFetch(method, path, queryParams, body, additionalOptions) {
  let options = {
    method,
    body: body ? JSON.stringify(body) : undefined,
  }

  if (additionalOptions) {
    options = {...options, ...additionalOptions}
  }

  const apiPath = `${path}${queryParams ? formatQueryString(queryParams) : ''}`
  const res = await fetch(apiPath, options)

  let json
  try {
    json = await res.json()
  } catch (e) {
    // Server sent back HTML for some reason
    const err = new EveryActionApiError('Server error', res)
    if (res.status >= 500) {
      logError(err)
    }
    throw err
  }

  if (!res.ok || json.error) {
    const errMsg = json.error
      ? typeof json.error === 'string'
        ? json.error
        : json.error.message
      : String(res.status)
    const code = json.error ? json.error.code : null
    const err = new EveryActionApiError(errMsg, res, json, code)
    // Only log 5xx errors to Sentry; 4xx should generally be handled
    if (res.status >= 500) {
      logError(err)
    }
    throw err
  }

  return json
}
