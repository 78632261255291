import styles, {
  getBorderRadiusFromProps,
  getPrimaryTextColorFromProps,
  getSecondaryBodyFontFromProps,
  getSecondaryTextColorFromProps,
  mediaMinWidthSmall,
} from './styles'

import Typography from './Typography'
import {css} from '@emotion/react'
import {forwardRef} from 'react'
import styled from '@emotion/styled/macro'

const CardWrapper = styled.div`
  ${(props) => !props.fullWidthOnMobile && getBorderRadiusFromProps(props)};

  // default padding
  ${(props) =>
    (props.padding === 'default' || !props.padding) &&
    `padding-top: ${styles.space.s}; padding-bottom: ${styles.space.s};`}
  ${(props) =>
    (props.padding === 'default' || !props.padding) &&
    `padding-left: ${styles.space.m}; padding-right: ${styles.space.m};`}

  // extra padding
  ${(props) =>
    props.padding === 'extra' &&
    `padding-top: ${styles.space.l}; padding-bottom: ${styles.space.l};`}
  ${(props) =>
    props.padding === 'extra' &&
    `padding-left: ${styles.space.l}; padding-right: ${styles.space.l};`}

  background-color: ${styles.colors.white};
  ${getBorderRadiusFromProps};

  ${(props) =>
    props.fullWidthOnMobile
      ? `border-top: 1px solid ${styles.colors.neutral300}; border-bottom: 1px solid ${styles.colors.neutral300};`
      : `border: 1px solid ${styles.colors.neutral300};`};

  ${getSecondaryBodyFontFromProps};
  ${getSecondaryTextColorFromProps};

  ${(props) => props.inline && 'display: inline;'}
  ${(props) => props.marginBottom && `margin-bottom: ${styles.space.m};`}

  ${(props) =>
    mediaMinWidthSmall(css`
      border: 1px solid ${styles.colors.neutral300};
      ${getBorderRadiusFromProps(props)};
    `)};

  ${(props) =>
    props.expandVerticallyOnMobile &&
    css`
      display: flex;
      flex-direction: column;

      // Specifies a minimum height (instead of a height) in case the modal content exceeds the
      // height of the screen. Specify a vh height first in case stretch or its prefixed versions
      // aren't supported (though the vh version isn't great because a lot of the time on mobile,
      // the bottom will be hidden by browser chrome)
      min-height: ${styles.limits.mobileModalHeightPercentage}vh;
      min-height: stretch;
      ${mediaMinWidthSmall('min-height: auto;')}
    `};
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ActionWrapper = styled.div`
  position: relative;
  // Negative card padding values
  top: -${styles.space.s};
  right: -${styles.space.m};
`

const CardHeader = styled.div`
  font-weight: ${styles.typography.fontWeightSemibold};
  font-size: ${styles.typography.fontSizeBase};
  text-transform: uppercase;
  ${getPrimaryTextColorFromProps};
  ${getSecondaryBodyFontFromProps};
  margin-bottom: ${styles.space.s};
  letter-spacing: 1px;
`

const HeaderAsTitleWrapper = styled.div`
  margin-top: ${styles.space.m};
`

const ContentWrapper = styled.div`
  ${(props) =>
    props.expandVerticallyOnMobile &&
    css`
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}
`

/**
 * A card that renders children in a box, with an optional header and optional `action` render prop.
 */
const Card = forwardRef(
  (
    {
      action,
      children,
      expandVerticallyOnMobile,
      fullWidthOnMobile,
      header,
      headerAsTitle,
      inline,
      marginBottom,
      padding,
      ...props
    },
    ref
  ) => (
    <CardWrapper
      {...props}
      padding={padding}
      marginBottom={marginBottom}
      fullWidthOnMobile={fullWidthOnMobile}
      expandVerticallyOnMobile={expandVerticallyOnMobile}
      inline={inline}
      // $FlowFixMe(jared) this works fine in practice and i don't know how to get the types right
      ref={ref}
    >
      {(header || action) && (
        <HeaderWrapper>
          {headerAsTitle ? (
            <HeaderAsTitleWrapper>
              <Typography variant="h2">{header}</Typography>
            </HeaderAsTitleWrapper>
          ) : (
            <CardHeader>{header}</CardHeader>
          )}
          {action && <ActionWrapper>{action()}</ActionWrapper>}
        </HeaderWrapper>
      )}
      <ContentWrapper expandVerticallyOnMobile={expandVerticallyOnMobile}>
        {children}
      </ContentWrapper>
    </CardWrapper>
  )
)

// forwardRef renames the component to forwardRef(Card), which is annoying, so change it back
Card.displayName = 'Card'

export default Card
