import {Button, Link, Typography} from 'components'

import {F} from 'util/i18n'
import SignupButton from 'events/components/SignupButton'
import {getOrganizationEventUrl} from 'util/routing'
import {maybeGetEventSubheading} from 'util/event'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const ContentWrapper = styled.div`
  text-align: center;
  margin-bottom: ${styles.space.xl};
`

const HeadingWrapper = styled.div`
  margin-bottom: ${styles.space.l};
`

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > {
    * {
      margin-bottom: ${styles.space.m};
    }
  }
`

/**
 * This could be more sophisticated later, picking a group that's nearby,
 * or that we think would be interesting to the user, or at the very least
 * that isn't a group the user is already in.
 */
function pickAGroupToInviteUserTo(owningGroups) {
  return owningGroups[0]
}

export default function JoinOwningGroup({
  owningGroups,
  signupIdentityFields,
  organization,
}) {
  const group = pickAGroupToInviteUserTo(owningGroups)
  const url = getOrganizationEventUrl(organization, group)

  return (
    <ContentWrapper>
      <HeadingWrapper>
        <Typography center variant="h1">
          <F
            defaultMessage="Join {groupName}"
            values={{groupName: group.name}}
          />
        </Typography>
        <Typography center variant={'body2'}>
          {maybeGetEventSubheading(organization.slug, group)}
        </Typography>
        <Typography center variant="body1">
          <F defaultMessage="Members of this group receive group communications and invitations to upcoming events." />
        </Typography>
      </HeadingWrapper>
      <OptionsWrapper>
        <SignupButton
          event={group}
          organization={organization}
          showSignupModal={false}
          signupIdentityFields={signupIdentityFields}
          signupSource="MODAL_join_associated_group"
          centered
        />
        <Link to={url} plain>
          <Button secondary fluid>
            <F defaultMessage="View group" />
          </Button>
        </Link>
      </OptionsWrapper>
    </ContentWrapper>
  )
}
