import {ExperienceFeedbackType, Timezone} from 'app/enums'
import {F, createIntl, defineMessages} from './i18n'
import {
  browserTimezone,
  formatMinimalMomentRange,
  formatSingleStartTime,
  getMinimalTimeFormat,
} from './time'
import moment, {MOMENT_TOKENS} from 'vendor/moment'

import {groupBy} from './common'
import {isValidURL} from './url'
import {orgFlagIsActive} from './organization'
import {toDisplayList} from './string'

const messages = defineMessages({
  noTimesAvailable: {defaultMessage: 'No times available'},
})

export function formatEventTimesOneLine(event, shouldShowMoreTimes = true) {
  const intl = createIntl()
  const availableTimes = filterOutAtCapacityTimeslots(event.times)
  if (!availableTimes.length) {
    return intl.formatMessage(messages.noTimesAvailable)
  }
  if (event.is_virtual_flexible) {
    const {
      dateDescription,
      timeDescription,
      evenMoreTimeslots,
    } = getVirtualFlexibleEventTimeDescriptions(event, {
      isTerse: true,
    })
    return (
      <>
        {dateDescription}
        {timeDescription && <> {timeDescription}</>}
        {evenMoreTimeslots && <> + {evenMoreTimeslots}</>}
      </>
    )
  }
  const firstStartMoment = moment(availableTimes[0].start).tz(event.timezone)
  const formattedDate = formatSingleStartDate(
    firstStartMoment,
    event.timezone,
    {
      isTerse: true,
      canSwapTodayForDayName: true,
    }
  )
  const formattedTime = firstStartMoment.format(
    getMinimalTimeFormat(firstStartMoment, {
      includeAmPm: true,
      includeTimezone: true,
    })
  )
  const maybeFormattedMoreTimes =
    shouldShowMoreTimes && availableTimes.length > 1
      ? ` + ${intl.formatNumber(availableTimes.length - 1)} more time${
          availableTimes.length > 2 ? 's' : ''
        }`
      : ''
  return `${formattedDate} @ ${formattedTime}${maybeFormattedMoreTimes}`
}

// Lazily extracted out of `getEventTimes` for reuse; TODO(jared) rethink a lot of this time
// formatting to better support internationalization
// (see https://www.dropbox.com/scl/fi/1v23zdgi3wwgviktbypc3/State-of-the-frontend.paper?dl=0&rlkey=5n8kfm03otk99z8mnb5nv4rdy)
function getVirtualFlexibleEventTimeDescriptions(
  {timezone, times},
  {isTerse, locale}
) {
  let dateDescription = ''

  const availableTimes = filterOutAtCapacityTimeslots(times)
  const firstTimeslot = availableTimes[0]
  const firstStartMoment = moment(firstTimeslot.start).tz(timezone)
  const firstEndMoment = moment(firstTimeslot.end).tz(timezone)
  const usersTimezone = browserTimezone()
  const isWithinTimeWindow = isOngoingTime(firstTimeslot, timezone)

  const currentMoment = moment()
  const isMultiDay = firstEndMoment.day() > currentMoment.day()
  const isAllDay =
    firstEndMoment.day() > currentMoment.day() ||
    (firstStartMoment.hours() === 0 && firstEndMoment.hours() === 23)

  const usersFirstEndMoment = firstEndMoment.tz(usersTimezone)
  if (isWithinTimeWindow) {
    if (isMultiDay) {
      const usersEndTime = usersFirstEndMoment.format(
        getMinimalTimeFormat(usersFirstEndMoment, {
          includeAmPm: true,
          includeTimezone: true,
        })
      )
      // Since we are localizing for the Mexican dialect of Spanish, we use a
      // mixture of Spanish date description and English time description.
      // This approach with the Intl browser API is what we want to refactor
      // towards in the long-term.
      const usersEndDate =
        locale === 'es'
          ? new Intl.DateTimeFormat('es-mx', {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            }).format(new Date(firstTimeslot.end))
          : usersFirstEndMoment.format('dddd, MMMM D, YYYY')

      dateDescription = (
        <F
          defaultMessage="Do anytime before {usersEndTime} on {usersEndDate}"
          values={{usersEndTime, usersEndDate}}
        />
      )
    } else if (isAllDay) {
      dateDescription = <F defaultMessage="Do anytime today" />
    } else {
      dateDescription = (
        <F
          defaultMessage="Do anytime before {dateTimeWithTimezone} today"
          values={{
            dateTimeWithTimezone: usersFirstEndMoment.format(
              getMinimalTimeFormat(usersFirstEndMoment, {
                includeAmPm: true,
                includeTimezone: true,
              })
            ),
          }}
        />
      )
    }
  } else {
    if (locale === 'es') {
      // Since we are localizing for the Mexican dialect of Spanish, we use a
      // mixture of Spanish date description and English time description.
      // This approach with the Intl browser API is what we want to refactor
      // towards in the long-term.
      const timeslotStartDateObj = new Date(firstTimeslot.start)
      const spanishStartDayAndMonth = new Intl.DateTimeFormat('es-mx', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        timeZone: usersTimezone,
      }).format(timeslotStartDateObj)

      const englishStartTime = new Intl.DateTimeFormat('en', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: usersTimezone,
        timeZoneName: 'short',
      }).format(timeslotStartDateObj)

      dateDescription = (
        <F
          defaultMessage="Do anytime {dateTimeWithTimezone}"
          values={{
            dateTimeWithTimezone:
              spanishStartDayAndMonth + ' @ ' + englishStartTime,
          }}
        />
      )
    } else {
      dateDescription = (
        <F
          defaultMessage="Do anytime {dateTimeWithTimezone}"
          values={{
            dateTimeWithTimezone: formatSingleStartTime(
              firstTimeslot.start,
              usersTimezone
            ),
          }}
        />
      )
    }
  }

  return {
    dateDescription,
    firstAvailableISO: firstTimeslot.start,
  }
}

// This takes an events times and tries to make them human-readable, at least
// our take on that. It looks for patterns in times and tries to reasonably give
// the person an idea of when these events are taking place.
//
// Generally prefer using `formatEventTimesOneLine` for a string representation.
//
// Options:
// - `isTerse` is used if you want generally shortened text.
export function formatEventTimes(event, {isTerse = false, locale = 'en'} = {}) {
  const {
    is_virtual_flexible: isVirtualFlexible,
    times: eventTimes,
    timezone: eventTimezone,
  } = event
  // XXX(mime): it seems that sometimes timezone is null here for some reason...
  // See: https://sentry.io/organizations/mobilizeamerica/issues/1681244641/events/a86c8ba3b9bb49b0aa0647d260d332f3/?project=1191083&statsPeriod=14d
  const timezone = eventTimezone ?? Timezone.EASTERN

  if (isVirtualFlexible) {
    return getVirtualFlexibleEventTimeDescriptions(event, {isTerse, locale})
  }

  const availableTimes = filterOutAtCapacityTimeslots(eventTimes)
  if (!availableTimes.length) {
    return null
  }

  const firstTimeslot = availableTimes[0]
  const firstEndTime = firstTimeslot.end
  const firstEndMoment = moment(firstEndTime).tz(timezone)

  const availableDateTimes = availableTimes.map((timeslot) =>
    moment(timeslot.start).tz(timezone)
  )

  if (isVirtualFlexible) {
    return getVirtualFlexibleEventTimeDescriptions(event, isTerse)
  }

  const {datesResult, evenMoreTimeslotsResult, isMultipleDays} = _getDates(
    availableDateTimes,
    timezone,
    isTerse
  )

  if (locale === 'es') {
    // Since we are localizing for the Mexican dialect of Spanish, we use a
    // mixture of Spanish date description and English time description.
    // This approach with the Intl browser API is what we want to refactor
    // towards in the long-term.
    const firstAvailableStartTimeDate = new Date(firstTimeslot.start)
    const firstAvailableEndTimeDate = new Date(firstTimeslot.end)

    return {
      dateDescription: new Intl.DateTimeFormat('es-mx', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        timeZone: event.timezone,
      }).format(firstAvailableStartTimeDate),
      timeDescription: new Intl.DateTimeFormat('en', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: event.timezone,
        timeZoneName: 'short',
      }).formatRange(firstAvailableStartTimeDate, firstAvailableEndTimeDate),
      evenMoreTimeslots: evenMoreTimeslotsResult,
      firstAvailableISO: firstTimeslot.start,
      isMultiDays: isMultipleDays,
    }
  }
  // The English localization is our legacy approach that is hyper-optimized
  // for only an English-speaking audience. We want to simplify this logic to
  // create parity across all localizations and to the Intl browser API.

  return {
    dateDescription: datesResult,
    timeDescription:
      (isTerse ? '@ ' : '') +
      _getTimes(
        availableDateTimes,
        firstEndMoment,
        timezone,
        isTerse,
        !!evenMoreTimeslotsResult
      ),
    evenMoreTimeslots: evenMoreTimeslotsResult,
    firstAvailableISO: firstTimeslot.start,
    isMultiDays: isMultipleDays,
  }
}

// Private helper function to calculate times for `formatEventTimes`.
// This provides the date part of the datetime string.
function _getDates(availableDateTimes, timezone, isTerse) {
  let datesResult = ''
  let evenMoreTimeslotsResult = ''
  const tokenOpt = isTerse ? 'SHORT' : 'LONG'
  const {DAY_NAME} = MOMENT_TOKENS
  const startDate = availableDateTimes[0]
  const endDate = availableDateTimes[availableDateTimes.length - 1]

  const isMultiWeek = endDate.diff(startDate, 'days') > 7
  const timeslotsByDayOfWeek = groupBy(availableDateTimes, (date) => date.day())
  const daysOfWeek = Object.keys(timeslotsByDayOfWeek).sort()
  const daysOfWeekStr = JSON.stringify(daysOfWeek)
  const hasTimeslotsEveryDayOfWeek = daysOfWeek.length === 7
  const isMultipleDays = startDate.date() !== endDate.date()

  if (isMultiWeek && !hasTimeslotsEveryDayOfWeek) {
    if (daysOfWeekStr === '["0","6"]') {
      // Weekends
      datesResult = isTerse ? 'weekends' : 'Weekends'
    } else if (daysOfWeekStr === '["1","2","3","4","5"]') {
      // Weekdays
      datesResult = isTerse ? 'weekdays' : 'Weekdays'
    } else if (daysOfWeek.length === 6) {
      // Most of the week, e.g. Monday – Saturday
      const timeslotOnFirstDayOfRange = moment(
        timeslotsByDayOfWeek[daysOfWeek[0]][0]
      )
        .tz(timezone)
        .format(DAY_NAME[tokenOpt])
      const timeslotOnLastDayOfRange = moment(
        timeslotsByDayOfWeek[daysOfWeek[daysOfWeek.length - 1]][0]
      )
        .tz(timezone)
        .format(DAY_NAME[tokenOpt])
      datesResult = `${timeslotOnFirstDayOfRange}s – ${timeslotOnLastDayOfRange}s`
    } else if (daysOfWeek.length === 1) {
      // One day a week, e.g. Tuesdays
      datesResult = moment(startDate).tz(timezone).format(DAY_NAME[tokenOpt])
      datesResult = `${datesResult}${isTerse ? '' : 's'}`
    } else {
      // Otherwise, list out the days, e.g. Mondays, Wednesdays, and Fridays
      datesResult = toDisplayList(
        daysOfWeek.map(
          (day) =>
            `${moment(timeslotsByDayOfWeek[day][0])
              .tz(timezone)
              .format(DAY_NAME[tokenOpt])}${isTerse ? '' : 's'}`
        )
      )
    }
  } else {
    const startDateStr = formatSingleStartDate(startDate, timezone, {
      isTerse,
    })
    const endDateStr = formatSingleStartDate(endDate, timezone, {isTerse})

    if (isMultipleDays) {
      // TODO(mime): consolidate this with ShiftPicker logic.
      const timeslotsByDay = groupBy(availableDateTimes, (date) => date.date())
      const daysBetweenStartAndEnd = endDate.diff(startDate, 'days') + 1
      const isConsecutive =
        Object.keys(timeslotsByDay).length === daysBetweenStartAndEnd
      if (isConsecutive) {
        datesResult = `${startDateStr} – ${endDateStr}`
      } else {
        const extraDaysCount = Object.keys(timeslotsByDay).length - 1
        datesResult = startDateStr
        if (extraDaysCount) {
          evenMoreTimeslotsResult = `${extraDaysCount} more ${
            extraDaysCount > 1 ? 'days' : 'day'
          }`
          evenMoreTimeslotsResult = isTerse
            ? evenMoreTimeslotsResult
            : `+ ${evenMoreTimeslotsResult}`
        }
      }
    } else {
      datesResult = startDateStr
    }
  }

  return {datesResult, evenMoreTimeslotsResult, isMultipleDays}
}

// Private helper function to calculate times for `formatEventTimes`.
// This provides the hour/minute part of the datetime string.
function _getTimes(
  availableDateTimes,
  firstEndMoment,
  timezone,
  isTerse,
  displaysMoreDays
) {
  const startDate = availableDateTimes[0]
  const endDate = availableDateTimes[availableDateTimes.length - 1]
  const isMultipleDays = startDate.date() !== endDate.date()

  const timezoneShortName = moment(availableDateTimes[0])
    .tz(timezone)
    .format('z')

  // This groups by distinct times.
  // NB: we use Moment here instead of the date object directly because the times
  // can potentially go across daylight savings time boundaries
  // i.e. the same time at 5p.m. before the fall daylight savings time switchover.
  // was causing times looking like "5pm, 5pm CDT" when it really should just be 5pm.
  const timeslotsByTime = groupBy(availableDateTimes, (date) =>
    moment(date).tz(timezone).format('HH:mm')
  )
  const timesCount = Object.keys(timeslotsByTime).length

  // $FlowFixMe(mime): flow doesn't know that this isn't Array<mixed>
  const firstStartMoment = Object.values(timeslotsByTime)[0][0]
  if (!isTerse && timesCount === 1) {
    return (
      formatMinimalMomentRange(
        moment(firstStartMoment).tz(timezone),
        firstEndMoment,
        {onlyTimes: true}
      ) + ` ${timezoneShortName}`
    )
  } else if (timesCount <= 3) {
    if (isMultipleDays) {
      let timesResult
      timesResult = firstStartMoment.format(
        getMinimalTimeFormat(firstStartMoment, {
          includeAmPm: true,
          includeTimezone: true,
        })
      )
      const extraTimes = timesCount - 1
      timesResult +=
        // Special case: if the date display includes "+ 30 more days"
        // we don't want to also display "+ 4 more times"
        timesCount > 1 && !displaysMoreDays
          ? ` + ${extraTimes} more time${extraTimes !== 1 ? 's' : ''}`
          : ''
      return timesResult
    } else {
      return (
        Object.keys(timeslotsByTime)
          .sort()
          .map((t) => {
            const m = moment(timeslotsByTime[t][0]).tz(timezone)
            return m.format(getMinimalTimeFormat(m, {includeAmPm: true}))
          })
          .join(', ') + ` ${timezoneShortName}`
      )
    }
  }

  return 'Multiple times available'
}

export function formatSingleStartDate(
  startMoment,
  timezone,
  {isTerse = false, canSwapTodayForDayName = false} = {}
) {
  const thisMoment = moment().tz(timezone)
  const tokenOpt = isTerse ? 'SHORT' : 'LONG'
  const {DAY_NAME, DAY, MONTH, YEAR} = MOMENT_TOKENS
  const dayNameTokenOrToday =
    canSwapTodayForDayName && thisMoment.isSame(startMoment, 'day')
      ? // Square brackets make it not get parsed as a token: https://stackoverflow.com/a/28243492/15586340
        '[Today]'
      : DAY_NAME[tokenOpt]
  const format =
    `${dayNameTokenOrToday}, ${MONTH[tokenOpt]} ${DAY[tokenOpt]}` +
    (!thisMoment.isSame(startMoment, 'year') ? `, ${YEAR[tokenOpt]}` : '')
  return startMoment.format(format)
}

// returns error message or null
export function validatePotentialTimeslot(
  startDate,
  endDate,
  startTime,
  endTime,
  timezone,
  virtualJoinUrl,
  allowPastStartTime
) {
  if (!timezone) {
    return 'The timezone of this event is unknown. Please set a location to continue.'
  }
  if (!moment(startTime, 'HH:mm', true).isValid()) {
    return 'Invalid start time entered. Please correct this to continue.'
  }
  if (!moment(endTime, 'HH:mm', true).isValid()) {
    return 'Invalid end time entered. Please correct this to continue.'
  }
  const startMoment = moment.tz(
    `${startDate} ${startTime}`,
    'YYYY-MM-DD HH:mm',
    timezone
  )
  const endMoment = moment.tz(
    `${startDate} ${endTime}`,
    'YYYY-MM-DD HH:mm',
    timezone
  )
  const now = moment()
  const startDateMoment = moment(startDate).tz(timezone)
  const endDateMoment = moment(endDate).tz(timezone)

  if (startMoment.isBefore(now) && !allowPastStartTime) {
    return 'Part or all of this shift is in the past. Please correct this to continue.'
  }
  if (endMoment.isAfter(now.add(3, 'years'))) {
    return 'Part or all of this shift is more than three years in the future. Please correct this to continue.'
  }
  if (startMoment.isAfter(endMoment)) {
    return 'Start time is after end time. Please correct this to continue.'
  }
  if (startDateMoment.isAfter(endDateMoment)) {
    return 'Start date is after end date. Please correct this to continue.'
  }

  if (
    !!virtualJoinUrl &&
    !(isValidURL(virtualJoinUrl) && !!new URL(virtualJoinUrl).hostname)
  ) {
    return 'The video call URL must begin with "https://" or "http://". Please correct this to continue.'
  }
  return null
}

export function getDayForTimeslot(timeslot) {
  return moment(timeslot.start, moment.ISO_8601).clone().startOf('day')
}

export function isPastTimeslot(timeslot, relativeToMoment) {
  const momentForComparison = relativeToMoment || moment()
  return momentForComparison.isAfter(
    moment(timeslot.starts_at_utc, moment.ISO_8601)
  )
}

export function isUpcomingTimeslot(timeslot, relativeToMoment) {
  return !isPastTimeslot(timeslot, relativeToMoment)
}

export function isOngoingTimeslot(timeslot, relativeToMoment) {
  const momentForComparison = relativeToMoment || moment()
  return (
    momentForComparison.isAfter(
      moment(timeslot.starts_at_utc, moment.ISO_8601)
    ) &&
    momentForComparison.isBefore(moment(timeslot.ends_at_utc, moment.ISO_8601))
  )
}

export function isOngoingTime(timeslot, timezone, relativeToMoment) {
  const momentForComparison = relativeToMoment || moment()
  return (
    momentForComparison.isAfter(moment(timeslot.start).tz(timezone)) &&
    momentForComparison.isBefore(moment(timeslot.end).tz(timezone))
  )
}

export function mapShiftToTimeslot(availableTimeslots, timeslotIdStr) {
  const timeslotId = parseInt(timeslotIdStr, 10)
  return availableTimeslots.find((ts) => ts.id === timeslotId)
}

export function getLatestTimeslot(event, customTimeFormat) {
  let timeFormat = ''

  if (customTimeFormat) {
    timeFormat = customTimeFormat
  } else {
    timeFormat = 'M/DD/YY, h:mma z'
  }

  return event.latest_timeslot
    ? moment(event.latest_timeslot.starts_at_utc)
        .tz(event.timezone)
        .format(timeFormat)
    : 'Nothing scheduled'
}

export function getNextTimeslot(event, customTimeFormat) {
  let timeFormat = ''

  if (customTimeFormat) {
    timeFormat = customTimeFormat
  } else {
    timeFormat = 'M/DD/YY, h:mma z'
  }

  return event.next_timeslot
    ? moment(event.next_timeslot.starts_at_utc)
        .tz(event.timezone)
        .format(timeFormat)
    : 'Nothing scheduled'
}

export function filterOutAtCapacityTimeslots(timeslots) {
  return timeslots.filter((timeslot) => !isAtCapacity(timeslot))
}

// In practice, both Timeslot and Participation have this property

export function isAtCapacity({remaining_spots: remainingSpots}) {
  return typeof remainingSpots === 'number' && remainingSpots <= 0
}

// Used for Event Details timeslot checkboxes/radio buttons, where we want to
// explicitly specify timeslot start and end times
export function formatTimeslot(timeslot, timezone, locale) {
  if (locale === 'es') {
    // Since we are localizing for the Mexican dialect of Spanish, we use a
    // mixture of Spanish date description and English time description.
    // This approach with the Intl browser API is what we want to refactor
    // towards in the long-term.
    const startDate = new Date(timeslot.start)
    const endDate = new Date(timeslot.end)
    const esDayString = new Intl.DateTimeFormat('es-mx', {
      weekday: 'short',
    }).format(startDate)
    const esDateString = new Intl.DateTimeFormat('es-mx', {
      month: 'short',
      day: 'numeric',
    }).format(startDate)
    const esTimeRange = new Intl.DateTimeFormat('en', {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
      timeZone: timezone,
    }).formatRange(startDate, endDate)
    return `${esDayString}, ${esDateString}, ${esTimeRange}`
  }
  const now = moment().tz(timezone)
  const startMoment = moment(timeslot.start).tz(timezone)
  const start = startMoment.format(
    startMoment.year() === now.year()
      ? 'ddd, MMM D, h:mma'
      : 'ddd, MMM D, YYYY, h:mma' // Include the year if the year is not this year
  )
  const end = moment(timeslot.end).tz(timezone).format('h:mma z')
  return `${start}–${end}`
}

export function formatTimeslotStartShort(timeslot, timezone) {
  const now = moment().tz(timezone)
  const startMoment = moment(timeslot.start).tz(timezone)
  // Include the year if the year is not this year
  const maybeYearComponent =
    startMoment.year() === now.year() ? '' : `, ${MOMENT_TOKENS.YEAR.SHORT}`
  const dateFormat = now.isSame(startMoment, 'day')
    ? '[today]' // square brackets keep this from being interpreted as moment tokens
    : `${MOMENT_TOKENS.MONTH.SHORT} ${MOMENT_TOKENS.DAY.SHORT}${maybeYearComponent}`
  const format = `${dateFormat} @ ${getMinimalTimeFormat(startMoment, {
    includeAmPm: true,
    includeTimezone: true,
  })}`

  return startMoment.format(format)
}

export const maybeGetRatingsDisplayString = (ratings) => {
  const myRatings = ratings || {}
  const positives = myRatings[ExperienceFeedbackType.APPROVED_OF_SHIFT] || 0
  const negatives = myRatings[ExperienceFeedbackType.DISAPPROVED_OF_SHIFT] || 0
  const totalRatings = positives + negatives
  if (totalRatings === 0) {
    return null
  }
  const percent = Math.round((100 * positives) / totalRatings)
  return {
    percent: `${percent}% positive`,
    total: `(${totalRatings} responses)`,
  }
}

export function arePrivateDetailsSet(
  // use TimeslotPrivateDetailsInexact so we can pass in whole TimeslotRows
  {privateDetails, virtualJoinUrl, zoomMeetingId, zoomMeetingType},
  org
) {
  const anyNonZoomDetailSet = !!privateDetails || !!virtualJoinUrl
  const zoomEnabled =
    orgFlagIsActive(org, 'enable_zoom_integration') &&
    org?.is_zoom_integration_active
  if (zoomEnabled) {
    const bothZoomDetailsSet = !!zoomMeetingId && !!zoomMeetingType
    return anyNonZoomDetailSet || bothZoomDetailsSet
  }
  return anyNonZoomDetailSet
}

export function timeslotOrEventPrivateDetailsUpdateFromZoomMeetingInfo(
  zoomMeetingInfo
) {
  const update = {}
  if (zoomMeetingInfo.error_message) {
    return update
  }
  update.zoomMeetingType = zoomMeetingInfo.zoom_meeting_type
  if (zoomMeetingInfo.requires_registration) {
    update.virtualJoinUrl = null // should already be null but just in case
  } else {
    update.virtualJoinUrl = zoomMeetingInfo.join_url
    update.zoomMeetingId = null
  }
  return update
}

export function validatePrivateDetails(org, timeslotPrivateDetails) {
  const {
    virtualJoinUrl,
    zoomMeetingId,
    zoomMeetingType,
  } = timeslotPrivateDetails

  if (!!virtualJoinUrl && !isValidURL(virtualJoinUrl)) {
    return false
  }

  const zoomEnabled =
    org &&
    orgFlagIsActive(org, 'enable_zoom_integration') &&
    org.is_zoom_integration_active
  if (zoomEnabled) {
    if (zoomMeetingType && !zoomMeetingId && !virtualJoinUrl) {
      return false
    }
  }

  return true
}
