import {Button} from 'components'
import styles from 'components/styles'

/**
 * Low-level Button wrapper with some Facebook defaults. You probably want FacebookLoginButton
 */
function FacebookButton(props) {
  return <Button {...props} />
}

FacebookButton.defaultProps = {
  color: styles.colors.facebookBlue,
  icon: 'facebook-square',
  iconPosition: 'left',
  iconFontSize: '1.5rem',
  children: 'Continue with Facebook',
}

export default FacebookButton
