import {
  DEFAULT_PHONE_COUNTRY_CODE,
  formatPhoneNumberAsRFC3966,
  formatPhoneNumberForDisplay,
  isValidPhoneNumber,
} from 'util/phone-number'
import {
  Input,
  Link,
  TextArea,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from 'components'
import {
  VirtualActionOptions,
  getVirtualActionOptionPredefinedMessage,
} from 'app/enums'
import {formatQueryString, getQueryParams, isValidURL} from 'util/url'
import {isFbShareUrl, isTweetUrl} from 'util/share'
import {useContext, useState} from 'react'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import Icon from 'components/Icon'
import VirtualActionSocialShareSettings from './VirtualActionSocialShareSettings'
import clientVars from 'util/clientVars'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const ActionButtonBehaviorHeading = styled.div`
  margin-bottom: ${styles.space.s};
`

const ActionButtonBehaviorDetails = styled.div`
  margin-bottom: ${styles.space.m};
`

const ToggleButtonWrapper = styled.div`
  width: 100%;
  text-align: left;
`

const getVirtualActionOptions = (excludedOptions) => {
  let options = [
    {
      value: VirtualActionOptions.EXTERNAL_LINK,
      key: VirtualActionOptions.EXTERNAL_LINK,
      text: 'Link to an external site',
      icon: 'external-link',
    },
    {
      value: VirtualActionOptions.SOCIAL_SHARE,
      key: VirtualActionOptions.SOCIAL_SHARE,
      text: 'Share post on social media',
      icon: 'share',
    },
    {
      value: VirtualActionOptions.PHONE_CALL,
      key: VirtualActionOptions.PHONE_CALL,
      text: 'Dial a phone number',
      icon: 'phone',
    },
    {
      value: VirtualActionOptions.SEND_EMAIL,
      key: VirtualActionOptions.SEND_EMAIL,
      text: 'Draft an email',
      icon: 'envelope',
    },
  ]
  return options.filter((option) => !excludedOptions?.includes(option.value))
}

export const inferSelectedAction = (actionData) => {
  const {url} = actionData

  try {
    if (url) {
      const actionUrl = new URL(url.includes(':') ? url : `https://${url}`)

      if (actionUrl.protocol.startsWith('tel')) {
        return VirtualActionOptions.PHONE_CALL
      } else if (actionUrl.protocol.startsWith('mailto')) {
        return VirtualActionOptions.SEND_EMAIL
      } else if (
        isValidURL(url) &&
        (isTweetUrl(actionUrl) || isFbShareUrl(actionUrl))
      ) {
        return VirtualActionOptions.SOCIAL_SHARE
      } else {
        return VirtualActionOptions.EXTERNAL_LINK
      }
    } else {
      return VirtualActionOptions.EXTERNAL_LINK
    }
  } catch (e) {
    // return the default if there is an error
    return VirtualActionOptions.EXTERNAL_LINK
  }
}

// get email addresses from url by cutting off 'mailto:'
// or phone number by cutting off 'tel:'
const inferEmailsOrPhoneNumber = (selectedAction, url) => {
  if (
    url &&
    (selectedAction === VirtualActionOptions.SEND_EMAIL ||
      selectedAction === VirtualActionOptions.PHONE_CALL)
  ) {
    const actionUrl = new URL(url)
    const value = actionUrl.pathname
    return selectedAction === VirtualActionOptions.PHONE_CALL
      ? formatPhoneNumberForDisplay(value)
      : value
  }
  return ''
}

const inferEmailSubject = (selectedAction, url) => {
  if (url && selectedAction === VirtualActionOptions.SEND_EMAIL) {
    const fullUrl = new URL(url)
    return getQueryParams(fullUrl.search).subject
  }
  return ''
}

const inferEmailBody = (selectedAction, url) => {
  if (url && selectedAction === VirtualActionOptions.SEND_EMAIL) {
    const fullUrl = new URL(url)
    return getQueryParams(fullUrl.search).body
  }
  return ''
}

export default function VirtualActionSettings({
  virtualActionUrl,
  virtualActionButtonText,
  onInputChange,
  updateFields,
}) {
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const [selectedAction, setSelectedAction] = useState(
    inferSelectedAction({
      url: virtualActionUrl,
      buttonText: virtualActionButtonText,
    })
  )
  const [phoneNumber, setPhoneNumber] = useState(
    inferEmailsOrPhoneNumber(selectedAction, virtualActionUrl)
  )

  const phoneNumberInvalid =
    !!phoneNumber &&
    !isValidPhoneNumber(phoneNumber, DEFAULT_PHONE_COUNTRY_CODE)

  const [emailAddrs, setEmailAddrs] = useState(
    inferEmailsOrPhoneNumber(selectedAction, virtualActionUrl)
  )

  const [emailSubject, setEmailSubject] = useState(
    inferEmailSubject(selectedAction, virtualActionUrl)
  )

  const [emailBody, setEmailBody] = useState(
    inferEmailBody(selectedAction, virtualActionUrl)
  )

  const includeSocialShare = orgFlagIsActive(
    currentOrganization,
    'enable_social_share_actions'
  )

  const virtualActionOptions = getVirtualActionOptions(
    !includeSocialShare ? [VirtualActionOptions.SOCIAL_SHARE] : []
  )

  const onSelectedActionChange = (value) => {
    setSelectedAction(value)
    setPhoneNumber(null)
    setEmailAddrs(null)
    const buttonText =
      getVirtualActionOptionPredefinedMessage(value)?.defaultMessage || ''
    updateFields({
      virtual_action_button_text: buttonText,
      virtual_action_url: null,
    })
    setPhoneNumber(null)
    setEmailAddrs(null)
  }

  const onSetPhone = (phoneNumber) => {
    if (
      phoneNumber &&
      phoneNumber.length >= 5 &&
      isValidPhoneNumber(phoneNumber, DEFAULT_PHONE_COUNTRY_CODE)
    ) {
      setPhoneNumber(formatPhoneNumberForDisplay(phoneNumber))
    } else {
      setPhoneNumber(phoneNumber)
    }
    const telUrl = formatPhoneNumberAsRFC3966(phoneNumber)
    updateFields({virtual_action_url: telUrl})
  }

  const onSetEmails = (emailAddresses, emailSubject, emailBody) => {
    setEmailAddrs(emailAddresses)
    setEmailSubject(emailSubject)
    setEmailBody(emailBody)

    if (!emailAddresses) {
      updateFields({virtual_action_url: ''})
      return
    }
    const params = {}
    if (emailSubject) {
      params.subject = emailSubject
    }
    if (emailBody) {
      params.body = emailBody
    }
    const emailUrl = `mailto:${emailAddresses}${formatQueryString(params)}`
    updateFields({virtual_action_url: emailUrl})
  }

  const showExternalLinkFields =
    selectedAction === VirtualActionOptions.EXTERNAL_LINK
  const showPhoneField = selectedAction === VirtualActionOptions.PHONE_CALL
  const showEmailFields = selectedAction === VirtualActionOptions.SEND_EMAIL
  const showSocialFields = selectedAction === VirtualActionOptions.SOCIAL_SHARE

  return (
    <>
      <Typography variant="h2">Action settings</Typography>
      <Typography variant="body1">
        An “action button” will register supporters and start the action.
        <br />
        Learn more in the{' '}
        <Link to="https://mblz.io/anytimeaction" target="_blank">
          Help Center
        </Link>
        . Check out our{' '}
        <Link
          to={`${clientVars.marketing_site_url}/tags/organizing`}
          target="_blank"
        >
          blog
        </Link>{' '}
        for inspiration.
      </Typography>
      <ToggleButtonGroup
        exclusive
        twoColumn
        label={
          <ActionButtonBehaviorHeading>
            <Typography variant="h3">
              When supporters click the button…
            </Typography>
          </ActionButtonBehaviorHeading>
        }
      >
        {virtualActionOptions.map((option, index) => (
          <ToggleButton
            key={index}
            name={option.text}
            onClick={() => onSelectedActionChange(option.value)}
            selected={selectedAction === option.value}
          >
            <ToggleButtonWrapper>
              {option.icon && <Icon name={option.icon} />}
              {option.text}
            </ToggleButtonWrapper>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {selectedAction && (
        <ActionButtonBehaviorDetails>
          <Typography variant="h3">
            {showExternalLinkFields
              ? 'Link details'
              : showSocialFields
              ? 'Post details'
              : showPhoneField
              ? 'Call details'
              : showEmailFields
              ? 'Email details'
              : ''}
          </Typography>
        </ActionButtonBehaviorDetails>
      )}
      {showExternalLinkFields && (
        <>
          <Input
            value={virtualActionButtonText || ''}
            label="Action button text"
            required
            name="virtual_action_button_text"
            onChange={onInputChange}
          />
          <Input
            value={virtualActionUrl || ''}
            label="External site URL"
            name="virtual_action_url"
            onChange={onInputChange}
            maxLength={1000}
            required
          />
        </>
      )}
      {showPhoneField && (
        <Input
          value={phoneNumber || ''}
          type="tel"
          label="Phone number"
          name="virtual_action_phone"
          onChange={(e) => onSetPhone(e.currentTarget.value)}
          required
          hint={
            phoneNumberInvalid
              ? 'Please enter a valid phone number.'
              : !phoneNumber &&
                'Double-check that you are providing the correct number'
          }
          validated={!!phoneNumber && !phoneNumberInvalid}
          error={phoneNumberInvalid}
        />
      )}
      {showEmailFields && (
        <>
          <TextArea
            value={emailAddrs || ''}
            label="Email addresses"
            name="virtual_action_email"
            onChange={(e) =>
              onSetEmails(e.currentTarget.value, emailSubject, emailBody)
            }
            required
            hint="Separate multiple email addresses with a comma"
          />
          <Input
            value={emailSubject || ''}
            label="Email subject"
            name="virtual_action_email_subject"
            onChange={(e) =>
              onSetEmails(emailAddrs, e.currentTarget.value, emailBody)
            }
          />
          <TextArea
            value={emailBody || ''}
            label="Email message"
            name="virtual_action_email_body"
            onChange={(e) =>
              onSetEmails(emailAddrs, emailSubject, e.currentTarget.value)
            }
            hint={`1500 characters maximum. Current length: ${
              (emailBody || '').length
            }`}
          />
        </>
      )}
      {showSocialFields && (
        <VirtualActionSocialShareSettings
          virtualActionUrl={virtualActionUrl}
          updateFields={updateFields}
        />
      )}
    </>
  )
}
