import {
  Step as MaterialStep,
  StepLabel as MaterialStepLabel,
  Stepper as MaterialStepper,
} from '@material-ui/core'

export function Stepper({children, ...props}) {
  return <MaterialStepper {...props}>{children}</MaterialStepper>
}

export function Step({children, ...props}) {
  return <MaterialStep {...props}>{children}</MaterialStep>
}

export function StepLabel({children, ...props}) {
  return <MaterialStepLabel {...props}>{children}</MaterialStepLabel>
}
