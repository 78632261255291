import {Button, InputGroup, Link} from 'components'
import {
  getHostFormUrlFromEventCampaign,
  getOrganizationFeedUrl,
} from 'util/routing'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import FeedItem from 'events/components/FeedItem'
import {maybeGetDisplayDateFromEventCampaign} from 'util/time'
import {useContext} from 'react'

export default function EventCampaignFeedItem({
  eventCampaign,
  eventCreationSource,
}) {
  const {currentOrganization} = useContext(CurrentOrganizationContext)

  // Make flow happy :-/
  if (!currentOrganization) {
    return null
  }

  const createUrl = getHostFormUrlFromEventCampaign(
    currentOrganization,
    eventCampaign,
    eventCreationSource ? {event_creation_source: eventCreationSource} : {}
  )
  const examplesUrl = getOrganizationFeedUrl(currentOrganization, {
    eventCampaign: eventCampaign.id,
    showAllEvents: true,
  })
  const displayTime =
    maybeGetDisplayDateFromEventCampaign(eventCampaign) || 'Anytime'

  return (
    <FeedItem
      linkTo={createUrl}
      dontLinkButtons={true}
      headline={eventCampaign.name}
      aria-label={eventCampaign.name}
      time={displayTime}
      actionContent={() => (
        <InputGroup stackOnMobile>
          <Link to={createUrl} plain>
            <Button icon="arrow-right">Create your event</Button>
          </Link>
          <Link to={examplesUrl} plain>
            <Button secondary icon="arrow-right">
              See examples
            </Button>
          </Link>
        </InputGroup>
      )}
      imageUrl={eventCampaign.hosted_event_images[0]}
    />
  )
}
