import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core'

import Icon from './Icon'
import Typography from './Typography'

export default function Accordion({children, expanded, header, onChange}) {
  return (
    <div>
      <MuiAccordion expanded={expanded} onChange={onChange} square={false}>
        <MuiAccordionSummary expandIcon={<Icon name="caret-down" />}>
          <Typography variant="h4">{header}</Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails>{children}</MuiAccordionDetails>
      </MuiAccordion>
    </div>
  )
}
