import {
  AcceptedCountryCodes,
  CountryCode,
  UsStatesAndTerritories,
} from 'app/enums'
import {
  Autocomplete,
  Button,
  CheckboxField,
  ClippyHint,
  FormItem,
  Input,
  InputGroup,
  Link,
  Message,
  MessageType,
  Typography,
} from 'components'
import {Component, createRef} from 'react'
import {EMPTY_LOCATION_VALIDATION_RESULTS, ENTER_KEY_CODE} from 'app/constants'
import {
  arePrimaryLocationAddressPartsEqual,
  geocodeByAddress,
  getAddressPartsFromGoogleResult,
  getLatLng,
  getLocationFromAddressParts,
  getOneLineAddressFromLocation,
  getPlaceDetails,
  maybeGetStateOrTerritoryFromLocation,
  validateAddressParts,
  validateLocation,
} from 'util/geo'
import {isEqual, logError} from 'util/common'
import styles, {mediaMinWidthSmall} from 'components/styles'

import {F} from 'util/i18n'
import PlacesAutocomplete from 'react-places-autocomplete'
import analytics from 'analytics'
import {css} from '@emotion/react'
import {keyframes} from '@emotion/react'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'

const EMPTY_LOCATION = {
  name: '',
  address_line1: '',
  address_line2: '',
  city: '',
  state: '',
  zipcode: '',
  country: CountryCode.UNITED_STATES,
  lat: null,
  lon: null,
}

const STATES_AND_TERRITORY_OPTIONS = Object.entries(UsStatesAndTerritories)
  // $FlowFixMe - We know the RHS of UsStatesAndTerritories is a string
  .map(([abbr, name]) => ({
    text: name,
    value: abbr,
  }))
  .sort(({text: firstName}, {text: secondName}) =>
    firstName.localeCompare(secondName)
  )

const LocationSearchInputWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
`

const LocationSearchInputContainer = styled.div`
  display: flex;
  margin-bottom: -1rem;
  position: relative;
  width: 100%;
`

const LocationResults = styled.div`
  position: absolute;
  z-index: ${styles.zindex.popup};
  top: 64px;
  width: 100%;
  min-width: 450px;
`

const LocationSearchItem = styled.div`
  text-align: left;
  padding: 10px;
  padding-left: 42px;
  background-color: ${styles.colors.white};
  border: 1px solid ${styles.colors.neutral300};
  border-top: none;
  cursor: pointer;
  color: ${styles.colors.neutral400};

  &:first-of-type {
    border-top: 1px solid ${styles.colors.neutral300};
  }

  ${(props) =>
    props.active && `background-color: ${styles.colors.neutral100};`};
  ${(props) => props.loading && `color: ${styles.colors.neutral300};`};
`

const fadeInSlideDownAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const MessageWrapper = styled.div`
  margin-top: ${styles.space.m};
  ${(props) =>
    props.fadeIn &&
    css`
      animation: ${fadeInSlideDownAnimation} 0.5s ease-in;
    `};
`

const AddressSuggestionContent = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaMinWidthSmall(css`
    justify-content: space-between;
    flex-direction: row;
  `)};
`

const SuggestedAddressText = styled.div`
  margin-top: ${styles.space.s};
  font-weight: ${styles.typography.fontWeightBold};
`

const AddressSuggestionOptionsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin-top: ${styles.space.s};

  ${mediaMinWidthSmall(css`
    margin-top: 0;
  `)};
`

const CheckboxContainer = styled.div`
  width: 576px;
`

const BlockLink = styled.div`
  ${Link} {
    display: block;
  }
`

class LocationSearchInput extends Component {
  inputRef = createRef()

  state = {
    address: this.props.defaultValue || '',
    addressParts: null,
    autocompleteDisabled: false,
    currentLocation:
      // Might need to revisit this heuristic for whether the location is empty or not, but for now,
      // see if it has a lat/lon
      this.props.defaultLocation &&
      this.props.defaultLocation.lat &&
      this.props.defaultLocation.lon
        ? this.props.defaultLocation
        : null,
    lastAddressSubmitted: '',
    lastLocationSubmitted: null,
    locationLatLon: null,
    fetchingLocation: false,
    geocodingInProgress: false,
    getLocationError: false,
    sessionToken: null,
    suggestedAddress: null,
    suggestedAddressParts: null,
    suggestedLocation: null,
  }

  // TODO(ramil) Session tokens expire 3 minutes after first use if a place is not selected,
  // but we can implement that edge case in the future.
  regenerateSessionToken = () => {
    if (this.props.useSessionToken) {
      this.setState({
        sessionToken: new window.google.maps.places.AutocompleteSessionToken(),
      })
    }
  }

  handleBlur = async () => {
    if (this.state.address) {
      await this.handleSelect(this.state.address)
    }
  }

  handleChange = (address) => {
    this.setState({address})
    if (address === '') {
      this.clearLocation()
    }
  }

  // Handler for the get current position button.
  handleGetLocation = async () => {
    if ('geolocation' in navigator) {
      this.setState({
        fetchingLocation: true,
        getLocationError: false,
      })
      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          const {coords} = pos
          const latLngString = `${coords.latitude}, ${coords.longitude}`
          let address, addressComponents
          try {
            const results = await geocodeByAddress(latLngString)
            address = results[0].formatted_address
            addressComponents = results[0].address_components
          } catch (e) {
            logError(e)
            this.setState({
              fetchingLocation: false,
              getLocationError: true,
            })
            return
          }
          this.setState({
            address,
            fetchingLocation: false,
          })
          this.locationChanged({
            address,
            addressComponents,
            latLng: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
            locationName: '',
          })
        },
        (error) => {
          this.setState({
            fetchingLocation: false,
            getLocationError: true,
          })
        }
      )
    }
  }

  handleSelect = async (address, placeId) => {
    if (address === this.state.lastAddressSubmitted) {
      return
    }

    this.setState({
      address,
      lastAddressSubmitted: address,
      geocodingInProgress: true,
    })
    const {useSessionToken} = this.props
    let results
    let placeDetailsRequested = false
    try {
      if (useSessionToken && placeId) {
        // If a session token is present, get the place details since we are already being billed
        // for the fixed cost Autocomplete and Place Details by session request.
        placeDetailsRequested = true
        results = await getPlaceDetails(placeId, this.state.sessionToken)
      } else {
        // If no session token is present, make the less expensive request billed under the
        // "Geocoding" SKU
        const response = await geocodeByAddress(address)
        results = response[0]
      }
    } catch (e) {
      this.clearLocation()
      return
    }
    this.setState({geocodingInProgress: false})

    if (placeDetailsRequested && useSessionToken) {
      this.regenerateSessionToken()
    }

    const latLng = getLatLng(results)
    const addressComponents = results.address_components
    const locationName = results.name || ''
    this.locationChanged({address, addressComponents, latLng, locationName})
  }

  clearLocation = () => {
    this.setState({
      address: '',
      addressParts: null,
      autocompleteDisabled: false,
      currentLocation: null,
      geocodingInProgress: false,
      lastAddressSubmitted: '',
      lastLocationSubmitted: null,
      suggestedAddress: null,
    })
    this.selectEmptyAddress()
    analytics.trackLocationCleared()
  }

  handleLocationInputFieldKeyDown = async (e) => {
    if (e.keyCode !== ENTER_KEY_CODE) return
    await this.handleLocationInputFieldChange()
  }

  handleLocationInputFieldChange = async () => {
    const {
      address,
      addressParts,
      currentLocation,
      lastLocationSubmitted,
    } = this.state
    if (!currentLocation) return

    const primaryLocationPartsHaveNotChanged =
      lastLocationSubmitted &&
      arePrimaryLocationAddressPartsEqual(
        currentLocation,
        lastLocationSubmitted
      )

    // Exit early if the primary address fields have not changed.
    if (primaryLocationPartsHaveNotChanged) {
      // Still send an update through the onSelect() callback if something has changed.
      if (!isEqual(currentLocation, lastLocationSubmitted)) {
        this.selectAddress(address, currentLocation, addressParts)
      }
      return
    }

    const newAddress = getOneLineAddressFromLocation(currentLocation)
    let results
    this.setState({geocodingInProgress: true})
    try {
      results = await geocodeByAddress(newAddress)
      this.setState({
        lastLocationSubmitted: currentLocation,
        geocodingInProgress: false,
      })
    } catch (e) {
      // TODO(ramil) Display an error state when this geocoding fails.
      console.log(e)
      this.setState({geocodingInProgress: false})
      return
    }

    const addressComponents = results[0].address_components
    const geocodedAddressParts = getAddressPartsFromGoogleResult(
      addressComponents
    )
    const geocodedLocation = getLocationFromAddressParts(geocodedAddressParts)
    const latLng = getLatLng(results[0])
    const suggestedLocation = {
      ...geocodedLocation,
      lat: latLng.lat,
      lon: latLng.lng,
    }
    // TODO(ramil) Figure out if suggested locations that fail validation should display.
    const suggestedAddress = getOneLineAddressFromLocation(suggestedLocation)
    currentLocation.lat = latLng.lat
    currentLocation.lon = latLng.lng

    this.setState({
      address: newAddress,
      // Since the new address did not come from a Google result, there are no addressParts
      addressParts: null,
      currentLocation,
      suggestedAddress,
      suggestedAddressParts: geocodedAddressParts,
      suggestedLocation,
    })

    this.selectAddress(newAddress, currentLocation)

    analytics.trackLocationSuggested({
      suggestedAddress,
      suggestedLat: suggestedLocation.lat,
      suggestedLon: suggestedLocation.lon,
      submittedAddress: newAddress,
    })
  }

  selectAddress = (addressOneLiner, location, addressParts) => {
    const searchResult = {addressOneLiner, location}
    if (this.props.validateSearchResults) {
      const validationResults = addressParts
        ? validateAddressParts(addressParts)
        : validateLocation(location)
      searchResult.validations = validationResults
    }
    this.props.onSelect(searchResult)
  }

  selectEmptyAddress = () => {
    this.props.onSelect({
      addressOneLiner: '',
      location: {...EMPTY_LOCATION},
      validations: {
        ...EMPTY_LOCATION_VALIDATION_RESULTS,
      },
    })
  }

  locationChanged = async (locationComponents) => {
    const {
      address,
      addressComponents,
      locationName,
      latLng,
    } = locationComponents
    if (!addressComponents) {
      return
    }
    const addressParts = getAddressPartsFromGoogleResult(addressComponents)
    const {addressLine1, city, state, zipcode, country} = addressParts
    const location = {
      name: locationName !== addressLine1 ? locationName || '' : '',
      address_line1: addressLine1,
      address_line2: '',
      city,
      state,
      zipcode,
      country,
      lat: latLng ? latLng.lat : null,
      lon: latLng ? latLng.lng : null,
    }
    this.setState({
      // Disable autocomplete when location fields are shown.
      autocompleteDisabled: !!this.props.showLocationFields,
      currentLocation: location,
      lastLocationSubmitted: location,
    })
    this.selectAddress(address, location, addressParts)
  }

  onLocationFieldChange = (e) => {
    const {name, value} = e.currentTarget
    this.setState({
      currentLocation: {
        ...EMPTY_LOCATION,
        ...this.state.currentLocation,
        [name]: value,
      },
    })
  }

  findCountryCode = (value) => {
    // $FlowFixMe(mime): 'find' is an esnext feature.
    const countryCode = AcceptedCountryCodes.find((code) => code === value)
    return countryCode || CountryCode.UNITED_STATES
  }

  onSelectStateOrTerritory = (e, {value}) => {
    const country = this.findCountryCode(value)
    // If we are updating to a country, empty out the state.
    const state = country === CountryCode.UNITED_STATES ? value : ''
    this.setState(
      {
        currentLocation: {
          ...EMPTY_LOCATION,
          ...this.state.currentLocation,
          country,
          state,
        },
      },
      this.handleLocationInputFieldChange
    )
  }

  acceptSuggestedAddress = () => {
    const {
      suggestedAddress,
      suggestedAddressParts,
      suggestedLocation,
    } = this.state
    if (!suggestedAddress || !suggestedAddressParts || !suggestedLocation)
      return
    this.setState(
      {
        suggestedAddress: null,
        suggestedAddressParts: null,
        suggestedLocation: null,
        address: suggestedAddress,
        addressParts: suggestedAddressParts,
        currentLocation: suggestedLocation,
        lastLocationSubmitted: suggestedLocation,
        lastAddressSubmitted: suggestedAddress,
      },
      () => {
        this.selectAddress(
          suggestedAddress,
          suggestedLocation,
          suggestedAddressParts
        )
      }
    )
    analytics.trackLocationSuggestionAccepted()
  }

  dismissSuggestedAddress = () => {
    this.setState({
      suggestedAddress: null,
      suggestedAddressParts: null,
      suggestedLocation: null,
    })
    analytics.trackLocationSuggestionDismissed()
  }

  async componentDidMount() {
    this.maybeSetSessionToken()

    // Geocode the defaultValue if there's no defaultLocation provided
    // TODO(jared) this part can be removed once we roll out the expanded state, since we will
    // be rendering an expanded state based on a fully geocoded EventLocation instead of having
    // to prefill and fetch like we're doing here
    if (this.props.defaultValue && !this.props.defaultLocation) {
      await this.handleSelect(this.props.defaultValue)
    }
    // Focus the input, if specified in props
    if (this.props.focusOnMount && this.inputRef.current) {
      this.inputRef.current.focus()
    }
  }

  // TODO(mime): use useGoogleMaps here when this class is converted.
  maybeSetSessionToken(retry = 0) {
    if (this.props.useSessionToken) {
      if (window.google && window.google.maps) {
        this.setState({
          sessionToken: new window.google.maps.places.AutocompleteSessionToken(),
        })
      } else if (retry < 10) {
        // Retry once a second for 10 seconds, waiting for GMaps lib to load.
        setTimeout(() => this.maybeSetSessionToken(retry + 1), 1000)
      }
    }
  }

  render() {
    const {
      errorMessage,
      showLocationFields,
      buttonClassName,
      onSubmit,
      searchInputLabel,
      isVirtual,
      isPetition,
      isVirtualFlexible,
      isDonationCampaign,
      isGroupEvent,
      required,
      onFieldChange,
      event,
      organization,
    } = this.props
    const {
      address,
      autocompleteDisabled,
      currentLocation,
      fetchingLocation,
      geocodingInProgress,
      getLocationError,
      sessionToken,
      suggestedAddress,
    } = this.state
    const placeholder = fetchingLocation
      ? 'Fetching location…'
      : getLocationError
      ? 'Enter a location'
      : this.props.placeholder
    const value = fetchingLocation ? '' : address
    const disabled =
      fetchingLocation || this.props.disabled || autocompleteDisabled
    const stateOrTerritoryValue =
      maybeGetStateOrTerritoryFromLocation(currentLocation) || null
    const expandLocationParts = !!showLocationFields && !!currentLocation
    const showPlacesAutocompleteInput = !expandLocationParts

    const commonInputProps = {
      type: 'text',
      loading: geocodingInProgress,
      onBlur: this.handleLocationInputFieldChange,
      onChange: this.onLocationFieldChange,
      onKeyDown: this.handleLocationInputFieldKeyDown,
    }

    return (
      <>
        {showPlacesAutocompleteInput && (
          <FormItem
            label={searchInputLabel}
            forceFormItem
            hint={
              <>
                {isVirtual && !isPetition && (
                  <div>
                    Private{' '}
                    {isDonationCampaign
                      ? 'fundraisers'
                      : isGroupEvent
                      ? 'groups'
                      : isVirtualFlexible
                      ? 'actions'
                      : 'events'}{' '}
                    are hidden from the public.
                  </div>
                )}
              </>
            }
          >
            <PlacesAutocomplete
              value={value}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              searchOptions={{sessionToken}}
              googleCallbackName="placesAutocompleteCb"
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <LocationSearchInputWrapper>
                  <LocationSearchInputContainer data-testid="LocationSearchInput">
                    <Input
                      {...getInputProps({
                        icon: 'search',
                        placeholder,
                        disabled,
                        required,
                        loading: loading || fetchingLocation,
                        onBlur: this.handleBlur,
                        ref: this.inputRef,
                        onKeyDown: (e) => {
                          // this appears to be the only way to mimic the default
                          // enter-to-submit behavior you'd expect from a form
                          // (something inside the component is probably calling
                          // preventDefault). workaround from
                          // https://github.com/hibiken/react-places-autocomplete/issues/208
                          if (
                            typeof onSubmit === 'function' &&
                            !suggestions.length &&
                            e.keyCode === ENTER_KEY_CODE
                          ) {
                            // No need to regenerate the session token because a get place details
                            // request isn't made here
                            onSubmit()
                          }
                        },
                      })}
                    />
                    <LocationResults>
                      {suggestions.map((suggestion) => {
                        return (
                          <LocationSearchItem
                            active={suggestion.active}
                            loading={loading}
                            {...getSuggestionItemProps(suggestion)}
                          >
                            <span>{suggestion.description}</span>
                          </LocationSearchItem>
                        )
                      })}
                      {suggestions.length > 0 && (
                        <LocationSearchItem
                          loading={loading}
                          active={false}
                          style={{cursor: 'default'}}
                        >
                          <img
                            style={{width: '144px', height: '18px'}}
                            src="/static/powered_by_google_on_white_hdpi.png"
                            alt="Powered by Google"
                          />
                        </LocationSearchItem>
                      )}
                    </LocationResults>
                  </LocationSearchInputContainer>
                  {this.props.showGetLocation && (
                    <Button
                      type="button"
                      disabled={getLocationError || disabled}
                      link
                      noUnderline
                      noBackground
                      className={buttonClassName}
                      iconFontSize="20px"
                      onClick={this.handleGetLocation}
                      icon="location-arrow"
                      aria-label="Use my location"
                      style={{height: '32px', marginTop: '2px'}}
                    />
                  )}
                </LocationSearchInputWrapper>
              )}
            </PlacesAutocomplete>
          </FormItem>
        )}
        {expandLocationParts && suggestedAddress && (
          <MessageWrapper fadeIn>
            <Message type="warning">
              <AddressSuggestionContent>
                <Typography variant="body2" component="div">
                  <div>Suggested address:</div>
                  <SuggestedAddressText>
                    {suggestedAddress}
                  </SuggestedAddressText>
                </Typography>
                <AddressSuggestionOptionsWrapper>
                  <InputGroup>
                    <Button type="button" onClick={this.acceptSuggestedAddress}>
                      Accept
                    </Button>
                    <Button
                      type="button"
                      secondary
                      onClick={this.dismissSuggestedAddress}
                    >
                      Dismiss
                    </Button>
                  </InputGroup>
                </AddressSuggestionOptionsWrapper>
              </AddressSuggestionContent>
            </Message>
          </MessageWrapper>
        )}
        {errorMessage && (
          <MessageWrapper>
            <Message type={MessageType.ERROR}>{errorMessage}</Message>
          </MessageWrapper>
        )}
        {expandLocationParts && (
          <>
            <Input
              name="name"
              label="Location name (optional)"
              value={currentLocation ? currentLocation.name : ''}
              {...commonInputProps}
            />
            <Input
              name="address_line1"
              label={
                this.props.isVirtual
                  ? 'Street address (optional)'
                  : 'Street address'
              }
              hint="Provide a complete address or intersection (e.g. “1234 Main St.” or “Grove St. and Pine St.”)"
              value={currentLocation ? currentLocation.address_line1 : ''}
              {...commonInputProps}
            />
            <Input
              name="address_line2"
              placeholder="e.g. Floor, Apt., Suite"
              label="Address line 2 (optional)"
              value={currentLocation ? currentLocation.address_line2 : ''}
              {...commonInputProps}
            />
            <InputGroup>
              <Input
                name="city"
                label="City"
                value={currentLocation ? currentLocation.city : ''}
                {...commonInputProps}
              />
              <Autocomplete
                name="state"
                label="State/Territory"
                options={STATES_AND_TERRITORY_OPTIONS}
                onChange={(e, data) => {
                  this.onSelectStateOrTerritory(e, data)
                }}
                value={stateOrTerritoryValue}
              />
              <Input
                name="zipcode"
                label="ZIP Code"
                value={currentLocation ? currentLocation.zipcode : ''}
                {...commonInputProps}
              />
            </InputGroup>
            <Button
              icon="repeat"
              iconPosition="left"
              link
              onClick={this.clearLocation}
              padding="none"
              type="button"
            >
              Reset location search
            </Button>

            {/* todo (hannah): remove org flag once statewide carousels are live */}
            {event && orgFlagIsActive(organization, 'enable_statewide_events') && (
              <ClippyHint
                hintCopy={
                  <>
                    <span>
                      Statewide events must have a zipcode, but it will not be
                      displayed to users in the feed. In-state users will see
                      the event in a designated 'statewide' section of their
                      feed.
                    </span>
                    <BlockLink>
                      <Link
                        to="http://help.mobilize.us/en/articles/6573898-statewide-events"
                        target="_blank"
                      >
                        Learn more about statewide events
                      </Link>
                    </BlockLink>
                  </>
                }
                narrow={false}
                showHint={!!event?.is_statewide}
              >
                <CheckboxContainer>
                  <CheckboxField
                    checked={!!event?.is_statewide}
                    name="is_statewide"
                    hint={
                      <>
                        <F defaultMessage="This event will be surfaced in a designated statewide section of your feed to all supporters located in the state specified above." />
                      </>
                    }
                    label={
                      <F
                        defaultMessage="This is a statewide event. <a>Learn more</a>"
                        values={{
                          a: (msg) => (
                            <Link
                              to="http://help.mobilize.us/en/articles/6573898-statewide-events"
                              target="_blank"
                            >
                              {msg}
                            </Link>
                          ),
                        }}
                      />
                    }
                    onChange={() => {
                      onFieldChange &&
                        onFieldChange(null, {
                          name: 'is_statewide',
                          value: !event?.is_statewide,
                        })
                    }}
                    type="switch"
                  />
                </CheckboxContainer>
              </ClippyHint>
            )}
          </>
        )}
      </>
    )
  }
}

// const mapState = () => ({})

export default LocationSearchInput
