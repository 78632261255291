import {maybeStringToMaybeInt} from './string'
import underscore from 'underscore'

export {capitalize, cloneDeep, get, pickBy, shuffle, mergeWith} from 'lodash'

export const compose = underscore.compose
export const debounce = underscore.debounce
export const defaults = underscore.defaults
export const defer = underscore.defer
export const difference = underscore.difference
export const flatten = underscore.flatten
export const groupBy = underscore.groupBy
export const isEmpty = underscore.isEmpty
export const isEqual = underscore.isEqual
export const isMatch = underscore.isMatch
export const isNumber = underscore.isNumber
export const last = underscore.last
export const mapObject = underscore.mapObject
export const memoize = underscore.memoize
export const omit = underscore.omit
export const partial = underscore.partial
export const pick = underscore.pick
export const range = underscore.range
export const sample = underscore.sample
export const sortBy = underscore.sortBy
export const times = underscore.times
// Passing the underscore object to `partial` indicates that it's an argument that should be sent
// at call-time
export const partialPlaceholder = underscore

export function getLast(arr) {
  return arr[arr.length - 1]
}

export function classnames(...maybeClassnames) {
  return maybeClassnames.filter((maybeClassname) => !!maybeClassname).join(' ')
}

// Basically just a workaround for Flow not understanding Array.prototype.filter
export function filterFalsy(arr) {
  return arr.filter(Boolean)
}

export function filterFalsyValues(obj) {
  return mapObject(obj, (val) => val || undefined)
}

export function dedupe(arr, fn) {
  const seen = new Set()
  return arr.filter((el) => {
    const test = fn ? fn(el) : el
    if (seen.has(test)) return false
    seen.add(test)
    return true
  })
}

export function getIds(objs) {
  // Given a list of objects with ids, lift out their ids and return that as an array.
  return objs.map((obj) => obj.id)
}

export function flattenToIntList(objs) {
  return flatten(objs).map(maybeStringToMaybeInt).filter(Boolean)
}

export function assert(val, message = 'Assertion failed') {
  if (!val) {
    throw new Error(message)
  }
  return val
}

export function logError(e, context) {
  // Log and report an exception to Sentry. Could potentially add other destinations here
  console.error(e, context)
  if (window.Sentry && window.Sentry.captureException) {
    window.Sentry.captureException(e, (scope) => {
      scope.clear()
      if (context) {
        for (const key in context) {
          scope.setExtra(key, context[key])
        }
      }
    })
  }
}

export function prefixKeys(obj, prefix) {
  /*
  This function takes an object and a string and returns a new object
  where all the keys start with the input string
  */
  return Object.entries(obj).reduce(
    (alreadyPrefixed, [key, value]) => ({
      ...alreadyPrefixed,
      [`${prefix}${key}`]: value,
    }),
    {}
  )
}

export function normalizeQueryParamValueToStringList(value) {
  if (!value) {
    return []
  }
  return flatten([value])
}
