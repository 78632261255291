import {Autocomplete} from 'components'

export default function OrganizationMultiselect({
  selectedOrgs,
  shownOrgs,
  onChange,
  sectionLabel,
}) {
  const shownOrgDropdownOptions = []
  const shownOrgDropdownDefaults = []
  const selectedOrgsSet = new Set(selectedOrgs)

  Array.from(shownOrgs.entries()).forEach(([id, name]) => {
    shownOrgDropdownOptions.push({key: id, text: name, value: id})
    if (selectedOrgsSet.has(id)) {
      shownOrgDropdownDefaults.push(id)
    }
  })
  shownOrgDropdownOptions.sort((a, b) => a.text.localeCompare(b.text))

  return (
    <Autocomplete
      sectionLabel={sectionLabel}
      placeholder={!shownOrgDropdownDefaults.length ? 'Any' : undefined}
      options={shownOrgDropdownOptions}
      multiple
      onChange={(evt, {value: selected}) => {
        onChange(selected)
      }}
      value={shownOrgDropdownDefaults}
    />
  )
}
