import {PostSignupAsk, getVirtualActionOptionPredefinedMessage} from 'app/enums'
import {createIntl, defineMessages} from 'util/i18n'
import {
  getButtonTextForSocialShareType,
  inferSelectedSocialOption,
} from 'dashboard/events/create/VirtualActionSocialShareSettings'
import {getEventTypeAffinityChecks, isAdvocacyEvent} from 'util/event'

import CurrentUserContext from 'app/CurrentUserContext'
import {filterOutAtCapacityTimeslots} from 'util/timeslot'
import {formatTimeslot} from 'util/timeslot'
import {getAvailableTimeslots} from 'events/details/util'
import {inferSelectedAction} from 'dashboard/events/create/VirtualActionSettings'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useContext} from 'react'

export const Container = styled.div`
  margin-bottom: ${styles.space.m};
`

export const getPrimaryButtonProps = (
  signupInFlightOrCreated,
  isGroupAndAlreadyJoined
) => ({
  type: 'submit',
  fluid: true,
  large: true,
  disabled: signupInFlightOrCreated || isGroupAndAlreadyJoined,
})

export const getActNowButtonProps = (
  event,
  signupCreatedWithinLastFiveSeconds,
  signupInFlightOrCreated,
  isGroupAndAlreadyJoined,
  onShiftsChange
) => {
  const handleActNowClick = async (e) => {
    const ts = getActiveTimeslot(event)
    if (!ts) {
      // TODO(jared) handle error, this is likely b/c the user had the page open
      // for a while and now the timeslot is no longer active
      return
    }
    if (onShiftsChange) {
      onShiftsChange([
        {
          timeslot_id: ts.id,
          start: moment().toISOString(),
          is_act_now_signup: true,
        },
      ])
    }
  }

  const primaryButtonProps = getPrimaryButtonProps(
    signupInFlightOrCreated,
    isGroupAndAlreadyJoined
  )
  return {
    ...primaryButtonProps,
    onClick: handleActNowClick,
    icon: isAdvocacyEvent(event)
      ? 'phone'
      : isGroupAndAlreadyJoined
      ? 'check'
      : null,
    iconPosition: 'left',
    loading: isAdvocacyEvent(event) && signupCreatedWithinLastFiveSeconds,
  }
}

export const getVirtualActionText = (
  event,
  signupCreatedWithinLastFiveSeconds,
  signupInFlightOrCreated,
  isGroupAndAlreadyJoined
) => {
  const intl = createIntl()

  const advocacyButtonText = intl.formatMessage(
    signupCtaMessages[
      signupInFlightOrCreated
        ? signupCreatedWithinLastFiveSeconds
          ? 'callPhone'
          : 'called'
        : 'callNow'
    ]
  )

  const {
    isPetition,
    isAdvocacy,
    isDonationCampaign,
    isGroup,
  } = getEventTypeAffinityChecks(event)

  if (isPetition) {
    return intl.formatMessage(
      signupCtaMessages[
        signupInFlightOrCreated ? 'thanksForSigning' : 'signPetition'
      ]
    )
  }

  if (isDonationCampaign) {
    return intl.formatMessage(
      signupCtaMessages[
        signupInFlightOrCreated ? 'thanksForSupporting' : 'donate'
      ]
    )
  }

  if (isGroup) {
    return intl.formatMessage(
      signupCtaMessages[
        isGroupAndAlreadyJoined
          ? 'joined'
          : signupInFlightOrCreated
          ? 'thanksForJoining'
          : 'joinGroup'
      ]
    )
  }

  let virtualActionText = null
  if (event.virtual_action_button_text) {
    const virtualActionOption = inferSelectedAction({
      url: event.virtual_action_url,
      buttonText: event.virtual_action_button_text,
    })
    const predefinedValue = getVirtualActionOptionPredefinedMessage(
      virtualActionOption
    )
    if (predefinedValue) {
      virtualActionText = intl.formatMessage(predefinedValue)
    } else {
      const socialOption = inferSelectedSocialOption(event.virtual_action_url)
      const socialButtonText = getButtonTextForSocialShareType(socialOption)
      virtualActionText = socialButtonText || event.virtual_action_button_text
    }
  }

  return isAdvocacy
    ? advocacyButtonText
    : signupInFlightOrCreated
    ? intl.formatMessage(signupCtaMessages['thanksForAction'])
    : virtualActionText || intl.formatMessage(signupCtaMessages['actNow'])
}

const getStickyPostSignupMessage = (event, shifts) => {
  const {
    isPetition,
    isCommitmentGathering,
    isDonationCampaign,
  } = getEventTypeAffinityChecks(event)

  if (isPetition) {
    return signupCtaMessages['signedPetition']
  }

  if (isCommitmentGathering) {
    return signupCtaMessages['thankYouForAction']
  }

  if (isDonationCampaign) {
    return signupCtaMessages['thanksForSupport']
  }

  if (event.is_virtual_flexible) {
    if (getActiveTimeslot(event)) {
      return signupCtaMessages['thankYouForAction']
    }
    return signupCtaMessages['scheduledForAction']
  }

  if (!shifts) {
    // NB(jo): we generally shouldn't get here; handling this case
    // primarily to appease Flow
    return signupCtaMessages['signedUp']
  }

  return signupCtaMessages['signedUpForTimeslots']
}

export const getStickyPostSignupText = (event, shifts) => {
  const intl = createIntl()
  const message = getStickyPostSignupMessage(event, shifts)

  return intl.formatMessage(message, {shiftsCount: shifts?.length})
}

export function shouldShowActNowButton(event, expandActLater) {
  // For registration only events, "act later" will never be an option
  const {isRegistrationOnly} = getEventTypeAffinityChecks(event)
  return (
    event.is_virtual_flexible &&
    (isRegistrationOnly || (!!getActiveTimeslot(event) && !expandActLater))
  )
}

export function shouldShowActLaterButton(event, expandActLater) {
  const availableTimeslots = getAvailableTimeslots(event)
  const now = moment()
  const hasLaterTimeslots = availableTimeslots.some(({start}) =>
    moment(start).isAfter(now)
  )
  const showActNow = shouldShowActNowButton(event, expandActLater)
  const isLiveAdvocacy = showActNow && isAdvocacyEvent(event)

  const advanceSignupsEnabled =
    !event.virtual_action_disable_advance_signups || !showActNow

  return (
    event.is_virtual_flexible &&
    advanceSignupsEnabled &&
    !expandActLater &&
    hasLaterTimeslots &&
    !isLiveAdvocacy
  )
}

export function getActiveTimeslot(event) {
  const availableTimeslots = getAvailableTimeslots(event)
  const now = moment()
  if (
    availableTimeslots.length > 0 &&
    moment(availableTimeslots[0].start).isBefore(now) &&
    moment(availableTimeslots[0].end).isAfter(now)
  ) {
    return availableTimeslots[0]
  }
  return null
}

export function shouldPrecheckTwoTimeslots(
  maybePrioritizedTimeslot,
  organization,
  event
) {
  const availableTimeslots = filterOutAtCapacityTimeslots(event.times)
  return (
    !maybePrioritizedTimeslot && // Only on non-prioritized shift signup pages
    availableTimeslots.length > 1 && // we need two timeslots to precheck two
    !event.is_virtual_flexible && // no virtual events
    event.post_signup_asks.includes(PostSignupAsk.SHIFT_SUGGESTIONS) && // event owner hasn't disabled shift suggestions
    !!organization.precheck_two_timeslots // the branding org wants this to happen generally
  )
}

const signupCtaMessages = defineMessages({
  signUp: {defaultMessage: 'Sign up'},
  signUpAs: {defaultMessage: 'Sign up as {firstName}'},
  signedUp: {defaultMessage: 'Signed up'},
  signedUpAs: {defaultMessage: 'Signed up as {firstName}'},
  attend: {defaultMessage: 'Attend'},
  attendAs: {defaultMessage: 'Attend as {firstName}'},
  attending: {defaultMessage: 'Attending'},
  attendingAs: {defaultMessage: 'Attending as {firstName}'},
  signAs: {defaultMessage: 'Sign as {firstName}'},
  signedAs: {defaultMessage: 'Signed as {firstName}'},
  joinGroup: {defaultMessage: 'Join this group'},
  joinGroupAs: {defaultMessage: 'Join group as {firstName}'},
  joined: {defaultMessage: 'Joined'},
  joinedGroupAs: {defaultMessage: 'Joined group as {firstName}'},
  checkIn: {defaultMessage: 'Check in'},
  checkInAs: {defaultMessage: 'Check in as {firstName}'},
  checkedIn: {defaultMessage: 'Checked in'},
  checkedInAs: {defaultMessage: 'Checked in as {firstName}'},
  thanksForAttending: {defaultMessage: 'Thanks for attending!'},
  callPhone: {defaultMessage: 'Calling Your Phone'},
  called: {defaultMessage: 'Called'},
  callNow: {defaultMessage: 'Call Me Now'},
  thanksForSigning: {defaultMessage: 'Thank you for signing!'},
  signPetition: {defaultMessage: 'Sign this petition'},
  thanksForSupporting: {defaultMessage: 'Thank you for your support!'},
  donate: {defaultMessage: 'Donate'},
  thanksForJoining: {defaultMessage: 'Thank you for joining!'},
  thanksForAction: {defaultMessage: 'Thanks for taking action!'},
  actNow: {defaultMessage: 'Act now'},
  signedPetition: {defaultMessage: 'You signed this petition'},
  thankYouForAction: {defaultMessage: 'Thank you for taking action'},
  thanksForSupport: {defaultMessage: 'Thank you for your support'},
  scheduledForAction: {defaultMessage: "You're scheduled to take action"},
  signedUpForTimeslots: {
    defaultMessage:
      'Signed up for {shiftsCount, plural, one {# timeslot} other {# timeslots}}',
  },
})

function determineSignupButtonMessage({
  firstName,
  pastTense,
  isPetition,
  isGroup,
  isVirtualFlexible,
  isSelfCheckIn,
  isFeed,
}) {
  if (isPetition) {
    return signupCtaMessages[pastTense ? 'signedAs' : 'signAs']
  } else if (isGroup) {
    return signupCtaMessages[pastTense ? 'joinedGroupAs' : 'joinGroupAs']
  } else if (isSelfCheckIn) {
    if (firstName) {
      return signupCtaMessages[pastTense ? 'checkedInAs' : 'checkInAs']
    }
    return signupCtaMessages[pastTense ? 'checkedIn' : 'checkIn']
  } else if (isVirtualFlexible) {
    if (firstName) {
      return signupCtaMessages[pastTense ? 'signedUpAs' : 'signUpAs']
    }
    return signupCtaMessages[pastTense ? 'signedUp' : 'signUp']
  } else if (!pastTense) {
    return signupCtaMessages[firstName ? 'attendAs' : 'attend']
  } else if (pastTense && isFeed) {
    return signupCtaMessages[firstName ? 'attendingAs' : 'attending']
  } else if (pastTense && !isFeed) {
    return signupCtaMessages.thanksForAttending
  }
  // fall-through safeguards; not intended for use
  return signupCtaMessages[pastTense ? 'attending' : 'attend']
}
export const useSignupButtonLabel = ({
  event,
  isFeed,
  signedUp,
  signupIdentityFields: {firstName: firstNameFromIDFields},
  timeslot,
  isSelfCheckIn,
}) => {
  const {user: userFromContext} = useContext(CurrentUserContext)
  const firstName =
    firstNameFromIDFields ||
    (userFromContext && userFromContext.first_name
      ? userFromContext.first_name
      : '')

  const intl = createIntl()
  if (timeslot) {
    return formatTimeslot(timeslot, event.timezone)
  }

  const {isPetition, isGroup} = getEventTypeAffinityChecks(event)
  const message = determineSignupButtonMessage({
    firstName,
    pastTense: signedUp,
    isPetition,
    isGroup,
    isVirtualFlexible: event.is_virtual_flexible,
    isSelfCheckIn,
    isFeed,
  })
  return intl.formatMessage(message, {firstName})
}
