// NOTE: if you update this file, you need to run `make generatestyles`

// Space units, intended to match Bootstrap's rhythm
export const space = {
  xs: '0.25rem',
  s: '0.5rem',
  m: '1rem',
  l: '1.5rem',
  xl: '3rem',

  // Annoyingly this is not in terms of our space units, probably worth making it consistent
  input: '0.75rem',

  // An odd duck in this list, but meh.
  borderRadius: '0.25rem',
}

const mobileModalMarginPercentage = 2.5
export const limits = {
  // breakpointLarge Corresponds with the Semantic-UI small monitor breakpoint of 992px
  // https://semantic-ui.com/elements/container.html
  breakpointLarge: 992,
  breakpointSmall: 576,

  maxWidth: '1000px',
  dashboardMaxWidth: '1440px',
  dashboardNavbarWidth: '264px', // A bit arbitrary, but needed in multiple places
  imageMaxWidth: 944,

  // Percentages for modals on mobile screens
  mobileModalMarginPercentage,
  mobileModalHeightPercentage: 100 - mobileModalMarginPercentage,
  mobileModalWidthPercentage: 100 - 2 * mobileModalMarginPercentage,
}

// The order of these defines which one 'wins' to be on top of the other.
// This simplifies guessing a number and just assigns number in sequential order.
// (excepting belowPage, page, abovePage, and important which are special z-indices)
// TODO(mime): the custom indices are set to 0 to satisify Flow, sigh.
const zIndices = {
  // Special cases
  belowPage: -1,
  page: 0,
  abovePage: 1,

  // Register custom z-indices here:
  popup: 0,
  abovePageShadow: 0,
  loading: 0,
  navigation: 0,
  datePickers: 0,
  notificationContainer: 0,

  // At same level as Material-UI modal, https://material-ui.com/customization/z-index/
  // TODO(mime): we can configure Material z-indices instead of us
  // working with them.
  modalOverlay: 1300,

  // Special case
  important: 2147483647,
}
Object.keys(zIndices).forEach((item, index) => {
  if (zIndices[item] === 0 && item !== 'page') {
    zIndices[item] = index - zIndices['abovePage']
  }
})

export const zindex = zIndices
