import Loader from './Loader'
import ReactDOM from 'react-dom'
import styles from './styles'

// TODO(mime): this should just manage its own div's
// and not rely on 'mbz-portal' in index.html.
const LoaderWithPortal = ({active}) => {
  if (!active) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        top: '50vh',
        left: '50vw',
        zIndex: styles.zindex.loading,
        backgroundColor: styles.colors.white,
        boxShadow: `0 0 0px 10px ${styles.colors.white}`,
        borderRadius: '50%',
        pointerEvents: 'none',
      }}
    >
      <Loader large />
    </div>,
    document.getElementById('mbz-portal')
  )
}
export default LoaderWithPortal
