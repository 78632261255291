import styles, {
  getPrimaryFontSizeFromProps,
  getPrimaryTextColorFromProps,
  getPrimaryTitleFontFromProps,
  getSecondaryBodyFontFromProps,
  getTitleTextTransformFromProps,
} from './styles'

import {css} from '@emotion/react'
// TODO(mime): This used by the `css=` prop below on the component.
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx} from '@emotion/react'
import styled from '@emotion/styled/macro'
import typographyStyles from './styles/typography'

const variantMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4', // Form label
  body1: 'p', // Body
  body2: 'p', // Help text
  subtitle1: 'span', // Small text
}

// Keep in sync with typography.js
const FONT_SIZE_MAPPING = {
  h1: styles.typography.fontSizeXL,
  h2: styles.typography.fontSizeL,
  h3: styles.typography.fontSizeM,
  h4: styles.typography.fontSizeBase,
  body1: styles.typography.fontSizeBase,
  body2: styles.typography.fontSizeS,
  subtitle1: styles.typography.fontSizeXS,
}

function isPrimary(variant) {
  return ['h1', 'h2', 'h3'].includes(variant)
}

const BODY_VARIANTS = new Set(['body1', 'body2'])
const maybeGetOverflowWrap = (props) =>
  BODY_VARIANTS.has(props.variant) && 'overflow-wrap: anywhere;'

const maybeGetUppercase = (props) =>
  props.uppercase && 'text-transform: uppercase;'

const ThemedWrapper = styled.span`
  ${(props) => isPrimary(props.variant) && getPrimaryTitleFontFromProps(props)};
  ${(props) =>
    !isPrimary(props.variant) && getSecondaryBodyFontFromProps(props)};
  ${(props) =>
    isPrimary(props.variant) && getTitleTextTransformFromProps(props)};
  ${(props) =>
    props.color
      ? `color: ${props.color}`
      : getPrimaryTextColorFromProps(props)};
  ${(props) =>
    isPrimary(props.variant) &&
    getPrimaryFontSizeFromProps(
      props,
      FONT_SIZE_MAPPING[props.variant || 'h1']
    )};
  ${maybeGetUppercase};
  ${maybeGetOverflowWrap};
`

const NonThemedWrapper = styled.span`
  ${(props) => props.color && `color: ${props.color}`};
  ${maybeGetUppercase};
  ${maybeGetOverflowWrap};
`

// This is cribbed pretty much directly from MaterialUI
// https://material-ui.com/components/typography/
const Typography = ({
  center,
  children,
  color,
  component,
  variant,
  uppercase,
  ...props
}) => {
  const componentCss = css`
    ${typographyStyles[variant]};
    // This needs to go at the top-level component because putting it on the (display: inline) child
    // span element does nothing
    ${center && 'text-align: center;'}
  `
  const Component = component || variantMap[variant]
  const Wrapper = ['body2', 'subtitle1'].includes(variant)
    ? NonThemedWrapper
    : ThemedWrapper

  return (
    <Component {...props} css={componentCss}>
      <Wrapper
        center={center}
        className="mbz-typography-inner-wrapper"
        color={color}
        variant={variant}
        uppercase={uppercase}
      >
        {children}
      </Wrapper>
    </Component>
  )
}

export default Typography
