import {defineMessages} from 'util/i18n'
import {getOrgCreationUrl} from 'util/routing'

const orgCreationMessages = defineMessages({
  trialAccount: {
    defaultMessage: 'Create a trial account',
  },
  demo: {
    defaultMessage: 'Get a demo',
  },
  learnMore: {
    defaultMessage: 'Learn more',
  },
})

const orgCreationLinks = [
  {
    label: orgCreationMessages.trialAccount,
    url: getOrgCreationUrl({
      utm_source: 'mobilize',
      utm_medium: 'referral',
      utm_campaign: 'bigfooter1',
    }),
  },
  {
    label: orgCreationMessages.demo,
    url: getOrgCreationUrl({
      utm_source: 'mobilize',
      utm_medium: 'referral',
      utm_campaign: 'bigfooter2',
    }),
  },
  {
    label: orgCreationMessages.learnMore,
    url: getOrgCreationUrl({
      utm_source: 'mobilize',
      utm_medium: 'referral',
      utm_campaign: 'bigfooter3',
    }),
  },
]

export const useOrgCreationLinks = () => {
  return orgCreationLinks
}
