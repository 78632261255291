import {Button, Divider, Typography} from 'components'
import CustomFields, {
  customFieldsValueMapper,
} from 'events/details/SignupForm/CustomFields'
import {
  formatIdentityFieldsForSignupRequest,
  getIdentityFieldsFromFormValues,
} from 'util/user'
import {useContext, useState} from 'react'

import {CopyToClipboard} from 'react-copy-to-clipboard'
import {EMPTY_STREET_ADDRESS_FIELDS_FOR_SIGNUP_REQUEST} from 'app/constants'
import {F} from 'util/i18n'
import Form from 'components/Form'
import TrackingParamsContext from 'app/TrackingParamsContext'
import UserSignupFormFields from 'events/components/UserSignupFormFields'
import analytics from 'analytics'
import api from 'data/api'
import {eventTypeToDisplayName} from 'app/enums'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {trackShareInitiated} from 'util/share'
import {useSnackbar} from 'notistack'

const ContentWrapper = styled.div`
  text-align: center;
  margin-bottom: ${styles.space.xl};
`

const HeadingWrapper = styled.div`
  margin-bottom: ${styles.space.l};
`

const SubheadingWrapper = styled.div`
  margin-top: ${styles.space.m};
`

const ButtonWrapper = styled.div`
  margin-bottom: ${styles.space.s};
`

const CustomFieldsWrapper = styled.div`
  text-align: left;
`

const GroupMemberHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${styles.space.m};
`

const DividerWrapper = styled.div`
  margin-top: ${styles.space.m};
  margin-bottom: ${styles.space.m};
`

function trackRegistrationCreated(signupRequest, currentOrganization, event) {
  analytics.trackRegistrationCreated({
    ...signupRequest,
    ...analytics.maybeGetOrgProperties(currentOrganization),
    virtual: event.is_virtual,
    virtualWhen: null,
    referringVol: null,
    created_by_distributed_organizing: event.created_by_distributed_organizing,
    eventType: eventTypeToDisplayName(event.event_type, event.is_virtual),
    event_host_type: null,
    numShifts: 1,
    prechecked_two_timeslots: null,
    referrer_share_context: null,
    referrer_share_medium: null,
    signup_source: 'group_signup_modal',
  })
}

export default function AddGroupMembers({
  groupShareLink,
  event,
  organization,
  isPostSignup,
  modalType,
  timeslotId,
  referringUserId,
  referringParticipationId,
  referringDataSignature,
  numUnclaimedGroupSpots,
  onRequestClose,
}) {
  const snackbar = useSnackbar()
  const {trackingParams} = useContext(TrackingParamsContext)
  const [copied, setCopied] = useState(false)
  const [groupMembersAdded, setGroupMembersAdded] = useState(false)

  const [fieldValues, setFieldValues] = useState([
    {userFields: {}, customFields: customFieldsValueMapper(event)},
  ])

  function handleCopy() {
    snackbar.enqueueSnackbar(<F defaultMessage="Copied!" />, {
      variant: 'success',
    })
    setCopied(true)
    trackShareInitiated('group_share_link', {
      share_context: modalType,
    })
  }

  const onAddGroupMember = async () => {
    if (groupMembersAdded) {
      return
    }
    setGroupMembersAdded(true)

    const signupRequest = {
      signups: fieldValues.map((values) => ({
        affiliation_id: organization.id,
        shifts: [{timeslot_id: timeslotId}],
        referring_user_id: referringUserId,
        referring_participation_id: referringParticipationId,
        referring_data_signature: referringDataSignature,
        sharer_role: null,
        smsOptIn: false,
        createdFromDashboard: false,
        is_promoted: !event.current_org_is_owner_or_co_owner,
        event_suggestion_context: null,
        ...formatIdentityFieldsForSignupRequest(
          getIdentityFieldsFromFormValues(values.userFields)
        ),
        custom_signup_fields: values.customFields,
        ...EMPTY_STREET_ADDRESS_FIELDS_FOR_SIGNUP_REQUEST,
        ...trackingParams,
      })),
    }
    try {
      // for now, we are not using the response
      await api.addGroupMemberSignupByEventId(event.id, signupRequest)
    } catch (e) {
      // TODO: eventually we should handle error
      snackbar.enqueueSnackbar('We failed to process your additional signups', {
        variant: 'error',
      })
    }
    for (const request of signupRequest.signups) {
      trackRegistrationCreated(request, organization, event)
    }
    if (onRequestClose) {
      onRequestClose()
    }
  }

  return (
    <ContentWrapper>
      <HeadingWrapper>
        <Typography center variant="h1">
          {isPostSignup ? (
            <F defaultMessage="Your group is signed up! Add or invite group members so they receive instructions." />
          ) : (
            <F defaultMessage="Add or invite group members so they receive instructions." />
          )}
        </Typography>
        <SubheadingWrapper>
          <Typography center variant="body1">
            <F
              defaultMessage="{orgName} knows to expect your group at this event, and we've emailed you instructions."
              values={{orgName: organization.name}}
            />
          </Typography>
        </SubheadingWrapper>
      </HeadingWrapper>
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          onAddGroupMember()
        }}
      >
        {fieldValues.map((form, i) => (
          <div key={i}>
            <GroupMemberHeading>
              <Typography
                style={{textAlign: 'left', marginBottom: '0'}}
                variant="h3"
              >
                <F defaultMessage="Group member {num}" values={{num: i + 1}} />
              </Typography>
              {i > 0 && (
                <div>
                  <Button
                    link
                    onClick={() => {
                      setFieldValues(
                        fieldValues.filter((form, num) => i !== num)
                      )
                    }}
                    padding="none"
                    type="button"
                  >
                    <F defaultMessage="Remove" />
                  </Button>
                </div>
              )}
            </GroupMemberHeading>
            <UserSignupFormFields
              errorFields={{}}
              identityFields={getIdentityFieldsFromFormValues(form.userFields)}
              onChange={({name, value}) => {
                const updatedValues = [...fieldValues]
                updatedValues[i] = {
                  customFields: updatedValues[i].customFields,
                  userFields: {...updatedValues[i].userFields, [name]: value},
                }
                setFieldValues(updatedValues)
              }}
              emailLessSubmission
            />
            <CustomFieldsWrapper>
              <CustomFields
                customSignupFieldValues={form.customFields}
                event={event}
                onChange={(updatedCustomFieldValues) => {
                  const updatedValues = [...fieldValues]
                  updatedValues[i] = {
                    userFields: updatedValues[i].userFields,
                    customFields: updatedCustomFieldValues,
                  }
                  setFieldValues(updatedValues)
                }}
                errors={{}}
              />
            </CustomFieldsWrapper>
          </div>
        ))}
        <ButtonWrapper>
          <Button
            onClick={() => {
              setFieldValues(
                [
                  ...fieldValues,
                  {
                    userFields: {},
                    customFields: customFieldsValueMapper(event),
                  },
                ].slice(0, numUnclaimedGroupSpots + 1)
              )
            }}
            icon="plus"
            fluid
            iconPosition="left-inline"
            secondary
            type="button"
            disabled={fieldValues.length >= numUnclaimedGroupSpots}
          >
            <F defaultMessage="Add another group member" />
          </Button>
        </ButtonWrapper>
        <DividerWrapper>
          <Divider />
        </DividerWrapper>
        <ButtonWrapper>
          <Button fluid type="submit" disabled={groupMembersAdded}>
            <F defaultMessage="Save group members" />
          </Button>
        </ButtonWrapper>
      </Form>

      <ButtonWrapper>
        <CopyToClipboard text={groupShareLink} onCopy={handleCopy}>
          <Button icon="link" fluid iconPosition="left-inline" secondary>
            {copied ? (
              <F defaultMessage="Copied" />
            ) : (
              <F defaultMessage="Copy invite link" />
            )}
          </Button>
        </CopyToClipboard>
      </ButtonWrapper>
      <Button link onClick={onRequestClose}>
        <F defaultMessage="I'll do this later" />
      </Button>
      <Typography variant="body2">
        <F defaultMessage="Clicking the button above confirms that group members agree to the Terms of Service and Privacy Policy." />
      </Typography>
    </ContentWrapper>
  )
}
