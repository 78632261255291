import {reduxActions} from 'app/enums'

export function addOrgFeedMembershipAffirmation(org, membershipAffirmedAt) {
  return {
    type: reduxActions.ADD_ORG_FEED_MEMBERSHIP_AFFIRMATION,
    orgId: org.id,
    membershipAffirmedAt: membershipAffirmedAt,
  }
}

export function setFeedUserData(feedUser) {
  return {
    type: reduxActions.SET_FEED_USER_DATA,
    feedUser,
  }
}

export function setLastSeenLoggedInAtToNow() {
  return {type: reduxActions.SET_LAST_LOGGED_IN_AT_TO_NOW}
}

export function setCurrentVolunteer(currentVolunteer) {
  return {type: reduxActions.SET_CURRENT_VOLUNTEER, currentVolunteer}
}

export function setFeedReshiftMethod(method) {
  return {type: reduxActions.SET_FEED_RESHIFT_METHOD, method}
}

export function setFeedFlakeReshiftMethod(method) {
  return {type: reduxActions.SET_FEED_FLAKE_RESHIFT_METHOD, method}
}

export function setEmbedData(data) {
  return {type: reduxActions.LOAD_DATA_FROM_EMBED, data}
}

export function setBuildCommit(buildCommit) {
  return {type: reduxActions.SET_BUILD_COMMIT, data: {buildCommit}}
}

export function setNumTimesViewedEventCreateModal() {
  return {type: reduxActions.SET_NUM_TIMES_VIEWED_EVENT_CREATE_CHAINING}
}

export function setEventSignups(eventId, timeslotIds) {
  return {type: reduxActions.SET_EVENT_SIGNUPS, data: {eventId, timeslotIds}}
}
