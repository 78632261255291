import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {Link} from 'components'
import {getOrganizationDefaultEventCampaignCreateEventUrl} from 'util/routing'
import {useContext} from 'react'

export default function UneditableDescription({contactEmail, mailtoLink}) {
  const orgContext = useContext(CurrentOrganizationContext)
  const org = orgContext?.currentOrganization

  if (!org) return null

  const contactOrg = `Contact ${org?.name}`

  return (
    <>
      {contactEmail ? (
        <Link to={mailtoLink} target="_blank">
          {contactOrg}
        </Link>
      ) : (
        contactOrg
      )}{' '}
      to change this, or{' '}
      <Link to={getOrganizationDefaultEventCampaignCreateEventUrl(org)}>
        create a new event.
      </Link>
    </>
  )
}
