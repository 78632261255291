import {EventType} from 'app/enums'
import {ValuesOf} from 'types'
import {defineMessages} from 'react-intl'

// this file stores (enum) => i18nMessage functions in a react-intl format so
// that we can extract and translate how enums are rendered to the user.

const convertSnakeCaseToCamelCase = (str: string) => str.split('_').reduce((result, word, i) => {
  if (i === 0) {
    return result += word.toLowerCase()
  }
  const chars = word.split('')
  return result += chars[0] + chars.splice(1).join('').toLowerCase()
}, '')

// Keep in sync with EVENT_TYPE_DISPLAY_NAMES in event/enums.py
const eventTypeDisplayMessages = defineMessages({
  canvass: {defaultMessage: 'Canvass'},
  phoneBank: {defaultMessage: 'Phone Bank'},
  textBank: {defaultMessage: 'Text Bank'},
  meeting: {defaultMessage: 'Meeting'},
  community: {defaultMessage: 'Community Event'},
  fundraiser: {defaultMessage: 'Fundraiser'},
  other: {defaultMessage: 'Other'},
  meetGreet: {defaultMessage: 'Meet-and-Greet'},
  houseParty: {defaultMessage: 'House Party'},
  voterReg: {defaultMessage: 'Voter Registration'},
  training: {defaultMessage: 'Training'},
  friendToFriendOutreach: {defaultMessage: 'Friend-to-Friend Outreach'},
  advocacyCall: {defaultMessage: 'Call Your Representative'},
  debateWatchParty: {defaultMessage: 'Watch Party'},
  rally: {defaultMessage: 'Rally'},
  townHall: {defaultMessage: 'Town Hall'},
  officeOpening: {defaultMessage: 'Office Opening'},
  barnstorm: {defaultMessage: 'Barnstorm'},
  solidarityEvent: {defaultMessage: 'Solidarity Event'},
  communityCanvass: {defaultMessage: 'Community Canvass'},
  signatureGathering: {defaultMessage: 'Signature Gathering'},
  carpool: {defaultMessage: 'Carpool'},
  workshop: {defaultMessage: 'Workshop'},
  petition: {defaultMessage: 'Petition'},
  automatedPhoneBank: {defaultMessage: 'Automated Phone Bank'},
  letterWriting: {defaultMessage: 'Letter Writing'},
  literatureDropOff: {defaultMessage: 'Literature Drop-off'},
  visibilityEvent: {defaultMessage: 'Visibility Event'},
  pledge: {defaultMessage: 'Pledge'},
  interestForm: {defaultMessage: 'Interest Form'},
  donationCampaign: {defaultMessage: 'Fundraising Campaign'},
  socialMediaCampaign: {defaultMessage: 'Social Media Campaign'},
  postcardWriting: {defaultMessage: 'Postcard Writing'},
  group: {defaultMessage: 'Group'},
  volunteerShift: {defaultMessage: 'Volunteer Shift'},
  advocacy: {defaultMessage: 'Advocacy Event'},
  relational: {defaultMessage: 'Relational'},
  dataEntry: {defaultMessage: 'Data Entry'},
  pollMonitoring: {defaultMessage: 'Poll Monitoring'},
  hotline: {defaultMessage: 'Hotline'},
  voterProtection: {defaultMessage: 'Voter Protection'},
})

const virtualEventTypeDisplayMessages = defineMessages({
  canvass: {defaultMessage: 'Virtual Canvass'},
  phoneBank: {defaultMessage: 'Virtual Phone Bank'},
  textBank: {defaultMessage: 'Virtual Text Bank'},
  meeting: {defaultMessage: 'Virtual Meeting'},
  community: {defaultMessage: 'Virtual Community Event'},
  fundraiser: {defaultMessage: 'Virtual Fundraiser'},
  other: {defaultMessage: 'Virtual Event'},
  meetGreet: {defaultMessage: 'Virtual Meet-and-Greet'},
  houseParty: {defaultMessage: 'Virtual House Party'},
  voterReg: {defaultMessage: 'Virtual Voter Registration'},
  training: {defaultMessage: 'Virtual Training'},
  friendToFriendOutreach: {defaultMessage: 'Virtual Friend-to-Friend Outreach'},
  advocacyCall: {defaultMessage: 'Virtual Call Your Representative'},
  debateWatchParty: {defaultMessage: 'Virtual Watch Party'},
  rally: {defaultMessage: 'Virtual Rally'},
  townHall: {defaultMessage: 'Virtual Town Hall'},
  officeOpening: {defaultMessage: 'Virtual Office Opening'},
  barnstorm: {defaultMessage: 'Virtual Barnstorm'},
  solidarityEvent: {defaultMessage: 'Virtual Solidarity Event'},
  communityCanvass: {defaultMessage: 'Virtual Community Canvass'},
  signatureGathering: {defaultMessage: 'Virtual Signature Gathering'},
  carpool: {defaultMessage: 'Virtual Carpool'},
  workshop: {defaultMessage: 'Virtual Workshop'},
  petition: {defaultMessage: 'Virtual Petition'},
  automatedPhoneBank: {defaultMessage: 'Virtual Automated Phone Bank'},
  letterWriting: {defaultMessage: 'Virtual Letter Writing'},
  literatureDropOff: {defaultMessage: 'Virtual Literature Drop-off'},
  visibilityEvent: {defaultMessage: 'Virtual Visibility Event'},
  pledge: {defaultMessage: 'Virtual Pledge'},
  interestForm: {defaultMessage: 'Virtual Interest Form'},
  donationCampaign: {defaultMessage: 'Virtual Fundraising Campaign'},
  socialMediaCampaign: {defaultMessage: 'Virtual Social Media Campaign'},
  postcardWriting: {defaultMessage: 'Virtual Postcard Writing'},
  group: {defaultMessage: 'Virtual Group'},
  volunteerShift: {defaultMessage: 'Virtual Volunteer Shift'},
  advocacy: {defaultMessage: 'Virtual Advocacy Event'},
  relational: {defaultMessage: 'Virtual Relational'},
  dataEntry: {defaultMessage: 'Virtual Data Entry'},
  pollMonitoring: {defaultMessage: 'Virtual Poll Monitoring'},
  hotline: {defaultMessage: 'Virtual Hotline'},
  voterProtection: {defaultMessage: 'Virtual Voter Protection'},
})

export function eventTypeToDisplayMessage(
  eventType: ValuesOf<typeof EventType>,
  virtual = false
) {
  const messages = virtual ? virtualEventTypeDisplayMessages : eventTypeDisplayMessages
  const eventTypeEntry = Object.entries(EventType).find(([_, value]) => value === eventType )

  if (!eventTypeEntry) {// only necessary because eventTypeToDisplayMessage is still used in .js files
    return ''
  }

  const eventTypeKey = convertSnakeCaseToCamelCase(eventTypeEntry[0])
  return messages[eventTypeKey]
}
