import {Step, StepLabel, Stepper, Typography} from 'components'

import ContactInformationFields from 'events/details/FundraisingForm/field_groups/ContactInformationFields'
import DonationProgress from './DonationProgress'
import {F} from 'util/i18n'
import {FormDefinitionElementNames} from 'events/details/FundraisingForm/constants'
import FormNavButtons from 'events/details/FundraisingForm/FormNavButtons'
import FundraisingFormStep from './FundraisingFormStep'
import {InView} from 'react-intersection-observer'
import {OnlineActionSelectedFrequencyToDisplayName} from 'app/enums'
import PoweredByMobilize from '../../components/PoweredByMobilize'
import SMSOptIn from '../SignupForm/SMSOptIn'
import TermsOfService from '../../components/TermsOfService'
import {formatNumberAsCurrency} from 'util/currency'
import {getEventTypeAffinityChecks} from 'util/event'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useState} from 'react'

const FundraisingFormStepWrapper = styled.div`
  margin-top: ${styles.space.m};
`

const SelectedContributionAmountTextWrapper = styled.div`
  margin-bottom: ${styles.space.m};
`

const CONTACT_INFO_STEP_TITLE = 'Contact Information'

export default function FundraisingFormFields({
  dispatch,
  isSubmitting,
  event,
  formDefinition,
  isSkippingDonation,
  isSuccess,
  maybePrioritizedTimeslot,
  onChange,
  onFormInViewChange,
  onSignupValueChange,
  onSubmit,
  organization,
  prefillSource,
  setIsSkippingDonation,
  shouldRenderVgsFields,
  signupValues,
  submitJsForm,
  values,
}) {
  const [activeStep, setActiveStep] = useState(0)
  const {
    isDonateToRsvpOptional,
    isPromptForDonation,
  } = getEventTypeAffinityChecks(event)

  const sections = formDefinition.form_elements.filter(
    (section) => section.type === 'fieldset' && section.children.length
  )
  const steps = sections.map((section) => section.title)
  const forms = sections.map((section, index) => (
    // TODO(ramil) The form element is temporary so we can use reportValidity()
    // See explanation in handleNext function.
    <form key={index} data-section={index}>
      <FundraisingFormStepWrapper>
        <FundraisingFormStep
          dispatch={dispatch}
          isDonateToRsvpEvent={isPromptForDonation}
          isActiveStep={activeStep === index}
          formMetadata={formDefinition.metadata}
          section={section}
          onChange={onChange}
          organization={organization}
          prefillSource={prefillSource}
          shouldRenderVgsFields={shouldRenderVgsFields}
          submitJsForm={submitJsForm}
          signupValues={signupValues}
          values={values}
          event={event}
          maybePrioritizedTimeslot={maybePrioritizedTimeslot}
        />
      </FundraisingFormStepWrapper>
    </form>
  ))

  const scrollToBeginningOfForm = (event) => {
    // ScrollIntoViewOptions are unsupported on IE and Safari, but will still scroll the
    // element into view (just with less finesse). See
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
    event.target.closest('form')?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const validateFormAndRunCallbackIfValid = (event, callback) => {
    const currentForm = document.querySelector(
      `form[data-section='${activeStep}']`
    )
    if (currentForm instanceof HTMLFormElement) {
      if (currentForm.reportValidity()) {
        callback()
      }
    } else {
      console.warn('Form to validate was not found.')
    }
  }

  const handleNext = (event) => {
    // TODO(ramil) Come up with a better implementation to perform field
    // validations on each step. This is temporary to get us through the squad
    // QA session. This uses a browser validity check (not compatible with
    // IE).
    validateFormAndRunCallbackIfValid(event, () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      scrollToBeginningOfForm(event)
    })
  }

  const handleBack = (event) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    // N.B. This isn't obvious, but going back from the contact information
    // step when skipping donation will bring you back to the contribution
    // selection step, so undo skip donate.
    setIsSkippingDonation(false)
    scrollToBeginningOfForm(event)
  }

  const handleContinueToSignupWithoutDonating = (event) => {
    validateFormAndRunCallbackIfValid(event, () => {
      setIsSkippingDonation(true)
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      scrollToBeginningOfForm(event)
    })
  }

  let amount = parseFloat(values.SelectAmount)
  if (values.CoverCostsAmount && values.CoverCostsAmountCalculated) {
    amount += parseFloat(values.CoverCostsAmountCalculated)
  }

  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === steps.length - 1
  const isContactInfoStep =
    activeStep === steps.indexOf(CONTACT_INFO_STEP_TITLE)

  const getContactInformationFields = () => {
    const fieldset = formDefinition.form_elements.find(
      (el) => el.name === FormDefinitionElementNames.CONTACT_INFO
    )
    if (!fieldset) {
      throw new Error('Contact information fieldset not found.')
    }
    return fieldset.children
  }

  const renderSignupWithoutDonating = (
    <>
      <ContactInformationFields
        fields={getContactInformationFields()}
        formMetadata={formDefinition.metadata}
        isSkippingDonation={isSkippingDonation}
        onChange={onChange}
        prefillSource={null}
        values={values}
      />
      <FormNavButtons
        isSubmitting={isSubmitting}
        showSecondaryButton
        disablePrimary={isSuccess}
        onClickPrimary={onSubmit}
        onClickSecondary={handleBack}
        isBackButton
        primaryLabel={
          isSuccess ? (
            <F defaultMessage="Thanks for signing up!" />
          ) : (
            <F defaultMessage="Sign up" />
          )
        }
        secondaryLabel={<F defaultMessage="Back to donation amounts" />}
        primaryId="submit-without-donating"
        secondaryId="back"
      />
      <SMSOptIn
        event={event}
        organization={organization}
        isChecked={signupValues.smsOptIn || false}
        onChange={({name, value}) => onSignupValueChange(name, value)}
      />
    </>
  )

  return (
    <InView onChange={onFormInViewChange}>
      {isSkippingDonation ? (
        renderSignupWithoutDonating
      ) : isFirstStep ? (
        <>
          {!event.disable_participant_count && (
            <DonationProgress expanded event={event} />
          )}
          {/* Hide after success so payment account info will not be displayed. */}
          {!isSuccess && forms}
          <FormNavButtons
            isSubmitting={isSubmitting}
            showSecondaryButton={isDonateToRsvpOptional}
            disablePrimary={!amount}
            onClickPrimary={handleNext}
            onClickSecondary={handleContinueToSignupWithoutDonating}
            isBackButton={false}
            primaryLabel={<F defaultMessage="Continue" />}
            secondaryLabel={<F defaultMessage="Sign up without donating" />}
            primaryId="continue"
            secondaryId="signup-without-donating"
          />
          <PoweredByMobilize />
          <TermsOfService hasMultipleButtons={true} />
        </>
      ) : (
        <>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={index} completed={isSuccess || activeStep > index}>
                <StepLabel children={null} />
              </Step>
            ))}
          </Stepper>
          {values.SelectAmount && (
            <SelectedContributionAmountTextWrapper>
              <Typography variant="h2">
                <F
                  defaultMessage="Complete your {amount} {frequency} donation to {orgName}"
                  values={{
                    amount: formatNumberAsCurrency(amount),
                    // TODO: Internationalize the values in OnlineActionSelectedFrequencyToDisplayName
                    frequency:
                      OnlineActionSelectedFrequencyToDisplayName[
                        values.SelectedFrequency
                      ],
                    orgName: event.organization.name,
                  }}
                  data-testid="donation-amount-header"
                />
              </Typography>
            </SelectedContributionAmountTextWrapper>
          )}
          {/* Hide after success so payment account info will not be displayed. */}
          {!isSuccess && forms}
          {isLastStep ? (
            <FormNavButtons
              isSubmitting={isSubmitting}
              showSecondaryButton={!isSuccess}
              disablePrimary={isSuccess}
              onClickPrimary={onSubmit}
              onClickSecondary={handleBack}
              isBackButton
              primaryLabel={
                isSuccess ? (
                  <F defaultMessage="Thanks for your contribution!" />
                ) : (
                  <F
                    defaultMessage="Donate {amount}"
                    values={{
                      amount: formatNumberAsCurrency(amount),
                    }}
                  />
                )
              }
              secondaryLabel={
                <F
                  defaultMessage="Go back to {stepName}"
                  values={{stepName: steps[activeStep - 1]}}
                />
              }
              primaryId="submit"
              secondaryId="back"
            />
          ) : (
            <FormNavButtons
              isSubmitting={isSubmitting}
              showSecondaryButton={!isSuccess}
              disablePrimary={!amount}
              onClickPrimary={handleNext}
              onClickSecondary={handleBack}
              isBackButton
              primaryLabel={
                <F
                  defaultMessage="Continue to {stepName}"
                  values={{stepName: steps[activeStep + 1]}}
                />
              }
              secondaryLabel={
                <F
                  defaultMessage="Go back to {stepName}"
                  values={{stepName: steps[activeStep - 1]}}
                />
              }
              primaryId="continue"
              secondaryId="back"
            />
          )}
          {isContactInfoStep && (
            <SMSOptIn
              event={event}
              organization={organization}
              isChecked={signupValues.smsOptIn || false}
              onChange={({name, value}) => onSignupValueChange(name, value)}
            />
          )}
        </>
      )}
    </InView>
  )
}
