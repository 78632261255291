import {Autocomplete} from 'components'
import React from 'react'

const LanguageSelect = ({
  localeOptions,
  sectionLabel,
  onChange,
  selectedLanguage = '',
}) => {
  return (
    <Autocomplete
      sectionLabel={sectionLabel}
      placeholder={
        selectedLanguage && selectedLanguage.length ? selectedLanguage : 'Any'
      }
      options={localeOptions}
      onChange={(evt, {value: selected}) => {
        onChange(selected)
      }}
      value={selectedLanguage}
    />
  )
}

export default LanguageSelect
