import AddGroupContactsForm from './AddGroupContactsForm'
import {CardModal} from 'components'
import GroupReservationSize from './GroupReservationSize'
import GroupSignupMembersList from './GroupSignupMembersList'
import {ParticipationStatus} from 'app/enums'
import {useSnackbar} from 'notistack'
import {useState} from 'react'

export default function GroupManagementModal({
  participation,
  isOpen,
  onRequestClose,
  groupLeaderUser,
  updateParticipation,
  addGroupMemberSignup,
  shareLink,
  updateGroupMemberStatus,
}) {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()
  const onAddGroupMembers = async (signups) => {
    setLoading(true)
    try {
      await addGroupMemberSignup(participation.id, signups)
      snackbar.enqueueSnackbar('New reservation added.', {
        variant: 'success',
      })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const updateGroupLeaderStatus = async (participationId, status) => {
    setLoading(true)
    const response = await updateParticipation(participationId, {
      status,
    })
    setLoading(false)

    const successMessage =
      status === ParticipationStatus.Registered
        ? 'Your contact is now signed up'
        : 'Your RSVP is cancelled'

    if (response) {
      snackbar.enqueueSnackbar(successMessage, {
        variant: 'success',
      })
    } else {
      snackbar.enqueueSnackbar(`We were unable to update your RSVP`, {
        variant: 'error',
      })
    }
  }

  return (
    <CardModal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      data-testid="new group management modal"
    >
      <GroupReservationSize
        participation={participation}
        groupLeaderName={groupLeaderUser.first_name}
        updateParticipation={updateParticipation}
      />
      <GroupSignupMembersList
        participation={participation}
        groupLeaderEmail={groupLeaderUser.email}
        shareLink={shareLink}
        updateGroupMemberStatus={async (memberParticipationId, status) => {
          await updateGroupMemberStatus(memberParticipationId, status)
        }}
        updateGroupLeaderStatus={updateGroupLeaderStatus}
        loading={loading}
      />
      <AddGroupContactsForm
        participation={participation}
        groupLeaderUserId={groupLeaderUser.user_id}
        event={participation.event}
        onAddGroupMembers={onAddGroupMembers}
      />
    </CardModal>
  )
}
