import {Tab as MaterialTab, Tabs as MaterialTabs} from '@material-ui/core'

import Link from './Link'

export function Tabs({children, value}) {
  return (
    <MaterialTabs value={value} scrollButtons="auto" variant="scrollable">
      {children}
    </MaterialTabs>
  )
}

export function Tab({label, name, selected, value}) {
  return (
    <MaterialTab
      trackingName={name}
      label={label}
      selected={selected}
      value={value}
    />
  )
}

export function LinkTab({label, name, selected, to}) {
  return (
    <MaterialTab
      component={Link}
      label={label}
      name={name}
      to={to}
      selected={selected}
      trackingName={name}
    />
  )
}
