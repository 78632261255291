import {
  AcceptedCountryCodes,
  CountryCode,
  CountryCodeToDisplayName,
  UsState,
} from 'app/enums'

import {F} from 'util/i18n'
import {isRegistrationOnlyEvent} from './event'

// Compare two EventLocation objects to see if the address, excluding the name and address_line2,
// are the same. These two fields are excluded so that we don't need to re-geocode or suggest a
// different address if the only differences are the venue name or unit number.
export const arePrimaryLocationAddressPartsEqual = (
  locationOne,
  locationTwo
) => {
  const fieldsToCompare = [
    'address_line1',
    'city',
    'zipcode',
    'state',
    'country',
  ]
  return fieldsToCompare.every(
    (field) => locationOne[field] === locationTwo[field]
  )
}

export const geocodeByAddress = (address) => {
  return new Promise((resolve, reject) => {
    new window.google.maps.Geocoder().geocode({address}, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        resolve(results)
      } else {
        reject(status)
      }
    })
  })
}

// Parses an EventLocation from Google's geocoding results
export const getAddressPartsFromGoogleResult = (addressComponents) => {
  let unit = '',
    number = '',
    street = '',
    intersection = '',
    locality = '',
    sublocality = '',
    state = '',
    zipcode = '',
    areaLevel2LongName = '',
    areaLevel3LongName = '',
    areaLevel4LongName = '',
    areaLevel5LongName = '',
    country = CountryCode.UNITED_STATES
  addressComponents.forEach((component) => {
    if (component.types.includes('subpremise')) {
      unit = component.long_name
    } else if (component.types.includes('street_number')) {
      number = component.long_name
    } else if (component.types.includes('route')) {
      street = component.short_name
    } else if (component.types.includes('intersection')) {
      intersection = component.long_name
    } else if (component.types.includes('locality')) {
      locality = component.long_name
    } else if (component.types.includes('sublocality')) {
      sublocality = component.long_name
    } else if (component.types.includes('administrative_area_level_5')) {
      areaLevel5LongName = component.long_name
    } else if (component.types.includes('administrative_area_level_4')) {
      areaLevel4LongName = component.long_name
    } else if (component.types.includes('administrative_area_level_3')) {
      areaLevel3LongName = component.long_name
    } else if (component.types.includes('administrative_area_level_2')) {
      areaLevel2LongName = component.long_name
    } else if (
      component.types.includes('administrative_area_level_1') &&
      // TODO(jared) we'll need to revisit this when we support all international addresses
      UsState[component.short_name]
    ) {
      state = component.short_name
    } else if (component.types.includes('postal_code')) {
      zipcode = component.long_name
    } else if (component.types.includes('country')) {
      country = component.short_name
    }
  })

  const city =
    locality ||
    sublocality ||
    areaLevel5LongName ||
    areaLevel4LongName ||
    areaLevel3LongName ||
    areaLevel2LongName

  // TODO(ramil) I am making an assumption that intersections never include a street or number
  const addressLine1Components = [number, street, unit, intersection].filter(
    (val) => !!val
  )
  const addressLine1 = addressLine1Components.join(' ')

  return {
    number,
    street,
    unit,
    intersection,
    addressLine1,
    city,
    state,
    zipcode,
    country,
  }
}

// Expects a Google Geocoding or Place Details result object with a geometry.location.
export const getLatLng = (result) => {
  return {
    lat: result.geometry.location.lat(),
    lng: result.geometry.location.lng(),
  }
}

export const getLocationFromAddressParts = (addressParts) => {
  return {
    name: '',
    address_line1: addressParts.addressLine1,
    address_line2: '',
    city: addressParts.city,
    state: addressParts.state,
    zipcode: addressParts.zipcode,
    country: addressParts.country,
    lat: null,
    lon: null,
  }
}

export const getPlaceDetails = async (
  placeId,
  sessionToken // a google.maps.places.AutocompleteSessionToken
) => {
  // Create a dummy div because PlacesService requires one to initialize.
  const service = new window.google.maps.places.PlacesService(
    document.createElement('div')
  )
  const options = {
    // Request only basic fields to keep costs down.
    fields: [
      'address_components',
      'formatted_address',
      'geometry',
      'name',
      'utc_offset',
      'vicinity',
    ],
    placeId,
    sessionToken: sessionToken,
  }

  return new Promise((resolve, reject) => {
    try {
      service.getDetails(options, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(result)
        } else {
          throw new Error(`Google PlacesService API returned error: ${status}`)
        }
      })
    } catch (e) {
      reject(e)
    }
  })
}

export const maybeGetStateOrTerritoryFromLocation = (location) => {
  if (!location) {
    return null
  }

  if (location.state) {
    return location.state
  } else if (
    location.country &&
    location.country !== CountryCode.UNITED_STATES
  ) {
    return location.country
  } else {
    return null
  }
}

export const validateAddressParts = (addressParts) => {
  const {zipcode, country} = addressParts
  const locationInUnsupportedCountry = !AcceptedCountryCodes.has(country)
  const locationNotSpecificEnough = !zipcode
  return {
    locationInUnsupportedCountry,
    locationNotSpecificEnough,
  }
}

export const validateLocation = (location) => {
  const {zipcode, country} = location
  const locationInUnsupportedCountry = !(
    country && AcceptedCountryCodes.has(country)
  )
  const locationNotSpecificEnough = !zipcode
  return {
    locationInUnsupportedCountry,
    locationNotSpecificEnough,
  }
}

export const locationToFilterParamsPartial = ({addressOneLiner, location}) => {
  // In this case the location looks like just a state or territory, so just set the relevant
  // state/country filters instead of doing a radius search
  if (
    !location.address_line1 &&
    !location.address_line2 &&
    !location.city &&
    !location.zipcode &&
    (location.state ||
      (location.country &&
        // We don't want to do this if the user enters e.g. "France"
        AcceptedCountryCodes.has(location.country)))
  ) {
    return {
      address: addressOneLiner,
      state: location.state,
      country: location.country,
      // Clear out lat/lon to prevent previous geo-radius search from being combined with this
      // state/country filter
      lat: null,
      lon: null,
    }
  }

  return {
    address: addressOneLiner,
    lat: location.lat,
    lon: location.lon,
    // Clear these out b/c otherwise previous searches' country/state values would persist,
    // leading to unexpected results
    country: null,
    state: null,
  }
}

// see lib.geo.is_valid_us_zipcode
const US_ZIPCODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/

// almost identical to above, but matches any zipcode-like string in a larger str
const US_ZIPCODE_SEARCH_REGEX = /\d{5}(?:[-\s]\d{4})?/

export function isValidUSZipcode(zip) {
  return US_ZIPCODE_REGEX.test(zip)
}

/**
 * @description if given an arbitrary string, look for a valid US zip code
 * and then if valid match found, return that, else return empty string
 */
export function getValidUSZipcodeFromString(address) {
  return address.match(US_ZIPCODE_SEARCH_REGEX)?.[0] || ''
}

export function isValidLatLon({lat, lon}) {
  return typeof lat === 'number' && typeof lon === 'number'
}

const geocoder =
  window.google && window.google.maps ? new window.google.maps.Geocoder() : null

export async function zipToMaybeLatLon(zip) {
  return new Promise((resolve, reject) => {
    if (!geocoder) {
      reject(new Error(`no geocoder found, could not geocode ${zip}`))
      return
    }
    if (!isValidUSZipcode(zip)) {
      resolve(null)
      return
    }
    geocoder.geocode(
      {
        address: `${zip}, US`,
        componentRestrictions: {postalCode: zip},
      },
      (results, status) => {
        if (status === 'OK') {
          let validResult = null
          results.forEach((result) => {
            if (validResult) {
              return
            }
            result.address_components.forEach((component) => {
              if (validResult) {
                return
              }
              if (
                component.types.includes('postal_code') &&
                component.short_name === zip
              ) {
                validResult = result
              }
            })
          })
          if (validResult) {
            const location = validResult.geometry.location
            resolve({
              lat: location.lat().toFixed(6),
              lon: location.lng().toFixed(6),
            })
          } else {
            reject(new Error(`${zip} is an invalid ZIP code`))
          }
        } else if (status === 'ZERO_RESULTS') {
          reject(new Error(`${zip} is an invalid ZIP code`))
        } else {
          reject(new Error(`geocoding ${zip} got error ${status}`))
        }
      }
    )
  })
}

export function maybeGetNonUSCountryDisplayName(country) {
  return country && country !== CountryCode.UNITED_STATES
    ? CountryCodeToDisplayName[country]
    : null
}

export function getOneLineAddressFromLocation(location) {
  const {address_line1, address_line2, city, state, zipcode, country} = location
  const maybeCountryDisplayName = maybeGetNonUSCountryDisplayName(country)
  return (
    (address_line1 && address_line1.trim() ? address_line1.trim() + ', ' : '') +
    (address_line2 && address_line2.trim() ? address_line2.trim() + ', ' : '') +
    (city && city.trim() ? city.trim() + ', ' : '') +
    (state && state.trim() ? state.trim() + ' ' : '') +
    (maybeCountryDisplayName ? maybeCountryDisplayName + ' ' : '') +
    (zipcode || '')
  )
}

export function getOneLineAddress(event, isCompactFeedItem) {
  const {address_line1, address_line2, city, state, zipcode, country} = event

  if (isRegistrationOnlyEvent(event)) {
    return null
  }
  if (event.is_virtual) {
    if (!!isCompactFeedItem && (city || state)) {
      return `Virtual · ${getLocationCopyForVirtualEvents(event)}`
    }
  }
  const oneLineAddress = getOneLineAddressFromLocation({
    name: null,
    address_line1,
    address_line2,
    city,
    state,
    zipcode,
    country,
    lat: null,
    lon: null,
  })
  return event.location_is_private
    ? `Private location · ${oneLineAddress}`
    : oneLineAddress
}

export function getOneLineAddressForMap(event) {
  const {address_line1, address_line2, city, state, zipcode, country} = event

  if (event.is_virtual) {
    return ''
  }
  return getOneLineAddressFromLocation({
    name: null,
    address_line1,
    address_line2,
    city,
    state,
    zipcode,
    country,
    lat: null,
    lon: null,
  })
}

export function getCityStateZip(event) {
  const {city, state, zipcode, country} = event

  return getOneLineAddressFromLocation({
    name: null,
    address_line1: null,
    address_line2: null,
    city,
    state,
    zipcode,
    country,
    lat: null,
    lon: null,
  })
}

export function getCityState(event) {
  const {city, state} = event

  return getOneLineAddressFromLocation({
    name: null,
    address_line1: null,
    address_line2: null,
    city,
    state,
    zipcode: null,
    country: null,
    lat: null,
    lon: null,
  })
}

export function getLocationCopyForVirtualEvents(event) {
  const cityState = getCityState(event).trim()

  const maybeState = maybeGetStateOrTerritoryFromLocation(event)
  const stateNameFull = (maybeState && UsState[maybeState]) || maybeState // use maybeState as a fallback here to capture territories/country value

  return event.is_statewide && stateNameFull ? (
    <>{stateNameFull}</>
  ) : cityState ? (
    <F defaultMessage="Hosted from {cityState}" values={{cityState}} />
  ) : (
    <F defaultMessage="Join from anywhere" />
  )
}
