import {Banner, Typography} from 'components'

export default function FeedBanners({
  organization,
  pagingInfo,
  currentVolunteer,
}) {
  let title = 'Find high-impact opportunities near you'
  if (organization.branding.banner_header) {
    title = organization.branding.banner_header
  } else if (organization.name) {
    title = `Take action with ${organization.name}`
  }
  let bannerBody
  if (organization.branding.banner_body_html) {
    bannerBody = organization.branding.banner_body_html
  } else {
    bannerBody = `Check out these ways to get involved!`
  }

  const isFeedBannerEnabled =
    organization.show_event_feed_banner && pagingInfo.page === 1

  return (
    <>
      {isFeedBannerEnabled ? (
        <Banner center compact>
          <Typography variant="h1" color="inherit">
            {title}
          </Typography>
          <Typography variant="body1" color="inherit">
            <div dangerouslySetInnerHTML={{__html: bannerBody}} />
          </Typography>
        </Banner>
      ) : (
        <Banner small compact />
      )}
    </>
  )
}
