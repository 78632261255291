import 'mapbox-gl/dist/mapbox-gl.css'

import {
  GeolocateControl,
  InteractiveMap,
  Marker,
  NavigationControl,
  Popup,
  Source,
  WebMercatorViewport,
} from 'react-map-gl'
import React, {useEffect, useMemo, useRef, useState} from 'react'

import {LocationSpecificity} from 'app/enums'
import MapCloseButton from 'components/map/MapCloseButton'
import MapLayers from 'components/map/MapLayers'
import cv from 'util/clientVars'
import {logError} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const TopLeftControlWrapper = styled.div`
  position: absolute;
  top: ${styles.space.s};
  left: ${styles.space.s};
  display: flex;
`

const getLocationSpecificityCount = (specificity) => {
  return [
    '+',
    ['case', ['==', ['get', 'locationSpecificity'], specificity], 1, 0],
  ]
}

const getBoundingBox = (features) => {
  const lngCoords = features.map((f) => f.geometry.coordinates[0])
  const latCoords = features.map((f) => f.geometry.coordinates[1])
  return [
    [Math.min(...lngCoords), Math.min(...latCoords)],
    [Math.max(...lngCoords), Math.max(...latCoords)],
  ]
}

const getBoundingViewport = ({
  features,
  map = null,
  transitionDuration,
  transitionInterpolator,
}) => {
  let dimensions = MERCATOR_VIEWPORT_DIMENSIONS
  if (map && map.getMap()) {
    const mapContainer = map.getMap().getContainer()
    dimensions = {
      width: mapContainer.offsetWidth,
      height: mapContainer.offsetHeight,
    }
  }
  const viewportUtil = new WebMercatorViewport(dimensions)
  const boundingBox = getBoundingBox(features)
  const {longitude, latitude, zoom} = viewportUtil.fitBounds(boundingBox, {
    padding: {top: 70, bottom: 60, left: 25, right: 50}, // move markers away from sides/controls
    minExtent: 0.01, // ~1000 meters
  })
  return {longitude, latitude, zoom, transitionDuration, transitionInterpolator}
}

const pinSize = 20
const pinIcon = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,
  10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,
  0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9C20.1,15.8,20.2,
  15.8,20.2,15.7z`

const Pin = () => (
  <svg
    height={pinSize}
    viewBox="0 0 24 24"
    style={{
      fill: '#d00',
      stroke: 'none',
      transform: `translate(${-pinSize / 2}px,${-pinSize}px)`,
    }}
  >
    <path d={pinIcon} />
  </svg>
)

const MERCATOR_VIEWPORT_DIMENSIONS = {width: 300, height: 300}
const CLICK_TRANSITION_DURATION = 250
const CLUSTER_ZOOM_DURATION = 1000
const MAX_ZOOM = 18
// clusterMaxZoom needs to be greater than the map's maxZoom so we can determine if a cluster can be expanded
const MAX_CLUSTER_ZOOM = MAX_ZOOM + 5

export default function Map({
  markers = [],
  latitude: initialLat,
  longitude: initialLng,
  zoom: initialZoom,
  height,
  width,
  onClusterClick = () => {},
  onMarkerClick = () => {},
  renderEventPopup = () => null,
  renderClusterPopup = () => null,
  navigationControl = true,
  geolocateControl = true,
  isInteractive = true,
  shouldInitiallyFitBounds = true,
  showCloseButton = false,
  onRequestClose = () => {},
  renderCenterAsMarker,
  onPositionChange = () => {},
  renderExtraControls,
}) {
  const mapRef = useRef(null)
  const [mapLoaded, setMapLoaded] = useState(false)
  // only fit bounds on the first features update
  const [shouldFitBounds, setShouldFitBounds] = useState(
    shouldInitiallyFitBounds
  )

  const [clusterPopupInfo, setClusterPopupInfo] = useState(null)
  const [eventPopupInfo, setEventPopupInfo] = useState(null)
  const [viewport, setViewport] = useState({
    latitude: initialLat,
    longitude: initialLng,
    zoom: initialZoom,
  })

  const features = useMemo(() => {
    return markers
      .filter(({lat, lng}) => {
        // filter out (-90.0, 0.0) values we set for events with no location
        return lat && lng && (lat !== -90.0 || lng !== 0.0)
      })
      .map(({event_id, lat, lng, zipcode, location_specificity}) => ({
        type: 'Feature',
        properties: {
          eventId: event_id,
          zipcode: zipcode,
          locationSpecificity: location_specificity,
          eventCount: 1, // use eventCount rather than the built-in point_count for clusters to allow aggregate sums
        },
        geometry: {type: 'Point', coordinates: [lng, lat]},
      }))
  }, [markers])

  // fit bounds of map to features
  useEffect(() => {
    if (mapLoaded && shouldFitBounds && features.length > 0) {
      setShouldFitBounds(false)
      try {
        setViewport(
          getBoundingViewport({
            features,
            map: mapRef.current,
          })
        )
      } catch (e) {
        logError(e)
      }
    }
  }, [features, shouldFitBounds, mapLoaded])

  const onClick = (event) => {
    setClusterPopupInfo(null)
    setEventPopupInfo(null)
    if (!isInteractive || !event.features || !event.features[0]) {
      return
    }

    const feature = event.features[0]
    const {eventId, eventCount, cluster_id: clusterId} = feature.properties
    const [longitude, latitude] = feature.geometry.coordinates

    const markerPosition = {lat: latitude, lng: longitude}

    if (clusterId && mapRef.current) {
      // handle cluster marker click
      const source = mapRef.current.getMap().getSource('events')
      // a cluster could consist of several event markers at the same location
      // if it is, we should set the popup to that location; otherwise, we should zoom and expand cluster
      // if the cluster expansion zoom is greater or equal to the max zoom, this cluster can't be expanded
      source.getClusterExpansionZoom(clusterId, (err, expansionZoom) => {
        if (err) {
          logError(err)
          return
        }
        const clusterIsExpandable = expansionZoom < MAX_ZOOM
        source.getClusterLeaves(clusterId, eventCount, 0, (err, leaves) => {
          if (err) {
            logError(err)
            return
          }
          const clusterMarker = {
            count: eventCount,
            isExpandable: clusterIsExpandable,
            leaves: leaves.map((feature) => ({
              event_id: feature.properties.eventId,
              location_specificity: feature.properties.locationSpecificity,
              zipcode: feature.properties.zipcode,
              lat: feature.geometry.coordinates[1],
              lng: feature.geometry.coordinates[0],
            })),
            ...markerPosition,
          }
          if (clusterIsExpandable) {
            const expandedViewport = getBoundingViewport({
              features: leaves,
              map: mapRef.current,
              transitionDuration: CLUSTER_ZOOM_DURATION,
            })
            setViewport(expandedViewport)
            const {latitude, longitude, zoom} = expandedViewport
            onPositionChange({latitude, longitude, zoom})
          } else {
            setClusterPopupInfo(clusterMarker)
          }
          onClusterClick(clusterMarker)
        })
      })
    } else if (eventId) {
      // handle event marker click
      const eventMarker = {event_id: eventId, ...markerPosition}
      const viewportUtil = new WebMercatorViewport({
        ...MERCATOR_VIEWPORT_DIMENSIONS,
        zoom: viewport.zoom,
      })
      // position the selected event marker centered at the top of the viewport
      const [lng, lat] = viewportUtil.getMapCenterByLngLatPosition({
        lngLat: [longitude, latitude],
        pos: [MERCATOR_VIEWPORT_DIMENSIONS.width / 2, 0],
      })
      const positionUpdate = {
        longitude: lng,
        latitude: lat,
        zoom: viewport.zoom,
      }
      setViewport({
        ...positionUpdate,
        transitionDuration: CLICK_TRANSITION_DURATION,
      })
      onPositionChange(positionUpdate)
      setEventPopupInfo(eventMarker)
      onMarkerClick(eventMarker)
    }
  }

  const onViewportChange = ({latitude, longitude, zoom, ...viewportExtra}) => {
    const positionUpdate = isInteractive
      ? {latitude, longitude, zoom}
      : {latitude: viewport.latitude, longitude: viewport.longitude, zoom} // if not interactive, only the zoom should be able to change
    setViewport({...viewport, ...positionUpdate, ...viewportExtra})
    // only propagate the position update if it was from user interaction, not transitions
    if (!viewportExtra.transitionDuration) {
      onPositionChange(positionUpdate)
    }
  }

  return (
    <InteractiveMap
      id="map"
      {...viewport}
      ref={mapRef}
      onLoad={() => setMapLoaded(true)}
      mapboxApiAccessToken={cv.mapbox_client_api_key}
      mapStyle={cv.mapbox_map_style_url}
      width={width}
      height={height}
      onViewportChange={onViewportChange}
      onClick={onClick}
      interactiveLayerIds={['unclustered-marker', 'clusters']}
      maxZoom={MAX_ZOOM}
      scrollZoom={isInteractive}
      asyncRender={true} // let mapbox-gl manage its own render cycle rather than force a re-draw at every input
    >
      {eventPopupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={eventPopupInfo.lng}
          latitude={eventPopupInfo.lat}
          closeOnClick={false}
          closeButton={false}
          onClose={() => setEventPopupInfo(null)}
        >
          {renderEventPopup(eventPopupInfo)}
        </Popup>
      )}
      {clusterPopupInfo && (
        <Popup
          offsetTop={10}
          tipSize={5}
          anchor="top"
          longitude={clusterPopupInfo.lng}
          latitude={clusterPopupInfo.lat}
          closeOnClick={false}
          closeButton={false}
          onClose={() => setClusterPopupInfo(null)}
        >
          {renderClusterPopup(clusterPopupInfo)}
        </Popup>
      )}
      <Source
        id="events"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: features,
        }}
        clusterProperties={{
          eventCount: ['+', ['get', 'eventCount'], ['get', 'eventCount']],
          zipcodeOnlyCount: getLocationSpecificityCount(
            LocationSpecificity.ZIPCODE
          ),
        }}
        maxzoom={MAX_CLUSTER_ZOOM}
        clusterRadius={50}
        cluster
      >
        <MapLayers />
      </Source>

      {navigationControl && (
        <NavigationControl
          showCompass={false}
          style={{
            top: styles.space.s,
            right: styles.space.s,
          }}
        />
      )}

      {geolocateControl && (
        <GeolocateControl
          style={{
            top: navigationControl
              ? `calc(2 * ${styles.space.s} + 58px)`
              : styles.space.s,
            right: styles.space.s,
          }}
        />
      )}

      <TopLeftControlWrapper>
        {showCloseButton && <MapCloseButton onRequestClose={onRequestClose} />}
        {renderExtraControls && renderExtraControls()}
      </TopLeftControlWrapper>

      {renderCenterAsMarker && (
        // todo(kayvon): render markers as symbols in unclustered-point layer to consolidate approach
        <Marker longitude={viewport.longitude} latitude={viewport.latitude}>
          <Pin />
        </Marker>
      )}
    </InteractiveMap>
  )
}
