import Button from './Button'
import MaxWidthContainer from './MaxWidthContainer'
import Message from './Message'
import styled from '@emotion/styled/macro'
import styles from './styles'

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default function Notification({message, onClose}) {
  const {body, type} = message

  return (
    <Message type={type} role="alert">
      <MaxWidthContainer>
        <InnerContainer>
          {body}
          <Button
            link
            plain
            noUnderline
            icon="times"
            aria-label="times"
            linkTextColor={styles.colors.neutral400}
            type="button"
            onClick={onClose}
          />
        </InnerContainer>
      </MaxWidthContainer>
    </Message>
  )
}
