import {lazyLoadElement} from '../util/lazy-load'

const initializedIDs = new Set()
let loadedScript = false

// initialize gtag for a given id
function ensureInitialized(id) {
  if (!loadedScript) {
    lazyLoadElement('script', {
      async: true,
      src: 'https://www.googletagmanager.com/gtag/js?id=' + id,
    })
    window.dataLayer = window.dataLayer || []
    window.gtag = function () {
      window.dataLayer.push(arguments)
    }
    window.gtag('js', new Date())
    loadedScript = true
  }

  if (!initializedIDs.has(id)) {
    window.gtag('config', id, {send_page_view: false})
    window.gtag('set', 'linker', {
      domains: ['mobilize.us', 'join.mobilize.us', 'mobilizeamerica.io'],
    })
    initializedIDs.add(id)
  }
}

export function pageview(id, url = null) {
  ensureInitialized(id)

  const params = {
    send_to: id,
  }
  if (url) {
    params.page_location = url // artificial pageview
  }
  window.gtag('event', 'page_view', params)
}
