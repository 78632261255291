import CurrentUserContext from 'app/CurrentUserContext'
import {getIdentityFields} from 'util/user'
import {useContext} from 'react'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'

// Hook wrapper around getIdentityFields. This includes signup fields and street
// address fields (addressLine1, addressLine2, city, state). Prefer using this
// wherever possible.
export default function useIdentityFields() {
  const {user} = useContext(CurrentUserContext)
  const location = useLocation()
  const {currentVolunteer, feedUser} = useSelector(
    ({currentVolunteer, feedUser}) => ({
      currentVolunteer,
      feedUser,
    })
  )
  return getIdentityFields({
    user,
    location,
    currentVolunteer,
    feedUser,
  })
}
