import {CheckboxField, Link} from 'components'

import {Container} from './util'
import {F} from 'util/i18n'
import IntlMessageContext from 'app/IntlMessageContext'
import cv from 'util/clientVars'
import {filterFalsy} from 'util/common'
import {useContext} from 'react'

export default function SMSOptIn({event, isChecked, onChange, organization}) {
  const {
    intlMessageConfig: {locale},
  } = useContext(IntlMessageContext)

  const {custom_signup_form_sms_opt_in_text} = organization.branding

  let coOwningOrgNames = []
  if (event.manually_added_co_owning_orgs) {
    const coOwningOrgs = event.manually_added_co_owning_orgs.filter(
      (o) => o.id !== organization.id
    )
    if (coOwningOrgs && coOwningOrgs.length < 3) {
      coOwningOrgNames = coOwningOrgs.map((o) => o.name)
    } else if (coOwningOrgs) {
      coOwningOrgNames = [
        coOwningOrgs[0].name,
        coOwningOrgs[1].name,
        'partnering organizations',
      ]
    }
  }

  const sendersArray = filterFalsy([
    // If the event owner is Mobilize, then it's redundant w/ 'Mobilize' at the end
    !event.organization.is_mobilize ? event.organization.name : null,
    // If the current org is the event owner, then it's redundant with the above line
    event.organization.id !== organization.id &&
    // If the current org is Mobilize, then it's redundant w/ 'Mobilize' at the end
    !organization.is_mobilize
      ? organization.name
      : null,
    cv.product_name,
    ...coOwningOrgNames,
  ])

  let sendersCopy = ''

  try {
    sendersCopy = new Intl.ListFormat(locale, {
      style: 'long',
      type: 'conjunction',
    }).format(sendersArray)
  } catch {
    // fallback in case Intl.ListFormat is not available
    sendersCopy = sendersArray.join(', ')
  }

  const smsOptInLabel = custom_signup_form_sms_opt_in_text ? (
    <div
      dangerouslySetInnerHTML={{__html: custom_signup_form_sms_opt_in_text}}
    />
  ) : (
    <div>
      <F
        defaultMessage="I want to receive occasional automated text messages from {senders} with updates about how to stay involved, such as recommended events and other actions. Message and data rates may apply. Message frequency varies. Text STOP to cancel or HELP for help. By opting in you agree to our <ToSLink>SMS Shortcode Terms of Service</ToSLink>"
        values={{
          senders: sendersCopy,
          ToSLink: (str) => (
            <Link to={cv.tos_url} popup>
              {str}
            </Link>
          ),
        }}
      />
    </div>
  )

  return (
    <Container>
      <CheckboxField
        name="smsOptIn"
        onChange={onChange}
        checked={isChecked}
        small
        label={smsOptInLabel}
      />
    </Container>
  )
}
