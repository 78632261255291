import {
  ActionButtons,
  Button,
  CardModal,
  Form,
  FormItem,
  Input,
  InputGroup,
  Message,
  MessageType,
  TimezoneSelector,
} from 'components'
import {TimezoneSource, UsTimezoneToString} from 'app/enums'
import styles, {fontSizeSmall} from 'components/styles'

import SettingsCard from './SettingsCard'
import api from 'data/api'
import cv from 'util/clientVars'
import {formatPhoneNumber} from 'util/string'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'
import {useState} from 'react'

const NameWrapper = styled.div`
  font-weight: ${styles.typography.fontWeightSemibold};
`

const PendingEmailWrapper = styled.div`
  font-style: italic;
  ${fontSizeSmall};
  margin-bottom: ${styles.space.s};
`

const ModalEmailContainer = styled.span`
  line-height: ${styles.typography.lineHeightM};
  margin-right: ${styles.space.m};
`

export default function UserDetailsSettingsCard({initialUser}) {
  const [user, setUser] = useState({...initialUser})
  const [pendingUser, setPendingUser] = useState({...user})
  const [pendingEmail, setPendingEmail] = useState(null)
  const [editDetailsModalOpen, setEditDetailsModalOpen] = useState(false)
  const [editingEmail, setEditingEmail] = useState(false)
  const [error, setError] = useState(null)

  const openEditDetails = () => setEditDetailsModalOpen(true)
  const closeEditDetails = () => setEditDetailsModalOpen(false)

  const onCancelEdit = (e) => {
    if (e) {
      e.preventDefault()
    }
    closeEditDetails()
    setError(null)
    setPendingUser({...user})
  }

  const onFieldChange = ({currentTarget}) =>
    setPendingUser({...pendingUser, [currentTarget.name]: currentTarget.value})

  const onTimezoneChange = (e, data) => {
    setPendingUser({...pendingUser, timezone: data.value})
  }

  const onDetailsSubmit = async (e) => {
    e.preventDefault()
    const newDetails = {
      first_name: pendingUser.first_name,
      last_name: pendingUser.last_name,
      phone: pendingUser.phone || null,
      zipcode: pendingUser.zipcode || null,
      timezone: pendingUser.timezone || null,
      timezone_source: pendingUser.timezone ? TimezoneSource.USER_INPUT : null,
    }
    try {
      await api.updateUserDetails(newDetails)
      if (pendingUser.email && pendingUser.email !== user.email) {
        await api.changeUserEmail({email: pendingUser.email})
        setPendingEmail(pendingUser.email)
      }
      setError(null)
      setEditingEmail(false)
      setUser({...user, ...newDetails})
      closeEditDetails()
    } catch (e) {
      setError(e.message)
    }
  }

  const onEditEmailClick = () => setEditingEmail(true)

  return (
    <>
      <CardModal
        header="Edit Contact Info"
        isOpen={editDetailsModalOpen}
        onRequestClose={onCancelEdit}
      >
        <Form onSubmit={onDetailsSubmit}>
          <InputGroup>
            <Input
              type="text"
              value={pendingUser.first_name}
              label="First name"
              name="first_name"
              onChange={onFieldChange}
            />
            <Input
              type="text"
              value={pendingUser.last_name}
              label="Last name"
              name="last_name"
              onChange={onFieldChange}
            />
          </InputGroup>
          {editingEmail ? (
            <Input
              type="email"
              name="email"
              label="Email"
              value={pendingUser.email}
              onChange={onFieldChange}
              required
              hint={
                editingEmail &&
                pendingUser.email !== user.email &&
                `After submitting this form, make sure to verify ${pendingUser.email} to confirm the change.`
              }
            />
          ) : (
            <FormItem label="Email" row>
              <ModalEmailContainer>
                {pendingUser.email !== user.email
                  ? `${pendingUser.email} (pending)`
                  : user.email}
              </ModalEmailContainer>
              <Button
                type="button"
                link
                padding="none"
                onClick={onEditEmailClick}
              >
                Edit
              </Button>
            </FormItem>
          )}
          <InputGroup>
            <Input
              type="tel"
              name="phone"
              label="Phone number"
              value={pendingUser.phone || ''}
              onChange={onFieldChange}
              required
            />
            <Input
              isZIPCode
              label="ZIP code"
              width="7rem"
              name="zipcode"
              value={pendingUser.zipcode || ''}
              onChange={onFieldChange}
              required
            />
          </InputGroup>
          <TimezoneSelector
            selectedTimezone={pendingUser.timezone}
            onChange={onTimezoneChange}
            fluid
          />
          {error && (
            <Message header="Oops" type={MessageType.ERROR} list={[error]} />
          )}
          <ActionButtons>
            <Button secondary onClick={onCancelEdit}>
              Cancel
            </Button>
            <Button type="submit">Update info</Button>
          </ActionButtons>
        </Form>
      </CardModal>
      <SettingsCard
        header="Contact Info"
        footer={`${cv.product_name} and event organizers will contact you about your events.`}
        action={() => (
          <Button
            link
            noUnderline
            icon="pencil"
            aria-label="edit"
            onClick={openEditDetails}
            linkTextColor={styles.colors.neutral400}
          />
        )}
      >
        {(user.first_name || user.last_name) && (
          <NameWrapper>
            {user.first_name} {user.last_name}
          </NameWrapper>
        )}
        <div>{user.email}</div>
        {pendingEmail && pendingEmail !== user.email && (
          <PendingEmailWrapper>
            {pendingEmail} (pending confirmation)
          </PendingEmailWrapper>
        )}
        {user.phone && <div>{formatPhoneNumber(user.phone)}</div>}
        {user.zipcode && <div>{user.zipcode}</div>}
        {user.timezone && (
          <div>
            Timezone: {UsTimezoneToString[user.timezone]} (GMT
            {moment().tz(user.timezone).format('Z')})
          </div>
        )}
      </SettingsCard>
    </>
  )
}
