import {Timezone, TimezoneToString} from 'app/enums'

import {Select} from 'components'

const TimezoneField = ({
  disabled,
  fluid,
  hint,
  label = 'Timezone',
  name = 'timezone',
  onChange,
  required,
  value,
  width,
}) => (
  <Select
    disabled={disabled}
    fluid={fluid}
    hint={hint}
    label={label}
    name={name}
    onChange={onChange}
    options={Object.values(Timezone)
      // $FlowFixMe - Flow doesn't understand Object.values
      .map((tz) => ({
        value: tz,
        key: tz,
        text: TimezoneToString[tz],
      }))}
    required={required}
    value={value}
  />
)

export default TimezoneField
