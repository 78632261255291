import {ActionType} from 'events/details/FundraisingForm/FundraisingFormReducer'

export function setFormDefinition(formDefinition) {
  return {type: ActionType.FORM_DEFINITION_SET, data: {formDefinition}}
}

export function setSignupValues(valuesToUpdate) {
  return {
    type: ActionType.SIGNUP_VALUES_UPDATED,
    data: {valuesToUpdate},
  }
}

export function setSubmitJsForm(form) {
  return {
    type: ActionType.SUBMIT_JS_FORM_INITIALIZED,
    data: {submitJsForm: form},
  }
}

export function setFormValues(formValuesData) {
  return {
    type: ActionType.FORM_VALUES_UPDATED,
    data: formValuesData,
  }
}

export function setModalState(isOpen) {
  return {
    type: ActionType.MODAL_STATE_UPDATED,
    data: {isOpen},
  }
}

export function setSkippingDonationState(isSkippingDonation) {
  return {
    type: ActionType.SKIPPING_DONATION_STATE_UPDATED,
    data: {isSkippingDonation},
  }
}

export function setSignupSucceeded(shouldOpenModal, signupResponse) {
  return {
    type: ActionType.SIGNUP_SUCCEEDED,
    data: {
      shouldOpenModal,
      signupResponse,
    },
  }
}
