import {MaxWidthContainer} from 'components'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const OffsetContainer = styled.div`
  margin-top: ${styles.space.m};
  margin-bottom: ${styles.space.m};
  padding-top: ${styles.space.m};
  padding-bottom: ${styles.space.m};
  border-top: 1px solid ${styles.colors.neutral300};
  border-bottom: 1px solid ${styles.colors.neutral300};
  background-color: ${styles.colors.neutral200};
`

// Full-width stripe across the feed with a centered child
export default function FeedOffsetItem({children}) {
  return (
    <OffsetContainer>
      <MaxWidthContainer noGutters>{children}</MaxWidthContainer>
    </OffsetContainer>
  )
}
