import {Button, Link} from 'components'

import {F} from 'util/i18n'

function timeString(startMoment) {
  const onTheHour = startMoment.minutes() === 0
  const formatString = onTheHour ? 'ha z' : 'h:mma z'
  return startMoment.format(formatString)
}

export default function JoinButton({isVideoCall, startMoment, virtualJoinUrl}) {
  const timeInfo = timeString(startMoment)

  return (
    <Link to={virtualJoinUrl} target="_blank">
      <Button style={{textAlign: 'left'}}>
        {isVideoCall ? (
          <F
            defaultMessage="Join video call @ {timeInfo}"
            values={{timeInfo}}
          />
        ) : (
          <F defaultMessage="Join event @ {timeInfo}" values={{timeInfo}} />
        )}
      </Button>
    </Link>
  )
}
