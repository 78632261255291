import {Children, useMemo} from 'react'

import Autocomplete from './Autocomplete'
import FormItem from './FormItem'
import moment from 'vendor/moment'

export const TIME_FORMAT = 'HH:mm'

export default function TimePicker({
  hint,
  maxTime,
  minTime,
  onChange,
  required,
  sectionLabel,
  value,
  disableAutoselect,
  ...props
}) {
  const handleChange = (e, {name, value}) => {
    const m = moment(value, TIME_FORMAT)
    onChange(m.isValid() ? m.format(TIME_FORMAT) : null)
  }

  const minDate = minTime && moment(minTime, TIME_FORMAT)
  const maxDate = maxTime && moment(maxTime, TIME_FORMAT)
  const shouldAutoselect = disableAutoselect ? !disableAutoselect : true

  const timeOptions = useMemo(() => {
    const options = []

    for (let index = 9; index < 33; ++index) {
      for (const minute of [0, 15, 30, 45]) {
        const reindexedHour = index % 24 // because we start at 7am and not at 12am and loop around to 31
        const timeOption = minDate ? minDate.clone() : moment()
        timeOption.set({hour: reindexedHour, minute, second: 0, millisecond: 0})

        if (
          (!minDate || minDate <= timeOption) &&
          (!maxDate || timeOption <= maxDate)
        ) {
          options.push({
            text: timeOption.format('h:mm A'),
            value: timeOption.format(TIME_FORMAT),
          })
        }
      }
    }

    return options
  }, [minDate, maxDate])

  return (
    <FormItem label={sectionLabel} required={required}>
      <Autocomplete
        autoComplete
        autoSelect={shouldAutoselect}
        autoHighlight
        groupBy={(opt) => opt.split(':')[0]}
        hint={hint}
        options={timeOptions}
        onChange={handleChange}
        renderGroup={(opt) => (
          <>
            {
              // NB: this is a bit out there.
              // We want the users to be able to input times at 15 minute intervals
              // but we also want to avoid the dropdown being too long.
              // So this effectively hides the intervals at 15 and 45 minutes
              // even though they're still accessible if you type it in.
              // `renderOption` didn't work unfortunately :-/
              Children.map(opt.children, (child, index) => {
                const innerChild = child.props.children // heh, love this variable name.
                if (typeof innerChild === 'string') {
                  const minutes = innerChild.split(':')[1].slice(0, 2)
                  if (['15', '45'].includes(minutes)) {
                    return null
                  }
                }
                return child
              })
            }
          </>
        )}
        shouldntGroupIfInputValue
        value={value ? moment(value, TIME_FORMAT).format(TIME_FORMAT) : null}
        {...props}
      />
    </FormItem>
  )
}
