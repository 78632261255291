import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  Switch as MuiSwitch,
} from '@material-ui/core'

import FormItem from './FormItem'
import {makeStyles} from '@material-ui/core/styles'
import styles from './styles'

const useStyles = makeStyles((theme) => ({
  small: {
    fontSize: styles.typography.fontSizeXS,
    color: styles.colors.neutral400,
  },
}))

let CHECKBOX_ID = 0

/** Simple generic checkbox. Also supports rendering as a radio button. */
function CheckboxField({
  checked,
  disabled,
  forceFormItem,
  hint,
  id,
  label,
  name,
  onChange,
  required,
  sectionLabel,
  small,
  trackingName,
  type,
}) {
  const classes = useStyles()

  const checkboxID = id ?? `mbz-checkbox-${CHECKBOX_ID++}`
  const handleChange = (evt) => {
    const checked = !!evt.currentTarget.checked
    onChange({
      name: name || '',
      value: checked,
    })
  }

  const control =
    type === 'checkbox' ? (
      <MuiCheckbox color="primary" required={required} />
    ) : type === 'radio' ? (
      <MuiRadio color="primary" required={required} />
    ) : (
      <MuiSwitch required={required} />
    )

  return (
    <FormItem
      forceFormItem={forceFormItem}
      label={sectionLabel}
      hint={hint}
      type={type}
    >
      <MuiFormControlLabel
        checked={checked}
        classes={small && {label: classes.small}}
        control={control}
        data-track={trackingName}
        disabled={disabled}
        id={checkboxID}
        label={label}
        name={name}
        onChange={handleChange}
        value={checked}
      />
    </FormItem>
  )
}

CheckboxField.defaultProps = {type: 'checkbox'}

export default CheckboxField
