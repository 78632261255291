import algoliasearch from 'algoliasearch'

function getAlgoliaClient() {
  return algoliasearch(
    window.__MLZ_ALGOLIA_APPLICATION_ID__,
    window.__MLZ_ALGOLIA_CLIENT_API_KEY__
  )
}

function getAlgoliaIndex(indexName) {
  return getAlgoliaClient().initIndex(
    `${indexName}_${window.__MLZ_ALGOLIA_INDEX_SUFFIX__}`
  )
}

async function searchAlgoliaIndex(
  indexName,
  search,
  searchFilters,
  additionalSearchOptions
) {
  // TODO(peter): we probably want to handle pagination in the future
  const algoliaResponse = await getAlgoliaIndex(indexName).search({
    query: search,
    filters: searchFilters,
    ...(additionalSearchOptions || {}),
  })
  return algoliaResponse.hits
}

export async function searchTags(
  search,
  relevantTagIds,
  currentDashboardOrgId
) {
  let additionalSearchOptions = {}
  let tagSearchFilter = ''
  if (relevantTagIds) {
    tagSearchFilter = relevantTagIds
      .map(function (tagId) {
        return 'id=' + tagId
      })
      .join(' OR ')
  } else if (currentDashboardOrgId) {
    // in the dashboard, we want orgs to primarily see tags that they've used
    // before + ones that we seed with so it's not empty to start
    if (search.length < 3) {
      // before really searching, only show official or used before
      tagSearchFilter = `mobilize_official=1 OR used_by_orgs.id=${currentDashboardOrgId}`
    } else {
      // after searching 3+ characters, just sort used before to the top but
      // show all search results
      additionalSearchOptions = {
        ...additionalSearchOptions,
        optionalFilters: [`used_by_orgs.id:${currentDashboardOrgId}`],
      }
    }
  }
  return await searchAlgoliaIndex(
    'Tag',
    search,
    tagSearchFilter,
    additionalSearchOptions
  )
}
