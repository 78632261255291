import {
  Chip as MaterialChip,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
  Select as MaterialSelect,
  FormHelperText as MuiFormHelperText,
} from '@material-ui/core'

import FormItem from './FormItem'
import {INPUT_HEIGHT} from './styles/muiTheme'
import Loader from './Loader'
import {makeStyles} from '@material-ui/core/styles'
import styled from '@emotion/styled/macro'
import styles from './styles'

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    height: INPUT_HEIGHT - 4, // 4 is just what works for chips to be the right size.
    fontFamily: styles.typography.fontFamily,
    fontSize: styles.typography.fontSizeBase,
  },
}))

const LoaderWrapper = styled.div`
  position: fixed;
  background: ${styles.colors.white};
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: ${styles.zindex.abovePage};
`

export const NONE_OPTION = {text: '(none)', value: null}

export default function Select({
  disabled,
  error,
  hint,
  label,
  loading,
  multiple,
  name,
  onChange,
  options,
  required,
  sectionLabel,
  value,
  emptyText,
}) {
  const classes = useStyles()

  let optionsMap = {}
  options.forEach(({text, value}) => (optionsMap[value || ''] = text))
  const selectOptions = options.map(({text, value}) => (
    <MaterialMenuItem key={value} value={value}>
      {text}
    </MaterialMenuItem>
  ))

  function handleChange(evt) {
    // $FlowFixMe: we want target, not currentTarget.
    const {name, value} = evt.target

    if (onChange) {
      onChange(evt, {name, value})
    }
  }

  // NB: we have some Select's that allow 'null' as values. (e.g. some VAN fields)
  // The problem with these is that it the rendering the label of the Select field
  // overlaps with the placeholder in this case causing layout madness.
  // We make an exception for the label and make it small by default if there is emptyText present
  // which indicates a possible null value.
  const allowEmptyWorkaround = !!emptyText

  // We control `shrink` and `notched` when value is null
  // because MUI doesn't handle this so well automatically.
  return (
    <FormItem label={sectionLabel}>
      <MaterialFormControl variant="outlined">
        {label && (
          <MaterialInputLabel
            error={error}
            required={required}
            shrink={allowEmptyWorkaround && !value ? true : undefined}
          >
            {label}
          </MaterialInputLabel>
        )}
        <MaterialSelect
          disabled={disabled}
          displayEmpty={allowEmptyWorkaround}
          label={label}
          multiple={multiple}
          name={name}
          notched={allowEmptyWorkaround && !value ? true : undefined}
          onChange={handleChange}
          required={required}
          renderValue={
            multiple
              ? (selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <MaterialChip
                        key={value}
                        label={optionsMap[value]}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )
              : (value) => {
                  return value ? optionsMap[value] : emptyText
                }
          }
          value={value || ''}
        >
          {selectOptions}
        </MaterialSelect>
        {hint && (
          <MuiFormHelperText variant="outlined">{hint}</MuiFormHelperText>
        )}
      </MaterialFormControl>
      {loading && (
        <LoaderWrapper>
          <Loader large />
        </LoaderWrapper>
      )}
    </FormItem>
  )
}
