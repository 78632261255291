import {Banner, Link, MaxWidthContainer} from 'components'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {DEGRADED_SERVICE_MESSAGE} from 'app/constants'
import Footer from 'app/nav/Footer'
import Header from 'app/nav/Header'
import {MainContent} from 'app/SkipToContent'
import {MessageType} from 'components/Message'
import NotificationContainer from 'app/NotificationContainer'
import OrganizationThemeProvider from 'app/OrganizationThemeProvider'
import flag from 'experiments/flag'
import {getQueryParams} from 'util/url'
import {isDistributedOrganizingDiscoverableForMaybeOrg} from 'util/organization'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useContext} from 'react'
// $FlowFixMe (mime): types need updating for latest react-router
import {useLocation} from 'react-router'

const ContentWrapper = styled.div`
  background-color: ${styles.colors.neutral100};
  padding-top: ${styles.space.l};
  padding-bottom: ${styles.space.l};
  border-top: 1px solid ${styles.colors.neutral300};
  flex-grow: 1;
`

const AppWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`

const LayoutOuter = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export default function Layout({children, maxWidth, ignoreOrgTheme}) {
  const location = useLocation()

  let {currentOrganization: organization} = useContext(
    CurrentOrganizationContext
  )

  if (ignoreOrgTheme) {
    organization = null
  }

  const contactEmail = organization ? organization.reply_to_email : ''
  const showHostEventLink = isDistributedOrganizingDiscoverableForMaybeOrg(
    organization
  )

  const getNotificationsFromQueryParams = () => {
    const initialQueryParams = getQueryParams(location.search)
    if (initialQueryParams.error === '404') {
      const body =
        initialQueryParams.reason === 'no_new_host_events'
          ? 'This form is no longer available.'
          : 'This event is no longer available. Take a look at the feed below to find other opportunities!'
      const message = {body, type: MessageType.WARNING}
      return [message]
    }
    return []
  }
  const notificationMessages = getNotificationsFromQueryParams()

  const page = maxWidth ? (
    <ContentWrapper>
      <MaxWidthContainer>{children}</MaxWidthContainer>
    </ContentWrapper>
  ) : (
    children
  )

  const degradedServiceMessage = {
    body: DEGRADED_SERVICE_MESSAGE,
    type: MessageType.WARNING,
  }
  return (
    <OrganizationThemeProvider organization={organization}>
      <LayoutOuter>
        {/* Display banner behind switch to indicate that the site is in a degraded state. */}
        {flag.isActive('kill_feed_filters') && (
          <Banner small center caution compact topOfPage>
            <div>
              Mobilize is currently experiencing high traffic, some features
              might not be available.
            </div>
          </Banner>
        )}

        {organization &&
          organization.branding.enable_brand_banner &&
          organization.branding.brand_banner_url &&
          organization.branding.brand_banner_text && (
            <Banner small center useBrandingFromTheme compact topOfPage>
              <Link
                disableBackground
                plain
                useBrandBannerTextColor
                noUnderline
                to={organization.branding.brand_banner_url}
              >
                {organization.branding.brand_banner_text}
              </Link>
            </Banner>
          )}

        <AppWrapper>
          <Header
            contactEmail={contactEmail}
            showHostEventLink={showHostEventLink}
            showHostEventLinkOnMobile={showHostEventLink}
            showSkipToContentLink
          />
          <NotificationContainer
            notificationMessages={
              flag.isActive('kill_lack_of_appwide_error_message')
                ? [degradedServiceMessage]
                : notificationMessages
            }
          />
          <MainContent>{page}</MainContent>
        </AppWrapper>
        <Footer />
      </LayoutOuter>
    </OrganizationThemeProvider>
  )
}
