import {Button, Link, MaxWidthContainer, Typography} from 'components'
import {EventsFetcherCarousel, LandingPageList} from 'events/components'

import {CarouselCategory} from 'app/enums'
import {F} from 'util/i18n'
import OrgCreationPrompt from 'events/feed/OrgCreationPrompt'
import {getOrganizationFeedUrl} from 'util/routing'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
// $FlowFixMe (mime): types need updating for latest react-router
import {useLocation} from 'react-router'

const RelatedWrapper = styled.div`
  border-top: 1px solid ${styles.colors.neutral300};
  background-color: ${styles.colors.neutral100};
`

const CenteredDiv = styled.div`
  text-align: center;
  margin-bottom: ${styles.space.xl};
`

const CAROUSELS_FOR_EVENT_OWNING_ORG = [
  CarouselCategory.HIGH_PRIORITY,
  CarouselCategory.NEAR_YOU,
  CarouselCategory.POPULAR_VIRTUAL,
  CarouselCategory.MORE_WITH_TAGS,
  CarouselCategory.POPULAR_VIRTUAL_FLEXIBLE,
]

const CAROUSELS_FOR_PROMOTED_OR_CO_OWNED = [
  CarouselCategory.MORE_FROM_EVENT_OWNING_ORG,
  ...CAROUSELS_FOR_EVENT_OWNING_ORG,
]

export default function DetailsRelated({event, organization}) {
  const location = useLocation()

  const maybePreviousFilterParams = location.state?.filterParams
  const previousFeedUrl = getOrganizationFeedUrl(
    organization,
    maybePreviousFilterParams
  )
  const carouselsToUse =
    organization.id === event.organization.id
      ? CAROUSELS_FOR_EVENT_OWNING_ORG
      : CAROUSELS_FOR_PROMOTED_OR_CO_OWNED

  const showLandingPageList = orgFlagIsActive(
    organization,
    'enable_suggested_landing_pages'
  )

  return (
    <RelatedWrapper data-testid="details_related">
      <MaxWidthContainer>
        {/* XXX(mime): reset.css gets rid of margin for first/last-child */}
        <div />
        <Typography variant="h1">
          <F
            defaultMessage="More events from {orgName}"
            values={{orgName: organization.name}}
          />
        </Typography>
        {/* XXX(mime): reset.css gets rid of margin for first/last-child */}
        <div />
      </MaxWidthContainer>

      {carouselsToUse.map((carouselCategory) => (
        <EventsFetcherCarousel
          carouselCategory={carouselCategory}
          currentEvent={event}
          key={carouselCategory}
          organization={organization}
          shouldDelayLoad
          eventSuggestionContext={`event-page-carousel-${carouselCategory}`}
          signupSource={`one_tap_carousel_event_${carouselCategory}`}
        />
      ))}

      {showLandingPageList ? (
        <>
          <LandingPageList organization={organization} />
          <OrgCreationPrompt organization={organization} />
        </>
      ) : (
        <MaxWidthContainer>
          <CenteredDiv>
            <Link plain to={previousFeedUrl}>
              <Button data-testid="see_more_events" primary>
                <F defaultMessage="See more events" />
              </Button>
            </Link>
          </CenteredDiv>
        </MaxWidthContainer>
      )}
    </RelatedWrapper>
  )
}
