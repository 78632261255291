import {
  Button,
  CheckboxField,
  Link,
  Message,
  MessageType,
  Typography,
} from 'components'
import {
  NotificationSetting,
  dashboardEmailCategories,
  emailCategoryToSubtext,
  emailCategoryToSummary,
  emailCategoryToUnsubscribeOutcome,
  eventAttendanceEmailCategories,
  eventsOwnershipEmailCategories,
} from 'app/enums'

import {F} from 'util/i18n'
import api from 'data/api'
import {getUserSettingsUrl} from 'util/routing'
import {partial} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
// $FlowFixMe (mime): types need updating for latest react-router
import {useLocation} from 'react-router'
import {useState} from 'react'
import withScreenData from 'app/withScreenData'

const SettingWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${styles.colors.neutral300};
  padding-top: ${styles.space.s};
  padding-bottom: ${styles.space.s};

  :last-child {
    border: none;
  }
`

const HeadingWrapper = styled.div`
  width: 60%;
`

const SectionWithVerticalMargin = styled.div`
  margin: ${styles.space.l} 0;
`

const EmailCategorySection = styled.div`
  margin-top: ${styles.space.xl};
`

function UnsubscribeScreen({data}) {
  const location = useLocation()
  const parsedParams = new URLSearchParams(location.search)
  const {
    email_notification_settings,
    category_unsubscribed_from: categoryUnsubscribedFrom,
    email_address: emailAddress,
    email_hash,
    person_id,
  } = data

  const [emailNotificationSettings, setEmailNotificationSettings] = useState(
    email_notification_settings
  )
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const categoryUnsubscribedFromMessage = emailCategoryToUnsubscribeOutcome(
    categoryUnsubscribedFrom
  )
  const categoryUnsubscribedFromSummary = emailCategoryToSummary(
    categoryUnsubscribedFrom,
    emailAddress
  )

  // TODO(mime): temporary for quick Spanish templates.
  if (parsedParams.get('lang') === 'es') {
    return (
      <>
        <Typography variant="h1">Suscripción cancelada con éxito</Typography>
        <Message type={MessageType.INFO}>
          Ya no recibirás este tipo de correo electrónico en {emailAddress}.
        </Message>
      </>
    )
  }

  const onSettingChange = async (category, {value}) => {
    const setting = value ? NotificationSetting.ALL : NotificationSetting.NONE
    const oldSettings = emailNotificationSettings

    const newSetting = {
      description: emailNotificationSettings[category].description,
      setting,
    }

    try {
      // Optimistically update UI; if there's an error, roll back change and render an error message
      setEmailNotificationSettings({
        ...oldSettings,
        [category]: newSetting,
      })
      await api.changeUserEmailNotificationSetting(category, {
        setting,
        person_id,
        email_hash,
      })

      setErrorMessage(null)
      setSuccessMessage('Setting updated!')
    } catch (e) {
      setEmailNotificationSettings(oldSettings)

      const errorMsg =
        e?.message ||
        'There was an error changing your notification setting. Please try again later.'

      setSuccessMessage(null)
      setErrorMessage(errorMsg)
    }
  }

  const renderTogglesForCategories = (categories) => {
    return (
      <>
        {categories.map((category) => {
          const {description, setting} = emailNotificationSettings[category]

          return (
            <SettingWrapper key={category}>
              <div>
                <span>{description}</span>
                <br />
                <Typography variant="subtitle1">
                  {/* $FlowFixMe */}
                  {emailCategoryToSubtext(category)}
                </Typography>
              </div>
              <CheckboxField
                checked={setting === NotificationSetting.ALL}
                onChange={partial(onSettingChange, category)}
                type="switch"
              />
            </SettingWrapper>
          )
        })}
      </>
    )
  }

  const renderEmailNotificationToggles = () => {
    const userEmailNotificationSettingsKeys = Object.keys(
      emailNotificationSettings
    )
    const userEventAttendanceCategories = userEmailNotificationSettingsKeys.filter(
      (en) => eventAttendanceEmailCategories.includes(parseInt(en))
    )
    const userEventOwnershipCategories = userEmailNotificationSettingsKeys.filter(
      (en) => eventsOwnershipEmailCategories.includes(parseInt(en))
    )
    const userDashboardCategories = userEmailNotificationSettingsKeys.filter(
      (en) => dashboardEmailCategories.includes(parseInt(en))
    )

    return (
      <>
        <EmailCategorySection>
          <Typography variant="h3">
            <F defaultMessage="For events you sign up for" />
          </Typography>
          {renderTogglesForCategories(userEventAttendanceCategories)}
        </EmailCategorySection>

        {userEventOwnershipCategories.length > 0 && (
          <EmailCategorySection>
            <Typography variant="h3">
              <F defaultMessage="For events you own" />
            </Typography>
            {renderTogglesForCategories(userEventOwnershipCategories)}
          </EmailCategorySection>
        )}

        {userDashboardCategories.length > 0 && (
          <EmailCategorySection>
            <Typography variant="h3">
              <F defaultMessage="About this dashboard" />
            </Typography>
            {renderTogglesForCategories(userDashboardCategories)}
          </EmailCategorySection>
        )}
      </>
    )
  }

  return (
    <>
      {/* Success and error messages. */}
      {successMessage && (
        <Message type={MessageType.SUCCESS}>{successMessage}</Message>
      )}
      {errorMessage && (
        <Message type={MessageType.ERROR}>{errorMessage}</Message>
      )}

      {/* Top level header. */}
      <SectionWithVerticalMargin>
        <HeadingWrapper>
          <Typography variant="h1">
            {categoryUnsubscribedFromMessage ? (
              categoryUnsubscribedFromMessage
            ) : (
              <F defaultMessage="You have been unsubscribed." />
            )}
          </Typography>

          <SectionWithVerticalMargin>
            <Typography variant="body1">
              {categoryUnsubscribedFromSummary || ''}
            </Typography>

            <Typography variant="body1">
              <F defaultMessage="Did you unsubscribe from these emails by mistake?" />
            </Typography>

            <Button
              secondary
              onClick={() =>
                onSettingChange(categoryUnsubscribedFrom, {value: true})
              }
            >
              <F defaultMessage="Subscribe" />
            </Button>
          </SectionWithVerticalMargin>
        </HeadingWrapper>
      </SectionWithVerticalMargin>

      {/* Email notification toggles. */}
      <SectionWithVerticalMargin>
        <Typography variant="h2">
          <F defaultMessage="Manage other email preferences" />
        </Typography>
        <Typography variant="body2">
          <F defaultMessage="Changes are immediately saved when you turn email notifications on and off." />
        </Typography>
        {renderEmailNotificationToggles()}
      </SectionWithVerticalMargin>

      <Link to={getUserSettingsUrl()}>
        <F defaultMessage="Log in to manage account settings" />
      </Link>
    </>
  )
}

export default withScreenData(UnsubscribeScreen)
