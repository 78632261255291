import {defineMessages, useIntl} from 'util/i18n'
import styles, {fontSizeSmall} from 'components/styles'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {Link} from 'components'
import cv from 'util/clientVars'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import {useContext} from 'react'

const TosMessage = styled.div`
  ${fontSizeSmall}
  margin: ${styles.space.m} 0 ${styles.space.l} 0;
  text-align: center;
`

const tosMessages = defineMessages({
  extended: {
    defaultMessage:
      "By clicking the {numOfButtons, plural, one {button} other {buttons}} above, you agree to Mobilize's <ToSLink>Terms of Service</ToSLink>, <PrivacyPolicyLink>Privacy Policy</PrivacyPolicyLink>, and <SmsTermsLink>SMS Terms</SmsTermsLink>. Message and data rates may apply. Message frequency varies. Text STOP to end, HELP for help.",
  },
  short: {
    defaultMessage:
      'By clicking the {numOfButtons, plural, one {button} other {buttons}} above, you agree to the <ToSLink>Terms of Service</ToSLink> and <PrivacyPolicyLink>Privacy Policy</PrivacyPolicyLink>.',
  },
})

export default function TermsOfService({hasMultipleButtons}) {
  const intl = useIntl()
  const {currentOrganization} = useContext(CurrentOrganizationContext)

  const shouldShowExtendedToS = orgFlagIsActive(
    currentOrganization,
    'enable_extended_mobilize_terms'
  )

  const smsTermsShortlink = 'https://mobilize.us/s/smsterms'

  const messageValues = {
    numOfButtons: hasMultipleButtons ? 2 : 1,
    ToSLink: (str) => (
      <Link to={cv.tos_url} popup>
        {str}
      </Link>
    ),
    PrivacyPolicyLink: (str) => (
      <Link to={cv.privacy_policy_url} popup>
        {str}
      </Link>
    ),
    SmsTermsLink: (str) => (
      <Link to={smsTermsShortlink} popup>
        {str}
      </Link>
    ),
  }

  return (
    <TosMessage>
      {shouldShowExtendedToS
        ? intl.formatMessage(tosMessages.extended, messageValues)
        : intl.formatMessage(tosMessages.short, messageValues)}
    </TosMessage>
  )
}
