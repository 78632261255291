import colors from './colors'
import {space} from './constants'
import typography from './typography'

export const defaultTheme = {
  style: 'default',
  // Colors
  primary_color: colors.primary200,
  link_color: colors.primary200,
  link_hover_color: colors.primary300,
  primary_text_color: colors.black,
  secondary_text_color: colors.neutral400,
  // Fonts + text
  primary_title_font: typography.fontFamilyHeader,
  primary_title_font_weight: typography.fontWeightBold,
  primary_font_size_scale: 1,
  title_text_transform: 'none',
  secondary_body_font: typography.fontFamily,
  link_text_decoration: 'none',
  link_hover_text_decoration: 'underline',
  link_font_weight: typography.fontWeightSemibold,
  link_background: 'none',
  link_hover_background: 'none',
  // Buttons
  button_border_width: '1px',
  primary_button_border_color: colors.primary200,
  primary_button_hover_border_color: colors.primary300,
  primary_button_background_color: colors.primary200,
  primary_button_hover_background_color: colors.primary300,
  primary_button_text_color: colors.white,
  primary_button_hover_text_color: colors.white,
  secondary_button_border_color: colors.neutral300,
  secondary_button_background_color: colors.white,
  secondary_button_hover_background_color: colors.neutral200,
  secondary_button_text_color: colors.black,
  secondary_button_hover_text_color: colors.black,
  secondary_button_hover_border_color: colors.neutral400,
  secondary_button_selected_background_color: colors.primary200,
  // Inputs
  input_text_color: colors.black,
  input_border_color: colors.neutral300,
  input_border_width: '1px',
  // Misc
  border_radius: space.borderRadius,
  brand_banner_background_color: colors.primary200,
  brand_banner_text_color: colors.white,
}
