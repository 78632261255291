import {Icon, Typography} from 'components'
import styles, {
  boxShadowLight,
  getBorderRadiusFromProps,
} from 'components/styles'

import {F} from 'util/i18n'
import UpcomingEventsLinks from './UpcomingEventsLinks'
import styled from '@emotion/styled/macro'

const CardWrapper = styled.div`
  text-align: center;
  margin-top: ${styles.space.l};
  margin-bottom: ${styles.space.s};
  margin-left: auto;
  margin-right: auto;
  padding: ${styles.space.l};
  background-color: ${styles.colors.white};
  ${getBorderRadiusFromProps}
  ${boxShadowLight}
`

export default function UpcomingEventsEmptyState({
  neverSignedUpBefore,
  organization,
}) {
  return (
    <CardWrapper data-testid="upcoming-events-empty-state">
      <Icon name="calendar" primary fontSize={styles.space.l} />
      <Typography variant="h3" style={{marginTop: styles.space.s}}>
        {neverSignedUpBefore ? (
          <F defaultMessage="Sign up for your first event" />
        ) : (
          <F defaultMessage="Sign up for more events" />
        )}
      </Typography>
      <UpcomingEventsLinks
        organization={organization}
        trackingLocation="empty-state"
      />
    </CardWrapper>
  )
}
