import styles, {fontSizeSmall} from 'components/styles'

import {Card} from 'components'
import styled from '@emotion/styled/macro'

const Footer = styled.p`
  color: ${styles.colors.neutral400};
  ${fontSizeSmall};
`

const SettingsCardInner = styled.div`
  ${(props) => !props.hasAction && `padding-top: ${styles.space.m};`};
  padding-bottom: ${styles.space.l};
`

const SettingsCard = ({header, action, footer, children}) => (
  <Card header={header} action={action} marginBottom>
    <SettingsCardInner hasAction={!!action}>{children}</SettingsCardInner>
    <Footer>{footer}</Footer>
  </Card>
)

export default SettingsCard
