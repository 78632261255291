const initializedIDs = new Set()
let loadedScript = false

// initialize GA for a given id/slug (slugs are to name the trackers)
function ensureInitialized(id, trackerName) {
  if (!loadedScript) {
    // prettier-ignore
    /* eslint-disable */
    ;(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    /* eslint-enable */
    loadedScript = true
  }

  if (!initializedIDs.has(id)) {
    window.ga('create', id, 'auto', trackerName, {allowLinker: true})
    initializedIDs.add(id)
  }
}

export function pageview(id, url = null) {
  // tracker names seemingly can't have hyphens, so to generate a unique tracker
  // name without too much work, just take the GA id (`UA-XXXX-YY`) and get rid
  // of the hyphens
  const trackerName = id.replace(/-/g, '')

  ensureInitialized(id, trackerName)

  if (url) {
    window.ga(`${trackerName}.send`, 'pageview', url) // artificial pageview
  } else {
    window.ga(`${trackerName}.send`, 'pageview') // derives url on its own
  }
}
