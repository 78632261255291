import {Button, Message, MessageType} from 'components'
import {useRef, useState} from 'react'

import api from 'data/api'

// N.B. Keep this amount in sync with same constant in base/settings.py and nginx limit in
// config/nginx.conf.erb
const DEFAULT_MAX_FILE_SIZE = 50 // 50 MB

function ImageUpload({resource, onChange, maximumSizeInMegabytes}) {
  const [isImageUploading, setIsImageUploading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const fileInputRef = useRef(null)

  const triggerFileInput = () => {
    const el = fileInputRef.current
    if (!(el instanceof HTMLElement)) return // for flow
    el.click()
  }

  const uploadFile = async (e) => {
    setIsImageUploading(true)
    const file = e.target.files[0]

    if (!file) {
      setIsImageUploading(false)
      setErrorMessage('')
      return
    }

    // file.size is in bytes, so convert maxSize to bytes for comparison
    if (file.size > maximumSizeInMegabytes * 1024 * 1024) {
      setIsImageUploading(false)
      setErrorMessage(
        `Please upload an image with a file size less than ${maximumSizeInMegabytes}MB.`
      )
      return
    }

    // Make sure image has a file extension.
    if (!file.type) {
      setIsImageUploading(false)
      setErrorMessage(
        'The image you provided could not be uploaded. We only accept .jpeg, .jpg, .gif and .png images.'
      )
      return
    }

    let url
    try {
      url = await api.getS3SignedURL(file.name, file.type, resource)
    } catch (error) {
      const errorMessages = error.fieldErrors
      // NB(avi): In practice, we only have one relevant error message at a time, so just pick
      // one to show
      const apiErrorMessage =
        errorMessages && errorMessages.length
          ? errorMessages[0]
          : 'Unknown error. Please try again.'
      setIsImageUploading(false)
      setErrorMessage(apiErrorMessage)
      return
    }

    try {
      await api.uploadPublicFileToS3(url, file)
    } catch (uploadError) {
      setIsImageUploading(false)
      setErrorMessage('Unknown error. Please try again.')
      return
    }

    onChange(url.split('?')[0])
    setIsImageUploading(false)
    setErrorMessage(null)
  }

  return (
    <div>
      <Button
        loading={isImageUploading}
        type="button"
        icon="cloud"
        onClick={triggerFileInput}
      >
        Upload a photo
      </Button>
      <div>
        <input
          onChange={uploadFile}
          type="file"
          ref={fileInputRef}
          style={{display: 'none'}}
        />
      </div>
      {!!errorMessage && (
        <Message topMargin type={MessageType.ERROR}>
          {errorMessage}
        </Message>
      )}
    </div>
  )
}

ImageUpload.defaultProps = {maximumSizeInMegabytes: DEFAULT_MAX_FILE_SIZE}
export default ImageUpload
