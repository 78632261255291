import {Map} from 'components'

const MapboxEventMap = ({centerLat, centerLng, height, width, initialZoom}) => {
  return (
    <Map
      height={height}
      width={width}
      longitude={centerLng}
      latitude={centerLat}
      zoom={initialZoom}
      isInteractive={false}
      geolocateControl={false}
      shouldInitiallyFitBounds={false}
      renderCenterAsMarker
    />
  )
}

export default MapboxEventMap
