import {
  ALLOWED_ACTION_TAG_ADDRESS_FIELDS,
  MOBILIZE_CONTACT_INFORMATION_FIELDS,
} from './constants'
import {Input, InputGroup} from 'components'

import MobilizeOnlineActionInput from './MobilizeOnlineActionInput'
import {pick} from 'util/common'
import {useMemo} from 'react'

const INCLUDED_FIELDS = [
  ...MOBILIZE_CONTACT_INFORMATION_FIELDS,
  ...ALLOWED_ACTION_TAG_ADDRESS_FIELDS,
]

/**
 * A specific field group for handling contact information fields because there
 * is some overlap between Mobilize fields and EveryAction OnlineAction Form
 * fields. This tries to preserve the layout of UserSignupFormFields while also
 * including potentially required EveryAction fields such as address fields.
 */
export default function ContactInformationFields({
  fields,
  formMetadata,
  isSkippingDonation,
  onChange,
  values,
}) {
  const findField = (name) => {
    return fields.find((f) => f.name === name)
  }

  // This is not necessarily an expensive operation, but useMemo is used because
  // we do not need to re-compute it.
  const otherRequiredFields = useMemo(() => {
    const remainingFields = pick(
      fields,
      (value) => !INCLUDED_FIELDS.includes(value.name) && value.required
    )
    // N.B. Typed Object.values as any to appease Flow. value.name exists.
    return Object.values(remainingFields).map((value) => value.name)
  }, [fields])

  const handleInputChange = (evt) => {
    const {name, value} = evt.target
    onChange(name, value)
  }

  const renderInputsForFields = (fieldsToCreate) => {
    return fieldsToCreate.map((fieldName) => {
      const maybeField = findField(fieldName)
      return (
        maybeField?.required && (
          <MobilizeOnlineActionInput
            key={fieldName}
            formMetadata={formMetadata}
            input={maybeField}
            onChange={onChange}
            value={values[fieldName] || ''}
          />
        )
      )
    })
  }

  return (
    <>
      <InputGroup>
        <Input
          name="FirstName"
          type="text"
          label="First name"
          required
          value={values.FirstName || ''}
          onChange={handleInputChange}
          maxLength={findField('FirstName')?.maxlength}
        />
        <Input
          name="LastName"
          type="text"
          label="Last name"
          required
          value={values.LastName || ''}
          onChange={handleInputChange}
          maxLength={findField('LastName')?.maxlength}
        />
        {/* A user could potentially make Prefix, MiddleName, and Suffix
          required fields, but this is unlikely, so exclude them for now. */}
      </InputGroup>

      <Input
        name="EmailAddress"
        type="email"
        label="Email"
        fluid
        required
        value={values.EmailAddress || ''}
        onChange={handleInputChange}
        // This is likely more restrictive than the Mobilize maxLength of 254.
        maxLength={findField('EmailAddress')?.maxlength}
      />

      {
        // Insert any other required address type fields before the PostalCode field.
        !isSkippingDonation &&
          renderInputsForFields(ALLOWED_ACTION_TAG_ADDRESS_FIELDS)
      }

      <InputGroup>
        {/* Differs from SignupForm where MobilePhone is ordered before ZIP.
        This is so it logically flows from other address fields.  */}
        <Input
          isZIPCode
          name="PostalCode"
          label="ZIP code"
          required
          value={values.PostalCode || ''}
          onChange={handleInputChange}
          // Limit this to Mobilize 5 chars even though EveryAction allows 9.
        />
        <Input
          name="MobilePhone"
          // Use our own validations since we don't have access to the ActionTag
          // validation rules for phone fields.
          type="tel"
          label="Mobile number"
          required
          value={values.MobilePhone || ''}
          onChange={handleInputChange}
        />
      </InputGroup>

      <>{renderInputsForFields(otherRequiredFields)}</>
    </>
  )
}
