import {
  ACCESSIBILITY_FEATURE_VALUES,
  CountryCodeToDisplayName,
  UsState,
} from 'app/enums'
import {EMPTY_FILTER_PARAMS, LOCATION_BASED_FILTER_PARAMS} from 'app/constants'
import {formatQueryString, getQueryParams} from './url'
import {isEqual, normalizeQueryParamValueToStringList} from 'util/common'
import {parseOrgTypes, parseRaceTypes} from './organization'

import {isValidUSZipcode} from './geo'
import moment from 'vendor/moment'
import {parseEventTypes} from './event'

export function filterParamsPartialToQueryParams(
  filterParamsPartial,
  initialQueryParams = {} // Any query params to begin with
) {
  return filterParamsToQueryParams(
    {...EMPTY_FILTER_PARAMS, ...filterParamsPartial},
    initialQueryParams
  )
}

export function filterParamsToQueryParams(
  filterParams,
  initialQueryParams = {} // Any query params to begin with
) {
  const parsedSearch = {...initialQueryParams}

  if (filterParams.zip) {
    parsedSearch.zip = filterParams.zip
  }
  if (filterParams.address && (filterParams.lat || filterParams.lon)) {
    parsedSearch.address = filterParams.address
  }
  if (filterParams.lat) {
    parsedSearch.lat = filterParams.lat
  }
  if (filterParams.lon) {
    parsedSearch.lon = filterParams.lon
  }
  if (filterParams.date) {
    parsedSearch.date = filterParams.date
  }
  if (filterParams.startDate) {
    parsedSearch.start_date = filterParams.startDate
  }
  if (filterParams.endDate) {
    parsedSearch.end_date = filterParams.endDate
  }
  if (filterParams.eventTypes.length) {
    parsedSearch.event_type = filterParams.eventTypes
  }
  if (filterParams.orgTypes.length) {
    parsedSearch.org_type = filterParams.orgTypes
  }
  if (filterParams.raceTypes.length) {
    parsedSearch.race_type = filterParams.raceTypes
  }
  if (filterParams.selectedOrgs?.length) {
    parsedSearch.org_ids = filterParams.selectedOrgs
  }
  if (filterParams.tagIds?.length) {
    parsedSearch.tag_ids = filterParams.tagIds
  }
  if (filterParams.maxDist && filterParams.maxDist !== Infinity) {
    parsedSearch.max_dist = filterParams.maxDist
  }
  if (typeof filterParams.isVirtual === 'boolean') {
    parsedSearch.is_virtual = filterParams.isVirtual
  }
  if (typeof filterParams.isVirtualFlexible === 'boolean') {
    parsedSearch.is_virtual_flexible = filterParams.isVirtualFlexible
  }
  if (typeof filterParams.isHighPriority === 'boolean') {
    parsedSearch.is_high_priority = filterParams.isHighPriority
  }
  if (typeof filterParams.isPrimary === 'boolean') {
    parsedSearch.is_primary = filterParams.isPrimary
  }
  if (typeof filterParams.isStatewide === 'boolean') {
    parsedSearch.is_statewide = filterParams.isStatewide
  }
  if (filterParams.state) {
    parsedSearch.state = filterParams.state
  }
  if (filterParams.country) {
    parsedSearch.country = filterParams.country
  }
  if (filterParams.q) {
    parsedSearch.q = filterParams.q
  }
  if (filterParams.volunteerHostedOnly) {
    parsedSearch.volunteer_hosted_only = true
  }
  if (filterParams.accessibleOnly) {
    parsedSearch.accessible_only = true
  }
  if (filterParams.accessibilityFeatures.length) {
    parsedSearch.accessibility_features = filterParams.accessibilityFeatures
  }
  if (filterParams.eventCampaign) {
    parsedSearch.event_campaign = filterParams.eventCampaign
  }
  if (filterParams.showAllEvents) {
    parsedSearch.show_all_events = true
  }
  if (filterParams.primaryLocale) {
    parsedSearch.primary_locale = filterParams.primaryLocale
  }
  if (filterParams.suggestingGroups?.length) {
    parsedSearch.suggesting_group_ids = filterParams.suggestingGroups
  }
  if (filterParams.owningGroups?.length) {
    parsedSearch.owning_group_ids = filterParams.owningGroups
  }
  if (filterParams.promotableFor) {
    parsedSearch.promotable_for = filterParams.promotableFor
  }

  return parsedSearch
}

export function maybeFilterParamsPartialToQueryString(
  filterParamsPartial,
  initialQueryParams = {} // Any query params to begin with
) {
  return filterParamsToQueryString(
    {
      ...EMPTY_FILTER_PARAMS,
      ...filterParamsPartial, // Expanding a null is like expanding {}
    },
    initialQueryParams
  )
}

export function filterParamsToQueryString(
  filterParams,
  initialQueryParams = {} // Any query params to begin with
) {
  return formatQueryString(
    filterParamsToQueryParams(filterParams, initialQueryParams)
  )
}

function parseAccessibilityFeatures(maybeAccessibilityFeatures) {
  // $FlowFixMe - Flow can't figure out the Set thing here
  return maybeAccessibilityFeatures.filter((af) =>
    // $FlowFixMe - ditto above
    ACCESSIBILITY_FEATURE_VALUES.has(af)
  )
}

export function queryStringToFilterParams(queryString) {
  const queryParams = getQueryParams(queryString)

  const q = queryParams.q || null
  const maxDist = Number(queryParams.max_dist) || Infinity
  const date = moment(queryParams.date, moment.ISO_8601).isValid()
    ? queryParams.date
    : null
  // if for whatever reason we get both `date` and `start_date`/`end_date`,
  // `date` takes precedence
  const startDate =
    !date && moment(queryParams.start_date, moment.ISO_8601).isValid()
      ? queryParams.start_date
      : null
  const endDate =
    !date && moment(queryParams.end_date, moment.ISO_8601).isValid()
      ? queryParams.end_date
      : null
  const zip = isValidUSZipcode(queryParams.zip || '') ? queryParams.zip : ''
  const lat = Number(queryParams.lat) || null
  const lon = Number(queryParams.lon) || null
  // Allow specifying: ?event_type=1, ?event_type=canvass, ?event_type=CANVASS
  // See also `api.serializers.EventFilterParamSerializer`
  const eventTypes = parseEventTypes(
    normalizeQueryParamValueToStringList(queryParams.event_type)
  )
  const orgTypes = parseOrgTypes(
    normalizeQueryParamValueToStringList(queryParams.org_type)
  )
  const raceTypes = parseRaceTypes(
    normalizeQueryParamValueToStringList(queryParams.race_type)
  )
  const accessibilityFeatures = parseAccessibilityFeatures(
    normalizeQueryParamValueToStringList(queryParams.accessibility_features)
  )

  const selectedOrgs = queryParamToArrayOfInts(queryParams.org_ids)
  const tagIds = queryParamToArrayOfInts(queryParams.tag_ids)
  const promotableFor = parseInt(queryParams.promotable_for, 10) || null

  const state = queryParams.state || null
  const country = queryParams.country || null
  const volunteerHostedOnly = queryParams.volunteer_hosted_only === 'true'
  const accessibleOnly = queryParams.accessible_only === 'true'
  const eventCampaign = queryParams.event_campaign || null
  const showAllEvents = queryParams.show_all_events === 'true'
  const primaryLocale = queryParams.primary_locale || null

  const suggestingGroups = queryParamToArrayOfInts(
    queryParams.suggesting_group_ids
  )
  const owningGroups = queryParamToArrayOfInts(queryParams.owning_group_ids)

  let address = queryParams.address || null
  if (!address && !lat && !lon) {
    // Fill in a good display name for the location search input from state or country filters, if
    // this appears not to be a geo-radius search
    if (state) {
      address = UsState[state]
    } else if (country) {
      address = CountryCodeToDisplayName[country]
    }
  }

  const isVirtual = maybeParseBool(queryParams.is_virtual)
  const isVirtualFlexible = maybeParseBool(queryParams.is_virtual_flexible)
  const isHighPriority = maybeParseBool(queryParams.is_high_priority)
  const isPrimary = maybeParseBool(queryParams.is_primary)
  const isStatewide = maybeParseBool(queryParams.is_statewide)

  return {
    accessibilityFeatures,
    accessibleOnly,
    address,
    country,
    date,
    endDate,
    eventCampaign,
    eventTypes,
    isHighPriority,
    isPrimary,
    isVirtual,
    isVirtualFlexible,
    isStatewide,
    lat,
    lon,
    maxDist,
    orgTypes,
    q,
    raceTypes,
    selectedOrgs,
    showAllEvents,
    startDate,
    state,
    tagIds,
    volunteerHostedOnly,
    zip,
    primaryLocale,
    suggestingGroups,
    owningGroups,
    promotableFor,
  }
}

// 'true' => true, 'false' => false, anything else => null
const maybeParseBool = (param) =>
  param === 'true' ? true : param === 'false' ? false : null

function queryParamToArrayOfInts(queryParam) {
  return (Array.isArray(queryParam) ? queryParam : [queryParam])
    .filter((maybeIntString) => !!parseInt(maybeIntString, 10))
    .map((intString) => parseInt(intString, 10))
}

export function maybeGetSignupIdentityFieldsFromCurrentVolunteer(
  currentVolunteer
) {
  const {
    firstName,
    lastName,
    email,
    zip,
    phone,
    addressLine1,
    addressLine2,
    city,
    state,
  } = currentVolunteer
  // TODO: If we need the street address fields to also be filled out, we should extend this to
  // optionally check that the street address fields (except addressLine2) are also not empty.
  if (!firstName || !lastName || !email || !zip || !phone) {
    return null
  }
  return {
    firstName,
    lastName,
    email,
    zip,
    phone,
    addressLine1: addressLine1 || '',
    addressLine2: addressLine2 || '',
    city: city || '',
    state: state || '',
  }
}

export function isFiltered(filterParams) {
  return !isEqual(filterParams, EMPTY_FILTER_PARAMS)
}

export function maybeGetSingleSelectedOrgName(filterParams, events) {
  if (filterParams.selectedOrgs.length !== 1) {
    return null
  }
  const selectedOrgId = filterParams.selectedOrgs[0]
  const maybeEventBySelectedOrg = events.find(
    (e) => e.organization.id === selectedOrgId
  )
  return maybeEventBySelectedOrg
    ? maybeEventBySelectedOrg.organization.name
    : null
}

const FILTERS_ALWAYS_EXCLUDED_FROM_COUNT = ['showAllEvents']

export function getFilterCount(filterParams, {excludeFilters = []} = {}) {
  const excludeFiltersSet = new Set([
    ...excludeFilters,
    ...FILTERS_ALWAYS_EXCLUDED_FROM_COUNT,
  ])
  return Object.keys(filterParams).reduce(
    (acc, key) => {
      const value = filterParams[key]
      if (
        !excludeFiltersSet.has(key) &&
        !isEqual(value, EMPTY_FILTER_PARAMS[key])
      ) {
        const isLocationParam = LOCATION_BASED_FILTER_PARAMS.has(key)
        if (isLocationParam && acc.locationAccountedFor) {
          return acc
        }
        return {
          count: acc.count + (Array.isArray(value) ? value.length : 1),
          locationAccountedFor: acc.locationAccountedFor || isLocationParam,
        }
      }
      return acc
    },
    {count: 0, locationAccountedFor: false}
  ).count
}
