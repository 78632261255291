import {MaxWidthContainer, Typography} from 'components'

import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const HeaderContainer = styled.div`
  padding-bottom: ${styles.space.l};
  margin-bottom: ${styles.space.l};
`

export default function FeedHeader({header, description}) {
  if (!header) {
    return null
  }
  return (
    <HeaderContainer>
      <MaxWidthContainer marginTop={styles.space.l}>
        <Typography variant="h1">{header}</Typography>
        {description && <Typography variant="body1">{description}</Typography>}
      </MaxWidthContainer>
    </HeaderContainer>
  )
}
