import {CardModal, Icon, Typography} from 'components'
import {Children, cloneElement, isValidElement, useState} from 'react'
import {defineMessages, useIntl} from 'util/i18n'

import {css} from '@emotion/react'
import {range} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const messages = defineMessages({
  skip: {defaultMessage: 'Skip'},
  done: {defaultMessage: 'Done'},
  steps: {defaultMessage: 'Step {currentStep} of {totalSteps}'},
})

const Steps = styled.div`
  display: flex;
  justify-content: center;
  margin: ${styles.space.m} auto;
`

const isPastStep = (props) => props.step < props.currentStep
const STEP_INNER_CLASSNAME = 'ChainingModalStepper-Step-inner'
const Step = styled(({step, currentStep, ...props}) => (
  <span {...props}>
    <span className={STEP_INNER_CLASSNAME}>
      {isPastStep({step, currentStep}) ? (
        <Icon
          noMargin
          name="check"
          // Looks a little too high visually by default, so scoot down ever so slightly
          style={{position: 'relative', top: '2px'}}
        />
      ) : (
        step
      )}
    </span>
  </span>
))`
  // Vertically center the lines and bubbles
  display: inline-flex;
  align-items: center;

  // Lines
  &:not(:last-of-type)::after {
    content: '';
    display: inline-block;
    width: ${styles.space.l};
    height: 1px;
    border-top: 1px solid
      ${(props) =>
        isPastStep(props)
          ? styles.colors.success200
          : styles.colors.neutral300};
    margin: 0 ${styles.space.xs};
  }

  // Bubbles
  .${STEP_INNER_CLASSNAME} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: ${styles.colors.neutral300};
    color: ${styles.colors.neutral300};
    ${(props) => {
      if (isPastStep(props)) {
        return css`
          border: 1px solid ${styles.colors.success200};
          background-color: ${styles.colors.success200};
          color: ${styles.colors.white};
        `
      }
      const borderAndTextColor =
        props.step === props.currentStep
          ? styles.colors.success200
          : styles.colors.neutral300
      return css`
        border: 1px solid ${borderAndTextColor};
        color: ${borderAndTextColor};
      `
    }};
  }
`

function ModalSteps({currentStep, totalSteps, headline}) {
  const intl = useIntl()
  return (
    <div>
      <Typography
        center
        uppercase
        variant="h4"
        color={styles.colors.neutral400}
      >
        {headline}
      </Typography>
      <Steps
        role="img"
        data-testid="steps"
        alt={intl.formatMessage(messages.steps, {
          currentStep: intl.formatNumber(currentStep),
          totalSteps: intl.formatNumber(totalSteps),
        })}
      >
        {range(1, totalSteps + 1).map((step) => (
          <Step key={step} step={step} currentStep={currentStep} />
        ))}
      </Steps>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

function getCurrentDisplayStep({modalIndex, shouldIncrementDisplayCount}) {
  // we add 2 always (1 because it's 1-indexed, so as to make the props better correspond with the
  // display state of the component, and 1 more because people have always completed an action
  // before getting here (e.g., signing up, confirming, etc.)
  const baseAmount = 2
  // We allow incrementing the step count by one more to account for cases like in signup chaining,
  // where if the user has signed up for all the timeslots we asked them to, then increment the
  // display counter by 1 (but importantly, not the `modalIndex` itself)
  const completedIndex = shouldIncrementDisplayCount
    ? modalIndex + 1
    : modalIndex
  return baseAmount + completedIndex
}

export default function ModalStepper(props) {
  const {
    children,
    isOpen,
    shouldIncrementDisplayCount,
    contextHeadline,
    onRequestClose,
  } = props
  const [modalIndex, setModalIndex] = useState(0)
  const intl = useIntl()
  const childrenArray = Children.toArray(children).filter(Boolean)

  let closeText = null
  if (childrenArray.length > 1) {
    closeText =
      modalIndex < childrenArray.length - 1
        ? intl.formatMessage(messages.skip)
        : intl.formatMessage(messages.done)
  }
  const nextModal = () => {
    if (modalIndex >= childrenArray.length - 1) {
      onRequestClose()
    } else {
      setModalIndex(modalIndex + 1)
    }
  }

  if (!childrenArray.length) {
    return null
  }

  const modalContent = childrenArray[modalIndex]

  return (
    <CardModal
      isOpen={isOpen}
      onRequestClose={nextModal}
      closeText={closeText}
      data-testid="modal-stepper"
      wide
    >
      <Container>
        <ModalSteps
          currentStep={getCurrentDisplayStep({
            shouldIncrementDisplayCount,
            modalIndex,
          })}
          // We always count the action that brought you here as a "step" and display it as
          // already completed (see comment on `getCurrentDisplayStep`)
          totalSteps={childrenArray.length + 1}
          headline={contextHeadline}
        />
        {isValidElement(modalContent)
          ? cloneElement(modalContent, {onRequestClose: nextModal})
          : modalContent}
      </Container>
    </CardModal>
  )
}
