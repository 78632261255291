import {mediaMinWidthSmall} from './styles'
import styled from '@emotion/styled/macro'

/** Utility component to hide contents below small breakpoint */
const HideBelowSmallScreen = styled.div`
  display: none;
  ${mediaMinWidthSmall('display: block;')};
`

export default HideBelowSmallScreen
