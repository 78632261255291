import {
  bootZendesk,
  installSlaaskSnippet,
  installZendeskSnippet,
  shutdownZendeskIfPresent,
  zendeskIsPresent,
} from './chatSnippetUtil'
import {
  initializeIntercom,
  shutdownIntercomIfPresentAndClearLoadedAppId,
} from './intercomSnippet'

import {OrgDomain} from 'app/enums'
import {logError} from 'util/common'

export const ChatSnippetInitializationMode = {
  // Note that client snippets may be Intercom, Zendesk, or Slaask.
  USE_MOBILIZE_INTERCOM_ONLY_FOR_ORG_STAFF_BUT_PREFER_CLIENT_CHAT_SNIPPET_FOR_HOSTS: 1,
  USE_CLIENT_CHAT_SNIPPET_ONLY: 2,
  USE_CLIENT_CHAT_SNIPPET_IF_PRESENT_BUT_FALLBACK_TO_MOBILIZE_INTERCOM: 3,
}

const shutdownAllChatSnippets = () => {
  // As we learn how to shut down the different snippets,
  // add them here.
  shutdownIntercomIfPresentAndClearLoadedAppId()
  shutdownZendeskIfPresent()
}

const handleSlaaskInitialization = (slaaskAppId) => {
  shutdownAllChatSnippets()
  installSlaaskSnippet(slaaskAppId)
}

const handleZendeskInitialization = (zendeskSubdomain) => {
  // NB: This function cannot currently handle switching between different orgs'
  // zendesk snippets.
  shutdownIntercomIfPresentAndClearLoadedAppId()

  if (zendeskIsPresent()) {
    bootZendesk()
    return
  }

  installZendeskSnippet(zendeskSubdomain)
}

const handleIntercomInitialization = (
  appId,
  user,
  orgIsIndependent,
  mobilizeIntercomAppId
) => {
  shutdownZendeskIfPresent()
  initializeIntercom(appId, user, orgIsIndependent, mobilizeIntercomAppId)
}

export const configureChatSnippetForPage = (
  data,

  maybeChatSnippetInitializationMode
) => {
  const {user, current_organization} = data

  if (!current_organization) {
    // If we're on a page without org context, shut down.
    shutdownAllChatSnippets()
    return
  }

  const {
    mobilize_intercom_app_id: mobilizeIntercomAppId,
    intercom_app_id: orgIntercomAppId,
    zendesk_subdomain: orgZendeskSubdomain,
    slaask_app_id: orgSlaaskAppId,
  } = current_organization

  const orgIsIndependent =
    current_organization.org_domain === OrgDomain.ELECTORAL_INDEPENDENT

  if (!mobilizeIntercomAppId) {
    // If we don't have a properly initialized current_organization, and therefore don't have
    // enough info to decide which snippet to display, shut down.
    shutdownAllChatSnippets()
    return
  }
  if (!maybeChatSnippetInitializationMode) {
    // If this screen doesn't call for loading a chat snippet at all, shut down.
    shutdownAllChatSnippets()
    return
  }
  const numClientSnippetKeysProvided = [
    orgIntercomAppId,
    orgZendeskSubdomain,
    orgSlaaskAppId,
  ].filter(Boolean).length
  if (numClientSnippetKeysProvided > 1) {
    logError(
      `${current_organization.name} (${current_organization.id}) has multiple client chat widgets configured. Please remove all but one.`
    )
    shutdownAllChatSnippets()
    return
  }
  if (
    maybeChatSnippetInitializationMode ===
      ChatSnippetInitializationMode.USE_CLIENT_CHAT_SNIPPET_ONLY &&
    numClientSnippetKeysProvided === 0
  ) {
    // If we are supposed to be loading a client snippet but don't have one, shut down.
    shutdownAllChatSnippets()
    return
  }

  const isMemberWithPreferMobilizePermission = !!(
    current_organization.user_org_permissions &&
    current_organization.user_org_permissions
      .can_prefer_mobilize_intercom_to_client_chat_snippet_in_dashboard
  )

  const useClientSnippetIfPresent =
    (maybeChatSnippetInitializationMode ===
      ChatSnippetInitializationMode.USE_MOBILIZE_INTERCOM_ONLY_FOR_ORG_STAFF_BUT_PREFER_CLIENT_CHAT_SNIPPET_FOR_HOSTS &&
      !isMemberWithPreferMobilizePermission) ||
    maybeChatSnippetInitializationMode ===
      ChatSnippetInitializationMode.USE_CLIENT_CHAT_SNIPPET_ONLY ||
    maybeChatSnippetInitializationMode ===
      ChatSnippetInitializationMode.USE_CLIENT_CHAT_SNIPPET_IF_PRESENT_BUT_FALLBACK_TO_MOBILIZE_INTERCOM

  if (!useClientSnippetIfPresent || numClientSnippetKeysProvided === 0) {
    handleIntercomInitialization(
      mobilizeIntercomAppId,
      user,
      orgIsIndependent,
      mobilizeIntercomAppId
    )
  } else if (useClientSnippetIfPresent && orgIntercomAppId) {
    handleIntercomInitialization(
      orgIntercomAppId,
      user,
      orgIsIndependent,
      mobilizeIntercomAppId
    )
  } else if (useClientSnippetIfPresent && orgZendeskSubdomain) {
    handleZendeskInitialization(orgZendeskSubdomain)
  } else if (useClientSnippetIfPresent && orgSlaaskAppId) {
    handleSlaaskInitialization(orgSlaaskAppId)
  }
}
