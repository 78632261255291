import {F, strongElement} from 'util/i18n'
import {Icon, Link, Typography} from 'components'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {EventRegistrationMode} from 'app/enums'
import {HostFormInstructions} from './HostCreateEventComponents'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useContext} from 'react'

const DonationInfoWrapper = styled.div`
  display: flex;
  margin-bottom: ${styles.space.m};
`

const BottomMarginZeroWrapper = styled.div`
  margin-bottom: 0;
`

export default function DonationHostedEvent({
  contactEmail,
  hostedEvent,
  isDonationCampaign,
  mailtoLink,
}) {
  const orgContext = useContext(CurrentOrganizationContext)
  const org = orgContext?.currentOrganization
  return (
    <>
      <DonationInfoWrapper>
        <Typography variant="body2">
          <Icon name="hand-holding-heart" />
        </Typography>
        <div>
          <BottomMarginZeroWrapper>
            <Typography variant="body2">
              <F
                defaultMessage="Raising money for {orgName}."
                values={{orgName: org?.name}}
              />
            </Typography>
          </BottomMarginZeroWrapper>
          {!isDonationCampaign && (
            <Typography variant="body2">
              {hostedEvent.registration_mode ===
              EventRegistrationMode.DONATE_TO_RSVP_OPTIONAL ? (
                <F
                  defaultMessage="Supporters can select a donation amount when they sign up, but it is <strong>not required</strong>."
                  values={strongElement('donation_hosted_event_not_required')}
                />
              ) : (
                <F
                  defaultMessage="Supporters are <strong>required</strong> to select a donation amount when they sign up."
                  values={strongElement('donation_hosted_event_required')}
                />
              )}
            </Typography>
          )}
        </div>
      </DonationInfoWrapper>
      <HostFormInstructions data-testid="donation_hosted_event_instructions">
        <Typography variant="body1">
          {isDonationCampaign ? (
            <F defaultMessage="Thanks for volunteering to raise money!" />
          ) : (
            <F defaultMessage="Thanks for volunteering to host a fundraising event!" />
          )}{' '}
          <F defaultMessage="Your efforts will help advance our mission." />{' '}
          {contactEmail && (
            <>
              <F defaultMessage="If you have any questions or need help, " />
              <Link to={mailtoLink} target="_blank">
                <F defaultMessage="contact us" />
              </Link>
              .
            </>
          )}
        </Typography>
      </HostFormInstructions>
    </>
  )
}
