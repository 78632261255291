import {
  EventCampaignStatus,
  ORG_TYPE_INT_VALUES,
  RACE_TYPE_INT_VALUES,
} from 'app/enums'

import cv from 'util/clientVars'
import {remapImageUrlToProxy} from 'util/image'
import waffle from 'experiments/flag'

export function maybeGetPlaceholderLogo() {
  let placeholderUrl, placeholderSrcSet
  // todo(kayvon): move these placeholder images into the repo and let django collect them to s3
  const baseUrl = `${cv.s3_static_hostname}/static/images/org-placeholders/default-org-icon`
  const image1x = remapImageUrlToProxy(`${baseUrl}.png`)
  const image2x = remapImageUrlToProxy(`${baseUrl}@2x.png`)
  placeholderUrl = image1x
  placeholderSrcSet = `${image1x}, ${image2x} 2x`
  return {placeholderUrl, placeholderSrcSet}
}

// Keep in sync with `Organization#is_distributed_organizing_discoverable`
export function isDistributedOrganizingDiscoverableForMaybeOrg(organization) {
  return !!(
    organization &&
    orgFlagIsActive(organization, 'enable_distributed_organizing') &&
    organization.enable_dist_org_create_event_discoverability
  )
}

// Use for contexts like the host event feed item where an active default EC
// is actually required for the feature
export function hasActiveFeaturedEventCampaign(organization) {
  return !!(
    isDistributedOrganizingDiscoverableForMaybeOrg(organization) &&
    organization?.default_event_campaign?.event_campaign_status ===
      EventCampaignStatus.ACTIVE
  )
}

export function hasValidDonationPostSignupAskConfig(organization) {
  return !!organization.donation_url
}

export function hasVanKeyIssue(organization) {
  return (
    waffle.isActive('enable_van_key_issue_warnings') &&
    (organization.has_invalid_van_key || organization.has_expired_van_key)
  )
}

export function hasVanIssue(organization) {
  // For now we're only checking for VAN API key issues, but we can add more things over time
  return hasVanKeyIssue(organization)
}

export function orgFlagIsActive(organization, name) {
  if (organization && organization.flags != null) {
    return !!organization.flags[name]
  }
  return false
}

export function hasFecDisclaimer(organization) {
  return !!organization.branding.fec_disclaimer_html
}

export function parseOrgTypes(maybeOrgTypes) {
  // $FlowFixMe - Flow can't figure out the Set thing here
  return maybeOrgTypes.map(Number).filter((n) => ORG_TYPE_INT_VALUES.has(n))
}

export function parseRaceTypes(maybeRaceTypes) {
  // $FlowFixMe - Flow can't figure out the Set thing here
  return maybeRaceTypes.map(Number).filter((n) => RACE_TYPE_INT_VALUES.has(n))
}
