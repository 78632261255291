import {Notification} from 'components'
import {partial} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useState} from 'react'

const Container = styled.div`
  max-width: ${styles.limits.maxWidth};
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: ${styles.zindex.notificationContainer};
`

export default function NotificationContainer({notificationMessages}) {
  const [notificationMessageList, setNotificationMessageList] = useState(
    notificationMessages || []
  )

  const removeNotificationAtIndex = (index) => {
    setNotificationMessageList([
      ...notificationMessageList.slice(0, index),
      ...notificationMessageList.slice(index + 1),
    ])
  }

  return (
    <Container>
      {notificationMessageList.map((message, i) => (
        <Notification
          key={i}
          message={message}
          onClose={partial(removeNotificationAtIndex, i)}
        />
      ))}
    </Container>
  )
}
