import {BackendSearchSelect} from 'components'
import {filterFalsy} from 'util/common'
import {searchTags} from 'vendor/algolia'
import {useState} from 'react'

export default function TagSearchDropdown({
  selectedTags,
  orgTagIds,
  currentDashboardOrgId,
  onSelectedTagsChanged,
  label,
  allowCreate = false,
  showLabelInsteadOfName = false,
  maxSelectedTags,
  ...props
}) {
  // We keep selectedTagNames in state b/c BackendSearchSelect's `value` prop needs to not
  // change with each state, otherwise you'll get a wonky Autocomplete.
  const [selectedTagNames, setSelectedTagNames] = useState(
    selectedTags.map((tag) => tag.name)
  )
  const [isLoading, setIsLoading] = useState(false)
  const [availableTags, setAvailableTags] = useState([])

  const atMaxSelected =
    !!maxSelectedTags && selectedTags.length >= maxSelectedTags

  async function fetchTags(searchQuery) {
    setIsLoading(true)
    try {
      const fetchedTags = await searchTags(
        searchQuery,
        orgTagIds,
        currentDashboardOrgId
      )
      setAvailableTags(fetchedTags)
    } catch (err) {
      console.error(err)
    }
    setIsLoading(false)
  }

  const onChange = (evt, {value: tagNames}) => {
    const newSelectedTags = filterFalsy(
      tagNames.map(
        (tagName) =>
          selectedTags.find((tag) => tag.name === tagName) ||
          availableTags.find((tag) => tag.name === tagName)
      )
    )
    if (atMaxSelected && newSelectedTags.length >= selectedTags.length) {
      // allow removing but not adding when at max
      return
    }
    onSelectedTagsChanged(newSelectedTags)
    setSelectedTagNames(newSelectedTags.map((tag) => tag.name))
  }

  const onAddItem = (evt, {value}) => {
    if (selectedTags.findIndex((tag) => tag.name === value) >= 0) {
      // don't let people add the same tag multiple times
      return
    }
    if (atMaxSelected) {
      return
    }
    const newTags = [...selectedTags, {name: value}]
    // $FlowFixMe we know these tags have ids; TODO(mime) consider breaking out a separate type
    onSelectedTagsChanged(newTags)
    setSelectedTagNames(newTags.map((tag) => tag.name))
  }

  const tagDisplayText = (tag) => {
    return showLabelInsteadOfName
      ? tag.event_create_form_checkbox_label || tag.name
      : tag.name
  }
  const tagsToDropdownOptions = (tags) => {
    return tags.map((tag) => ({
      text: tagDisplayText(tag),
      value: tag.name,
    }))
  }
  const dropdownOptions = atMaxSelected
    ? [
        ...tagsToDropdownOptions(selectedTags),
        {
          text:
            '⚠️ Only three tags are allowed per event. Remove one to add another.',
          value: 'adding_disabled',
        },
      ]
    : [
        ...tagsToDropdownOptions(selectedTags),
        ...tagsToDropdownOptions(availableTags),
      ]

  return (
    <BackendSearchSelect
      {...props}
      options={dropdownOptions}
      loading={isLoading}
      value={selectedTagNames}
      onChange={onChange}
      getFilteredOptions={fetchTags}
      creatable={allowCreate && !atMaxSelected}
      onAddItem={onAddItem}
      multiple
      label={label}
    />
  )
}
