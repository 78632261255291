import {Card} from 'components'
import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {F} from 'util/i18n'
import {ReferrerBannerVariant} from 'app/enums'
import {getEventTypeAffinityChecks} from 'util/event'
import {isAtCapacity} from 'util/timeslot'
import {maybeStringToMaybeInt} from 'util/string'
import moment from 'vendor/moment'
import {useContext} from 'react'
import {useExperimentAllocation} from 'hooks/experiments'

const ReferrerBanner = ({
  event,
  maybeName,
  maybeTimeslot,
  timezone,
  variant,
  useDonationCopy,
  useGroupCopy,
  isGroupInvite,
  groupIsFull,
}) => {
  const {currentOrganization} = useContext(CurrentOrganizationContext)

  const maybeReferrerStart =
    maybeTimeslot && moment(maybeTimeslot.start).tz(timezone)
  const maybeReferrerStartDay =
    maybeReferrerStart && maybeReferrerStart.format('ddd, MMM D')
  const maybeReferrerStartTime =
    maybeReferrerStart && maybeReferrerStart.format('h:mma z')
  const maybeReferrerOrGroupName =
    isGroupInvite && maybeName ? `${maybeName}'s group` : maybeName

  const actionType = useDonationCopy
    ? 'fundraiser'
    : useGroupCopy
    ? 'group'
    : 'event'
  const askCopy = useDonationCopy
    ? 'Donate now!'
    : useGroupCopy
    ? 'Join now!'
    : 'Get involved by signing up!'
  const friendAction = useGroupCopy ? 'invited you to' : 'picked'

  const allocation = useExperimentAllocation('invitee_banner_copy')?.variant
    .name

  const allocationWithConditions = (allocation) => {
    if (allocation === 'social_pressure') {
      // event is more than or equal to 40% full
      const eventIsFillingUp =
        maybeTimeslot &&
        maybeTimeslot.remaining_spots &&
        maybeTimeslot.max_attendees &&
        maybeTimeslot.remaining_spots / maybeTimeslot.max_attendees <= 0.6

      // OR if more than 10 people have signed up in the last 24 hours
      const eventIsPopular = event.total_recent_signups > 10

      if (eventIsFillingUp || eventIsPopular) {
        return 'social_pressure'
      }
      return // will hit default
    } else if (allocation === 'fomo') {
      // more than 80% of users have given events by this organization a thumbs up
      // the average_event_rating returned is an average value between 1 and 2
      if (
        event?.average_event_rating_for_org &&
        event.average_event_rating_for_org <= 1.2
      ) {
        return 'fomo'
      }
      return // will hit default
    } else {
      return allocation
    }
  }

  const Emoji = () => {
    // adapt emoji to match experiment variants while also supporting group/donation/etc.
    switch (allocationWithConditions(allocation)) {
      case 'hard_ask':
        return (
          <span role="img" aria-label="waving emoji">
            👋
          </span>
        )
      case 'social_pressure':
        return (
          <span role="img" aria-label="clock emoji">
            ⏰
          </span>
        )
      case 'fomo':
        return (
          <span role="img" aria-label="heart emoji">
            ❤️
          </span>
        )
      case 'impact':
        return (
          <span role="img" aria-label="growth emoji">
            📈
          </span>
        )
      default:
        // return the control as default
        return (
          <>
            {useDonationCopy ? (
              <span role="img" aria-label="money bag emoji">
                💰
              </span>
            ) : useGroupCopy ? (
              <span role="img" aria-label="waving emoji">
                👋
              </span>
            ) : (
              <span role="img" aria-label="grinning face emoji">
                😄
              </span>
            )}
          </>
        )
    }
  }

  const getBannerCopy = () => {
    switch (allocationWithConditions(allocation)) {
      case 'hard_ask':
        return 'Can <b>{name}</b> count on you to join them on <b>{startDay}</b> at {startTime}? Sign up now!'
      case 'social_pressure':
        return "Join <b>{name}</b> on <b>{startDay}</b> at {startTime}. It's filling up fast, so don't miss out!"
      case 'fomo':
        if (currentOrganization?.name) {
          // flow doesn't like using currentOrganization.name in this string if it's undefined or null, which it
          // will be until the currentOrganizationContext hydrates the data. the user will almost never see the
          // second case here (except in the case of very slow connections), but adding a logical string just in case.
          return `Attendees give ${currentOrganization.name} events high ratings! Sign up to join <b>{name}</b> on <b>{startDay}</b> at <b>{startTime}</b>.`
        }
        return `Attendees give this organization's events high ratings! Sign up to join <b>{name}</b> on <b>{startDay}</b> at <b>{startTime}</b>.`
      case 'impact':
        return 'Help <b>{name}</b> double their impact! Sign up and join them on <b>{startDay}</b> at {startTime}.'
      default:
        // return the control as default
        return "<b>{name}</b> is attending this event on <b>{startDay}</b> at {startTime}. Let them know you're going by signing up!"
    }
  }

  return (
    <Card>
      <Emoji />{' '}
      {/* Donation campaigns are virtual flexible, so no need to support them in the NAME_AND_TIME variant */}
      {variant === ReferrerBannerVariant.NAME_AND_TIME && (
        <span>
          {isGroupInvite ? (
            <>
              <F
                defaultMessage="<b>{name}</b> is attending this event on <b>{startDay}</b> at {startTime}. "
                values={{
                  b: (msg) => <strong>{msg}</strong>,
                  name: maybeReferrerOrGroupName,
                  startDay: maybeReferrerStartDay,
                  startTime: maybeReferrerStartTime,
                }}
              />
              {groupIsFull ? (
                <F
                  defaultMessage="{groupName} is full but you can still sign up for the event! "
                  values={{groupName: maybeReferrerOrGroupName}}
                />
              ) : (
                <F defaultMessage="Let them know you're going by signing up!" />
              )}
            </>
          ) : (
            <F
              defaultMessage={getBannerCopy()}
              values={{
                b: (msg) => <strong>{msg}</strong>,
                name: maybeReferrerOrGroupName,
                startDay: maybeReferrerStartDay,
                startTime: maybeReferrerStartTime,
              }}
            />
          )}
        </span>
      )}
      {variant === ReferrerBannerVariant.NAME_ONLY && (
        <span data-testid="referrer_name_only">
          Your friend <strong>{maybeName}</strong> {friendAction} this{' '}
          {actionType}.{' ' + askCopy}
        </span>
      )}
      {variant === ReferrerBannerVariant.ANONYMOUS && (
        <span data-testid="referrer_anonymous">
          Your friend {friendAction} this {actionType}.{' ' + askCopy}
        </span>
      )}
    </Card>
  )
}

export function getReferrerBannerInfo(initialQueryParams, event) {
  const maybePrioritizedTimeslot = getMaybePrioritizedTimeslot(
    initialQueryParams,
    event
  )

  const {isDonationCampaign, isGroup} = getEventTypeAffinityChecks(event)

  const isGroupInvite =
    initialQueryParams.is_group_invite?.toLowerCase() === 'true'
  const groupIsFull = initialQueryParams.group_is_full?.toLowerCase() === 'true'

  let variant = null
  if (
    initialQueryParams.rname &&
    maybePrioritizedTimeslot &&
    !isAtCapacity(maybePrioritizedTimeslot)
  ) {
    variant = event.is_virtual_flexible
      ? ReferrerBannerVariant.NAME_ONLY
      : ReferrerBannerVariant.NAME_AND_TIME
  } else if (initialQueryParams.rname) {
    variant = ReferrerBannerVariant.NAME_ONLY
  } else if (initialQueryParams.force_banner === 'true') {
    variant = ReferrerBannerVariant.ANONYMOUS
  }

  return {
    referrerName: initialQueryParams.rname || null,
    useDonationCopy: isDonationCampaign,
    useGroupCopy: isGroup,
    variant,
    isGroupInvite,
    groupIsFull,
  }
}

export function getMaybePrioritizedTimeslot(initialQueryParams, event) {
  const prioritizedTimeslotId =
    maybeStringToMaybeInt(initialQueryParams.check_in_timeslot) ||
    maybeStringToMaybeInt(initialQueryParams.timeslot)
  return (
    (event.times &&
      event.times.find((ts) => ts.id === prioritizedTimeslotId)) ||
    null
  )
}

export default ReferrerBanner
