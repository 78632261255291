import {CardModal} from 'components'
import EventSignupChaining from './content/EventSignupChaining'

export default function EventSignupChainingModal({
  onRequestClose,
  closeText,
  ...props
}) {
  return (
    <CardModal
      isOpen={props.isOpen}
      onRequestClose={onRequestClose}
      closeText={closeText}
      wide
    >
      <EventSignupChaining {...props} />
    </CardModal>
  )
}
