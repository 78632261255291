import {Button, Input} from 'components'
import {defineMessages, useIntl} from 'util/i18n'
import {formatQueryString, getQueryParams} from 'util/url'
import styles, {mediaMinWidthSmall} from 'components/styles'
import {useContext, useRef, useState} from 'react'
// $FlowFixMe: types need updating for latest react-router
import {useHistory, useLocation} from 'react-router'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {getFeedUrl} from 'util/routing'
import styled from '@emotion/styled/macro'
import useWindowSize from 'hooks/windowSize'

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Search',
  },
})

const SearchWrapper = styled.div`
  ${(props) =>
    props.isExpanded &&
    `
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${styles.colors.white};
      padding: ${styles.space.s} 0;
      display: flex;
    `}

  ${mediaMinWidthSmall(`
    position: static;
  `)};
`

export default function Search() {
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const {search} = useLocation()

  // This is to trigger a isDesktop change if necessary.
  // TODO(mime): could be that we want to eventually combine isDesktop into it's own hook
  // to encapsulate this.
  // eslint-disable-next-line no-unused-vars
  const windowSize = useWindowSize()

  const history = useHistory()
  const queryParams = getQueryParams(search)
  // We auto-focus and don't collapse on the homepage (if desktop).
  const isDesktop = window.matchMedia(
    `screen and (min-width: ${styles.limits.breakpointSmall}px)`
  ).matches
  const feedUrl = getFeedUrl(currentOrganization)
  const inputRef = useRef(null)
  const intl = useIntl()
  const [isExpanded, setIsExpanded] = useState(!!queryParams.q)
  const [value, setValue] = useState(queryParams.q)

  const searchPlaceholder = intl.formatMessage(messages.placeholder)
  const handleChange = ({target: {value}}) => setValue(value)
  const handleBlur = () => setIsExpanded(false)
  const handleClick = () => {
    setIsExpanded(true)
    if (inputRef.current) {
      // Waits a tick until the input is marked enabled again.
      setTimeout(() => inputRef.current?.focus(), 0)
    }
  }
  const handleKeyDown = ({key}) => {
    if (key === 'Enter') {
      history.push({
        pathname: feedUrl.pathname,
        search: formatQueryString({q: value}),
      })
    }
  }

  function handleCloseSearchClick() {
    setIsExpanded(false)
  }

  return (
    <SearchWrapper isExpanded={isExpanded}>
      {!isDesktop && isExpanded && (
        <Button link icon="arrow-left" onClick={handleCloseSearchClick} />
      )}
      <Input
        autoComplete="off"
        disabled={!isExpanded}
        fullWidthAndOverlay={!isDesktop && isExpanded}
        icon="search"
        inputRef={inputRef}
        isCollapsed={!isExpanded}
        label=""
        name="search"
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        placeholder={searchPlaceholder}
        shouldDisableAnimation={!isDesktop}
        type="search"
        value={value}
      />
    </SearchWrapper>
  )
}
