import {
  InputAdornment as MaterialInputAdornment,
  TextField as MaterialTextField,
} from '@material-ui/core'
import {classnames, logError} from 'util/common'

import FormItem from './FormItem'
import Icon from './Icon'
import {getCharacterCount} from 'util/string'
import {makeStyles} from '@material-ui/core/styles'
import styled from '@emotion/styled/macro'
import styles from './styles'

const useStyles = makeStyles((theme) => ({
  validatedInput: {
    borderColor: styles.colors.success200,

    '.MuiOutlinedInput-root.Mui-focused &': {
      borderColor: styles.colors.success200,
    },
  },
  validatedInputLabel: {
    '&.Mui-focused': {
      color: styles.colors.success300,
    },
  },
  validatedHelperText: {
    color: styles.colors.success300,
  },
  monospace: {
    fontFamily: styles.typography.fontMonospace,
  },

  collapsibleRoot: {
    width: 'min(25vw, 300px)',
    transition: 'width 125ms ease-in',
    '&:not(:only-child)': {
      marginBottom: 0,
    },
  },
  collapsibleAndIsCollapsedRoot: {
    width: '32px',
  },
  collapsibleNotchedOutline: {
    transition: 'all 125ms ease-in',
  },
  collapsibleAndIsCollapsedNotchedOutline: {
    borderColor: 'transparent !important',
  },

  // These classes, by their nature, override the collapsible* classes above.
  fullWidthAndOverlay: {
    width: '100%',
    transition: 'none',
    border: '0',
  },
  fullWidthAndOverlayNotchedOutline: {
    borderColor: 'transparent !important',
  },

  disableAnimation: {
    transition: 'none',
  },
}))

let INPUT_ID = 0

const MAX_LENGTH_FOR_EMAIL = 254

const AlignRight = styled.div`
  text-align: right;
`

const HelperTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Input = ({
  autoComplete,
  className,
  disabled,
  error,
  focusOnMount,
  fullWidthAndOverlay,
  hint,
  icon,
  id,
  inputRef,
  isCollapsed,
  isMonospace,
  isZIPCode,
  label,
  loading,
  max,
  maxLength,
  showMaxLengthCounter,
  maxWidth,
  min,
  minLength,
  multiline,
  name,
  onBlur,
  onChange,
  onClick,
  onKeyDown,
  pattern,
  placeholder,
  prefix,
  readOnly,
  required,
  rows,
  sectionLabel,
  shouldDisableAnimation,
  step,
  style,
  type,
  validated,
  value,
}) => {
  const classes = useStyles()

  const inputID = id || `mbz-input-${INPUT_ID++}`

  const getMaxLength = () => {
    if (isZIPCode) {
      return 5
    } else if (type === 'email') {
      // Limit to the default match max_length of lib.serializers.EmailField
      // unless a shorter length is specified (such as an EveryAction ActionTag
      // limit).
      if (!maxLength) {
        return MAX_LENGTH_FOR_EMAIL
      } else if (maxLength > MAX_LENGTH_FOR_EMAIL) {
        logError(
          `maxLength cannot be greater than ${MAX_LENGTH_FOR_EMAIL} for email Inputs.`,
          {maxLength}
        )
      }
    }

    return maxLength
  }

  let helperText = hint
  if (maxLength !== undefined && showMaxLengthCounter) {
    const maxLengthCounter = getCharacterCount(value || '', maxLength)
    helperText = helperText ? (
      <HelperTextWrapper>
        {hint}
        {maxLengthCounter}
      </HelperTextWrapper>
    ) : (
      <AlignRight>{maxLengthCounter}</AlignRight>
    )
  }

  return (
    <FormItem label={sectionLabel}>
      <MaterialTextField
        autoComplete={autoComplete}
        autoFocus={focusOnMount}
        classes={{
          root: classnames(
            isCollapsed !== undefined ? classes.collapsibleRoot : undefined,
            isCollapsed === true
              ? classes.collapsibleAndIsCollapsedRoot
              : undefined,
            fullWidthAndOverlay ? classes.fullWidthAndOverlay : undefined,
            shouldDisableAnimation ? classes.disableAnimation : undefined
          ),
        }}
        className={className}
        disabled={disabled}
        error={error}
        FormHelperTextProps={{
          classes: validated ? {root: classes.validatedHelperText} : undefined,
          component: 'div',
        }}
        helperText={helperText}
        id={inputID}
        InputLabelProps={{
          classes: validated
            ? {shrink: classes.validatedInputLabel}
            : undefined,
        }}
        InputProps={{
          classes: {
            root: classnames(isMonospace ? classes.monospace : undefined),
            notchedOutline: classnames(
              validated ? classes.validatedInput : undefined,
              isCollapsed !== undefined
                ? classes.collapsibleNotchedOutline
                : undefined,
              isCollapsed === true
                ? classes.collapsibleAndIsCollapsedNotchedOutline
                : undefined,
              fullWidthAndOverlay
                ? classes.fullWidthAndOverlayNotchedOutline
                : undefined,
              shouldDisableAnimation ? classes.disableAnimation : undefined
            ),
          },
          startAdornment:
            loading || icon ? (
              <Icon loading={loading} name={icon || ''} />
            ) : prefix ? (
              <MaterialInputAdornment position="start">
                {prefix}
              </MaterialInputAdornment>
            ) : undefined,
        }}
        inputProps={{
          'aria-label': label || placeholder,
          minLength: minLength,
          maxLength: getMaxLength(),
          min,
          max,
          onBlur,
          onKeyDown,
          pattern: isZIPCode ? '\\d{5}' : pattern,
          // Undefined defaults to a step of 1
          step: type === 'number' ? step : undefined,
          role: 'input',
        }}
        inputRef={inputRef}
        label={label}
        multiline={multiline}
        name={name}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        rows={rows}
        style={{
          ...style,
          maxWidth,
        }}
        type={isZIPCode ? 'tel' : type}
        value={value}
        variant="outlined"
      />
    </FormItem>
  )
}

export default Input
