import invariant from 'assert'

// Lazy load elements such as scripts or links that are only needed by certain
// components by dynamically inserting them into the DOM. Insert the new element
// in the document head.
export const lazyLoadElement = (tagName, properties) => {
  const newElement = document.createElement(tagName)
  Object.assign(newElement, properties)
  invariant(document.head) // N.B. For Flow. document.head should exist.
  const head = document.head
  head.insertBefore(newElement, null)
}
