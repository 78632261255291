import {ChatSnippetInitializationMode} from 'vendor/intercom/chatSnippetManager'
import FeedMap from 'events/feed/FeedMap'
import MembershipConfirmationModal from 'events/modals/MembershipConfirmationModal'
import {getQueryParams} from 'util/url'
import {queryStringToFilterParams} from 'util/feed'
import withOrgAnalytics from 'analytics/withOrgAnalytics'
import withScreenData from 'app/withScreenData'

function FeedMapScreen({
  data: {
    current_organization: organization,
    org_tag_ids: orgTagIds,
    filter_tags: filterTags,
    event_marker_response: eventMarkerData,
  },
  trackingParams,
  location,
}) {
  const filterParams = queryStringToFilterParams(location.search)
  const queryParams = getQueryParams(location.search)
  const zoom = Number(queryParams.zoom) || null

  return (
    <>
      <MembershipConfirmationModal
        organization={organization}
        trackingParams={trackingParams}
      />
      <FeedMap
        organization={organization}
        filterParams={filterParams}
        zoom={zoom || undefined}
        filterTags={filterTags}
        orgTagIds={orgTagIds}
        initialEventMarkerData={eventMarkerData}
      />
    </>
  )
}

export default withScreenData(withOrgAnalytics(FeedMapScreen), {
  chatSnippetInitializationMode:
    ChatSnippetInitializationMode.USE_CLIENT_CHAT_SNIPPET_ONLY,
})
