import ContentReportComponent from './ContentReportComponent'
import api from 'data/api'
import withScreenData from 'app/withScreenData'

function EventContentReportScreen(props) {
  return (
    <ContentReportComponent
      reportedEntityDisplayName="event"
      onSubmit={(notesObject) =>
        api.submitEventContentReport(props.data.event_id, notesObject)
      }
      data={props.data}
    />
  )
}

export default withScreenData(EventContentReportScreen)
