import {Button, MaxWidthContainer, Typography} from 'components'
import {EMPTY_FILTER_PARAMS, MILES_TO_METERS} from 'app/constants'
import {
  hasActiveFeaturedEventCampaign,
  orgFlagIsActive,
} from 'util/organization'

import {EventFeedItem} from 'events/components'
import {F} from 'util/i18n'
import FeedOffsetItem from './FeedOffsetItem'
import HostEventFeedItem from './HostEventFeedItem'
import LeadCaptureForm from './LeadCaptureForm'
import LeadCaptureFormV2 from './LeadCaptureFormV2'
import PublicSitePagination from './PublicSitePagination'
import {isFiltered} from 'util/feed'
import {partial} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useExperimentAllocation} from 'hooks/experiments'

const ClearButtonWrapper = styled.div`
  margin-top: ${styles.space.m};
  margin-bottom: ${styles.space.xl};
  text-align: center;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  list-style-type: none;
`
const OffsetWrapper = styled.div`
  background-color: white;
  box-shaddow: 4px 4px 4px #dadada;
  margin-top: 4rem;
  margin-bottom: 4rem;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
`

const EventListSubheading = styled.div`
  ${(props) => props.marginTop && `margin-top: ${styles.space.xl};`}
  font-weight: ${styles.typography.fontWeightSemibold};
`

const NoResultsHeader = styled.div`
  padding-top: ${styles.space.m};
  text-align: center;
  ${(props) =>
    props.hasFiltersApplied
      ? `padding-bottom: ${styles.space.m};`
      : `padding-bottom: ${styles.space.xl};`}
`

const PaginationWrapper = styled.div`
  margin-top: ${styles.space.m};
`

// Helper function to make the body a little more readable
function _didGeofilter(filterParams, isGeolocated) {
  return (
    isGeolocated ||
    !!(filterParams.zip || (filterParams.lat && filterParams.lon))
  )
}

function EventList({
  events,
  organization,
  filterParams,
  onFilterChange,
  pagingInfo,
  isGeolocated,
  geolocationName,
}) {
  const distributedOrganizingActive = hasActiveFeaturedEventCampaign(
    organization
  )
  const leadCaptureVariant = useExperimentAllocation('new_lead_capture_form')
    ?.variant.name
  const shouldShowNewLeadCapture =
    leadCaptureVariant === 'lead_capture_v2' &&
    orgFlagIsActive(organization, 'enable_new_lead_capture_form')

  const hasFiltersApplied = isFiltered(filterParams)
  if (!events.length) {
    return (
      <>
        {distributedOrganizingActive &&
          organization.default_event_campaign /* checked again here just for flow */ && (
            <MaxWidthContainer noGutters>
              <HostEventFeedItem
                organization={organization}
                eventCampaign={organization.default_event_campaign}
              />
            </MaxWidthContainer>
          )}
        <NoResultsHeader hasFiltersApplied={hasFiltersApplied}>
          <Typography variant="h4" center>
            {hasFiltersApplied
              ? 'Want to see more events? Search again with different filters.'
              : 'There are no events at the moment.'}
          </Typography>
        </NoResultsHeader>
        {hasFiltersApplied && (
          <ClearButtonWrapper>
            <Button
              onClick={partial(onFilterChange, {
                ...EMPTY_FILTER_PARAMS,
                showAllEvents: true,
              })}
            >
              Clear filters
            </Button>
          </ClearButtonWrapper>
        )}
        {!distributedOrganizingActive && (
          <FeedOffsetItem>
            <LeadCaptureForm
              organization={organization}
              parentComponent="feed"
            />
          </FeedOffsetItem>
        )}
      </>
    )
  }

  const didGeofilter = _didGeofilter(filterParams, isGeolocated)

  // Find the index at which events start being farther than
  // `distanceCutoffMiles` away
  const distanceCutoffMiles = 25
  let eventDistanceCutoffIndex = didGeofilter
    ? events.findIndex(
        ({distance}) =>
          typeof distance === 'number' &&
          distance / MILES_TO_METERS > distanceCutoffMiles
      )
    : -1

  // If we're not on page 1 and the first event is the distance cutoff, we can't
  // know if it's actually the first "far" event or just a continuation of
  // other "far" events, so just punt in this case
  if (eventDistanceCutoffIndex === 0 && pagingInfo.page > 1) {
    eventDistanceCutoffIndex = -1
  }

  const lastIndex = events.length - 1

  // By default put the virtual recirc card at the distance cutoff
  let virtualRecircIndex = eventDistanceCutoffIndex

  const virtualRecircExperimentMaxIndex = 3
  if (
    // If the page is too small, just leave the recirc card at the distance
    // cutoff (its default, set above)
    lastIndex > virtualRecircExperimentMaxIndex
  ) {
    if (eventDistanceCutoffIndex === -1) {
      // Everything on the page is within the distance cutoff so put the recirc
      // card at `virtualRecircExperimentMaxIndex`
      virtualRecircIndex = virtualRecircExperimentMaxIndex
    } else {
      // Put the recirc card at whatever's smaller, the distance cutoff or the
      // `virtualRecircExperimentMaxIndex`
      virtualRecircIndex = Math.min(
        eventDistanceCutoffIndex,
        virtualRecircExperimentMaxIndex
      )
    }
  }

  // Try to move the lead capture form at least `padding` units away from the
  // virtual recirc form
  let leadCaptureIndex = Math.min(2, lastIndex)
  const indexDelta = leadCaptureIndex - virtualRecircIndex
  const padding = 2
  if (indexDelta < padding) {
    leadCaptureIndex = Math.min(
      leadCaptureIndex + padding - indexDelta,
      lastIndex
    )
  }

  // Nothing on the page was within 25mi
  const noCloseResultsFound =
    eventDistanceCutoffIndex === 0 && pagingInfo.page === 1

  const nearby =
    geolocationName || filterParams.zip || filterParams.address || 'you'

  return (
    <>
      {didGeofilter && nearby && (
        <MaxWidthContainer marginBottom>
          <Typography variant="h2" data-geolocated-header id="events-near-you">
            <F
              defaultMessage="Events and volunteer opportunities near {location}"
              values={{location: nearby}}
            />
          </Typography>
        </MaxWidthContainer>
      )}

      {noCloseResultsFound && (
        <MaxWidthContainer marginBottom>
          <EventListSubheading>
            <Typography variant="body2">
              No events found near {nearby}.{' '}
              <Button
                padding="none"
                link
                onClick={(e) => {
                  e.preventDefault()
                  onFilterChange({
                    ...filterParams,
                    zip: '',
                    address: '',
                    lat: null,
                    lon: null,
                    showAllEvents: true,
                  })
                }}
              >
                Clear location
              </Button>
            </Typography>
          </EventListSubheading>
        </MaxWidthContainer>
      )}

      {!didGeofilter && (
        <MaxWidthContainer marginBottom>
          <Typography variant="h2" data-geolocated-header id="all-events">
            All events
          </Typography>
        </MaxWidthContainer>
      )}

      <List>
        {events.map((event, index) => {
          const shouldShowLeadCaptureForm =
            index === leadCaptureIndex && organization.lead_capture_in_feed
          const shouldShowDistanceMsg =
            didGeofilter && index === eventDistanceCutoffIndex

          return (
            <ListItem key={event.id}>
              {shouldShowDistanceMsg && (
                <MaxWidthContainer marginBottom>
                  <EventListSubheading marginTop>
                    <Typography variant="h2">
                      Here are some events farther away...
                    </Typography>
                  </EventListSubheading>
                </MaxWidthContainer>
              )}
              <MaxWidthContainer noGutters>
                <EventFeedItem event={event} filterParams={filterParams} />
              </MaxWidthContainer>
              {shouldShowLeadCaptureForm &&
                (shouldShowNewLeadCapture ? (
                  <OffsetWrapper>
                    <LeadCaptureFormV2
                      organization={organization}
                      parentComponent="feed"
                    />
                  </OffsetWrapper>
                ) : (
                  <FeedOffsetItem>
                    <LeadCaptureForm
                      organization={organization}
                      parentComponent="feed"
                    />
                  </FeedOffsetItem>
                ))}
            </ListItem>
          )
        })}
      </List>

      <PaginationWrapper>
        <PublicSitePagination
          pagingInfo={pagingInfo}
          numOnPage={events.length}
        />
      </PaginationWrapper>
    </>
  )
}

export default EventList
