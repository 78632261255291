// Fields to always include in the form in addition to required fields.
export const ALWAYS_INCLUDED_ACTION_TAG_GENERAL_FIELDS = [
  'Account',
  'ExpirationDate',
  'ExpirationYear',
  'LegalDescription',
  'LegalHeaderHtml',
]

// Address Fields that may be included in an ActionTag form.
export const ALLOWED_ACTION_TAG_ADDRESS_FIELDS = [
  'AddressLine1',
  'AddressLine2',
  'City',
  'StateProvince',
  'Country',
]

export const MOBILIZE_CONTACT_INFORMATION_FIELDS = [
  'EmailAddress',
  'FirstName',
  'LastName',
  'MobilePhone',
  'PostalCode',
  // TODO: Eventually we should include smsOptIn here.
]

export const ALLOWED_ACTION_TAG_CONTRIBUTION_FIELDS = [
  'CoverCostsAmount',
  'ProcessingCurrency',
  'SelectAmount',
  'SelectedFrequency',
]

// https://developers.ngpvan.com/action-tag#usage-query-strings
// This can be converted into an enum in the future if we ever need to reference
// other frequency types.
export const RECURRING_COMMITMENT_FREQUENCY_ONE_TIME = '0'

export const CREDIT_CARD_CONTAINER_NAME = 'cc-container'
export const CREDIT_CARD_EXPIRATION_CONTAINER_NAME = 'exp-container'
export const CREDIT_CARD_SECURITY_CODE_CONTAINER_NAME = 'cvv-container'

export const CREDIT_CARD_CONTAINER_ID = `#${CREDIT_CARD_CONTAINER_NAME}`
export const CREDIT_CARD_EXPIRATION_CONTAINER_ID = `#${CREDIT_CARD_EXPIRATION_CONTAINER_NAME}`
export const CREDIT_CARD_SECURITY_CODE_CONTAINER_ID = `#${CREDIT_CARD_SECURITY_CODE_CONTAINER_NAME}`
