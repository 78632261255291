import {localStoragePaths, sessionStoragePaths} from './config'
import {maybeSetInLocalStorage, maybeSetInSessionStorage} from './util'

import analytics from 'analytics'
import {isEqual} from 'util/common'

export const getStorageSubscriber = (store) => () => {
  const state = store.getState()
  localStoragePaths.forEach((path) => {
    maybeSetInLocalStorage(path, state[path])
  })
  sessionStoragePaths.forEach((path) => {
    maybeSetInSessionStorage(path, state[path])
  })
}

export const getAnalyticsSubscriber = (store) => {
  let {currentVolunteer: previousCurrentVolunteer} = store.getState()
  return () => {
    const {currentVolunteer} = store.getState()
    if (!isEqual(currentVolunteer, previousCurrentVolunteer)) {
      analytics.trackCurrentVolunteerChanged()
    }
    previousCurrentVolunteer = currentVolunteer
  }
}
