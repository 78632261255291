import api from 'data/api'
import cv from 'util/clientVars'

const callAhaEndpoint = async (user_id) => {
  const response = await api.getAhaToken(user_id)
  const response_status = response['status']
  if (response_status === 200) {
    // redirect to Aha! portal after getting succesful token generation from backend
    window.open(
      `https://bonterra.identity.aha.io/idea_portal_provider/jwt_callback/7168891954131264010
      ?jwt=${response['token']}`,
      '_blank'
    )
  } else if (response_status === 401) {
    window.open(cv.feedback_url, '_blank')
  }
  return
}

export default callAhaEndpoint
