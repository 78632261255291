import {formatQueryString, getQueryParams} from 'util/url'
// $FlowFixMe (mime): types need updating for latest react-router
import {useHistory, useLocation} from 'react-router'

import {GoogleLoginButtonOptions} from './enums'
import PageAnalytics from './PageAnalytics'
import {USER_ID_DIFFERS_PARAM} from './constants'
import api from 'data/api'
import callAhaEndpoint from 'dashboard/components/util'
import {configureChatSnippetForPage} from 'vendor/intercom/chatSnippetManager'
import {omit} from 'util/common'
import {useEffect} from 'react'
import {useSnackbar} from 'notistack'

const GLOBAL_QUERY_PARAMS_TO_REMOVE = [USER_ID_DIFFERS_PARAM]

export default function Page({
  analyticsPageName,
  children,
  screenOptions,
  wrappedProps,
}) {
  useGoogleLogin()
  useQueryCleanup({screenOptions})
  useConfigureChat({screenOptions, wrappedProps})

  return (
    <>
      <PageAnalytics
        analyticsPageName={analyticsPageName}
        screenOptions={screenOptions}
        wrappedProps={wrappedProps}
      />
      {children}
    </>
  )
}

async function useGoogleLogin() {
  // NB: The code and state are passed in the URL from Google after the requestCode() function is called in GoogleLoginButton.js
  const {search} = useLocation()
  const snackbar = useSnackbar()
  var params = getQueryParams(search)
  const {code: googleAuthCode} = params
  const {state: googleAuthState} = params
  const history = useHistory()
  useEffect(() => {
    async function loginUser() {
      try {
        if (googleAuthCode) {
          history.replace({search: ''})
          if (googleAuthState === GoogleLoginButtonOptions.LOGIN) {
            await api.loginWithGoogle(googleAuthCode)
          } else if (googleAuthState === GoogleLoginButtonOptions.CONNECT) {
            await api.connectUserGoogle(googleAuthCode)
          } else if (
            googleAuthState === GoogleLoginButtonOptions.LOGIN_WITH_AHA
          ) {
            const mobilizeData = await api.loginWithGoogle(googleAuthCode)
            await callAhaEndpoint(mobilizeData.user.id)
          }
          window.location.reload()
        }
      } catch (mobilizeError) {
        let detailedError = 'Google Auth Error'
        if (mobilizeError.json) {
          detailedError =
            typeof mobilizeError.json.error === 'string'
              ? mobilizeError.json.error
              : mobilizeError.json.error.message
        }
        snackbar.enqueueSnackbar(detailedError, {variant: 'error'})
      }
    }
    loginUser()
  }, [googleAuthCode, googleAuthState, history, snackbar])
}

function useQueryCleanup({screenOptions}) {
  const history = useHistory()
  const {hash, search} = useLocation()

  useEffect(() => {
    const queryParamsToRemove = [
      ...(screenOptions.queryParamsToRemove || []),
      ...GLOBAL_QUERY_PARAMS_TO_REMOVE,
    ]
    const queryParams = getQueryParams(search)
    if (queryParamsToRemove.some((param) => queryParams[param])) {
      const newQueryString = formatQueryString(
        omit(queryParams, queryParamsToRemove)
      )
      history.replace({
        search: newQueryString,
        // don't clobber a hash if it exists
        hash: hash,
      })
    }
  }, [hash, history, screenOptions, search])
}

function useConfigureChat({screenOptions, wrappedProps}) {
  useEffect(() => {
    configureChatSnippetForPage(
      wrappedProps.data,
      screenOptions.chatSnippetInitializationMode
    )
  }, [screenOptions, wrappedProps])
}
