import {
  CREDIT_CARD_CONTAINER_ID,
  CREDIT_CARD_CONTAINER_NAME,
  CREDIT_CARD_EXPIRATION_CONTAINER_ID,
  CREDIT_CARD_EXPIRATION_CONTAINER_NAME,
  CREDIT_CARD_SECURITY_CODE_CONTAINER_ID,
  CREDIT_CARD_SECURITY_CODE_CONTAINER_NAME,
} from 'events/details/FundraisingForm/field_groups/constants'

import {ActionType} from '../FundraisingFormReducer'
import {Button} from 'components'
import {F} from 'util/i18n'
import styled from '@emotion/styled/macro'
import {useEffect} from 'react'

const CreditCardFieldsWrapper = styled.div`
  ${(props) => !props.shouldRenderVgsFields && 'display: none;'}
`

export default function PaymentInformationFields({
  dispatch,
  creditCardType,
  creditCardLastFour,
  fields,
  formMetadata,
  shouldRenderVgsFields,
  submitJsForm,
}) {
  // When Submit.js is able to support other payment types, only render credit
  // card fields if the payment method is credit card. For now, assume that we
  // need to display credit card data.

  const {accepted_cards} = formMetadata

  const maybeSecurityCodeField = fields.find((el) => el.name === 'SecurityCode')
  const securityCodeIsRequired =
    !!maybeSecurityCodeField && maybeSecurityCodeField.required

  useEffect(() => {
    // Renders the VGS credit card fields only when using FastAction for credit
    // card payment.
    if (!shouldRenderVgsFields) {
      return
    }

    const commonOptions = {
      createContainer: true,
      inlineErrors: true,
      tabOnComplete: true,
    }

    // N.B. Cannot pass the same options object into the render method or the
    // containers will not render properly because VGS adds a container key to
    // the options object.
    const optionsCreditCard = {
      options: {
        ...commonOptions,
        acceptedCardTypes: accepted_cards,
      },
    }
    const optionsExpirationDate = {options: {...commonOptions}}

    submitJsForm.renderCreditCard(CREDIT_CARD_CONTAINER_ID, optionsCreditCard)
    submitJsForm.renderExpirationDate(
      CREDIT_CARD_EXPIRATION_CONTAINER_ID,
      optionsExpirationDate
    )

    if (securityCodeIsRequired) {
      const optionsSecurityCode = {options: {...commonOptions}}
      submitJsForm.renderCvv(
        CREDIT_CARD_SECURITY_CODE_CONTAINER_ID,
        optionsSecurityCode
      )
    }
  }, [
    accepted_cards,
    shouldRenderVgsFields,
    securityCodeIsRequired,
    submitJsForm,
  ])

  const handleUseDifferentCard = () => {
    dispatch({type: ActionType.CREDIT_CARD_FIELDS_CLEARED})
  }

  return (
    <>
      {creditCardLastFour && creditCardType && (
        <>
          <F
            data-testid="prefilled-card-info"
            defaultMessage="{creditCardType} ending in {creditCardLastFour}"
            values={{
              creditCardLastFour,
              creditCardType,
            }}
          />
          <Button link onClick={handleUseDifferentCard}>
            <F defaultMessage="(Use a different card)" />
          </Button>
        </>
      )}

      <CreditCardFieldsWrapper
        id="mbz-payment-information"
        shouldRenderVgsFields={shouldRenderVgsFields}
      >
        <div>
          <label>
            <F defaultMessage="Card Number" />
            <div id={CREDIT_CARD_CONTAINER_NAME}></div>
          </label>
          <label>
            <F defaultMessage="Expiration Date" />
            <div id={CREDIT_CARD_EXPIRATION_CONTAINER_NAME}></div>
          </label>
          {securityCodeIsRequired && (
            <label>
              <F defaultMessage="Security Code" />
              <div id={CREDIT_CARD_SECURITY_CODE_CONTAINER_NAME}></div>
            </label>
          )}
        </div>
      </CreditCardFieldsWrapper>
    </>
  )
}
