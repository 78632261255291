// Name of credit card fields that are set by FastAction
export const CREDIT_CARD_FIELDS = ['cc_4_digit', 'cc_type']

export const CREDIT_CARD_PAYMENT_METHOD = 'creditcard'

// Strings corresponding with the 'name' keys of elements in the form_definition
// object. These are used to select the elements for modification.
export const FormDefinitionElementNames = Object.freeze({
  ACCOUNT: 'Account',
  CONTRIBUTION_INFO: 'ContributionInformation',
  CONTACT_INFO: 'ContactInformation',
  COVER_COSTS_AMOUNT: 'CoverCostsAmount',
  EXPIRATION_DATE: 'ExpirationDate',
  EXPIRATION_MONTH: 'ExpirationMonth',
  EXPIRATION_YEAR: 'ExpirationYear',
  HEADER_HTML: 'HeaderHtml',
  METER_HTML: 'MeterHtml',
  PAYMENT_INFO: 'PaymentInformation',
  SECURITY_CODE: 'SecurityCode',
  SELECTED_FREQUENCY: 'SelectedFrequency',
  SELECT_AMOUNT: 'SelectAmount',
})

export const SELF_RECURRING_SELECT_AMOUNT = 'self_recurringselectamount'
