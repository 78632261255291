import {
  Button,
  DatePicker,
  FormItem,
  Input,
  InputGroup,
  Message,
  MessageType,
  TimePicker,
  Typography,
} from 'components'
import styles, {getSecondaryTextColorFromProps} from 'components/styles'

import {Component} from 'react'
import {TimezoneToString} from 'app/enums'
import {maybeStringToMaybeInt} from 'util/string'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'

const TimeslotFieldDisplayWrapper = styled.div`
  ${getSecondaryTextColorFromProps};
`

const SignupCountWrapper = styled.div`
  margin-bottom: ${styles.space.l};
`

class HostTimeslotRow extends Component {
  onMaxAttendeesChange = (e) => {
    const {value} = e.target
    this.props.onModifyTimeslot({
      ...this.props.timeslot,
      maxAttendees: maybeStringToMaybeInt(value),
    })
  }

  render() {
    const {
      timeslot,
      errorMessage,
      onModifyTimeslot,
      onRemoveTimeslot,
      hideDelete,
      timezone,
      displayTimezoneIfKnown,
      rangeStartMoment,
      rangeEndMoment,
      useDefaultDate,
      useDefaultStart,
      useDefaultEnd,
      eventIsVirtual,
    } = this.props

    // if timeslot has an id, check if the timeslot is in the past.
    // use the timezone if there is one, and if there's no timezone
    // compare without it.
    const startMoment =
      timeslot.id &&
      timeslot.date &&
      timeslot.startTime &&
      (timezone
        ? moment(
            `${timeslot.date} ${timeslot.startTime}`,
            'YYYY-MM-DD HH:mm'
          ).tz(timezone)
        : moment(`${timeslot.date} ${timeslot.startTime}`, 'YYYY-MM-DD HH:mm'))
    const currentMoment = timezone ? moment.tz(timezone) : moment()
    // If the timeslot is a preexisting timeslot that already happened,
    // don't let the user edit it at all
    const disablePastTimeslot =
      startMoment && startMoment.isBefore(currentMoment)

    let signupCountMsg = `0 signups`
    if (timeslot.attendingCount) {
      if (timeslot.attendingCount > 1) {
        signupCountMsg = `${timeslot.attendingCount} signups`
      } else {
        signupCountMsg = `${timeslot.attendingCount} signup`
      }
    }

    const maybeTimezoneString =
      timezone && displayTimezoneIfKnown
        ? TimezoneToString[timezone]
        : undefined

    return (
      <>
        <InputGroup stackOnMobile>
          <DatePicker
            label="Date"
            onChange={(value) =>
              onModifyTimeslot({
                ...timeslot,
                date: value,
              })
            }
            shouldReturnHTML5Date
            disablePast
            disabled={!!(useDefaultDate || disablePastTimeslot)}
            minDate={rangeStartMoment ? rangeStartMoment : undefined}
            maxDate={rangeEndMoment ? rangeEndMoment : undefined}
            value={timeslot.date}
          />
          <TimePicker
            value={timeslot.startTime}
            label="Start time"
            name="start_time"
            onChange={(startTime) => {
              onModifyTimeslot({...timeslot, startTime})
            }}
            disabled={!!(useDefaultStart || disablePastTimeslot)}
            hint={maybeTimezoneString}
          />
          <TimePicker
            value={timeslot.endTime}
            label="End time"
            name="end_time"
            onChange={(endTime) => {
              onModifyTimeslot({...timeslot, endTime})
            }}
            disabled={!!(useDefaultEnd || disablePastTimeslot)}
            hint={maybeTimezoneString}
          />
          {eventIsVirtual && (
            <Input
              name="virtual_join_url"
              value={timeslot.virtualJoinUrl || ''}
              label="Video call link"
              type="text"
              onChange={(e) => {
                onModifyTimeslot({...timeslot, virtualJoinUrl: e.target?.value})
              }}
            />
          )}
          <>
            {disablePastTimeslot ? (
              <FormItem label="Capacity">
                <TimeslotFieldDisplayWrapper>
                  {timeslot.maxAttendees === null
                    ? 'N/A'
                    : timeslot.maxAttendees}
                </TimeslotFieldDisplayWrapper>
              </FormItem>
            ) : (
              <Input
                name="maxAttendees"
                value={
                  timeslot.maxAttendees === null ? '' : timeslot.maxAttendees
                }
                label="Capacity"
                type="number"
                min={0}
                placeholder="(optional)"
                onChange={this.onMaxAttendeesChange}
              />
            )}
          </>
          {!hideDelete && !disablePastTimeslot && (
            <Button
              link
              padding="none"
              icon="times"
              type="button"
              onClick={onRemoveTimeslot}
            />
          )}
        </InputGroup>
        {timeslot.id && (
          <Typography variant="subtitle1">
            <SignupCountWrapper>{signupCountMsg}</SignupCountWrapper>
          </Typography>
        )}
        {errorMessage && (
          <Message type={MessageType.ERROR}>{errorMessage}</Message>
        )}
      </>
    )
  }
}

export default HostTimeslotRow
