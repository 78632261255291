import {createMuiTheme} from '@material-ui/core/styles'
import styles from './index'

// NB: a lot of places where you see these constants, we subtract/add
// a seemingly arbitrary number because each component has a different
// way of sizing itself :-/
const MAX_WIDTH = styles.limits.breakpointSmall
const SELECTS_MIN_WIDTH = 150
const TOOLTIP_MAX_WIDTH = 300

export const INPUT_HEIGHT = 48

export default function muiTheme(theme) {
  const muiTheme = createMuiTheme({
    typography: {
      useNextVariants: true,
      fontFamily: theme.secondary_body_font,
    },
    overrides: {
      MuiAutocomplete: {
        root: {
          maxWidth: MAX_WIDTH,
          minWidth: SELECTS_MIN_WIDTH,
          // TODO(mime): why can't i just put this in `typography` above?
          fontSize: styles.typography.fontSizeBase,

          '&:not(:only-child)': {
            marginBottom: styles.space.m,
          },

          // XXX(mime): this is funky. For Autocompletes with helper text
          // we don't always wrap them in FormItem's. I don't have a better way
          // for this currently.
          // 3px is what MUI uses, sigh.
          '& + .MuiFormHelperText-root': {
            marginTop: `calc(-${styles.space.m} + 3px)`,
            marginBottom: `${
              parseFloat(styles.space.m) + parseFloat(styles.space.s)
            }rem`,
          },
        },
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"]': {
            padding: `${(INPUT_HEIGHT - 38) / 2}px ${styles.space.m}`,
            overflow: 'scroll',
          },
        },
        tag: {
          // TODO(mime): why can't i just put this in `typography` above?
          fontSize: styles.typography.fontSizeBase,
        },
      },

      MuiCheckbox: {
        root: {
          alignSelf: 'flex-start',
        },
        colorPrimary: {
          color: theme.primary_color,

          '&$checked': {
            color: theme.primary_color,
          },
        },
      },

      MuiDivider: {
        root: {
          marginTop: styles.space.s,
          marginBottom: styles.space.s,
        },
      },

      MuiAccordion: {
        root: {
          marginBottom: styles.space.m,
          '&$expanded': {
            '&:last-child': {
              marginBottom: styles.space.m,
            },
          },
        },
      },

      MuiLinearProgress: {
        colorPrimary: {
          backgroundColor: styles.colors.neutral200,
        },
        barColorPrimary: {
          backgroundColor: styles.colors.primary200,
        },
        bar: {
          borderRadius: styles.space.borderRadius,
        },
        root: {
          height: styles.space.s,
          borderRadius: styles.space.borderRadius,
        },
      },

      MuiAccordionDetails: {
        root: {
          display: 'block',
        },
      },

      MuiFormControl: {
        root: {
          display: 'flex',
          maxWidth: MAX_WIDTH,

          '&:not(:only-child)': {
            marginBottom: styles.space.m,
          },
        },
      },

      MuiFormControlLabel: {
        root: {
          display: 'flex',
          maxWidth: MAX_WIDTH,
        },
      },

      MuiFormGroup: {
        // Keep in sync with InputGroup.js -> stackOnMobile styles.
        row: {
          flexWrap: 'nowrap',
          alignItems: 'flex-start',
          [`& > .MuiFormControl-root,
           & > .MuiAutocomplete-root
          `]: {
            width: '100%',
          },
          [`& > .MuiFormControl-root:not(:last-child),
           & > .MuiAutocomplete-root:not(:last-child),
           & > button:not(:last-child),
           & > a:not(:last-child)
          `]: {
            marginRight: styles.space.m,
          },
          [`& > button,
           & > a
          `]: {
            marginBottom: styles.space.s,
          },
        },
      },

      MuiFormHelperText: {
        root: {
          fontSize: styles.typography.fontSizeS,
          maxWidth: MAX_WIDTH,
          color: styles.colors.neutral400,

          // XXX(mime): ugh we don't need to do last-child usually
          // but our reset.css file demands it still. need to change up that rule.
          '&:last-child': {
            marginBottom: styles.space.s,
          },
        },
      },

      MuiFormLabel: {
        root: {
          '&:not(.MuiInputLabel-shrink)': {
            marginTop: `${INPUT_HEIGHT - 52}px`,
          },
        },
      },

      MuiInputBase: {
        formControl: {
          maxWidth: MAX_WIDTH,
          backgroundColor: styles.colors.white,
        },
        inputMultiline: {
          resize: 'auto',
        },
      },

      MuiInputLabel: {
        root: {
          // TODO(mime): why can't i just put this in `typography` above?
          fontSize: styles.typography.fontSizeBase,
          // Allow our helper tooltips to be hoverable.
          '&$shrink': {
            pointerEvents: 'auto',
          },
        },
      },

      MuiListItem: {
        root: {
          '&:hover': {
            backgroundColor: styles.colors.neutral100,
            borderRadius: styles.space.borderRadius,
          },
          '&$selected': {
            backgroundColor: styles.colors.neutral200,
            borderRadius: styles.space.borderRadius,
          },
        },
      },

      MuiListItemIcon: {
        root: {
          minWidth: '2.25rem',
          justifyContent: 'center',
        },
      },

      MuiListItemText: {
        primary: {
          // TODO(mime): why can't i just put this in `typography` above?
          fontSize: styles.typography.fontSizeBase,
        },
      },

      MuiMenuItem: {
        root: {
          // TODO(mime): why can't i just put this in `typography` above?
          fontSize: styles.typography.fontSizeBase,
        },
      },

      MuiOutlinedInput: {
        adornedStart: {
          '& > i': {
            color: styles.colors.neutral400,
          },
        },
        adornedEnd: {
          paddingRight: 2,
        },
        input: {
          padding: `${(INPUT_HEIGHT - 19) / 2}px ${styles.space.m}`,
        },
        inputMultiline: {
          padding: `${(INPUT_HEIGHT - 19) / 2}px ${styles.space.m}`,
        },
        multiline: {
          padding: 1,
          '& > $inputAdornedStart': {
            paddingLeft: '14px',
          },
        },
        notchedOutline: {
          color: theme.input_text_color,
          borderRadius: theme.border_radius,
          borderStyle: 'solid',
          borderWidth: theme.input_border_width,
          borderColor: theme.input_border_color,
          '&:focus': {
            borderRadius: theme.border_radius,
          },
        },
      },

      MuiRadio: {
        colorPrimary: {
          color: theme.primary_color,

          '&$checked': {
            color: theme.primary_color,
          },
        },
      },

      MuiSelect: {
        root: {
          minHeight: '33px',
          lineHeight: '33px',
          height: 'auto',
        },
        select: {
          minWidth: SELECTS_MIN_WIDTH - 47,
          padding: `${(INPUT_HEIGHT - 33) / 2}px ${styles.space.m}`,
        },
      },

      MuiSwitch: {
        root: {
          width: '4.25rem', // accommodate a 2.5rem track
          height: '2.6rem', // don't clip the ripple
        },
        switchBase: {
          '& $thumb': {
            border: `1px solid ${styles.colors.neutral300}`,
          },
          '&$checked $thumb': {
            border: `none`,
          },
        },
        colorSecondary: {
          color: styles.colors.white,
          '&$checked': {
            color: styles.colors.primary200,
            '&:hover': {
              backgroundColor: styles.colors.faintTransparentGrey,
            },
          },
          '&:hover': {
            backgroundColor: styles.colors.faintTransparentGrey,
          },
          '& + $track': {
            backgroundColor: styles.colors.neutral300,
            opacity: '100%',
          },
          '&$checked + $track': {
            backgroundColor: styles.colors.primary100,
            opacity: '100%',
          },
        },
        thumb: {
          width: styles.space.l,
          height: styles.space.l,
        },
        checked: {},
        track: {
          width: '2.5rem',
          height: styles.space.m,
          borderRadius: '.5rem',
          marginTop: '.035rem',
        },
      },

      MuiTab: {
        root: {
          // TODO(mime): why can't i just put this in `typography` above?
          fontSize: styles.typography.fontSizeBase,
          textTransform: 'none',
          minWidth: 0,
          '&$selected': {
            fontWeight: styles.typography.fontWeightBold,
          },
          whiteSpace: 'nowrap',
        },
      },

      MuiTabs: {
        root: {
          boxShadow: `inset 0 -2px ${styles.colors.neutral300}`,
        },
        indicator: {
          backgroundColor: styles.colors.primary200,
        },
      },

      MuiTableCell: {
        root: {
          // TODO(mime): why can't i just put this in `typography` above?
          fontSize: styles.typography.fontSizeBase,
        },
        head: {
          fontWeight: styles.typography.fontWeightBold,
        },
        footer: {
          // TODO(mime): why can't i just put this in `typography` above?
          fontSize: styles.typography.fontSizeBase,
        },
      },

      MuiTableContainer: {
        root: {
          marginTop: styles.space.m,
          marginBottom: styles.space.m,
        },
      },

      MuiTextField: {
        root: {
          // TODO(mime): why can't i just put this in `typography` above?
          fontSize: styles.typography.fontSizeBase,
          width: '100%',
        },
      },

      MuiToggleButtonGroup: {
        root: {
          width: 'fit-content',
        },
        grouped: {
          '&:not(:first-child)': {
            borderLeft: `1px solid ${styles.colors.neutral300}`,
          },
        },
      },

      MuiToggleButton: {
        root: {
          // TODO(mime): why can't i just put this in `typography` above?
          fontSize: styles.typography.fontSizeBase,
          textTransform: 'initial',
          color: styles.colors.black,
          border: `1px solid ${styles.colors.neutral300}`,

          '&:hover': {
            backgroundColor: styles.colors.success200,
            borderColor: styles.colors.success200,
            color: styles.colors.white,
          },
          '&$selected': {
            borderColor: styles.colors.success300,
            backgroundColor: styles.colors.success300,
            color: styles.colors.white,

            '&:hover': {
              borderColor: styles.colors.success300,
              backgroundColor: styles.colors.success300,
              color: styles.colors.white,
            },
          },
        },
      },

      MuiTooltip: {
        arrow: {
          color: styles.colors.neutral300,
        },
        tooltip: {
          backgroundColor: styles.colors.white,
          color: styles.colors.black,
          maxWidth: TOOLTIP_MAX_WIDTH,
          fontSize: styles.typography.fontSizeS,
          border: `1px solid ${styles.colors.neutral300}`,
        },
      },
    },
  })

  muiTheme.overrides.MuiTab.root = {
    ...muiTheme.overrides.MuiTab.root,
    [muiTheme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
  }

  // TODO(mime): probably we should have our limits match Material-UI's
  // and not the other way around but I don't want to mess with this currently.
  muiTheme.breakpoints.values.sm = styles.limits.breakpointSmall
  muiTheme.breakpoints.values.md = styles.limits.breakpointLarge

  return muiTheme
}
