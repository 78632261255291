import {EMPTY_VIRTUAL_JOIN_DETAILS} from '../dashboard/events/create/util'
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  virtualJoinDetails: EMPTY_VIRTUAL_JOIN_DETAILS,
}

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    addVirtualJoinInformationToEvent(state, action) {
      state.virtualJoinDetails = {
        ...state.virtualJoinDetails,
        ...action.payload,
      }
    },
  },
})

export const {addVirtualJoinInformationToEvent} = eventSlice.actions

export default eventSlice.reducer
