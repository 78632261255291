import {backgroundVerticalShadowGradient} from './styles'
import styled from '@emotion/styled/macro'

/**
 * This component adds a shadow for a container that has overflow text
 * along with a Read More/Expand type behavior.
 * TODO(mime): probably in the future, we'd want to consolidate here
 * logic to just include the Read More functionality.
 */
const ShadowContainer = styled.div`
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    ${(props) => (props.collapsed ? 'display: block;' : 'display: none;')}
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 28px;
    ${backgroundVerticalShadowGradient}
    pointer-events: none;
  }
`

export default ShadowContainer
