import {isEqual, omit} from 'util/common'
import {useContext, useEffect, useState} from 'react'
// $FlowFixMe (mime): types need updating for latest react-router
import {useHistory, useLocation} from 'react-router'

import IntlMessageContext from 'app/IntlMessageContext'
import api from 'data/api'
import clientVars from '../util/clientVars'
import {getQueryParams} from 'util/url'
import usePrevious from 'hooks/usePrevious'

export default function useScreenData(oldScreenOpts) {
  const location = useLocation()
  const history = useHistory()
  const prevLocation = usePrevious(location)
  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [metadata, setMetadata] = useState({
    url_name: '',
    build_commit: '',
    page_title: '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const {intlMessageConfig, setIntlMessageConfig} = useContext(
    IntlMessageContext
  )

  useEffect(() => {
    async function fetch() {
      const {pathname, search} = location

      setIsLoading(true)
      setError(null)

      let response
      try {
        response = await api.fetchScreenResponse(
          pathname,
          getQueryParams(search),
          oldScreenOpts.cacheResponse
        )
        console.info('Loaded data from api', response)
      } catch (error) {
        if (error.isRedirect) {
          // Redirect to the specified url
          console.log('Redirecting:', error)
          const {pathname, search} = new URL(error.url)
          history.replace({
            pathname,
            search,
          })
        } else {
          setError(error)
          setIsLoading(false)
        }
        return {data, error, metadata, isLoading}
      }

      // NB: in development, we support HMR (hot module reloading).
      // Problem is that when the App reloads, especially on organizer side,
      // these values have been cleared away and the app crashes.
      // This is an effect of our screen data paradigm but we will rework that
      // in the future and this problem should go away along with that.
      // (instead of it being an HMR problem really).
      if (process.env.NODE_ENV === 'development') {
        window.__MLZ_CACHED_LOCALDEV_EMBEDDED_DATA__ = response
      }

      setData(response.data || {})
      setMetadata(
        response.metadata || {
          url_name: '',
          build_commit: '',
          page_title: '',
        }
      )
      // update intlMessageConfig, if needed
      if (
        intlMessageConfig.locale !== clientVars.default_locale ||
        (response.data?.locale &&
          response.data?.locale !== intlMessageConfig.locale) ||
        (response.data?.i18n_namespace &&
          response.data?.i18n_namespace !== intlMessageConfig.i18nNamespace)
      ) {
        setIntlMessageConfig({
          locale: response.data?.locale || clientVars.default_locale,
          i18nNamespace: response.data?.i18n_namespace || '',
        })
      }
      setIsLoading(false)
    }

    async function fetchDataWrapper() {
      if (prevLocation) {
        // This code path is for subsequent page navigations.

        // N.B. (jared) this only really gets called if we stay on the same route; otherwise, a new
        // instance of withScreenData gets mounted, since a new screen-level component is rendered.
        // This could probably be optimized to just have one data-fetcher that remains in the tree above
        // the route-by-route components, but it's probably fine for now
        if (
          location.pathname !== prevLocation.pathname ||
          !isEqual(
            omit(
              getQueryParams(location.search),
              oldScreenOpts.queryParamsToRemove
            ),
            omit(
              getQueryParams(prevLocation.search),
              oldScreenOpts.queryParamsToRemove
            )
          )
        ) {
          await fetch()
        }
      } else {
        // This code path is for the initial page load.

        // We have no way that I can find of telling from React Router that this
        // was a client vs. server-side nav, so we simply check for the presence
        // of embedded data. If present, we use that. If not we assume it's
        // client side and make the API call.
        const embedded = window.__MLZ_EMBEDDED_DATA__
        if (embedded) {
          console.info('Loading embedded data', embedded)
          const {data, error, metadata} = embedded
          setData(data || {})
          setError(error)
          setMetadata(
            metadata || {
              url_name: '',
              build_commit: '',
              page_title: '',
            }
          )
          setIsLoading(false)

          if (data && oldScreenOpts.cacheResponse) {
            const cacheKey = api.getResponseCacheKey(
              location.pathname,
              getQueryParams(location.search)
            )
            api.upsertScreenResponse(cacheKey, embedded)
          }
          delete window.__MLZ_EMBEDDED_DATA__
        } else {
          await fetch()
        }
      }
    }
    fetchDataWrapper()
  }, [
    data,
    error,
    metadata,
    isLoading,
    history,
    location,
    prevLocation,
    oldScreenOpts.cacheResponse,
    oldScreenOpts.queryParamsToRemove,
    intlMessageConfig,
    setIntlMessageConfig,
  ])

  return {data, error, metadata, isLoading}
}
