import {useContext, useState} from 'react'

import {ChatSnippetInitializationMode} from 'vendor/intercom/chatSnippetManager'
import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {Divider} from 'components'
import EventCampaignList from './EventCampaignList'
import {HostCommitmentSource} from 'app/enums'
import HostInterestForm from './HostInterestForm'
import {getHostFormUrlFromEventCampaign} from 'util/routing'
import {getQueryParams} from 'util/url'
import {hasActiveFeaturedEventCampaign} from 'util/organization'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import withOrgAnalytics from 'analytics/withOrgAnalytics'
import withScreenData from 'app/withScreenData'

const Container = styled.div`
  margin-top: ${styles.space.l};
  margin-bottom: ${styles.space.l};
`

function HostInterestScreen({
  data: {event_campaigns: eventCampaigns, training_event: trainingEvent},
  trackingParams,
  location,
  history,
}) {
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const queryParams = getQueryParams(location.search)
  const maybeRedirect = queryParams.redirect === 'true'
  // TODO(kayvon): remove backwards-compatibility hash check
  const [committedToHost, setCommittedToHost] = useState(
    queryParams.committed === 'true' || location.hash === '#event-campaigns'
  )

  const hostCommitmentSource =
    trackingParams.host_commitment_source ||
    HostCommitmentSource.EVENT_CAMPAIGN_DISCOVERY_PAGE

  // passed to the event create page as a url param
  const fallbackCreationSource = 'discovery_page_no_commit'
  const postCommitCreationSource = `post_commit_${hostCommitmentSource}`
  const getEventCreationSource = () => {
    return (
      trackingParams.event_creation_source ||
      (committedToHost ? postCommitCreationSource : fallbackCreationSource)
    )
  }

  const maybeGetEventCampaignForRedirect = () => {
    if (hasActiveFeaturedEventCampaign(currentOrganization)) {
      return currentOrganization.default_event_campaign
    }
    if (eventCampaigns.length === 1) {
      return eventCampaigns[0]
    }
    return null
  }

  const maybeRedirectToEventCreatePage = () => {
    const maybeDefaultEvent = maybeGetEventCampaignForRedirect()
    if (maybeDefaultEvent) {
      const {pathname, search} = getHostFormUrlFromEventCampaign(
        currentOrganization,
        maybeDefaultEvent,
        {
          event_creation_source:
            trackingParams.event_creation_source || postCommitCreationSource,
        }
      )
      history.push({pathname, search})
    }
  }

  if (maybeRedirect && committedToHost) {
    maybeRedirectToEventCreatePage()
  }

  const onHostInterestFormSuccess = ({canSkipTraining}) => {
    if (canSkipTraining) {
      maybeRedirectToEventCreatePage()
    }
    if (eventCampaigns.length > 0) {
      setCommittedToHost(true)
    }
  }

  return (
    <>
      {!committedToHost && (
        <Container>
          <HostInterestForm onSuccess={onHostInterestFormSuccess} />
        </Container>
      )}
      {(eventCampaigns.length > 0 || trainingEvent) && <Divider />}
      <Container>
        <EventCampaignList
          eventCreationSource={getEventCreationSource()}
          trainingEvent={trainingEvent}
          committedToHost={committedToHost}
          eventCampaigns={eventCampaigns}
        />
      </Container>
    </>
  )
}

export default withScreenData(withOrgAnalytics(HostInterestScreen), {
  chatSnippetInitializationMode:
    ChatSnippetInitializationMode.USE_CLIENT_CHAT_SNIPPET_IF_PRESENT_BUT_FALLBACK_TO_MOBILIZE_INTERCOM,
})
