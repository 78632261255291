/**
 * Accessor for data injected through the server.
 */

// Keep types in sync with lib.template.ClientVars. You may also wish to update
// setupTest.js's mock of this file if a new field is used in tests.

function getClientVars() {
  if (!window.__MLZ_CLIENT_VARS__) {
    console.error('No injected data found!!')
    // We don't want to hard fail in this case, but we also want Flow to treat
    // ClientVars as if it'll always be there, so we just ignore this type error
    // $FlowFixMe
    return {}
  }
  const clientVars = window.__MLZ_CLIENT_VARS__
  delete window.__MLZ_CLIENT_VARS__

  return Object.freeze(clientVars)
}

const clientVars = getClientVars()

export default clientVars
