import {Link} from 'components'

const MarkdownHelp = () => (
  <div>
    Format this text using{' '}
    <Link to="https://mobilize.us/s/markdown" target="_blank">
      Markdown
    </Link>
    .
  </div>
)

export default MarkdownHelp
