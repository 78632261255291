import {CustomSignupFieldType} from '../app/enums'
import {createSlice} from '@reduxjs/toolkit'

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
})

const initialState = {
  name: '',
  field_type: null,
  signup_form_label: '',
  choices: [],
  activist_code_ids: [],
}

const customFieldSlice = createSlice({
  name: 'customFieldFormValues',
  initialState,
  reducers: {
    loadExistingValues(state, action) {
      const result = Object.keys(initialState).reduce(
        (accumulator, currentKey) => {
          accumulator[currentKey] =
            action.payload[currentKey] ?? initialState[currentKey]
          return accumulator
        },
        {}
      )

      // Pull activist_code_ids up one level, out of its object
      // van_custom_field_configs.
      if (
        action.payload.van_custom_field_configs &&
        action.payload.van_custom_field_configs.activist_code_ids
      ) {
        result.activist_code_ids =
          action.payload.van_custom_field_configs.activist_code_ids
      }
      return result
    },
    setName(state, action) {
      state.name = action.payload
    },
    setFieldType(state, action) {
      if (action.payload !== CustomSignupFieldType.BOOLEAN) {
        state.activist_code_ids = []
      }
      if (action.payload !== CustomSignupFieldType.DROPDOWN) {
        state.choices = []
      }
      state.field_type = action.payload
    },
    setSignupFormLabel(state, action) {
      state.signup_form_label = action.payload
    },
    setChoices(state, action) {
      state.choices = [...new Set(action.payload)].sort(collator.compare)
    },
    addActivistCodeId(state, action) {
      state.activist_code_ids.push(action.payload)
    },
    removeActivistCodeId(state, action) {
      state.activist_code_ids = state.activist_code_ids.filter(
        (activist_code_id) => activist_code_id !== action.payload
      )
    },
    clear() {
      return initialState
    },
  },
})

export const {
  loadExistingValues,
  setName,
  setFieldType,
  setSignupFormLabel,
  setChoices,
  addActivistCodeId,
  removeActivistCodeId,
  clear,
} = customFieldSlice.actions
export default customFieldSlice.reducer
