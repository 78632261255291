import {Button, Image, Loader, ModalStepper, Typography} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {Fragment, useState} from 'react'
import styles, {mediaMinWidthSmall} from 'components/styles'

import {PromotionContext} from 'app/enums'
import analytics from 'analytics'
import api from 'data/api'
import {css} from '@emotion/react'
import {remapImageUrlToProxy} from 'util/image'
import styled from '@emotion/styled/macro'
import {useAsync} from 'hooks/async'

const messages = defineMessages({
  contextHeadline: {defaultMessage: 'More ways to promote'},
  orgLogoAltText: {defaultMessage: '{orgName} logo'},
})

// TODO(jared): this row/button structure is duplicated in the EventSignupChaining modal content. If
// we use it in a third place, it's probably time to formalize it as a top-level component

const OrgRow = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: ${styles.space.m};
  padding-bottom: ${styles.space.m};
  border-top: 1px solid ${styles.colors.neutral300};
  &:first-of-type {
    border-top: none;
    margin-top: ${styles.space.m};
  }
`

const ButtonWrapper = styled.div`
  width: 8rem;
  min-width: 8rem; // Prevent flexbox from crushing button
  ${mediaMinWidthSmall(css`
    width: 10rem;
    min-width: 10rem;
  `)}
  margin-left: ${styles.space.m};
`

const LogoWrapper = styled.div`
  width: 100%;
  max-width: 150px;
`

const DetailsWrapper = styled.div`
  flex-grow: 1;
`

const LoaderWrapper = styled.div`
  margin: ${styles.space.xl} 0;
`

function PromoteOrgRow({
  promotingOrg,
  orgToPromote,
  onPromotionSuccess,
  promotionContext,
}) {
  const intl = useIntl()
  const [isPromoted, setIsPromoted] = useState(false)

  async function onRequestPromote() {
    // Prevent button-mashing making multiple requests
    if (isPromoted) {
      return
    }

    // Optimistically try to show that the promotion succeeded
    setIsPromoted(true)
    try {
      await api.addOrgPromotion(
        promotingOrg.slug,
        orgToPromote.slug,
        promotionContext
      )
    } catch (_) {
      // Revert optimistic update on error. Could eventually show something to the user here
      setIsPromoted(false)
      return
    }

    analytics.trackPromotionCreated({
      promotingOrg,
      promotedOrg: orgToPromote,
      context: promotionContext,
    })
    // Communicate out that the promotion succeeded
    onPromotionSuccess()
  }

  return (
    <OrgRow>
      <DetailsWrapper>
        {orgToPromote.branding.logo_url && (
          <LogoWrapper>
            <Image
              ratio={1 / 2}
              resizeFitMode="contain"
              src={remapImageUrlToProxy(orgToPromote.branding.logo_url)}
              alt={intl.formatMessage(messages.orgLogoAltText, {
                orgName: orgToPromote.name,
              })}
            />
          </LogoWrapper>
        )}
        <Typography variant="h3" style={{marginTop: styles.space.s}}>
          {orgToPromote.name}
        </Typography>
      </DetailsWrapper>
      <ButtonWrapper>
        <Button
          fluid
          nowrap
          success={isPromoted}
          icon={isPromoted ? 'check' : undefined}
          iconPosition="left-inline"
          onClick={onRequestPromote}
        >
          {isPromoted ? (
            <F defaultMessage="Promoted" />
          ) : (
            <F defaultMessage="Promote" />
          )}
        </Button>
      </ButtonWrapper>
    </OrgRow>
  )
}

export default function PromotionSuggestionsModalStepper({
  organization,
  justPromotedOrg,
  onRequestClose,
  onPromote,
  showJustPromotedOrg = false,
  promotionContext = PromotionContext.SUGGESTIONS_MODAL,
}) {
  const shouldRender =
    organization.user_org_permissions.can_promote_organizations

  const orgSuggestionsPerPage = 6

  const intl = useIntl()
  const {loading, result, error} = useAsync(async () => {
    if (!shouldRender) {
      return {organizations: []}
    }
    return api.getOrgSuggestionsForPromotion({
      promotingOrgSlug: organization.slug,
      justPromotedOrgSlug: justPromotedOrg.slug,
      page: 1,
      per_page: orgSuggestionsPerPage * 2, // Fetch enough for two pages
    })
  }, [
    shouldRender,
    organization.slug,
    justPromotedOrg.slug,
    orgSuggestionsPerPage,
  ])

  const orgSuggestionsFromApi = result?.organizations || []
  const orgsToSuggest = showJustPromotedOrg
    ? [justPromotedOrg, ...orgSuggestionsFromApi]
    : orgSuggestionsFromApi

  if (error || (!loading && !orgsToSuggest.length) || !shouldRender) {
    return null
  }

  const pages = [
    orgsToSuggest.slice(0, orgSuggestionsPerPage),
    orgsToSuggest.slice(orgSuggestionsPerPage, orgSuggestionsPerPage * 2),
  ].filter((page) => page.length)

  return (
    <ModalStepper
      isOpen // Parents should control openness by conditional rendering
      onRequestClose={onRequestClose}
      contextHeadline={intl.formatMessage(messages.contextHeadline)}
    >
      {pages.map((orgsToSuggestByPage, i) => (
        <Fragment key={`page-${i}`}>
          <Typography variant="h1" center>
            <F defaultMessage="Promote all events by these organizations" />
          </Typography>
          {loading ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            orgsToSuggestByPage.map((orgToPromote) => (
              <PromoteOrgRow
                key={orgToPromote.id}
                promotingOrg={organization}
                orgToPromote={orgToPromote}
                onPromotionSuccess={() =>
                  onPromote(orgToPromote.id, orgToPromote.promotable_by_request)
                }
                promotionContext={promotionContext}
              />
            ))
          )}
        </Fragment>
      ))}
    </ModalStepper>
  )
}
