// Functions for managing the loaded intercom snippet across soft navigations

import {
  bootIntercom,
  installIntercomSnippet,
  shutdownIntercomIfPresent,
  updateIntercom,
} from './chatSnippetUtil'

let LOADED_INTERCOM_APP_ID

export const shutdownIntercomIfPresentAndClearLoadedAppId = () => {
  LOADED_INTERCOM_APP_ID = null
  shutdownIntercomIfPresent()
}

export const initializeIntercom = (
  appId,
  user,
  orgIsIndependent,
  mobilizeIntercomAppId
) => {
  let shouldBootIntercom = true

  if (!LOADED_INTERCOM_APP_ID) {
    installIntercomSnippet(appId)
  } else if (LOADED_INTERCOM_APP_ID === appId) {
    if (user) {
      // Don't reboot, but update so that Intercom will register the page change etc
      shouldBootIntercom = false
    } else {
      shutdownIntercomIfPresentAndClearLoadedAppId() // so that Intercom will be rebooted without the user data
    }
  } else if (LOADED_INTERCOM_APP_ID !== appId) {
    // Intercom is installed, but with the wrong appId, so shutdown before we reboot with
    // the app ID we need.
    shutdownIntercomIfPresentAndClearLoadedAppId()
  }

  const maybeUserData =
    !!user && appId === mobilizeIntercomAppId
      ? {
          user_id: `${user.id}`, // stringify the id because Intercom requires such
          user_hash: orgIsIndependent
            ? user.intercom_independent_user_hash
            : user.intercom_coordinated_user_hash,
        }
      : null

  shouldBootIntercom
    ? bootIntercom(appId, maybeUserData)
    : updateIntercom(maybeUserData)
  LOADED_INTERCOM_APP_ID = appId
}
