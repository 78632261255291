import {Button, Link} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {useEffect, useState} from 'react'

import {getFilterCount} from 'util/feed'
import {getOrganizationFeedMapUrl} from 'util/routing'
import {getQueryParams} from 'util/url'
import {searchTags} from 'vendor/algolia'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
// $FlowFixMe: types need updating for latest react-router
import {useLocation} from 'react-router'

const messages = defineMessages({
  filtersDescription: {
    defaultMessage: 'Filters ({filterCount} selected)',
  },
  mapViewDescription: {
    defaultMessage: 'Show map view',
  },
})

const FilterPanelButtonWrapper = styled.div`
  margin-right: ${styles.space.m};
  margin-bottom: ${styles.space.m};
  display: inline-block;
`

export default function FilterPanelButtons({
  orgTagIds,
  onSubmit,
  filterParams,
  onRequestExpandFilters,
  organization,
}) {
  const intl = useIntl()
  const [tags, setTags] = useState([])
  const location = useLocation()
  const {zoom: mapZoomParam} = getQueryParams(location.search)

  const toggleTagFilter = (event) => {
    const tagId = parseInt(event.currentTarget.value)
    const selectedTagIds = filterParams?.tagIds
    let updatedSelected
    if (!selectedTagIds) {
      updatedSelected = []
    } else if (isTagSelected(tagId)) {
      updatedSelected = selectedTagIds.filter((tId) => tId !== tagId)
    } else {
      updatedSelected = selectedTagIds.concat(tagId)
    }

    onSubmit({...filterParams, tagIds: updatedSelected})
  }

  const isTagSelected = (tagId) => {
    return filterParams?.tagIds && filterParams.tagIds.includes(tagId)
  }

  useEffect(() => {
    let mounted = true
    async function getTags() {
      try {
        if (orgTagIds.length) {
          const tags = await searchTags('', orgTagIds.slice(0, 5), null)
          // this makes sure the component is still mounted before we set state
          if (mounted) {
            setTags(tags)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
    getTags()
    return () => {
      mounted = false
    }
  }, [orgTagIds])

  const filterCount = getFilterCount(filterParams, {excludeFilters: ['tagIds']})
  const hasFilters = filterCount > 0
  const filtersDescription = intl.formatMessage(messages.filtersDescription, {
    filterCount,
  })
  const mapViewDescription = intl.formatMessage(messages.mapViewDescription)

  const feedMapUrl = getOrganizationFeedMapUrl(
    organization,
    {...filterParams, maxDist: null},
    {
      zoom: mapZoomParam || undefined,
    }
  )

  return (
    <>
      <FilterPanelButtonWrapper>
        <Link plain to={feedMapUrl}>
          <Button
            secondary
            icon="map"
            iconPosition="left"
            aria-label={mapViewDescription}
          >
            <F defaultMessage="Map" />
          </Button>
        </Link>
      </FilterPanelButtonWrapper>
      <FilterPanelButtonWrapper>
        <Button
          onClick={onRequestExpandFilters}
          secondary
          icon="sliders-h"
          iconPosition="left"
          aria-label={filtersDescription}
          selected={hasFilters}
        >
          {hasFilters ? (
            <F
              defaultMessage="Filters · {filterCount}"
              values={{filterCount}}
            />
          ) : (
            <F defaultMessage="Filters" />
          )}
        </Button>
      </FilterPanelButtonWrapper>

      {tags.map((tag) => {
        const isSelected = isTagSelected(tag.id)
        return (
          <FilterPanelButtonWrapper key={tag.id}>
            <Button
              secondary
              onClick={toggleTagFilter}
              selected={isSelected}
              value={tag.id}
              icon={isSelected ? 'check' : undefined}
              iconPosition="left"
            >
              {tag.name}
            </Button>
          </FilterPanelButtonWrapper>
        )
      })}

      <FilterPanelButtonWrapper>
        <Button secondary onClick={onRequestExpandFilters}>
          More filters...
        </Button>
      </FilterPanelButtonWrapper>
    </>
  )
}
