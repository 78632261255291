import {Button, FormItem, Link, Message, MessageType} from 'components'

import ImagePreview from './ImagePreview'
import ImageUpload from './ImageUpload'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useState} from 'react'

const SocialImageWarningWrapper = styled.div`
  max-width: 40rem;
  margin-top: ${styles.space.s};
`

const MAX_SIZE_FOR_SOCIAL_MEDIA_IN_MB = 8 // 8MB is what FB allows you to upload
const MIN_RECOMMENDED_WIDTH_FOR_SOCIAL_MEDIA_IN_PX = 1200 // 1200 pixels is a recommended minimum

function ImageUploadWithPreview({
  hint,
  imageIsForSocial,
  imageUrl,
  label,
  onChange,
  resource,
  useStdAspectRatioPreview,
}) {
  const [imageWidth, setImageWidth] = useState(null)

  const clearPhoto = () => {
    onChange('')
    setImageWidth(null)
  }

  const onImageLoaded = (e) => {
    setImageWidth(
      e.target &&
        // $FlowFixMe: Flow doesn't know that EventTargets have naturalWidth, but images do so it's fine
        e.target.naturalWidth &&
        typeof e.target.naturalWidth === 'number'
        ? e.target.naturalWidth
        : null
    )
  }

  return (
    <FormItem label={label} hint={hint}>
      {imageUrl ? (
        <>
          <Button
            error
            icon="times"
            iconPosition="left"
            onClick={clearPhoto}
            fitContent
          >
            Clear photo
          </Button>

          <ImagePreview
            src={imageUrl}
            useStdAspectRatioPreview={useStdAspectRatioPreview}
            onImageLoaded={onImageLoaded}
          />
          {imageIsForSocial &&
            !!imageWidth &&
            imageWidth < MIN_RECOMMENDED_WIDTH_FOR_SOCIAL_MEDIA_IN_PX && (
              <SocialImageWarningWrapper>
                <Message type={MessageType.WARNING}>
                  Per{' '}
                  <Link to="https://developers.facebook.com/docs/sharing/webmasters/images/">
                    Facebook's guidelines
                  </Link>{' '}
                  we recommend you upload an image that is at least{' '}
                  {MIN_RECOMMENDED_WIDTH_FOR_SOCIAL_MEDIA_IN_PX}px wide.
                </Message>
              </SocialImageWarningWrapper>
            )}
        </>
      ) : (
        <ImageUpload
          onChange={onChange}
          imageUrl={imageUrl}
          resource={resource}
          maximumSizeInMegabytes={
            imageIsForSocial ? MAX_SIZE_FOR_SOCIAL_MEDIA_IN_MB : undefined
          }
        />
      )}
    </FormItem>
  )
}

ImageUploadWithPreview.defaultProps = {imageIsForSocial: false}
export default ImageUploadWithPreview
