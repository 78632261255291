import {createContext} from 'react'
import clientVars from 'util/clientVars'

// used to update locale && i18nNamespace inside of `useScreenData`,
// to trigger view-specific locale & i18nNamespace message overrides
export default createContext<{
  setI18nMessageConfig: () => void,
  intlMessageConfig: {
    locale: 'en' | 'es',
    i18nNamespace: string}
}>({
  setI18nMessageConfig: () => {},
  intlMessageConfig: { locale: clientVars.default_locale, i18nNamespace: '' },
})
