import {F, defineMessages, useIntl} from 'util/i18n'
import {getEventTypeAffinityChecks, isDonationCampaignEvent} from 'util/event'
import {useContext, useState} from 'react'

import AddGroupMembers from 'events/modals/content/AddGroupMembers'
import CurrentUserContext from 'app/CurrentUserContext'
import Donate from './content/Donate'
import {EventChainingAction} from 'app/enums'
import EventSignupChaining from './content/EventSignupChaining'
import GroupInvite from './content/GroupInvite'
import JoinOwningGroup from 'events/modals/content/JoinOwningGroup'
import ModalStepper from 'components/ModalStepper'
import {ModalTypes} from './enums'
import Share from './content/Share'

const messages = defineMessages({
  contextHeadline: {
    defaultMessage: 'More ways to help',
  },
})

const getAdditionalNotification = (event, chainingAction, donationAmount) => {
  const {
    isDonationCampaign,
    isPromptForDonation,
    isRegistrationOnly,
  } = getEventTypeAffinityChecks(event)

  if (chainingAction === EventChainingAction.SIGNED_UP) {
    return isDonationCampaign && donationAmount ? (
      <F
        defaultMessage="We've emailed you a receipt for your ${donationAmount} donation." // eslint-disable-line no-template-curly-in-string
        values={{donationAmount}}
      />
    ) : isPromptForDonation && donationAmount ? (
      <F
        defaultMessage="We've emailed you a receipt for your ${donationAmount} donation, along with instructions for how to join this event." // eslint-disable-line no-template-curly-in-string
        values={{donationAmount}}
      />
    ) : event.is_virtual && !isRegistrationOnly ? (
      <F defaultMessage="We've emailed you instructions for how to join." />
    ) : null
  }
  return null
}

const getHeaderPreamble = (event, chainingAction) => {
  // Note that we only care about the actions that can be taken from the web, since the SMS types
  // already have an acknowledgement of the action in the SMS conversation
  if (chainingAction === EventChainingAction.CANCELLED) {
    return <F defaultMessage="Thanks for letting us know." />
  }
  if (chainingAction === EventChainingAction.CONFIRMED) {
    return <F defaultMessage="Thanks for confirming." />
  }
  if (chainingAction === EventChainingAction.LEFT_POSITIVE_FEEDBACK) {
    return <F defaultMessage="Thanks for your feedback." />
  }
  if (chainingAction === EventChainingAction.SIGNED_UP) {
    return isDonationCampaignEvent(event) ? (
      <F defaultMessage="Thanks for donating!" />
    ) : event.is_virtual_flexible ? (
      <F defaultMessage="Thank you for your support." />
    ) : (
      <F defaultMessage="You're signed up." />
    )
  }
  return null
}

export default function ChainingModalStepper({
  chainingAction,
  donationAmount,
  event,
  identityFields,
  isOpen,
  linkForCopy,
  linkForGroupShare,
  linkForNativeShare,
  modals,
  onRequestClose,
  organization,
  owningGroups = [],
  recommendedTimeslots,
  shareLink,
  shareModalType,
  skipHeaderPreamble,
  trackingParams,
  groupSignupInfo,
  userId,
}) {
  // we still pass through groups past their end date so filter them out here
  // TODO(kayvon): figure out how to better handle "expired" groups on the backend
  const owningGroupsStillUpcoming = owningGroups.filter(
    (g) => g.times.length !== 0
  )
  function filterOutUnneededModals(modal) {
    if (modal === ModalTypes.SIGNUP && recommendedTimeslots.length < 1) {
      return false
    } else if (
      modal === ModalTypes.LINKED_GROUP &&
      owningGroupsStillUpcoming.length < 1
    ) {
      return false
    }
    return true
  }

  const filteredModals = modals.filter(filterOutUnneededModals)
  const [hasCompletedSignupModal, setHasCompletedSignupModal] = useState(false)
  const {user} = useContext(CurrentUserContext)

  const intl = useIntl()

  return (
    <ModalStepper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contextHeadline={intl.formatMessage(messages.contextHeadline)}
      shouldIncrementDisplayCount={hasCompletedSignupModal}
    >
      {filteredModals.map((modalType, index) => {
        const maybeHeaderPreamble =
          index === 0 && !skipHeaderPreamble
            ? getHeaderPreamble(event, chainingAction)
            : ''
        const maybeAdditionalNotification =
          index === 0
            ? getAdditionalNotification(event, chainingAction, donationAmount)
            : ''
        switch (modalType) {
          case ModalTypes.SHARE:
            return (
              <Share
                isOpen={isOpen}
                headerPreamble={maybeHeaderPreamble}
                additionalNotification={maybeAdditionalNotification}
                shareLink={shareLink}
                linkForCopy={linkForCopy}
                linkForNativeShare={linkForNativeShare}
                event={event}
                userFirstName={identityFields.firstName}
                modalType={shareModalType}
                chainingAction={chainingAction}
                key={ModalTypes.SHARE}
              />
            )

          case ModalTypes.SIGNUP:
            return (
              <EventSignupChaining
                headerPreamble={maybeHeaderPreamble}
                additionalNotification={maybeAdditionalNotification}
                trackingParams={trackingParams}
                organization={organization}
                isOpen={isOpen}
                action={chainingAction}
                timeslots={recommendedTimeslots}
                signupIdentityFields={identityFields}
                onSignup={(signedUpForTimeslotIds) => {
                  if (
                    // we ask to sign up for 3 more timeslots maximum even if more are available
                    signedUpForTimeslotIds.length ===
                    Math.min(3, recommendedTimeslots.length)
                  ) {
                    setHasCompletedSignupModal(true)
                  }
                }}
                key={ModalTypes.SIGNUP}
              />
            )

          case ModalTypes.DONATE:
            return (
              <Donate
                event={event}
                isOpen={isOpen}
                action={chainingAction}
                userId={user?.id}
                headerPreamble={maybeHeaderPreamble}
                additionalNotification={maybeAdditionalNotification}
                key={ModalTypes.DONATE}
              />
            )

          case ModalTypes.GROUP_INVITE:
            return groupSignupInfo && userId ? (
              <AddGroupMembers
                groupShareLink={linkForGroupShare || ''}
                event={event}
                modalType="post_signup_modal"
                isPostSignup
                organization={organization}
                timeslotId={groupSignupInfo.timeslot_id}
                referringDataSignature={groupSignupInfo.signature}
                referringParticipationId={
                  groupSignupInfo.referring_participation
                }
                referringUserId={userId}
                key={ModalTypes.GROUP_INVITE}
                numUnclaimedGroupSpots={
                  groupSignupInfo.num_unclaimed_reserved_participations
                }
              />
            ) : (
              <GroupInvite
                groupShareLink={linkForGroupShare || ''}
                event={event}
                modalType="post_signup_modal"
                isPostSignup
                key={ModalTypes.GROUP_INVITE}
              />
            )

          case ModalTypes.LINKED_GROUP:
            return (
              <JoinOwningGroup
                signupIdentityFields={identityFields}
                owningGroups={owningGroups}
                organization={organization}
                key={ModalTypes.LINKED_GROUP}
              />
            )

          default:
            break
        }
        return null
      })}
    </ModalStepper>
  )
}
