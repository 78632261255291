import styles, {mediaMinWidthSmall} from 'components/styles'

import {Image} from 'components'
import {maybeGetPlaceholderImage} from 'util/event'
import styled from '@emotion/styled/macro'

const ImageWrapper = styled.div`
  ${mediaMinWidthSmall(`
    margin-bottom: ${styles.space.m};
    border: 1px solid ${styles.colors.neutral300};
  `)}
`

function DetailsImage({imageAltText, eventType, url}) {
  const {placeholderUrl, placeholderSrcSet} = maybeGetPlaceholderImage(
    eventType
  )
  if (!url && !placeholderUrl) {
    return null
  }

  return (
    <ImageWrapper>
      <Image
        src={url || placeholderUrl || ''}
        srcSet={url || placeholderSrcSet || ''}
        maxWidth={styles.limits.imageMaxWidth}
        alt={imageAltText}
      />
    </ImageWrapper>
  )
}

export default DetailsImage
