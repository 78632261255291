// NOTE: if you update this file, you need to run `make generatestyles` to update Colors.css

// Primary
const primary = {
  primary100: '#eae9ff',
  primary200: '#4642ff', // Primary action, accent color, Links, Secondary button text
  primary300: '#16147f', // Button hover, Link hover
}

// Default/Neutrals
const neutral = {
  neutral100: '#f9f9f9', // background color, panel background color
  neutral200: '#f2f2f2',
  neutral300: '#ccc', // borders, horizontal rules, table lines
  neutral400: '#666', // help text, secondary text, subtitles, form field outlines, icons
  neutral500: '#323232', // Icon button, icons (hover)
}

// Destroy/Error
const error = {
  error100: '#fde7e5', // Error banner background
  error200: '#ec1000', // Destructive action, Form validation error text
  error300: '#ed0900', // Destroy button hover
}

// Caution/Alert
const caution = {
  caution100: '#fff0dc', // Alert banner background
  caution200: '#ffb450',
  caution300: '#ff7830',
}

// Success
const success = {
  success100: '#e5f6f0', // Success banner background
  success200: '#00aa70',
  success300: '#00774e', // Text: successful action performed, Success toast
}

const hint = {
  hint100: '#EAE9FF', // hint banner background,
  hint200: '#4642FF', // hint border color
}

// Basics
const basics = {
  white: '#ffffff', // Text, Backgrounds
  faintTransparentGrey: 'rgba(0, 0, 0, 0.04)',
  transparentGrey: 'rgba(0, 0, 0, 0.1)',
  transparentBlack: 'rgba(0, 0, 0, 0.75)',
  black: '#000', // Body copy, Headers, Form labels
}

const accents = {
  blue: '#2094ff',
  teal: '#00c6ba',
  green: '#a6d459',
  magenta: '#e30088',
  purple: '#9428ff',
  pink: '#e30088',
  lightPink: '#ffeaf6',
  lightBlue: '#deefff',
}

const thirdParty = {
  facebookBlue: '#3b5998',
  googleBlue: '#4285f4',
}

const colors = {
  ...primary,
  ...neutral,
  ...error,
  ...caution,
  ...success,
  ...hint,
  ...basics,
  ...accents,
  ...thirdParty,
}
export default colors

export const storybook = {
  primary,
  neutral,
  error,
  caution,
  success,
  hint,
  basics,
  accents,
  thirdParty,
}
