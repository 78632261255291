import {
  ToggleButton as MaterialToggleButton,
  ToggleButtonGroup as MaterialToggleButtonGroup,
} from '@material-ui/lab'

import FormItem from './FormItem'
import {classnames} from 'util/common'
import {makeStyles} from '@material-ui/core/styles'
import styles from './styles'

const useStyles = makeStyles((theme) => ({
  twoColumn: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  twoColumnGrouped: {
    flex: `0 0 calc(50% - ${styles.space.s})`,
    marginBottom: styles.space.m,
    borderRadius: `${styles.space.borderRadius} !important`,
  },
  twoColumnGroupedWithHint: {
    '&:nth-last-child(1)': {
      marginBottom: 0,
    },
    '&:nth-last-child(2)': {
      marginBottom: 0,
    },
  },
}))

export const ToggleButton = MaterialToggleButton
ToggleButton.displayName = 'ToggleButton'

export const ToggleButtonGroup = ({
  children,
  exclusive,
  label,
  twoColumn,
  hint,
}) => {
  const classes = useStyles()

  return (
    <FormItem label={label} hint={hint}>
      <MaterialToggleButtonGroup
        exclusive={exclusive}
        classes={
          twoColumn && {
            root: classes.twoColumn,
            grouped: classnames(
              classes.twoColumnGrouped,
              hint ? classes.twoColumnGroupedWithHint : undefined
            ),
          }
        }
      >
        {children}
      </MaterialToggleButtonGroup>
    </FormItem>
  )
}
