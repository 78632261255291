import {Button} from 'components'
import {GoogleLoginButtonOptions} from '../../app/enums'
import styles from 'components/styles'

/**
 * Button used for Google login
 */
function GoogleLoginButton(props) {
  const propTypeCheck = (propType) => {
    if (
      propType === GoogleLoginButtonOptions.LOGIN ||
      propType === GoogleLoginButtonOptions.LOGIN_WITH_AHA
    ) {
      return true
    }
    return false
  }

  const _onClick = async (evt) => {
    evt.preventDefault()
    const {onClick, type} = props

    if (typeof onClick === 'function') {
      onClick()
    }
    //NB: We don't actually call the login api from here. This is a hacky way to tell Page.js which endpoint to hit
    window.googleAuthClient.o.state = type

    await window.googleAuthClient.requestCode()
  }

  return (
    <Button
      onClick={_onClick}
      color={styles.colors.googleBlue}
      fluid={props.fluid}
      icon="google"
      iconPosition="left"
      iconFontSize="1.5rem"
      children={
        propTypeCheck(props.type)
          ? 'Continue with Google'
          : 'Link Google account'
      }
    />
  )
}

export default GoogleLoginButton
