import {mediaMinWidthLarge} from './styles'
import styled from '@emotion/styled/macro'

/** Utility component to hide contents below small breakpoint */
const HideBelowLargeScreen = styled.div`
  display: none;
  ${mediaMinWidthLarge('display: block;')};
`

export default HideBelowLargeScreen
