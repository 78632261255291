import {Carousel, FeedItem} from 'events/components'
import {defineMessages, useIntl} from 'util/i18n'

import {CarouselCategory} from 'app/enums'
import api from 'data/api'
import cv from 'util/clientVars'
import flag from 'experiments/flag'
import {getOrganizationFeedUrl} from 'util/routing'
import {maybeGetPlaceholderLogo} from 'util/organization'
import {remapImageUrlToProxy} from 'util/image'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useAsync} from 'hooks/async'
import {useInView} from 'react-intersection-observer'

const messages = defineMessages({
  orgsWithUpcomingEvents: {
    defaultMessage: 'Organizations with upcoming events',
  },
  orgSuggestionFeedItemAriaLabel: {
    defaultMessage: 'See upcoming events for {orgName}',
  },
  orgSuggestionFeedItemSubhead: {
    defaultMessage:
      '{numUpcomingEvents, plural, one {# upcoming event} other {# upcoming events}}',
  },
  orgCarouselSeeMoreHeader: {
    defaultMessage: 'Get your organization on {productName}',
  },
  orgCarouselSeeMoreButtonText: {
    defaultMessage: 'Learn more',
  },
})

// todo(kayvon): remove uses of these classnames and add more granular props to FeedItem for controlling these styles
const OrgSuggestionsOuterWrapper = styled.div`
  & .mbz-feed-item {
    width: 210px;
    height: 210px;
    vertical-align: middle;
    margin: ${styles.space.m};
    scroll-snap-align: center;
    white-space: normal;
  }

  & .mbz-feed-item-container {
    height: 100%;
  }
`

function OrgSuggestionFeedItem({currentOrg, orgSuggestion}) {
  const intl = useIntl()
  const ariaLabel = intl.formatMessage(
    messages.orgSuggestionFeedItemAriaLabel,
    {orgName: orgSuggestion.organization.name}
  )
  const feedUrl = getOrganizationFeedUrl(currentOrg, {
    selectedOrgs: [orgSuggestion.organization.id],
  })
  const {placeholderUrl, placeholderSrcSet} = maybeGetPlaceholderLogo()
  const imageUrl = remapImageUrlToProxy(
    orgSuggestion.organization.branding.logo_url
  )

  return (
    <FeedItem
      aria-label={ariaLabel}
      headline={orgSuggestion.organization.name}
      subhead={intl.formatMessage(messages.orgSuggestionFeedItemSubhead, {
        numUpcomingEvents: orgSuggestion.upcoming_event_count,
      })}
      imageUrl={imageUrl || placeholderUrl}
      imageSrcSet={imageUrl || placeholderSrcSet}
      imageIsLogo
      linkTo={feedUrl}
      isCompactFeedItem
      showCompactDivider
    />
  )
}

const CAROUSEL_CONFIG = {
  [CarouselCategory.ORGS_WITH_UPCOMING_EVENTS]: {
    title: messages.orgsWithUpcomingEvents,
    getFilterParamsPartial: () => ({}),
  },
}

export default function OrgSuggestionsCarousel({
  carouselCategory,
  organization,
  shouldDelayLoad,
}) {
  const intl = useIntl()
  const [ref, inView] = useInView({
    rootMargin: '100px 0px',
    triggerOnce: true,
  })
  const config = CAROUSEL_CONFIG[carouselCategory]

  const shouldFetchAndRender =
    (!shouldDelayLoad || inView) && !flag.isActive('kill_org_carousels')

  const {result: orgSuggestionsResponse, loading, error} = useAsync(() => {
    const maybeFilterParamsPartial = config.getFilterParamsPartial({
      organization,
      currentEvent: null,
    })
    if (!maybeFilterParamsPartial || !shouldFetchAndRender) {
      return Promise.resolve(null)
    }
    return api.getOrgSuggestionsForCarousel(
      organization.slug,
      maybeFilterParamsPartial
    )
  }, [organization, config])

  const header = intl.formatMessage(config.title)

  const orgSuggestions = orgSuggestionsResponse?.organization_suggestions || []

  // don't show the carousel with only 1 org
  if (!loading && (error || orgSuggestions.length < 2)) {
    return <div ref={shouldDelayLoad ? ref : null} />
  }

  return (
    <div ref={shouldDelayLoad ? ref : null}>
      <OrgSuggestionsOuterWrapper>
        <Carousel
          carouselId={carouselCategory}
          header={header}
          isLoading={loading}
          items={orgSuggestions}
          itemRenderFn={(item) => (
            <OrgSuggestionFeedItem
              currentOrg={organization}
              orgSuggestion={item}
            />
          )}
          itemKeyFn={(item) => item.organization.id}
          actionUrl={
            organization.is_mobilize
              ? 'https://join.mobilize.us/get-started?utm_source=mobilize&utm_medium=referral&utm_campaign=carousel'
              : undefined
          }
          actionItemButtonLabel={intl.formatMessage(
            messages.orgCarouselSeeMoreButtonText
          )}
          actionItemHeader={intl.formatMessage(
            messages.orgCarouselSeeMoreHeader,
            {productName: cv.product_name}
          )}
          dontLinkHeader
          alwaysShowActionItem
        />
      </OrgSuggestionsOuterWrapper>
    </div>
  )
}
