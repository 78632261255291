import {css} from '@emotion/react'
import {getPrimaryColorFromProps} from './styles'
import isPropValid from '@emotion/is-prop-valid'
import {keyframes} from '@emotion/react'
import styled from '@emotion/styled/macro'

const loaderOuterKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const loaderInner1Keyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(220deg);
  }
`

const loaderInner2Keyframe = keyframes`
  0% {
    transform: rotate(-140deg);
  }
  50% {
    transform: rotate(-160deg);
  }
  100% {
    transform: rotate(140deg);
  }
`

const SIZE_LARGE = 80
const SIZE_DEFAULT = 32
const getSize = (large) => (large ? SIZE_LARGE : SIZE_DEFAULT)

const BORDER_WIDTH_LARGE = 6
const BORDER_WIDTH_DEFAULT = 3
const getBorderWidth = ({large}) =>
  large ? BORDER_WIDTH_LARGE : BORDER_WIDTH_DEFAULT

const heightAndWidthCss = ({large}) => {
  const size = getSize(large)
  return css`
    height: ${size}px;
    width: ${size}px;
  `
}

const commonInnerCss = ({large}) => {
  const size = getSize(large)
  return css`
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    ${heightAndWidthCss({large})};
    clip: rect(0, ${size}px, ${size}px, ${size / 2}px);
  `
}

// `size` is a valid attribute (for inputs), but we shouldn't put that
// on a div, so filter it out
const shouldForwardProp = (prop) => prop !== 'size' && isPropValid(prop)

const LoaderOuter = styled('div', {shouldForwardProp})`
  margin: 0 auto;
  ${heightAndWidthCss}
  animation: ${loaderOuterKeyframe} 4.8s linear infinite;
`

const LoaderInner = styled('div', {shouldForwardProp})`
  ${commonInnerCss};
  animation: ${loaderInner1Keyframe} 1.2s linear infinite;

  ::after {
    content: '';
    ${commonInnerCss};
    border: ${getBorderWidth}px solid ${getPrimaryColorFromProps};
    border-radius: 50%;
    animation: ${loaderInner2Keyframe} 1.2s cubic-bezier(0.77, 0, 0.175, 1)
      infinite;
  }
`

/**
 * A slightly fancy loader which can pick up branding colors from a theme provider,
 * (usually `OrganizationThemeProvider`).
 *
 * Credit: https://codepen.io/rbv912/pen/dYbqLQ
 */
const Loader = (props) => (
  <LoaderOuter {...props}>
    <LoaderInner {...props} />
  </LoaderOuter>
)

export default Loader
