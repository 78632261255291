import {UsStates, UsStatesAndTerritories} from 'app/enums'

import {Autocomplete} from 'components/'

const getStateOrTerritoryOptions = (excludeTerritories) => {
  let entries
  if (excludeTerritories) {
    entries = Object.entries(UsStates)
  } else {
    entries = Object.entries(UsStatesAndTerritories)
  }
  return (
    entries
      // $FlowFixMe Object.entries thinks values are mixed too :-/
      .map(([stateOrCountryCode, name]) => ({
        value: stateOrCountryCode,
        text: name,
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  )
}

export default function StateOrTerritoryDropdown({
  statesOrTerritories,
  onStatesOrTerritoriesChange,
  excludeTerritories,
}) {
  function onChange(e, {value}) {
    onStatesOrTerritoriesChange(value)
  }

  return (
    <Autocomplete
      multiple
      label="State or territory"
      name="state_or_territory"
      placeholder="Choose one or more…"
      options={getStateOrTerritoryOptions(excludeTerritories)}
      value={statesOrTerritories}
      onChange={onChange}
    />
  )
}
