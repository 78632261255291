import {ActionButtons, Button, Typography} from 'components'

import EditableEventSuggestion from './EditableEventSuggestion'
import {Link} from 'components'
import {MAX_EVENT_SUGGESTIONS} from 'app/constants'
import cv from 'util/clientVars'
import eventSuggestionsReducer from './EventSuggestions.reducer'
import {filterFalsy} from 'util/common'
import {range} from 'lodash'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useReducer} from 'react'

const Container = styled.div`
  padding-bottom: ${(props) => props.paddingBottom || '0'};
  padding-top: ${(props) => props.paddingTop || '0'};
`

const MarginBottom = styled.div`
  display: block;
  margin-bottom: ${styles.space.l};
`

const SuggestionsWrapper = styled.div`
  max-height: 400px;
  padding: ${styles.space.s};
  overflow-y: scroll;
`

const HELP_URL = 'https://mblz.io/customize-your-event-suggestions'
const Explainer = () => (
  <Typography variant="subtitle1">
    Suggestions may be shown to attendees in a different order, depending on
    their past attendance history. {cv.product_name} may add more event
    suggestions below your customized suggestions.{' '}
    <Link target="_blank" to={HELP_URL}>
      Learn more
    </Link>
  </Typography>
)

export default function EventSuggestions(props) {
  const {
    organization,
    explainerAtTop,
    paddingBottom,
    paddingTop,
    onCancel,
    onSubmit,
    eventSuggestions,
    sourceIsOrganization = false,
    submitButtonText = 'Update',
    minimumInputsToRender = 3,
    groupSuggestions,
  } = props
  const [state, dispatch] = useReducer(eventSuggestionsReducer, {
    eventSuggestions: [...eventSuggestions], // i dunno why this appeases flow but it does
    pendingCount: eventSuggestions.length, // they are all loading to begin with
    errors: range(MAX_EVENT_SUGGESTIONS).map(() => null),
  })

  const canSubmit = state.pendingCount === 0
  const inputsToRenderUncapped = Math.max(
    minimumInputsToRender,
    state.eventSuggestions.length + 1
  )
  const numInputsToRender = groupSuggestions
    ? inputsToRenderUncapped
    : Math.min(MAX_EVENT_SUGGESTIONS, inputsToRenderUncapped)

  const suggestionsOrder = range(numInputsToRender).sort((first, second) => {
    const indexToNumber = (i) => (state.eventSuggestions[i] ? 1 : -1)
    // non-null suggestion inputs should be ordered before empty ones
    const priorityRankByNonNull = indexToNumber(first) - indexToNumber(second)
    // empty inputs should be in natural order by index, and non-null suggestions should be in reverse order
    const fallbackRank =
      indexToNumber(first) > 0 ? second - first : first - second
    return priorityRankByNonNull !== 0 ? priorityRankByNonNull : fallbackRank
  })

  // only one empty input should be visible, unless minimumInputsToRender is less than the number of filled inputs
  const numFilledInputs = suggestionsOrder.filter(
    (i) => !!state.eventSuggestions[i]
  ).length
  const numVisibleEmptyInputs =
    Math.max(minimumInputsToRender, numFilledInputs + 1) - numFilledInputs
  const canBeEmptyInputs = suggestionsOrder.slice(0, numVisibleEmptyInputs)

  return (
    <Container paddingBottom={paddingBottom} paddingTop={paddingTop}>
      {explainerAtTop && !groupSuggestions && (
        <MarginBottom>
          <Explainer />
        </MarginBottom>
      )}
      <SuggestionsWrapper>
        {organization &&
          suggestionsOrder
            .filter(
              (i) => !!state.eventSuggestions[i] || canBeEmptyInputs.includes(i)
            )
            .map((i) => (
              <EditableEventSuggestion
                key={i}
                index={i} // needed to properly dispatch
                eventSuggestion={state.eventSuggestions[i] || null}
                error={state.errors[i]}
                organization={organization}
                // We use a dispatch function here instead of individual callbacks (e.g.,
                // "onAddSuggestion") because the dispatch function's identity is constant, so it can
                // safely be used inside of useEffect hooks without declaring it as a dependency (vs.
                // declaring prop callbacks as dependencies, which, when their identity changes, rerun
                // effect hooks, which would be Bad--we only want them to rerun when certain data changes)
                dispatch={dispatch}
                sourceIsOrganization={sourceIsOrganization}
                dontAllowFeeds={groupSuggestions}
                allowVirtualFlexible={groupSuggestions}
              />
            ))}
      </SuggestionsWrapper>
      {!explainerAtTop && !groupSuggestions && <Explainer />}
      <ActionButtons>
        <Button secondary type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="button"
          // if event suggestions have errors, etc
          disabled={!canSubmit}
          onClick={(e) => {
            e.preventDefault()
            onSubmit(filterFalsy(state.eventSuggestions))
          }}
        >
          {submitButtonText}
        </Button>
      </ActionButtons>
    </Container>
  )
}
