import {Button, InputGroup, Link, Typography} from 'components'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import FeedItem from 'events/components/FeedItem'
import SignupButton from 'events/components/SignupButton'
import {filterFalsy} from 'util/common'
import {filterOutAtCapacityTimeslots} from 'util/timeslot'
import {getOneLineAddress} from 'util/geo'
import {getOrganizationEventUrl} from 'util/routing'
import {isEveryIdentityFieldFilledOut} from 'util/user'
import {useContext} from 'react'
import useIdentityFields from 'hooks/identity-fields'

export default function HostTrainingEventFeedItem({event}) {
  const {currentOrganization} = useContext(CurrentOrganizationContext)

  const availableTimeslots = filterOutAtCapacityTimeslots(event.times)
  const signupIdentityFields = useIdentityFields()
  const showOneTapSignup =
    isEveryIdentityFieldFilledOut(signupIdentityFields) &&
    availableTimeslots.length >= 1

  const SIGNUP_CONTEXT = 'commit_flow'
  const url = getOrganizationEventUrl(
    currentOrganization || event.organization,
    event,
    {followup_modal_context: SIGNUP_CONTEXT}
  )

  const oneTapActionButtons = () => {
    return (
      <div>
        <Typography variant={'body1'}>
          Sign up as {signupIdentityFields.firstName}:
        </Typography>

        <InputGroup stackOnMobile>
          {availableTimeslots.slice(0, 3).map((t) => (
            <SignupButton
              key={t.id}
              event={event}
              timeslot={t}
              signupIdentityFields={signupIdentityFields}
              organization={currentOrganization}
              signupSource={SIGNUP_CONTEXT}
            />
          ))}
        </InputGroup>

        <InputGroup>
          <Link to={url} plain>
            <Button secondary icon="arrow-right">
              See more times
            </Button>
          </Link>
        </InputGroup>
      </div>
    )
  }

  const seeDetailsButton = () => {
    return (
      <InputGroup>
        <Link to={url} plain>
          <Button>See details</Button>
        </Link>
      </InputGroup>
    )
  }

  return (
    <FeedItem
      linkTo={url}
      dontLinkButtons
      headline={event.name}
      aria-label={event.name}
      details={filterFalsy([getOneLineAddress(event)])}
      actionContent={() =>
        showOneTapSignup ? oneTapActionButtons() : seeDetailsButton()
      }
      imageUrl={event.image_url}
      event={event}
    />
  )
}
