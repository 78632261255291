import {
  ActionButtons,
  Button,
  CardModal,
  Form,
  TextArea,
  Typography,
} from 'components'

import {ExperienceFeedbackType} from 'app/enums'
import {partial} from 'util/common'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const FeedbackTextFormWrapper = styled.div`
  margin-top: ${styles.space.m};
`

const ParticipationItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${styles.space.s};

  /* TODO(ramil + jared) Figure out a better way of adding margin to the child components. */
  & > * {
    margin-right: ${styles.space.m};
  }
`

/** Lazy abstraction of the inlined modal to break up the wall of components in the render prop;
 * TODO(jared) should eventually make these prop names more meaningful and test this thing */
export default function ParticipationItemFeedbackModal(props) {
  const {
    detailedFeedback,
    closeFeedbackModal,
    handleFeedbackType,
    handleFeedbackText,
    onFeedbackChange,
    experienceFeedbackType,
  } = props
  const leftPositiveFeedback =
    experienceFeedbackType === ExperienceFeedbackType.APPROVED_OF_SHIFT
  const leftNegativeFeedback =
    experienceFeedbackType === ExperienceFeedbackType.DISAPPROVED_OF_SHIFT
  const didNotAttend =
    experienceFeedbackType === ExperienceFeedbackType.DID_NOT_ATTEND

  return (
    <CardModal
      isOpen
      header="Leave Feedback"
      onRequestClose={closeFeedbackModal}
    >
      <Typography variant="body2">How was it?</Typography>
      <ParticipationItemContainer>
        <Button
          selected={leftPositiveFeedback}
          secondary
          icon="thumbs-up"
          onClick={partial(
            handleFeedbackType,
            ExperienceFeedbackType.APPROVED_OF_SHIFT
          )}
          disabled={leftPositiveFeedback}
        />
        <Button
          selected={leftNegativeFeedback}
          secondary
          icon="thumbs-down"
          onClick={partial(
            handleFeedbackType,
            ExperienceFeedbackType.DISAPPROVED_OF_SHIFT
          )}
          disabled={leftNegativeFeedback}
        />
        <Button
          selected={didNotAttend}
          secondary
          onClick={() => {
            handleFeedbackType(ExperienceFeedbackType.DID_NOT_ATTEND)
            closeFeedbackModal()
          }}
          disabled={didNotAttend}
        >
          Did not attend
        </Button>
      </ParticipationItemContainer>
      <FeedbackTextFormWrapper>
        <Form onSubmit={handleFeedbackText}>
          <TextArea
            placeholder="Your feedback"
            name="detailedFeedback"
            value={detailedFeedback}
            onChange={(e) => onFeedbackChange(e.target.value)}
            label="Say more (optional)"
          />
          <ActionButtons>
            <Button type="button" secondary onClick={closeFeedbackModal}>
              Cancel
            </Button>
            <Button type="submit">Leave Feedback</Button>
          </ActionButtons>
        </Form>
      </FeedbackTextFormWrapper>
    </CardModal>
  )
}
