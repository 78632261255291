import {Link, RadioGroup, Typography} from 'components'

import {LocaleName} from 'app/enums'

/** Allows the selection of a single language. */
export default function PrimaryLocaleSelector({onChange, selectedLocale}) {
  return (
    <>
      <Typography variant="h3">Primary language</Typography>
      <RadioGroup
        label={
          <Typography variant="body1" fontWeight="regular">
            Automated communications and Mobilize-generated text on sign-up
            pages (excluding the event description) will be translated into the
            selected language. Learn more about what gets translated{' '}
            <Link
              to="https://help.mobilize.us/en/articles/9265466-configure-spanish-events-and-actions-available-by-request"
              target="_blank"
            >
              here
            </Link>
            .
          </Typography>
        }
        name="primary_locale"
        options={[
          {
            text: 'English',
            value: LocaleName.EN,
          },
          {
            text: 'Spanish',
            value: LocaleName.ES,
          },
        ]}
        value={selectedLocale}
        onChange={onChange}
      />
    </>
  )
}
