import {F} from 'util/i18n'
import {ISOStringInNDays} from 'util/time'
import {Link} from 'components'
import analytics from 'analytics'
import {formatQueryString} from 'util/url'
import {getFeedUrl} from 'util/routing'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const GrayText = styled.span`
  color: ${styles.colors.neutral300};
`

export default function UpcomingEventsLinks({organization, trackingLocation}) {
  const feedUrl = getFeedUrl(organization).toString()
  function filteredFeedUrl(queryParams) {
    return `${feedUrl}${formatQueryString(queryParams)}`
  }

  function onClick(trackingLocation, timeframe) {
    analytics.track(`volSchedule.timeClicked`, {
      from_widget: trackingLocation,
      timeframe,
    })
  }
  return (
    <div>
      <Link
        plain
        target="_blank"
        onClick={() => onClick(trackingLocation, 'today')}
        to={filteredFeedUrl({
          date: ISOStringInNDays(0),
        })}
      >
        <F defaultMessage="Today" />
      </Link>
      <GrayText>{' • '}</GrayText>
      <Link
        plain
        target="_blank"
        onClick={() => onClick(trackingLocation, 'tomorrow')}
        to={filteredFeedUrl({
          date: ISOStringInNDays(1),
        })}
      >
        <F defaultMessage="Tomorrow" />
      </Link>
      <GrayText>{' • '}</GrayText>
      <Link
        plain
        target="_blank"
        onClick={() => onClick(trackingLocation, 'this_week')}
        to={filteredFeedUrl({
          start_date: ISOStringInNDays(0),
          end_date: ISOStringInNDays(6),
        })}
      >
        <F defaultMessage="This week" />
      </Link>
    </div>
  )
}
