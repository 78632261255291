import color from 'color'
import colors from './colors'
import {css} from '@emotion/react'
import {limits} from './constants'
import typography from './typography'

export const fontSizeSmall = css`
  font-size: ${typography.fontSizeS};
  line-height: ${typography.lineHeightS};
`

export const focusable = (extraFocusCss) => css`
  &:focus {
    outline: none;
  }

  // focus-visible class added by polyfill (see polyfill.js) for older browsers that don't support
  // the pseudoselector yet (namely, safari; see https://caniuse.com/css-focus-visible). when
  // :focus-visible has good enough support, we can drop the polyfill and just use the
  // :focus-visible pseudoselector instead
  // note also that to test this in safari you need to enable the keyboard nav setting:
  // https://www.webassign.net/manual/student_guide/t_a_osx_tab_config.htm#ariaid-title2
  &:focus.focus-visible {
    box-shadow: 0 0 0 2px ${colors.white}, 0 0 0 4px ${colors.primary200};
    ${extraFocusCss}
  }
`

export function mediaMinWidth(breakpoint, content) {
  return css`
    @media (min-width: ${breakpoint}px) {
      ${content};
    }
  `
}

export function mediaMaxWidth(breakpoint, content) {
  return css`
    @media (max-width: ${breakpoint}px) {
      ${content};
    }
  `
}

export function mediaMinWidthLarge(content) {
  return mediaMinWidth(limits.breakpointLarge, content)
}

export function mediaMinWidthSmall(content) {
  return mediaMinWidth(limits.breakpointSmall, content)
}

export function mediaMinWidthDashboardLarge(content) {
  return mediaMinWidth(limits.breakpointLarge, content)
}

export function mediaMaxWidthSmall(content) {
  return mediaMaxWidth(limits.breakpointSmall - 1, content)
}

export const boxShadowDefault = css`
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
`

export const boxShadowLight = css`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`

export const backgroundVerticalShadowGradient = css`
  background: 0 0 linear-gradient(0deg, ${colors.white} 0%, transparent 100%)
    no-repeat;
`

export const inputDisabledCss = css`
  background-color: ${colors.neutral200};
  color: ${colors.neutral400};
  cursor: not-allowed;
`

export const ellipsizedText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

// NB: this has limitations until the standard is more fully baked, still useful though:
// https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
export const clampLines = (maxLines) => css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${maxLines};
`

export const getPrimaryFontSizeFromProps = ({theme}, fontSize) => css`
  // Default to 1 out of paranoia; this should be defined
  font-size: ${parseFloat(fontSize) * theme.primary_font_size_scale || 1}rem;
`

export const getPrimaryTitleFontFromProps = ({theme}) => css`
  font-family: ${theme.primary_title_font};
  font-weight: ${theme.primary_title_font_weight};
`

export const getSecondaryBodyFontFromProps = ({theme}) => css`
  font-family: ${theme.secondary_body_font};
`

export const getBorderRadiusFromProps = ({theme}, corners) =>
  corners
    ? css`
        ${Array.from(corners).map(
          (corner) => `border-${corner}-radius: ${theme.border_radius};`
        )};
      `
    : css`
        border-radius: ${theme.border_radius};
      `

export const getPrimaryTextColorFromProps = ({theme}) => css`
  color: ${theme.primary_text_color};
`

export const getSecondaryTextColorFromProps = ({theme}) => css`
  color: ${theme.secondary_text_color};
`

export const getTitleTextTransformFromProps = ({theme}) => css`
  text-transform: ${theme.title_text_transform};
`

export const getLinkStyleFromProps = (
  {theme},
  {
    disableHover,
    disableLinkColor = false,
    disableBackground,
    disableTextDecoration,
    textHoverTarget = '&',
  } = {}
) => css`
  ${!disableBackground && `background: ${theme.link_background};`}
  color: ${disableLinkColor ? colors.neutral400 : theme.link_color};
  font-weight: ${theme.link_font_weight};
  ${getSecondaryBodyFontFromProps({theme})};
  // Only apply text-decoration to the area specified as text by the caller. For example, see how
  // Button uses textHoverTarget to differentiate the text from an icon, so that the icon doesn't
  // get underlined on hover.
  ${!disableTextDecoration &&
  `${textHoverTarget} {
    text-decoration: ${theme.link_text_decoration};
  }`}

  ${!disableHover &&
  css`
    &:hover {
      color: ${theme.link_hover_color};
      background: ${theme.link_hover_background};
      ${!disableTextDecoration &&
      `${textHoverTarget} {
            text-decoration: ${theme.link_hover_text_decoration};
          }`}
    }
  `};
`

export const getInputStyleFromProps = ({theme}) => css`
  ${getInputFontStyleFromProps({theme})};
  ${getInputBorderStyleFromProps({theme})};
`

export const getInputFontStyleFromProps = ({theme}) => css`
  color: ${theme.input_text_color};
  ${getSecondaryBodyFontFromProps({theme})};
  line-height: ${typography.lineHeightBase};
`

export const getInputBorderStyleFromProps = ({theme}) => css`
  border: ${theme.input_border_width} solid ${theme.input_border_color};
  ${getBorderRadiusFromProps({theme})};
`

/**
 * Get the background color out of the theme, as well as a legible text color
 */
export function getReadableColorOnPrimaryColor(props) {
  const color = getPrimaryColorFromProps(props)
  return css`
    color: ${readableColor(color)};
    background-color: ${color};
  `
}

export const getPlaceholderStyleFromProps = ({theme}) => css`
  color: ${colors.neutral300};
  ${getSecondaryBodyFontFromProps({theme})};
`

// Get a legible text color for a given background color (per WCAG contrast
// ratio). See https://www.w3.org/TR/WCAG20/#contrast-ratiodef and
// https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
export function readableColor(background) {
  if (color(background).contrast(color('white')) >= 3) {
    return 'white'
  }
  return 'black'
}

export function getPrimaryColorFromProps({theme}) {
  return theme.primary_color
}

// Some attempts at algorithms for hover/active button colors
const LIGHTNESS_SCALING_FACTOR = 0.15
export function getHoverColor(c) {
  const parsed = color(c)
  return (parsed.isLight()
    ? parsed.darken(LIGHTNESS_SCALING_FACTOR)
    : parsed.lighten(LIGHTNESS_SCALING_FACTOR)
  )
    .hsl()
    .string()
}

export function getActiveColor(c) {
  const parsed = color(c)
  return (parsed.isLight()
    ? parsed.darken(LIGHTNESS_SCALING_FACTOR / 2)
    : parsed.lighten(LIGHTNESS_SCALING_FACTOR / 2)
  )
    .hsl()
    .string()
}

export function hideBorderOnLastElement(isLastElement, borderStyle) {
  return `border-bottom: ${isLastElement ? '0px' : borderStyle}`
}
