import {Button, InputGroup, Message, MessageType, Typography} from 'components'
import {partial, range} from 'util/common'

import {EMPTY_VIRTUAL_JOIN_DETAILS} from '../util'
import HostDeleteTimeslotConfirmationModal from './HostDeleteTimeslotConfirmationModal'
import HostTimeslotRow from './HostTimeslotRow'
import {Timezone} from 'app/enums'
import {TimezoneField} from 'dashboard/components'
import {WESTERNMOST_TIMEZONE_FOR_EVENT_CREATION} from 'dashboard/events/create/constants'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useState} from 'react'

const TimeslotInstructions = styled.div`
  margin-bottom: ${styles.space.m};
`

const MessageWrapper = styled.div`
  display: flex;
  margin-top: ${styles.space.l};
  margin-bottom: ${styles.space.l};
`

const HostCreateTimeslots = ({
  eventIsVirtual,
  timeslots,
  onTimeslotsChange,
  eventCampaign,
  timezone,
  perTimeslotErrors,
  onTimezoneChange,
  timezoneFetchFailed,
  timeslotErrorMessage,
  organizationName,
}) => {
  const [timeslotToDelete, setTimeslotToDelete] = useState(null)

  const modifyTimeslot = (idx, timeslot) => {
    const tempTimeslots = [...timeslots]
    tempTimeslots[idx] = timeslot
    onTimeslotsChange(tempTimeslots)
  }

  const getEmptyTimeslot = (eventCampaign) => {
    const {
      hosted_event_default_date: date,
      hosted_event_default_start: start,
      hosted_event_default_end: end,
    } = eventCampaign
    return {
      date: date || '',
      startTime: start || '',
      endTime: end || '',
      deleted: false,
      maxAttendees: null,
      attendingCount: 0,
      ...EMPTY_VIRTUAL_JOIN_DETAILS,
    }
  }

  const addTimeslot = () => {
    const tempTimeslots = [...timeslots, getEmptyTimeslot(eventCampaign)]
    onTimeslotsChange(tempTimeslots)
  }

  const addRepeatForAMonthTimeslots = () => {
    const lastTimeslotOnForm = timeslots[timeslots.length - 1]
    const baseWeeksOffset = getWeeksOffsetForWeeklyRepeat()

    const newTimeslots = range(1, 4)
      .map((timeslotOffsetWeeks) => {
        const thisTimeslot = {
          ...lastTimeslotOnForm,
          id: undefined,
          // Null out videoconferencing fields bc they're probably one-time-use
          virtualJoinUrl: null,
          zoomMeetingId: null,
          zoomMeetingType: null,
        }
        if (
          rangeEnd &&
          !nWeeksAfterLastTimeslotOnFormIsBeforeRangeEnd(
            baseWeeksOffset + timeslotOffsetWeeks
          )
        ) {
          /* If the timeslot we would add is after the EC's range end, don't add it */
          return null
        }

        thisTimeslot.date = moment(lastTimeslotOnForm.date)
          .add(baseWeeksOffset + timeslotOffsetWeeks, 'weeks')
          .format('YYYY-MM-DD')
        thisTimeslot.startTime = lastTimeslotOnForm.startTime
        thisTimeslot.endTime = lastTimeslotOnForm.endTime
        return thisTimeslot
      })
      .filter(Boolean)

    const tempTimeslots = [...timeslots, ...newTimeslots]
    onTimeslotsChange(tempTimeslots)
  }

  const confirmDeleteTimeslot = () => {
    const tempTimeslots = [...timeslots]
    if (!timeslotToDelete) {
      return
    } else {
      const deletedTs = timeslotToDelete
      onTimeslotsChange(
        tempTimeslots.map((ts) =>
          ts.id === deletedTs.id ? {...ts, deleted: true} : ts
        )
      )
      setTimeslotToDelete(null)
    }
  }

  const deleteTimeslot = (idx, ts) => {
    const tempTimeslots = [...timeslots]
    const timeslotToDelete = tempTimeslots[idx]
    if (timeslotToDelete.id) {
      setTimeslotToDelete(timeslotToDelete)
    } else {
      tempTimeslots.splice(idx, 1)
      onTimeslotsChange(tempTimeslots)
    }
  }

  const cancelDeleteTimeslot = () => {
    setTimeslotToDelete(null)
  }

  const {
    hosted_event_default_date: defaultDate,
    hosted_event_default_start: defaultStart,
    hosted_event_default_end: defaultEnd,
    hosted_event_range_start: rangeStart,
    hosted_event_range_end: rangeEnd,
    enable_recurring_timeslots: shouldAllowMultipleTimeslots,
  } = eventCampaign

  const dayStartMoment = moment().startOf('day')
  let rangeStartMoment = rangeStart
    ? moment(rangeStart, moment.ISO_8601).startOf('day')
    : null
  // If the range starts before today or isn't set, use today as the range
  // start, because we don't want to allow event creation in the past
  if (!rangeStartMoment || rangeStartMoment.isBefore(dayStartMoment)) {
    rangeStartMoment = dayStartMoment
  }

  const getMomentFromTimeslot = (ts, timeField) => {
    return moment(
      // $FlowFixMe: these should be nullchecked before calling
      `${ts.date} ${ts[timeField]}`,
      'YYYY-MM-DD HH:mm'
    ).tz(
      timezone || Timezone.EASTERN
    ) /* tz may be null when this is called, but we'd like this to work before it gets a value */
  }

  const nWeeksAfterLastTimeslotOnFormIsBeforeRangeEnd = (n) => {
    const timeslotOffsetMoment = getMomentFromTimeslot(
      // $FlowFixMe: maybeLastTimeslotOnForm should be nullchecked before calling
      maybeLastTimeslotOnForm,
      'endTime'
    ).add(n, 'weeks')
    return timeslotOffsetMoment.isSameOrBefore(rangeEndMoment)
  }

  const rangeEndMoment = rangeEnd
    ? moment(rangeEnd, moment.ISO_8601)
        .tz(WESTERNMOST_TIMEZONE_FOR_EVENT_CREATION)
        .endOf('day')
    : null

  const shouldShowAddTimeButton =
    !(defaultDate && defaultStart && defaultEnd) &&
    !!shouldAllowMultipleTimeslots

  const maybeLastTimeslotOnForm = timeslots[timeslots.length - 1] || null

  const shouldShowRepeatForAMonthButton =
    shouldShowAddTimeButton && !defaultDate

  const getWeeksOffsetForWeeklyRepeat = () => {
    // returns the number of weeks we need to advance from the last timeslot
    // on the form s.t. adding a week gives us a time in the future.

    const timeslotStartMoment = getMomentFromTimeslot(
      // $FlowFixMe: maybeLastTimeslotOnForm should be nullchecked before calling
      maybeLastTimeslotOnForm,
      'startTime'
    )
    let weeksOffset = 0
    while (timeslotStartMoment.add(1, 'week').isBefore()) {
      ++weeksOffset
    }
    return weeksOffset
  }

  const shouldEnableRepeatForAMonthButton =
    shouldShowRepeatForAMonthButton &&
    !!maybeLastTimeslotOnForm &&
    maybeLastTimeslotOnForm.startTime &&
    maybeLastTimeslotOnForm.endTime &&
    maybeLastTimeslotOnForm.date &&
    /* when we start creating future timeslots, the event campaign will still be active */
    (!rangeEnd ||
      nWeeksAfterLastTimeslotOnFormIsBeforeRangeEnd(
        getWeeksOffsetForWeeklyRepeat() + 1
      ))

  const shouldShowScheduleMessage = defaultDate || defaultStart || defaultEnd

  const instructionText = shouldAllowMultipleTimeslots ? (
    <span>
      Enter a single time, or add multiple if your event occurs more than once.
      <br />
      Timezone is determined by the event location.
    </span>
  ) : (
    <span>
      Enter a date, start and end time. Timezone is determined by the event
      location.
    </span>
  )

  return (
    <div>
      <TimeslotInstructions>
        <Typography variant="body2">{instructionText}</Typography>
      </TimeslotInstructions>
      {shouldShowScheduleMessage && (
        <MessageWrapper>
          <Message type={MessageType.INFO}>
            Schedule for this event is set by {organizationName}
          </Message>
        </MessageWrapper>
      )}
      {
        /* If we've tried to get the timezone from the event location but failed
      (e.g. because Google gave us a non-standard timezone), show a dropdown
      to let the user pick their timezone. */
        timezoneFetchFailed && (
          <TimezoneField
            name="timezone"
            value={timezone || Timezone.EASTERN}
            onChange={(_, {value}) => onTimezoneChange(value)}
            fluid
          />
        )
      }
      <>
        {timeslots.map((timeslot, index) => {
          const shouldHideDeleteButton = timeslots.length === 1
          const isLast = index === timeslots.length - 1
          return (
            !timeslot.deleted && (
              <HostTimeslotRow
                key={index}
                timeslot={timeslot}
                onModifyTimeslot={partial(modifyTimeslot, index)}
                onRemoveTimeslot={partial(deleteTimeslot, index)}
                hideDelete={shouldHideDeleteButton}
                timezone={timezone}
                errorMessage={perTimeslotErrors[index]}
                rangeStartMoment={rangeStartMoment}
                rangeEndMoment={rangeEndMoment}
                useDefaultDate={!!defaultDate}
                useDefaultStart={!!defaultStart}
                useDefaultEnd={!!defaultEnd}
                eventIsVirtual={eventIsVirtual}
                displayTimezoneIfKnown={isLast}
              />
            )
          )
        })}
      </>
      {timeslotErrorMessage && (
        <Message type={MessageType.ERROR}>{timeslotErrorMessage}</Message>
      )}
      {shouldShowAddTimeButton && (
        <InputGroup>
          {shouldShowRepeatForAMonthButton && (
            <Button
              secondary
              type="button"
              icon="repeat"
              iconPosition="left"
              iconFontSize={styles.typography.fontSizeM}
              onClick={addRepeatForAMonthTimeslots}
              disabled={!shouldEnableRepeatForAMonthButton}
            >
              Repeat every week for a month
            </Button>
          )}
          <Button
            secondary
            type="button"
            icon="plus"
            iconPosition="left"
            iconFontSize={styles.typography.fontSizeM}
            onClick={addTimeslot}
          >
            Add another time
          </Button>
        </InputGroup>
      )}
      {timeslotToDelete && (
        <HostDeleteTimeslotConfirmationModal
          timeslotToDelete={timeslotToDelete}
          onCancelDeleteTimeslot={cancelDeleteTimeslot}
          onConfirmDeleteTimeslot={confirmDeleteTimeslot}
        />
      )}
    </div>
  )
}

export default HostCreateTimeslots
