const initializedIDs = new Set()

// initialize FB pixel for a given id
function ensureInitialized(id) {
  // FB-provided IIFE to get all the necessary scripts and set up the
  // global `window.fbq` function; don't lint or format it
  // prettier-ignore
  /* eslint-disable */
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  /* eslint-enable */

  // we need to manage pushing pageviews ourselves, because otherwise all
  // loaded pixels get all pageviews (when we transition from one org to
  // another, the first org's pixel should not get events from the second org)
  window.fbq.disablePushState = true

  // Only initialize each pixel once
  if (!initializedIDs.has(id)) {
    // disable "automatic configuration" (basically just scrapes a bunch of
    // other data from the page and sets up automatic event listeners):
    // https://developers.facebook.com/docs/ads-for-websites/pixel-events/v3.0#auto
    window.fbq('set', 'autoConfig', 'false', id)
    window.fbq('init', id)
    initializedIDs.add(id)
  }
}

export function pageview(id) {
  ensureInitialized(id)
  window.fbq('trackSingle', id, 'PageView')
}

export function send(id, eventName, params) {
  ensureInitialized(id)
  window.fbq('trackSingle', id, eventName, params)
}
