import {Button, Message, MessageType, Typography} from 'components'

import {Container} from './util'
import {F} from 'util/i18n'
import TimeslotCheckboxes from './TimeslotCheckboxes'
import {filterFalsy} from 'util/common'
import {filterOutAtCapacityTimeslots} from 'util/timeslot'
import {getEventTypeAffinityChecks} from 'util/event'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const UpsellMessageContent = styled.div`
  text-align: center;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: ${styles.space.l};
`

export default function Timeslots({
  event,
  maybePrioritizedTimeslot,
  onChange,
  precheckedTwoTimeslots,
  shiftErrors,
  shifts,
  showSelectAll,
  selectAllOn,
  setSelectAllOn,
}) {
  const {isPromptForDonation} = getEventTypeAffinityChecks(event)
  const onChangeSelectedTimeslots = (timeslotIds) => {
    onChange(timeslotIds.map((timeslot_id) => ({timeslot_id})))
  }

  if (event.is_virtual_flexible) {
    return null
  }

  const availableTimeslots =
    event.times && event.times.length > 0
      ? filterOutAtCapacityTimeslots(event.times)
      : []

  const selectAllText = selectAllOn ? (
    <F
      defaultMessage="Unselect all ({numShifts} times)"
      values={{numShifts: shifts.length}}
    />
  ) : (
    <F
      defaultMessage="Select all ({numAvailable} times)"
      values={{numAvailable: availableTimeslots.length}}
    />
  )

  const handleSelectAllClick = (e) => {
    e && e.preventDefault()
    if (selectAllOn) {
      setSelectAllOn(false)
      onChangeSelectedTimeslots([])
    } else {
      const availableTimeslotIds = availableTimeslots.map(
        (timeslot) => timeslot.id
      )
      if (availableTimeslots) {
        setSelectAllOn(true)
        onChangeSelectedTimeslots(availableTimeslotIds)
      }
    }
  }

  return (
    <>
      <HeaderWrapper>
        <Typography variant="h3">
          <F defaultMessage="Available times" />
        </Typography>
        {showSelectAll && (
          <Button link padding="none" onClick={handleSelectAllClick}>
            {selectAllText}
          </Button>
        )}
      </HeaderWrapper>
      {precheckedTwoTimeslots && shifts.length < 2 && (
        <Message type={MessageType.WARNING}>
          <UpsellMessageContent>
            <F defaultMessage="Most people pick two times" />{' '}
            <span role="img" aria-label="smiling face wearing sunglasses">
              😎
            </span>
          </UpsellMessageContent>
        </Message>
      )}
      <Container>
        <TimeslotCheckboxes
          event={event}
          onChange={onChangeSelectedTimeslots}
          maybePrioritizedTimeslot={maybePrioritizedTimeslot}
          selectedTimeslotIds={filterFalsy(
            shifts.map((shift) => shift && shift.timeslot_id)
          )}
          timeslotErrors={shiftErrors}
          renderAsRadioButtons={isPromptForDonation}
          availableTimeslots={availableTimeslots}
        />
      </Container>
    </>
  )
}
