import {CheckboxField, Icon} from 'components'
import MaterialAutocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'

import FormItem from './FormItem'
import {TextField as MaterialTextField} from '@material-ui/core'
import {classnames} from 'util/common'
import {makeStyles} from '@material-ui/core/styles'
import styles from './styles'
import {useState} from 'react'

const filter = createFilterOptions()

const useStyles = makeStyles((theme) => ({
  // classes applied to inputRoot through outer AutoComplete component
  multilineInputRoot: {
    '& .MuiAutocomplete-input': {
      maxHeight: styles.space.l,
      color: styles.colors.white,
      resize: 'none',
    },
    '&.Mui-focused .MuiAutocomplete-input': {
      width: '100%',
      maxHeight: '250px',
      overflow: 'scroll!important',
      color: 'inherit',
    },
  },
  validatedInputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: styles.colors.success200,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: styles.colors.success200,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: styles.colors.success200,
    },
    '& MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: styles.colors.success300,
    },
  },

  // passed in InputLabelProps to the inner TextField component
  validatedInputLabel: {
    color: styles.colors.success300,
    '&.Mui-focused': {
      color: styles.colors.success300,
    },
  },

  // passed in FormHelperTextProps to the inner TextField component
  validatedHelperText: {
    color: styles.colors.success300,
  },
}))

export default function Autocomplete({
  autoComplete,
  autoHighlight,
  autoSelect,
  creatable,
  disableCloseOnSelect,
  disablePortal,
  disabled,
  groupBy,
  hint,
  label,
  multiple,
  name,
  onAddItem,
  onChange,
  onInputChange,
  options,
  placeholder,
  renderGroup,
  required,
  sectionLabel,
  shouldntGroupIfInputValue,
  value,
  disableCreateLabels,
  validated,
  disableClearable,
  multiline,
  error,
  limitTags,
  checkboxes,
  disableClearOnBlur,
  addItemMessage,
  icon,
}) {
  const [inputValue, setInputValue] = useState('')

  const classes = useStyles()

  function handleChange(evt, value) {
    const latestNewValue = multiple ? value.slice(-1)[0] : value
    onChange(evt, {name, value})

    if (
      latestNewValue &&
      onAddItem &&
      creatable &&
      !optionsMap[latestNewValue]
    ) {
      onAddItem(evt, {name, value: latestNewValue})
    }
  }

  function handleInputChange(evt, value) {
    if (onInputChange) {
      onInputChange(evt, value)
    }
    setInputValue(value)
  }

  function handleFilterOptions(options, params) {
    const filtered = filter(options, params)

    // Suggest the creation of a new value
    if (!disableCreateLabels && params.inputValue !== '') {
      filtered.push(params.inputValue)
    }

    return filtered
  }

  function renderTextField(params) {
    return icon ? (
      <MaterialTextField
        {...params}
        helperText={hint}
        label={label}
        placeholder={placeholder}
        error={error}
        InputLabelProps={{
          required,
          classes: validated
            ? {shrink: classes.validatedInputLabel}
            : undefined,
        }}
        InputProps={{
          ...params.InputProps,
          startAdornment: icon ? <Icon name={icon || ''} /> : undefined,
        }}
        FormHelperTextProps={{
          component: 'div',
          classes: validated ? {root: classes.validatedHelperText} : undefined,
        }}
        variant="outlined"
        onKeyDown={
          multiline
            ? (event) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation()
                }
              }
            : undefined
        }
        multiline={multiline}
        // if multiple, then the input will be empty after selection
        required={required && (!multiple || !value?.length)}
      />
    ) : (
      <MaterialTextField
        {...params}
        helperText={hint}
        label={label}
        placeholder={placeholder}
        error={error}
        InputLabelProps={{
          required,
          classes: validated
            ? {shrink: classes.validatedInputLabel}
            : undefined,
        }}
        FormHelperTextProps={{
          component: 'div',
          classes: validated ? {root: classes.validatedHelperText} : undefined,
        }}
        variant="outlined"
        onKeyDown={
          multiline
            ? (event) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation()
                }
              }
            : undefined
        }
        multiline={multiline}
        // if multiple, then the input will be empty after selection
        required={required && (!multiple || !value?.length)}
      />
    )
  }

  // TODO(kyle): These weird maps are necessary because we only pass the
  // options' `value`s into the actual component. I'd rather refactor it to take
  // the full `SelectOption` objects, but that would change the signature of
  // onChange so is a bigger refactor.
  let optionsMap = {}
  options.forEach(({text, value}) => (optionsMap[value || ''] = text))
  let optionsDisabledMap = {}
  options.forEach(
    ({value, disabled}) => (optionsDisabledMap[value || ''] = !!disabled)
  )

  return (
    <FormItem label={sectionLabel}>
      <MaterialAutocomplete
        disableCloseOnSelect={disableCloseOnSelect}
        limitTags={limitTags || -1}
        autoComplete={autoComplete}
        autoHighlight={autoHighlight}
        autoSelect={autoSelect}
        clearOnBlur={disableClearOnBlur ? false : creatable && !multiline}
        disabled={disabled}
        filterOptions={creatable ? handleFilterOptions : undefined}
        filterSelectedOptions
        freeSolo={creatable}
        getOptionLabel={
          disableCreateLabels
            ? undefined
            : (value) =>
                optionsMap[value] ||
                (creatable
                  ? addItemMessage
                    ? `${addItemMessage}`
                    : `Add “${value}”`
                  : '')
        }
        getOptionSelected={(option, value) => option === value}
        groupBy={shouldntGroupIfInputValue && inputValue ? undefined : groupBy}
        inputValue={inputValue}
        multiple={multiple}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options.map((option) => option.value)}
        renderInput={renderTextField}
        renderGroup={
          shouldntGroupIfInputValue && inputValue ? undefined : renderGroup
        }
        renderOption={
          checkboxes
            ? (option, {selected}) => {
                return (
                  <CheckboxField
                    checked={selected}
                    disabled={!!optionsDisabledMap[option]}
                    // Required by CheckboxField but no-op since this component
                    // handles state. Consider making optional.
                    onChange={() => {}}
                    label={optionsMap[option]}
                  />
                )
              }
            : null
        }
        disablePortal={disablePortal}
        getOptionDisabled={(option) => !!optionsDisabledMap[option]}
        selectOnFocus={creatable}
        value={value}
        disableClearable={disableClearable}
        classes={{
          inputRoot: classnames(
            multiline ? classes.multilineInputRoot : undefined,
            validated ? classes.validatedInputRoot : undefined
          ),
        }}
      />
    </FormItem>
  )
}
