import escapeRegExp from 'lodash.escaperegexp'
import styled from '@emotion/styled/macro'
import styles from './styles'

const Highlight = styled.span`
  background-color: ${styles.colors.primary100};
`

/** Highlights `term` case-insensitively inside `text`. */
export default function HighlightTerm({term, text}) {
  if (!term) {
    return text
  }

  const regex = new RegExp(`(${escapeRegExp(term)})`, 'ugi')
  const split = text.split(regex)
  const lowerCaseTerm = term.toLowerCase()
  return (
    <>
      {split
        .filter((s) => s !== '')
        .map((splitTerm, index) =>
          lowerCaseTerm === splitTerm.toLowerCase() ? (
            <Highlight key={index}>{splitTerm}</Highlight>
          ) : (
            <span key={index}>{splitTerm}</span>
          )
        )}
    </>
  )
}
