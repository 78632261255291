import {
  CheckboxField,
  EventMap,
  LocationSearchInput,
  RadioGroup,
  Typography,
} from 'components'
import {
  LOCATION_INPUT_ERROR_MISSING_ZIP_CODE,
  LOCATION_INPUT_ERROR_UNSUPPORTED_COUNTRY,
} from 'app/constants'
import {isDonationCampaignHostedEvent, isGroupHostedEvent} from 'util/event'
import styles, {mediaMinWidthDashboardLarge} from 'components/styles'

import {css} from '@emotion/react'
import {keyframes} from '@emotion/react'
import styled from '@emotion/styled/macro'

const LocationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${styles.space.l};
  margin-top: ${styles.space.l};

  // Below 830px, the Google Map will not display the zoom buttons, so we need
  // to switch direction to column-reverse so that the map expands to 100% width.
  flex-direction: column-reverse;

  @media (min-width: 830px) {
    flex-direction: row;
  }
`
const AddressWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding-right: 0;

  ${mediaMinWidthDashboardLarge(css`
    padding-right: ${styles.space.l};
    width: 66%;
  `)}
`

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const MapWrapper = styled.div`
  animation: ${fadeInAnimation} 0.3s ease-in;
  flex: auto;
  margin-bottom: ${styles.space.l};
`

const HostCreateLocation = ({
  hostedEvent,
  defaultLocationValue,
  locationValidations,
  onLocationChange,
  onLocationFieldChange,
  editingEvent,
}) => {
  const hostedEventIsDonationCampaign = isDonationCampaignHostedEvent(
    hostedEvent
  )
  const hostedEventIsGroup = isGroupHostedEvent(hostedEvent)
  const actionCopy = hostedEventIsDonationCampaign
    ? 'fundraiser'
    : hostedEventIsGroup
    ? 'group'
    : 'event'

  const maybeGetLocationErrorMessage = () => {
    if (locationValidations.locationInUnsupportedCountry) {
      return LOCATION_INPUT_ERROR_UNSUPPORTED_COUNTRY
    }
    if (locationValidations.locationNotSpecificEnough) {
      return LOCATION_INPUT_ERROR_MISSING_ZIP_CODE
    }
    return null
  }

  const location = hostedEvent.location
  const {
    locationNotSpecificEnough,
    locationInUnsupportedCountry,
  } = locationValidations
  const locationError =
    locationNotSpecificEnough || locationInUnsupportedCountry

  return (
    <>
      {!hostedEventIsDonationCampaign && !hostedEventIsGroup && (
        <RadioGroup
          label="Type"
          name="is_virtual"
          options={[
            {disabled: editingEvent, text: 'In-person', value: false},
            {disabled: editingEvent, text: 'Virtual', value: true},
          ]}
          value={hostedEvent.is_virtual}
          onChange={onLocationFieldChange}
        />
      )}
      <LocationWrapper>
        <AddressWrapper>
          <Typography variant="body1">
            Add a location to help supporters find this {actionCopy}.
          </Typography>
          <LocationSearchInput
            defaultValue={defaultLocationValue}
            defaultLocation={location}
            onSelect={onLocationChange}
            placeholder={`${
              hostedEvent.is_virtual
                ? 'Show this to supporters near…'
                : 'Event location'
            } (required)`}
            required
            showGetLocation
            showLocationFields
            useSessionToken
            validateSearchResults
            errorMessage={maybeGetLocationErrorMessage()}
            isVirtual={hostedEvent.is_virtual}
            isVirtualFlexible={
              hostedEventIsDonationCampaign /* currently the only virtual flexible case */
            }
            isDonationCampaign={hostedEventIsDonationCampaign}
            isGroupEvent={hostedEventIsGroup}
          />
        </AddressWrapper>
        {location && location.lat && location.lon && !locationError && (
          <MapWrapper>
            <EventMap
              centerLat={location.lat}
              centerLng={location.lon}
              zoom={15}
              height="325px"
              width="100%"
            />
          </MapWrapper>
        )}
        {!hostedEvent.is_virtual && (
          <div style={{width: '100%'}}>
            <CheckboxField
              name="location_is_private"
              label="Only attendees can see this location"
              type="checkbox"
              checked={hostedEvent.location_is_private}
              onChange={() =>
                onLocationFieldChange(
                  'location_is_private',
                  !hostedEvent.location_is_private
                )
              }
            />
          </div>
        )}
      </LocationWrapper>
    </>
  )
}

export default HostCreateLocation
