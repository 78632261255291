import {EventType, eventTypeToPluralDisplayName} from 'app/enums'

import {Autocomplete} from 'components'
import {capitalize} from 'util/common'

export default function EventTypeMultiselect({
  eventTypeOptions,
  sectionLabel,
  onChange,
  selectedTypes = [],
}) {
  const eventTypes =
    eventTypeOptions && eventTypeOptions.length
      ? eventTypeOptions
      : Object.keys(EventType).map((key) => EventType[key])

  const shownTypeDropdownOptions = eventTypes
    .map((eventType) => {
      const eventTypeName = eventTypeToPluralDisplayName(eventType)
      const sentenceCasedName = capitalize(eventTypeName)

      return {
        key: eventType,
        text: sentenceCasedName,
        value: eventType,
      }
    })
    .sort((a, b) => a.text.localeCompare(b.text))

  return (
    <Autocomplete
      sectionLabel={sectionLabel}
      placeholder={!selectedTypes || !selectedTypes.length ? 'Any' : undefined}
      options={shownTypeDropdownOptions}
      multiple
      onChange={(evt, {value: selected}) => {
        onChange(selected)
      }}
      value={selectedTypes}
    />
  )
}
