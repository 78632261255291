import {Button, CardModal, Icon, Link, Table, Typography} from 'components'

import {F} from 'util/i18n'
import {ParticipationStatus} from 'app/enums'
import {createMailUrl} from 'util/url'
import flag from 'experiments/flag'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const GroupLeaderDescription = styled.span`
  margin-right: ${styles.space.s};
`

const ModalDescription = styled.span`
  margin-top: ${styles.space.s};
`

const MemberNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export default function ViewGroupMembersModal({
  groupLeaderParticipation,
  groupLeaderUser,
  onRequestClose,
  onGroupMemberCancel,
}) {
  const totalGroupSize =
    (groupLeaderParticipation.num_additional_reserved_participations || 0) + 1
  const claimedGroupSpots =
    totalGroupSize -
    (groupLeaderParticipation.num_unclaimed_reserved_participations || 0)

  const showCancelButton = flag.isActive('enable_group_leader_cancelling')

  return (
    <CardModal isOpen onRequestClose={onRequestClose}>
      <Typography variant="h2">
        <F defaultMessage="View group members" />
      </Typography>
      <ModalDescription>
        <Typography variant="body1">
          <F
            defaultMessage={
              'Your group has {totalGroupSize} total members. ' +
              '{claimedGroupSpots} of them {claimedGroupSpots, plural, one {has} other {have}} already signed up on Mobilize.'
            }
            values={{
              totalGroupSize,
              claimedGroupSpots,
            }}
          />
        </Typography>
      </ModalDescription>
      <Table.Table stickyHeader maxHeight="400px">
        <Table.Head>
          <Table.Row>
            <Table.Cell>
              <F defaultMessage="Name and email" />
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <div>
                {`${groupLeaderUser.first_name} ${groupLeaderUser.last_name}`}
              </div>
              <Link to={createMailUrl(groupLeaderUser.email)} target="_blank">
                {groupLeaderUser.email}
              </Link>
              <Typography variant="body2">
                <GroupLeaderDescription>
                  <Icon name="flag" /> <F defaultMessage="Group leader" />
                </GroupLeaderDescription>
              </Typography>
            </Table.Cell>
          </Table.Row>
          {groupLeaderParticipation.group_members.map((member, i) => {
            const isEmaillessMember = !member.email
            return (
              <Table.Row key={i}>
                <Table.Cell>
                  <MemberNameWrapper>
                    <div>{`${member.first_name} ${member.last_name}`}</div>
                    {member.participation_status_attendance_combined ===
                    ParticipationStatus.CANCELLED ? (
                      <Typography variant="body2">
                        <F defaultMessage="Cancelled" />
                      </Typography>
                    ) : (
                      isEmaillessMember &&
                      showCancelButton && (
                        <Button
                          secondary
                          onClick={() =>
                            onGroupMemberCancel(member.participation_id)
                          }
                        >
                          <F defaultMessage="Cancel" />
                        </Button>
                      )
                    )}
                  </MemberNameWrapper>
                  {!isEmaillessMember && (
                    <Link to={createMailUrl(member.email)} target="_blank">
                      {member.email}
                    </Link>
                  )}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Table>
    </CardModal>
  )
}
