import {difference, isFinite} from 'lodash'

import {getEventCardTextFromFilterParam} from './FilterList.util'
import pluralize from 'pluralize'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const List = styled.ul`
  font-weight: ${styles.typography.fontWeightBold};
  list-style: none;
  margin: 0 0 ${styles.space.s};
  padding: 0;
`

// the list of filter params we support rendering
// for all others, we just render + n filters
// the null values are for filters that come with other filters
// address/lat/lon all come together but we only need address to render
// startDate/endDate come together if using a date range, but we only need one
const filterMap = {
  accessibleOnly: 'Accessibility',
  address: 'Near',
  date: 'Date',
  endDate: null,
  eventTypes: 'Type',
  isVirtual: 'Virtual',
  lat: null,
  lon: null,
  selectedOrgs: 'Organization',
  startDate: 'Date',
  tagIds: 'Tags',
}

const allowedFilters = Object.keys(filterMap)

/**
 * @description renders filter params list for the feed version of the EventCard
 */
export const FilterList = (props) => {
  const {filterParams, organization} = props

  // all filters come through, just some are null or undefined
  // we then get applied ones only
  const appliedFilters = Object.entries(filterParams)
    .filter(([key, val]) => {
      if (!val) {
        return false
      }
      // maxDist's default value appears is Infinity
      if (typeof val === 'number' && !isFinite(val)) {
        return false
      }
      if (Array.isArray(val)) {
        return !!val.length
      }
      return true
    })
    .map(([key, _]) => key)

  // dont render list at all if no applied filters
  if (appliedFilters.length === 0) return null

  // we only render a subset of filters, but we support all of them on the BE
  // so this gets the diff - if it's a positive number we render the n+ msg
  const nPlusFiltersLen = difference(appliedFilters, allowedFilters).length

  return (
    <List>
      {appliedFilters.map((key) => {
        // just use startDate to render date range
        if (key === 'endDate') return null

        // lat/lon are added automatically when you put you have an address
        // but we only need to render the zipcode from the address - so skip
        if (key === 'lat' || key === 'lon') return null

        const valueToRender = getEventCardTextFromFilterParam(
          filterParams,
          key,
          organization
        )

        if (!valueToRender) return null

        const keyToRender =
          typeof filterMap[key] !== 'undefined' ? filterMap[key] : ''

        if (!keyToRender) return null

        return (
          <li key={key}>
            {filterMap[key]}: {valueToRender}
          </li>
        )
      })}
      {nPlusFiltersLen > 0 && (
        <li>
          +{nPlusFiltersLen} more {pluralize('filter', nPlusFiltersLen)}
        </li>
      )}
    </List>
  )
}
