import {Carousel} from 'events/components'
import EventFeedItem from './EventFeedItem'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const MIN_EVENT_HEIGHT = '450px'
const PROMOTABLE_EVENT_HEIGHT = '364px'

const CarouselEventsOuterWrapper = styled.div`
  & .mbz-feed-item {
    min-width: 320px;
    width: 300px;
    vertical-align: middle;
    margin: ${styles.space.m};
    scroll-snap-align: center;
    white-space: normal;

    /* some events are smaller than others. */
    min-height: ${(props) =>
      props.isPromotable ? PROMOTABLE_EVENT_HEIGHT : MIN_EVENT_HEIGHT};
    height: ${(props) =>
      props.isPromotable ? PROMOTABLE_EVENT_HEIGHT : MIN_EVENT_HEIGHT};
  }

  & .mbz-feed-item-container {
    height: 100%;
  }
`

export default function EventsCarousel({
  carouselCategory,
  categoryLikelyHasEvents,
  events,
  eventSuggestionContext,
  header,
  subheader,
  isLoading,
  moreEventsUrl,
  signupSource,
  promotionContext,
  isPromotable,
  promotingOrg,
  fluid,
  backgroundColor,
}) {
  return (
    <CarouselEventsOuterWrapper
      isPromotable={isPromotable}
      data-testid="event_carousel"
    >
      <Carousel
        categoryLikelyHasItems={categoryLikelyHasEvents}
        carouselId={carouselCategory}
        header={header}
        subheader={subheader}
        isLoading={isLoading}
        items={events}
        actionUrl={moreEventsUrl}
        itemRenderFn={(item) => (
          <EventFeedItem
            event={item}
            eventSuggestionContext={eventSuggestionContext}
            signupSource={signupSource}
            isCompactFeedItem
            promotionContext={promotionContext}
            isPromotableEvent={isPromotable}
            promotingOrg={promotingOrg}
          />
        )}
        itemKeyFn={(item) => item.id}
        fluid={fluid}
        backgroundColor={backgroundColor}
      />
    </CarouselEventsOuterWrapper>
  )
}
