import styles, {
  focusable,
  getActiveColor,
  getBorderRadiusFromProps,
  getHoverColor,
  getLinkStyleFromProps,
  getPrimaryFontSizeFromProps,
  getSecondaryBodyFontFromProps,
  readableColor,
} from './styles'

import {INPUT_HEIGHT} from './styles/muiTheme'
import Icon from './Icon'
import {css} from '@emotion/react'
import {forwardRef} from 'react'
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled/macro'

const ICON_MARGIN = '0.75rem'

const ButtonIconPosition = Object.freeze({
  LEFT: 'left',
  LEFT_INLINE: 'left-inline',
  RIGHT: 'right',
  RIGHT_INLINE: 'right-inline',
})

// For `sort-down` we put it up a notch to align better.
const IconWrapper = styled.span`
  ${(props) => props.name === 'sort-down' && 'margin-top: -6px;'}

  ${(props) =>
    [ButtonIconPosition.RIGHT, ButtonIconPosition.RIGHT_INLINE].includes(
      props.position
    )
      ? `margin-left: ${props.noMargin ? '0' : ICON_MARGIN}`
      : `margin-right: ${props.noMargin ? '0' : ICON_MARGIN}`};
`

const maybeGetSelectedColorOrDefault = (props, defaultColor) => {
  if (!props.selected) {
    return defaultColor
  }
  return getSelectedColor(props)
}

const getSelectedColor = (props) => {
  if (props.secondary) {
    return props.theme.secondary_button_selected_background_color
  }
  return styles.colors.neutral500
}

const LINK_TEXT_HOVER_TARGET_CLASSNAME = 'hover-target'

const getHoverState = (props) => {
  if (props.disabled || props.loading || props.plain) {
    return
  }
  if (props.link) {
    return css`
      ${props.linkTextColor && `color: ${getHoverColor(props.linkTextColor)}`};
      ${props.noUnderline && 'text-decoration: none;'}
    `
  }
  if (props.selected) {
    const color = getHoverColor(getSelectedColor(props))
    return css`
      background-color: ${color};
      border-color: ${color};
    `
  }
  if (props.color) {
    const color = getHoverColor(props.color)
    return css`
      background-color: ${color};
      border-color: ${color};
    `
  }
  if (props.checked === false) {
    return
  }
  if (props.checked === true) {
    return css`
      background-color: ${styles.colors.success200};
      border-color: ${styles.colors.success200};
      color: ${styles.colors.white};
    `
  }
  if (props.success) {
    return css`
      background-color: ${styles.colors.success300};
      border-color: ${styles.colors.success300};
      color: ${styles.colors.white};
    `
  }
  if (props.error || props.destructive) {
    return css`
      background-color: ${styles.colors.error300};
      border-color: ${styles.colors.error300};
    `
  }
  if (props.secondary) {
    return css`
      background-color: ${props.theme.secondary_button_hover_background_color};
      border-color: ${props.theme.secondary_button_hover_border_color};
      color: ${props.theme.secondary_button_hover_text_color};
    `
  }
  if (props.inversePrimaryColors) {
    return css`
      background-color: ${props.theme.secondary_button_hover_background_color};
      border-color: ${props.theme.secondary_button_hover_border_color};
    `
  }
  return css`
    background-color: ${props.theme.primary_button_hover_background_color};
    border-color: ${props.theme.primary_button_hover_border_color};
    color: ${props.theme.primary_button_hover_text_color};
  `
}

const getActiveState = (props) => {
  if (props.disabled || props.link || props.checked !== undefined) {
    return
  }

  if (props.success) {
    return css`
      background-color: ${styles.colors.success300};
      border-color: ${styles.colors.success300};
      color: ${styles.colors.white};
    `
  }
  if (props.error || props.destructive) {
    return css`
      background-color: ${styles.colors.error300};
      border-color: ${styles.colors.error300};
    `
  }
  if (props.secondary) {
    return css`
      background-color: ${getActiveColor(
        props.theme.secondary_button_hover_background_color
      )};
      color: ${getActiveColor(props.theme.secondary_button_hover_text_color)};
    `
  }
  if (props.inversePrimaryColors) {
    return css`
      background-color: ${getActiveColor(
        props.theme.secondary_button_hover_background_color
      )};
    `
  }

  const color = getActiveColor(
    maybeGetSelectedColorOrDefault(
      props,
      props.color || props.theme.primary_button_hover_background_color
    )
  )
  return css`
    background-color: ${color};
    border-color: ${color};
  `
}

const linkCss = (props) => css`
  background: transparent;
  border: none;
  font: inherit;
  ${!props.plain &&
  getLinkStyleFromProps(props, {
    disableHover: props.disabled,
    disableBackground: props.noBackground,
    textHoverTarget: props.icon
      ? `.${LINK_TEXT_HOVER_TARGET_CLASSNAME}`
      : undefined,
  })};
  ${!props.plain && getSecondaryBodyFontFromProps(props)};
  ${props.linkTextColor && `color: ${props.linkTextColor}`};
  text-transform: none;
`

const getPadding = (props) => {
  if (props.padding === 'none') {
    return 'padding: 0;'
  }
  // default: props.padding === 'normal'
  // could eventually add more enum values here
  return css`
    padding-left: ${styles.space.m};
    padding-right: ${styles.space.m};
    padding-top: ${styles.space.s};
    padding-bottom: ${styles.space.s};
  `
}

const getColorsAndBorder = (props) => {
  let backgroundColor = maybeGetSelectedColorOrDefault(
    props,
    props.color || props.theme.primary_button_background_color
  )
  let color = props.selected
    ? styles.colors.white
    : (props.color && readableColor(props.color)) ||
      props.theme.primary_button_text_color
  let borderColor = maybeGetSelectedColorOrDefault(
    props,
    props.color || props.theme.primary_button_border_color
  )

  if (props.inversePrimaryColors) {
    const temp = color
    color = backgroundColor
    backgroundColor = temp
    borderColor = maybeGetSelectedColorOrDefault(
      props,
      props.theme.secondary_button_border_color
    )
  }

  return css`
    color: ${color};
    background-color: ${backgroundColor};
    border: ${props.theme.button_border_width} solid ${borderColor};
  `
}

const getSecondaryCss = (props) => css`
  border: ${props.theme.button_border_width} solid
    ${maybeGetSelectedColorOrDefault(
      props,
      props.theme.secondary_button_border_color
    )};
  color: ${props.selected
    ? styles.colors.white
    : props.theme.secondary_button_text_color};
  background-color: ${maybeGetSelectedColorOrDefault(
    props,
    props.theme.secondary_button_background_color
  )};
  font-weight: ${styles.typography.fontWeightSemibold};
`

// Used for `success` and `checked`.
const getSuccessCss = (props) => css`
  border: ${props.theme.button_border_width} solid ${styles.colors.success200};
  color: ${styles.colors.white};
  background-color: ${styles.colors.success200};
  font-weight: ${styles.typography.fontWeightSemibold};
`

const getErrorCss = (props) => css`
  border: ${props.theme.button_border_width} solid ${styles.colors.error200};
  color: ${styles.colors.white};
  background-color: ${styles.colors.error200};
  font-weight: ${styles.typography.fontWeightSemibold};
`

const disabledCss = (props) => {
  return css`
    cursor: not-allowed;
    ${!props.selected && 'opacity: 0.65'};
  `
}

const iconCss = (props) => {
  return css`
    /* TODO(jared) this should probably be inline-flex to keep Button inline by default. */
    display: flex;
    ${props.children
      ? css`
          justify-content: space-between;
          text-align: left;
        `
      : css`
          justify-content: center;
          text-align: center;
        `}
    align-items: center;

    /* This is to make the button look square. Height to match our inputs. */
    min-width: ${INPUT_HEIGHT}px;
    min-height: ${INPUT_HEIGHT}px;
  `
}

const CenteredButtonLabel = styled.span`
  text-align: center;
  width: 100%;

  ${(props) =>
    props.fluid &&
    props.iconPosition === ButtonIconPosition.LEFT &&
    `margin-left: calc(-16px - ${ICON_MARGIN})`};
  word-wrap: break-word;
  padding-left: 1.25rem;
`

const additionalPropsToForward = new Set([
  'fluid',
  'loading',
  'icon',
  'iconFontSize',
  'iconPosition',
  'iconWidth',
  'iconStyle',
])
// https://emotion.sh/docs/styled#customizing-prop-forwarding
const shouldForwardProp = (prop) =>
  isPropValid(prop) || additionalPropsToForward.has(prop)

/**
 * A button, with primary/secondary/link options.
 *
 * A button can also have a `selected` option (dark grey color).
 *
 * Should live inside a `ThemeProvider` (usually `OrganizationThemeProvider`).
 *
 * Can also pass a font-awesome icon name with the `icon` prop.
 */
const Button = styled(
  forwardRef(
    (
      {
        // $FlowFixMe (mime): weird, says `icon` is missing
        icon,
        fluid,
        iconFontSize,
        iconPosition,
        iconWidth,
        iconStyle,
        children,
        loading,
        ...props
      },
      ref
    ) => {
      if (icon || props.checked !== undefined) {
        const name = props.checked !== undefined ? 'check' : icon

        const iconProps = {
          checked: props.checked,
          loading,
          name,
          fontSize: iconFontSize,
          width: iconWidth,
          iconStyle,
        }

        const buttonProps = {
          ...props,
          disabled: props.disabled || loading,
          role: props.checked !== undefined ? 'checkbox' : undefined,
          'aria-checked': props.checked,
        }

        const renderIconInline = () => (
          <IconWrapper name={name} position={iconPosition} noMargin={!children}>
            <Icon {...iconProps} noMargin />
          </IconWrapper>
        )

        return (
          <button {...buttonProps} ref={ref}>
            {iconPosition !== ButtonIconPosition.RIGHT ? (
              <>
                {iconPosition === ButtonIconPosition.LEFT && (
                  <IconWrapper
                    name={name}
                    position={iconPosition}
                    noMargin={!children}
                  >
                    <Icon {...iconProps} noMargin />
                  </IconWrapper>
                )}
                {children && (
                  <CenteredButtonLabel
                    iconPosition={iconPosition}
                    fluid={fluid}
                  >
                    {iconPosition === ButtonIconPosition.LEFT_INLINE &&
                      renderIconInline()}
                    <span className={LINK_TEXT_HOVER_TARGET_CLASSNAME}>
                      {children}
                    </span>
                    {iconPosition === ButtonIconPosition.RIGHT_INLINE &&
                      renderIconInline()}
                  </CenteredButtonLabel>
                )}
              </>
            ) : (
              <>
                {children && (
                  <span className={LINK_TEXT_HOVER_TARGET_CLASSNAME}>
                    {children}
                  </span>
                )}
                <IconWrapper
                  name={name}
                  position={iconPosition}
                  noMargin={!children}
                >
                  <Icon {...iconProps} noMargin />
                </IconWrapper>
              </>
            )}
          </button>
        )
      }
      return (
        <button {...props} ref={ref}>
          {children}
        </button>
      )
    }
  ),
  {shouldForwardProp}
)`
  ${getBorderRadiusFromProps};
  ${(props) =>
    getPrimaryFontSizeFromProps(props, styles.typography.fontSizeBase)};
  ${(props) => !props.link && `min-height: ${INPUT_HEIGHT}px;`};
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  ${getColorsAndBorder};
  ${getPadding};
  ${(props) => props.nowrap && 'white-space: nowrap;'}
  ${(props) => props.fluid && 'width: 100%;'}
  ${(props) => (props.disabled || props.loading) && disabledCss(props)};
  ${(props) =>
    props.link ? linkCss(props) : getSecondaryBodyFontFromProps(props)};
  ${(props) => `font-weight: ${props.theme.link_font_weight};`}
  ${(props) => props.secondary && !props.selected && getSecondaryCss(props)};
  ${(props) =>
    props.checked === true && !props.selected && getSuccessCss(props)};
  ${(props) =>
    props.checked === false && !props.selected && getSecondaryCss(props)};
  ${(props) => props.success && !props.selected && getSuccessCss(props)};
  ${(props) =>
    (props.error || props.destructive) &&
    !props.selected &&
    getErrorCss(props)};
  ${(props) => props.icon && iconCss(props)};
  &:hover {
    ${getHoverState};
  }
  &:active {
    ${getActiveState};
  }
  ${(props) => props.active && getActiveState(props)};
  ${(props) => props.centered && 'text-align: center;'}
  ${(props) => props.fitContent && 'width: fit-content;'}
  ${(props) => focusable(getHoverState(props))};
`

// $FlowFixMe(jared): ComponentType doesn't include defaultProps https://github.com/facebook/flow/issues/5692
Button.defaultProps = {iconPosition: ButtonIconPosition.RIGHT}

// TODO(mime): this only works dashboard-side currently. Not whitelabel-able.
const ComboButtonWrapper = styled.span`
  padding: 0;
  border: 0;

  & > ${Button} {
    border: 1px solid transparent;
    display: inline-block;
    padding-left: ${styles.space.m};
    padding-right: ${styles.space.m};
    padding-top: ${styles.space.s};
    padding-bottom: ${styles.space.s};

    &:hover {
      background-color: ${styles.colors.primary300};
      border-color: transparent;
    }
  }

  & > ${Button}:first-of-type {
    ${(props) =>
      getBorderRadiusFromProps(props, new Set(['top-left', 'bottom-left']))};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > ${Button}:last-of-type {
    ${(props) =>
      getBorderRadiusFromProps(props, new Set(['top-right', 'bottom-right']))};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
export const ComboButton = ({children}) => (
  <ComboButtonWrapper>{children}</ComboButtonWrapper>
)

export const ButtonGroup = styled.div`
  display: inline-block;
  border-radius: ${styles.space.borderRadius};
  border: 1px solid ${styles.colors.neutral300};
  width: fit-content;

  > ${Button} {
    border-radius: 0;
  }

  > ${Button}:first-of-type {
    ${(props) =>
      getBorderRadiusFromProps(props, new Set(['top-left', 'bottom-left']))};
  }

  > ${Button}:last-of-type {
    ${(props) =>
      getBorderRadiusFromProps(props, new Set(['top-right', 'bottom-right']))};
  }
`

/** @component */
export default Button
