import {EventSuggestionType, EventVisibility} from 'app/enums'
import {
  getOrganizationEventByEventIdUrl,
  getOrganizationFeedUrl,
} from 'util/routing'
import {getQueryParams, isSamePath, isValidURL} from 'util/url'
import {useEffect, useState} from 'react'

import EventCard from './EventCard'
import {Input} from 'components'
import api from 'data/api'
import {compact} from 'underscore'
import cv from 'util/clientVars'
import {formatEventTimesOneLine} from 'util/timeslot'
import {getEventIdFromUrl} from 'util/event'
import {queryStringToFilterParams} from 'util/feed'

const getWarningsForFeedSuggestedEvents = (events) => {
  if (events.length === 0) {
    return [
      {
        icon: 'hourglass-end',
        text: 'No upcoming events',
      },
    ]
  }
  return []
}

const getWarningsForSuggestedEvent = (event, allowVirtualFlexible) => {
  const {is_virtual_flexible, times, visibility} = event
  const isPast = times.length === 0

  return compact([
    isPast && {
      icon: 'hourglass-end',
      text: 'This event has passed',
    },
    visibility === EventVisibility.PRIVATE && {
      icon: 'lock',
      text: 'This event is private. Are you sure you want to include it?',
    },
    is_virtual_flexible &&
      !allowVirtualFlexible && {
        icon: 'lock',
        text:
          'Currently you can only add shifted events, not flexible events or online actions.',
      },
  ])
}

const getScreenDataUrlFromEventSuggestion = (eventSuggestion, organization) => {
  const {
    suggested_event_id: suggestedEventId,
    suggested_filter_param_query_string: suggestedFilterParamQueryString,
  } = eventSuggestion

  if (suggestedEventId) {
    return getOrganizationEventByEventIdUrl(organization, suggestedEventId)
  }

  const suggestedFilterParams = queryStringToFilterParams(
    suggestedFilterParamQueryString || ''
  )
  return getOrganizationFeedUrl(organization, suggestedFilterParams)
}

const getSuggestionType = ({destinationIsFeed, sourceIsOrganization}) => {
  if (sourceIsOrganization) {
    return destinationIsFeed
      ? EventSuggestionType.ORGANIZATION_TO_FEED_FILTER_PARAMS
      : EventSuggestionType.ORGANIZATION_TO_EVENT
  }
  return destinationIsFeed
    ? EventSuggestionType.EVENT_TO_FEED_FILTER_PARAMS
    : EventSuggestionType.EVENT_TO_EVENT
}

export default function EditableEventSuggestion(props) {
  const {
    organization,
    eventSuggestion,
    error,
    dispatch,
    sourceIsOrganization,
    index,
    dontAllowFeeds,
    allowVirtualFlexible,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const [suggestedEvent, setSuggestedEvent] = useState(null)
  const [feedSuggestedEvents, setFeedSuggestedEvents] = useState(null)
  const [url, setUrl] = useState('')

  const {pathname: orgFeedPathname} = getOrganizationFeedUrl(organization)
  useEffect(() => {
    async function fetchEventOrFeed(eventSuggestion) {
      setIsLoading(true)
      const fetchUrl = getScreenDataUrlFromEventSuggestion(
        eventSuggestion,
        organization
      )
      let resp
      try {
        resp = await api.fetchScreenResponse(
          fetchUrl.pathname,
          getQueryParams(fetchUrl.search),
          true
        )
      } catch (e) {
        if (e.isRedirect) {
          const {search: redirectSearch, pathname: redirectPathname} = new URL(
            e.url
          )
          const parsedRedirectQueryParams = getQueryParams(redirectSearch)

          // If we are redirecting to the feed with `?error=404` it means the event isn't owned or
          // promoted by the org
          if (
            redirectPathname === orgFeedPathname &&
            parsedRedirectQueryParams.error === '404'
          ) {
            dispatch({
              type: 'errored',
              data: {
                index,
                error: 'Event is not owned or promoted by this organization',
              },
            })
            return
          }

          // Otherwise, try to follow the redirect (it's probably a redirect to a landing page)
          try {
            resp = await api.fetchScreenResponse(
              redirectPathname,
              parsedRedirectQueryParams,
              true
            )
            // If this fails, fall through to the unknown error handling below (`resp` will be
            // undefined)
          } catch {}
        }
      } finally {
        // Note that this executes before we return in the case where the error is caught
        setIsLoading(false)
      }

      if (!resp) {
        dispatch({
          type: 'errored',
          data: {
            index,
            error: 'Something went wrong - try again?',
          },
        })
        return
      }

      // `event` will be in the response if it's a request to the event endpoint, whereas `events`
      // will come back for the feed endpoint
      const {event, events} = resp.data

      if (events && dontAllowFeeds) {
        dispatch({
          type: 'errored',
          data: {
            index,
            error: 'Only paste in specific events and actions',
          },
        })
        return
      }

      if (event) {
        setSuggestedEvent(event)
      } else if (events) {
        setFeedSuggestedEvents(events)
      }
      dispatch({type: 'loaded'})
    }
    if (eventSuggestion) {
      fetchEventOrFeed(eventSuggestion)
    }
    // Passing `dispatch` as a dependency is OK because its identity is constant
  }, [
    eventSuggestion,
    organization,
    orgFeedPathname,
    index,
    dispatch,
    dontAllowFeeds,
  ])

  useEffect(() => {
    // if input is blank, reset error and kick out early. fires somewhat gratuitously when e.g. the
    // component first mounts, but isn't actually harmful in practice
    if (!url) {
      dispatch({type: 'cleared', data: {index}})
      return
    }

    const {pathname: path = '', search} = isValidURL(url) ? new URL(url) : {}

    // we consider this a feed url if the pathname typed in and the org's pathname are the same
    const destinationIsFeed = isSamePath(path, orgFeedPathname)
    const filterParamQueryString = destinationIsFeed ? search : null

    // if we can pull out an event id, then we consider this suggestion to have an event destination
    const eventId = getEventIdFromUrl(url)

    // if it's not recognized as a feed or an event, then the url is likely only halfway typed in,
    // or is otherwise not parsing or invalid, so just kick out early and don't add the suggestion
    if (!destinationIsFeed && !eventId) {
      return
    }

    const suggestionToAdd = {
      suggested_event_id: eventId,
      suggested_filter_param_query_string: filterParamQueryString,
      suggestion_type: getSuggestionType({
        destinationIsFeed,
        sourceIsOrganization,
      }),
    }

    dispatch({
      type: 'added',
      data: {index, eventSuggestion: suggestionToAdd},
    })
    // Passing `dispatch` as a dependency is OK because its identity is constant
  }, [url, orgFeedPathname, sourceIsOrganization, index, dispatch])

  if (!eventSuggestion) {
    return (
      <Input
        type="url"
        disabled={isLoading}
        error={!!error}
        hint={error}
        label={`Paste ${cv.product_name} event${
          dontAllowFeeds ? '' : ' or feed link'
        }`}
        name={`event_link_${index}`}
        onChange={(ev) => setUrl(ev.target.value)}
        value={url}
      />
    )
  }

  let timeslotDetail = ''
  if (suggestedEvent) {
    timeslotDetail = formatEventTimesOneLine(suggestedEvent)
  } else if (feedSuggestedEvents) {
    timeslotDetail = 'Feed of multiple events'
  }

  const warnings = suggestedEvent
    ? getWarningsForSuggestedEvent(suggestedEvent, allowVirtualFlexible)
    : feedSuggestedEvents
    ? getWarningsForFeedSuggestedEvents(feedSuggestedEvents)
    : // One or the other will be nonnull at this point, but Flow doesn't know, so default to []
      []

  return (
    <EventCard
      isLoading={isLoading}
      eventName={suggestedEvent?.name}
      filterParams={queryStringToFilterParams(
        // Ignored if it's an event suggestion, so fine to pass an empty filter params object in
        // that case (i.e., when `suggested_filter_param_query_string` is null)
        eventSuggestion.suggested_filter_param_query_string || ''
      )}
      onRequestDismiss={() => {
        setUrl('')
        setSuggestedEvent(null)
        setFeedSuggestedEvents(null)
        dispatch({type: 'removed', data: {index}})
      }}
      organization={organization}
      timeslotDetail={timeslotDetail}
      to={getScreenDataUrlFromEventSuggestion(
        eventSuggestion,
        organization
      ).toString()}
      warnings={warnings}
    />
  )
}
