import React, {useState} from 'react'
import styles, {mediaMinWidthSmall} from 'components/styles'

import {Icon} from 'components'
import styled from '@emotion/styled/macro'

const ExpandButton = styled.div`
  color: ${styles.colors.primary200};
  flex-basis: 100%;
  font-weight: 600;
  margin: 1rem auto;
  text-align: center;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`

const ListExpandContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaMinWidthSmall(`
    flex-wrap: wrap;
    flex-direction: row;
  `)}
  width: 100%;
`

const ButtonContainer = styled.div`
  width: 100%;
`

export default function ListExpand({
  childElement,
  expandText,
  initialNumber,
  list,
  listPropName,
  ...props
}) {
  const [expanded, setExpanded] = useState(false)
  return (
    <ListExpandContainer data-testid="list_expand">
      {list.map((item, index) => {
        if (index >= initialNumber && expanded === false) return null
        return React.createElement(childElement, {
          [listPropName]: list[index],
          key: index,
          ...props,
        })
      })}
      {list.length > initialNumber && (
        <ButtonContainer>
          {expanded ? (
            <ExpandButton
              data-testid="list_expand_button"
              tabIndex={0}
              onClick={() => setExpanded(!expanded)}
              onKeyPress={(e) => {
                if (e.code === 'Enter') {
                  setExpanded(!expanded)
                }
              }}
            >
              See less <Icon name="angle-up" />
            </ExpandButton>
          ) : (
            <ExpandButton
              data-testid="list_expand_button"
              tabIndex={0}
              onClick={() => setExpanded(!expanded)}
              onKeyPress={(e) => {
                if (e.code === 'Enter') {
                  setExpanded(!expanded)
                }
              }}
            >
              {expandText}&nbsp;
              <Icon name="angle-down" />
            </ExpandButton>
          )}
        </ButtonContainer>
      )}
    </ListExpandContainer>
  )
}
