/**
 * Be sure to keep this up to date with the $UsTimezone type in enums.ts as well as the backend
 * enum in lib.datetime. Note that if you update this, you probably also want to re-generate the
 * data that moment-timezone uses; see `/docs/infra-front-end/moment-timezone.md`.
 *
 * Note that this uses module.exports to be compatible with CommonJS require() (for moment timezone
 * generation script) as well as ES6 import (for enums.ts and regular app code).
 */
module.exports = {
  AMERICAN_SAMOA: 'Pacific/Pago_Pago',
  HAWAII: 'Pacific/Honolulu',
  HAWAII_ALEUTIAN: 'America/Adak',
  ALASKA: 'America/Anchorage',
  ALASKA_JUNEAU: 'America/Juneau',
  ALASKA_SITKA: 'America/Sitka',
  ALASKA_METLAKATLA: 'America/Metlakatla',
  ALASKA_YAKUTAT: 'America/Yakutat',
  ALASKA_NOME: 'America/Nome',
  PACIFIC: 'America/Los_Angeles',
  ARIZONA: 'America/Phoenix',
  MOUNTAIN: 'America/Denver',
  MOUNTAIN_BOISE: 'America/Boise',
  CENTRAL: 'America/Chicago',
  CENTRAL_INDIANA_TELL_CITY: 'America/Indiana/Tell_City',
  CENTRAL_INDIANA_KNOX: 'America/Indiana/Knox',
  CENTRAL_MENOMINEE: 'America/Menominee',
  CENTRAL_NORTH_DAKOTA_CENTER: 'America/North_Dakota/Center',
  CENTRAL_NORTH_DAKOTA_NEW_SALEM: 'America/North_Dakota/New_Salem',
  CENTRAL_NORTH_DAKOTA_BEULAH: 'America/North_Dakota/Beulah',
  EASTERN: 'America/New_York',
  EASTERN_DETROIT: 'America/Detroit',
  EASTERN_KENTUCKY_LOUISVILLE: 'America/Kentucky/Louisville',
  EASTERN_KENTUCKY_MONTICELLO: 'America/Kentucky/Monticello',
  EASTERN_INDIANA_INDANAPOLIS: 'America/Indiana/Indianapolis',
  EASTERN_INDIANA_VINCENNES: 'America/Indiana/Vincennes',
  EASTERN_INDIANA_WINAMAC: 'America/Indiana/Winamac',
  EASTERN_INDIANA_MARENGO: 'America/Indiana/Marengo',
  EASTERN_INDIANA_PETERSBURG: 'America/Indiana/Petersburg',
  EASTERN_INDIANA_VEVAY: 'America/Indiana/Vevay',
  ATLANTIC_PUERTO_RICO: 'America/Puerto_Rico',
  ATLANTIC_ST_THOMAS: 'America/St_Thomas',
  PALAU: 'Pacific/Palau',
  CHAMORRO_GUAM: 'Pacific/Guam',
  CHAMORRO_NORTHERN_MARIANA_ISLANDS: 'Pacific/Saipan',
  CHUUK: 'Pacific/Chuuk',
  MARSHALL_ISLANDS_MAJURO: 'Pacific/Majuro',
}
