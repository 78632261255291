import {useContext, useEffect} from 'react'
// $FlowFixMe (mime): types need updating for latest react-router
import {useLocation, withRouter} from 'react-router'

import CurrentOrganizationContext from './CurrentOrganizationContext'
import CurrentUserContext from '../app/CurrentUserContext'
import analytics from 'analytics'
import {useIsAllocatedToExperimentVariant} from 'hooks/experiments'

function PageAnalytics({
  analyticsPageName,
  match,
  screenOptions,
  wrappedProps,
}) {
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const {user} = useContext(CurrentUserContext)
  const location = useLocation()
  const shouldSendSampleToAmplitude = useIsAllocatedToExperimentVariant(
    'sample_events_to_amplitude',
    'on'
  )

  useEffect(() => {
    analytics.tracker.setPageName(analyticsPageName)
    analytics.tracker.setShouldSendSampleToAmplitude(
      shouldSendSampleToAmplitude
    )

    let additionalProps = {}
    if (screenOptions.additionalTrackPageLoadedProps) {
      additionalProps = screenOptions.additionalTrackPageLoadedProps(
        wrappedProps
      )
    }
    // TODO(jared) if we ever turn this code on, the page title (picked up by segment) might
    // actually be out of date (or, more likely, there could be a race condition), since it's
    // updated in a separate useEffect hook in a parent component
    analytics.trackPageLoaded(currentOrganization, location, additionalProps)

    if (match) {
      analytics.trackClientPerformance(match.path)
      analytics.setCurrentCanonicalPath(match.path)
    }
  }, [
    analyticsPageName,
    currentOrganization,
    location,
    match,
    screenOptions,
    shouldSendSampleToAmplitude,
    user,
    wrappedProps,
  ])

  return null
}

export default withRouter(PageAnalytics)
