import {Alert} from '@material-ui/lab'
import {F} from '../../util/i18n'
import {filterOutAtCapacityTimeslots} from '../../util/timeslot'
import styled from '@emotion/styled/macro'

const AlertWrapper = styled.div`
  width: 100%;
`

export default function SpecialCaseInfoBanner({event, checkInStartTime}) {
  const isPast = !(event.times && event.times.length)
  const tooEarlyForCheckIn =
    checkInStartTime && new Date(checkInStartTime) > new Date()
  const allFull =
    event.times && !filterOutAtCapacityTimeslots(event.times).length
  return isPast ? (
    <AlertWrapper>
      <Alert severity="info">
        <F defaultMessage="Event ended." />
      </Alert>
    </AlertWrapper>
  ) : tooEarlyForCheckIn ? (
    <AlertWrapper>
      <Alert severity="warning">
        <F defaultMessage="Too early to check in." />
      </Alert>
    </AlertWrapper>
  ) : allFull ? (
    <AlertWrapper>
      <Alert severity="info">
        <F defaultMessage="Event at capacity." />
      </Alert>
    </AlertWrapper>
  ) : null
}
