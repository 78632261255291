import {CardModal} from 'components'
import LoginComponent from './LoginComponent'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

export const LoginComponentWrapper = styled.div`
  padding-bottom: ${styles.space.xl};
`

/**
 * Universal login modal.
 */
export default function LoginModal({
  onRequestClose,
  onFacebookConnected,
  onGoogleConnected,
  isOpen,
}) {
  return (
    <CardModal
      data-testid="login_modal"
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      narrow
    >
      <LoginComponentWrapper>
        <LoginComponent
          onFacebookConnected={onFacebookConnected}
          onGoogleConnected={onGoogleConnected}
        />
      </LoginComponentWrapper>
    </CardModal>
  )
}
