import {storagePathPrefix} from './config'

const maybeGetStorage = (storageName) => {
  // Because Firefox is ridiculous, only try to access localStorage and sessionStorage from
  // within a try-catch statement
  try {
    return window[storageName]
  } catch (e) {
    return null
  }
}

const maybeSetInStorage = (storageName) => (path, val) => {
  // Stringifies the provided object as JSON and saves it at pathprefix+path in storage
  // Does nothing if the storage at storageName is inaccessible or nonexistent
  const storage = maybeGetStorage(storageName)
  if (!storage) return

  // In a try-catch because we get a lot of errors of the nature:
  // "QuotaExceededError: DOM Exception 22: An attempt was made to add something to storage that exceeded the quota."
  // We ignore them in Sentry but still we shouldn't be sending them up.
  try {
    storage.setItem(`${storagePathPrefix}${path}`, JSON.stringify(val))
  } catch (ex) {
    // Do nothing
  }
}

const maybeGetFromStorage = (storageName) => (path) => {
  // Tries to get the value at pathprefix+path in storage; if one is found, parse and return it
  // as a POJO. o/w, null. Returns null if the storage at storageName is inaccessible or nonexistent
  const storage = maybeGetStorage(storageName)
  if (!storage) return null

  let maybeVal
  // In a try-catch because we get a lot of errors of the nature:
  // "NS_ERROR_FILE_CORRUPTED maybeGetFromLocalStorage(redux/util)"
  try {
    maybeVal = storage.getItem(`${storagePathPrefix}${path}`)
  } catch (e) {
    return null
  }

  if (!maybeVal) return null

  try {
    return JSON.parse(maybeVal)
  } catch (e) {
    console.warn(`Could not JSON parse from ${storageName}: ${maybeVal}`)
    return null
  }
}

const maybeGetFromLocalStorage = maybeGetFromStorage('localStorage')
const maybeGetFromSessionStorage = maybeGetFromStorage('sessionStorage')
const maybeSetInLocalStorage = maybeSetInStorage('localStorage')
const maybeSetInSessionStorage = maybeSetInStorage('sessionStorage')

export {
  maybeSetInLocalStorage,
  maybeSetInSessionStorage,
  maybeGetFromLocalStorage,
  maybeGetFromSessionStorage,
}
