import {isValidPhoneNumber, parsePhoneNumber} from 'libphonenumber-js'

export const DEFAULT_PHONE_COUNTRY_CODE = 'US'

export function formatPhoneNumberAsRFC3966(phoneNumber) {
  let parsedNumber = null
  if (
    phoneNumber &&
    isValidPhoneNumber(phoneNumber, DEFAULT_PHONE_COUNTRY_CODE)
  ) {
    parsedNumber = parsePhoneNumber(phoneNumber, DEFAULT_PHONE_COUNTRY_CODE)
  }

  // always adding the schema but setting the number to empty so we can still identity the type of the value
  const telUrl =
    parsedNumber && parsedNumber.isValid() ? parsedNumber.getURI() : 'tel:'
  return telUrl
}

export function formatPhoneNumberForDisplay(phoneNumber) {
  if (!phoneNumber) {
    return ''
  }

  const parsedNumber = isValidPhoneNumber(
    phoneNumber,
    DEFAULT_PHONE_COUNTRY_CODE
  )
    ? parsePhoneNumber(phoneNumber, DEFAULT_PHONE_COUNTRY_CODE)
    : ''

  if (!parsedNumber) {
    return ''
  }

  if (parsedNumber.country === DEFAULT_PHONE_COUNTRY_CODE) {
    return parsedNumber.formatNational()
  }

  return parsedNumber.formatInternational()
}

export {isValidPhoneNumber}
