export function getCustomSignupFieldTextValue(customSignupFieldValues, id) {
  if (customSignupFieldValues === null) {
    return ''
  }
  const i = customSignupFieldValues.findIndex(
    (field) => field.custom_signup_field_id === id
  )
  if (i >= 0) {
    return customSignupFieldValues[i].text_value || ''
  }
  return ''
}

export function getCustomSignupFieldBooleanValue(customSignupFieldValues, id) {
  if (customSignupFieldValues === null) {
    return false
  }
  const i = customSignupFieldValues.findIndex(
    (field) => field.custom_signup_field_id === id
  )
  if (i >= 0) {
    return customSignupFieldValues[i].boolean_value || false
  }
  return false
}
