import {
  CREDIT_CARD_PAYMENT_METHOD,
  FormDefinitionElementNames,
} from './constants'
import {
  Currency,
  OnlineActionPaymentMethodToPaymentMethod,
  OnlineActionSelectedFrequencyToPaymentFrequency,
  PaymentFrequency,
  SignupStep,
} from 'app/enums'
import {
  DJANGO_CSRF_COOKIE_NAME,
  DJANGO_CSRF_TOKEN_FIELD_NAME,
} from 'app/constants'
import {cloneDeep, logError, omit} from 'util/common'

import {ActionType} from './FundraisingFormReducer'
import Cookies from 'js-cookie'
import analytics from 'analytics'
import cv from 'util/clientVars'
import {dollarStringToCents} from 'util/currency'
import {getAvailableTimeslots} from 'events/details/util'
import {getIdentityFieldsFromFormValues} from 'util/user'
import submitSignupForm from '../SignupForm/submitSignupForm'

export const constructSubmitJsPayload = (formState) => {
  // This constructs the payload for the `additionalData` arg of the submit.js
  // Form.submit().
  //
  // Fields we should not try to overwrite from the Mobilize form include:
  // - The VGS fields (Account, ExpirationMonth, ExpirationYear,
  //   SecurityCode/CVV): they are filled out in the iframe fields created by
  //   submit.js
  // - CoverCostsAmountCalculated: this field only exists in the Mobilize
  //   form and its value will be used to overwrite CoverCostsAmount (which)
  //   is previously a boolean that is supplied to control the checkbox field.
  // - Hidden fields without defaults: Remove them from the values object
  //   (values that are undefined).
  //
  // Additional fields that we exclude:
  // - SuggestedAmount: This value may be included in form definition prefills
  // - cc_4_digit: This value may be prefilled by FastAction
  // - cc_type: This value may be prefilled by FastAction

  const values = cloneDeep(formState.values)
  const {dataBagIdentities, requiredFields, formDefinition} = formState

  // The ActionTag form remaps this value before posting, so replicating here.
  // SelectAmount will be omitted from the final payload.
  values.Amount = values.SelectAmount

  // If CoverCostsAmount is checked, replace the checkbox boolean value with the
  // actual calculated amount. Otherwise, set it to 0.
  const coverCostsAmount =
    values.CoverCostsAmount && values.CoverCostsAmountCalculated
      ? values.CoverCostsAmountCalculated
      : '0'
  values[FormDefinitionElementNames.COVER_COSTS_AMOUNT] = String(
    coverCostsAmount
  )

  // N.B. If HomePhone is a required field, copy over the value of MobilePhone
  // because we only include the MobilePhone field in the Mobilize form. We
  // always require a phone for our signups (mapped to MobilePhone) so we do not
  // need to do anything if MobilePhone is a required field.
  //
  // We may potentially override an existing HomePhone on the supporter's
  // EveryAction profile, so we can revisit this in the future if it becomes a
  // concern.
  if (requiredFields.HomePhone) {
    values.HomePhone = values.MobilePhone
  }

  values.actionProfileId = dataBagIdentities.actionProfileId
  values.fastActionId = dataBagIdentities.fastActionId
  values.fastActionSessionId = dataBagIdentities.fastActionSessionId

  // If cc_4_digit and cc_type are present in the FundraisingFormState values,
  // then this submission is using a FastAction saved card.
  // cc_4_digit tells public.py to route the submission to FastAction instead of OA
  // FastActionTokenPool tells FastAction to retrieve the saved CC token
  if (values.cc_4_digit && values.cc_type) {
    values.CreditCardLastFour = values.cc_4_digit
    values.CreditCardLastFourType = values.cc_type
    // no fallback to Paragon because I'm assuming all Mobilize-supported form gateway types have a token pool in the
    // form def. If there's no fastActionTokenPool in the formDef, we should fail to match a savedCard anyway
    // formDefinition should never be null here, only doing optional chaining to make the type checker happy
    values.FastActionTokenPool = formDefinition?.metadata.fastActionTokenPool
  }

  // Remove all the extra keys that should not be merged with the current data.
  const valuesForPayload = omit(
    values,
    'CoverCostsAmountCalculated',
    FormDefinitionElementNames.ACCOUNT,
    FormDefinitionElementNames.EXPIRATION_MONTH,
    FormDefinitionElementNames.EXPIRATION_YEAR,
    FormDefinitionElementNames.SECURITY_CODE,
    'SelectAmount',
    'SuggestedAmount',
    'cc_4_digit',
    'cc_type'
  )

  // Remove any undefined values so we don't overwrite the default values of
  // hidden fields.
  Object.entries(valuesForPayload).forEach(
    ([key, value]) => value === undefined && delete valuesForPayload[key]
  )

  valuesForPayload[DJANGO_CSRF_TOKEN_FIELD_NAME] = Cookies.get(
    DJANGO_CSRF_COOKIE_NAME
  )

  return filterNotRequiredAddressFields(valuesForPayload, requiredFields)
}

export const convertDataBagFastActionDataToContributionFormValues = (
  formDefinition,
  fastActionIdentity,
  fastActionDataBag
) => {
  // most profile values come from the fastaction databag
  let values = {
    ...fastActionDataBag,
    EmailAddress: fastActionDataBag.email,
    FirstName: fastActionDataBag.firstName,
    LastName: fastActionDataBag.lastName,
  }

  // saved card comes from the fastaction identity. no fallback to Paragon because the formDef should have a token pool
  // when disableCreditCardAutofill=false. If formDef is somehow missing fastActionTokenPool, we just don't match a saved card
  const tokenPool = formDefinition.metadata.fastActionTokenPool
  const savedCard = fastActionIdentity.savedCards?.find(
    (c) => c.tokenPool === tokenPool
  )
  if (savedCard && !formDefinition.metadata.disableCreditCardAutofill) {
    values.cc_type = savedCard.ccType
    values.cc_4_digit = savedCard.ccLastFour
  }

  return omit(
    omit(values, 'email', 'firstName', 'lastName'),
    (value) => value === null || value === undefined
  )
}

export const convertDataBagProfileDataToContributionFormValues = (data) => {
  const {_canonical, nvtag} = data

  const values = {
    ...nvtag,
    EmailAddress: _canonical.email,
    FirstName: _canonical.firstName,
    LastName: _canonical.lastName,
  }
  delete values['MobilePhoneCountryCode'] // extra field we don't use
  return values
}

export const convertSubmitJsResponseToPaymentDataForAnalytics = (
  data,
  submittedValues
) => {
  const frequencyDefault = PaymentFrequency.ONE_TIME
  // creditcard is the only payment method currently supported by submit.js so
  // statically set it here since it is not included in submittedValues.
  // Update this when submit.js supports other payment methods.
  const paymentMethod = CREDIT_CARD_PAYMENT_METHOD

  const {confirmationID, contributionAmount} = data.response
  const {SelectedFrequency, ProcessingCurrency} = submittedValues

  return {
    amount_in_cents: dollarStringToCents(contributionAmount),
    frequency:
      OnlineActionSelectedFrequencyToPaymentFrequency[SelectedFrequency] ||
      frequencyDefault,
    currency: Currency[ProcessingCurrency],
    payment_method: OnlineActionPaymentMethodToPaymentMethod[paymentMethod],
    ea_confirmation_id: confirmationID,
  }
}

// Remaps Mobilize IdentityFields into the ngp-form PascalCase format.
export const convertSignupIdentityFieldsToContactInformation = (data) => {
  // It's possible for the EveryAction form to include HomePhone and MobilePhone,
  // but we only use MobilePhone in our form.
  return {
    FirstName: data.firstName,
    LastName: data.lastName,
    MobilePhone: data.phone,
    PostalCode: data.zip,
    EmailAddress: data.email,
    AddressLine1: data.addressLine1 || '',
    AddressLine2: data.addressLine2 || '',
    City: data.city || '',
    StateProvince: data.state || '',
  }
}

export const getIdentityFieldsFromContributionFormValues = (data) => {
  return {
    firstName: data.FirstName || '',
    lastName: data.LastName || '',
    email: data.EmailAddress || '',
    phone: data.MobilePhone || '',
    zip: data.PostalCode || '',
    addressLine1: data.AddressLine1 || '',
    addressLine2: data.AddressLine2 || '',
    city: data.City || '',
    state: data.StateProvince || '',
  }
}

export const filterNotRequiredAddressFields = (values, requiredFields) => {
  const ADDRESS_FIELDS = [
    'AddressLine1',
    'AddressLine2',
    'City',
    'StateProvince',
  ]
  const requiredFieldNames = Object.keys(requiredFields)
  return omit(values, (value, key) => {
    if (!ADDRESS_FIELDS.includes(key)) {
      return false
    }
    return !requiredFieldNames.includes(key)
  })
}

export const submitPaymentAndSignup = async (
  context,
  dispatch,
  onSuccess,

  isSkippingDonation,
  submitJsForm,
  formDefinition,
  paymentValues
) => {
  const paymentData = {
    submitJsForm,
    formDefinition,
    paymentValues,
  }

  const {
    event,
    organization,
    trackingParams,
    filterParams,
    eventSuggestionContext,
    location,
    signupValues,
  } = context

  const {shifts, smsOptIn} = signupValues

  const {
    FirstName: firstName,
    LastName: lastName,
    EmailAddress: email,
    MobilePhone: phone,
    PostalCode: zip,
    AddressLine1: addressLine1,
    AddressLine2: addressLine2,
    City: city,
    StateProvince: state,
  } = paymentValues

  const identityFields = {
    firstName,
    lastName,
    email,
    phone,
    zip,
    addressLine1,
    addressLine2,
    city,
    state,
  }

  dispatch({type: ActionType.SIGNUP_SUBMITTED})

  const signupErrorMessage = isSkippingDonation
    ? `There was an error recording this signup in ${cv.product_name}.`
    : `The payment succeeded, but there was an error recording it in ${cv.product_name}.`

  const validationErrorMessage = isSkippingDonation
    ? 'Please correct the following errors:'
    : 'Payment was not completed. Please correct the following errors:'

  function handleSignupError(
    dispatch,
    errorFields,
    updatedShifts,
    shiftErrors,
    signupErrorMessage,
    errorMessages
  ) {
    dispatch({
      type: ActionType.SIGNUP_ERRORED,
      data: {
        // Do not include Signup error messages since the user cannot resubmit
        // at this point as they have already made a payment.
        errors: [signupErrorMessage],
      },
    })
    logError(signupErrorMessage, {
      errorFields,
      paymentErrors: errorFields?.payment,
      updatedShifts,
      shiftErrors,
      errorMessages,
    })
  }

  function handlePaymentError(dispatch, errorMessages) {
    // Errors are currently logged to Sentry.
    const errorSummary =
      errorMessages && errorMessages.length > 0
        ? validationErrorMessage
        : 'There was an error processing the payment'
    const errors = errorMessages
      ? [errorSummary, ...errorMessages]
      : [errorSummary]
    dispatch({
      type: ActionType.PAYMENT_ERRORED,
      data: {
        errors: errors,
      },
    })
  }

  // This validation mirrors what we do on the backend when submitting a signup.
  // We do it here on the FE as well to minimize the number of cases where a user
  // might have input enough information that their payment goes through, but not
  // enough that the signup can be completed.
  // TODO(wendell): instead of pseudo-validating here, make a call to the BE that
  // validates with the same serializer we use for creating events. That way a user
  // cannot pass pre-payment validation with info that won't pass signup validation.
  function validateForm(formValues, dispatch) {
    const identityFields = getIdentityFieldsFromFormValues(formValues)
    let errorMessages = []
    if (!identityFields.firstName) {
      errorMessages.push('First name is required')
    }
    if (!identityFields.lastName) {
      errorMessages.push('Last name is required')
    }
    if (!identityFields.email) {
      errorMessages.push('Email is required')
    }
    if (!identityFields.phone) {
      errorMessages.push('Phone number is required')
    }
    if (!identityFields.zip) {
      errorMessages.push('Zipcode is required')
    }
    // NB(Zeke): not requiring city or state as a temporary solution to allow
    // some orgs not wanting to collect city/state; this may let a few more
    // validation errors through than desired, but the ideal solution involves
    // addressing the TODO(julia) mentioned above this block.
    // if (!identityFields.city) {
    //   errorMessages.push('City is required')
    // }
    // if (!identityFields.state) {
    //   errorMessages.push('State/province is required')
    // }

    if (errorMessages.length > 0) {
      dispatch({
        type: ActionType.VALIDATION_ERRORED,
        data: {
          errors: [validationErrorMessage, ...errorMessages],
        },
      })
      return false
    }
    return true
  }

  const validationPassed = validateForm(identityFields, dispatch)
  if (!validationPassed) {
    return
  }

  // Submit the form, including payment data.
  await submitSignupForm({
    customSignupFieldValues: [],
    event,
    expandActLater: false,
    initialQueryParams: {},
    location,
    onError: (
      errorFields,
      updatedShifts,
      shiftErrors,
      signupErrorStep,
      errorMessages
    ) => {
      if (signupErrorStep === SignupStep.PAYMENT && !isSkippingDonation) {
        handlePaymentError(dispatch, errorMessages)
      } else {
        handleSignupError(
          dispatch,
          errorFields,
          updatedShifts,
          shiftErrors,
          signupErrorMessage,
          errorMessages
        )
      }
    },
    onSuccess,
    organization,
    precheckedTwoTimeslots: false,
    shifts: shifts?.length
      ? shifts
      : [{timeslot_id: getAvailableTimeslots(event)[0].id}],
    trackingParams,
    values: {...identityFields, smsOptIn: smsOptIn || false},
    filterParams,
    eventSuggestionContext,
    paymentData: isSkippingDonation ? null : paymentData,
  }).catch((e) => {
    // This event should be rare and indicates an error in our code rather than
    // a failure in the request. API request errors are handled in
    // `submitSignupForm.onError`.
    dispatch({
      type: ActionType.SIGNUP_ERRORED,
      data: {
        errors: [signupErrorMessage],
      },
    })
    logError(e)
  })
}

export const trackOnlineActionFormLoaded = (formDefinition) => {
  const formElements = formDefinition.form_elements
  const contributionInfoSection = maybeFindElementByName(
    formElements,
    FormDefinitionElementNames.CONTRIBUTION_INFO
  )

  if (!contributionInfoSection) {
    return
  }

  const selectedFrequency = maybeFindElementByName(
    contributionInfoSection.children,
    FormDefinitionElementNames.SELECTED_FREQUENCY
  )

  const selectAmount = maybeFindElementByName(
    contributionInfoSection.children,
    FormDefinitionElementNames.SELECT_AMOUNT
  )

  analytics.trackOnlineActionFormLoaded({
    availableAmounts: selectAmount && Object.values(selectAmount.options),
    selectedFrequency:
      selectedFrequency &&
      selectedFrequency.default_value &&
      selectedFrequency.options.find(
        (o) => o.value === selectedFrequency.default_value
      ).display,
    availableFrequencies:
      selectedFrequency &&
      selectedFrequency.options.map((option) => option.display),
    availablePaymentMethods: {
      creditcard: !!(
        formDefinition.metadata && formDefinition.metadata.accepted_cards
      ),
      paypal: !!(
        formDefinition.metadata && formDefinition.metadata.isPayPalEnabled
      ),
    },
  })
}

const maybeFindElementByName = (formElements, name) => {
  return formElements.find((el) => el.name === name)
}
