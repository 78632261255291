import {Button} from 'components'
import LoginModal from './LoginModal'
import {useState} from 'react'

const LoginModalButton = ({
  children,
  onFacebookConnected,
  onGoogleConnected,
  onClick,
  ...buttonProps
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onThirdPartyAccountConnected = () => {
    window.location.reload()
  }

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick()
          }

          setIsModalOpen(true)
        }}
      >
        {children}
      </Button>
      <LoginModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onFacebookConnected={
          onFacebookConnected
            ? onFacebookConnected
            : onThirdPartyAccountConnected
        }
        onGoogleConnected={
          onGoogleConnected ? onGoogleConnected : onThirdPartyAccountConnected
        }
      />
    </>
  )
}

export default LoginModalButton
