import {localStoragePaths, sessionStoragePaths} from './config'
import {maybeGetFromLocalStorage, maybeGetFromSessionStorage} from './util'

const getPersistedState = () => {
  const persistedState = {}

  localStoragePaths.forEach((path) => {
    const maybeVal = maybeGetFromLocalStorage(path)
    if (maybeVal) {
      persistedState[path] = maybeVal
    }
  })
  sessionStoragePaths.forEach((path) => {
    const maybeVal = maybeGetFromSessionStorage(path)
    if (maybeVal) {
      persistedState[path] = maybeVal
    }
  })
  return persistedState
}

export {getPersistedState}
