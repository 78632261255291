import {F, defineMessages, useIntl} from 'util/i18n'
import {Image, Message} from 'components'

import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const messages = defineMessages({
  previewImageAlt: {
    defaultMessage: 'Preview of your uploaded image',
  },
})

const WIDTH = '22rem'
const ThreeTwoImagePreviewWrapper = styled.div`
  width: ${WIDTH};
`

const ImagePreviewWrapper = styled.div`
  width: ${WIDTH};
  margin-top: 15px;
  border: 1px solid ${styles.colors.neutral300};
`

export default function ImagePreview({
  src,
  useStdAspectRatioPreview,
  onImageLoaded,
}) {
  const intl = useIntl()
  const altText = intl.formatMessage(messages.previewImageAlt)
  return (
    <>
      {useStdAspectRatioPreview ? (
        <Message topMargin compact style={{maxWidth: '100%'}}>
          <p>
            <F defaultMessage="Here is a preview of your image." />
          </p>
          <p>
            <F defaultMessage="Our recommended size is 1200x630px." />
          </p>
          <ThreeTwoImagePreviewWrapper>
            <Image
              src={src}
              maxWidth={styles.limits.imageMaxWidth}
              alt={altText}
            />
          </ThreeTwoImagePreviewWrapper>
        </Message>
      ) : (
        <ImagePreviewWrapper>
          <Image src={src} onLoad={onImageLoaded} alt={altText} />
        </ImagePreviewWrapper>
      )}
    </>
  )
}
