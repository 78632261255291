import {Icon, ProgressBar, Typography} from 'components'

import {F} from 'util/i18n'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const CountWrapper = styled.div`
  padding-bottom: ${styles.space.s};
`

const ProgressMessageWrapper = styled.div`
  font-weight: ${styles.typography.fontWeightBold};
  color: ${({primary}) =>
    primary ? styles.colors.primary200 : styles.colors.black};
  padding-top: ${styles.space.xs};
`

const PromptMessageWrapper = styled.div`
  font-weight: ${styles.typography.fontWeightNormal};
  color: ${styles.colors.black};
  display: inline-block;
`

export default function ProgressToGoalDetails({
  currentGoalText,
  currentProgress,
  currentProgressText,
  expanded,
  goal,
  goalIsEnabled,
  goalMetText,
  hasCustomGoal,
  helpUsText,
  isCompactFeedItem,
  progressIconName,
}) {
  // supporterGoalMet
  const goalIsMet = hasCustomGoal && currentProgress >= goal

  // show the current count if progress is > 100 (supporters or dollars donated), or a custom goal is set
  const showCurrentCount = currentProgress > 100 || hasCustomGoal
  const showProgressBar = !goalIsMet && goalIsEnabled && showCurrentCount

  // Don't show icon if expanded with progress bar to save space and avoid line break
  const showIcon = !(expanded && showProgressBar)

  const ProgressIcon = () => (
    <Icon name={progressIconName} noMargin={!expanded} />
  )

  const GoalMetMessage = () => (
    <Typography
      component="span"
      variant={isCompactFeedItem ? 'subtitle1' : 'body1'}
    >
      <span role="img" aria-label="chart pointing up">
        📈
      </span>{' '}
      <strong>
        <F defaultMessage="We have lots of momentum!" />
      </strong>
      <div>{goalMetText}</div>
    </Typography>
  )

  const ProgressMessage = () => (
    <Typography
      component="span"
      variant={expanded ? 'body1' : isCompactFeedItem ? 'subtitle1' : 'body2'}
    >
      <ProgressMessageWrapper primary={!expanded}>
        {showIcon && (
          <>
            <ProgressIcon />{' '}
          </>
        )}
        {!goalIsEnabled ? (
          currentProgress > 100 ? (
            currentProgressText
          ) : (
            helpUsText
          )
        ) : showCurrentCount ? (
          <>
            {currentProgressText}
            {' · '}
            <PromptMessageWrapper>{currentGoalText}</PromptMessageWrapper>
          </>
        ) : (
          helpUsText
        )}
      </ProgressMessageWrapper>
    </Typography>
  )

  return (
    <>
      {expanded ? (
        <>
          <CountWrapper>
            {goalIsMet ? <GoalMetMessage /> : <ProgressMessage />}
          </CountWrapper>
          {showProgressBar && (
            <ProgressBar value={currentProgress} max={goal} />
          )}
        </>
      ) : (
        <ProgressMessage />
      )}
    </>
  )
}
