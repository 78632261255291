export function valid() {
  return {valid: true}
}

export function invalid(message) {
  return {valid: false, message}
}

/**
 * VAN source codes can only contain alphanumeric, _, and - characters,
 * per https://docs.ngpvan.com/reference/codes-common-models.
 * Regex pattern should match van.util.is_valid_van_source_code()
 **/
export function isValidVanSourceCode(input) {
  return /^([a-zA-Z0-9-_ ]){1,50}$/.test(input)
}
