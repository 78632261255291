import {Loader, Message} from 'components'

import EventFeedItem from 'events/components/EventFeedItem'
import React from 'react'
import api from 'data/api'
import {getOrganizationEventByEventIdUrl} from 'util/routing'
import styled from '@emotion/styled/macro'
import {useAsync} from 'hooks/async'

const MAX_EVENT_HEIGHT = '300px'
const EVENT_WIDTH = '275px'

const OuterWrapper = styled.div`
  & .mbz-feed-item {
    width: ${EVENT_WIDTH};
    margin: 0;
  }

  & .mbz-feed-item-container {
    height: 100%;
  }

  max-height: ${MAX_EVENT_HEIGHT};
  width: ${EVENT_WIDTH};
`

function FeedMapItem({organization, eventId}) {
  const eventResponseState = useAsync(async () => {
    const url = getOrganizationEventByEventIdUrl(organization, eventId)
    return api.fetchScreenResponse(url.pathname, {}, true)
  }, [organization, eventId])

  const event = eventResponseState.result?.data.event

  return (
    <OuterWrapper>
      {eventResponseState.loading && <Loader large />}
      {eventResponseState.error && (
        <Message type="error">{eventResponseState.error.message}</Message>
      )}
      {event && <EventFeedItem event={event} isCompactFeedItem hideButtons />}
    </OuterWrapper>
  )
}

export default React.memo(FeedMapItem)
