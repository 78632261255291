import styled from '@emotion/styled/macro'
import styles from './styles'

const HEIGHT = 28

/**
 * This component adds shadows for a scrollable container.
 */
const ScrollContainerEl = styled.div`
  position: sticky;
  overflow: auto;
  margin: 0 calc(-${styles.space.m} + 1px);
  ${(props) => `max-height: ${props.maxHeight};`}

  &::before,
  &::after {
    content: ' ';
    position: sticky;
    display: block;
    left: 0;
    right: 0;
    width: 100%;
    height: ${HEIGHT}px;
    pointer-events: none;
  }

  &::before {
    top: 0;
    background: 0 0
      linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, 0.05) 100%) no-repeat;
  }

  &::after {
    bottom: 0;
    background: 0 0
      linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, transparent 100%) no-repeat;
  }
`

// These hide the :before/:after pseudo elements when the scroller is 'at rest'.
// They cover up them up and then move out of the way when scrolled.
const ScrollShadowHider = styled.div`
  position: sticky;
  height: ${HEIGHT}px;
  margin: -${HEIGHT}px 0;
  ${(props) =>
    props.isBottom
      ? `background: linear-gradient(rgba(255,255,255,0) 0%, ${styles.colors.white} 100%);`
      : `background: linear-gradient(${styles.colors.white} 0%, rgba(255,255,255,0) 100%);`}
  pointer-events: none;
  z-index: ${styles.zindex.abovePage};
`

const InnerScroll = styled.div`
  padding: ${HEIGHT / 2}px ${styles.space.m};
`

export default function ScrollContainer({children, maxHeight}) {
  return (
    <ScrollContainerEl maxHeight={maxHeight}>
      <ScrollShadowHider />
      <InnerScroll>{children}</InnerScroll>
      <ScrollShadowHider isBottom={true} />
    </ScrollContainerEl>
  )
}
