import Tooltip from './Tooltip'
import Typography from './Typography'
import styled from '@emotion/styled/macro'
import styles from './styles'

// DEFAULT VALUES:
// color: primary
// bg: primary-200

// VARIANTS:
// white bg
// black text

const BadgeWrapper = styled.span`
  border-radius: ${styles.space.borderRadius};
  font-size: ${styles.typography.fontSizeS};
  font-weight: ${styles.typography.fontWeightSemibold};
  padding: 3px 7px;
  position: relative;
  white-space: nowrap;

  background-color: ${(props) =>
    props.error
      ? `${styles.colors.error100}`
      : props.bgColor === 'white'
      ? `${styles.colors.white}`
      : props.bgColor === 'lightPink'
      ? `${styles.colors.lightPink}`
      : props.bgColor === 'primary200'
      ? `${styles.colors.primary200}`
      : props.bgColor === 'lightBlue'
      ? `${styles.colors.lightBlue}`
      : props.bgColor === 'neutral200'
      ? `${styles.colors.neutral200}`
      : `${styles.colors.primary100}`};

  color: ${(props) =>
    props.error
      ? `${styles.colors.error200}`
      : props.textColor === 'black'
      ? `${styles.colors.black}`
      : props.textColor === 'pink'
      ? `${styles.colors.pink}`
      : props.bgColor === 'primary200'
      ? `${styles.colors.white}`
      : props.textColor === 'blue'
      ? `${styles.colors.blue}`
      : `${styles.colors.primary200}`};

  ${(props) => props.marginRight && `margin-right: ${styles.space.xs};`}
`

/** A badge to display next to text. Can take in an optional tooltip to provide explanatory text
 * or links on hover.
 */
const Badge = ({
  bgColor,
  children,
  error,
  textColor = 'primary',
  tooltipContent,
  marginRight,
}) => {
  const badgeContent = (
    <Typography variant="subtitle1">
      <BadgeWrapper
        error={error}
        bgColor={bgColor}
        textColor={textColor}
        marginRight={marginRight}
      >
        {children}
      </BadgeWrapper>
    </Typography>
  )
  return tooltipContent ? (
    <Tooltip title={tooltipContent}>{badgeContent}</Tooltip>
  ) : (
    badgeContent
  )
}

export default Badge
