import {FormGroup as MuiFormGroup} from '@material-ui/core'
import {classnames} from 'util/common'
import {makeStyles} from '@material-ui/core/styles'
import styles from './styles'

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-end',
    marginTop: styles.space.m,
    alignItems: 'baseline',
  },

  // Keep in sync with MuiFormGroup -> row in muiTheme.js
  stackOnMobile: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      // This feels super hacky, but also without it stacked `fluid` buttons
      // will have a weird space on the right instead of properly being
      // full-bleed. May need to add selectors for other input elements.
      '& > button:not(:last-child)': {
        marginRight: '0',
      },
    },
  },

  center: {
    justifyContent: 'center',
  },

  stackAlways: {
    flexWrap: 'wrap',
  },
}))

const FormGroup = ({actions, center, children, stackAlways, stackOnMobile}) => {
  const classes = useStyles()

  return (
    <MuiFormGroup
      row
      classes={{
        root: classnames(
          actions ? classes.actions : undefined,
          stackAlways ? classes.stackAlways : undefined,
          stackAlways ? classes.stackAlways : undefined,
          stackOnMobile ? classes.stackOnMobile : undefined,
          center ? classes.center : undefined
        ),
      }}
    >
      {children}
    </MuiFormGroup>
  )
}

export default FormGroup
