import {Tooltip as MaterialTooltip} from '@material-ui/core'

export default function Tooltip({children, ...props}) {
  return (
    <MaterialTooltip interactive {...props} arrow>
      {/* Needs to have a single target for the hover behavior to work, so instead of doing this
      every place that uses this (and forgetting it all the time), just wrap the children in a
      <span> here */}
      <span>{children}</span>
    </MaterialTooltip>
  )
}
