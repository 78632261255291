import {Button, Input, InputGroup} from 'components'

export default function CoHostRow({coHost, onCoHostChange, onRemove, canEdit}) {
  const onChange = (e) => {
    const {name, value} = e.target
    onCoHostChange({
      ...coHost,
      [name]: value,
    })
  }
  const alreadySaved = !!coHost.id

  return (
    <InputGroup stackOnMobile>
      <Input
        name="first_name"
        value={coHost.first_name}
        label="First name"
        onChange={onChange}
        required
        disabled={alreadySaved || !canEdit}
      />
      <Input
        name="last_name"
        value={coHost.last_name}
        label="Last name"
        onChange={onChange}
        required
        disabled={alreadySaved || !canEdit}
      />
      <Input
        name="email"
        value={coHost.email}
        label="Email"
        onChange={onChange}
        required
        disabled={alreadySaved || !canEdit}
      />
      {canEdit && (
        <Button
          link
          padding="none"
          icon="times"
          type="button"
          onClick={onRemove}
        />
      )}
    </InputGroup>
  )
}
