import {filterOutAtCapacityTimeslots, isAtCapacity} from 'util/timeslot'

import {shouldPrecheckTwoTimeslots} from 'events/details/SignupForm/util'

export function getAvailableTimeslots(event) {
  return filterOutAtCapacityTimeslots(event.times)
}

export function getShifts(maybePrioritizedTimeslot, organization, event) {
  const availableTimeslots = getAvailableTimeslots(event)
  if (!availableTimeslots.length) {
    return []
  }

  let selectedTimeslotIds = []
  if (maybePrioritizedTimeslot && !isAtCapacity(maybePrioritizedTimeslot)) {
    selectedTimeslotIds = [maybePrioritizedTimeslot.id]
  } else if (
    shouldPrecheckTwoTimeslots(maybePrioritizedTimeslot, organization, event)
  ) {
    selectedTimeslotIds = [availableTimeslots[0].id, availableTimeslots[1].id]
  } else if (availableTimeslots.length === 1) {
    selectedTimeslotIds = [availableTimeslots[0].id]
  }
  const shifts = selectedTimeslotIds.map((timeslotId) => ({
    timeslot_id: timeslotId,
  }))

  return shifts
}
