import {Button, Form, Input, Message, MessageType, Typography} from 'components'
import {invalid, valid} from 'util/validation'

import {ChatSnippetInitializationMode} from 'vendor/intercom/chatSnippetManager'
import {Component} from 'react'
import api from 'data/api'
import withScreenData from 'app/withScreenData'

const ValidationError = ({children}) => (
  <Message compact type={MessageType.ERROR}>
    {children}
  </Message>
)

class LegacyUserSettingsPasswordScreen extends Component {
  state = {
    old_password: '',
    new_password: '',
    repeated_new_password: '',
    isProcessing: false,
    errorMsg: '',
    passwordChanged: false,
    validationErrors: {},
  }

  hasValidationErrors = () =>
    Object.values(this.state.validationErrors).some(Boolean)

  onSubmit = async () => {
    if (this.hasValidationErrors()) {
      this.setState({
        errorMsg: 'Please fix the errors above.',
        passwordChanged: false,
      })
      return
    }

    this.setState({isProcessing: true})
    const {old_password, new_password} = this.state
    const data = {old_password, new_password}
    try {
      await api.changeUserPassword(data)
    } catch (e) {
      this.setState({
        isProcessing: false,
        // This is an ugly hack for surfacing validation errors elegantly. TODO(jo): improve
        errorMsg:
          e.message.toString().split('password": ')[1] ||
          'There was an error changing your password. Check that your old password is correct and try again.',
        passwordChanged: false,
      })
      return
    }
    this.setState({
      isProcessing: false,
      errorMsg: '',
      passwordChanged: true,
    })
  }

  onFieldChange = (e, validator) => {
    const {name, value} = e.currentTarget

    // TODO(jared) figure out how to make this more generalizable
    const validationError = {}
    if (validator) {
      const validationResult = validator(value)
      validationError[name] = validationResult.valid
        ? null
        : validationResult.message
    }

    this.setState({
      [name]: value,
      // $FlowFixMe: spreading with overrides is tricky currently.
      validationErrors: {...this.state.validationErrors, ...validationError},
    })
  }

  validateNewPassword = (value) => {
    if (value && value.length < 8) {
      return invalid('Your password must be at least 8 characters.')
    }
    return valid()
  }

  validateRepeatedNewPassword = (value) => {
    if (value && value.length < 8) {
      return invalid('Your password must be at least 8 characters.')
    }
    const {new_password} = this.state
    if (new_password && value && new_password !== value) {
      return invalid('Passwords do not match.')
    }
    return valid()
  }

  render() {
    const {
      errorMsg,
      isProcessing,
      passwordChanged,
      old_password,
      new_password,
      repeated_new_password,
      validationErrors,
    } = this.state

    return (
      <>
        <Typography variant="h1">Password</Typography>
        <Form onSubmit={this.onSubmit} loading={isProcessing}>
          <Input
            name="old_password"
            label="Old password"
            type="password"
            required
            onChange={this.onFieldChange}
            value={old_password}
            width={6}
          />
          <Input
            name="new_password"
            label="New password"
            required
            value={new_password}
            type="password"
            onChange={(e) => this.onFieldChange(e, this.validateNewPassword)}
            error={!!validationErrors.new_password}
            width={6}
          />
          {validationErrors.new_password && (
            <ValidationError>{validationErrors.new_password}</ValidationError>
          )}
          <Input
            name="repeated_new_password"
            label="Confirm new password"
            required
            value={repeated_new_password}
            type="password"
            onChange={(e) =>
              this.onFieldChange(e, this.validateRepeatedNewPassword)
            }
            error={!!validationErrors.repeated_new_password}
            width={6}
          />
          {validationErrors.repeated_new_password && (
            <ValidationError>
              {validationErrors.repeated_new_password}
            </ValidationError>
          )}
          {errorMsg && (
            <Message type={MessageType.ERROR} header="Oops">
              {errorMsg}
            </Message>
          )}
          {passwordChanged && (
            <Message type={MessageType.SUCCESS} header="Password changed">
              Your password has been successfully changed.
            </Message>
          )}
          <div>
            <Button disabled={this.hasValidationErrors()}>Update</Button>
          </div>
        </Form>
      </>
    )
  }
}

export default withScreenData(LegacyUserSettingsPasswordScreen, {
  chatSnippetInitializationMode:
    ChatSnippetInitializationMode.USE_MOBILIZE_INTERCOM_ONLY_FOR_ORG_STAFF_BUT_PREFER_CLIENT_CHAT_SNIPPET_FOR_HOSTS,
})
