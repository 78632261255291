import {
  ActionButtons,
  Button,
  CardModal,
  Icon,
  Input,
  InputGroup,
  TextArea,
  Typography,
} from 'components'

import {F} from 'util/i18n'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
import {useState} from 'react'

const CenteredContent = styled.div`
  text-align: center;
`

const ModalContent = styled.div`
  margin-left: ${styles.space.m};
  margin-right: ${styles.space.m};
`

const ModalContentItem = styled.div`
  margin-top: ${styles.space.s};
  margin-bottom: ${styles.space.l};
`

const MarginBottom = styled.div`
  margin-bottom: ${styles.space.l};
`

function SuccessModal({isOpen, onRequestClose}) {
  /* Success message shown after request is sent. */

  return (
    <CardModal onRequestClose={onRequestClose} isOpen={isOpen} wide>
      <ModalContent>
        <CenteredContent>
          <ModalContentItem>
            <Icon name="check-circle" success huge />
          </ModalContentItem>
          <ModalContentItem>
            <Typography variant="h2">
              <F defaultMessage="Message sent!" />
            </Typography>
          </ModalContentItem>
          <ModalContentItem>
            <Button onClick={onRequestClose}>
              <F defaultMessage="Close" />
            </Button>
          </ModalContentItem>
        </CenteredContent>
      </ModalContent>
    </CardModal>
  )
}

export default function ContactHostModal({
  isOpen,
  onRequestClose,
  onSubmit,
  eventName,
}) {
  const [sendInProgress, setSendInProgress] = useState(false)

  // Form properties.
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  // Disable submit if any param is empty.
  const sendButtonDisabled = !(firstName && lastName && email && message)

  const closeModal = () => {
    setSendInProgress(false)
    onRequestClose()
  }

  return (
    <>
      {sendInProgress ? (
        <SuccessModal isOpen={isOpen} onRequestClose={closeModal} />
      ) : (
        <CardModal onRequestClose={closeModal} isOpen={isOpen}>
          <ModalContent>
            <MarginBottom>
              <Typography variant="h2">
                Contact the organizer of "{eventName}"
              </Typography>
            </MarginBottom>

            <MarginBottom>
              <InputGroup>
                <Input
                  name="firstName"
                  type="text"
                  label="First name"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={false}
                />
                <Input
                  name="lastName"
                  type="text"
                  label="Last name"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  error={false}
                />
              </InputGroup>
              <Input
                name="email"
                type="email"
                label="Your email"
                fluid
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={false}
              />
              <TextArea
                name="message"
                label="Message to the organizer"
                required
                placeholder="e.g Does this event have live captions?"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </MarginBottom>

            <ActionButtons>
              <Button type="button" secondary onClick={closeModal}>
                <F defaultMessage="Cancel" />
              </Button>
              <Button
                primary
                type="submit"
                onClick={() => {
                  onSubmit({
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    message,
                  })
                  setSendInProgress(true)
                }}
                disabled={sendButtonDisabled}
              >
                <F defaultMessage="Send message" />
              </Button>
            </ActionButtons>
          </ModalContent>
        </CardModal>
      )}
    </>
  )
}
