import {AuthProvider, AuthProviderToDisplayName} from 'app/enums'
import {
  FacebookLoginButton,
  GoogleLoginButton,
  Message,
  MessageType,
} from 'components'

import DisconnectAuthProviderButton from './DisconnectAuthProviderButton'
import {GoogleLoginButtonOptions} from '../../app/enums'
import SettingsCard from './SettingsCard'
import cv from 'util/clientVars'
import moment from 'vendor/moment'
import {useState} from 'react'

export default function ConnectedAccountSettingsCard({
  facebook_associated_at,
  google_associated_at,
}) {
  const [facebookAssociatedAt, setFacebookAssociatedAt] = useState(
    facebook_associated_at
  )
  const [googleAssociatedAt, setGoogleAssociatedAt] = useState(
    google_associated_at
  )
  const [error, setError] = useState(null)

  const getOnDisconnectAuthProvider = (authProvider) => () => {
    setError(null)
    if (authProvider === AuthProvider.FACEBOOK) {
      setFacebookAssociatedAt(null)
    } else {
      setGoogleAssociatedAt(null)
    }
  }

  const getOnConnectAuthProvider = (authProvider) => ({
    user: {google_associated_at, facebook_associated_at},
  }) => {
    setError(null)
    if (authProvider === AuthProvider.FACEBOOK) {
      setFacebookAssociatedAt(facebook_associated_at)
    } else {
      setGoogleAssociatedAt(google_associated_at)
    }
  }

  const getOnAuthProviderConnectionError = (authProvider) => (
    e,
    detailedError
  ) => {
    let error =
      detailedError ??
      `Could not connect your ${AuthProviderToDisplayName[authProvider]} account.`
    setError(error)
  }

  const getOnDisconnectError = (authProvider) => () =>
    setError(
      `Error disconnecting ${AuthProviderToDisplayName[authProvider]} account. Please try again later or contact support@mobilize.us if this problem persists.`
    )

  const maybeGetAssociatedAtMoment = (associated_at) =>
    associated_at ? moment(associated_at, moment.ISO_8601) : null

  const facebookAssociated = !!facebookAssociatedAt
  const googleAssociated = !!googleAssociatedAt
  const associatedFacebookAtMoment = maybeGetAssociatedAtMoment(
    facebookAssociatedAt
  )
  const associatedGoogleAtMoment = maybeGetAssociatedAtMoment(
    googleAssociatedAt
  )

  let footerText
  if (facebookAssociated && googleAssociated) {
    footerText = `You can use your Facebook or Google accounts to log into ${cv.product_name}.`
  } else if (facebookAssociated && !googleAssociated) {
    footerText = `You can use your Facebook account to log into ${cv.product_name}. If you link your Google account, you can also use it to log in.`
  } else if (!facebookAssociated && googleAssociated) {
    footerText = `You can use your Google account to log into ${cv.product_name}. If you link your Facebook account, you can also use it to log in.`
  } else if (!facebookAssociated && !googleAssociated) {
    footerText = `Link your Facebook or Google accounts so you can use them to log into ${cv.product_name}.`
  }

  return (
    <SettingsCard header="Connected Accounts" footer={footerText}>
      {associatedFacebookAtMoment ? (
        <>
          <p data-testid="facebook-connected-at">
            You connected your Facebook account on{' '}
            {associatedFacebookAtMoment.format('MMMM D, YYYY')}.
          </p>
          <p>
            <DisconnectAuthProviderButton
              onComplete={getOnDisconnectAuthProvider(AuthProvider.FACEBOOK)}
              onError={getOnDisconnectError(AuthProvider.FACEBOOK)}
              authProvider={AuthProvider.FACEBOOK}
            />
          </p>
        </>
      ) : (
        <p>
          <FacebookLoginButton
            type="connect"
            onComplete={getOnConnectAuthProvider(AuthProvider.FACEBOOK)}
            onError={getOnAuthProviderConnectionError(AuthProvider.FACEBOOK)}
          />
        </p>
      )}
      {associatedGoogleAtMoment ? (
        <>
          <p data-testid="google-connected-at">
            You connected your Google account on{' '}
            {associatedGoogleAtMoment.format('MMMM D, YYYY')}.
          </p>
          <p>
            <DisconnectAuthProviderButton
              onComplete={getOnDisconnectAuthProvider(AuthProvider.GOOGLE)}
              onError={getOnDisconnectError(AuthProvider.GOOGLE)}
              authProvider={AuthProvider.GOOGLE}
            />
          </p>
        </>
      ) : (
        <p>
          <GoogleLoginButton
            type={GoogleLoginButtonOptions.CONNECT}
            onComplete={getOnConnectAuthProvider(AuthProvider.GOOGLE)}
            onError={getOnAuthProviderConnectionError(AuthProvider.GOOGLE)}
          />
        </p>
      )}
      {error && (
        <Message type={MessageType.ERROR} header="Oops" list={[error]} />
      )}
    </SettingsCard>
  )
}
