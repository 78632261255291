import {useEffect, useReducer} from 'react'

function requestReducer(state, {type, result, error}) {
  // Aborted requests should not change state again
  if (state.aborted) return state
  switch (type) {
    case 'request_start':
      return {loading: true, aborted: false}
    case 'request_complete':
      return {loading: false, aborted: false, result}
    case 'request_error':
      return {loading: false, aborted: false, error}
    case 'request_abort':
      // Only requests that have started can abort
      if (!state.loading) return state
      return {loading: false, aborted: true}

    default:
      throw new Error(`Unsupported action: ${type}`)
  }
}

const actionRequestStart = () => ({type: 'request_start'})
const actionRequestComplete = (result) => ({type: 'request_complete', result})
const actionRequestError = (error) => ({type: 'request_error', error})
const actionRequestAbort = () => ({type: 'request_abort'})

/**
 * Given an async function `fn` and a list of dependencies, run and return the
 * state of `fn` anytime `deps` change (like `useEffect`).
 */
export function useAsync(fn, deps) {
  const [state, dispatch] = useReducer(requestReducer, {
    loading: false,
    aborted: false,
  })

  useEffect(() => {
    async function runAsync() {
      dispatch(actionRequestStart())
      try {
        const result = await fn()
        dispatch(actionRequestComplete(result))
      } catch (error) {
        dispatch(actionRequestError(error))
      }
    }
    runAsync()
    return () => dispatch(actionRequestAbort())
    // These aren't statically inspectable by ESLint, so they throw the
    // warning, but are in fact the exact set of dependencies to this callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return state
}

/**
 * Hook to create a callback that can be used to execute an async function while
 * tracking its state.
 */
export function useAsyncCallback(fn) {
  const [state, dispatch] = useReducer(requestReducer, {
    loading: false,
    aborted: false,
  })

  const execute = (...args) => {
    dispatch(actionRequestStart())
    fn(...args)
      .then((result) => {
        dispatch(actionRequestComplete(result))
      })
      .catch((error) => {
        dispatch(actionRequestError(error))
      })
  }

  const abort = () => {
    dispatch(actionRequestAbort())
  }

  return {
    execute,
    abort,
    state,
  }
}
