import {useEffect, useState} from 'react'

import api from 'data/api'

// TODO(jared) consider swapping in useAsync here to avoid reinventing the wheel
export default function useFetchEvents(
  maybeFilterParamsPartial,
  organization,
  shouldFetch
) {
  const [events, setEvents] = useState([])
  const [hasError, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchData(filterParamsPartial) {
      setIsLoading(true)
      try {
        const response = await api.getEventsFromFeedForCarousel(
          filterParamsPartial,
          organization
        )
        if (response.events.length) {
          setEvents(response.events)
        } else {
          setError(true)
        }
      } catch (ex) {
        setError(true)
      }
      setIsLoading(false)
    }
    if (
      shouldFetch &&
      maybeFilterParamsPartial &&
      !hasError &&
      !events.length &&
      !isLoading
    ) {
      fetchData(maybeFilterParamsPartial)
    } else if (!maybeFilterParamsPartial) {
      // Returning null from a config's getFilterParamsPartial means that the carousel is trying to
      // render in a context where it doesn't make sense, so stop loading and set the error flag.
      setError(true)
      setIsLoading(false)
    }
  }, [
    events.length,
    hasError,
    maybeFilterParamsPartial,
    organization,
    shouldFetch,
    isLoading,
  ])

  return {events, hasError, isLoading}
}
