import {debounce, omit} from 'util/common'

import Autocomplete from './Autocomplete'
import {Component} from 'react'

/** A Autocomplete that expects getFilteredOptions to make
 * a backend search call. Debounces calls to getFilteredOptions. */
export default class BackendSearchSelect extends Component {
  debouncedGetFilteredOptions = debounce(this.props.getFilteredOptions, 200)

  onSearchChange = (e, value) => {
    this.debouncedGetFilteredOptions(value)
  }

  componentDidMount() {
    // Initialize with an empty string so that results won't initially be empty
    this.props.getFilteredOptions('')
  }

  // XXX(mime): ugh. I spent too much time on this thing.
  // This was *specifically* added for OwnerFilter.js which references `creators`
  // in its API call. However, there is some sort of funny timing between when the lifecycle
  // when the components get updated and when the `debounce` above is called.
  // Specifically, it seems that `onChange` is called on `AutoComplete` below, which updates via
  // `onChange` callback the `value` (in OwnerFilter's case, `creators` is the `value`) and
  // then `onSearchChange` is called signaling a new blank search via the `debounce`d `getFilteredOptions`.
  // However, in the `getFilteredOptions` the `creators` value is still the old one when its run
  // because it seems that the component hasn't fully finished updating or something.
  //
  // The weird thing is that this behavior isn't exhibited by the other users of BackendSearchSelect.
  // I suspect it's because those other consumers don't pass the `onChange` callback to a grandparent component which
  // is what happens with the OwnerFilter. Hence why I think it's a lifecycle thing.
  //
  // ANYWAY, this cancels the debounce operation in progress if the `value` prop is updated
  // and triggers a new filtered options which seems to fix the issue at hand.
  // Related bugs and PRs:
  // - https://app.asana.com/0/892870801704597/1198959170318557
  // - https://app.asana.com/0/892870801704597/1182103047147705
  // - previous PR attempt: https://github.com/mobilizeamerica/mobilize/pull/6061
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.debouncedGetFilteredOptions.cancel()
      this.props.getFilteredOptions('')
    }
  }

  render() {
    const passthruProps = omit(this.props, 'getFilteredOptions')
    return (
      <Autocomplete {...passthruProps} onInputChange={this.onSearchChange} />
    )
  }
}
