import Loader from './Loader'
import styled from '@emotion/styled/macro'
import styles from './styles'

const LoaderWrapper = styled.div`
  position: fixed;
  background: ${styles.colors.white};
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: ${styles.zindex.abovePage};
`

export default function Form({children, className, loading, onSubmit}) {
  return (
    <>
      {loading && (
        <LoaderWrapper>
          <Loader large />
        </LoaderWrapper>
      )}
      <form
        className={className}
        onSubmit={(e) => {
          e.preventDefault()
          if (onSubmit) {
            onSubmit(e)
          }
        }}
      >
        {children}
      </form>
    </>
  )
}
