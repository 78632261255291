// localStorage/sessionStorage keys created by this Redux configuration will have the form
// `${storagePathPrefix}${path}`
const storagePathPrefix = 'redux-mobilizeamerica-'
// Only the parts of the ReduxState under localStoragePaths will be synced to LocalStorage
const localStoragePaths = [
  // 'feedMemberships' (deprecated) is reserved
  'currentVolunteer',
  'lastSeenLoggedInAt',
  'hasAttemptedEmbedTransfer',
  'lastAffirmedFeedMembershipsAt',
  'eventSignups',
]
// Only the parts of the ReduxState under sessionStoragePaths will be synced to SessionStorage
const sessionStoragePaths = [
  'feedUser',
  'feedReshiftMethod',
  'feedFlakeReshiftMethod',
  'numTimesViewedEventCreateModal',
]

export {storagePathPrefix, localStoragePaths, sessionStoragePaths}
