import {EventRegistrationMode, EventType} from 'app/enums'

export function getEventCampaignTypeAffinityChecks(eventCampaign) {
  return {
    isFundraisingEventCampaign: isFundraisingEventCampaign(eventCampaign),
    isPeerToPeerFundraisingCampaign: isPeerToPeerFundraisingCampaign(
      eventCampaign
    ),
  }
}

// Fundraising event campaigns are ECs with donate-to-rsvp events
export function isFundraisingEventCampaign(eventCampaign) {
  return (
    eventCampaign?.hosted_event_registration_mode ===
      EventRegistrationMode.DONATE_TO_RSVP ||
    eventCampaign?.hosted_event_registration_mode ===
      EventRegistrationMode.DONATE_TO_RSVP_OPTIONAL
  )
}

// Peer-to-peer fundraising campaigns are campaigns where all events are fundraisers
export function isPeerToPeerFundraisingCampaign(eventCampaign) {
  return (
    eventCampaign?.hosted_event_types.length === 1 &&
    eventCampaign?.hosted_event_types[0] === EventType.DONATION_CAMPAIGN
  )
}

// Group event campaigns are campaigns where all events are groups
export function isGroupEventCampaign(eventCampaign) {
  return (
    eventCampaign?.hosted_event_types.length === 1 &&
    eventCampaign?.hosted_event_types[0] === EventType.GROUP
  )
}
